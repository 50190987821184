<crm-breadcrumb
  [header]="activity ? activity.name : 'newActivity'"
  [actionButtons]="actions"
  (actionEvent)="onActionEvent($event)"
></crm-breadcrumb>
<crm-container [maxSize]="'xl'">
  <ng-container>
    <form (submit)="onSubmit()">
      <div class="form-row">
        <div class="col-md-8">
          <crm-form-template
            [formGroup]="formGroup"
            [formFields]="formFields"
          ></crm-form-template>
        </div>

        <div class="col-md-4">
          <crm-form-template
            [formGroup]="formGroup"
            [formFields]="formFieldsRight"
          ></crm-form-template>
        </div>
        <div class="col-md-12">
          <div
            [matTooltip]="getToolTipSubmitBtnMessage() | i18nextCap"
            [matTooltipDisabled]="
              !(isSubmitDisabled$ | async) || userCanEditForm
            "
            class="submit-div"
          >
            <button
              type="button"
              mat-flat-button
              color="primary"
              type="submit"
              [disabled]="(isSubmitDisabled$ | async) || !userCanEditForm"
            >
              {{ 'forms:' + 'save' | i18nextCap }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </ng-container>
</crm-container>
