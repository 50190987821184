import { UnsavedTicketInstallation } from './../../models/v0/UnsavedTicketInstallation';
import { ISerialisedInstallationTicket } from './../../models/v0/serialisations/ISerialisedInstallationTicket';
import { TicketInstallation } from '../../models/v0/TicketInstallation';
import { Response } from '../Response';
import { Endpoint } from '../Endpoint';
import { HttpClient } from '@angular/common/http';
import { IListResponse } from '../IListResponse';
import { Injectable, Inject } from '@angular/core';
import { API_SERVICE_CONFIG } from '../ApiServiceConfig';
import { IApiServiceConfig } from '../IApiServiceConfig';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { InstallationTicketInput } from '../types/InstallationTicket';

type InstallationList = IListResponse<ISerialisedInstallationTicket>;

@Injectable()
export class InstallationTicketEndpoint extends Endpoint<
  TicketInstallation,
  ISerialisedInstallationTicket
> {
  private readonly url = this.config.url + 'tickets/installations';

  constructor(
    @Inject(API_SERVICE_CONFIG) private config: IApiServiceConfig,
    private http: HttpClient
  ) {
    super(TicketInstallation.fromSerialised);
  }

  getAll(
    filters: {},
    source?: Observable<InstallationList>,
    store?: BehaviorSubject<Response<TicketInstallation[]>>
  ): Observable<Response<TicketInstallation[]>> {
    let query = '';
    Object.keys(filters).forEach((key) => (query += `&${key}=${filters[key]}`));

    const defaultSource = this.http.get<InstallationList>(
      `${this.url}?page=0${query}`
    );

    return this.list(source || defaultSource, store);
  }

  get(
    id: string,
    source?: Observable<ISerialisedInstallationTicket>,
    store?: BehaviorSubject<Response<TicketInstallation[]>>
  ): Observable<Response<TicketInstallation>> {
    const defaultSource = this.http.get<ISerialisedInstallationTicket>(
      `${this.url}/${id}`
    );
    return this.retrieve(source || defaultSource, store);
  }

  post(
    installationTicket: UnsavedTicketInstallation,
    source?: Observable<ISerialisedInstallationTicket>,
    store?: BehaviorSubject<Response<TicketInstallation[]>>
  ): Observable<Response<TicketInstallation>> {
    const body = installationTicket;

    const defaultSource = this.http.post<ISerialisedInstallationTicket>(
      this.url,
      body
    );
    return this.add(source || defaultSource, store);
  }

  delete(
    id: string,
    source?: Observable<{}>,
    store?: BehaviorSubject<Response<TicketInstallation[]>>
  ): Observable<Response<{}>> {
    const defaultSource = this.http.delete<{}>(`${this.url}${id}/`);
    return this.remove(source || defaultSource, id, store);
  }

  updateRequestTicketById(
    id: string,
    body: InstallationTicketInput,
    store?: BehaviorSubject<Response<TicketInstallation[]>>
  ) {
    const defaultSource = this.http.patch<ISerialisedInstallationTicket>(
      `${this.url}/${id}`,
      body
    );

    return defaultSource.pipe(
      tap((instance) =>
        this.update(
          defaultSource,
          TicketInstallation.fromSerialised(instance),
          store
        )
      ),
      map(
        (serialiser) =>
          new Response(TicketInstallation.fromSerialised(serialiser))
      )
    );
  }

  patch(
    id: string,
    ticketSupport: UnsavedTicketInstallation,
    source?: Observable<ISerialisedInstallationTicket>,
    store?: BehaviorSubject<Response<TicketInstallation[]>>
  ) {
    const { ...body } = ticketSupport.toSerialised();
    const defaultSource = this.http.patch<ISerialisedInstallationTicket>(
      `${this.url}/${id}/`,
      body
    );

    return defaultSource.pipe(
      tap((instance) =>
        this.update(
          source || defaultSource,
          TicketInstallation.fromSerialised(instance),
          store
        )
      ),
      map(
        (serialiser) =>
          new Response(TicketInstallation.fromSerialised(serialiser))
      )
    );
  }
}
