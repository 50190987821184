import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { first } from 'rxjs/operators';
import { UiAuthService } from '@fullyops/legacy/ui';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

interface WizardItemsTypes {
  icon: string;
  title: string;
  subtitle: string;
  selected?: boolean;
}

@Component({
  selector: 'app-loginv2',
  templateUrl: './loginv2.component.html',
  styleUrls: ['./loginv2.component.scss'],
})
export class LoginV2Component implements OnInit {
  loginForm: UntypedFormGroup;
  loading$ = new BehaviorSubject(false);
  returnUrl: string;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: UiAuthService,
    private snackBar: MatSnackBar
  ) {}

  wizardItems: WizardItemsTypes[] = [
    {
      icon: 'work_outline',
      title: 'Tarefa',
      subtitle: 'Informações',
      selected: true,
    },
    { icon: 'settings', title: 'Intervenções', subtitle: 'Lista e Detalhes' },
    { icon: 'library_books', title: 'Relatórios', subtitle: 'Lista e Geração' },
  ];

  navSections = [
    {
      name: 'contacts',
      subsections: [
        {
          name: 'people',
        },
        {
          name: 'companies',
        },
      ],
    },
    {
      name: 'activities',
    },
  ];

  ticketData = {
    title: 'Informação da Assistência',
    subtitle: 'Informação base da assistência técnica',
  };

  ngOnInit() {}

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }
}
