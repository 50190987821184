import {
  Component,
  ChangeDetectionStrategy,
  AfterViewInit,
  EventEmitter,
  ViewChild,
  Output,
} from '@angular/core';
import { ComponentInOutAnimation } from '../utils/component-base-animation';
import SignaturePad from 'signature_pad';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UnsavedUpload } from '@fullyops/legacy/data';
import { TimeZoneDateFormatterService } from '@fullyops/core/services/date-formatter.service';

@Component({
  selector: 'crm-signature-pad',
  templateUrl: './signature-pad.component.html',
  styleUrls: ['./signature-pad.component.scss'],
  animations: [ComponentInOutAnimation.AnimTrigger],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignaturePadComponent implements AfterViewInit {
  constructor(public timezoneDate: TimeZoneDateFormatterService) {}
  @Output() changeEvent = new EventEmitter<FormData>();

  @ViewChild('sPad', { static: true }) signaturePadElement;
  signaturePad: any;

  ngAfterViewInit(): void {
    this.signaturePad = new SignaturePad(
      this.signaturePadElement.nativeElement
    );

    (this.signaturePad as SignaturePad).onEnd = (event) => {
      this.send();
    };
  }

  clear() {
    this.signaturePad.clear();
    this.changeEvent.emit(null);
  }

  b64toFile(dataURI): File {
    // convert the data URL to a byte string
    const byteString = atob(dataURI.split(',')[1]);

    // pull out the mime type from the data URL
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // Convert to byte array
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // Create a blob that looks like a file.
    const blob = new Blob([ab], { type: mimeString });
    blob['lastModifiedDate'] = this.timezoneDate.currentDate().toISOString();
    blob['name'] = 'file';

    // Figure out what extension the file should have
    switch (blob.type) {
      case 'image/jpeg':
        blob['name'] += '.jpg';
        break;
      case 'image/png':
        blob['name'] += '.png';
        break;
    }
    // cast to a File
    return blob as File;
  }

  send() {
    if (this.signaturePad.isEmpty()) {
      alert('Please provide a signature first.');
    } else {
      const dataURL = this.signaturePad.toDataURL();
      const file = this.b64toFile(dataURL);
      const formData = new FormData();
      formData.append('file', file, 'file.png');

      this.changeEvent.emit(formData);
    }
  }
}
