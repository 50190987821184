import { ISerialisedUnsavedReportRequest } from './serialisations/ISerialisedReportRequest';

export class UnsavedReportRequest {
  constructor(
    readonly templateId: string,
    readonly type: string,
    readonly file: FormData
  ) {}

  static fromSerialised(serialised: ISerialisedUnsavedReportRequest) {
    return new UnsavedReportRequest(
      serialised.templateId,
      serialised.type,
      serialised.file
    );
  }

  toSerialised() {
    return this.file;
  }
}
