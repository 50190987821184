<crm-breadcrumb
  [header]="title"
  [actionButtons]="actions"
  (actionEvent)="onActionEvent($event)"
></crm-breadcrumb>

<crm-container [maxSize]="'xl'">
  <mat-form-field class="example-full-width">
    <mat-label>{{ 'forms:search' | i18nextCap }}</mat-label>
    <input matInput [formControl]="searchUser" />
  </mat-form-field>

  <div class="users-list">
    <mat-card
      *ngFor="let user of usersV2Filtered$ | async"
      class="user-card"
      (click)="openUser(user.id)"
    >
      <div class="basic-data">
        <ngx-avatars
          [size]="36"
          [matTooltip]="user.firstName + ' ' + user.lastName"
          [name]="user.firstName + ' ' + user.lastName"
          [src]="user.profilePictureURL || ''"
          class="my-avatar"
        ></ngx-avatars>
        <span>{{ user.firstName + ' ' + user.lastName }}</span>
      </div>

      <mat-chip-list aria-label="Dog selection">
        <mat-chip
          class="role-chip"
          *ngFor="let roleName of getUserSortedRoles(user)"
          [ngStyle]="{ 'background-color': getRoleColor(roleName) }"
        >
          <div matChipAvatar class="div-role-icon">
            <fa-icon
              [icon]="getRoleIcon(roleName)"
              class="fa-role-icon"
            ></fa-icon>
          </div>
          <span>{{ roleName | i18nextCap }}</span>
        </mat-chip>
      </mat-chip-list>
    </mat-card>
  </div>
</crm-container>
