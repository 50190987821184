import { ISerialisedUnsavedTicketRequestPart } from './serialisations/ISerialisedTicketRequestPart';
import { UnsavedPart } from './UnsavedPart';

export class UnsavedTicketRequestPart {
  constructor(readonly part: UnsavedPart, readonly quantity: number) {}

  static fromSerialised(serialised: ISerialisedUnsavedTicketRequestPart) {
    const part = UnsavedPart.fromSerialised(serialised.part);

    return new UnsavedTicketRequestPart(part, serialised.quantity);
  }

  toSerialised(): ISerialisedUnsavedTicketRequestPart {
    const partObj = this.part.toSerialised();

    return {
      part: partObj,
      quantity: this.quantity,
    };
  }
  clone(
    partialUnsavedTicketRequestPart: PartialUnsavedTicketRequestPart
  ): UnsavedTicketRequestPart {
    const resolve = (key: keyof UnsavedTicketRequestPart) =>
      partialUnsavedTicketRequestPart.hasOwnProperty(key)
        ? partialUnsavedTicketRequestPart[key]
        : this[key];

    return new UnsavedTicketRequestPart(resolve('part'), resolve('quantity'));
  }
}
type PartialUnsavedTicketRequestPart = Partial<
  Pick<UnsavedTicketRequestPart, 'part' | 'quantity'>
>;
