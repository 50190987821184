import { faUser } from '@fortawesome/free-solid-svg-icons';
import { Component, Input } from '@angular/core';
import { User } from '@fullyops/legacy/data';

@Component({
  selector: 'user-profile-image-component',
  templateUrl: './user-profile-image.component.html',
  styleUrls: ['./user-profile-image.component.scss'],
})
export class UserProfileImageComponent {
  faUser = faUser;
  @Input() user: User | null | any;
  @Input() size = 38;
  @Input() textOnly = false;
  @Input() includeBorder = false;

  getUserFullName(assignee: User) {
    return `${assignee.firstName} ${assignee.lastName}`;
  }
}
