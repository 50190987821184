import { Injectable } from '@angular/core';
import {
  Activity,
  UnsavedActivity,
  ActivitiesEndpoint,
  Response,
  TicketType,
} from '@fullyops/legacy/data';
import { Observable } from 'rxjs';
import { UiService } from './ui.service';

@Injectable({
  providedIn: 'root',
})
export class UiActivitiesService extends UiService<
  Activity,
  UnsavedActivity,
  ActivitiesEndpoint
> {
  constructor(protected activitiesEndpoint: ActivitiesEndpoint) {
    super(activitiesEndpoint.createStore(), activitiesEndpoint);
  }

  getAllByAssigneeId(id: string): Observable<Response<Activity[]>> {
    return this.activitiesEndpoint.getAll(
      { assignee: id },
      undefined,
      this.store$
    );
  }

  getAllByCompanyId(id: string): Observable<Response<Activity[]>> {
    return this.activitiesEndpoint.getAll(
      { company: id },
      undefined,
      this.store$
    );
  }

  getAllByContactId(id: string): Observable<Response<Activity[]>> {
    return this.activitiesEndpoint.getAll(
      { contact: id },
      undefined,
      this.store$
    );
  }

  getAllByTicketId(
    type: TicketType,
    id: string
  ): Observable<Response<Activity[]>> {
    return this.activitiesEndpoint.getAll(
      { ticketType: type, ticketId: id },
      undefined,
      this.store$
    );
  }
}
