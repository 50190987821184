<div class="internal-server-error">
  <h1 class="title">Woops!</h1>
  <h2 class="sub-title">Something went wrong :(</h2>
  <p class="error-code text-align-center">ERR_INTERNAL_ERROR</p>
  <p class="details text-align-center">
    Sorry, something went wrong. We are currently trying to fix the problem.
    Reach out to support or:
  </p>
  <button class="submit btn-flip" (click)="restartApp()">
    Refresh the page
  </button>
</div>
