<mat-expansion-panel>
  <mat-expansion-panel-header>
    <mat-panel-title>
      {{ listItem.product.name }}
      <label>{{
        'productHasNConfigurations'
          | i18next: { count: listItem.configurations.length }
      }}</label>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <ng-template matExpansionPanelContent>
    <div class="product-config-container">
      <crm-product-configuration-list-card
        *ngFor="let productConfig of listItem.configurations"
        [productConfig]="productConfig"
        (openConfigurationEvent)="openConfigurationEvent.emit($event)"
      ></crm-product-configuration-list-card>
    </div>
  </ng-template>
</mat-expansion-panel>
