import {
  ISerialisedProductConfigSimulation,
  ISerialisedProductConfigSimulationListItem,
} from './serialisations/ISerialisedProductConfigSimulation';
import { Product } from './Product';
import { ProductConfigSimulation } from './ProductConfigSimulation';

export class ProductConfigSimulationListItem {
  constructor(
    readonly configurations: ProductConfigSimulation[],
    readonly product: Product
  ) {}

  static fromSerialised(
    serialised: ISerialisedProductConfigSimulationListItem
  ) {
    const configurations = serialised.configurations.map(
      ProductConfigSimulation.fromSerialised
    );
    const product = Product.fromSerialised(serialised.product);

    return new ProductConfigSimulationListItem(configurations, product);
  }

  toSerialised(): ISerialisedProductConfigSimulationListItem {
    const configurationsObj = this.configurations.map((config) =>
      config.toSerialised()
    );
    const productObj = this.product.toSerialised();

    return {
      configurations: configurationsObj,
      product: productObj,
    };
  }

  clone(
    partialProduct: PartialProductConfigSimulationListItem
  ): ProductConfigSimulationListItem {
    const resolve = (key: keyof ProductConfigSimulationListItem) =>
      partialProduct.hasOwnProperty(key) ? partialProduct[key] : this[key];

    return new ProductConfigSimulationListItem(
      resolve('configurations'),
      resolve('product')
    );
  }
}

type PartialProductConfigSimulationListItem = Partial<
  Pick<ProductConfigSimulationListItem, 'configurations' | 'product'>
>;
