import {
  InterventionPatchRequest,
  InterventionPostRequest,
  InterventionResponse,
} from '@fullyops/legacy/data/api/types/Intervention';
import { PageResponseWrapper } from '@fullyops/legacy/data/api/types/HttpResponses';
import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { API_SERVICE_CONFIG } from '@fullyops/legacy/data/api/ApiServiceConfig';
import { IApiServiceConfig } from '@fullyops/legacy/data/api/IApiServiceConfig';

type QueryParameters = {
  createdBy?: string;
  description?: string;
  includeDeleted?: boolean;
  installationTicketId?: string;
  internalObservation?: string;
  order?: 'ASC' | 'DESC';
  orderBy?:
    | 'CREATED_AT'
    | 'UPDATED_AT'
    | 'UUID'
    | 'DESCRIPTION'
    | 'INTERNAL_OBSERVATION'
    | 'USER_OBSERVATION'
    | 'CLOSED_AT'
    | 'UNREGISTERED_PARTS';
  page?: number;
  size?: number;
  supportTicketId?: string;
  unregisteredParts?: string;
  updatedBy?: string;
  userObservation?: string;
};

@Injectable({
  providedIn: 'root',
})
export class InterventionControllerV2 {
  constructor(
    @Inject(API_SERVICE_CONFIG) private config: IApiServiceConfig,
    private http: HttpClient
  ) {}

  private readonly url = this.config.url + 'interventions';

  private createQueryParams = (params) => {
    return Object.keys(params)
      .map((k) => `${k}=${encodeURI(params[k])}`)
      .join('&');
  };

  getInterventions(params: { queryParameters?: QueryParameters }) {
    let query = '';
    if (params.queryParameters) {
      const queries = this.createQueryParams(params.queryParameters);
      query = `?${queries}`;
    }

    return this.http.get<PageResponseWrapper<InterventionResponse>>(
      `${this.url}${query}`
    );
  }

  createIntervention(params: { intervention: InterventionPostRequest }) {
    return this.http.post<InterventionResponse>(this.url, params.intervention);
  }

  getInterventionById(params: { id: string }) {
    return this.http.get<InterventionResponse>(`${this.url}/${params.id}`);
  }

  deleteInterventionById(params: { id: string }) {
    return this.http.delete(`${this.url}/${params.id}`);
  }

  updateInterventionById(params: { intervention: InterventionPatchRequest }) {
    return this.http.patch<InterventionResponse>(
      `${this.url}/${params.intervention.id}`,
      params.intervention
    );
  }
}
