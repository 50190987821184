import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ActionButton } from '../utils/crm-types';

@Component({
  selector: 'crm-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListComponent implements OnInit {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() createEntryLabel: string;
  @Input() emptyListLabel: string;
  @Input() actionButtons?: ActionButton[];
  @Input() list$: BehaviorSubject<
    { id: string; name: string; icon?: string; color?: string }[]
  >;
  @Output() createEntryEvent = new EventEmitter();
  @Output() actionEvent = new EventEmitter<{ action: string; id: string }>();

  ngOnInit() {}

  fireAction(id: string, action: string) {
    this.actionEvent.emit({ action, id });
  }
}
