import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CalendarAdjustmentService {
  private calendarComponent: any;

  registerCalendarComponent(calendarComponent: any) {
    this.calendarComponent = calendarComponent;
  }

  adjustCalendarSize() {
    if (this.calendarComponent) {
      this.calendarComponent.updateCalendarDimensions();
    }
  }
}
