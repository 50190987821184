<div class="list" *ngIf="filteredItems$ | async as filteredItems">
  <div class="mdc-layout-grid" matSort (matSortChange)="sortData($event)">
    <div class="header mdc-layout-grid__inner full-width">
      <div
        *ngFor="let column of columns"
        [mat-sort-header]="column.key"
        [ngClass]="[
          'mdc-layout-grid__cell',
          'mdc-layout-grid__cell--span-' + desktopColumnSize + '-desktop',
          'mdc-layout-grid__cell--span-' + mobileColumnSize + '-phone'
        ]"
      >
        {{ column.label | i18nextCap }}
      </div>
    </div>
    <div
      class="body"
      infiniteScroll
      [infiniteScrollDistance]="0"
      [infiniteScrollThrottle]="50"
      [scrollWindow]="false"
      (scrolled)="onLoadMore()"
    >
      <div
        *ngFor="let item of filteredItems"
        [ngClass]="[
          'line mdc-layout-grid__inner',
          item.background ? item.background : 'grey-50'
        ]"
      >
        <div
          *ngFor="let column of columns; index as i"
          [class.align-center]="i"
          [ngClass]="[
            'mdc-layout-grid__cell',
            'mdc-layout-grid__cell--span-' + desktopColumnSize + '-desktop',
            'mdc-layout-grid__cell--span-' + mobileColumnSize + '-phone'
          ]"
          (click)="onOpen(item.id)"
        >
          <ng-container *ngIf="!i && item.rowType">
            <ng-container [ngSwitch]="item.rowType.value">
              <mat-icon
                *ngSwitchCase="ActivityType.CALL"
                aria-hidden="false"
                aria-label="call"
                >call</mat-icon
              >
              <mat-icon
                *ngSwitchCase="ActivityType.EMAIL"
                aria-hidden="false"
                aria-label="email"
                >email</mat-icon
              >
              <mat-icon
                *ngSwitchCase="ActivityType.REMINDER"
                aria-hidden="false"
                aria-label="REMINDER"
                >timelapse</mat-icon
              >
              <mat-icon
                *ngSwitchCase="ActivityType.MEETING"
                aria-hidden="false"
                aria-label="meeting"
                >people</mat-icon
              >
              <mat-icon
                *ngSwitchCase="ActivityType.TASK"
                aria-hidden="false"
                aria-label="task"
                >assignment</mat-icon
              >
            </ng-container>
          </ng-container>
          <ng-container [ngSwitch]="column.type">
            <ng-container *ngSwitchCase="columnType.Label">
              <label [ngClass]="['mat-label', item.columns[column.key].color]">
                {{ 'forms:' + item.columns[column.key].value | i18nextCap }}
              </label>
            </ng-container>
            <ng-container *ngSwitchCase="columnType.Text">
              <span [class.align-center]="item.rowType">{{
                item.columns[column.key].value
              }}</span>
            </ng-container>
            <ng-container *ngSwitchCase="columnType.Enum">
              <span>{{ item.columns[column.key].value | i18nextCap }}</span>
            </ng-container>
            <ng-container *ngSwitchCase="columnType.Number">
              {{ item.columns[column.key].value }}
            </ng-container>
            <ng-container *ngSwitchCase="columnType.Price">
              <ng-container *ngIf="item.columns[column.key].value === 0">
                -
              </ng-container>
              <ng-container *ngIf="item.columns[column.key].value !== 0">
                {{ item.columns[column.key].value }} €
              </ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="columnType.Date">
              <ng-container *ngIf="item.columns[column.key].value !== 0">
                {{ item.columns[column.key].value | date: 'd MMMM y' }}
              </ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="columnType.User">
              <span
                *ngIf="item.columns[column.key].value !== '-'"
                aria-hidden="false"
                aria-label="request-ticket"
                class="user-icon"
              >
                <img src="assets/svg/fo-user.svg" alt="User Icon" />
              </span>
              <span>{{ item.columns[column.key].value | titlecase }}</span>
            </ng-container>
            <ng-container *ngSwitchCase="columnType.Identifier">
              <mat-icon
                *ngIf="item.columns[column.key].warranty !== 'false'"
                aria-hidden="false"
                aria-label="request-ticket"
                >star</mat-icon
              >
              <span>{{ item.columns[column.key].value | titlecase }}</span>
            </ng-container>
            <ng-container *ngSwitchCase="columnType.Company">
              <mat-icon aria-hidden="false" aria-label="request-ticket"
                >home_repair_service</mat-icon
              >
              {{ item.columns[column.key].value | titlecase }}
            </ng-container>
            <ng-container *ngSwitchCase="columnType.Request">
              <mat-icon aria-hidden="false" aria-label="request-ticket"
                >local_shipping</mat-icon
              >
              {{ item.columns[column.key].value | titlecase }}
            </ng-container>
            <ng-container *ngSwitchCase="columnType.Array">
              <label
                *ngFor="let label of item.columns[column.key].value"
                class="mat-label"
                [ngClass]="[label.color ? label.color : 'grey-100']"
              >
                {{ label.label | i18nextCap }}
              </label>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
