<h2 mat-dialog-title>{{ data.title | i18nextCap }}</h2>
<mat-dialog-content class="mat-typography">
  <h3>{{ data.message | i18nextCap }}</h3>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button color="warn" mat-dialog-close>
    {{ 'cancel' | i18nextCap }}
  </button>
  <button mat-button color="primary" [mat-dialog-close]="true" cdkFocusInitial>
    {{ 'save' | i18nextCap }}
  </button>
</mat-dialog-actions>
