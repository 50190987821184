import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SelectOptionModel } from '../models/form-model.models';
import { WorkOrderScopesService } from '@fullyops/domain/work-order-scopes/services/work-order-scopes.service';

@Injectable({ providedIn: 'root' })
export class WorkOrderScopesUtils {
  constructor(private service: WorkOrderScopesService) {}

  scopesOptions$ = new BehaviorSubject<SelectOptionModel[]>([]);

  getOptions$() {
    this.service.getAll().subscribe((scopes) => {
      const options: SelectOptionModel[] = scopes.map((scope) => {
        return {
          id: scope.label,
          name: scope.label,
        };
      });
      this.scopesOptions$.next(options);
    });

    return this.scopesOptions$;
  }
}
