import { UiServiceV2 } from './ui.service-v2.service';
import { PageResponseWrapper } from '../../data/api/types/HttpResponses';
import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { API_SERVICE_CONFIG } from '../../data/api/ApiServiceConfig';
import { IApiServiceConfig } from '../../data/api/IApiServiceConfig';
import {
  UncataloguedPartPatchRequest,
  UncataloguedPartPostRequest,
  UncataloguedPartResponse,
} from '../../data/api/types/UncataloguedPart';
import { BehaviorSubject } from 'rxjs';
import { SelectOptionType } from '../ui-shared/form-select/form-select.component';

export type QueryParameters = {
  createdBy?: string;
  includeDeleted?: boolean;
  name?: string;
  order?: 'ASC' | 'DESC';
  orderBy?: 'CREATED_AT' | 'UPDATED_AT' | 'UUID' | 'NAME';
  page?: number;
  size?: number;
  updatedBy?: string;
};

@Injectable({
  providedIn: 'root',
})
export class PartUncataloguedControllerV2 extends UiServiceV2 {
  constructor(
    @Inject(API_SERVICE_CONFIG) private config: IApiServiceConfig,
    private http: HttpClient
  ) {
    super();
    this.onListUpdate_UpdateListOptions();
  }

  private readonly url = this.config.url + 'uncatalogued/parts';
  private list$: BehaviorSubject<Array<UncataloguedPartResponse>> =
    new BehaviorSubject([]);
  private listOptions$: BehaviorSubject<Array<SelectOptionType>> =
    new BehaviorSubject([]);

  getAll(params: { queryParameters?: QueryParameters }) {
    let query = '';
    if (params.queryParameters) {
      const queries = this.createQueryParams(params.queryParameters);
      query = `?${queries}`;
    }

    const request = this.http.get<
      PageResponseWrapper<UncataloguedPartResponse>
    >(`${this.url}${query}`);

    request.subscribe((res) => {
      this.list$.next(res.results);
    });

    return request;
  }

  create(params: { newFreePart: UncataloguedPartPostRequest }) {
    return this.http.post<UncataloguedPartResponse>(
      this.url,
      params.newFreePart
    );
  }

  getById(params: { id: string }) {
    return this.http.get<UncataloguedPartResponse>(`${this.url}/${params.id}`);
  }

  deleteById(params: { id: string }) {
    return this.http.delete(`${this.url}/${params.id}`);
  }

  updateById(params: {
    id: string;
    updatedFreePart: UncataloguedPartPatchRequest;
  }) {
    return this.http.patch<UncataloguedPartResponse>(
      `${this.url}/${params.id}`,
      params.updatedFreePart
    );
  }

  getUncataloguedPartsOptionsList$(queryParameters?: QueryParameters) {
    this.getAll({ queryParameters });
    return this.listOptions$;
  }

  onListUpdate_UpdateListOptions() {
    this.list$.subscribe((items) =>
      this.listOptions$.next(this.formatToOption(items))
    );
  }

  private formatToOption(
    items: UncataloguedPartResponse[]
  ): Array<SelectOptionType> {
    return items.map((item) => {
      return {
        id: item.id,
        name: item.name,
      };
    });
  }
}
