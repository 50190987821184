<section>
  <header>
    <p>
      {{ 'forms:' + title | i18nextCap }}
      <button
        *ngIf="!preview && createEntryLabel"
        mat-flat-button
        type="button"
        color="primary"
        (click)="addTable()"
      >
        {{ 'forms:addTable' | i18nextCap }}
      </button>
    </p>
    <p class="subheader">{{ 'forms:' + subtitle | i18nextCap }}</p>
  </header>

  <div
    class="table"
    *ngFor="
      let tableSection of technicalInfoForm.controls;
      let tableIndex = index
    "
    [formGroup]="$any(tableSection)"
  >
    <mat-form-field>
      <mat-label>{{ 'forms:tableName' | i18nextCap }}</mat-label>
      <input matInput formControlName="name" />
      <mat-error>{{ 'validation:youMustEnterAValue' | i18nextCap }}</mat-error>
    </mat-form-field>
    <div class="table-container">
      <table class="form-table">
        <tr>
          <th
            *ngFor="
              let cell of $any(tableSection).controls['table'].controls[0]
                .controls;
              let index = index
            "
          >
            {{ 'forms:column' | i18nextCap }} {{ index + 1 }}
            <button
              *ngIf="!preview"
              mat-icon-button
              type="button"
              color="warn"
              matTooltip="{{ 'forms:removeColumn' | i18nextCap }}"
              (click)="removeColumn(tableIndex, index)"
            >
              <mat-icon>clear</mat-icon>
            </button>
          </th>
        </tr>
        <tr
          *ngFor="
            let row of $any(tableSection).controls['table'].controls;
            let index = index
          "
        >
          <td *ngFor="let cell of row.controls">
            <mat-form-field class="d-block">
              <input matInput [formControl]="cell" />
              <mat-error>{{
                'validation:youMustEnterAValue' | i18nextCap
              }}</mat-error>
            </mat-form-field>
          </td>
          <td class="action-column" *ngIf="!preview">
            <button
              mat-icon-button
              type="button"
              color="warn"
              matTooltip="{{ 'forms:removeLine' | i18nextCap }}"
              (click)="removeLine(tableIndex, index)"
            >
              <mat-icon>clear</mat-icon>
            </button>
          </td>
        </tr>
      </table>
    </div>
    <ng-container *ngIf="!preview">
      <button
        mat-flat-button
        type="button"
        color="primary"
        (click)="addLine(tableIndex)"
      >
        {{ 'forms:addLine' | i18nextCap }}
      </button>
      <button
        mat-flat-button
        type="button"
        color="primary"
        (click)="addColumn(tableIndex)"
      >
        {{ 'forms:addColumn' | i18nextCap }}
      </button>
      <button
        mat-flat-button
        type="button"
        color="warn"
        (click)="removeTable(tableIndex)"
      >
        {{ 'forms:removeTable' | i18nextCap }}
      </button>
    </ng-container>
  </div>
</section>
