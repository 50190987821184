import { Response } from '../Response';
import { Endpoint } from '../Endpoint';
import { HttpClient } from '@angular/common/http';
import { IListResponse } from '../IListResponse';
import { Injectable, Inject } from '@angular/core';
import { API_SERVICE_CONFIG } from '../ApiServiceConfig';
import { IApiServiceConfig } from '../IApiServiceConfig';

import { Observable, BehaviorSubject, of } from 'rxjs';

import { TicketRequest } from '../../models/v0/TicketRequest';
import { UnsavedTicketRequest } from '../../models/v0/UnsavedTicketRequest';
import { ISerialisedTicketRequest } from '../../models/v0/serialisations/ISerialisedTicketRequest';
import { tap, map } from 'rxjs/operators';

type TicketRequestList = IListResponse<ISerialisedTicketRequest>;

@Injectable()
export class TicketsRequestEndpoint extends Endpoint<
  TicketRequest,
  ISerialisedTicketRequest
> {
  private readonly url = this.config.url + 'tickets/requests/';

  constructor(
    @Inject(API_SERVICE_CONFIG) private config: IApiServiceConfig,
    private http: HttpClient
  ) {
    super(TicketRequest.fromSerialised);
  }

  getAll(
    filters: {},
    source?: Observable<TicketRequestList>,
    store?: BehaviorSubject<Response<TicketRequest[]>>
  ): Observable<Response<TicketRequest[]>> {
    let query = '';
    Object.keys(filters).forEach((key) => (query += `&${key}=${filters[key]}`));

    const defaultSource = this.http.get<TicketRequestList>(
      `${this.url}?page=0${query}`
    );

    return this.list(source || defaultSource, store);
  }

  get(
    id: string,
    source?: Observable<ISerialisedTicketRequest>,
    store?: BehaviorSubject<Response<TicketRequest[]>>
  ): Observable<Response<TicketRequest>> {
    const defaultSource = this.http.get<ISerialisedTicketRequest>(
      `${this.url}${id}/`
    );
    return this.retrieve(source || defaultSource, store);
  }

  post(
    ticketRequest: UnsavedTicketRequest,
    source?: Observable<ISerialisedTicketRequest>,
    store?: BehaviorSubject<Response<TicketRequest[]>>
  ): Observable<Response<TicketRequest>> {
    const defaultSource = this.http.post<ISerialisedTicketRequest>(
      this.url,
      ticketRequest.toSerialised()
    );
    return this.add(source || defaultSource, store);
  }

  patch(
    id: string,
    ticketRequest: UnsavedTicketRequest,
    source?: Observable<ISerialisedTicketRequest>,
    store?: BehaviorSubject<Response<TicketRequest[]>>
  ) {
    const body = ticketRequest.toSerialised();
    const defaultSource = this.http.patch<ISerialisedTicketRequest>(
      `${this.url}${id}/`,
      body
    );

    return defaultSource.pipe(
      tap((instance) =>
        this.update(
          source || defaultSource,
          TicketRequest.fromSerialised(instance),
          store
        )
      ),
      map(
        (serialiser) => new Response(TicketRequest.fromSerialised(serialiser))
      )
    );
  }

  delete(
    id: string,
    source?: Observable<{}>,
    store?: BehaviorSubject<Response<TicketRequest[]>>
  ): Observable<Response<{}>> {
    const defaultSource = this.http.delete<{}>(`${this.url}${id}/`);
    return this.remove(source || defaultSource, id, store);
  }

  email(id: string): Observable<Response<TicketRequest>> {
    const defaultSource = this.http.post<ISerialisedTicketRequest>(
      `${this.url}${id}/email`,
      {}
    );
    return defaultSource.pipe(
      map((ticket) => new Response(TicketRequest.fromSerialised(ticket)))
    );
  }
}
