<mat-card class="stock-card" *ngIf="part && part.part">
  <mat-card-content>
    <div class="left">
      <div class="user-icon gray-icon">
        <fa-icon [icon]="faScrewdriverWrench" size="lg"></fa-icon>
      </div>
      {{ part.part.name }}
    </div>

    <div class="right">
      <div class="stock-item-card-quantity">
        <!-- <input mat-input type="number" [formControl]="quantity" /> -->
        <mat-form-field class="no-padding-bottom stock-quantity-input">
          <mat-label>{{ 'forms:quantity' | i18nextCap }}</mat-label>
          <input matInput type="number" [formControl]="quantity" />
        </mat-form-field>
      </div>

      <div>
        <button
          mat-icon-button
          *ngIf="quantity.disabled"
          (click)="toggleIsEditing()"
        >
          <div class="user-icon blue-icon">
            <fa-icon [icon]="faPen" size="lg"></fa-icon>
          </div>
        </button>

        <button
          mat-icon-button
          *ngIf="!quantity.disabled"
          (click)="updateUserPart()"
        >
          <div class="user-icon green-icon">
            <fa-icon [icon]="faFloppyDisk" size="lg"></fa-icon>
          </div>
        </button>
      </div>

      <div>
        <button
          mat-icon-button
          *ngIf="quantity.disabled"
          (click)="deleteUserPartClick()"
        >
          <div class="user-icon red-icon">
            <fa-icon [icon]="faTrashCan" size="lg"></fa-icon>
          </div>
        </button>

        <button
          mat-icon-button
          *ngIf="!quantity.disabled"
          (click)="revert($event)"
        >
          <div class="user-icon blue-icon">
            <fa-icon [icon]="faArrowRotateRight" size="lg"></fa-icon>
          </div>
        </button>
      </div>
    </div>
  </mat-card-content>
</mat-card>
