<div>
  <h1>{{ 'forms:subProducts' | i18nextCap }}</h1>

  <mat-card
    class="sub-product"
    *ngFor="let subProduct of subProducts; let subProductIndex = index"
  >
    <mat-card-header class="sub-product-header">
      <mat-card-title>{{ subProduct.name }}</mat-card-title>
      <mat-card-subtitle>
        {{ 'translation:thisSubProductHas' | i18nextCap }}
        {{ subProduct.variants.length }}
        {{ 'translation:variants' | i18nextCap }}
      </mat-card-subtitle>
      <span style="flex: 1 1 auto"></span>
      <mat-card-actions>
        <button
          mat-flat-button
          type="button"
          (click)="selectSubProduct(subProductIndex)"
        >
          <mat-icon *ngIf="subProductIndex !== selectedSubProduct"
            >keyboard_arrow_down</mat-icon
          >
          <mat-icon *ngIf="subProductIndex === selectedSubProduct"
            >keyboard_arrow_up</mat-icon
          >
        </button>
      </mat-card-actions>
    </mat-card-header>

    <mat-card-content *ngIf="subProductIndex === selectedSubProduct">
      <crm-sub-product-variants
        [preview]="true"
        [variants]="subProduct.variants"
        (editVariantEvent)="editVariant(subProduct.id, $event)"
      ></crm-sub-product-variants>
      <mat-card-actions>
        <button
          mat-flat-button
          color="primary"
          type="button"
          (click)="editSupProduct(subProduct.id)"
        >
          {{ 'editSubProduct' | i18nextCap }}
        </button>
        <button
          mat-flat-button
          color="warn"
          type="button"
          (click)="deleteSupProduct(subProduct.id)"
        >
          {{ 'removeSubProduct' | i18nextCap }}
        </button>
      </mat-card-actions>
    </mat-card-content>
  </mat-card>
  <button
    mat-flat-button
    color="primary"
    type="button"
    (click)="newSupProduct()"
  >
    {{ 'newSubProduct' | i18nextCap }}
  </button>
</div>
