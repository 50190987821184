import { Response } from '../Response';
import { Endpoint } from '../Endpoint';
import { HttpClient } from '@angular/common/http';
import { IListResponse } from '../IListResponse';
import { Injectable, Inject } from '@angular/core';
import { API_SERVICE_CONFIG } from '../ApiServiceConfig';
import { IApiServiceConfig } from '../IApiServiceConfig';

import { Observable, BehaviorSubject } from 'rxjs';

import { Product } from '../../models/v0/Product';
import { UnsavedProduct } from '../../models/v0/UnsavedProduct';
import { ISerialisedProduct } from '../../models/v0/serialisations/ISerialisedProduct';
import { tap, map } from 'rxjs/operators';

type ProductList = IListResponse<ISerialisedProduct>;

@Injectable()
export class ProductsEndpoint extends Endpoint<Product, ISerialisedProduct> {
  private readonly url = this.config.url + 'products/';

  constructor(
    @Inject(API_SERVICE_CONFIG) private config: IApiServiceConfig,
    private http: HttpClient
  ) {
    super(Product.fromSerialised);
  }

  getAll(
    filter?: {},
    source?: Observable<ProductList>,
    store?: BehaviorSubject<Response<Product[]>>
  ): Observable<Response<Product[]>> {
    const defaultSource = this.http.get<ProductList>(this.url);
    return this.list(source || defaultSource, store);
  }

  get(
    id: string,
    source?: Observable<ISerialisedProduct>,
    store?: BehaviorSubject<Response<Product[]>>
  ): Observable<Response<Product>> {
    const defaultSource = this.http.get<ISerialisedProduct>(
      `${this.url}${id}/`
    );
    return this.retrieve(source || defaultSource, store);
  }

  post(
    product: UnsavedProduct,
    source?: Observable<ISerialisedProduct>,
    store?: BehaviorSubject<Response<Product[]>>
  ): Observable<Response<Product>> {
    const { ...body } = product.toSerialised();
    const defaultSource = this.http.post<ISerialisedProduct>(this.url, {
      ...body,
      labels: body.labels.map((label) => label.id),
    });
    return this.add(source || defaultSource, store);
  }

  patch(
    id: string,
    product: UnsavedProduct,
    source?: Observable<ISerialisedProduct>,
    store?: BehaviorSubject<Response<Product[]>>
  ) {
    const body = product.toSerialised();
    const defaultSource = this.http.patch<ISerialisedProduct>(
      `${this.url}${id}/`,
      { ...body, labels: body.labels.map((label) => label.id) }
    );

    return defaultSource.pipe(
      tap((instance) =>
        this.update(
          source || defaultSource,
          Product.fromSerialised(instance),
          store
        )
      ),
      map((serialiser) => new Response(Product.fromSerialised(serialiser)))
    );
  }

  delete(
    id: string,
    source?: Observable<{}>,
    store?: BehaviorSubject<Response<Product[]>>
  ): Observable<Response<{}>> {
    const defaultSource = this.http.delete<{}>(`${this.url}${id}/`);
    return this.remove(source || defaultSource, id, store);
  }
}
