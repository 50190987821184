<div [@inOut]="'in'" [class.fullscreen]="fullscreen">
  <p class="empty-message primary-color">
    {{ 'error:' + message | i18nextCap }}
    <button
      *ngIf="actionLabel"
      mat-flat-button
      color="primary"
      type="button"
      (click)="onAction()"
      [disabled]="isDisabled"
    >
      {{ 'forms:' + actionLabel | i18nextCap }}
    </button>
  </p>
</div>
