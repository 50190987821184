import { ISerialisedTravelAssignee } from './serialisations/ISerialisedTravelAssignee';
import { User } from './User';

export class TravelAssignee {
  constructor(
    readonly assignee: User,
    readonly createdAt: string,
    readonly createdBy: User,
    readonly travelId: string,
    readonly updatedAt: string,
    readonly updatedBy: User
  ) {}

  static fromSerialised(serialised: ISerialisedTravelAssignee) {
    const assignee = User.fromSerialised(serialised.assignee);
    const createdBy = User.fromSerialised(serialised.createdBy);
    const updatedBy = User.fromSerialised(serialised.updatedBy);

    return new TravelAssignee(
      assignee,
      serialised.createdAt,
      createdBy,
      serialised.travelId,
      serialised.updatedAt,
      updatedBy
    );
  }

  toSerialised(): ISerialisedTravelAssignee {
    const assigneeObj = this.assignee.toSerialised();
    const createdByObj = this.createdBy.toSerialised();
    const updatedByObj = this.updatedBy.toSerialised();

    return {
      ...this,
      assignee: assigneeObj,
      createdBy: createdByObj,
      updatedBy: updatedByObj,
    };
  }

  clone(partialTravelAssignee: PartialTravelAssignee): TravelAssignee {
    const resolve = (key: keyof TravelAssignee) =>
      partialTravelAssignee.hasOwnProperty(key)
        ? partialTravelAssignee[key]
        : this[key];

    return new TravelAssignee(
      resolve('assignee'),
      resolve('createdAt'),
      resolve('createdBy'),
      resolve('travelId'),
      resolve('updatedAt'),
      resolve('updatedBy')
    );
  }
}
type PartialTravelAssignee = Partial<
  Pick<
    TravelAssignee,
    | 'assignee'
    | 'createdAt'
    | 'createdBy'
    | 'travelId'
    | 'updatedAt'
    | 'updatedBy'
  >
>;
