import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { LoadingService } from '@fullyops/legacy/ui/ui-shared/loading/loading.service';

@Injectable()
export class LoadingHTTPRequestInterceptor implements HttpInterceptor {
  private totalRequests = 0;

  constructor(public loadingService: LoadingService) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.totalRequests++;

    const getShowSpinnerValue = () => {
      return this.loadingService.showSpinner.getValue();
    };

    if (!getShowSpinnerValue() && this.totalRequests == 1) {
      this.loadingService.showSpinner.next(true);
    }

    const onRequestHandle = () => {
      this.totalRequests--;
      if (this.totalRequests == 0 && getShowSpinnerValue()) {
        this.loadingService.showSpinner.next(false);
      }
    };

    return next.handle(req).pipe(
      tap({
        // complete: () => onRequestHandle(),
        error: () => {
          onRequestHandle();
        },
        next: (value) => {
          if (value instanceof HttpResponse) {
            onRequestHandle();
          }
        },
      })
    );
  }
}
