import { UiServiceV2 } from './ui.service-v2.service';
import { PageResponseWrapper } from '../../data/api/types/HttpResponses';
import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { API_SERVICE_CONFIG } from '../../data/api/ApiServiceConfig';
import { IApiServiceConfig } from '../../data/api/IApiServiceConfig';
import {
  PartPatchRequest,
  PartPostRequest,
  PartResponse,
} from '../../data/api/types/Part';
import { BehaviorSubject } from 'rxjs';
import { SelectOptionType } from '../ui-shared/form-select/form-select.component';

export type QueryParameters = {
  createdBy?: string;
  includeDeleted?: boolean;
  name?: string;
  order?: 'ASC' | 'DESC';
  orderBy?: string;
  page?: number;
  reference?: string;
  size?: number;
  supplier?: string;
  updatedBy?: string;
};

@Injectable({
  providedIn: 'root',
})
export class PartControllerV2 extends UiServiceV2 {
  constructor(
    @Inject(API_SERVICE_CONFIG) private config: IApiServiceConfig,
    private http: HttpClient
  ) {
    super();
    this.onListUpdate_UpdateListOptions();
  }

  private readonly url = this.config.url + 'parts';
  private list$: BehaviorSubject<Array<PartResponse>> = new BehaviorSubject([]);
  private listOptions$: BehaviorSubject<Array<SelectOptionType>> =
    new BehaviorSubject([]);

  getAll(params: { queryParameters?: QueryParameters }) {
    let query = '';
    if (params.queryParameters) {
      const queries = this.createQueryParams(params.queryParameters);
      query = `?${queries}`;
    }

    const request = this.http.get<PageResponseWrapper<PartResponse>>(
      `${this.url}/all${query}`
    );

    request.subscribe((res) => {
      this.list$.next(res.results);
    });

    return request;
  }

  getPartById(params: { id: string }) {
    return this.http.get<PartResponse>(`${this.url}/${params.id}`);
  }

  createPart(params: { newPart: PartPostRequest }) {
    return this.http.post<PartResponse>(this.url, params.newPart);
  }

  updatePartById(params: { updatedPart: PartPatchRequest; id: string }) {
    return this.http.patch<PartResponse>(
      `${this.url}/${params.id}`,
      params.updatedPart
    );
  }

  deletePartById(params: { id: string }) {
    return this.http.delete(`${this.url}/${params.id}`);
  }

  getPartsOptionsList$(queryParameters?: QueryParameters) {
    this.getAll({ queryParameters });
    return this.listOptions$;
  }

  onListUpdate_UpdateListOptions() {
    this.list$.subscribe((items) =>
      this.listOptions$.next(this.formatToOption(items))
    );
  }

  private formatToOption(items: PartResponse[]): Array<SelectOptionType> {
    return items.map((item) => {
      return {
        id: item.id,
        name: item.name,
        ref: item.ref,
      };
    });
  }
}
