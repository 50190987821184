<div
  class="work-order-actions"
  *ngIf="(showUncataloguedPart$ | async) !== null"
>
  <mat-accordion>
    <ng-container *hasPermission="{ permissions: permissionToCreateAction }">
      <ng-container *ngIf="!isDetailPage">
        <ng-container *ngIf="ticket$ | async as ticket">
          <ng-container *ngIf="ticket">
            <mat-expansion-panel
              [expanded]="openedIndex == -1"
              (opened)="setOpenedIndex(-1)"
              (closed)="setOpenedIndex(-2)"
            >
              <mat-expansion-panel-header>
                <mat-panel-title class="intervention-title">
                  <mat-icon class="green-icon">add_circle</mat-icon>
                  <div *ngIf="ticket.interventionType == 'PREVENTIVE'">
                    {{ 'translation:addPreventiveAction' | i18nextCap }}
                  </div>
                  <div *ngIf="ticket.interventionType == 'GENERAL'">
                    {{ 'translation:addGeneralAction' | i18nextCap }}
                  </div>
                  <div *ngIf="ticket.interventionType == 'CORRECTIVE'">
                    {{ 'translation:addCorrectiveAction' | i18nextCap }}
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>

              <crm-action-corrective-form
                *ngIf="ticket.interventionType == 'CORRECTIVE'"
                [ticket]="ticket"
                [isNew]="true"
                [clientEquipmentListOptions$]="clientEquipmentListOptions$"
                [anomaliesOptions$]="anomaliesOptions$"
                [partOptions$]="partsOptions$"
                [uncataloguedPartsOptions$]="uncataloguedPartsOptions$"
                (openRequestTab)="navigateToRequestPartTab()"
                (createCorrectiveAction)="createCorrectiveAction($event)"
                (createMachineByPopUp)="createMachineByPopUp($event)"
                [index]="-1"
                [showUncataloguedPart]="showUncataloguedPart$ | async"
                (onCreatePart)="createPart($event)"
                (onCreateUncataloguedPart)="createUncataloguedPart($event)"
                (updatePartsList)="initPartsList($event)"
                (updateUncataloguedPartsList)="
                  initUncataloguedPartOptions($event)
                "
              ></crm-action-corrective-form>

              <crm-action-preventive-form
                *ngIf="ticket.interventionType == 'PREVENTIVE'"
                [ticket]="ticket"
                [isNew]="true"
                [clientEquipmentListOptions$]="clientEquipmentListOptions$"
                [partOptions$]="partsOptions$"
                [uncataloguedPartsOptions$]="uncataloguedPartsOptions$"
                (openRequestTab)="navigateToRequestPartTab()"
                (createCorrectiveAction)="createPreventiveAction($event)"
                (createMachineByPopUp)="createMachineByPopUp($event)"
                [index]="-1"
                [showUncataloguedPart]="showUncataloguedPart$ | async"
                (onCreatePart)="createPart($event)"
                (onCreateUncataloguedPart)="createUncataloguedPart($event)"
                (updatePartsList)="initPartsList($event)"
                (updateUncataloguedPartsList)="
                  initUncataloguedPartOptions($event)
                "
              ></crm-action-preventive-form>

              <crm-action-general-form
                *ngIf="ticket.interventionType == 'GENERAL'"
                [ticket]="ticket"
                [isNew]="true"
                [clientEquipmentListOptions$]="clientEquipmentListOptions$"
                [anomaliesOptions$]="anomaliesOptions$"
                [partOptions$]="partsOptions$"
                [uncataloguedPartsOptions$]="uncataloguedPartsOptions$"
                (openRequestTab)="navigateToRequestPartTab()"
                (createGeneralAction)="createGeneralAction($event)"
                (createMachineByPopUp)="createMachineByPopUp($event)"
                [index]="-1"
                [showUncataloguedPart]="showUncataloguedPart$ | async"
                (onCreatePart)="createPart($event)"
                (onCreateUncataloguedPart)="createUncataloguedPart($event)"
                (updatePartsList)="initPartsList($event)"
                (updateUncataloguedPartsList)="
                  initUncataloguedPartOptions($event)
                "
              ></crm-action-general-form>
            </mat-expansion-panel>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>

    <!-- List of Interventions -->
    <ng-container *ngIf="(ticket$ | async).interventions.length > 0">
      <ng-container
        *ngFor="
          let intervention of (ticket$ | async).interventions;
          let i = index
        "
      >
        <mat-expansion-panel
          [expanded]="openedIndex == i"
          (opened)="setOpenedIndex(i)"
          *hasPermission="{
            permissions: permissionToSeeIntervention,
            ownerId: intervention.createdBy?.id
          }"
        >
          <mat-expansion-panel-header>
            <mat-panel-title class="intervention-title">
              <mat-icon [ngClass]="'blue-icon'">{{
                intervention.title ? 'build' : 'warning'
              }}</mat-icon>

              {{ intervention.title }}
            </mat-panel-title>
          </mat-expansion-panel-header>

          <crm-action-corrective-form
            *ngIf="(ticket$ | async).interventionType == 'CORRECTIVE'"
            [ticket]="ticket$ | async"
            [clientEquipmentListOptions$]="clientEquipmentListOptions$"
            [anomaliesOptions$]="anomaliesOptions$"
            [partOptions$]="partsOptions$"
            [uncataloguedPartsOptions$]="uncataloguedPartsOptions$"
            (openRequestTab)="navigateToRequestPartTab()"
            (editCorrectiveAction)="editCorrectiveAction($event)"
            (createMachineByPopUp)="createMachineByPopUp($event)"
            (deleteIntervention)="deleteIntervention(i)"
            [intervention]="intervention"
            [index]="i"
            [showUncataloguedPart]="showUncataloguedPart$ | async"
            [ownerId]="intervention.createdBy?.id"
            (onCreatePart)="createPart($event)"
            (onCreateUncataloguedPart)="createUncataloguedPart($event)"
            (updatePartsList)="initPartsList($event)"
            (updateUncataloguedPartsList)="initUncataloguedPartOptions($event)"
          ></crm-action-corrective-form>

          <crm-action-preventive-form
            *ngIf="(ticket$ | async).interventionType == 'PREVENTIVE'"
            [ticket]="ticket$ | async"
            [clientEquipmentListOptions$]="clientEquipmentListOptions$"
            [partOptions$]="partsOptions$"
            [uncataloguedPartsOptions$]="uncataloguedPartsOptions$"
            (openRequestTab)="navigateToRequestPartTab()"
            (editPreventiveAction)="editPreventiveAction($event)"
            (createMachineByPopUp)="createMachineByPopUp($event)"
            (deleteIntervention)="deleteIntervention(i)"
            [intervention]="intervention"
            [index]="i"
            [showUncataloguedPart]="showUncataloguedPart$ | async"
            [ownerId]="intervention.createdBy?.id"
            (onCreatePart)="createPart($event)"
            (onCreateUncataloguedPart)="createUncataloguedPart($event)"
            (updatePartsList)="initPartsList($event)"
            (updateUncataloguedPartsList)="initUncataloguedPartOptions($event)"
          ></crm-action-preventive-form>

          <crm-action-general-form
            *ngIf="(ticket$ | async).interventionType == 'GENERAL'"
            [ticket]="ticket$ | async"
            [clientEquipmentListOptions$]="clientEquipmentListOptions$"
            [anomaliesOptions$]="anomaliesOptions$"
            [partOptions$]="partsOptions$"
            [uncataloguedPartsOptions$]="uncataloguedPartsOptions$"
            (openRequestTab)="navigateToRequestPartTab()"
            (editGeneralAction)="editGeneralAction($event)"
            (createMachineByPopUp)="createMachineByPopUp($event)"
            (deleteIntervention)="deleteIntervention(i)"
            [intervention]="intervention"
            [index]="i"
            [showUncataloguedPart]="showUncataloguedPart$ | async"
            [ownerId]="intervention.createdBy?.id"
            (onCreatePart)="createPart($event)"
            (onCreateUncataloguedPart)="createUncataloguedPart($event)"
            (updatePartsList)="initPartsList($event)"
            (updateUncataloguedPartsList)="initUncataloguedPartOptions($event)"
          ></crm-action-general-form>
        </mat-expansion-panel>
      </ng-container>
    </ng-container>
  </mat-accordion>
</div>
