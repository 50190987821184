<div class="timeline-event">
  <div class="timeline-date" [class.completed]="activity.completed">
    <time>{{
      activity.startsAt | momentTimezoneToAngularTimezone: 'HH:mm'
    }}</time>
    <span>{{ activity.startsAt | momentTimezoneToAngularTimezone }}</span>
  </div>
  <mat-icon
    [class.teal-500]="activity.activityType === ActivityType.MEETING"
    [class.green-600]="activity.activityType === ActivityType.CALL"
    [class.amber-500]="activity.activityType === ActivityType.REMINDER"
    [class.blue-700]="activity.activityType === ActivityType.TASK"
    [class.red-500]="activity.activityType === ActivityType.EMAIL"
    [class.not-completed]="!activity.completed"
    matTooltip="{{ 'forms:' + activity.activityType | i18nextCap }}"
    matTooltipClass="historyTooltip"
  >
    {{ activity.icon }}
  </mat-icon>
  <div class="timeline-info">
    <label
      class="primary-color"
      matTooltip="{{ 'forms:openActivity' | i18nextCap }}"
      matTooltipClass="historyTooltip"
      [disableLinkIfNotAllowed]="activityPermissions"
      (click)="openActivity(activity.id)"
    >
      {{ activity.name }}
    </label>
    <span *ngIf="!activity.activityLink">{{ 'noTicket' | i18nextCap }}</span>
    <span
      *ngIf="!!activity.activityLink"
      class="link"
      matTooltip="{{ 'forms:openTicket' | i18nextCap }}"
      matTooltipClass="historyTooltip"
      [disableLinkIfNotAllowed]="ticketPermissions"
      (click)="
        openTicket(
          activity.activityLink.ticket.id,
          activity.activityLink.linkType
        )
      "
    >
      [{{
        activity.activityLink.ticket.identifier
          | ticketIdentifier: activity.activityLink.linkType
      }}] {{ activity.activityLink.ticket.name }}
    </span>
  </div>
</div>
