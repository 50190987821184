import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  FormGroup,
} from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { FormTemplateFields } from '../form-template/form-template.component';

export interface SelectOptionType {
  id: string;
  name: string;
  color?: string;
  icon?: string;
  disabled?: boolean;
  active?: boolean;
  ref?: string;
}

@Component({
  selector: 'crm-form-select',
  templateUrl: './form-select.component.html',
  styleUrls: ['./form-select.component.scss'],
})
export class FormSelectComponent implements OnInit, OnDestroy {
  @Input() formGroup: UntypedFormGroup;
  @Input() field: FormTemplateFields<FormGroup<any>>;
  @Input() multiple = false;
  @Output() selectionChange = new EventEmitter();
  @Output() close = new EventEmitter();

  @ViewChild('unassign') private unassign: MatOption;

  formControl: UntypedFormControl;

  color = '';
  itemsSubscription: Subscription;
  selected: SelectOptionType = {
    id: '',
    name: '',
    color: '',
    icon: '',
    disabled: false,
  };

  modelChange(event) {
    if (!this.field.items$) return;
    if (!event) return;
    this.selected = this.field.items$.value.find(
      (elem: SelectOptionType) => elem.id === event.value || elem.id === event
    );
    this.setColor();
    this.updateColor(event.value, this.field.items$.getValue());
  }

  ngOnInit() {
    this.formControl = this.formGroup.get(
      this.field.name
    ) as UntypedFormControl;

    this.selected = this.field.items$
      .getValue()
      .find((elem: SelectOptionType) => {
        return elem.id === this.formGroup.get(this.field.name).value;
      });

    this.setColor();

    // this.handleSelection();
  }

  tosslePerOne() {
    if (!this.field.nullable) {
      return;
    }
    if (!this.formGroup.get(this.field.name).value.length) {
      this.unassign.select();
    } else if (this.unassign.selected) {
      this.unassign.deselect();
    }
  }

  toggleUnassigned() {
    if (this.multiple) {
      this.formGroup.get(this.field.name).patchValue(['']);
    } else {
      this.formGroup.get(this.field.name).patchValue('');
    }
  }

  setColor() {
    const selected = (this.formGroup.get(this.field.name) as UntypedFormControl)
      .value;
    this.itemsSubscription = this.field.items$.subscribe((items) =>
      this.updateColor(selected, items)
    );
  }

  updateColor(selected, items: SelectOptionType[]) {
    const selectedItem = items.find(
      (item: SelectOptionType) => item.id === selected
    );

    if (selectedItem && selectedItem.color) {
      this.color = selectedItem.color;
    }
  }

  onSelectChange(event: any) {
    this.selectionChange.emit(event);
  }

  onClose(event) {
    this.close.emit(event);
  }
  ngOnDestroy() {
    if (!this.itemsSubscription) return;
    this.itemsSubscription.unsubscribe();
  }
}
