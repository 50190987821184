import { User } from './User';
import { ISerialisedActivity } from './serialisations/ISerialisedActivity';
import { Company } from './Company';
import { Contact } from './Contact';
import { UnsavedActivity } from './UnsavedActivity';
import { ActivityLink } from './ActivityLink';

export class Activity {
  constructor(
    readonly id: string,
    readonly name: string,
    readonly activityLink: ActivityLink | null,
    readonly activityType: string,
    readonly assignees: User[],
    readonly company: Company,
    readonly contacts: Contact[],
    readonly done: boolean,
    readonly startsAt: Date | null,
    readonly endsAt: Date | null,
    readonly internalObservation: string
  ) {
    if (name) this.name = name.trim();
    if (activityType) this.activityType = activityType.trim();
    if (internalObservation)
      this.internalObservation = internalObservation.trim();
  }

  static fromSerialised(serialised: ISerialisedActivity) {
    const activityLink = serialised.activityLinkResponse
      ? ActivityLink.fromSerialised(serialised.activityLinkResponse)
      : null;

    const assignees = serialised.assignees.map(User.fromSerialised);
    const contacts = serialised.contacts.map(Contact.fromSerialised);
    const company = Company.fromSerialised(serialised.company);

    return new Activity(
      serialised.id,
      serialised.name,
      activityLink,
      serialised.activityType,
      assignees,
      company,
      contacts,
      serialised.done,
      serialised.startsAt ? new Date(serialised.startsAt) : null,
      serialised.endsAt ? new Date(serialised.endsAt) : null,
      serialised.internalObservation
    );
  }

  toSerialised(): ISerialisedActivity {
    const linkObj = this.activityLink ? this.activityLink.toSerialised() : null;
    const companyObj = this.company.toSerialised();

    const assigneesObj = this.assignees.map((assignee) =>
      assignee.toSerialised()
    );
    const contactsObj = this.contacts.map((contact) => contact.toSerialised());

    return {
      id: this.id,
      name: this.name,
      activityLinkResponse: linkObj,
      activityType: this.activityType,
      assignees: assigneesObj,
      company: companyObj,
      contacts: contactsObj,
      done: this.done,
      startsAt: this.startsAt ? this.startsAt.toISOString() : null,
      endsAt: this.endsAt ? this.endsAt.toISOString() : null,
      internalObservation: this.internalObservation,
    };
  }

  toDraft(): UnsavedActivity {
    const activity = this.toSerialised();
    const ticketIdObj = this.activityLink ? this.activityLink.ticket.id : null;
    const ticketTypeObj = this.activityLink ? this.activityLink.linkType : null;

    return UnsavedActivity.fromSerialised({
      ...activity,
      ticketId: ticketIdObj,
      ticketType: ticketTypeObj,
      companyId: activity.company.id,
      contactIds: activity.contacts.map((contact) => contact.id),
      assigneeIds: activity.assignees.map((assignee) => assignee.id),
    });
  }

  clone(partialActivity: PartialActivity): Activity {
    const resolve = (key: keyof Activity) =>
      partialActivity.hasOwnProperty(key) ? partialActivity[key] : this[key];

    return new Activity(
      resolve('id'),
      resolve('name'),
      resolve('activityLink'),
      resolve('activityType'),
      resolve('assignees'),
      resolve('company'),
      resolve('contacts'),
      resolve('done'),
      resolve('startsAt'),
      resolve('endsAt'),
      resolve('internalObservation')
    );
  }
}

type PartialActivity = Partial<
  Pick<
    Activity,
    | 'id'
    | 'name'
    | 'activityLink'
    | 'activityType'
    | 'assignees'
    | 'company'
    | 'contacts'
    | 'done'
    | 'startsAt'
    | 'endsAt'
    | 'internalObservation'
  >
>;
