import { Injectable } from '@angular/core';
import {
  ClientEquipment,
  UnsavedClientEquipment,
  Response,
  TicketsSupportMultimachineEquipmentEndpoint,
} from '@fullyops/legacy/data';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UiTicketsSupportMultimachineEquipmentService {
  readonly store$ = this.service.createStore();

  constructor(protected service: TicketsSupportMultimachineEquipmentEndpoint) {}

  loadAll() {
    this.service
      .getAll({}, undefined, this.store$)
      .subscribe({ error: console.error });
  }

  getTicketMachines(id: string): Observable<Response<ClientEquipment[]>> {
    return this.service.getTicketMachines(id, undefined, this.store$);
  }

  get(ticketId: string): Observable<Response<ClientEquipment>> {
    return this.service.get(ticketId, undefined, this.store$);
  }

  create(ticketId: string, machine: ClientEquipment) {
    return this.service.post(ticketId, machine, undefined, this.store$);
  }

  update(ticketId: string, unsavedMachine: UnsavedClientEquipment) {
    return this.service.patch(ticketId, unsavedMachine, undefined, this.store$);
  }

  remove(ticketId: string, partId: string): Observable<Response<{}>> {
    return this.service.delete(ticketId, partId, undefined, this.store$);
  }
}
