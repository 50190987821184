import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  HostListener,
} from '@angular/core';
import { TicketIdentifierPipe } from '@fullyops/legacy/ui/ui-shared/utils/tickets-identifier.pipe';
import { ComponentInOutAnimation } from '@fullyops/legacy/ui/ui-shared/utils/component-base-animation';
import { faCloudArrowUp } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'crm-drop-report-input',
  templateUrl: './drop-report-input.component.html',
  styleUrls: ['./drop-report-input.component.scss'],
  animations: [ComponentInOutAnimation.AnimTrigger],
  providers: [TicketIdentifierPipe],
})
export class DropReportInputComponent implements OnInit {
  constructor() {}

  faCloudArrowUp = faCloudArrowUp;
  isDragOver = false;

  @Output() uploadTemplateEmitter = new EventEmitter();

  @HostListener('dragover', ['$event'])
  onDragOver($event) {
    $event.preventDefault();
    $event.stopPropagation();
    $event.stopImmediatePropagation();
    this.isDragOver = true;
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave($event) {
    $event.preventDefault();
    $event.stopPropagation();
    $event.stopImmediatePropagation();
    this.isDragOver = false;
  }

  ngOnInit() {}

  uploadTemplate(event) {
    this.uploadTemplateEmitter.emit(event);
  }
}
