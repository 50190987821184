import { ISerialisedUnsavedClientEquipment } from './serialisations/ISerialisedClientEquipment';
import { User } from './User';

export class UnsavedClientEquipment {
  constructor(
    readonly brand: string,
    readonly companyId: string,
    readonly createdAt: string,
    readonly createdBy: User | null,
    readonly equipmentData: string,
    readonly hasWarranty: boolean,
    readonly id: string,
    readonly model: string,
    readonly serialNumber: string,
    readonly type: string,
    readonly updatedAt: string,
    readonly updatedBy: User | null,
    readonly warranty: string
  ) {
    if (brand) this.brand = brand.trim();
    if (equipmentData) this.equipmentData = equipmentData.trim();
    if (model) this.model = model.trim();
    if (serialNumber) this.serialNumber = serialNumber.trim();
    if (type) this.type = type.trim();
  }

  static fromSerialised(serialised: ISerialisedUnsavedClientEquipment) {
    const warranty = serialised.warranty ? new Date(serialised.warranty) : null;
    const createdBy = User.fromSerialised(serialised.createdBy);
    const updatedBy = User.fromSerialised(serialised.updatedBy);

    return new UnsavedClientEquipment(
      serialised.brand,
      serialised.companyId,
      serialised.createdAt,
      createdBy,
      serialised.equipmentData,
      serialised.hasWarranty,
      serialised.id,
      serialised.model,
      serialised.serialNumber,
      serialised.type,
      serialised.updatedAt,
      updatedBy,
      serialised.warranty
    );
  }

  toSerialised(): ISerialisedUnsavedClientEquipment {
    const createdByObj = this.createdBy ? this.createdBy.toSerialised() : null;
    const updatedByObj = this.updatedBy ? this.updatedBy.toSerialised() : null;

    return {
      ...this,
      createdBy: createdByObj,
      updatedBy: updatedByObj,
    };
  }

  clone(
    partialUnsavedClientEquipment: PartialUnsavedClientEquipment
  ): UnsavedClientEquipment {
    const resolve = (key: keyof UnsavedClientEquipment) =>
      partialUnsavedClientEquipment.hasOwnProperty(key)
        ? partialUnsavedClientEquipment[key]
        : this[key];
    return new UnsavedClientEquipment(
      resolve('brand'),
      resolve('companyId'),
      resolve('createdAt'),
      resolve('createdBy'),
      resolve('equipmentData'),
      resolve('hasWarranty'),
      resolve('id'),
      resolve('model'),
      resolve('serialNumber'),
      resolve('type'),
      resolve('updatedAt'),
      resolve('updatedBy'),
      resolve('warranty')
    );
  }
}

type PartialUnsavedClientEquipment = Partial<
  Pick<
    UnsavedClientEquipment,
    | 'brand'
    | 'companyId'
    | 'createdAt'
    | 'createdBy'
    | 'equipmentData'
    | 'hasWarranty'
    | 'id'
    | 'model'
    | 'serialNumber'
    | 'type'
    | 'updatedAt'
    | 'updatedBy'
    | 'warranty'
  >
>;
