<button class="product-config" mat-button type="button">
  <mat-card>
    <img
      mat-card-image
      src="assets/images/not-found.png"
      (click)="openConfiguration()"
    />
    <mat-card-title>{{ productConfig.name }}</mat-card-title>
    <mat-card-subtitle>
      <p>{{ 'product' | i18nextCap }}</p>
    </mat-card-subtitle>
    <mat-card-content>
      <label>{{ 'status' | i18nextCap }}</label>
      <p>
        {{ productConfig.status | i18nextCap }}
      </p>
      <label>{{ 'description' | i18nextCap }}</label>
      <p>
        {{ productConfig.product.description }}
      </p>
      <mat-expansion-panel
        (opened)="panelOpenState = true"
        (closed)="panelOpenState = false"
      >
        <mat-expansion-panel-header>
          {{ 'configuration' | i18nextCap }} ({{
            'nVariants'
              | i18next: { count: productConfig.product.subproducts.length }
          }})
        </mat-expansion-panel-header>
        <mat-list *ngIf="panelOpenState">
          <mat-list-item
            *ngFor="let subProduct of productConfig.product.subproducts"
          >
            <mat-icon>check_circle_outline</mat-icon>
            {{
              subProduct.variants[0]
                ? subProduct.variants[0].name
                : subProduct.name
            }}
          </mat-list-item>
        </mat-list>
      </mat-expansion-panel>
    </mat-card-content>
  </mat-card>
</button>
