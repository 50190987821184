import { Component, Input, OnInit } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ComponentInOutAnimation } from '@fullyops/legacy/ui/ui-shared/utils/component-base-animation';

@Component({
  selector: 'crm-product-form-technical-info',
  templateUrl: './product-form-technical-info.component.html',
  styleUrls: ['./product-form-technical-info.component.scss'],
  animations: [ComponentInOutAnimation.AnimTrigger],
})
export class ProductFormTechnicalInfoComponent {
  @Input() preview = false;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() createEntryLabel: string;
  @Input() technicalInfoForm: UntypedFormArray;

  addTable() {
    this.technicalInfoForm.controls.push(
      new UntypedFormGroup({
        name: new UntypedFormControl('', Validators.required),
        table: new UntypedFormArray(
          ['', '', ''].map(
            (_) =>
              new UntypedFormArray(
                ['', '', ''].map((_) => new UntypedFormControl('', [])),
                []
              )
          ),
          Validators.required
        ),
      })
    );
  }

  addLine(tableIndex: number) {
    const table = (
      this.technicalInfoForm.controls[tableIndex].get(
        'table'
      ) as UntypedFormArray
    ).controls;

    table.push(
      new UntypedFormArray(
        (table[0] as UntypedFormArray).controls.map(
          (_) => new UntypedFormControl('', [])
        ),
        []
      )
    );
  }

  addColumn(tableIndex: number) {
    const table = (
      this.technicalInfoForm.controls[tableIndex].get(
        'table'
      ) as UntypedFormArray
    ).controls;

    table.forEach((row: UntypedFormArray) =>
      row.controls.push(new UntypedFormControl('', []))
    );
  }

  removeLine(tableIndex: number, index: number) {
    const tableRows = (
      this.technicalInfoForm.controls[tableIndex].get(
        'table'
      ) as UntypedFormArray
    ).controls;

    if (tableRows.length === 1) {
      return this.removeTable(tableIndex);
    }

    (
      this.technicalInfoForm.controls[tableIndex].get(
        'table'
      ) as UntypedFormArray
    ).controls.splice(index, 1);
  }

  removeColumn(tableIndex: number, index: number) {
    const tableRows = (
      this.technicalInfoForm.controls[tableIndex].get(
        'table'
      ) as UntypedFormArray
    ).controls;

    if ((tableRows[0] as UntypedFormArray).controls.length === 1) {
      return this.removeTable(tableIndex);
    }

    tableRows.forEach((row: UntypedFormArray) => row.controls.splice(index, 1));
  }

  removeTable(tableIndex: number) {
    this.technicalInfoForm.controls.splice(tableIndex, 1);
  }
}
