import { Response } from '../Response';
import { Endpoint } from '../Endpoint';
import { HttpClient } from '@angular/common/http';
import { IListResponse } from '../IListResponse';
import { Injectable, Inject } from '@angular/core';
import { API_SERVICE_CONFIG } from '../ApiServiceConfig';

import { Observable, BehaviorSubject } from 'rxjs';

import { IApiServiceConfig } from '../IApiServiceConfig';
import { map, tap } from 'rxjs/operators';
import { TicketSupportMultimachineAnomaly } from '../../models/v0/TicketSupportMultimachineAnomaly';
import { ISerialisedTicketSupportMultimachineAnomaly } from '../../models/v0/serialisations/ISerialisedTicketSupportMultimachineAnomaly';
import { Anomaly } from '../../models/v0/Anomaly';
import { UnsavedTicketSupportMultimachineAnomaly } from '../../models/v0/UnsavedTicketSupportMultimachineAnomaly';

type AnomalyList = IListResponse<ISerialisedTicketSupportMultimachineAnomaly>;

@Injectable()
export class TicketsSupportAnomalyEndpoint extends Endpoint<
  TicketSupportMultimachineAnomaly,
  ISerialisedTicketSupportMultimachineAnomaly
> {
  private readonly url =
    this.config.url + 'tickets/multimachinesupports/equipments/anomalies';

  constructor(
    @Inject(API_SERVICE_CONFIG) private config: IApiServiceConfig,
    private http: HttpClient
  ) {
    super(TicketSupportMultimachineAnomaly.fromSerialised);
  }

  getAll(
    filters: {},
    source?: Observable<AnomalyList>,
    store?: BehaviorSubject<Response<TicketSupportMultimachineAnomaly[]>>
  ): Observable<Response<TicketSupportMultimachineAnomaly[]>> {
    let query = '';
    Object.keys(filters).forEach((key) => (query += `&${key}=${filters[key]}`));

    const defaultSource = this.http.get<AnomalyList>(
      `${this.url}?size=100&page=0${query}`
    );
    return this.list(source || defaultSource, store);
  }

  getTicketMachineAnomalies(
    ticketId: string,
    machineId: string,
    source?: Observable<AnomalyList>,
    store?: BehaviorSubject<Response<TicketSupportMultimachineAnomaly[]>>
  ): Observable<Response<TicketSupportMultimachineAnomaly[]>> {
    const defaultSource = this.http.get<AnomalyList>(`${this.url}${ticketId}/`);

    return this.list(source || defaultSource, store);
  }

  get(
    id: string,
    source?: Observable<ISerialisedTicketSupportMultimachineAnomaly>,
    store?: BehaviorSubject<Response<TicketSupportMultimachineAnomaly[]>>
  ): Observable<Response<TicketSupportMultimachineAnomaly>> {
    const defaultSource =
      this.http.get<ISerialisedTicketSupportMultimachineAnomaly>(
        `${this.url}${id}/`
      );
    return this.retrieve(source || defaultSource, store);
  }

  post(
    ticketId: string,
    machineId: string,
    anomaly: Anomaly,
    source?: Observable<ISerialisedTicketSupportMultimachineAnomaly>,
    store?: BehaviorSubject<Response<TicketSupportMultimachineAnomaly[]>>
  ): Observable<Response<TicketSupportMultimachineAnomaly>> {
    const body = {
      clientEquipmentId: machineId,
      supportTicketId: ticketId,
      anomalyId: anomaly.id,
    };
    const defaultSource =
      this.http.post<ISerialisedTicketSupportMultimachineAnomaly>(
        `${this.url}`,
        body
      );
    return this.add(source || defaultSource, store);
  }

  patch(
    ticketId: string,
    machineId: string,
    machine: UnsavedTicketSupportMultimachineAnomaly,
    source?: Observable<ISerialisedTicketSupportMultimachineAnomaly>,
    store?: BehaviorSubject<Response<TicketSupportMultimachineAnomaly[]>>
  ): Observable<Response<TicketSupportMultimachineAnomaly>> {
    const { ...body } = machine.toSerialised();
    const defaultSource =
      this.http.patch<ISerialisedTicketSupportMultimachineAnomaly>(
        `${this.url}${ticketId}/`,
        body
      );

    return defaultSource.pipe(
      tap((instance) =>
        this.update(
          source || defaultSource,
          TicketSupportMultimachineAnomaly.fromSerialised(instance),
          store
        )
      ),
      map(
        (serialiser) =>
          new Response(
            TicketSupportMultimachineAnomaly.fromSerialised(serialiser)
          )
      )
    );
  }

  delete(
    ticketId: string,
    machineId: string,
    anomalyId: string,
    source?: Observable<{}>,
    store?: BehaviorSubject<Response<TicketSupportMultimachineAnomaly[]>>
  ): Observable<Response<{}>> {
    const defaultSource = this.http.delete<{}>(
      `${this.url}/${ticketId}/${machineId}/${anomalyId}/`
    );
    return this.remove(
      source || defaultSource,
      ticketId + machineId + anomalyId,
      store
    );
  }
}
