import { Response } from '../Response';
import { Endpoint } from '../Endpoint';
import { HttpClient } from '@angular/common/http';
import { IListResponse } from '../IListResponse';
import { Injectable, Inject } from '@angular/core';
import { API_SERVICE_CONFIG } from '../ApiServiceConfig';

import { Observable, BehaviorSubject } from 'rxjs';

import { Label } from '../../models/v0/Label';
import { ISerialisedLabel } from '../../models/v0/serialisations/ISerialisedLabel';
import { IApiServiceConfig } from '../IApiServiceConfig';
import { UnsavedLabel } from '../../models/v0/UnsavedLabel';

type LabelList = IListResponse<ISerialisedLabel>;

@Injectable()
export class LabelsEndpoint extends Endpoint<Label, ISerialisedLabel> {
  private readonly url = this.config.url + 'products/labels/';

  constructor(
    @Inject(API_SERVICE_CONFIG) private config: IApiServiceConfig,
    private http: HttpClient
  ) {
    super(Label.fromSerialised);
  }

  getAll(
    source?: Observable<LabelList>,
    store?: BehaviorSubject<Response<Label[]>>
  ): Observable<Response<Label[]>> {
    const defaultSource = this.http.get<LabelList>(this.url);
    return this.list(source || defaultSource, store);
  }

  get(
    id: string,
    source?: Observable<ISerialisedLabel>,
    store?: BehaviorSubject<Response<Label[]>>
  ): Observable<Response<Label>> {
    const defaultSource = this.http.get<ISerialisedLabel>(`${this.url}${id}/`);
    return this.retrieve(source || defaultSource, store);
  }

  post(
    label: UnsavedLabel,
    source?: Observable<ISerialisedLabel>,
    store?: BehaviorSubject<Response<Label[]>>
  ): Observable<Response<Label>> {
    const defaultSource = this.http.post<ISerialisedLabel>(
      this.url,
      label.toSerialised()
    );
    return this.add(source || defaultSource, store);
  }

  patch(
    label: Label,
    source?: Observable<ISerialisedLabel>,
    store?: BehaviorSubject<Response<Label[]>>
  ) {
    const { id, ...body } = label.toSerialised();
    const defaultSource = this.http.patch<ISerialisedLabel>(
      `${this.url}${id}/`,
      body
    );
    return this.update(source || defaultSource, label, store);
  }

  delete(
    id: string,
    source?: Observable<{}>,
    store?: BehaviorSubject<Response<Label[]>>
  ): Observable<Response<{}>> {
    const defaultSource = this.http.delete<{}>(`${this.url}${id}/`);
    return this.remove(source || defaultSource, id, store);
  }
}
