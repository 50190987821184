import { COLORS } from '@fullyops/legacy/ui/ui-shared/utils/styles';
import { HotToastService } from '@ngneat/hot-toast';
import { IApiServiceConfig } from './../api/IApiServiceConfig';
import { API_SERVICE_CONFIG } from './../api/ApiServiceConfig';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
  EndpointErrorMessages,
  GenericErrorMessages,
} from './ErrorMessages/ErrorHandlingMessages';
import { I18NextPipe } from 'angular-i18next';
import { companyDynamicErrorMessage } from './ErrorMessages/CompanyRequestErrorMessagens';
import { AuthEndpoint } from '../api/v0/AuthEndpoint';
import { AutenticanteErrorMessages } from './ErrorMessages/AutenticanteErrorMessages';

@Injectable()
export class ErrorHandingInterceptor implements HttpInterceptor {
  constructor(
    @Inject(API_SERVICE_CONFIG) private apiServiceConfig: IApiServiceConfig,
    private i18nextPipe: I18NextPipe,
    private snackBarService: HotToastService,
    private auth: AuthEndpoint
  ) {}

  getErrorMessage = (
    endPoint,
    status: HttpStatusCode,
    errorMessage?: string | { errors: Array<string> }
  ): string => {
    const getGenericErrorMessage = (statusCode: HttpStatusCode) =>
      GenericErrorMessages[statusCode] ||
      GenericErrorMessages[`${statusCode.toString()[0]}xx`] ||
      GenericErrorMessages['xxx'];
    const isErrorMessageObject = typeof errorMessage == 'object';

    if (status >= 500 && status <= 599) return getGenericErrorMessage(status);

    if (isErrorMessageObject && errorMessage?.errors) {
      const companyDynamicErrorMessageResponse = companyDynamicErrorMessage({
        endPoint,
        errorMessage: isErrorMessageObject
          ? errorMessage.errors[0]
          : errorMessage,
      });

      if (companyDynamicErrorMessageResponse) {
        return companyDynamicErrorMessageResponse;
      } else {
        if (errorMessage?.errors[0]) {
          return errorMessage?.errors[0];
        }
        if (!EndpointErrorMessages[endPoint]) {
          return getGenericErrorMessage(status);
        }

        if (!EndpointErrorMessages[endPoint][status]) {
          return getGenericErrorMessage(status);
        }
      }
    } else {
      if (typeof errorMessage === 'string') {
        if (
          errorMessage.includes('JWT') ||
          errorMessage.includes('no tenant specified')
        ) {
          this.auth.logout();
          return AutenticanteErrorMessages['401'].sessionExpired;
        }

        if (!EndpointErrorMessages[endPoint]) {
          return getGenericErrorMessage(status);
        }
      }
    }
    return EndpointErrorMessages?.[endPoint]?.[status]?.['default'] || 'error';
  };

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap({
        error: (error: HttpErrorResponse) => {
          const hasNumber = /\d/;
          const endPointWithParams = req.url.split('api')[1];
          const endpointParts = endPointWithParams.split('/');
          const endpointValidParts = endpointParts.filter(
            (urlPart) => urlPart && !hasNumber.test(urlPart)
          );
          const endPoint = '/' + endpointValidParts.join('/');
          const errorMessage = this.getErrorMessage(
            endPoint,
            error.status,
            error.error
          );
          //  Open SnackBar
          this.snackBarService.error(this.i18nextPipe.transform(errorMessage), {
            position: 'bottom-right',
            duration: 5000,
            dismissible: true,
            style: {
              color: 'white',
              backgroundColor: COLORS.red,
            },
            icon: '⚠️',
          });
        },
      })
    );
  }
}
