import { ISerialisedUnsavedContact } from './serialisations/ISerialisedContact';

export class UnsavedContact {
  constructor(
    readonly name: string,
    readonly email: string,
    readonly address: string,
    readonly mobileNumber: string,
    readonly notes: string,
    readonly companyId: string,
    readonly jobPosition: string
  ) {
    if (name) this.name = name.trim();
    if (email) this.email = email.trim();
    if (address) this.address = address.trim();
    if (mobileNumber) this.mobileNumber = mobileNumber.trim();
    if (notes) this.notes = notes.trim();
  }

  static fromSerialised(serialised: ISerialisedUnsavedContact) {
    return new UnsavedContact(
      serialised.name,
      serialised.email,
      serialised.address,
      serialised.mobileNumber,
      serialised.notes,
      serialised.companyId,
      serialised.jobPosition
    );
  }

  toSerialised(): ISerialisedUnsavedContact {
    return {
      name: this.name,
      email: this.email,
      address: this.address,
      mobileNumber: this.mobileNumber,
      notes: this.notes,
      companyId: this.companyId,
      jobPosition: this.jobPosition,
    };
  }

  clone(partialUnsavedContact: PartialUnsavedContact): UnsavedContact {
    const resolve = (key: keyof UnsavedContact) =>
      partialUnsavedContact.hasOwnProperty(key)
        ? partialUnsavedContact[key]
        : this[key];
    return new UnsavedContact(
      resolve('name'),
      resolve('email'),
      resolve('address'),
      resolve('mobileNumber'),
      resolve('notes'),
      resolve('companyId'),
      resolve('jobPosition')
    );
  }
}

type PartialUnsavedContact = Partial<
  Pick<
    UnsavedContact,
    | 'name'
    | 'email'
    | 'address'
    | 'mobileNumber'
    | 'notes'
    | 'companyId'
    | 'jobPosition'
  >
>;
