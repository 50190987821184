import { Component, Input, OnInit } from '@angular/core';
import { User } from '@fullyops/legacy/data/models/v0/User';

@Component({
  selector: 'crm-assignees-icon-list',
  templateUrl: './assignees-icon-list.component.html',
  styleUrls: ['./assignees-icon-list.component.scss'],
})
export class AssigneesIconListComponent implements OnInit {
  @Input() assignees: User[];
  @Input() size: number = 25;
  @Input() maxDisplay: number = 2;

  ngOnInit() {}
}
