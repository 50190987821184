import {
  Component,
  Input,
  OnInit,
  Output,
  ViewChild,
  EventEmitter,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { BehaviorSubject, forkJoin, of } from 'rxjs';
import { TravelControllerV2 } from '../ui-travel-controller-v2.service';
import {
  TravelRequest,
  TravelResponse,
} from '@fullyops/legacy/data/api/types/Travel';
import { TravelAssigneeControllerV2 } from '../ui-travel-assignee-controller-vs.service';
import { MatAccordion } from '@angular/material/expansion';
import { UserResponse } from '@fullyops/legacy/data/api/types/User';
import { TravelCardV2Component } from '../travel-card-v2/travel-card-v2.component';
import { DialogService } from '@fullyops/legacy/ui/ui-shared/dialog/dialog.service';
import { SupportTicketResponse } from '@fullyops/legacy/data/api/types/SupportTicket';

export type TravelData = {
  travelBasicData: TravelRequest;
  assigneesIds: string[];
};

export type TravelDataToEdit = {
  travelBasicData: TravelRequest;
  assigneesIds: string[];
  oldTravelData: TravelResponse;
};

@Component({
  selector: 'crm-trips',
  templateUrl: './trips.component.html',
  styleUrls: ['./trips.component.scss'],
})
export class TripsComponent implements OnInit {
  constructor(
    protected travelControllerV2: TravelControllerV2,
    protected travelAssigneeControllerV2: TravelAssigneeControllerV2,
    private dialogService: DialogService
  ) {}
  @Input() isDetailPage: boolean;
  @Input() usersAssignees$: BehaviorSubject<UserResponse[]>;
  @Input() ticket$: BehaviorSubject<SupportTicketResponse>;
  @ViewChild(MatAccordion) accordion: MatAccordion;
  @ViewChildren(TravelCardV2Component)
  components: QueryList<TravelCardV2Component>;
  @Input() openedIndex: number;
  @Output() setAccordionTabIndex = new EventEmitter<any>();

  ngOnInit() {}

  getDirtyFormTravelComponent() {
    return this.components.find((tripComponent) => {
      if (tripComponent.travelForm.dirty) return true;
      return false;
    });
  }

  getDirtyFormTravelComponents() {
    return this.components.filter((tripComponent) => {
      return tripComponent.travelForm.dirty;
    });
  }

  setOpenedIndex(index) {
    this.setAccordionTabIndex.emit(index);
  }

  reloadTravels(props: { travelIdToOpenAfterLoad?: string }) {
    const openTravelById = () => {
      const indexTravelAdded = this.ticket$.value.travels.findIndex(
        (travel) => travel.id == props.travelIdToOpenAfterLoad
      );
      this.setOpenedIndex(indexTravelAdded);
    };

    this.travelControllerV2
      .getAll({
        queryParameters: {
          order: 'ASC',
          orderBy: 'STARTING_HOUR',
          supportTicketId: this.ticket$.value.id,
        },
      })
      .subscribe((res) => {
        const newTicket = { ...this.ticket$.value };
        newTicket.travels = res.results;
        this.ticket$.next(newTicket);
        if (props.travelIdToOpenAfterLoad) {
          openTravelById();
        }
      });
  }

  removeTravel(travelId: string) {
    const dialogRef = this.dialogService.openDialogBeforeDelete();

    dialogRef.afterClosed().subscribe((saveOutput) => {
      if (saveOutput) {
        this.travelControllerV2.delete({ travelId }).subscribe(() => {
          const newTicket = { ...this.ticket$.value };
          newTicket.travels = this.ticket$.value.travels.filter(
            (travel) => travel.id !== travelId
          );
          this.ticket$.next(newTicket);
        });
        this.setOpenedIndex(-2);
        this.gotoTop();
      }
    });
  }

  createTravel(data: TravelData) {
    const assignUsersRequests = (travelId: string) => {
      return data.assigneesIds.map((assigneeId) => {
        return this.travelAssigneeControllerV2.assignUserToTravel({
          assigneeId,
          travelId,
        });
      });
    };

    this.travelControllerV2
      .create({ travel: data.travelBasicData })
      .subscribe((travelResponse) => {
        forkJoin([...assignUsersRequests(travelResponse.id)]).subscribe(() => {
          this.reloadTravels({});
          this.setOpenedIndex(-2);
          this.gotoTop();
        });
      });
  }

  editTravel(data: TravelDataToEdit) {
    const assignUsersRequests = () => {
      const oldAssignees = data.oldTravelData.assignees;
      const newAssignees = data.assigneesIds;

      const assigneesToAdd = newAssignees.filter((newAssignee) => {
        return !oldAssignees.some(
          (oldAssignee) => oldAssignee.assignee.id == newAssignee
        );
      });

      const assigneesToRemove = oldAssignees.filter((assignee) => {
        return !newAssignees.some(
          (newAssigneeId) => assignee.assignee.id == newAssigneeId
        );
      });

      const assignUsersRequests = assigneesToAdd.map((assigneeId) => {
        return this.travelAssigneeControllerV2.assignUserToTravel({
          travelId: data.oldTravelData.id,
          assigneeId,
        });
      });

      const unAssignUsersRequests = assigneesToRemove.map(
        (assigneeResponse) => {
          return this.travelAssigneeControllerV2.unAssignUserToTravel({
            assigneeId: assigneeResponse.assignee.id,
            travelId: data.oldTravelData.id,
          });
        }
      );

      if (
        assignUsersRequests.length == 0 &&
        unAssignUsersRequests.length == 0
      ) {
        return of({});
      }

      return forkJoin([...assignUsersRequests, ...unAssignUsersRequests]);
    };

    const updateBasicData = () => {
      return this.travelControllerV2.updateById({
        travel: data.travelBasicData,
        travelId: data.oldTravelData.id,
      });
    };

    assignUsersRequests().subscribe(() => {
      updateBasicData().subscribe((travelResponse) => {
        this.reloadTravels({});
        this.setOpenedIndex(-2);
        this.gotoTop();
      });
    });
  }

  gotoTop() {
    document
      .querySelector('.mat-sidenav-content')
      .scrollTo({ behavior: 'smooth', left: 0, top: 0 });
  }
}
