import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { CanDeactivate } from '@angular/router';
import { ComponentCanDeactivate, DialogComponent } from '@fullyops/legacy/ui';
import { Observable, of } from 'rxjs';

@Injectable()
export class CanDeactivateGuard
  implements CanDeactivate<ComponentCanDeactivate>
{
  constructor(public dialog: MatDialog) {}

  canDeactivate(component: ComponentCanDeactivate): Observable<boolean> {
    if (!component.canDeactivate()) {
      const dialogRef = this.dialog.open(DialogComponent, {
        data: {
          message: 'leavePageMessage',
          closeModalText: 'cancel',
          actionModalText: 'leave',
        },
      });
      return dialogRef.afterClosed() as Observable<boolean>;
    }

    return of(true);
  }
}
