<section *ngIf="list$ | async as list">
  <header>
    <p>
      {{ title | i18nextCap }}
      <button
        *ngIf="createEntryLabel && list.length"
        mat-flat-button
        type="button"
        color="primary"
        (click)="createEntryEvent.emit()"
      >
        {{ 'forms:' + createEntryLabel | i18nextCap }}
      </button>
    </p>
    <p class="subheader">{{ subtitle | i18nextCap }}</p>
  </header>
  <mat-list>
    <mat-list-item *ngFor="let item of list">
      <mat-icon class="{{ item.color ? item.color : '' }}">
        {{ item.icon ? item.icon : 'article' }}
      </mat-icon>
      <span>
        <b>{{ item.name }}</b>
      </span>
      <ng-container *ngFor="let button of actionButtons; index as i">
        <button
          mat-button
          [class]="button.color"
          type="button"
          (click)="fireAction(item.id, button.label)"
        >
          {{ 'forms:' + button.label | i18nextCap }}
        </button>
      </ng-container>
    </mat-list-item>
  </mat-list>
  <crm-empty-warn
    *ngIf="!list.length"
    [message]="emptyListLabel"
    [actionLabel]="createEntryLabel"
    (actionEvent)="createEntryEvent.emit()"
  ></crm-empty-warn>
</section>
