import { ISerialisedUnsavedTicketSupportMultimachineAnomaly } from './serialisations/ISerialisedTicketSupportMultimachineAnomaly';
import { User } from './User';

export class UnsavedTicketSupportMultimachineAnomaly {
  constructor(
    readonly anomalyId: string,
    readonly clientEquipmentId: string,
    readonly deleted: boolean,
    readonly supportTicketId: string,
    readonly createdAt: string,
    readonly createdBy: User | null,
    readonly updatedAt: string,
    readonly updatedBy: User | null
  ) {}

  static fromSerialised(
    serialised: ISerialisedUnsavedTicketSupportMultimachineAnomaly
  ) {
    const createdBy = User.fromSerialised(serialised.createdBy);
    const updatedBy = User.fromSerialised(serialised.updatedBy);

    return new UnsavedTicketSupportMultimachineAnomaly(
      serialised.anomalyId,
      serialised.clientEquipmentId,
      serialised.deleted,
      serialised.supportTicketId,
      serialised.createdAt,
      createdBy,
      serialised.updatedAt,
      updatedBy
    );
  }

  toSerialised(): ISerialisedUnsavedTicketSupportMultimachineAnomaly {
    const createdByObj = this.createdBy ? this.createdBy.toSerialised() : null;
    const updatedByObj = this.updatedBy ? this.updatedBy.toSerialised() : null;

    return {
      ...this,
      createdBy: createdByObj,
      updatedBy: updatedByObj,
    };
  }

  clone(
    partialUnsavedTicketSupportMultimachineAnomaly: PartialUnsavedTicketSupportMultimachineAnomaly
  ): UnsavedTicketSupportMultimachineAnomaly {
    const resolve = (key: keyof UnsavedTicketSupportMultimachineAnomaly) =>
      partialUnsavedTicketSupportMultimachineAnomaly.hasOwnProperty(key)
        ? partialUnsavedTicketSupportMultimachineAnomaly[key]
        : this[key];
    return new UnsavedTicketSupportMultimachineAnomaly(
      resolve('anomalyId'),
      resolve('clientEquipmentId'),
      resolve('deleted'),
      resolve('supportTicketId'),
      resolve('createdAt'),
      resolve('createdBy'),
      resolve('updatedAt'),
      resolve('updatedBy')
    );
  }
}

type PartialUnsavedTicketSupportMultimachineAnomaly = Partial<
  Pick<
    UnsavedTicketSupportMultimachineAnomaly,
    | 'anomalyId'
    | 'clientEquipmentId'
    | 'deleted'
    | 'supportTicketId'
    | 'createdAt'
    | 'createdBy'
    | 'updatedAt'
    | 'updatedBy'
  >
>;
