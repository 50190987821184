import { startWith, map, debounceTime } from 'rxjs/operators';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import {
  UntypedFormGroup,
  AbstractControl,
  ValidationErrors,
  Validators,
  FormGroup,
  UntypedFormControl,
  FormControl,
} from '@angular/forms';
import { Observable } from 'rxjs';
import { I18NextPipe } from 'angular-i18next';
import { FormTemplateFields } from '../form-template/form-template.component';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { SelectOptionType } from '../form-select/form-select.component';
import { MatLegacyAutocomplete } from '@angular/material/legacy-autocomplete';

@Component({
  selector: 'crm-form-autocomplete',
  templateUrl: './form-autocomplete.component.html',
  styleUrls: ['./form-autocomplete.component.scss'],
})
export class FormAutocompleteComponent implements OnInit {
  constructor(
    private i18nextPipe: I18NextPipe,
    private cdRef: ChangeDetectorRef
  ) {}
  @Input() field: FormTemplateFields<FormGroup<any>>;
  @Input() formGroup: UntypedFormGroup;

  valueSet = null;

  filteredOptions: Observable<SelectOptionType[]>;

  ngOnInit() {
    this.formGroup.controls[this.field.name].addValidators([
      (control) =>
        this.validateItemSelectedIsOnList(
          control,
          this,
          this.formGroup.controls[this.field.name].hasValidator(
            Validators.required
          )
        ),
    ]);

    this.field.items$.subscribe(() => {
      this.filteredOptions = this.formGroup.controls[
        this.field.name
      ].valueChanges.pipe(
        startWith(''),
        map((value) => this._filter(value || ''))
      );
      this.cdRef.detectChanges();
    });

    this.applyOnChange();
  }

  checkValueOnFocusOut() {
    const control: AbstractControl<any, any> =
      this.formGroup.controls[this.field.name];

    if (typeof control.value == 'string') {
      const found = this.field.items$.value.find(
        (item) =>
          (item.name as string).toLowerCase() ==
          (control.value as string).toLowerCase()
      );

      if (found) {
        control.setValue(found);
      }
    }
  }

  validateItemSelectedIsOnList(
    control: AbstractControl,
    self,
    required
  ): ValidationErrors | null {
    if (!required) {
      if (!control.value) return null;
    }
    if (typeof control.value == 'string') return { noFound: true };
    return null;
  }

  private _filter(value: string | { name: string }) {
    if (!value) return this.field.items$.value;
    if (typeof value == 'object') value = value.name;

    const filterValue = value.toLowerCase();

    const data = this.field.items$.value.filter((option: any) => {
      return this.i18nextPipe
        .transform(option.name as string)
        .toLowerCase()
        .includes(filterValue);
    });

    return data;
  }

  displayFn(value) {
    if (this.field.displayValue) {
      return this.field.displayValue(value) || null;
    }

    return value ? value.name : null;
  }

  applyOnChange() {
    if (!this.field.autocomplete?.onInputChange?.callback) return;
    const debounceTimeValue =
      this.field.autocomplete?.onInputChange?.debounceTime;

    this.formGroup.controls[this.field.name].valueChanges
      .pipe(debounceTime(debounceTimeValue || 0))
      .subscribe((e) => {
        this.field.autocomplete?.onInputChange?.callback(e);
      });
  }

  onSelected(event: MatAutocompleteSelectedEvent) {
    const isFirstOptionSet = !!this.field?.autocomplete?.firstOption;
    const firstOptionValue =
      this.field?.autocomplete?.firstOption?.value || null;

    if (event.option.value == firstOptionValue && isFirstOptionSet) {
      return this.field.autocomplete?.firstOption.onClick();
    }

    if (this.field.autocomplete?.onSelect) {
      return this.field.autocomplete?.onSelect();
    }
  }

  isItemAlreadySelected(item: SelectOptionType): boolean {
    if (this.field?.autocomplete?.getItemsSelected) {
      const itemsSelected = this.field.autocomplete?.getItemsSelected();
      if (!itemsSelected) return false;
      return itemsSelected.some(({ id }) => item.id == id);
    }

    const formValue = (
      this.formGroup.get(this.field.name) as FormControl<SelectOptionType>
    ).value;

    return formValue ? formValue.id == item.id : false;
  }

  onEnter(e: Event) {
    if (this.field.autocomplete?.onTypeEnter?.callback) {
      this.field.autocomplete?.onTypeEnter?.callback({
        inputElement: e.target as HTMLInputElement,
        formControl: this.formGroup.controls[this.field.name],
      });
    }
  }
}
