import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TenantResolverService {
  constructor() {}

  public getActiveTenant(): string {
    const { defaultTenant } = environment;
    if (defaultTenant != null) {
      return defaultTenant;
    }

    return window.location.host.split('.')[0].replace(':', '');
  }
}
