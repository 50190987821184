import { Pipe, PipeTransform } from '@angular/core';
import { UiAuthService } from '@fullyops/legacy/ui';
import * as moment from 'moment-timezone';

@Pipe({
  name: 'momentTimezoneToAngularTimezone',
})
export class MomentTimezoneToAngularTimezonePipe implements PipeTransform {
  constructor(protected uiAuthService: UiAuthService) {
    moment.locale(this.uiAuthService.currentUser.locale);
  }

  transform(date: string | Date, format?): string {
    const lang = this.uiAuthService.currentUser.locale;
    if (!format) {
      if (lang == 'pt') {
        format = 'D [de] MMM [de] YYYY';
      }
      if (lang == 'en') {
        format = 'MMMM D, YYYY';
      }
    }
    const dateFormatted = moment
      .tz(date, this.uiAuthService.currentUser.timeZone)
      .format(format);

    return dateFormatted;
  }
}
