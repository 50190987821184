import { ISerialisedUser } from './serialisations/ISerialisedUser';
import { UnsavedUser } from './UnsavedUser';
import { Role } from './Role';
import { Tenant } from './Tenant';

export class User {
  constructor(
    readonly id: string,
    readonly email: string,
    readonly firstName: string,
    readonly lastName: string,
    readonly locale: string,
    readonly phone: string,
    readonly photo: string,
    readonly username: string,
    readonly roles: Role[],
    readonly tenants: Tenant[],
    readonly active: boolean,
    readonly profilePictureID: string,
    readonly profilePictureURL: string,
    readonly timeZone: string
  ) {
    if (email) this.email = email.trim();
    if (firstName) this.firstName = firstName.trim();
    if (lastName) this.lastName = lastName.trim();
    if (phone) this.phone = phone.trim();
    if (username) this.username = username.trim();
  }

  static fromSerialised(
    serialised: ISerialisedUser | Partial<ISerialisedUser>
  ) {
    const roles = serialised?.roles?.map(Role.fromSerialised) || [];
    const tenants = serialised?.tenants?.map(Tenant.fromSerialised) || [];

    return new User(
      serialised?.id || null,
      serialised?.email || null,
      serialised?.firstName || null,
      serialised?.lastName || null,
      serialised?.locale || null,
      serialised?.phone || null,
      serialised?.photo || null,
      serialised?.username || null,
      roles,
      tenants,
      serialised?.active || null,
      serialised?.profilePictureID || null,
      serialised?.profilePictureURL || null,
      serialised?.timeZone || null
    );
  }

  toSerialised(): ISerialisedUser {
    const rolesObj = this.roles.map((role) => role.toSerialised());
    const tenantsObj = this.tenants?.map((tenant) => tenant.toSerialised());

    return {
      id: this.id,
      email: this.email,
      firstName: this.firstName,
      lastName: this.lastName,
      locale: this.locale,
      phone: this.phone,
      photo: this.photo,
      username: this.username,
      roles: rolesObj,
      tenants: tenantsObj,
      active: this.active,
      profilePictureID: this.profilePictureID,
      profilePictureURL: this.profilePictureURL,
      timeZone: this.timeZone,
    };
  }

  toDraft(): UnsavedUser {
    const roles = this.roles.map((role) => role.id);

    return new UnsavedUser(
      this.email,
      this.firstName,
      this.lastName,
      this.locale,
      this.phone,
      this.photo,
      this.username,
      roles,
      '',
      this.tenants,
      this.active,
      this.profilePictureID,
      this.profilePictureURL,
      this.timeZone
    );
  }

  clone(partialUser: PartialUser): User {
    const resolve = (key: keyof User) =>
      partialUser.hasOwnProperty(key) ? partialUser[key] : this[key];

    return new User(
      resolve('id'),
      resolve('email'),
      resolve('firstName'),
      resolve('lastName'),
      resolve('locale'),
      resolve('phone'),
      resolve('photo'),
      resolve('username'),
      resolve('roles'),
      resolve('tenants'),
      resolve('active'),
      resolve('profilePictureID'),
      resolve('profilePictureURL'),
      resolve('timeZone')
    );
  }
}
type PartialUser = Partial<
  Pick<
    User,
    | 'id'
    | 'email'
    | 'firstName'
    | 'lastName'
    | 'locale'
    | 'phone'
    | 'photo'
    | 'username'
    | 'roles'
    | 'tenants'
    | 'active'
    | 'profilePictureID'
    | 'profilePictureURL'
    | 'timeZone'
  >
>;
