import { ISerialisedUnsavedUser } from './serialisations/ISerialisedUser';
import { ISerialisedTenant } from '../v0/serialisations/ISerialisedTenant';

export class UnsavedUser {
  constructor(
    readonly email: string,
    readonly firstName: string,
    readonly lastName: string,
    readonly locale: string,
    readonly phone: string,
    readonly photo: string,
    readonly username: string,
    readonly roles: string[],
    readonly password: string,
    readonly tenants: ISerialisedTenant[],
    readonly active: boolean,
    readonly profilePictureID: string,
    readonly profilePictureURL: string,
    readonly timeZone: string
  ) {
    if (email) this.email = email.trim();
    if (firstName) this.firstName = firstName.trim();
    if (lastName) this.lastName = lastName.trim();
    if (phone) this.phone = phone.trim();
    if (username) this.username = username.trim();
  }

  static fromSerialised(
    serialised: ISerialisedUnsavedUser | Partial<ISerialisedUnsavedUser>
  ) {
    return new UnsavedUser(
      serialised.email || null,
      serialised.firstName || null,
      serialised.lastName || null,
      serialised.locale || null,
      serialised.phone || null,
      serialised.photo || null,
      serialised.username || null,
      serialised.roles || null,
      serialised.password || null,
      serialised.tenants || null,
      serialised.active || null,
      serialised.profilePictureID || null,
      serialised.profilePictureURL || null,
      serialised.timeZone || null
    );
  }

  toSerialised(): ISerialisedUnsavedUser {
    return {
      email: this.email,
      firstName: this.firstName,
      lastName: this.lastName,
      locale: this.locale,
      phone: this.phone,
      photo: this.photo,
      username: this.username,
      roles: this.roles,
      password: this.password,
      tenants: this.tenants,
      active: this.active,
      profilePictureID: this.profilePictureID,
      profilePictureURL: this.profilePictureURL,
      timeZone: this.timeZone,
    };
  }
  clone(partialUnsavedUser: PartialUnsavedUser): UnsavedUser {
    const resolve = (key: keyof UnsavedUser) =>
      partialUnsavedUser.hasOwnProperty(key)
        ? partialUnsavedUser[key]
        : this[key];

    return new UnsavedUser(
      resolve('email'),
      resolve('firstName'),
      resolve('lastName'),
      resolve('locale'),
      resolve('phone'),
      resolve('photo'),
      resolve('username'),
      resolve('roles'),
      resolve('password'),
      resolve('tenants'),
      resolve('active'),
      resolve('profilePictureID'),
      resolve('profilePictureURL'),
      resolve('timeZone')
    );
  }
}
type PartialUnsavedUser = Partial<
  Pick<
    UnsavedUser,
    | 'email'
    | 'firstName'
    | 'lastName'
    | 'locale'
    | 'phone'
    | 'photo'
    | 'username'
    | 'roles'
    | 'password'
    | 'tenants'
    | 'active'
    | 'profilePictureID'
    | 'profilePictureURL'
    | 'timeZone'
  >
>;
