import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import {
  ActionButtons,
  PermissionType,
  CompanyTypeColor,
  PaginationResult,
} from '@fullyops/legacy/ui/ui-shared/utils/crm-types';
import { ComponentInOutAnimation } from '@fullyops/legacy/ui/ui-shared/utils/component-base-animation';
import { ResponseState, User } from '@fullyops/legacy/data';
import { BehaviorSubject } from 'rxjs';
import { MatLegacyTabGroup as MatTabGroup } from '@angular/material/legacy-tabs';
import { CompanyResponse } from '@fullyops/legacy/data/api/types/Company';

@Component({
  selector: 'crm-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss'],
  animations: [ComponentInOutAnimation.AnimTrigger],
})
export class CompaniesComponent implements OnInit {
  @ViewChild('tabs') tabs: MatTabGroup;

  @Input() title: string;
  @Input() filters$ = new BehaviorSubject({});
  @Input() listCompaniesPagination$ = new BehaviorSubject<
    PaginationResult<CompanyResponse>
  >(null);
  @Input() users$: BehaviorSubject<User[]>;

  @Output() openEvent = new EventEmitter<string>();
  @Output() addNewEvent = new EventEmitter();
  @Output() filtersChangeEvent = new EventEmitter<{}>();
  @Output() pageChangeEvent = new EventEmitter<number>();

  // Response Possible States
  state = ResponseState;

  actions: ActionButtons = {};

  displayedColumns = ['name', 'type'];
  CompanyTypeColor = CompanyTypeColor;

  ngOnInit() {
    this.initActionButtons();
  }

  initActionButtons() {
    this.actions.primary = {
      label: 'addNew',
      color: 'primary',
      permissions: [PermissionType.CAN_EDIT_COMPANIES],
    };
  }

  onActionEvent(event: string) {
    if (event === 'addNew') {
      this.onAddNew();
    }
  }

  onOpen(id: string) {
    this.openEvent.emit(id);
  }

  onAddNew() {
    this.addNewEvent.emit();
  }

  onFiltersChange(filters) {
    this.filtersChangeEvent.emit(filters);
  }

  onPageChange(pageIndex: number) {
    this.pageChangeEvent.emit(pageIndex);
  }
}
