import { Injectable } from '@angular/core';
import {
  LossMotive,
  LossMotivesEndpoint,
  UnsavedLossMotive,
} from '@fullyops/legacy/data';
import { UiService } from './ui.service';
@Injectable({
  providedIn: 'root',
})
export class UiLossMotivesService extends UiService<
  LossMotive,
  UnsavedLossMotive,
  LossMotivesEndpoint
> {
  constructor(protected lossMotivesEndpoint: LossMotivesEndpoint) {
    super(lossMotivesEndpoint.createStore(), lossMotivesEndpoint);
  }
}
