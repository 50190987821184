import { Directive, ElementRef, OnInit, Input } from '@angular/core';
import { PermissionType } from '@fullyops/legacy/ui/ui-shared/utils/crm-types';
import { UiAuthService } from '@fullyops/legacy/ui/ui-crm/ui-auth.service';
import { FeaturePermissionToggleServiceV2 } from '@fullyops/legacy/ui/ui-crm/v2/feature-toggles/ui-feature-permission-toggle.service';
import { FeaturePermissionLabels } from '@fullyops/legacy/data/api/types/FeaturePermissionToggle';

// TODO: We're mixing up concepts here, we should create a separate directive per concept instead, this is messy
@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[hideIfNotAllowed]',
})
export class HideIfNotAllowedDirective implements OnInit {
  @Input('hideIfNotAllowed') permissions: PermissionType[];
  @Input('featureToggle') featureToggleLabel: FeaturePermissionLabels;

  isToggleDisabledResponse = false;
  isPermissionApproved = true;

  constructor(
    private el: ElementRef,
    private authService: UiAuthService,
    private featurePermissionToggleService: FeaturePermissionToggleServiceV2
  ) {}

  ngOnInit() {
    if (!this.permissions) {
      return;
    }

    this.authService.hasPermission(this.permissions).subscribe((res) => {
      this.isPermissionApproved = res;
      this.checkToDisabledElement();
    });

    this.featurePermissionToggleService
      .isFeatureDisabled({
        featureLabel: this.featureToggleLabel,
      })
      .subscribe((res) => {
        this.isToggleDisabledResponse = res;
        this.checkToDisabledElement();
      });
  }

  checkToDisabledElement() {
    if (this.featureToggleLabel === undefined && this.isPermissionApproved) {
      return;
    }
    if (this.isToggleDisabledResponse || !this.isPermissionApproved) {
      this.el.nativeElement.style.display = 'none';
      this.el.nativeElement.remove();
      return;
    }
  }
}
