import { Response } from '@fullyops/legacy/data';
import { BehaviorSubject, Observable } from 'rxjs';

interface AbstractEndpoint<Model, UnsavedModel> {
  createStore(): BehaviorSubject<Response<Model[]>>;

  getAll(
    filter?: {},
    source?: Observable<{ results: any[] }>,
    store?: BehaviorSubject<Response<Model[]>>
  ): Observable<Response<Model[]>>;

  get(
    id: string,
    source?: Observable<{}>,
    store?: BehaviorSubject<Response<Model[]>>
  ): Observable<Response<Model>>;

  post(
    model: UnsavedModel,
    source?: Observable<{}>,
    store?: BehaviorSubject<Response<Model[]>>
  ): Observable<Response<Model>>;

  patch?(
    id: string,
    model: UnsavedModel,
    source?: Observable<{}>,
    store?: BehaviorSubject<Response<Model[]>>
  ): Observable<Response<Model>>;

  delete(
    id: string,
    source?: Observable<{}>,
    store?: BehaviorSubject<Response<Model[]>>
  ): Observable<Response<{}>>;
}

export abstract class UiService<
  Model,
  UnsavedModel,
  Endpoint extends AbstractEndpoint<Model, UnsavedModel>
> {
  constructor(
    public store$: BehaviorSubject<Response<Model[]>>,
    protected service: Endpoint
  ) {}

  loadAll(filters: {} = {}) {
    this.service
      .getAll(filters, undefined, this.store$)
      .subscribe({ error: console.error });
  }

  get(id: string): Observable<Response<Model>> {
    return this.service.get(id, undefined, this.store$);
  }

  create(unsavedModel: UnsavedModel) {
    return this.service.post(unsavedModel, undefined, this.store$);
  }

  update(id: string, unsavedModel: UnsavedModel) {
    return this.service.patch(id, unsavedModel, undefined, this.store$);
  }

  remove(id: string): Observable<Response<{}>> {
    return this.service.delete(id, undefined, this.store$);
  }
}
