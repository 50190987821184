import { ISerialisedUnsavedProductConfigSimulation } from './serialisations/ISerialisedProductConfigSimulation';
import { Product } from './Product';
import { ReportSection } from './ReportSection';

export class UnsavedProductConfigSimulation {
  constructor(
    readonly name: string,
    readonly status: string,
    readonly product: Product,
    readonly saleTicketId: string,
    readonly additionalInfo: ReportSection[]
  ) {
    if (name) this.name = name.trim();
    if (status) this.status = status.trim();
  }

  static fromSerialised(serialised: ISerialisedUnsavedProductConfigSimulation) {
    const product = Product.fromSerialised(serialised.product);
    const additionalInfo = serialised.additionalInfo.map(
      ReportSection.fromSerialised
    );

    return new UnsavedProductConfigSimulation(
      serialised.name,
      serialised.status,
      product,
      serialised.saleTicketId,
      additionalInfo
    );
  }

  toSerialised(): ISerialisedUnsavedProductConfigSimulation {
    const productObj = this.product.toSerialised();
    const additionalInfoObj = this.additionalInfo.map((info) =>
      info.toSerialised()
    );

    return {
      name: this.name,
      status: this.status,
      product: productObj,
      saleTicketId: this.saleTicketId,
      additionalInfo: additionalInfoObj,
    };
  }

  clone(
    partialProduct: PartialUnsavedProductConfigSimulation
  ): UnsavedProductConfigSimulation {
    const resolve = (key: keyof UnsavedProductConfigSimulation) =>
      partialProduct.hasOwnProperty(key) ? partialProduct[key] : this[key];

    return new UnsavedProductConfigSimulation(
      resolve('name'),
      resolve('status'),
      resolve('product'),
      resolve('saleTicketId'),
      resolve('additionalInfo')
    );
  }
}

type PartialUnsavedProductConfigSimulation = Partial<
  Pick<
    UnsavedProductConfigSimulation,
    'name' | 'status' | 'product' | 'saleTicketId' | 'additionalInfo'
  >
>;
