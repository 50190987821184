import { TicketRequest } from './TicketRequest';
import { ISerialisedHistoryLink } from './serialisations/ISerialisedHistoryLink';
import { Activity } from './Activity';
import { HistoryType } from '../types';
import { TicketSale } from './TicketSale';
import { TicketSupportMultimachine } from './TicketSupportMultimachine';
import { InterventionMachine } from './InterventionMachine';

export class HistoryLink {
  constructor(
    readonly id: string,
    readonly linkType: HistoryType,
    readonly activity: Activity | null,
    readonly requestTicket: TicketRequest | null,
    readonly supportTicket: TicketSupportMultimachine | null,
    readonly saleTicket: TicketSale | null,
    readonly intervention: InterventionMachine | null
  ) {}

  static fromSerialised(serialised: ISerialisedHistoryLink) {
    if (!serialised) {
      return null;
    }

    const activity = serialised.activity
      ? Activity.fromSerialised(serialised.activity)
      : null;
    const ticketRequest = serialised.requestTicket
      ? TicketRequest.fromSerialised(serialised.requestTicket)
      : null;
    const ticketSupport = serialised.supportTicket
      ? TicketSupportMultimachine.fromSerialised(serialised.supportTicket)
      : null;
    const ticketSale = serialised.saleTicket
      ? TicketSale.fromSerialised(serialised.saleTicket)
      : null;

    const intervention = serialised.intervention
      ? InterventionMachine.fromSerialised(serialised.intervention)
      : null;

    return new HistoryLink(
      serialised.id,
      serialised.linkType,
      activity,
      ticketRequest,
      ticketSupport,
      ticketSale,
      intervention
    );
  }

  toSerialised(): ISerialisedHistoryLink {
    const activityObj = this.activity ? this.activity.toSerialised() : null;
    const ticketSupportObj = this.supportTicket
      ? this.supportTicket.toSerialised()
      : null;
    const ticketRequestObj = this.requestTicket
      ? this.requestTicket.toSerialised()
      : null;
    const ticketSaleObj = this.saleTicket
      ? this.saleTicket.toSerialised()
      : null;
    const interventionObj = this.intervention
      ? this.intervention.toSerialised()
      : null;

    return {
      id: this.id,
      linkType: this.linkType,
      activity: activityObj,
      supportTicket: ticketSupportObj,
      requestTicket: ticketRequestObj,
      saleTicket: ticketSaleObj,
      intervention: interventionObj,
    };
  }
}
