import { User } from './User';
import { ISerialisedTicketSupportMultimachineAssignee } from './serialisations/ISerialisedTicketSupportMultimachineAssignee';

export class TicketSupportMultimachineAssignee {
  constructor(
    readonly id: string,
    readonly assignee: User,
    readonly createdAt: string,
    readonly createdBy: User,
    readonly deleted: boolean,
    readonly supportTicketId: string,
    readonly updatedAt: string,
    readonly updatedBy: User
  ) {}

  static fromSerialised(
    serialised: ISerialisedTicketSupportMultimachineAssignee
  ) {
    const assignee = User.fromSerialised(serialised.assignee);
    const createdBy = User.fromSerialised(serialised.createdBy);
    const updatedBy = User.fromSerialised(serialised.updatedBy);

    return new TicketSupportMultimachineAssignee(
      '',
      assignee,
      serialised.createdAt,
      createdBy,
      serialised.deleted,
      serialised.supportTicketId,
      serialised.updatedAt,
      updatedBy
    );
  }

  toSerialised(): ISerialisedTicketSupportMultimachineAssignee {
    const assigneeObj = this.assignee.toSerialised();
    const createdByObj = this.createdBy.toSerialised();
    const updatedByObj = this.updatedBy.toSerialised();

    return {
      id: '',
      assignee: assigneeObj,
      createdBy: createdByObj,
      updatedBy: updatedByObj,
      supportTicketId: this.supportTicketId,
      ...this,
    };
  }

  clone(
    partialTicketSupport: PartialTicketSupportMultimachineAssignee
  ): TicketSupportMultimachineAssignee {
    const resolve = (key: keyof TicketSupportMultimachineAssignee) =>
      partialTicketSupport.hasOwnProperty(key)
        ? partialTicketSupport[key]
        : this[key];

    return new TicketSupportMultimachineAssignee(
      resolve('id'),
      resolve('assignee'),
      resolve('createdAt'),
      resolve('createdBy'),
      resolve('deleted'),
      resolve('supportTicketId'),
      resolve('updatedAt'),
      resolve('updatedBy')
    );
  }
}

type PartialTicketSupportMultimachineAssignee = Partial<
  Pick<
    TicketSupportMultimachineAssignee,
    | 'id'
    | 'assignee'
    | 'createdAt'
    | 'createdBy'
    | 'deleted'
    | 'supportTicketId'
    | 'updatedAt'
    | 'updatedBy'
  >
>;
