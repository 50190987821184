<mat-table [dataSource]="scopes$">
  <ng-container matColumnDef="label">
    <mat-header-cell *matHeaderCellDef>{{
      'label' | i18nextCap
    }}</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <span>{{ 'workOrderScope' + element.label | i18nextCap }}</span>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="active">
    <mat-header-cell *matHeaderCellDef>{{
      'active' | i18nextCap
    }}</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <div
        [matTooltip]="'defaultOptionsCannotBeTurnedOff' | i18nextCap"
        [matTooltipDisabled]="!element.isDefault"
      >
        <mat-slide-toggle
          color="primary"
          checked="{{ element.active }}"
          disabled="{{ element.isDefault }}"
          (change)="toggleActive(element.label)"
        ></mat-slide-toggle>
      </div>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="isDefault">
    <mat-header-cell *matHeaderCellDef>{{
      'isDefault' | i18nextCap
    }}</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <!-- Only one of the scopes can be the default at any one time,
        so a slide toggle gives us exactly the visual semantics we need. -->
      <div
        [matTooltip]="'disabledOptionsCannotBeSetAsDefault' | i18nextCap"
        [matTooltipDisabled]="element.active"
      >
        <mat-slide-toggle
          color="primary"
          checked="{{ element.isDefault }}"
          disabled="{{ !element.active }}"
          (change)="setDefault(element.label)"
        ></mat-slide-toggle>
      </div>
    </mat-cell>
  </ng-container>

  <mat-header-row
    *matHeaderRowDef="tableHeaders; sticky: true"
  ></mat-header-row>

  <mat-row *matRowDef="let row; columns: tableHeaders"> </mat-row>
</mat-table>
<div *ngIf="noScopesFound$ | async" class="no-results">
  {{ 'noResults' | i18nextCap }}
</div>
