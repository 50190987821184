import { Response } from '../Response';
import { Endpoint } from '../Endpoint';
import { HttpClient } from '@angular/common/http';
import { IListResponse } from '../IListResponse';
import { Injectable, Inject } from '@angular/core';
import { API_SERVICE_CONFIG } from '../ApiServiceConfig';

import { Observable, BehaviorSubject } from 'rxjs';

import { IApiServiceConfig } from '../IApiServiceConfig';
import { map, tap } from 'rxjs/operators';
import { ISerialisedWorkPeriod } from '../../models/v0/serialisations/ISerialisedWorkPeriod';
import { WorkPeriod } from '../../models/v0/WorkPeriod';
import { UnsavedWorkPeriod } from '../../models/v0/UnsavedWorkPeriod';

type WorkPeriodList = IListResponse<ISerialisedWorkPeriod>;

@Injectable()
export class WorkPeriodEndpoint extends Endpoint<
  WorkPeriod,
  ISerialisedWorkPeriod
> {
  private readonly url = this.config.url + 'workperiods';

  constructor(
    @Inject(API_SERVICE_CONFIG) private config: IApiServiceConfig,
    private http: HttpClient
  ) {
    super(WorkPeriod.fromSerialised);
  }

  getAll(
    filters: {},
    source?: Observable<WorkPeriodList>,
    store?: BehaviorSubject<Response<WorkPeriod[]>>
  ): Observable<Response<WorkPeriod[]>> {
    let query = '';
    Object.keys(filters).forEach((key) => (query += `&${key}=${filters[key]}`));

    const defaultSource = this.http.get<WorkPeriodList>(
      `${this.url}?size=100&page=0${query}`
    );
    return this.list(source || defaultSource, store);
  }

  get(
    id: string,
    source?: Observable<ISerialisedWorkPeriod>,
    store?: BehaviorSubject<Response<WorkPeriod[]>>
  ): Observable<Response<WorkPeriod>> {
    const defaultSource = this.http.get<ISerialisedWorkPeriod>(
      `${this.url}${id}/`
    );
    return this.retrieve(source || defaultSource, store);
  }

  post(
    workPeriod: UnsavedWorkPeriod,
    source?: Observable<ISerialisedWorkPeriod>,
    store?: BehaviorSubject<Response<WorkPeriod[]>>
  ): Observable<Response<WorkPeriod>> {
    const defaultSource = this.http.post<ISerialisedWorkPeriod>(
      this.url,
      workPeriod.toSerialised()
    );
    return this.add(source || defaultSource, store);
  }

  patch(
    id: string,
    workPeriod: UnsavedWorkPeriod,
    source?: Observable<ISerialisedWorkPeriod>,
    store?: BehaviorSubject<Response<WorkPeriod[]>>
  ): Observable<Response<WorkPeriod>> {
    const { ...body } = workPeriod.toSerialised();
    const defaultSource = this.http.patch<ISerialisedWorkPeriod>(
      `${this.url}${id}/`,
      body
    );

    return defaultSource.pipe(
      tap((instance) =>
        this.update(
          source || defaultSource,
          WorkPeriod.fromSerialised(instance),
          store
        )
      ),
      map((serialiser) => new Response(WorkPeriod.fromSerialised(serialiser)))
    );
  }

  delete(
    id: string,
    source?: Observable<{}>,
    store?: BehaviorSubject<Response<WorkPeriod[]>>
  ): Observable<Response<{}>> {
    const defaultSource = this.http.delete<{}>(`${this.url}/${id}/`);
    return this.remove(source || defaultSource, id, store);
  }
}
