import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormArray,
  Validators,
  ValidatorFn,
  AbstractControl,
} from '@angular/forms';
import {
  UnsavedProductConfig,
  SubProduct,
  Product,
  ReportSection,
} from '@fullyops/legacy/data';

const validateSubProduct = (): ValidatorFn => {
  return (control: AbstractControl) => {
    return control.value.find(
      (subproduct) => subproduct.variantId === null && subproduct.checked
    )
      ? control.value
      : null;
  };
};

class ProductForm extends UntypedFormGroup {
  constructor(product: Product, dis: boolean) {
    super({});

    if (!product)
      product = new Product('', '', 0, 0, '', '', [], [], {}, [], null, null);

    Object.keys(product).forEach((key) =>
      this.addControl(
        key,
        new UntypedFormControl({ value: product[key], disabled: dis }, [])
      )
    );
  }
}

class ProductConfigForm extends UntypedFormGroup {
  constructor(productConfig: UnsavedProductConfig, dis: boolean) {
    super({});

    this.addControl(
      'name',
      new UntypedFormControl(
        { value: productConfig.name, disabled: dis },
        Validators.required
      )
    );
    this.addControl(
      'status',
      new UntypedFormControl(
        { value: productConfig.status, disabled: dis },
        Validators.required
      )
    );
    this.addControl(
      'productId',
      new UntypedFormControl(
        {
          value: productConfig.product ? productConfig.product.id : null,
          disabled: dis,
        },
        Validators.required
      )
    );
    this.addControl('product', new ProductForm(productConfig.product, true));

    this.addControl(
      'subproductConfigs',
      new UntypedFormArray(
        productConfig.product.subproducts.map((subproduct: SubProduct) => {
          return new UntypedFormGroup({
            id: new UntypedFormControl(
              { value: subproduct.id, disabled: dis },
              Validators.required
            ),
            checked: new UntypedFormControl(
              { value: false, disabled: dis },
              Validators.required
            ),
            variantId: new UntypedFormControl(
              { value: null, disabled: dis },
              []
            ),
          });
        }),
        [validateSubProduct()]
      )
    );
    this.addControl(
      'additionalInfo',
      new UntypedFormArray(
        productConfig.additionalInfo.map(
          (section) =>
            new UntypedFormGroup(
              {
                section: new UntypedFormControl(
                  { value: section.section, disabled: dis },
                  Validators.required
                ),
                content: new UntypedFormArray(
                  section.content.map(
                    (subsection) =>
                      new UntypedFormGroup(
                        {
                          title: new UntypedFormControl(
                            { value: subsection.title, disabled: dis },
                            Validators.required
                          ),
                          content: new UntypedFormArray(
                            subsection.content.map(
                              (line) =>
                                new UntypedFormGroup(
                                  {
                                    url: new UntypedFormControl(
                                      { value: line.url, disabled: dis },
                                      []
                                    ),
                                    data: new UntypedFormArray(
                                      line.data.map(
                                        (value) =>
                                          new UntypedFormControl(
                                            { value, disabled: dis },
                                            Validators.required
                                          )
                                      ),
                                      []
                                    ),
                                  },
                                  Validators.required
                                )
                            ),
                            Validators.required
                          ),
                        },
                        Validators.required
                      )
                  ),
                  []
                ),
              },
              []
            )
        ),
        []
      )
    );
  }
}

@Injectable()
export class ProductConfigFormService {
  fb = new UntypedFormBuilder();

  private initialProductConfig: BehaviorSubject<UnsavedProductConfig>;
  private products$: BehaviorSubject<Product[]> = new BehaviorSubject([]);
  private form: BehaviorSubject<UntypedFormGroup> =
    new BehaviorSubject<UntypedFormGroup>(new UntypedFormGroup({}));
  subProducts$: BehaviorSubject<SubProduct[]> = new BehaviorSubject([]);
  form$: Observable<UntypedFormGroup> = this.form.asObservable();
  imageUrl$ = new BehaviorSubject<string>('');

  constructor() {}

  setProducts(products$: BehaviorSubject<Product[]>) {
    this.products$ = products$;
  }

  initForm(draftProductConfig: UnsavedProductConfig, disabled: boolean) {
    this.initialProductConfig = new BehaviorSubject(draftProductConfig);

    this.form.next(new ProductConfigForm(draftProductConfig, disabled));
    this.subProducts$.next(draftProductConfig.product.subproducts);

    (this.form.getValue() as UntypedFormGroup)
      .get('productId')
      .valueChanges.subscribe((productId) => {
        const tempForm = this.form.getValue();
        const product = this.products$
          .getValue()
          .find((p) => p.id === productId);

        if (product) {
          if (product.imageUrls?.length > 0) {
            this.imageUrl$.next(product.imageUrls[0]);
          } else {
            this.imageUrl$.next('');
          }
        } else {
          this.imageUrl$.next('');
        }

        tempForm.setControl('product', new ProductForm(product, true));

        if (product) {
          tempForm.setControl(
            'subproductConfigs',
            new UntypedFormArray(
              product.subproducts.map((subproduct: SubProduct) => {
                return new UntypedFormGroup({
                  id: new UntypedFormControl(
                    { value: subproduct.id, disabled: false },
                    Validators.required
                  ),
                  checked: new UntypedFormControl(
                    { value: false, disabled: false },
                    Validators.required
                  ),
                  variantId: new UntypedFormControl(
                    { value: null, disabled: false },
                    []
                  ),
                });
              }),
              [validateSubProduct()]
            )
          );
          this.subProducts$.next(product.subproducts);
        }

        this.form.next(tempForm);
      });
  }

  getDraft() {
    const productConfigDraft = (this.form.getValue() as UntypedFormGroup).value;
    const product = this.products$
      .getValue()
      .find((p) => p.id === productConfigDraft.productId);

    return new UnsavedProductConfig(
      (this.form.getValue().get('name') as UntypedFormControl).value,
      (this.form.getValue().get('status') as UntypedFormControl).value,
      product.clone({
        subproducts: product.subproducts
          .filter((subproduct) =>
            productConfigDraft.subproductConfigs.find(
              (sp) => sp.id === subproduct.id && sp.checked
            )
          )
          .map((subproduct) =>
            subproduct.clone({
              variants: [
                subproduct.variants.find(
                  (variant) =>
                    productConfigDraft.subproductConfigs.find(
                      (sp) => sp.id === subproduct.id
                    ).variantId === variant.id
                ),
              ],
            })
          ),
      }),
      (
        this.form.getValue().get('additionalInfo') as UntypedFormArray
      ).controls.map((section) => ReportSection.fromSerialised(section.value))
    );
  }

  markAllAsTouched() {
    const currentProductConfig = this.form.getValue();
    currentProductConfig.markAllAsTouched();

    this.form.next(currentProductConfig);
  }
}
