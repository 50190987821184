import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CustomError } from '@fullyops/core/errors/custom-error';

@Component({
  selector: 'internal-error',
  templateUrl: './internal-error.component.html',
  styleUrls: ['./internal-error.component.scss'],
})
export class InternalErrorComponent {
  error?: CustomError;

  constructor(private router: Router) {
    // beware of calling getCurrentNavigation() within the constructor, everything later
    // is too late, because the navigation step will be executed and the state is lost.
    const state = this.router.getCurrentNavigation()?.extras?.state;
    if (state) {
      this.error = state['lastError'] as CustomError;
    }
  }

  public restartApp(): void {
    window.location.href = '/';
  }
}
