import { ClientInfoRequest } from '../../api/types/Client';
import { SaleTicketInfo } from './SaleTicketInfo';
import { ISerialisedUnsavedTicketSale } from './serialisations/ISerialisedTicketSale';

export class UnsavedTicketSale {
  constructor(
    readonly name: string,
    readonly priority: string,
    readonly statusId: string,
    readonly assigneeIds: string[],
    readonly companyId: string,
    readonly contactIds: string[],
    readonly notes: string,
    readonly createdAt: Date,
    readonly saleDate: Date | null,
    readonly saleReturn: number,
    readonly won: boolean | null,
    readonly lossMotiveId: string | null,
    readonly productConfigurationIds: string[],
    readonly simulationsIds: string[],
    readonly saleTicketInfo: SaleTicketInfo,
    readonly clientInfo: ClientInfoRequest
  ) {
    if (name) this.name = name.trim();
    if (priority) this.priority = priority.trim();
    if (notes) this.notes = notes.trim();
  }

  static fromSerialised(serialised: ISerialisedUnsavedTicketSale) {
    const saleTicketInfo = SaleTicketInfo.fromSerialised(
      serialised.saleTicketInfo
    );

    return new UnsavedTicketSale(
      serialised.name,
      serialised.priority,
      serialised.statusId,
      serialised.assigneeIds,
      serialised.companyId,
      serialised.contactIds,
      serialised.notes,
      new Date(serialised.createdAt),
      serialised.saleDate ? new Date(serialised.saleDate) : null,
      serialised.saleReturn,
      serialised.won,
      serialised.lossMotiveId,
      serialised.productConfigurationIds,
      serialised.simulationsIds,
      saleTicketInfo,
      {
        address: serialised.clientInfo.address,
        city: serialised.clientInfo.city,
        countryISOCode: serialised.clientInfo.countryISOCode,
        distance: serialised.clientInfo.distance,
        zipCode: serialised.clientInfo.zipCode,
      }
    );
  }

  toSerialised(): ISerialisedUnsavedTicketSale {
    return {
      name: this.name,
      priority: this.priority,
      statusId: this.statusId,
      assigneeIds: this.assigneeIds,
      companyId: this.companyId,
      contactIds: this.contactIds,
      notes: this.notes,
      createdAt: this.createdAt.toISOString(),
      saleDate: this.saleDate ? this.saleDate.toISOString() : null,
      saleReturn: this.saleReturn,
      won: this.won,
      lossMotiveId: this.lossMotiveId,
      productConfigurationIds: this.productConfigurationIds,
      simulationsIds: this.simulationsIds,
      saleTicketInfo: this.saleTicketInfo,
      clientInfo: this.clientInfo,
    };
  }

  clone(partialTicketSale: PartialUnsavedTicketSale): UnsavedTicketSale {
    const resolve = (key: keyof UnsavedTicketSale) =>
      partialTicketSale.hasOwnProperty(key)
        ? partialTicketSale[key]
        : this[key];

    return new UnsavedTicketSale(
      resolve('name'),
      resolve('priority'),
      resolve('statusId'),
      resolve('assigneeIds'),
      resolve('companyId'),
      resolve('contactIds'),
      resolve('notes'),
      resolve('createdAt'),
      resolve('saleDate'),
      resolve('saleReturn'),
      resolve('won'),
      resolve('lossMotiveId'),
      resolve('productConfigurationIds'),
      resolve('simulationsIds'),
      resolve('saleTicketInfo'),
      resolve('clientInfo')
    );
  }
}

type PartialUnsavedTicketSale = Partial<
  Pick<
    UnsavedTicketSale,
    | 'name'
    | 'priority'
    | 'statusId'
    | 'assigneeIds'
    | 'companyId'
    | 'contactIds'
    | 'notes'
    | 'saleTicketInfo'
    | 'saleDate'
    | 'createdAt'
    | 'saleReturn'
    | 'won'
    | 'lossMotiveId'
    | 'productConfigurationIds'
    | 'simulationsIds'
    | 'clientInfo'
  >
>;
