import { TemplateFormFields } from './../form-template/form-template.component';
import {
  Component,
  ViewChild,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { UntypedFormGroup, NgForm } from '@angular/forms';
import {
  AbstractFormService,
  AbstractModel,
  ActionButtons,
  FormType,
  PermissionType,
  RoleGroup,
} from './crm-types';
import { BehaviorSubject } from 'rxjs';
import { SnackbarService } from '@fullyops/legacy/ui/ui-crm/ui-snackbar.service';
import { UiAuthService } from '@fullyops/legacy/ui/ui-crm/ui-auth.service';

@Component({ template: '' })
export abstract class FormComponent<
  Model extends AbstractModel<UnsavedModel>,
  UnsavedModel,
  FormService extends AbstractFormService<UnsavedModel>
> {
  @ViewChild('ngForm')
  ngForm: NgForm;

  @Input() preview = false;
  @Input() entity?: Model;
  @Output() editEvent = new EventEmitter();
  @Output() deleteEvent = new EventEmitter();
  @Output() cancelEvent = new EventEmitter();
  @Output() saveEvent = new EventEmitter<UnsavedModel>();

  draft$: BehaviorSubject<UnsavedModel>;

  form: UntypedFormGroup;
  formType: FormType = FormType.Details;
  formService: FormService;
  formFields: TemplateFormFields<any>;
  actions: ActionButtons = {};

  formEditPermissions: PermissionType[];
  invalidFormMessage = 'Some fields are invalid';
  companyEmailRequired = true;

  constructor(
    public snackBar: SnackbarService,
    public authService: UiAuthService
  ) {}

  initForm() {
    if (this.entity) {
      this.draft$.next(this.entity.toDraft());
      this.formType = this.preview ? FormType.Details : FormType.Edit;
    } else {
      this.formType = FormType.New;
    }

    this.formService.initForm(
      this.draft$.getValue(),
      this.preview,
      this.companyEmailRequired
    );
    this.formService.form$.subscribe((form) => (this.form = form));

    this.initActionButtons();
  }

  initActionButtons() {
    switch (this.formType) {
      case 'details':
        break;
      case 'edit':
        if (
          this.authService.hasRole(RoleGroup.ADMIN) ||
          this.authService.hasRole(RoleGroup.MANAGER)
        ) {
          this.actions.warn = {
            label: 'delete',
            color: 'warn',
            dataTestId: 'delete-ticket-test',
          };
        }
        break;
      case 'new':
        this.actions.secondary = { label: 'cancel', color: 'secondary' };
        break;
    }
  }

  onActionEvent(event: string) {
    switch (event) {
      case 'create':
        this.ngForm.onSubmit(null);
        break;
      case 'save':
        this.ngForm.onSubmit(null);
        break;
      case 'cancel':
        this.onCancel();
        break;
      case 'delete':
        this.onDelete();
        break;
      case 'edit':
        this.onEdit();
        break;
      default:
        break;
    }
  }

  onSubmit(form?: UntypedFormGroup) {
    if (form ? form.valid : this.ngForm.valid) {
      this.saveEvent.emit(this.formService.getDraft());
    } else {
      this.formService.markAllAsTouched();
      this.snackBar.openErrorFormMissingFields();
    }
  }

  onCancel() {
    this.cancelEvent.emit();
  }

  onEdit() {
    this.editEvent.emit();
  }

  onDelete() {
    this.deleteEvent.emit();
  }
}
