import { Injectable } from '@angular/core';
import { createStore, withProps } from '@ngneat/elf';
import {
  addEntities,
  deleteEntities,
  getEntity,
  getEntityByPredicate,
  selectAllEntities,
  setEntities,
  updateEntities,
  updateEntitiesIds,
  withEntities,
} from '@ngneat/elf-entities';
import { withRequestsStatus } from '@ngneat/elf-requests';
import { WorkOrderCategoryModel } from '../models/work-order-category.model';

export interface WorkOrderCategoriesProps {}

export const store = createStore(
  { name: 'work-order-categories' },
  withProps<WorkOrderCategoriesProps>({}),
  withEntities<WorkOrderCategoryModel, 'id'>({ idKey: 'id' }),
  withRequestsStatus<'work-order-categories'>()
);

export const workOrderCategories$ = store.pipe(selectAllEntities());

@Injectable({ providedIn: 'root' })
export class WorkOrderCategoriesRepository {
  set(categories: WorkOrderCategoryModel[]) {
    store.update(setEntities(categories));
  }

  add(category: WorkOrderCategoryModel) {
    store.update(addEntities(category));
  }

  update(
    id: WorkOrderCategoryModel['id'],
    category: Partial<WorkOrderCategoryModel>
  ) {
    store.update(updateEntities(id, category));
  }

  updateId(
    oldId: WorkOrderCategoryModel['id'],
    newId: WorkOrderCategoryModel['id']
  ) {
    store.update(updateEntitiesIds(oldId, newId));
  }

  delete(id: WorkOrderCategoryModel['id']) {
    store.update(deleteEntities(id));
  }

  query(uuid: WorkOrderCategoryModel['id']): WorkOrderCategoryModel | null {
    return store.query(getEntity(uuid)) ?? null;
  }

  getByLabel(label: string): WorkOrderCategoryModel | null {
    return store.query(
      getEntityByPredicate(({ label: categoryLabel }) => categoryLabel == label)
    );
  }

  getByName(name: string): WorkOrderCategoryModel | null {
    return store.query(
      getEntityByPredicate(({ name: categoryName }) => categoryName == name)
    );
  }

  getByExternalId(externalId: string): WorkOrderCategoryModel | null {
    return store.query(
      getEntityByPredicate(
        ({ externalId: categoryExternalId }) => categoryExternalId == externalId
      )
    );
  }

  existsByLabel(label: string): boolean {
    return this.getByLabel(label) != null;
  }

  existsByName(name: string): boolean {
    return this.getByName(name) != null;
  }

  existsByExternalId(externalId: string): boolean {
    return this.getByExternalId(externalId) != null;
  }
}
