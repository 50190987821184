import { ISerialisedInterventionClientEquipment } from './serialisations/ISerialisedInterventionClientEquipment';
import { UnsavedInterventionClientEquipment } from './UnsavedInterventionClientEquipment';
import { User } from './User';

export class InterventionClientEquipment {
  constructor(
    readonly id: string,
    readonly clientEquipmentId: string,
    readonly createdAt: string,
    readonly createdBy: User | null,
    readonly interventionId: string,
    readonly updatedAt: string,
    readonly updatedBy: User | null
  ) {}

  static fromSerialised(serialised: ISerialisedInterventionClientEquipment) {
    const createdBy = serialised.createdBy
      ? User.fromSerialised(serialised.createdBy)
      : null;
    const updatedBy = serialised.updatedBy
      ? User.fromSerialised(serialised.updatedBy)
      : null;

    return new InterventionClientEquipment(
      serialised.id,
      serialised.clientEquipmentId,
      serialised.createdAt,
      createdBy,
      serialised.interventionId,
      serialised.updatedAt,
      updatedBy
    );
  }

  toSerialised(): ISerialisedInterventionClientEquipment {
    const createdByObj = this.createdBy.toSerialised();
    const updatedByObj = this.updatedBy.toSerialised();

    return {
      id: this.id,
      clientEquipmentId: this.clientEquipmentId,
      createdAt: this.createdAt,
      createdBy: createdByObj,
      interventionId: this.interventionId,
      updatedAt: this.updatedAt,
      updatedBy: updatedByObj,
    };
  }

  toDraft(): UnsavedInterventionClientEquipment {
    const interventionClientEquipment = this.toSerialised();

    return UnsavedInterventionClientEquipment.fromSerialised({
      id: undefined,
      clientEquipmentId: interventionClientEquipment.clientEquipmentId,
      createdAt: undefined,
      createdBy: interventionClientEquipment.createdBy,
      interventionId: this.interventionId,
      updatedAt: undefined,
      updatedBy: interventionClientEquipment.updatedBy,
    });
  }

  clone(
    partialInterventionClientEquipment: InterventionClientEquipmentPartial
  ): InterventionClientEquipment {
    const resolve = (key: keyof InterventionClientEquipment) =>
      partialInterventionClientEquipment.hasOwnProperty(key)
        ? partialInterventionClientEquipment[key]
        : this[key];

    return new InterventionClientEquipment(
      resolve('id'),
      resolve('clientEquipmentId'),
      resolve('createdAt'),
      resolve('createdBy'),
      resolve('interventionId'),
      resolve('updatedAt'),
      resolve('updatedBy')
    );
  }
}
type InterventionClientEquipmentPartial = Partial<
  Pick<
    InterventionClientEquipment,
    | 'id'
    | 'clientEquipmentId'
    | 'createdAt'
    | 'createdBy'
    | 'interventionId'
    | 'updatedAt'
    | 'updatedBy'
  >
>;
