import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export abstract class UiServiceV2 {
  protected createQueryParams = (params) => {
    return Object.keys(params)
      .map((k) => {
        return `${k}=${encodeURI(params[k])}`;
      })
      .join('&');
  };
}
