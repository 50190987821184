import { Injectable } from '@angular/core';
import { UploadEndpoint, UnsavedUpload } from '@fullyops/legacy/data';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UiUploadService {
  constructor(protected upload: UploadEndpoint) {}

  uploadImage(file: UnsavedUpload): Observable<{ url: string }> {
    return this.upload.post(file);
  }
}
