import { Response } from '../Response';
import { Endpoint } from '../Endpoint';
import { HttpClient } from '@angular/common/http';
import { IListResponse } from '../IListResponse';
import { Injectable, Inject } from '@angular/core';
import { API_SERVICE_CONFIG } from '../ApiServiceConfig';

import { Observable, BehaviorSubject } from 'rxjs';

import { Contact } from '../../models/v0/Contact';
import { ISerialisedContact } from '../../models/v0/serialisations/ISerialisedContact';
import { IApiServiceConfig } from '../IApiServiceConfig';
import { UnsavedContact } from '../../models/v0/UnsavedContact';
import { map, tap } from 'rxjs/operators';

type ContactList = IListResponse<ISerialisedContact>;

@Injectable()
export class ContactsEndpoint extends Endpoint<Contact, ISerialisedContact> {
  private readonly url = this.config.url + 'contacts';

  constructor(
    @Inject(API_SERVICE_CONFIG) private config: IApiServiceConfig,
    private http: HttpClient
  ) {
    super(Contact.fromSerialised);
  }

  getAll(
    filters?: {},
    source?: Observable<ContactList>,
    store?: BehaviorSubject<Response<Contact[]>>
  ): Observable<Response<Contact[]>> {
    let filtersStr = '';
    Object.keys(filters).forEach(
      (key) => (filtersStr += `&${key}=${filters[key]}`)
    );

    const defaultSource = this.http.get<ContactList>(
      `${this.url}?pageNumber=0${filtersStr}`
    );
    return this.list(source || defaultSource, store);
  }

  get(
    id: string,
    source?: Observable<ISerialisedContact>,
    store?: BehaviorSubject<Response<Contact[]>>
  ): Observable<Response<Contact>> {
    const defaultSource = this.http.get<ISerialisedContact>(
      `${this.url}/${id}/`
    );
    return this.retrieve(source || defaultSource, store);
  }

  post(
    contact: UnsavedContact,
    source?: Observable<ISerialisedContact>,
    store?: BehaviorSubject<Response<Contact[]>>
  ): Observable<Response<Contact>> {
    const defaultSource = this.http.post<ISerialisedContact>(
      this.url,
      contact.toSerialised()
    );
    return this.add(source || defaultSource, store);
  }

  patch(
    id: string,
    contact: UnsavedContact,
    source?: Observable<ISerialisedContact>,
    store?: BehaviorSubject<Response<Contact[]>>
  ) {
    const { ...body } = contact.toSerialised();
    const defaultSource = this.http.patch<ISerialisedContact>(
      `${this.url}/${id}/`,
      body
    );

    return defaultSource.pipe(
      tap((instance) =>
        this.update(
          source || defaultSource,
          Contact.fromSerialised(instance),
          store
        )
      ),
      map((serialiser) => new Response(Contact.fromSerialised(serialiser)))
    );
  }

  delete(
    id: string,
    source?: Observable<{}>,
    store?: BehaviorSubject<Response<Contact[]>>
  ): Observable<Response<{}>> {
    const defaultSource = this.http.delete<{}>(`${this.url}/${id}/`);
    return this.remove(source || defaultSource, id, store);
  }
}
