import { ISerialisedAccessToken } from './v0/serialisations/ISerialisedAccessToken';

export class AccessToken {
  constructor(readonly id: string, readonly accessToken: string) {}

  static fromSerialised(serialised: ISerialisedAccessToken) {
    return new AccessToken(serialised.id, serialised.access_token);
  }

  toSerialised(): ISerialisedAccessToken {
    return {
      id: this.id,
      access_token: this.accessToken,
    };
  }
}
