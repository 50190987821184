import { Injectable } from '@angular/core';
import { WorkOrderCategoriesService } from '@fullyops/domain/work-order-categories/services/work-order-categories.service';
import { BehaviorSubject } from 'rxjs';
import { SelectOptionModel } from '../models/form-model.models';

@Injectable({ providedIn: 'root' })
export class WorkOrderCategoryUtils {
  constructor(private service: WorkOrderCategoriesService) {}

  activeWorkOrderCategoriesOptions$ = new BehaviorSubject<SelectOptionModel[]>(
    []
  );

  getOptions$() {
    this.service.activeWorkOrderCategories$.subscribe((categories) => {
      const options: SelectOptionModel[] = categories.map((category) => {
        return {
          id: category.id,
          name: category.name,
        };
      });
      this.activeWorkOrderCategoriesOptions$.next(options);
    });

    return this.activeWorkOrderCategoriesOptions$;
  }
}
