import { Component, Inject } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';

export interface DialogSaveData {
  title: string;
  message: string;
}

@Component({
  selector: 'crm-dialog-save',
  templateUrl: './dialog-save.component.html',
})
export class DialogSaveComponent {
  constructor(
    public dialogRef: MatDialogRef<DialogSaveComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogSaveData
  ) {}

  onCancel(): boolean {
    this.dialogRef.close();
    return false;
  }
  onSave(): boolean {
    this.dialogRef.close();
    return true;
  }
}
