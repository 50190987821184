import { TemplateFormFields } from '@fullyops/legacy/ui/ui-shared/form-template/form-template.component';
import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { Part } from '@fullyops/legacy/data';
import { BehaviorSubject } from 'rxjs';
import {
  UntypedFormControl,
  UntypedFormArray,
  UntypedFormGroup,
} from '@angular/forms';

@Component({
  selector: 'crm-ticket-request-form-parts',
  templateUrl: './ticket-request-form-parts.component.html',
  styleUrls: ['./ticket-request-form-parts.component.scss'],
})
export class TicketRequestFormPartsComponent implements OnInit {
  @Input() formGroup: UntypedFormArray;
  @Input() parts$: BehaviorSubject<Part[]>;
  @Output() addPartEvent = new EventEmitter<Part>();
  @Output() removePartEvent = new EventEmitter<string>();
  @Output() createPartEvent = new EventEmitter<string>();

  formFields: any[] = [];

  autocompletePartsList$ = new BehaviorSubject<Part[]>([]);
  selectedParts = [];
  partsCreated = [];

  ngOnInit() {
    this.initAutocompletePartsList();

    this.updateFields();

    this.handleFieldChanges();

    this.parts$.subscribe((parts) => {
      if (this.partsCreated.length) {
        this.partsCreated.forEach((partName, index) => {
          const part = parts.find((p) => p.name === partName);
          if (part) {
            this.onAddPart(part);
            this.partsCreated.splice(index, 1);
          }
        });
      }

      this.updateAutocompletePartsList();
    });
  }

  initAutocompletePartsList() {
    this.selectedParts = Object.keys(this.formGroup.controls).map(
      (partControl) =>
        (this.formGroup.controls[partControl] as UntypedFormControl).get(
          'partId'
        ).value
    );

    this.updateAutocompletePartsList();
  }

  updateAutocompletePartsList() {
    this.autocompletePartsList$.next(
      this.parts$
        .getValue()
        .filter((p) => !this.selectedParts.includes(p.ref))
        .slice()
    );
  }

  handleFieldChanges() {
    this.formGroup.valueChanges.subscribe((_) => this.updateFields());
  }

  updateFields() {
    this.formFields = Object.keys(this.formGroup.controls).map(
      (partControlIndex) => {
        const partFormGroup = this.formGroup.controls[
          partControlIndex
        ] as UntypedFormGroup;

        return {
          control: partFormGroup,
          fields: [
            [
              { name: 'ref', type: 'text', label: 'partRef', size: 2 },
              { name: 'name', type: 'text', label: 'partName', size: 6 },
              { name: 'quantity', type: 'number', label: 'quantity', size: 2 },
              this.formGroup.enabled
                ? {
                    name: 'remove',
                    type: 'button',
                    label: 'remove',
                    size: 2,
                    onClick: () => this.removePartEvent.emit(partControlIndex),
                  }
                : {},
            ],
          ],
        };
      }
    );
  }

  onChange({ source, value }) {
    if (value === 'click') {
      const partId = source.get('partId').value;
      this.selectedParts.splice(this.selectedParts.indexOf(partId), 1);
      this.updateAutocompletePartsList();
      this.removePartEvent.emit(partId);
    }
  }

  onAddPart(part: Part) {
    this.selectedParts.push(part.id);
    this.updateAutocompletePartsList();
    this.addPartEvent.emit(part);
  }

  onCreatePart(partName: string) {
    this.createPartEvent.emit(partName);
    this.partsCreated.push(partName);
  }
}
