import { ISerialisedStatus } from './serialisations/ISerialisedStatus';
import { ISerialisedUnsavedUser } from './serialisations/ISerialisedUser';
import { ISerialisedUnsavedInstallationTicket } from './serialisations/ISerialisedInstallationTicket';
import { ISerialisedUnsavedInterventionMachine } from './serialisations/ISerialisedInterventionMachine';
import { ClientEquipment } from './ClientEquipment';
import { ClientInfo } from './ClientInfo';

export class UnsavedTicketInstallation {
  constructor(
    readonly assigneeIds: string[],
    readonly closedAt: string,
    readonly companyId: string,
    readonly createdAt: Date,
    readonly contactIds: string[],
    readonly clientInfo: ClientInfo,
    readonly equipments: Object, //  Need to create another model
    readonly executedAt: string,
    readonly interventions: ISerialisedUnsavedInterventionMachine[],
    readonly name: string,
    readonly priority: 'LOW' | 'MEDIUM' | 'HIGH' | 'URGENT',
    readonly statusId: string
  ) {
    if (name) this.name = name.trim();
  }

  static fromSerialised(serialised: ISerialisedUnsavedInstallationTicket) {
    const clientInfo = ClientInfo.fromSerialised(serialised.clientInfo);

    return new UnsavedTicketInstallation(
      serialised.assigneeIds,
      serialised.closedAt,
      serialised.companyId,
      serialised.createdAt ? new Date(serialised.createdAt) : null,
      serialised.contactIds,
      clientInfo,
      serialised.equipments,
      serialised.executedAt,
      serialised.interventions,
      serialised.name,
      serialised.priority,
      serialised.statusId
    );
  }

  toSerialised(): ISerialisedUnsavedInstallationTicket {
    const clientInfoObj = this.clientInfo.toSerialised();

    return {
      assigneeIds: this.assigneeIds,
      closedAt: this.closedAt,
      companyId: this.companyId,
      contactIds: this.contactIds,
      clientInfo: clientInfoObj,
      equipments: this.equipments,
      executedAt: this.executedAt,
      interventions: this.interventions,
      name: this.name,
      priority: this.priority,
      statusId: this.statusId,
      createdAt: this.createdAt.toISOString(),
    };
  }

  clone(
    partialUnsavedTicketSupportMultimachine: PartialUnsavedTicketInstallation
  ): UnsavedTicketInstallation {
    const resolve = (key: keyof UnsavedTicketInstallation) =>
      partialUnsavedTicketSupportMultimachine.hasOwnProperty(key)
        ? partialUnsavedTicketSupportMultimachine[key]
        : this[key];
    return new UnsavedTicketInstallation(
      resolve('assigneeIds'),
      resolve('closedAt'),
      resolve('companyId'),
      resolve('createdAt'),
      resolve('contactIds'),
      resolve('clientInfo'),
      resolve('equipments'),
      resolve('executedAt'),
      resolve('interventions'),
      resolve('name'),
      resolve('priority'),
      resolve('statusId')
    );
  }
}

type PartialUnsavedTicketInstallation = Partial<
  Pick<
    UnsavedTicketInstallation,
    | 'assigneeIds'
    | 'closedAt'
    | 'companyId'
    | 'contactIds'
    | 'clientInfo'
    | 'createdAt'
    | 'equipments'
    | 'executedAt'
    | 'interventions'
    | 'name'
    | 'priority'
    | 'statusId'
  >
>;
