import {
  Directive,
  ElementRef,
  OnInit,
  Input,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import {
  PermissionType,
  PermissionsType,
} from '@fullyops/legacy/ui/ui-shared/utils/crm-types';
import { UiAuthService } from '@fullyops/legacy/ui/ui-crm/ui-auth.service';

interface PermissionInput {
  permissions: PermissionsType[] | PermissionType[];
  ownerId?: string;
}

@Directive({
  selector: '[hasPermission]',
})
export class HasPermissionDirective implements OnInit {
  private permissions = [];
  private ownerId: string;

  @Input()
  set hasPermission(val: PermissionInput) {
    this.permissions = val.permissions;
    this.ownerId = val.ownerId;
    this.updateView();
  }

  constructor(
    private element: ElementRef,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authService: UiAuthService
  ) {}

  ngOnInit() {
    if (!this.permissions) {
      return this.viewContainer.clear();
    }
  }

  private updateView() {
    this.authService
      .hasPermission(this.permissions, this.ownerId)
      .subscribe((permission) => {
        permission
          ? this.viewContainer.createEmbeddedView(this.templateRef)
          : this.viewContainer.clear();
      });
  }
}
