import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { SubProduct } from '@fullyops/legacy/data';

@Component({
  selector: 'crm-sub-products',
  templateUrl: './sub-products.component.html',
  styleUrls: ['./sub-products.component.scss'],
})
export class SubProductsComponent implements OnInit {
  @Input() subProducts: SubProduct[];
  @Output() newSupProductEvent = new EventEmitter();
  @Output() editSupProductEvent = new EventEmitter<string>();
  @Output() deleteSupProductEvent = new EventEmitter<string>();
  @Output() editVariantEvent = new EventEmitter<{
    subProductId: string;
    variantId: string;
  }>();

  selectedSubProduct = -1;

  ngOnInit() {}

  selectSubProduct(subProductIndex: number) {
    this.selectedSubProduct === subProductIndex
      ? (this.selectedSubProduct = -1)
      : (this.selectedSubProduct = subProductIndex);
  }

  editVariant(subProductId: string, variantId: string) {
    this.editVariantEvent.emit({ subProductId, variantId });
  }

  newSupProduct() {
    this.newSupProductEvent.emit();
  }

  editSupProduct(subProductId: string) {
    this.editSupProductEvent.emit(subProductId);
  }

  deleteSupProduct(subProductId: string) {
    this.deleteSupProductEvent.emit(subProductId);
  }
}
