import { Observable } from 'rxjs';
import {
  InterventionTypeRequest,
  InterventionTypeResponse,
} from './entities/action-type-entity';
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  API_SERVICE_CONFIG,
  IApiServiceConfig,
} from '@fullyops/data/base/configuration/api-service-config';

@Injectable()
export class ApiActionTypeAdapterService {
  private readonly url = this.config.url + 'interventions/types';

  constructor(
    private http: HttpClient,
    @Inject(API_SERVICE_CONFIG) private config: IApiServiceConfig
  ) {}

  getAll(): Observable<InterventionTypeResponse[]> {
    return this.http.get<InterventionTypeResponse[]>(this.url);
  }

  getByLabel() {}

  updateByLabel({
    label,
    data,
  }: {
    label: string;
    data: InterventionTypeRequest;
  }) {
    return this.http.patch<InterventionTypeResponse>(
      `${this.url}/${label}`,
      data
    );
  }

  getByParentLabel() {}
}
