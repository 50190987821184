import { LoginV2Component } from '@fullyops/legacy/pages/loginv2/loginv2.component';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LoginComponent } from '@fullyops/legacy/pages/login/login.component';
import { ResetPasswordComponent } from '@fullyops/legacy/pages/reset-password/reset-password.component';
import { ForgotPasswordComponent } from '@fullyops/legacy/pages/forgot-password/forgot-password.component';
import { DownloadsComponent } from '@fullyops/legacy/pages/downloads/downloads.component';
import { AuthGuard } from './core/guards/auth.guard';
import { TokenExpiredComponent } from '@fullyops/legacy/pages/token-expired/token-expired.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./app-container/app-container.module').then(
        (m) => m.AppContainerModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'ticket/multimachinesupport-v2',
    component: LoginV2Component,
  },
  { path: 'login', component: LoginComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'confirm-reset', component: ResetPasswordComponent },
  { path: 'token-expired', component: TokenExpiredComponent },
  {
    path: 'downloads/:token',
    component: DownloadsComponent,
  },

  { path: '**', pathMatch: 'full', redirectTo: '/' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
