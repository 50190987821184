import { Response } from '../Response';
import { Endpoint } from '../Endpoint';
import { HttpClient } from '@angular/common/http';
import { IListResponse } from '../IListResponse';
import { Injectable, Inject } from '@angular/core';
import { API_SERVICE_CONFIG } from '../ApiServiceConfig';
import { IApiServiceConfig } from '../IApiServiceConfig';

import { Observable, BehaviorSubject } from 'rxjs';

import { SubProductVariant } from '../../models/v0/SubProductVariant';
import { UnsavedSubProductVariant } from '../../models/v0/UnsavedSubProductVariant';
import { ISerialisedSubProductVariant } from '../../models/v0/serialisations/ISerialisedSubProductVariant';
import { tap, map } from 'rxjs/operators';

type SubProductVariantList = IListResponse<ISerialisedSubProductVariant>;

@Injectable()
export class SubProductVariantsEndpoint extends Endpoint<
  SubProductVariant,
  ISerialisedSubProductVariant
> {
  private readonly url = this.config.url + 'variants/';

  constructor(
    @Inject(API_SERVICE_CONFIG) private config: IApiServiceConfig,
    private http: HttpClient
  ) {
    super(SubProductVariant.fromSerialised);
  }

  getAll(
    source?: Observable<SubProductVariantList>,
    store?: BehaviorSubject<Response<SubProductVariant[]>>
  ): Observable<Response<SubProductVariant[]>> {
    const defaultSource = this.http.get<SubProductVariantList>(this.url);
    return this.list(source || defaultSource, store);
  }

  get(
    id: string,
    source?: Observable<ISerialisedSubProductVariant>,
    store?: BehaviorSubject<Response<SubProductVariant[]>>
  ): Observable<Response<SubProductVariant>> {
    const defaultSource = this.http.get<ISerialisedSubProductVariant>(
      `${this.url}${id}/`
    );
    return this.retrieve(source || defaultSource, store);
  }

  post(
    subProduct: UnsavedSubProductVariant,
    source?: Observable<ISerialisedSubProductVariant>,
    store?: BehaviorSubject<Response<SubProductVariant[]>>
  ): Observable<Response<SubProductVariant>> {
    const { ...body } = subProduct.toSerialised();
    const defaultSource = this.http.post<ISerialisedSubProductVariant>(
      this.url,
      body
    );
    return this.add(source || defaultSource, store);
  }

  patch(
    id: string,
    user: UnsavedSubProductVariant,
    source?: Observable<ISerialisedSubProductVariant>,
    store?: BehaviorSubject<Response<SubProductVariant[]>>
  ) {
    const defaultSource = this.http.patch<ISerialisedSubProductVariant>(
      `${this.url}${id}/`,
      user
    );

    return defaultSource.pipe(
      tap((instance) =>
        this.update(
          source || defaultSource,
          SubProductVariant.fromSerialised(instance),
          store
        )
      ),
      map(
        (serialiser) =>
          new Response(SubProductVariant.fromSerialised(serialiser))
      )
    );
  }

  delete(
    id: string,
    source?: Observable<{}>,
    store?: BehaviorSubject<Response<SubProductVariant[]>>
  ): Observable<Response<{}>> {
    const defaultSource = this.http.delete<{}>(`${this.url}${id}/`);
    return this.remove(source || defaultSource, id, store);
  }
}
