import { Injectable } from '@angular/core';
import { Role, UnsavedRole, RolesEndpoint } from '@fullyops/legacy/data';
import { UiService } from './ui.service';

@Injectable({
  providedIn: 'root',
})
export class UiRolesService extends UiService<
  Role,
  UnsavedRole,
  RolesEndpoint
> {
  constructor(protected rolesEndpoint: RolesEndpoint) {
    super(rolesEndpoint.createStore(), rolesEndpoint);
  }
}
