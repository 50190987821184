<section>
  <mat-card class="request-new-item" (click)="createTicketRequest()">
    <div class="create-request">
      <div class="create-request-icon">
        <fa-icon [icon]="faFileCirclePlus" size="xl"></fa-icon>
      </div>
      <b>{{ 'forms:createRequest' | i18nextCap }}</b>
    </div>
  </mat-card>

  <mat-accordion>
    <mat-expansion-panel *ngFor="let request of requests">
      <mat-expansion-panel-header>
        <div class="request-item-header">
          <div class="request-item-header-identifier">
            <mat-icon class="primary-color">local_shipping</mat-icon>
            R-0{{ request.identifier }}
          </div>
          <div>{{ request.name }}</div>
          <div>
            <mat-chip [ngClass]="request.status.color">{{
              'forms:' + request.status.name | i18nextCap
            }}</mat-chip>
          </div>
        </div>
      </mat-expansion-panel-header>
      <div class="request-accordion-content">
        <table
          mat-table
          [dataSource]="request.parts"
          class="mat-elevation-z2 full-width"
        >
          <!-- Item Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'forms:name' | i18nextCap }}
            </th>
            <td mat-cell *matCellDef="let item">
              {{ item.part.name }}
            </td>
            <td mat-footer-cell *matFooterCellDef><b>Total</b></td>
          </ng-container>

          <!-- Cost Column -->
          <ng-container matColumnDef="price">
            <th mat-header-cell *matHeaderCellDef>
              <div class="center-cell">
                {{ 'forms:price' | i18nextCap }}
              </div>
            </th>
            <td mat-cell *matCellDef="let item">
              <div class="center-cell">
                {{ item.part.price | currency: 'EUR' }}
              </div>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="quantity">
            <th mat-header-cell *matHeaderCellDef>
              <div class="center-cell">{{ 'forms:quantity' | i18nextCap }}</div>
            </th>
            <td mat-cell *matCellDef="let item">
              <div class="center-cell">
                {{ item.quantity }}
              </div>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="total">
            <th mat-header-cell *matHeaderCellDef>
              <div class="end-cell">
                {{ 'forms:total' | i18nextCap }}
              </div>
            </th>
            <td mat-cell *matCellDef="let item">
              <div class="end-cell">
                {{ item.part.price * item.quantity | currency: 'EUR' }}
              </div>
            </td>
            <td mat-footer-cell *matFooterCellDef>
              <div class="end-cell">
                <b>
                  {{ getTotalTicketRequestPrice(request) | currency: 'EUR' }}
                </b>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
        </table>
        <div *hasPermission="{ permissions: requestPermissions }">
          <button
            mat-flat-button
            color="primary"
            type="button"
            (click)="onOpenRequestTicket(request.id)"
            [disabled]="
              canOnlySeeOwn && request?.createdBy?.id !== currentUser.id
            "
          >
            Ver mais detalhes
          </button>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</section>
