import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { User } from '@fullyops/legacy/data';
import { BehaviorSubject } from 'rxjs';
import { ComponentInOutAnimation } from '@fullyops/legacy/ui/ui-shared/utils/component-base-animation';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

export type CurrentFilters = {
  name?: string;
  assigneeIds?: string[];
  mobileNumber?: string;
  email?: string;
  externalId?: string;
  address?: string;
  nif?: string;
  type?: string;
};
@Component({
  selector: 'crm-companies-filters',
  templateUrl: './companies-filters.component.html',
  styleUrls: ['./companies-filters.component.scss'],
  animations: [ComponentInOutAnimation.AnimTrigger],
})
export class CompaniesFiltersComponent implements OnInit {
  @Input() selfPipeline = false;
  @Input() filters$ = new BehaviorSubject<CurrentFilters>({});
  @Input() users: User[];
  @Output() filtersChangeEvent = new EventEmitter<{}>();

  filtersForm: UntypedFormGroup;
  typeControl: UntypedFormControl;
  filtersFormFields = [];
  currentFilters$ = new BehaviorSubject({});

  clients$ = new BehaviorSubject<[]>([]);
  assignees$ = new BehaviorSubject<any[]>([]);

  ngOnInit() {
    this.filtersForm = new UntypedFormGroup({
      name: new UntypedFormControl('', []),
      mobileNumber: new UntypedFormControl('', []),
      assigneeIds: new UntypedFormControl([], []),
      email: new UntypedFormControl('', []),
      address: new UntypedFormControl('', []),
      externalId: new UntypedFormControl('', []),
      nif: new UntypedFormControl('', []),
    });

    this.typeControl = new UntypedFormControl(
      this.filters$.value?.type || 'CLIENT',
      [Validators.required]
    );
    this.typeControl.valueChanges.subscribe(() => this.applyFilters());

    this.filters$.subscribe((filters) => {
      let assignees = [];

      if (filters.assigneeIds) {
        assignees = filters.assigneeIds.map((assigneeId) => {
          return this.users.find((user) => user.id == assigneeId);
        });
      }

      Object.entries(filters).forEach(([key, value]) => {
        if (key === 'assigneeIds')
          return this.filtersForm.get('assigneeIds').patchValue(assignees);

        if (this.filtersForm.get(key))
          this.filtersForm.get(key).patchValue(value);
      });

      this.updateChips();
    });

    this.assignees$.next(
      this.users.map((user: User) => ({
        id: user,
        name: `${user.firstName} ${user.lastName}`,
      }))
    );

    this.filtersFormFields = [
      [
        { name: 'name', type: 'text', label: 'name', size: 12 },
        { name: 'mobileNumber', type: 'text', label: 'mobileNumber', size: 12 },
        { name: 'email', type: 'text', label: 'email', size: 12 },
        { name: 'address', type: 'text', label: 'address', size: 12 },
        { name: 'externalId', type: 'text', label: 'externalId', size: 12 },
        { name: 'nif', type: 'text', label: 'nif', size: 12 },
        {
          name: 'assigneeIds',
          type: 'multiple-select',
          label: 'assignees',
          size: 12,
          items$: this.assignees$,
          nullable: false,
          translate: false,
        },
      ],
    ];
  }

  concatMultipleItems(list: { name: string }[], maxItems = 2) {
    let temp = '';
    if (list.length >= maxItems) {
      temp = `${list[0].name}, ${list[1].name}`;

      if (list.length > maxItems) temp = `${temp}, +${list.length - maxItems}`;
    } else {
      temp = `${list[0].name}`;
    }
    return temp;
  }

  updateChips() {
    const filters = this.filtersForm.value;
    let currentFilters = {};

    Object.keys(filters).forEach((key) => {
      if (
        filters[key] === undefined ||
        filters[key] === null ||
        filters[key] === ''
      )
        return;
      const value = filters[key];

      if (Array.isArray(value)) {
        if (value.length == 0) return;
      }

      if (key == 'assigneeIds') {
        return (currentFilters['assigneeIds'] = this.concatMultipleItems(
          filters[key].map((u) => ({ name: u.firstName + ' ' + u.lastName }))
        ));
      }

      currentFilters[key] = value;
    });

    return this.currentFilters$.next(currentFilters);
  }

  getFilterFormValueFormatted() {
    return Object.entries(this.filtersForm.value).reduce(
      (acc, [key, value]) => {
        if (value === undefined || value === null || value === '') return acc;

        if (Array.isArray(value)) {
          if (value.length == 0) return acc;
        }

        switch (key) {
          case 'assigneeIds':
            acc['assigneeIds'] = (value as User[]).map((u) => u.id);
            break;
          default:
            acc[key] = value;
            break;
        }
        return acc;
      },
      {}
    );
  }

  applyFilters() {
    this.filtersChangeEvent.emit({
      ...this.getFilterFormValueFormatted(),
      type: this.typeControl.value,
    });
  }

  // tslint:disable-next-line: no-shadowed-variable
  removeFilter(filter: string) {
    this.filtersForm.get(filter).patchValue(null);
    this.applyFilters();
  }
}
