import { Response } from '../Response';
import { Endpoint } from '../Endpoint';
import { HttpClient } from '@angular/common/http';
import { IListResponse } from '../IListResponse';
import { Injectable, Inject } from '@angular/core';
import { API_SERVICE_CONFIG } from '../ApiServiceConfig';

import { Observable, BehaviorSubject } from 'rxjs';

import { Part } from '../../models/v0/Part';
import { ISerialisedPart } from '../../models/v0/serialisations/ISerialisedPart';
import { IApiServiceConfig } from '../IApiServiceConfig';
import { UnsavedPart } from '../../models/v0/UnsavedPart';
import { map, tap } from 'rxjs/operators';

type PartList = IListResponse<ISerialisedPart>;

@Injectable()
export class PartsEndpoint extends Endpoint<Part, ISerialisedPart> {
  private readonly url = this.config.url + 'parts/';

  constructor(
    @Inject(API_SERVICE_CONFIG) private config: IApiServiceConfig,
    private http: HttpClient
  ) {
    super(Part.fromSerialised);
  }

  getAll(
    filters: {},
    source?: Observable<PartList>,
    store?: BehaviorSubject<Response<Part[]>>
  ): Observable<Response<Part[]>> {
    let query = '';
    Object.keys(filters).forEach((key) => (query += `&${key}=${filters[key]}`));

    const defaultSource = this.http.get<PartList>(
      `${this.url}all?size=100&page=0${query}`
    );
    return this.list(source || defaultSource, store);
  }

  get(
    id: string,
    source?: Observable<ISerialisedPart>,
    store?: BehaviorSubject<Response<Part[]>>
  ): Observable<Response<Part>> {
    const defaultSource = this.http.get<ISerialisedPart>(`${this.url}${id}/`);
    return this.retrieve(source || defaultSource, store);
  }

  post(
    part: UnsavedPart,
    source?: Observable<ISerialisedPart>,
    store?: BehaviorSubject<Response<Part[]>>
  ): Observable<Response<Part>> {
    const defaultSource = this.http.post<ISerialisedPart>(
      this.url,
      part.toSerialised()
    );
    return this.add(source || defaultSource, store);
  }

  patch(
    id: string,
    part: UnsavedPart,
    source?: Observable<ISerialisedPart>,
    store?: BehaviorSubject<Response<Part[]>>
  ): Observable<Response<Part>> {
    const { ...body } = part.toSerialised();
    const defaultSource = this.http.patch<ISerialisedPart>(
      `${this.url}${id}/`,
      body
    );

    return defaultSource.pipe(
      tap((instance) =>
        this.update(
          source || defaultSource,
          Part.fromSerialised(instance),
          store
        )
      ),
      map((serialiser) => new Response(Part.fromSerialised(serialiser)))
    );
  }

  delete(
    id: string,
    source?: Observable<{}>,
    store?: BehaviorSubject<Response<Part[]>>
  ): Observable<Response<{}>> {
    const defaultSource = this.http.delete<{}>(`${this.url}${id}/`);
    return this.remove(source || defaultSource, id, store);
  }
}
