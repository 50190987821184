import { UntypedFormGroup } from '@angular/forms';
import { ActivityType } from '@fullyops/legacy/data';
import { Observable } from 'rxjs';

export type CallbackFunction = () => void;

export type PaginationParams = {
  page: number;
  size: number;
};

export type PaginationResult<T> = {
  currentPage: number;
  pageSize: number;
  results: T[];
  hasPrevious: boolean;
  hasNext: boolean;
  totalPages: number;
  count: number;
};

export const PAGE_SIZE = 20;

export interface AbstractFormService<UnsavedModel> {
  form$: Observable<UntypedFormGroup>;
  initForm(
    entity: UnsavedModel,
    disable: boolean,
    emailRequired: boolean
  ): void;
  getDraft(): UnsavedModel;
  markAllAsTouched(): void;
}

export interface AbstractModel<UnsavedModel> {
  toDraft(): UnsavedModel;
}

export enum FormType {
  Details = 'details',
  Edit = 'edit',
  New = 'new',
}

export enum ActionEventColor {
  Primary = 'primary',
  White = 'white',
}

export interface ActionEvent {
  label: string;
  color: ActionEventColor;
  callback: any;
}

export interface ComponentActions {
  actionOne?: ActionEvent;
  actionTwo?: ActionEvent;
  actionThree?: ActionEvent;
}

export interface ActionButtons {
  primary?: ActionButton;
  secondary?: ActionButton;
  warn?: ActionButton;
}

export interface ActionButton {
  label: string;
  color: string;
  permissions?: PermissionType[];
  dataTestId?: string;
}

export type ActionButtonEvent = 'primary' | 'secondary' | 'warn';

export interface Label {
  text: string;
  color: string;
}

export interface ListItemColumn {
  readonly rowType?: ActivityType;
  readonly key?: string;
  readonly label: string;
  readonly type: number;
  readonly value: string | number;
  readonly color: string;
}

export interface ListItem {
  id: string;
  columns: ListItemColumn[];
  background: string;
  rowType?: { value: string };
}

export enum ProductStatus {
  New = 'NEW',
  Used = 'USED',
  Refurbished = 'REFURBISHED',
}

export enum FieldType {
  Text,
  Number,
  Date,
  Label,
  Array,
  Priority,
  Status,
  User,
  Company,
  Request,
  Support,
  Price,
  Activity,
  Enum,
  Identifier,
}

export interface TicketMoved {
  ticketId: string;
  previouseStatus: string;
  currentStatus: string;
}

export enum CompanyType {
  CLIENT = 'client',
  SUPPLIER = 'supplier',
  BOTH = 'both',
  SELF = 'self',
}

export enum CompanyTypeColor {
  CLIENT = 'light-blue-50',
  SUPPLIER = 'light-green-50',
  BOTH = 'teal-50',
  SELF = 'amber-100',
}

export enum TicketTypeIcon {
  SUPPORT = 'handyman',
  REQUEST = 'local_shipping',
  SALE = 'work',
  INTERVENTION = 'handyman',
}

export enum Priority {
  URGENT = 'urgent',
  HIGH = 'high',
  MEDIUM = 'medium',
  LOW = 'low',
}

export enum ActivityTypeIcon {
  CALL = 'call',
  REMINDER = 'timelapse',
  EMAIL = 'email',
  MEETING = 'people',
  TASK = 'assignment',
}

export enum PriorityColor {
  URGENT = 'red-200',
  HIGH = 'red-100',
  MEDIUM = 'orange-100',
  LOW = 'lime-100',
}

export enum Palette {
  RED = 'red',
  PINK = 'pink',
  PURPLE = 'purple',
  DEEP_PURPLE = 'deep-purple',
  INDIGO = 'indigo',
  BLUE = 'blue',
  LIGHT_BLUE = 'light-blue',
  CYAN = 'cyan',
  TEAL = 'teal',
  GREEN = 'green',
  LIGHT_GREEN = 'light-green',
  LIME = 'lime',
  YELLOW = 'yellow',
  AMBER = 'amber',
  ORANGE = 'orange',
  DEEP_ORANGE = 'deep-orange',
  BROWN = 'brown',
  GREY = 'grey',
  BLUE_GREY = 'blue-grey',
}

export enum ColorWeight {
  W50 = '50',
  W100 = '100',
  W200 = '200',
  W300 = '300',
  W400 = '400',
  W500 = '500',
  W600 = '600',
  W700 = '700',
  W800 = '800',
  W900 = '900',
}

export enum RoleGroup {
  ADMIN = 'ADMIN',
  MANAGER = 'MANAGER',
  TECHNICIAN = 'TECHNICIAN',
  SALESMAN = 'SALESMAN',
  INSTALLER = 'INSTALLER',
}

export enum RoleGroupColor {
  ADMIN = 'amber-200',
  MANAGER = 'light-blue-100',
  TECHNICIAN = 'orange-100',
  SALESMAN = 'purple-100',
}

export enum PermissionType {
  CAN_ACCESS_SALE_TICKETS = 'CAN_ACCESS_SALE_TICKETS',
  CAN_EDIT_SALE_TICKETS = 'CAN_EDIT_SALE_TICKETS',
  CAN_ACCESS_ACTIVITIES = 'CAN_ACCESS_ACTIVITIES',
  CAN_EDIT_ACTIVITIES = 'CAN_EDIT_ACTIVITIES',
  CAN_ACCESS_COMPANIES = 'CAN_ACCESS_COMPANIES',
  CAN_EDIT_COMPANIES = 'CAN_EDIT_COMPANIES',
  CAN_ACCESS_CONTACTS = 'CAN_ACCESS_CONTACTS',
  CAN_EDIT_CONTACTS = 'CAN_EDIT_CONTACTS',
  CAN_ACCESS_HISTORY = 'CAN_ACCESS_HISTORY',
  CAN_EDIT_HISTORY = 'CAN_EDIT_HISTORY',
  CAN_ACCESS_PARTS = 'CAN_ACCESS_PARTS',
  CAN_EDIT_PARTS = 'CAN_EDIT_PARTS',
  CAN_ACCESS_PRODUCTS = 'CAN_ACCESS_PRODUCTS',
  CAN_EDIT_PRODUCTS = 'CAN_EDIT_PRODUCTS',
  CAN_ACCESS_PRODUCT_CONFIGURATIONS = 'CAN_ACCESS_PRODUCT_CONFIGURATIONS',
  CAN_EDIT_PRODUCT_CONFIGURATIONS = 'CAN_EDIT_PRODUCT_CONFIGURATIONS',
  CAN_ACCESS_PRODUCT_LABELS = 'CAN_ACCESS_PRODUCT_LABELS',
  CAN_EDIT_PRODUCT_LABELS = 'CAN_EDIT_PRODUCT_LABELS',
  CAN_ACCESS_REPORTS = 'CAN_ACCESS_REPORTS',
  CAN_EDIT_REPORTS = 'CAN_EDIT_REPORTS',
  CAN_ACCESS_REQUEST_TICKETS = 'CAN_ACCESS_REQUEST_TICKETS',
  CAN_EDIT_REQUEST_TICKETS = 'CAN_EDIT_REQUEST_TICKETS',
  CAN_ACCESS_REQUEST_TICKET_STATUS = 'CAN_ACCESS_REQUEST_TICKET_STATUS',
  CAN_EDIT_REQUEST_TICKET_STATUS = 'CAN_EDIT_REQUEST_TICKET_STATUS',
  CAN_ACCESS_ROLES = 'CAN_ACCESS_ROLES',
  CAN_EDIT_ROLES = 'CAN_EDIT_ROLES',
  CAN_ACCESS_SUPPORT_TICKETS = 'CAN_ACCESS_SUPPORT_TICKETS',
  CAN_EDIT_SUPPORT_TICKETS = 'CAN_EDIT_SUPPORT_TICKETS',
  CAN_ACCESS_SUPPORT_TICKET_STATUS = 'CAN_ACCESS_SUPPORT_TICKET_STATUS',
  CAN_EDIT_SUPPORT_TICKET_STATUS = 'CAN_EDIT_SUPPORT_TICKET_STATUS',
  CAN_ACCESS_TENANTS = 'CAN_ACCESS_TENANTS',
  CAN_EDIT_TENANTS = 'CAN_EDIT_TENANTS',
  CAN_ACCESS_USERS = 'CAN_ACCESS_USERS',
  CAN_ACCESS_OWN_USER = 'CAN_ACCESS_OWN_USER',
  CAN_EDIT_USERS = 'CAN_EDIT_USERS',
  CAN_CREATE_USERS = 'CAN_CREATE_USERS',
  CAN_ACCESS_USER_ROLES = 'CAN_ACCESS_USER_ROLES',
  CAN_EDIT_USER_ROLES = 'CAN_EDIT_USER_ROLES',
  CAN_EDIT_ROLE_PERMISSIONS = 'CAN_EDIT_ROLE_PERMISSIONS',
  CAN_ACCESS_LOSS_MOTIVES = 'CAN_ACCESS_LOSS_MOTIVES',
  CAN_EDIT_LOSS_MOTIVES = 'CAN_EDIT_LOSS_MOTIVES',
  CAN_EDIT_CLIENT_EQUIPMENTS = 'CAN_EDIT_CLIENT_EQUIPMENTS',
  CAN_ACCESS_CLIENT_EQUIPMENTS = 'CAN_ACCESS_CLIENT_EQUIPMENTS',
  CAN_ACCESS_INSTALLATION_TICKETS = 'CAN_ACCESS_INSTALLATION_TICKETS',
  CAN_EDIT_INSTALLATION_TICKETS = 'CAN_EDIT_INSTALLATION_TICKETS',
  CAN_DELETE_INSTALLATION_TICKETS = 'CAN_DELETE_INSTALLATION_TICKETS',
  CAN_ACCESS_INSTALLATION_TICKET_STATUSES = 'CAN_ACCESS_INSTALLATION_TICKET_STATUSES',
  CAN_EDIT_INSTALLATION_TICKET_STATUSES = 'CAN_EDIT_INSTALLATION_TICKET_STATUSES',
  CAN_ACCESS_INSTALLATION_TICKET_CONTACTS = 'CAN_ACCESS_INSTALLATION_TICKET_CONTACTS',
  CAN_EDIT_INSTALLATION_TICKET_CONTACTS = 'CAN_EDIT_INSTALLATION_TICKET_CONTACTS',
  CAN_ACCESS_INSTALLATION_TICKET_ASSIGNEES = 'CAN_ACCESS_INSTALLATION_TICKET_ASSIGNEES',
  CAN_EDIT_INSTALLATION_TICKET_ASSIGNEES = 'CAN_EDIT_INSTALLATION_TICKET_ASSIGNEES',
  CAN_ACCESS_INSTALLATION_TICKET_CLIENT_EQUIPMENTS = 'CAN_ACCESS_INSTALLATION_TICKET_CLIENT_EQUIPMENTS',
  CAN_EDIT_INSTALLATION_TICKET_CLIENT_EQUIPMENTS = 'CAN_EDIT_INSTALLATION_TICKET_CLIENT_EQUIPMENTS',
  CAN_ACCESS_TENANT_CONFIGURATIONS = 'CAN_ACCESS_TENANT_CONFIGURATIONS',
  CAN_EDIT_TENANT_CONFIGURATIONS = 'CAN_EDIT_TENANT_CONFIGURATIONS',
  CAN_CREATE_API_TOKENS = 'CAN_CREATE_API_TOKENS',
  CAN_DELETE_API_TOKENS = 'CAN_DELETE_API_TOKENS',
  CAN_ACCESS_API_TOKENS = 'CAN_ACCESS_API_TOKENS',
  CAN_EDIT_REPORT_TEMPLATES = 'CAN_EDIT_REPORT_TEMPLATES',
  CAN_DELETE_CLIENT_EQUIPMENTS = 'CAN_DELETE_CLIENT_EQUIPMENTS',
  CAN_ACCESS_USER_PARTS = 'CAN_ACCESS_USER_PARTS',
  CAN_ACCESS_OWN_USER_PARTS = 'CAN_ACCESS_OWN_USER_PARTS',
  CAN_ACCESS_UNCATALOGUED_PARTS = 'CAN_ACCESS_UNCATALOGUED_PARTS',
  CAN_CREATE_UNCATALOGUED_PARTS = 'CAN_CREATE_UNCATALOGUED_PARTS',
  CAN_DELETE_UNCATALOGUED_PARTS = 'CAN_DELETE_UNCATALOGUED_PARTS',
  CAN_EDIT_UNCATALOGUED_PARTS = 'CAN_EDIT_UNCATALOGUED_PARTS',
  CAN_EDIT_OWN_USER = 'CAN_EDIT_OWN_USER',
  CAN_EDIT_INTERVENTION_PARTS = 'CAN_EDIT_INTERVENTION_PARTS',
  CAN_ACCESS_OWN_REQUEST_TICKETS = 'CAN_ACCESS_OWN_REQUEST_TICKETS',
  CAN_EDIT_OWN_REQUEST_TICKETS = 'CAN_EDIT_OWN_REQUEST_TICKETS',
  CAN_SEND_REQUEST_NOTIFICATION_EMAIL = 'CAN_SEND_REQUEST_NOTIFICATION_EMAIL',
  CAN_ACCESS_OWN_SALE_TICKETS = 'CAN_ACCESS_OWN_SALE_TICKETS',
  CAN_CREATE_SALE_TICKETS = 'CAN_CREATE_SALE_TICKETS',
  CAN_EDIT_OWN_SALE_TICKETS = 'CAN_EDIT_OWN_SALE_TICKETS',
  CAN_DELETE_SALE_TICKETS = 'CAN_DELETE_SALE_TICKETS',
  CAN_DELETE_OWN_SALE_TICKETS = 'CAN_DELETE_OWN_SALE_TICKETS',
  CAN_CREATE_ACTIVITIES = 'CAN_CREATE_ACTIVITIES',
  CAN_ACCESS_OWN_ACTIVITIES = 'CAN_ACCESS_OWN_ACTIVITIES',
  CAN_EDIT_OWN_ACTIVITIES = 'CAN_EDIT_OWN_ACTIVITIES',
  CAN_DELETE_OWN_ACTIVITIES = 'CAN_DELETE_OWN_ACTIVITIES',
  CAN_DELETE_ACTIVITIES = 'CAN_DELETE_ACTIVITIES',
  CAN_CREATE_SUPPORT_TICKETS = 'CAN_CREATE_SUPPORT_TICKETS',
  CAN_EDIT_OWN_SUPPORT_TICKETS = 'CAN_EDIT_OWN_SUPPORT_TICKETS',
  CAN_DELETE_SUPPORT_TICKETS = 'CAN_DELETE_SUPPORT_TICKETS',
  CAN_DELETE_OWN_SUPPORT_TICKETS = 'CAN_DELETE_OWN_SUPPORT_TICKETS',
  CAN_ACCESS_OWN_SUPPORT_TICKETS = 'CAN_ACCESS_OWN_SUPPORT_TICKETS',
  CAN_ACCESS_SUPPORT_TICKET_CATEGORIES = 'CAN_ACCESS_SUPPORT_TICKET_CATEGORIES',
  CAN_CREATE_SUPPORT_TICKET_CATEGORIES = 'CAN_CREATE_SUPPORT_TICKET_CATEGORIES',
  CAN_EDIT_OWN_INTERVENTIONS = 'CAN_EDIT_OWN_INTERVENTIONS',
  CAN_ACCESS_OWN_INTERVENTIONS = 'CAN_ACCESS_OWN_INTERVENTIONS',
  CAN_DELETE_OWN_INTERVENTIONS = 'CAN_DELETE_OWN_INTERVENTIONS',
  CAN_CREATE_INTERVENTIONS = 'CAN_CREATE_INTERVENTIONS',
  CAN_ACCESS_INTERVENTIONS = 'CAN_ACCESS_INTERVENTIONS',
  CAN_EDIT_INTERVENTIONS = 'CAN_EDIT_INTERVENTIONS',
  CAN_DELETE_INTERVENTIONS = 'CAN_DELETE_INTERVENTIONS',
  CAN_ACCESS_SUPPORT_TICKET_SCOPES = 'CAN_ACCESS_SUPPORT_TICKET_SCOPES',
  CAN_EDIT_SUPPORT_TICKET_SCOPES = 'CAN_EDIT_SUPPORT_TICKET_SCOPES',
  CAN_EXPORT_WORK_ORDERS = 'CAN_EXPORT_WORK_ORDERS',
}

export enum FeaturePermissionToggle {
  PIPELINES_SALE_TICKET = 'PIPELINES_SALE_TICKET',
  PIPELINES_SUPPORT_TICKET = 'PIPELINES_SUPPORT_TICKET',
  SALE_OPPORTUNITIES = 'SALE_OPPORTUNITIES',
}

export interface CustomFilter {
  readonly orderBy?: string;
  readonly name: string;
  readonly type: string;
}

export type PermissionsType =
  | 'CAN_ACCESS_SALE_TICKETS'
  | 'CAN_EDIT_SALE_TICKETS'
  | 'CAN_ACCESS_ACTIVITIES'
  | 'CAN_EDIT_ACTIVITIES'
  | 'CAN_ACCESS_COMPANIES'
  | 'CAN_EDIT_COMPANIES'
  | 'CAN_ACCESS_CONTACTS'
  | 'CAN_EDIT_CONTACTS'
  | 'CAN_ACCESS_HISTORY'
  | 'CAN_EDIT_HISTORY'
  | 'CAN_ACCESS_PARTS'
  | 'CAN_EDIT_PARTS'
  | 'CAN_ACCESS_PRODUCTS'
  | 'CAN_EDIT_PRODUCTS'
  | 'CAN_ACCESS_PRODUCT_CONFIGURATIONS'
  | 'CAN_EDIT_PRODUCT_CONFIGURATIONS'
  | 'CAN_ACCESS_PRODUCT_LABELS'
  | 'CAN_EDIT_PRODUCT_LABELS'
  | 'CAN_ACCESS_REPORTS'
  | 'CAN_EDIT_REPORTS'
  | 'CAN_ACCESS_REQUEST_TICKETS'
  | 'CAN_EDIT_REQUEST_TICKETS'
  | 'CAN_ACCESS_REQUEST_TICKET_STATUS'
  | 'CAN_EDIT_REQUEST_TICKET_STATUS'
  | 'CAN_ACCESS_ROLES'
  | 'CAN_EDIT_ROLES'
  | 'CAN_ACCESS_SUPPORT_TICKETS'
  | 'CAN_EDIT_SUPPORT_TICKETS'
  | 'CAN_ACCESS_SUPPORT_TICKET_STATUS'
  | 'CAN_EDIT_SUPPORT_TICKET_STATUS'
  | 'CAN_ACCESS_TENANTS'
  | 'CAN_EDIT_TENANTS'
  | 'CAN_ACCESS_USERS'
  | 'CAN_EDIT_USERS'
  | 'CAN_ACCESS_USER_ROLES'
  | 'CAN_EDIT_USER_ROLES'
  | 'CAN_ACCESS_LOSS_MOTIVES'
  | 'CAN_EDIT_LOSS_MOTIVES'
  | 'ADMIN'
  | 'CAN_EDIT_CLIENT_EQUIPMENTS'
  | 'CAN_ACCESS_CLIENT_EQUIPMENTS'
  | 'CAN_ACCESS_INSTALLATION_TICKETS'
  | 'CAN_EDIT_INSTALLATION_TICKETS'
  | 'CAN_DELETE_INSTALLATION_TICKETS'
  | 'CAN_ACCESS_INSTALLATION_TICKET_STATUSES'
  | 'CAN_EDIT_INSTALLATION_TICKET_STATUSES'
  | 'CAN_ACCESS_INSTALLATION_TICKET_CONTACTS'
  | 'CAN_EDIT_INSTALLATION_TICKET_CONTACTS'
  | 'CAN_ACCESS_INSTALLATION_TICKET_ASSIGNEES'
  | 'CAN_EDIT_INSTALLATION_TICKET_ASSIGNEES'
  | 'CAN_ACCESS_INSTALLATION_TICKET_CLIENT_EQUIPMENTS'
  | 'CAN_EDIT_INSTALLATION_TICKET_CLIENT_EQUIPMENTS'
  | 'CAN_ACCESS_TENANT_CONFIGURATIONS'
  | 'CAN_EDIT_TENANT_CONFIGURATIONS'
  | 'CAN_ACCESS_OWN_REQUEST_TICKETS'
  | 'CAN_EDIT_OWN_REQUEST_TICKETS'
  | 'CAN_SEND_REQUEST_NOTIFICATION_EMAIL'
  | 'CAN_ACCESS_OWN_SALE_TICKETS'
  | 'CAN_CREATE_SALE_TICKETS'
  | 'CAN_EDIT_OWN_SALE_TICKETS'
  | 'CAN_DELETE_SALE_TICKETS'
  | 'CAN_DELETE_OWN_SALE_TICKETS'
  | 'CAN_CREATE_SUPPORT_TICKETS'
  | 'CAN_EDIT_OWN_SUPPORT_TICKETS'
  | 'CAN_DELETE_SUPPORT_TICKETS'
  | 'CAN_DELETE_OWN_SUPPORT_TICKETS'
  | 'CAN_CREATE_ACTIVITIES'
  | 'CAN_ACCESS_OWN_ACTIVITIES'
  | 'CAN_EDIT_OWN_ACTIVITIES'
  | 'CAN_DELETE_OWN_ACTIVITIES'
  | 'CAN_DELETE_ACTIVITIES'
  | 'CAN_ACCESS_OWN_SUPPORT_TICKETS'
  | 'CAN_EDIT_OWN_INTERVENTIONS'
  | 'CAN_ACCESS_OWN_INTERVENTIONS'
  | 'CAN_DELETE_OWN_INTERVENTIONS'
  | 'CAN_CREATE_INTERVENTIONS'
  | 'CAN_ACCESS_INTERVENTIONS'
  | 'CAN_EDIT_INTERVENTIONS'
  | 'CAN_DELETE_INTERVENTIONS';
