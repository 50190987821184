import { UiServiceV2 } from './ui.service-v2.service';
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_SERVICE_CONFIG } from '@fullyops/legacy/data/api/ApiServiceConfig';
import { IApiServiceConfig } from '@fullyops/legacy/data/api/IApiServiceConfig';
import {
  ImageResponse,
  SignedImageResponse,
} from '@fullyops/legacy/data/api/types/Image';

@Injectable({
  providedIn: 'root',
})
export class ImageController extends UiServiceV2 {
  constructor(
    private http: HttpClient,
    @Inject(API_SERVICE_CONFIG) private config: IApiServiceConfig
  ) {
    super();
  }

  private readonly endpointUrl = this.config.url + 'images';

  downloadImage(id: string) {
    return this.http.get(`${this.endpointUrl}/${id}/download`, {
      responseType: 'blob',
    });
  }

  upload(params: { file: File }) {
    const formData = new FormData();
    formData.append('file', params.file, `${params.file.name}`);

    return this.http.post<ImageResponse>(`${this.endpointUrl}`, formData);
  }

  getById(params: { id: string }) {
    return this.http.get<SignedImageResponse>(
      `${this.endpointUrl}/${params.id}`
    );
  }

  getImageData(id: string) {
    return this.http.get(`${this.endpointUrl}/${id}`);
  }
}
