import { Injectable } from '@angular/core';
import {
  Response,
  UnsavedProductConfig,
  ProductConfig,
  TicketsSaleProductsConfigEndpoint,
} from '@fullyops/legacy/data';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UiTicketSaleProductsConfigurationService {
  readonly store$ = this.endpoint.createStore();

  constructor(protected endpoint: TicketsSaleProductsConfigEndpoint) {}

  loadAll(ticketId: string) {
    this.endpoint
      .getAll(ticketId)
      .subscribe((response) => this.store$.next(response));
  }

  get(id: string): Observable<Response<ProductConfig>> {
    return;
  }

  create(ticketId: string, configurationId: string) {
    return this.endpoint.post(
      ticketId,
      configurationId,
      undefined,
      this.store$
    );
  }

  update(
    id: string,
    unsavedModel: UnsavedProductConfig
  ): Observable<Response<ProductConfig>> {
    return;
  }

  remove(ticketId: string, configurationId: string): Observable<Response<{}>> {
    return this.endpoint.delete(
      ticketId,
      configurationId,
      undefined,
      this.store$
    );
  }
}
