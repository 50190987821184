import { Injectable, Inject } from '@angular/core';
import { Endpoint } from '../Endpoint';
import { Observable, BehaviorSubject } from 'rxjs';
import { Response } from '../Response';
import { HttpClient } from '@angular/common/http';
import { API_SERVICE_CONFIG } from '../ApiServiceConfig';
import { IApiServiceConfig } from '../IApiServiceConfig';
import { User } from '../../models/v0/User';
import { ISerialisedUser } from '../../models/v0/serialisations/ISerialisedUser';

@Injectable()
export class MeEndpoint extends Endpoint<User, ISerialisedUser> {
  constructor(
    @Inject(API_SERVICE_CONFIG) private config: IApiServiceConfig,
    private http: HttpClient
  ) {
    super(User.fromSerialised);
  }

  private readonly url = this.config.url + 'users/me/';

  get(
    source?: Observable<ISerialisedUser>,
    store?: BehaviorSubject<Response<User[]>>
  ): Observable<Response<User>> {
    const defaultSource = this.http.get<ISerialisedUser>(this.url);

    return this.retrieve(source || defaultSource, store);
  }
}
