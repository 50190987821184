import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import {
  SubProductVariant,
  UnsavedSubProductVariant,
} from '@fullyops/legacy/data';
import { BehaviorSubject } from 'rxjs';
import { SubProductVariantFormService } from './sub-product-variant-form.service';
import {
  ProductStatus,
  ActionButtons,
  PermissionType,
  FieldType,
} from '@fullyops/legacy/ui/ui-shared/utils/crm-types';
import {
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ComponentInOutAnimation } from '@fullyops/legacy/ui/ui-shared/utils/component-base-animation';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'crm-sub-product-variant-form',
  templateUrl: './sub-product-variant-form.component.html',
  styleUrls: ['./sub-product-variant-form.component.scss'],
  providers: [SubProductVariantFormService],
  animations: [ComponentInOutAnimation.AnimTrigger],
})
export class SubProductVariantFormComponent implements OnInit {
  @Input() preview = false;
  @Input() subProductId = '';
  @Input() subProductVariant?: SubProductVariant;
  @Input() uploadService: (
    file: File,
    extension: string
  ) => BehaviorSubject<{ url: string }>;
  @Output() editEvent = new EventEmitter();
  @Output() deleteEvent = new EventEmitter();
  @Output() cancelEvent = new EventEmitter();
  @Output() saveEvent = new EventEmitter<UnsavedSubProductVariant>();

  draft$: BehaviorSubject<UnsavedSubProductVariant>;
  actions: ActionButtons = {};
  form: UntypedFormGroup;
  formFields: any[];

  statusEnum = ProductStatus;

  constructor(
    public subProductVariantFormService: SubProductVariantFormService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.draft$ = new BehaviorSubject<UnsavedSubProductVariant>(
      new UnsavedSubProductVariant('', 0, 0, '', '', this.subProductId, [])
    );

    this.initForm();
    this.initActionButtons();
    this.initFields();
  }

  initFields() {
    this.formFields = [
      [
        { type: 'separator', label: 'variantInformation' },
        { name: 'name', label: 'name', type: 'text', size: 12 },
        { name: 'price', label: 'price', type: 'price', size: 6 },
        { name: 'stock', label: 'stock', type: 'number', size: 6 },
        {
          name: 'description',
          label: 'description',
          type: 'textarea',
          size: 12,
        },
      ],
    ];
  }

  initForm() {
    if (this.subProductVariant) {
      this.draft$.next(this.subProductVariant.toDraft());
    }

    this.subProductVariantFormService.initForm(
      this.draft$.getValue(),
      this.preview
    );
    this.subProductVariantFormService.subProductVariantForm$.subscribe(
      (form) => (this.form = form)
    );
  }

  initActionButtons() {
    if (this.preview) {
      this.actions.primary = {
        label: 'edit',
        color: 'primary',
        permissions: [PermissionType.CAN_EDIT_PRODUCTS],
      };
    } else {
      this.actions.primary = {
        label: 'save',
        color: 'primary',
      };
    }

    if (!this.preview && this.subProductVariant) {
      this.actions.warn = {
        label: 'delete',
        color: 'warn',
      };
    }
  }

  onActionEvent(event: string) {
    switch (event) {
      case 'save':
        this.onSubmit();
        break;
      case 'cancel':
        this.onCancel();
        break;
      case 'delete':
        this.onDelete();
        break;
      case 'edit':
        this.onEdit();
        break;
      default:
        break;
    }
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }

  removeLine(index: number) {
    this.accessories.controls.splice(index, 1);
  }

  get accessories(): UntypedFormArray {
    return this.form.get('accessories') as UntypedFormArray;
  }

  get urlControl(): UntypedFormControl {
    return this.form.get('imageUri') as UntypedFormControl;
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.accessories.controls,
      event.previousIndex,
      event.currentIndex
    );
    moveItemInArray(
      this.accessories.value,
      event.previousIndex,
      event.currentIndex
    );
  }

  createEntry() {
    this.accessories.push(new UntypedFormControl('', Validators.required));
  }

  onSubmit() {
    if (this.form.valid) {
      this.saveEvent.emit(
        this.subProductVariantFormService.getSubProductDraft()
      );
    } else {
      this.subProductVariantFormService.markAllAsTouched();
      this.openSnackBar('Some mandatory fields are still blank!', 'Close');
    }
  }

  onCancel() {
    this.cancelEvent.emit();
  }

  onEdit() {
    this.editEvent.emit();
  }

  onDelete() {
    this.deleteEvent.emit();
  }
}
