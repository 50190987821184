import { Response } from '../Response';
import { Endpoint } from '../Endpoint';
import { HttpClient } from '@angular/common/http';
import { IListResponse } from '../IListResponse';
import { Injectable, Inject } from '@angular/core';
import { API_SERVICE_CONFIG } from '../ApiServiceConfig';
import { IApiServiceConfig } from '../IApiServiceConfig';

import { Observable, BehaviorSubject } from 'rxjs';

import { ISerialisedProductConfig } from '../../models/v0/serialisations/ISerialisedProductConfig';
import { ProductConfigSimulation } from '../../models/v0/ProductConfigSimulation';
import { ISerialisedProductConfigSimulation } from '../../models/v0/serialisations/ISerialisedProductConfigSimulation';

type ProductConfigSimulationList =
  IListResponse<ISerialisedProductConfigSimulation>;

@Injectable()
export class TicketsSaleProductsConfigSimulationEndpoint extends Endpoint<
  ProductConfigSimulation,
  ISerialisedProductConfig
> {
  private readonly url = this.config.url + 'tickets/sales';

  constructor(
    @Inject(API_SERVICE_CONFIG) private config: IApiServiceConfig,
    private http: HttpClient
  ) {
    super(ProductConfigSimulation.fromSerialised);
  }

  getAll(
    ticketId: string,
    filter?: {},
    source?: Observable<ProductConfigSimulationList>,
    store?: BehaviorSubject<Response<ProductConfigSimulation[]>>
  ): Observable<Response<ProductConfigSimulation[]>> {
    const defaultSource = this.http.get<ProductConfigSimulationList>(
      `${this.url}/${ticketId}/simulations`
    );

    return this.list(source || defaultSource, store);
  }
}
