import { ISerialisedSaleTicketInfo } from './serialisations/ISerialisedSaleTicketInfo';

export class SaleTicketInfo {
  constructor(
    readonly formation: string,
    readonly deliveryDate: string,
    readonly deliveryAddress: string,
    readonly payment: string,
    readonly warranty: string,
    readonly installation: string,
    readonly technicalAssistance: string,
    readonly expiresIn: string,
    readonly proposalNumber: string
  ) {
    if (formation) this.formation = formation.trim();
    if (deliveryDate) this.deliveryDate = deliveryDate.trim();
    if (deliveryAddress) this.deliveryAddress = deliveryAddress.trim();
    if (payment) this.payment = payment.trim();
    if (warranty) this.warranty = warranty.trim();
    if (installation) this.installation = installation.trim();
    if (technicalAssistance)
      this.technicalAssistance = technicalAssistance.trim();
    if (expiresIn) this.expiresIn = expiresIn.trim();
    if (proposalNumber) this.proposalNumber = proposalNumber.trim();
  }

  static fromSerialised(serialised: ISerialisedSaleTicketInfo) {
    return new SaleTicketInfo(
      serialised.formation,
      serialised.deliveryDate,
      serialised.deliveryAddress,
      serialised.payment,
      serialised.warranty,
      serialised.installation,
      serialised.technicalAssistance,
      serialised.expiresIn,
      serialised.proposalNumber
    );
  }

  toSerialised(): ISerialisedSaleTicketInfo {
    return {
      formation: this.formation,
      deliveryDate: this.deliveryDate,
      deliveryAddress: this.deliveryAddress,
      payment: this.payment,
      warranty: this.warranty,
      installation: this.installation,
      technicalAssistance: this.technicalAssistance,
      expiresIn: this.expiresIn,
      proposalNumber: this.proposalNumber,
    };
  }
}
