import { Injectable } from '@angular/core';
import {
  InstallationTicketAssigneeEndpoint,
  TicketInstallationAssignee,
  Response,
  CompanyAssigneeEndpoint,
  CompanyAssignee,
} from '@fullyops/legacy/data';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UiCompanyAssigneeService {
  readonly store$ = this.service.createStore();
  constructor(protected service: CompanyAssigneeEndpoint) {}

  assignUserToCompany(userId: string, companyId: string) {
    return this.service.assignUserToCompany(
      userId,
      companyId,
      undefined,
      this.store$
    );
  }

  unassignUser(userId: string, companyId: string) {
    return this.service.unassignCompanyToUser(
      userId,
      companyId,
      undefined,
      this.store$
    );
  }

  getCompanyAssigneesByIds(filters: Object, userId: string, companyId: string) {
    return this.service.getCompanyAssigneesByIds(
      filters,
      userId,
      companyId,
      undefined,
      this.store$
    );
  }

  getAllCompanyAssignees(
    filters: Object
  ): Observable<Response<CompanyAssignee[]>> {
    return this.service.getAll(filters);
  }
}
