<mat-sidenav-container>
  <mat-sidenav
    [@expandedCollapsed]="{
      value: collapsed && !(isMobileScreen$ | async) ? 'collapsed' : 'expanded',
      params: { expandedWidth: expandedWidth }
    }"
    mode="side"
    [opened]="!(isMobileScreen$ | async) || isMobileSidebarOpen"
    class="mat-elevation-z6 primary"
  >
    <mat-list
      role="list"
      [@expandedCollapsed]="
        collapsed && !(isMobileScreen$ | async) ? 'ulCollapsed' : 'ulExpanded'
      "
    >
      <ng-container *ngFor="let group of systemSections; let i = index">
        <div
          *ngIf="group.name"
          class="section-group-name"
          [class.collapsed]="collapsed && !(isMobileScreen$ | async)"
        >
          {{ group.name | i18nextCap }}
        </div>
        <mat-divider *ngIf="i !== 0"></mat-divider>
        <mat-list-item
          *ngFor="let section of $any(group).sections"
          [hideIfNotAllowed]="$any(section).permissions"
          [featureToggle]="section.featureToggle"
          routerLinkActive="selected"
          [routerLinkActiveOptions]="{ exact: section.exactPath }"
          [matTooltip]="
            (isConfigurationDisabled(section.name)
              ? 'underDevelopment'
              : section.toolTipText
            ) | i18nextCap
          "
          matTooltipPosition="right"
          [class.collapsed]="collapsed && !(isMobileScreen$ | async)"
        >
          <a
            mat-menu-item
            routerLink="{{ section.path }}"
            [class.collapsed]="collapsed && !(isMobileScreen$ | async)"
            [class.disabled]="isConfigurationDisabled(section.name)"
            (click)="onMobileSidebarChange.emit()"
          >
            <mat-icon>
              <fa-icon [icon]="section.icon" class="icon-color"></fa-icon>
            </mat-icon>
            <span
              class="glyphicon glyphicon-plus"
              [class.collapsed]="collapsed && !(isMobileScreen$ | async)"
            >
              {{ section.name | i18nextCap }}</span
            >
          </a>
        </mat-list-item>
      </ng-container>
    </mat-list>
    <div class="collapse-button">
      <button class="expand" mat-icon-button (click)="toggleExpand()">
        <fa-icon
          [icon]="
            collapsed && !(isMobileScreen$ | async) ? faArrowRight : faArrowLeft
          "
          class="icon-color"
          size="xl"
        ></fa-icon>
      </button>
    </div>
  </mat-sidenav>
  <mat-sidenav-content
    [@expandedCollapsed]="{
      value:
        collapsed && !(isMobileScreen$ | async)
          ? 'contentCollapsed'
          : 'contentExpanded',
      params: { expandedWidth: expandedWidth }
    }"
    (click)="collapse()"
  >
    <div class="router-outlet-css">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
