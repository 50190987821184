import { ReportSection } from './ReportSection';
import { ISerialisedUnsavedSubProductVariant } from './serialisations/ISerialisedSubProductVariant';

export class UnsavedSubProductVariant {
  constructor(
    readonly name: string,
    readonly price: number,
    readonly stock: number,
    readonly description: string,
    readonly imageUri: string,
    readonly subProductId: string,
    readonly accessories: string[]
  ) {
    if (name) this.name = name.trim();
    if (description) this.description = description.trim();
  }

  static fromSerialised(serialised: ISerialisedUnsavedSubProductVariant) {
    return new UnsavedSubProductVariant(
      serialised.name,
      serialised.price,
      serialised.stock,
      serialised.description,
      serialised.imageUri,
      serialised.subProductId,
      serialised.accessories
    );
  }

  toSerialised(): ISerialisedUnsavedSubProductVariant {
    return {
      name: this.name,
      price: this.price,
      stock: this.stock,
      description: this.description,
      imageUri: this.imageUri,
      subProductId: this.subProductId,
      accessories: this.accessories,
    };
  }

  clone(
    partialUnsavedPart: PartialUnsavedSubProductVariant
  ): UnsavedSubProductVariant {
    const resolve = (key: keyof UnsavedSubProductVariant) =>
      partialUnsavedPart.hasOwnProperty(key)
        ? partialUnsavedPart[key]
        : this[key];

    return new UnsavedSubProductVariant(
      resolve('name'),
      resolve('price'),
      resolve('stock'),
      resolve('description'),
      resolve('imageUri'),
      resolve('subProductId'),
      resolve('accessories')
    );
  }
}
type PartialUnsavedSubProductVariant = Partial<
  Pick<
    UnsavedSubProductVariant,
    | 'name'
    | 'price'
    | 'stock'
    | 'description'
    | 'imageUri'
    | 'subProductId'
    | 'accessories'
  >
>;
