import {
  InterventionAnomaly,
  UnsavedInterventionUserParts,
} from '@fullyops/legacy/data';
import { InterventionUserParts } from './InterventionUserParts';
import { ISerialisedInterventionMachine } from './serialisations/ISerialisedInterventionMachine';
import { TicketRequestPart } from './TicketRequestPart';
import { UnsavedInterventionMachine } from './UnsavedInterventionMachine';
import { User } from './User';
import { ClientEquipment } from './ClientEquipment';

export class InterventionMachine {
  constructor(
    readonly anomalies: InterventionAnomaly[],
    readonly closedAt: Date | null,
    readonly createdAt: Date,
    readonly createdBy: User | null,
    readonly description: string,
    readonly id: string,
    readonly internalObservation: string,
    readonly parts: TicketRequestPart[],
    readonly supportTicketId: string,
    readonly installationTicketId: string,
    readonly unregisteredParts: string,
    readonly updatedAt: Date,
    readonly updatedBy: User | null,
    readonly userObservation: string,
    readonly userParts: { [key: string]: InterventionUserParts },
    readonly clientEquipment: ClientEquipment
  ) {
    if (description) this.description = description.trim();
    if (internalObservation)
      this.internalObservation = internalObservation.trim();
    if (unregisteredParts) this.unregisteredParts = unregisteredParts.trim();
    if (userObservation) this.userObservation = userObservation.trim();
  }

  static fromSerialised(serialised: ISerialisedInterventionMachine) {
    const anomalies = serialised.anomalies.map(
      InterventionAnomaly.fromSerialised
    );
    const parts = serialised.parts.map(TicketRequestPart.fromSerialised);
    const createdBy = serialised.createdBy
      ? User.fromSerialised(serialised.createdBy)
      : null;
    const updatedBy = serialised.updatedBy
      ? User.fromSerialised(serialised.updatedBy)
      : null;

    const userParts = {};
    Object.keys(serialised.userParts).forEach(
      (key) =>
        (userParts[key] = InterventionUserParts.fromSerialised(
          serialised.userParts[key]
        ))
    );

    return new InterventionMachine(
      anomalies,
      serialised.closedAt,
      serialised.createdAt,
      createdBy,
      serialised.description,
      undefined, // id
      serialised.internalObservation,
      parts,
      serialised.supportTicketId,
      serialised.installationTicketId,
      serialised.unregisteredParts,
      serialised.updatedAt,
      updatedBy,
      serialised.userObservation,
      userParts,
      ClientEquipment.fromSerialised(serialised.clientEquipment)
    );
  }

  toSerialised(): ISerialisedInterventionMachine {
    const anomaliesObj = this.anomalies.map((anomaly) =>
      anomaly.toSerialised()
    );
    const partsObj = this.parts.map((part) => part.toSerialised());

    const createdByObj = this.createdBy.toSerialised();
    const updatedByObj = this.updatedBy.toSerialised();

    const userPartsObj = {};
    Object.keys(this.userParts).forEach(
      (key) => (userPartsObj[key] = this.userParts[key].toSerialised())
    );

    return {
      anomalies: anomaliesObj,
      closedAt: this.closedAt,
      createdAt: this.createdAt,
      createdBy: createdByObj,
      description: this.description,
      id: this.id,
      internalObservation: this.internalObservation,
      parts: partsObj,
      supportTicketId: this.supportTicketId,
      installationTicketId: this.installationTicketId,
      unregisteredParts: this.unregisteredParts,
      updatedAt: this.updatedAt,
      updatedBy: updatedByObj,
      userObservation: this.userObservation,
      userParts: userPartsObj,
      clientEquipment: this.clientEquipment,
    };
  }

  toDraft(): UnsavedInterventionMachine {
    const userParts: { [userId: string]: UnsavedInterventionUserParts } = {};

    Object.keys(this.userParts).forEach(
      (key) =>
        (userParts[key] = UnsavedInterventionUserParts.fromSerialised(
          this.userParts[key].toDraft()
        ))
    );

    return UnsavedInterventionMachine.fromSerialised({
      anomalies: this.anomalies.map((anomalies) => anomalies.toDraft()),
      closedAt: undefined,
      createdAt: undefined,
      createdBy: undefined,
      description: '',
      internalObservation: '',
      parts: this.parts.map((part) => part.toDraft()),
      supportTicketId: '',
      installationTicketId: '',
      unregisteredParts: '',
      updatedAt: undefined,
      updatedBy: undefined,
      userObservation: '',
      userParts,
      clientEquipment: undefined,
    });
  }

  clone(
    partialInterventionMachine: InterventionMachinePartial
  ): InterventionMachine {
    const resolve = (key: keyof InterventionMachine) =>
      partialInterventionMachine.hasOwnProperty(key)
        ? partialInterventionMachine[key]
        : this[key];

    return new InterventionMachine(
      resolve('anomalies'),
      resolve('closedAt'),
      resolve('createdAt'),
      resolve('createdBy'),
      resolve('description'),
      resolve('id'),
      resolve('internalObservation'),
      resolve('parts'),
      resolve('supportTicketId'),
      resolve('installationTicketId'),
      resolve('unregisteredParts'),
      resolve('updatedAt'),
      resolve('updatedBy'),
      resolve('userObservation'),
      resolve('userParts'),
      resolve('clientEquipment')
    );
  }
}
type InterventionMachinePartial = Partial<
  Pick<
    InterventionMachine,
    | 'anomalies'
    | 'closedAt'
    | 'createdAt'
    | 'createdBy'
    | 'description'
    | 'id'
    | 'internalObservation'
    | 'parts'
    | 'supportTicketId'
    | 'installationTicketId'
    | 'unregisteredParts'
    | 'updatedAt'
    | 'updatedBy'
    | 'userObservation'
    | 'userParts'
    | 'clientEquipment'
  >
>;
