import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ApiWorkOrderCategoriesAdapterService } from './work-order-categories/api-work-order-categories-adapter.service';

import { environment } from '../../environments/environment';
import {
  API_SERVICE_CONFIG,
  createApiServiceConfig,
} from '@fullyops/data/base/configuration/api-service-config';
import { ApiWorkOrderScopesAdapterService } from './work-order-scopes/api-adapter.service';
import { ApiActionTypeAdapterService } from './action-types/api-action-types-adapter.service';

const apiServiceConfig = () => {
  return createApiServiceConfig(environment.apiUrl);
};

@NgModule({
  exports: [],
  imports: [HttpClientModule],
  providers: [
    ApiWorkOrderCategoriesAdapterService,
    ApiWorkOrderScopesAdapterService,
    ApiActionTypeAdapterService,
    { provide: API_SERVICE_CONFIG, useFactory: apiServiceConfig },
  ],
})
export class DataModule {}
