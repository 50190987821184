import { ISerialisedPart } from './serialisations/ISerialisedPart';
import { Company } from './Company';
import { UnsavedPart } from './UnsavedPart';

export class Part {
  constructor(
    readonly id: string,
    readonly name: string,
    readonly ref: string,
    readonly price: number
  ) {
    if (name) this.name = name.trim();
    if (ref) this.ref = ref.trim();
  }

  static fromSerialised(serialised: ISerialisedPart) {
    return new Part(
      serialised.id,
      serialised.name,
      serialised.ref,
      serialised.price
    );
  }

  toSerialised(): ISerialisedPart {
    return {
      id: this.id,
      name: this.name,
      ref: this.ref,
      price: this.price,
    };
  }

  toDraft(): UnsavedPart {
    return UnsavedPart.fromSerialised({
      ...this,
    });
  }

  clone(partialPart: PartialPart): Part {
    const resolve = (key: keyof Part) =>
      partialPart.hasOwnProperty(key) ? partialPart[key] : this[key];

    return new Part(
      resolve('id'),
      resolve('name'),
      resolve('ref'),
      resolve('price')
    );
  }
}
type PartialPart = Partial<Pick<Part, 'id' | 'name' | 'ref' | 'price'>>;
