<crm-breadcrumb
  [header]="title"
  [tabs]="tabs"
  [actionButtons]="actions"
  (actionEvent)="onActionEvent($event)"
></crm-breadcrumb>
<crm-container [maxSize]="'xl'">
  <div [@inOut]="'in'" class="content-wraper">
    <div class="table-container" *ngIf="listCompaniesPagination$">
      <div class="table-container-header">
        <ng-container *ngIf="users$ | async as users">
          <crm-companies-filters
            [filters$]="filters$"
            [users]="users"
            (filtersChangeEvent)="onFiltersChange($event)"
          ></crm-companies-filters>
        </ng-container>
      </div>
      <crm-table
        [paginatedList$]="listCompaniesPagination$"
        [displayedColumns]="displayedColumns"
        (onPageChange)="onPageChange($event)"
        (onClick)="onOpen($event)"
      >
        <!-- Name Column-->
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef>
            {{ 'name' | i18nextCap }}
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <div class="cell-row">
              <span class="title">{{ element.name }}</span>
              <div>
                <span *ngIf="element.mobileNumber" class="subtitle"
                  ><mat-icon>phone</mat-icon> {{ element.mobileNumber }}</span
                >
                <span *ngIf="element.city" class="subtitle"
                  ><mat-icon>apartment</mat-icon> {{ element.city }},
                  {{ element.zipCode }}</span
                >
              </div>
            </div>
          </mat-cell>
        </ng-container>

        <!-- Type Column-->
        <ng-container matColumnDef="type">
          <mat-header-cell *matHeaderCellDef>
            {{ 'type' | i18nextCap }}
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <div class="cell-row">
              <label [ngClass]="['mat-label', CompanyTypeColor[element.type]]">
                {{ element.type | i18nextCap }}
              </label>
            </div>
          </mat-cell>
        </ng-container>
      </crm-table>
    </div>
  </div>
</crm-container>
