import { Injectable } from '@angular/core';
import { UiService } from './ui.service';
import {
  TicketSale,
  UnsavedTicketSale,
  TicketsSaleEndpoint,
} from '@fullyops/legacy/data';

@Injectable({
  providedIn: 'root',
})
export class UiTicketsSaleService extends UiService<
  TicketSale,
  UnsavedTicketSale,
  TicketsSaleEndpoint
> {
  constructor(protected ticketsSaleEndpoint: TicketsSaleEndpoint) {
    super(ticketsSaleEndpoint.createStore(), ticketsSaleEndpoint);
  }

  ticket$;
  getTicketEntity() {
    return this.ticket$;
  }

  setTicketEntity(ticketEntity$) {
    this.ticket$ = ticketEntity$;
  }
}
