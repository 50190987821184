import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { API_SERVICE_CONFIG } from '../../data/api/ApiServiceConfig';
import { IApiServiceConfig } from '../../data/api/IApiServiceConfig';
import {
  InterventionTypeRequest,
  InterventionTypeResponse,
} from '../../data/api/types/Intervention';
import { BehaviorSubject, tap } from 'rxjs';
import { SelectOptionType } from '../ui-shared/form-select/form-select.component';
import * as moment from 'moment';
import { FormControl } from '@angular/forms';
import { UiServiceWithCache } from './ui.service-with-cache.service';

@Injectable({
  providedIn: 'root',
})
export class InterventionTypeController extends UiServiceWithCache {
  constructor(
    @Inject(API_SERVICE_CONFIG) private config: IApiServiceConfig,
    private http: HttpClient
  ) {
    super();
    this.updateInterventionTypeSelectOptions();
  }

  protected cacheData = {
    interventionType$: new BehaviorSubject<InterventionTypeResponse[]>([]),
    interventionTypeSelectOptions$: new BehaviorSubject<SelectOptionType[]>([]),
  };

  private readonly url = this.config.url + 'interventions/types';

  fetchAll({ forceFetch }: { forceFetch?: boolean }) {
    if (!this.isCacheValid() || forceFetch) {
      const response = this.http.get<InterventionTypeResponse[]>(`${this.url}`);
      response.subscribe((e) => {
        this.cacheData.interventionType$.next(e);
        this.cacheTime = moment();
      });
    }
  }

  protected updateInterventionTypeSelectOptions() {
    this.cacheData.interventionType$.subscribe((e) => {
      this.cacheData.interventionTypeSelectOptions$.next(
        e.map(({ label, active }) => ({ id: label, name: label, active }))
      );
    });
  }

  getInterventionTypeSelectOptions$({ forceFetch }: { forceFetch?: boolean }) {
    if (forceFetch) this.fetchAll({ forceFetch });
    return this.cacheData.interventionTypeSelectOptions$;
  }

  getInterventionType$({ forceFetch }: { forceFetch?: boolean }) {
    if (forceFetch) this.fetchAll({ forceFetch });
    return this.cacheData.interventionType$;
  }

  setDefaultValueOnForm({ formControl }: { formControl: FormControl }) {
    this.fetchAll({});
    this.cacheData.interventionType$.subscribe((value) => {
      if (!value) return;
      const defaultValue = value.find(({ isDefault }) => isDefault)?.label;
      if (defaultValue) formControl.setValue(defaultValue);
    });
  }

  updateByLabel({
    label,
    data,
  }: {
    label: string;
    data: InterventionTypeRequest;
  }) {
    const request = this.http
      .patch<InterventionTypeResponse>(`${this.url}/${label}`, data)
      .pipe(
        tap((res) => {
          const newData = this.cacheData.interventionType$.value;
          const indexToChange = newData.findIndex(
            (data) => data.label == label
          );
          newData[indexToChange] = res;
          this.cacheData.interventionType$.next(newData);
        })
      );

    return request;
  }
}
