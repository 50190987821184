import { Injectable } from '@angular/core';
import { UiService } from './ui.service';
import {
  TicketRequest,
  UnsavedTicketRequest,
  TicketsRequestEndpoint,
} from '@fullyops/legacy/data';

@Injectable({
  providedIn: 'root',
})
export class UiTicketsRequestService extends UiService<
  TicketRequest,
  UnsavedTicketRequest,
  TicketsRequestEndpoint
> {
  constructor(protected ticketsRequestEndpoint: TicketsRequestEndpoint) {
    super(ticketsRequestEndpoint.createStore(), ticketsRequestEndpoint);
  }

  sendEmail(ticketRequestId: string) {
    return this.ticketsRequestEndpoint.email(ticketRequestId);
  }
}
