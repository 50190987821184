import { Response } from '../Response';
import { Endpoint } from '../Endpoint';
import { HttpClient } from '@angular/common/http';
import { IListResponse } from '../IListResponse';
import { Injectable, Inject } from '@angular/core';
import { API_SERVICE_CONFIG } from '../ApiServiceConfig';

import { Observable, BehaviorSubject } from 'rxjs';

import { Anomaly } from '../../models/v0/Anomaly';
import { ISerialisedAnomaly } from '../../models/v0/serialisations/ISerialisedAnomaly';
import { IApiServiceConfig } from '../IApiServiceConfig';
import { UnsavedAnomaly } from '../../models/v0/UnsavedAnomaly';
import { map, tap } from 'rxjs/operators';

type AnomalyList = IListResponse<ISerialisedAnomaly>;

@Injectable()
export class AnomaliesEndpoint extends Endpoint<Anomaly, ISerialisedAnomaly> {
  private readonly url = this.config.url + 'anomalies';

  constructor(
    @Inject(API_SERVICE_CONFIG) private config: IApiServiceConfig,
    private http: HttpClient
  ) {
    super(Anomaly.fromSerialised);
  }

  getAll(
    filters: {},
    source?: Observable<AnomalyList>,
    store?: BehaviorSubject<Response<Anomaly[]>>
  ): Observable<Response<Anomaly[]>> {
    let query = '';
    Object.keys(filters).forEach((key) => (query += `&${key}=${filters[key]}`));

    const defaultSource = this.http.get<AnomalyList>(
      `${this.url}?page=0${query}`
    );
    return this.list(source || defaultSource, store);
  }

  get(
    id: string,
    source?: Observable<ISerialisedAnomaly>,
    store?: BehaviorSubject<Response<Anomaly[]>>
  ): Observable<Response<Anomaly>> {
    const defaultSource = this.http.get<ISerialisedAnomaly>(
      `${this.url}${id}/`
    );
    return this.retrieve(source || defaultSource, store);
  }

  post(
    part: UnsavedAnomaly,
    source?: Observable<ISerialisedAnomaly>,
    store?: BehaviorSubject<Response<Anomaly[]>>
  ): Observable<Response<Anomaly>> {
    const defaultSource = this.http.post<ISerialisedAnomaly>(
      this.url,
      part.toSerialised()
    );
    return this.add(source || defaultSource, store);
  }

  patch(
    id: string,
    part: UnsavedAnomaly,
    source?: Observable<ISerialisedAnomaly>,
    store?: BehaviorSubject<Response<Anomaly[]>>
  ): Observable<Response<Anomaly>> {
    const { ...body } = part.toSerialised();
    const defaultSource = this.http.patch<ISerialisedAnomaly>(
      `${this.url}${id}/`,
      body
    );

    return defaultSource.pipe(
      tap((instance) =>
        this.update(
          source || defaultSource,
          Anomaly.fromSerialised(instance),
          store
        )
      ),
      map((serialiser) => new Response(Anomaly.fromSerialised(serialiser)))
    );
  }

  delete(
    id: string,
    source?: Observable<{}>,
    store?: BehaviorSubject<Response<Anomaly[]>>
  ): Observable<Response<{}>> {
    const defaultSource = this.http.delete<{}>(`${this.url}${id}/`);
    return this.remove(source || defaultSource, id, store);
  }
}
