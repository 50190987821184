import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_SERVICE_CONFIG } from '../ApiServiceConfig';
import { IApiServiceConfig } from '../IApiServiceConfig';
import { Observable } from 'rxjs';

export interface ResetPasswordPayload {
  duplicatePassword: string;
  newPassword: string;
  token: string;
  username: string;
}

@Injectable()
export class PasswordEndpoint {
  private readonly url = this.config.url + 'password';

  constructor(
    @Inject(API_SERVICE_CONFIG) private config: IApiServiceConfig,
    private http: HttpClient
  ) {}

  forgotPassword(username: string, tenantName: string): Observable<{}> {
    return this.http.post(
      `${this.url}/forgot/${username}`,
      {},
      { headers: { tenantName: tenantName } }
    );
  }

  resetPassword(
    body: ResetPasswordPayload,
    tenantName: string
  ): Observable<{}> {
    return this.http.post(`${this.url}/reset/`, body, {
      headers: { tenantName: tenantName },
    });
  }
}
