import {
  FormTemplateFields,
  TemplateFormFields,
} from '@fullyops/legacy/ui/ui-shared/form-template/form-template.component';
import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import {
  FormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import {
  Part,
  TicketRequest,
  TicketRequestPart,
  TicketSupportMultimachine,
  User,
  UserPart,
} from '@fullyops/legacy/data';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import {
  FieldType,
  PermissionType,
} from '@fullyops/legacy/ui/ui-shared/utils/crm-types';
import { faRotate, faFileCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { UiAuthService } from '../ui-auth.service';

@Component({
  selector: 'crm-ticket-support-request-parts',
  templateUrl: './ticket-support-request-parts.component.html',
  styleUrls: ['./ticket-support-request-parts.component.scss'],
})
export class InterventionPartsComponent implements OnInit {
  constructor(protected router: Router, private auth: UiAuthService) {}
  @Input() isDetailPage: boolean;
  @Input() ticket: TicketSupportMultimachine;
  @Input() formGroup: UntypedFormGroup;
  @Input() users$: BehaviorSubject<User[]>;
  @Input() usersParts$: BehaviorSubject<{ [userId: string]: Part[] }>;
  @Input() requests: TicketRequest[];
  @Output() addPartEvent = new EventEmitter<{ part: Part; userId: string }>();
  @Output() removePartEvent = new EventEmitter<{
    partId: string;
    userId: string;
  }>();

  currentUser: User = this.auth.currentUser;
  requestPermissions = [
    PermissionType.CAN_EDIT_REQUEST_TICKETS,
    PermissionType.CAN_EDIT_OWN_REQUEST_TICKETS,
  ];

  usersParts = [];
  filtredParts$ = new BehaviorSubject<Part[]>([]);
  requestList$ = new BehaviorSubject<any[]>([]);
  total = 0;

  formFields: any[];
  formFieldsV2: TemplateFormFields<any> = [
    [{ name: 'company', label: 'company', type: 'text' }],
  ];

  displayedColumns: string[] = ['name', 'price', 'quantity', 'total'];
  displayedColumnsFooter: string[] = ['name', 'total'];

  faFileCirclePlus = faFileCirclePlus;

  canOnlySeeOwn = this.auth.hasPermission([
    PermissionType.CAN_EDIT_OWN_REQUEST_TICKETS,
  ]);

  ngOnInit() {
    this.initRequestsList();
    this.calculatePartsTotal();

    this.formFields = [
      [
        {
          name: 'unregisteredParts',
          type: 'textarea',
          label: 'generalParts',
          size: 12,
        },
      ],
    ];
  }

  updatePartsLists(users: User[]) {
    const usersPartsFormGroup = (
      this.formGroup.get('usersParts') as UntypedFormGroup
    ).controls;

    this.usersParts = [];

    Object.keys(usersPartsFormGroup).forEach((key) => {
      users
        .filter((u) => u.id === key)
        .forEach((user) => {
          this.usersParts.push({
            userId: key,
            userName: `${user.firstName} ${user.lastName}`,
            parts: usersPartsFormGroup[key],
            userParts$: new BehaviorSubject(
              this.usersParts$.getValue()[user.id]
                ? this.usersParts$
                    .getValue()
                    [user.id].filter(
                      (part) =>
                        !usersPartsFormGroup[key].value.find(
                          (p) => p.partId === part.id
                        )
                    )
                : []
            ),
          });
        });
    });
  }

  calculatePartsTotal() {
    this.total = this.requests.reduce((accumulator, currentValue) => {
      const partTotal = currentValue.parts.reduce(
        (a, c: TicketRequestPart) => (a += c.part.price * c.quantity),
        0
      );
      return (accumulator += partTotal);
    }, 0);
  }

  initRequestsList() {
    this.requestList$.next(
      this.requests.reduce((accumulator, currentValue) => {
        return accumulator.concat(this.parseRequest(currentValue));
      }, [])
    );
  }

  parseRequest(ticket: TicketRequest) {
    return ticket.parts.map((request) => ({
      id: ticket.id,
      columns: {
        ticket: {
          label: 'identifier',
          type: FieldType.Request,
          value: `R-00${ticket.identifier}`,
        },
        part: { label: 'part', type: FieldType.Text, value: request.part.name },
        unitPrice: {
          label: 'unitPrice',
          type: FieldType.Price,
          value: request.part.price,
        },
        quantity: {
          label: 'qty',
          type: FieldType.Number,
          value: request.quantity,
        },
        costSubtotal: {
          label: 'costSubtotal',
          type: FieldType.Price,
          value: request.part.price * request.quantity,
        },
        status: {
          label: 'status',
          type: FieldType.Label,
          value: ticket.status.name,
          color: ticket.status.color,
        },
      },
    }));
  }

  createTicketRequest() {
    this.router.navigate(['/tickets/request/new'], {
      queryParams: {
        supportTicketId: this.ticket.id,
      },
    });
  }

  addUserPart(part: Part, userId: string) {
    this.addPartEvent.emit({ part, userId });
  }

  removeUserPart(partId: string, userId: string) {
    this.removePartEvent.emit({ partId, userId });
  }

  generateFormGroup(ticket: TicketRequest) {
    const formGroup = new UntypedFormGroup({
      company: new UntypedFormControl(ticket.company.name),
    });
    formGroup.disable();
    return formGroup;
  }

  getTotalTicketRequestPrice(ticket: TicketRequest) {
    return ticket.parts
      .map((part) => part.quantity * part.part.price)
      .reduce((acc, value) => acc + value, 0);
  }

  onOpenRequestTicket(requestTicketId: string) {
    this.router.navigate([`/tickets/request/${requestTicketId}/edit`]);
  }
}
