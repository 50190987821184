import { Pipe, PipeTransform } from '@angular/core';
import { TicketType, ActivityLinkType } from '@fullyops/legacy/data';

@Pipe({ name: 'ticketIdentifier' })
export class TicketIdentifierPipe implements PipeTransform {
  transform(value: number, ticketType?: TicketType | ActivityLinkType): string {
    return ticketType === TicketType.REQUEST
      ? `R-${value}`
      : ticketType === TicketType.SUPPORT
      ? `WO-${value}`
      : ticketType === TicketType.SALE
      ? `V-${value}`
      : `${value}`;
  }
}
