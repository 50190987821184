import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { CustomError } from '@fullyops/core/errors/custom-error';
import { Router } from '@angular/router';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private injector: Injector, private ngZone: NgZone) {}

  handleError(error: any) {
    try {
      const customError = this.parseError(error);
      const router = this.injector.get(Router);

      if (customError.isServerside) {
        return; // do nothing
      }

      if (NgZone.isInAngularZone()) {
        router.navigate(['/internal-error'], {
          state: { lastError: customError },
        });
      } else {
        this.ngZone.run(() => {
          router.navigate(['/internal-error'], {
            state: { lastError: customError },
          });
        });
      }

      console.error(error);
    } catch (error) {
      console.error('Error occurred within custom-error-handler.ts', error);
    }
  }

  private parseError(error: any): CustomError {
    let customError: CustomError;

    if (error instanceof HttpErrorResponse) {
      const httpErrorResponse = error as HttpErrorResponse;
      customError = {
        ...httpErrorResponse,
        isServerside: true,
        headers: undefined,
      };
    } else {
      const clientError = error as Error;
      customError = {
        isServerside: false,
        message: clientError.message,
        name: clientError.name,
        stack: clientError.stack,
      };
    }

    customError.navigationUrl = window.location.href;

    return customError;
  }
}
