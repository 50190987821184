import { ClientEquipment } from './ClientEquipment';
import { ISerialisedInstallationClientEquipment } from './serialisations/ISerialisedInstallationClientEquipment';
import { ISerialisedInterventionClientEquipment } from './serialisations/ISerialisedInterventionClientEquipment';
import { UnsavedInterventionClientEquipment } from './UnsavedInterventionClientEquipment';
import { User } from './User';

export class InstallationClientEquipment {
  constructor(
    readonly id: string,
    readonly equipment: ClientEquipment,
    readonly createdAt: string,
    readonly createdBy: User | null,
    readonly ticketId: string,
    readonly updatedAt: string,
    readonly updatedBy: User | null,
    readonly photos: string[],
    readonly deleted: boolean
  ) {}

  static fromSerialised(serialised: ISerialisedInstallationClientEquipment) {
    const createdBy = serialised.createdBy
      ? User.fromSerialised(serialised.createdBy)
      : null;
    const updatedBy = serialised.updatedBy
      ? User.fromSerialised(serialised.updatedBy)
      : null;

    const equipment = ClientEquipment.fromSerialised(serialised.equipment);

    return new InstallationClientEquipment(
      serialised.id,
      equipment,
      serialised.createdAt,
      createdBy,
      serialised.ticketId,
      serialised.updatedAt,
      updatedBy,
      serialised.photos,
      serialised.deleted
    );
  }

  toSerialised(): ISerialisedInstallationClientEquipment {
    const createdByObj = this.createdBy.toSerialised();
    const updatedByObj = this.updatedBy.toSerialised();
    const clientEquipment = this.equipment.toSerialised();

    return {
      id: this.id,
      equipment: clientEquipment,
      createdAt: this.createdAt,
      createdBy: createdByObj,
      ticketId: this.ticketId,
      updatedAt: this.updatedAt,
      updatedBy: updatedByObj,
      deleted: this.deleted,
      photos: this.photos,
    };
  }

  clone(
    partialInstallationClientEquipment: InstallationClientEquipmentPartial
  ): InstallationClientEquipment {
    const resolve = (key: keyof InstallationClientEquipment) =>
      partialInstallationClientEquipment.hasOwnProperty(key)
        ? partialInstallationClientEquipment[key]
        : this[key];

    return new InstallationClientEquipment(
      resolve('id'),
      resolve('equipment'),
      resolve('createdAt'),
      resolve('createdBy'),
      resolve('ticketId'),
      resolve('updatedAt'),
      resolve('updatedBy'),
      resolve('photos'),
      resolve('deleted')
    );
  }
}
type InstallationClientEquipmentPartial = Partial<
  Pick<
    InstallationClientEquipment,
    | 'id'
    | 'equipment'
    | 'createdAt'
    | 'createdBy'
    | 'ticketId'
    | 'updatedAt'
    | 'updatedBy'
    | 'photos'
    | 'deleted'
  >
>;
