import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { UnsavedAnomaly } from '@fullyops/legacy/data';

class AnomalyForm {
  type = new UntypedFormControl();
  description = new UntypedFormControl();

  constructor(anomaly: UnsavedAnomaly, dis: boolean) {
    const fb = new UntypedFormBuilder();
    this.type = fb.control(
      { value: anomaly.type, disabled: dis },
      Validators.required
    );
    this.description = fb.control(
      { value: anomaly.description, disabled: dis },
      Validators.required
    );
  }
}

@Injectable()
export class AnomalyFormService {
  fb = new UntypedFormBuilder();

  private form: BehaviorSubject<UntypedFormGroup> =
    new BehaviorSubject<UntypedFormGroup>(new UntypedFormGroup({}));
  form$: Observable<UntypedFormGroup> = this.form.asObservable();

  constructor() {}

  initForm(draftAnomaly: UnsavedAnomaly, disabled: boolean) {
    this.form.next(this.fb.group(new AnomalyForm(draftAnomaly, disabled)));
  }

  getDraft() {
    return new UnsavedAnomaly(
      null,
      null,
      (this.form.getValue().get('description') as UntypedFormControl).value,
      null,
      null,
      (this.form.getValue().get('type') as UntypedFormControl).value,
      null,
      null
    );
  }

  markAllAsTouched() {
    const currentAnomaly = this.form.getValue();
    currentAnomaly.markAllAsTouched();

    this.form.next(currentAnomaly);
  }
}
