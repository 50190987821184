import { HttpClient } from '@angular/common/http';
import { IApiServiceConfig } from '../IApiServiceConfig';
import { API_SERVICE_CONFIG } from '../ApiServiceConfig';
import { Inject, Injectable } from '@angular/core';
import { Endpoint } from '../Endpoint';
import { BehaviorSubject, Observable } from 'rxjs';
import { Response } from '../Response';
import { IListResponse } from '../IListResponse';
import { CompanyAssignee } from '../../models/v0/CompanyAssignee';
import { ISerialisedCompanyAssignee } from '../../models/v0/serialisations/ISerialisedCompanyAssignee';

type CompanyAssigneeList = IListResponse<ISerialisedCompanyAssignee>;

@Injectable()
export class CompanyAssigneeEndpoint extends Endpoint<
  CompanyAssignee,
  ISerialisedCompanyAssignee
> {
  private readonly url = this.config.url + 'companies/assignees';

  constructor(
    @Inject(API_SERVICE_CONFIG) private config: IApiServiceConfig,
    private http: HttpClient
  ) {
    super(CompanyAssignee.fromSerialised);
  }

  assignUserToCompany(
    assigneeId: string,
    companyId: string,
    source?: Observable<CompanyAssignee>,
    store?: BehaviorSubject<Response<CompanyAssignee[]>>
  ) {
    const body = { assigneeId, companyId };
    const defaultSource = this.http.post<CompanyAssignee>(`${this.url}`, body);
    return this.add(source || defaultSource, store);
  }

  unassignCompanyToUser(
    userId: string,
    companyId: string,
    source?: Observable<CompanyAssignee>,
    store?: BehaviorSubject<Response<CompanyAssignee[]>>
  ) {
    const defaultSource = this.http.delete<null>(
      `${this.url}/${companyId}/${userId}/`
    );
    return this.remove(source || defaultSource, companyId, store);
  }

  getCompanyAssigneesByIds(
    filters: {},
    userId: string,
    companyId: string,
    source?: Observable<CompanyAssigneeList>,
    store?: BehaviorSubject<Response<CompanyAssignee[]>>
  ) {
    let query = '';
    Object.keys(filters).forEach((key) => (query += `&${key}=${filters[key]}`));

    const defaultSource = this.http.get<CompanyAssigneeList>(
      `${this.url}/${companyId}/${userId}?size=100&page=0${query}`
    );

    return this.list(source || defaultSource, store);
  }

  getAll(
    filters: {},
    source?: Observable<CompanyAssigneeList>,
    store?: BehaviorSubject<Response<CompanyAssignee[]>>
  ): Observable<Response<CompanyAssignee[]>> {
    let query = '';
    Object.keys(filters).forEach((key) => (query += `&${key}=${filters[key]}`));

    const defaultSource = this.http.get<CompanyAssigneeList>(
      `${this.url}?size=100&page=0${query}`
    );
    return this.list(source || defaultSource, store);
  }
}
