import { ISerialisedUnsavedLossMotive } from './serialisations/ISerialisedLossMotive';

export class UnsavedLossMotive {
  constructor(readonly name: string, readonly description: string) {
    if (name) this.name = name.trim();
    if (description) this.description = description.trim();
  }

  static fromSerialised(serialised: ISerialisedUnsavedLossMotive) {
    return new UnsavedLossMotive(serialised.name, serialised.description);
  }

  toSerialised(): ISerialisedUnsavedLossMotive {
    return {
      name: this.name,
      description: this.description,
    };
  }

  clone(partialUnsavedLossMotive: PartialUnsavedLossMotive): UnsavedLossMotive {
    const resolve = (key: keyof UnsavedLossMotive) =>
      partialUnsavedLossMotive.hasOwnProperty(key)
        ? partialUnsavedLossMotive[key]
        : this[key];

    return new UnsavedLossMotive(resolve('name'), resolve('description'));
  }
}

type PartialUnsavedLossMotive = Partial<
  Pick<UnsavedLossMotive, 'name' | 'description'>
>;
