<ng-template [ngIf]="user">
  <div
    class="working-period-avatar icon"
    [ngStyle]="{ height: size + 'px', width: size + 'px' }"
  >
    <ngx-avatars
      [matTooltip]="getUserFullName(user)"
      [size]="size"
      [name]="user.firstName + ' ' + user.lastName"
      [src]="user.profilePictureURL || ''"
      class="my-avatar"
      [ngClass]="includeBorder ? 'my-avatar-border' : ''"
    ></ngx-avatars>
  </div>
</ng-template>
