import { ISerialisedPermission } from './serialisations/ISerialisedPermission';

export class Permission {
  constructor(
    readonly id: string,
    readonly name: string,
    readonly deleted: boolean
  ) {
    if (name) this.name = name.trim();
  }

  static fromSerialised(serialised: ISerialisedPermission) {
    return new Permission(serialised.id, serialised.name, serialised.deleted);
  }

  toSerialised(): ISerialisedPermission {
    return {
      id: this.id,
      name: this.name,
      deleted: this.deleted,
    };
  }

  clone(partialPermission: PartialPermission): Permission {
    const resolve = (key: keyof Permission) =>
      partialPermission.hasOwnProperty(key)
        ? partialPermission[key]
        : this[key];

    return new Permission(resolve('id'), resolve('name'), resolve('deleted'));
  }
}
type PartialPermission = Partial<Pick<Permission, 'id' | 'name' | 'deleted'>>;
