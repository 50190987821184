import {
  TenantConfigurationGroupLabel,
  TenantConfigurationLabel,
} from '@fullyops/legacy/data/api/types/Tenant';
import { ResponseWrapper } from '@fullyops/legacy/data/api/types/HttpResponses';
import { UiServiceV2 } from './ui.service-v2.service';
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_SERVICE_CONFIG } from '@fullyops/legacy/data/api/ApiServiceConfig';
import { IApiServiceConfig } from '@fullyops/legacy/data/api/IApiServiceConfig';
import { TenantConfigurationResponse } from '@fullyops/legacy/data/api/types/Tenant';

type GetConfigurationsParams = {
  groupLabel?: TenantConfigurationGroupLabel;
  includeDeprecated?: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class TenantConfigurationController extends UiServiceV2 {
  constructor(
    private http: HttpClient,
    @Inject(API_SERVICE_CONFIG) private config: IApiServiceConfig
  ) {
    super();
  }

  private readonly endpointUrl = this.config.url + 'tenants/configurations';

  getConfigurations(params: GetConfigurationsParams) {
    let query = '';
    if (Object.keys(params).length > 0) {
      query = `?${this.createQueryParams(params)}`;
    }

    return this.http.get<ResponseWrapper<TenantConfigurationResponse>>(
      `${this.endpointUrl}${query}`
    );
  }

  updateTenantConfigurationInBatch(formData: FormData) {
    return this.http.patch<ResponseWrapper<TenantConfigurationResponse>>(
      `${this.endpointUrl}/batch`,
      formData
    );
  }

  getConfigurationsByLabel(params: { label: TenantConfigurationLabel }) {
    return this.http.get<TenantConfigurationResponse>(
      `${this.endpointUrl}/${params.label}`
    );
  }
}
