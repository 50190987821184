import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormArray,
  Validators,
} from '@angular/forms';
import { UnsavedSubProduct } from '@fullyops/legacy/data';

class SubProductForm {
  name = new UntypedFormControl();
  variants = new UntypedFormArray([]);

  constructor(subproduct: UnsavedSubProduct, dis: boolean) {
    const fb = new UntypedFormBuilder();

    this.name = fb.control(
      { value: subproduct.name, disabled: dis },
      Validators.required
    );
  }
}

@Injectable()
export class SubProductFormService {
  fb = new UntypedFormBuilder();

  private subProductForm: BehaviorSubject<UntypedFormGroup> =
    new BehaviorSubject<UntypedFormGroup>(new UntypedFormGroup({}));
  subProductForm$: Observable<UntypedFormGroup> =
    this.subProductForm.asObservable();

  productId: string;

  constructor() {}

  initForm(draftProduct: UnsavedSubProduct, disabled: boolean) {
    this.subProductForm.next(
      this.fb.group(new SubProductForm(draftProduct, disabled))
    );

    this.productId = draftProduct.productId;
  }

  getSubProductDraft() {
    return new UnsavedSubProduct(
      (this.subProductForm.getValue().get('name') as UntypedFormControl).value,
      [],
      this.productId
    );
  }

  markAllAsTouched() {
    const currentProduct = this.subProductForm.getValue();
    currentProduct.markAllAsTouched();

    this.subProductForm.next(currentProduct);
  }
}
