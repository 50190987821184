import { ReportSubSection } from './ReportSubSection';
import { ISerialisedReportSection } from './serialisations/ISerialisedReportSection';

export class ReportSection {
  constructor(readonly section: string, readonly content: ReportSubSection[]) {
    if (section) this.section = section.trim();
  }

  static fromSerialised(serialised: ISerialisedReportSection) {
    const content = serialised.content.map(ReportSubSection.fromSerialised);

    return new ReportSection(serialised.section, content);
  }

  toSerialised(): ISerialisedReportSection {
    const contentObj = this.content.map((c) => c.toSerialised());

    return {
      section: this.section,
      content: contentObj,
    };
  }

  clone(partialReportSection: PartialReportSection): ReportSection {
    const resolve = (key: keyof ReportSection) =>
      partialReportSection.hasOwnProperty(key)
        ? partialReportSection[key]
        : this[key];

    return new ReportSection(resolve('section'), resolve('content'));
  }
}
type PartialReportSection = Partial<Pick<ReportSection, 'section' | 'content'>>;
