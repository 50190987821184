<mat-dialog-content class="dialog-content">
  <div class="header">
    <h2>{{ data.title | i18nextCap }}</h2>

    <div class="buttons-options">
      <button mat-icon-button (click)="onDownload()">
        <div class="icon blue-icon">
          <fa-icon [icon]="faDownload" size="2x"></fa-icon>
        </div>
      </button>

      <button mat-icon-button (click)="onLeave()">
        <div class="icon blue-icon">
          <fa-icon [icon]="faCircleXmark" size="2x"></fa-icon>
        </div>
      </button>
    </div>
  </div>
  <div class="imageContainer" *ngIf="data.imageUrl">
    <img
      [src]="data.imageUrl"
      *ngIf="getFileTypeName(data.file) == 'image'; else notImageBlock"
    />

    <ng-template #notImageBlock>
      <div class="filePreview">
        <fa-icon
          [icon]="['fas', getFileTypeName(data.file)]"
          size="6x"
          class="filePreviewColor"
        ></fa-icon>

        <span>{{ formatFileName(data.file.file.fileName) }}</span>

        <button mat-flat-button (click)="onDownload()">
          <div class="icon blue-icon">
            <fa-icon [icon]="faDownload" size="2x"></fa-icon>
          </div>
          <span>
            {{ 'download' | i18nextCap }}
          </span>
        </button>
      </div>
    </ng-template>
  </div>
</mat-dialog-content>
