import { Response } from '../Response';
import { Endpoint } from '../Endpoint';
import { HttpClient } from '@angular/common/http';
import { IListResponse } from '../IListResponse';
import { Injectable, Inject } from '@angular/core';
import { API_SERVICE_CONFIG } from '../ApiServiceConfig';

import { Observable, BehaviorSubject } from 'rxjs';

import { LossMotive } from '../../models/v0/LossMotive';
import { ISerialisedLossMotive } from '../../models/v0/serialisations/ISerialisedLossMotive';
import { IApiServiceConfig } from '../IApiServiceConfig';
import { UnsavedLossMotive } from '../../models/v0/UnsavedLossMotive';
import { map, tap } from 'rxjs/operators';

type LossMotiveList = IListResponse<ISerialisedLossMotive>;

@Injectable()
export class LossMotivesEndpoint extends Endpoint<
  LossMotive,
  ISerialisedLossMotive
> {
  private readonly url = this.config.url + 'lossmotives/';

  constructor(
    @Inject(API_SERVICE_CONFIG) private config: IApiServiceConfig,
    private http: HttpClient
  ) {
    super(LossMotive.fromSerialised);
  }

  getAll(
    filters: {},
    source?: Observable<LossMotiveList>,
    store?: BehaviorSubject<Response<LossMotive[]>>
  ): Observable<Response<LossMotive[]>> {
    const defaultSource = this.http.get<LossMotiveList>(this.url);
    return this.list(source || defaultSource, store);
  }

  get(
    id: string,
    source?: Observable<ISerialisedLossMotive>,
    store?: BehaviorSubject<Response<LossMotive[]>>
  ): Observable<Response<LossMotive>> {
    const defaultSource = this.http.get<ISerialisedLossMotive>(
      `${this.url}${id}/`
    );
    return this.retrieve(source || defaultSource, store);
  }

  post(
    lossMotive: UnsavedLossMotive,
    source?: Observable<ISerialisedLossMotive>,
    store?: BehaviorSubject<Response<LossMotive[]>>
  ): Observable<Response<LossMotive>> {
    const defaultSource = this.http.post<ISerialisedLossMotive>(
      this.url,
      lossMotive.toSerialised()
    );
    return this.add(source || defaultSource, store);
  }

  patch(
    id: string,
    lossMotive: UnsavedLossMotive,
    source?: Observable<ISerialisedLossMotive>,
    store?: BehaviorSubject<Response<LossMotive[]>>
  ): Observable<Response<LossMotive>> {
    const { ...body } = lossMotive.toSerialised();
    const defaultSource = this.http.patch<ISerialisedLossMotive>(
      `${this.url}${id}/`,
      body
    );

    return defaultSource.pipe(
      tap((instance) =>
        this.update(
          source || defaultSource,
          LossMotive.fromSerialised(instance),
          store
        )
      ),
      map((serialiser) => new Response(LossMotive.fromSerialised(serialiser)))
    );
  }

  delete(
    id: string,
    source?: Observable<{}>,
    store?: BehaviorSubject<Response<LossMotive[]>>
  ): Observable<Response<{}>> {
    const defaultSource = this.http.delete<{}>(`${this.url}${id}/`);
    return this.remove(source || defaultSource, id, store);
  }
}
