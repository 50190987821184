import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  HostListener,
  Input,
  ChangeDetectorRef,
} from '@angular/core';
import { TicketIdentifierPipe } from '@fullyops/legacy/ui/ui-shared/utils/tickets-identifier.pipe';
import { ComponentInOutAnimation } from '@fullyops/legacy/ui/ui-shared/utils/component-base-animation';
import {
  faCircleInfo,
  faCloudArrowUp,
} from '@fortawesome/free-solid-svg-icons';
import { UntypedFormControl } from '@angular/forms';
import { ImageController } from '../ui-image-v2-controller.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { TenantConfigurationResponse } from '@fullyops/legacy/data/api/types/Tenant';

@Component({
  selector: 'crm-drop-input',
  templateUrl: './drop-input.component.html',
  styleUrls: ['./drop-input.component.scss'],
  animations: [ComponentInOutAnimation.AnimTrigger],
  providers: [TicketIdentifierPipe],
})
export class DropInputComponent implements OnInit {
  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    public imageController: ImageController,
    private sanitizer: DomSanitizer
  ) {}
  @Input() formControlElement: UntypedFormControl;
  @Input() description: string;
  @Input() hasError: boolean;
  @Input() errorMessage: string;
  @Input() height: number = 100;
  @Input() label: string;
  @Input() configuration: TenantConfigurationResponse;

  @Output() uploadTemplateEmitter = new EventEmitter();

  faCloudArrowUp = faCloudArrowUp;
  faCircleInfo = faCircleInfo;
  isDragOver = false;

  accept: string = '';
  imageURL: string | SafeUrl;

  @HostListener('dragover', ['$event'])
  onDragOver($event) {
    $event.preventDefault();
    $event.stopPropagation();
    $event.stopImmediatePropagation();
    this.isDragOver = true;
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave($event) {
    $event.preventDefault();
    $event.stopPropagation();
    $event.stopImmediatePropagation();
    this.isDragOver = false;
  }

  @HostListener('drop', ['$event'])
  onDrop() {
    this.isDragOver = false;
  }

  ngOnInit() {
    this.checkFileTypeAndLoad();
    this.loadAcceptFile();
  }

  getAcceptFile() {
    if (this.configuration.options?.fileExtensions) {
      return (this.accept =
        this.configuration.options?.fileExtensions?.toString()).replace(
        ',',
        ', '
      );
    }
  }

  loadAcceptFile() {
    if (this.configuration.options?.fileExtensions) {
      return (this.accept =
        this.configuration.options?.fileExtensions?.toString());
    }
  }

  checkFileTypeAndLoad() {
    const value = this.formControlElement
      .value as TenantConfigurationResponse['image'];

    if (!value) return;

    // If is image
    if (value.file) {
      this.imageController.downloadImage(value.id).subscribe((e) => {
        this.imageURL = this.sanitizer.bypassSecurityTrustUrl(
          URL.createObjectURL(e)
        );
        this.changeDetectorRef.detectChanges();
      });
    } else if (typeof value === 'string') {
      this.imageURL = this.sanitizer.bypassSecurityTrustUrl(value);
    }
  }

  previewImage(file: File) {
    const reader = new FileReader();
    reader.onload = () => {
      this.imageURL = reader.result as string;
      this.changeDetectorRef.detectChanges();
    };
    reader.readAsDataURL(file);
  }

  isFileFormatValid(file: File) {
    if (!this.configuration.options?.fileExtensions) return true;

    const filesExtensions = this.configuration.options?.fileExtensions.filter(
      (value) => value.includes('.')
    );
    const newFileExtension = '.' + file.name.split('.').pop();
    if (filesExtensions.includes(newFileExtension)) {
      return true;
    } else {
      this.formControlElement.setErrors({ formatFile: true });
      this.formControlElement.markAsTouched();
      return false;
    }
  }

  isFileSizeValid(file: File) {
    if (!this.configuration.options?.maxFileSize) {
      return true;
    }

    if (file.size > this.configuration.options.maxFileSize) {
      this.formControlElement.setErrors({ size: true });
      this.formControlElement.markAsTouched();
      return false;
    }
    return true;
  }

  uploadTemplate(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const file = inputElement.files[0];
    this.imageURL = null;
    if (!file) return;

    if (!this.isFileSizeValid(file)) return;

    if (!this.isFileFormatValid(file)) return;

    if (file.type.includes('image')) {
      this.previewImage(inputElement.files[0]);
    }
    this.uploadTemplateEmitter.emit(event);
  }

  formatBytes(bytes) {
    let units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'];

    let i = 0;

    for (i; bytes > 1024; i++) {
      bytes /= 1024;
    }

    return bytes.toFixed(1) + ' ' + units[i];
  }
}
