import { Injectable } from '@angular/core';
import {
  ClientEquipment,
  UnsavedClientEquipment,
  ClientEquipmentsEndpoint,
} from '@fullyops/legacy/data';
import { UiService } from './ui.service';

@Injectable({
  providedIn: 'root',
})
export class UiClientEquipmentsService extends UiService<
  ClientEquipment,
  UnsavedClientEquipment,
  ClientEquipmentsEndpoint
> {
  constructor(protected clientEquipmentsEndpoint: ClientEquipmentsEndpoint) {
    super(clientEquipmentsEndpoint.createStore(), clientEquipmentsEndpoint);
  }
}
