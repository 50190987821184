import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import {
  InterventionPostRequest,
  InterventionResponse,
} from '@fullyops/legacy/data/api/types/Intervention';
import { SupportTicketResponse } from '@fullyops/legacy/data/api/types/SupportTicket';
import { PermissionType, UiAuthService } from '@fullyops/legacy/ui';
import { SnackbarService } from '@fullyops/legacy/ui/ui-crm/ui-snackbar.service';

@Component({ template: '' })
export abstract class ActionForm implements OnInit {
  @Input() isNew: boolean;
  @Input() index: number;
  @Input() ticket: SupportTicketResponse;
  @Input() intervention: InterventionResponse;
  @Input() ownerId!: string;

  @Output() deleteIntervention = new EventEmitter();
  @Output() openRequestTab = new EventEmitter<any>();

  abstract formGroup: FormGroup;
  permissionToEditForm: PermissionType[] = [
    PermissionType.CAN_EDIT_OWN_INTERVENTIONS,
    PermissionType.CAN_EDIT_INTERVENTIONS,
  ];
  permissionToDeleteIntervention: PermissionType[] = [
    PermissionType.CAN_DELETE_OWN_INTERVENTIONS,
    PermissionType.CAN_DELETE_INTERVENTIONS,
  ];
  havePermissionToDeleteIntervention = false;

  constructor(
    public snackbar: SnackbarService,
    public authService: UiAuthService
  ) {}

  ngOnInit(): void {
    this.disableFormIfCantEdit();
    this.setHavePermissionToDeleteIntervention();
  }

  setHavePermissionToDeleteIntervention() {
    if (this.ticket != undefined) {
      this.authService
        .hasPermission(this.permissionToDeleteIntervention, this.ownerId)
        .subscribe((permission) => {
          if (!permission) {
            this.havePermissionToDeleteIntervention = false;
          } else {
            this.havePermissionToDeleteIntervention = true;
          }
        });
    }
  }

  scrollToInvalidControl() {
    const firstInvalidControlKeyName = Object.keys(
      this.formGroup.controls
    ).find((key) => {
      const formControl = this.formGroup.controls[key] as AbstractControl;
      if (formControl.invalid) return true;
      return false;
    });

    const invalidControlEl = document.querySelectorAll(
      `[name="${firstInvalidControlKeyName}"]`
    )[this.index + 1];

    if (invalidControlEl) {
      invalidControlEl.scrollIntoView({ behavior: 'smooth' });
      if (invalidControlEl instanceof HTMLInputElement) {
        invalidControlEl.focus();
      }
    }
  }

  onDeleteAction() {
    this.deleteIntervention.emit();
  }

  navigateToRequestTab = () => {
    this.openRequestTab.emit();
  };

  onSubmit() {
    this.formGroup.markAllAsTouched();

    if (!this.formGroup.valid) {
      this.scrollToInvalidControl();

      this.snackbar.openErrorFormMissingFields();
      return;
    }

    if (this.isNew) return this.onCreateAction();
    return this.onEditAction();
  }

  disableFormIfCantEdit() {
    this.authService
      .hasPermission(this.permissionToEditForm, this.ownerId)
      .subscribe((permission) => {
        if (!permission) {
          if (this.ticket != undefined) this.formGroup.disable();
        }
      });
  }

  getCreatedByName() {
    if (!this.intervention?.createdBy) return '';
    const { firstName, lastName } = this.intervention?.createdBy || {};
    if (firstName || lastName) return `${firstName} ${lastName}`;
    return '';
  }

  abstract onCreateAction(): any;
  abstract onEditAction(): any;
  abstract formatToRequest(): InterventionPostRequest;
  abstract initFormValues(): void;
  abstract initFormFields(): void;
}
