import { HttpClient } from '@angular/common/http';
import { IApiServiceConfig } from './../IApiServiceConfig';
import { API_SERVICE_CONFIG } from './../ApiServiceConfig';
import { Inject, Injectable } from '@angular/core';
import { ISerialisedTicketInstallationAssignee } from '../../models/v0/serialisations/ISerialisedTicketInstallationAssignee';
import { TicketInstallationAssignee } from '../../models/v0/TicketInstallationAssignee';
import { Endpoint } from '../Endpoint';
import { BehaviorSubject, Observable } from 'rxjs';
import { Response } from '../Response';
import { IListResponse } from '../IListResponse';

type TicketInstallationAssigneeList =
  IListResponse<ISerialisedTicketInstallationAssignee>;

@Injectable()
export class InstallationTicketAssigneeEndpoint extends Endpoint<
  TicketInstallationAssignee,
  ISerialisedTicketInstallationAssignee
> {
  private readonly url = this.config.url + 'tickets/installations/assignees';

  constructor(
    @Inject(API_SERVICE_CONFIG) private config: IApiServiceConfig,
    private http: HttpClient
  ) {
    super(TicketInstallationAssignee.fromSerialised);
  }

  assignTicketToUser(
    assigneeId: string,
    installationTicketId: string,
    source?: Observable<TicketInstallationAssignee>,
    store?: BehaviorSubject<Response<TicketInstallationAssignee[]>>
  ) {
    const body = { assigneeId, installationTicketId };
    const defaultSource = this.http.post<TicketInstallationAssignee>(
      `${this.url}`,
      body
    );
    return this.add(source || defaultSource, store);
  }

  unassignUserTicket(
    userId: string,
    ticketId: string,
    source?: Observable<TicketInstallationAssignee>,
    store?: BehaviorSubject<Response<TicketInstallationAssignee[]>>
  ) {
    const defaultSource = this.http.delete<null>(
      `${this.url}/${ticketId}/${userId}/`
    );
    return this.remove(source || defaultSource, ticketId, store);
  }

  getAll(
    filters: {},
    source?: Observable<TicketInstallationAssigneeList>,
    store?: BehaviorSubject<Response<TicketInstallationAssignee[]>>
  ): Observable<Response<TicketInstallationAssignee[]>> {
    let query = '';
    Object.keys(filters).forEach((key) => (query += `&${key}=${filters[key]}`));

    const defaultSource = this.http.get<TicketInstallationAssigneeList>(
      `${this.url}?size=100&page=0${query}`
    );
    return this.list(source || defaultSource, store);
  }
}
