import { Observable } from 'rxjs';
import { WorkOrderCategoryEntity } from './entities/work-order-category-entity';
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ResponseWrapper } from '@fullyops/data/base/entities/responses/response-wrapper';
import {
  API_SERVICE_CONFIG,
  IApiServiceConfig,
} from '@fullyops/data/base/configuration/api-service-config';

export type CreateWorkOrderCategoryDto = Omit<
  WorkOrderCategoryEntity,
  'id' | 'deleted'
>;
export type UpdateWorkOrderCategoryDto = WorkOrderCategoryEntity;

export class WorkOrderCategoryNotFoundError extends Error {}

@Injectable()
export class ApiWorkOrderCategoriesAdapterService {
  private readonly url = this.config.url + 'work-orders/categories';

  constructor(
    private http: HttpClient,
    @Inject(API_SERVICE_CONFIG) private config: IApiServiceConfig
  ) {}

  get(): Observable<WorkOrderCategoryEntity[]> {
    return this.http
      .get<ResponseWrapper<WorkOrderCategoryEntity>>(`${this.url}`)
      .pipe(map((responseWrapper) => responseWrapper.results));
  }

  getById(id: string): Observable<WorkOrderCategoryEntity> {
    return this.http.get<WorkOrderCategoryEntity>(`${this.url}/${id}`);
  }

  create(
    category: CreateWorkOrderCategoryDto
  ): Observable<WorkOrderCategoryEntity> {
    return this.http.post<WorkOrderCategoryEntity>(`${this.url}`, category);
  }

  update(
    category: UpdateWorkOrderCategoryDto
  ): Observable<WorkOrderCategoryEntity> {
    return this.http.patch<WorkOrderCategoryEntity>(
      `${this.url}/${category.id}`,
      category
    );
  }

  delete(id: string): Observable<void> {
    return this.http.delete<void>(`${this.url}/${id}`);
  }
}
