import { Injectable } from '@angular/core';
import { Part, PartsEndpoint, UnsavedPart } from '@fullyops/legacy/data';
import { UiService } from './ui.service';

@Injectable({
  providedIn: 'root',
})
export class UiPartsService extends UiService<
  Part,
  UnsavedPart,
  PartsEndpoint
> {
  constructor(protected partsEndpoint: PartsEndpoint) {
    super(partsEndpoint.createStore(), partsEndpoint);
  }

  getBySupplier(supplierId: string) {
    this.service
      .getAll({ supplier: supplierId }, undefined, this.store$)
      .subscribe({ error: console.error });
  }
}
