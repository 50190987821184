import { SaleTicketRequest } from '@fullyops/legacy/data/api/types/Ticket';
import { UiServiceV2 } from './ui.service-v2.service';
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_SERVICE_CONFIG } from '@fullyops/legacy/data/api/ApiServiceConfig';
import { IApiServiceConfig } from '@fullyops/legacy/data/api/IApiServiceConfig';
import { SaleTicketResponse } from '@fullyops/legacy/data/api/types/Ticket';
import { PageResponseWrapper } from '@fullyops/legacy/data/api/types/HttpResponses';
import { map } from 'rxjs/operators';

type QueryParams = {
  companyIds?: string[];
  createdBy?: string;
  endsAt?: string;
  finalizedAfter?: string;
  identifier?: string;
  includeDeleted?: boolean;
  name?: string;
  order?: 'ASC' | 'DESC';
  orderBy?: string;
  page?: number;
  priority?: 'LOW' | 'MEDIUM' | 'HIGH' | 'URGENT';
  saleDate?: string;
  size?: number;
  startsAt?: string;
  statusIds?: string[];
  updatedBy?: string;
  userIds?: string[];
};

@Injectable({
  providedIn: 'root',
})
export class SaleTicketControllerV2 extends UiServiceV2 {
  constructor(
    private http: HttpClient,
    @Inject(API_SERVICE_CONFIG) private config: IApiServiceConfig
  ) {
    super();
  }

  private readonly endpointUrl = this.config.url + 'tickets/sales';

  getAllSaleTickets(params: { queryParams: QueryParams }) {
    let query = '';
    if (params.queryParams) {
      query = `?${this.createQueryParams(params.queryParams)}`;
    }
    return this.http
      .get<PageResponseWrapper<SaleTicketResponse>>(
        `${this.endpointUrl}${query}`
      )
      .pipe(
        map((response) => ({
          ...response,
          results: response.results.map((t) => ({
            ...t,
            identifier: `V-${t.identifier}`,
          })),
        }))
      );
  }

  updateSaleTicketById(params: { data: SaleTicketRequest }) {
    const { data } = params;
    return this.http.patch<SaleTicketResponse>(
      `${this.endpointUrl}/${data.id}`,
      data
    );
  }
}
