import { SIDE_BAR_OPTIONS } from '../app/core/components/sidebar/sidebar.component';
import {
  SETTINGS_OPTION,
  SETTINGS_SUB_OPTION,
} from '../app/core/components/navbar/navbar.component';
import { Environment } from './IEnvironment';

export const environment: Environment = {
  defaultTenant: null,
  apiUrl: 'https://be.fullyops.io/api/',
  production: true,
  menuConfigurationHide: {
    [SETTINGS_OPTION.SALES_MANAGEMENT]: {
      hide: false,
      // hideSubOption: {
      //   [SETTINGS_SUB_OPTION['salesManagement'].GENERAL_CONFIGS]: false,
      // },
    },
    [SETTINGS_OPTION.SUPPORT_MANAGEMENT]: {
      hide: false,
      hideSubOption: {
        // [SETTINGS_SUB_OPTION.supportManagement.GENERAL_CONFIGS]: true,
      },
    },
    [SETTINGS_OPTION.REQUEST_MANAGEMENT]: {
      hide: false,
      hideSubOption: {
        // [SETTINGS_SUB_OPTION.requestManagement.GENERAL_CONFIGS]: true,
      },
    },
    [SETTINGS_OPTION.COMPANY_MANAGEMENT]: {
      hide: false,
    },
    [SETTINGS_OPTION.COMPANY_MANAGEMENT]: {
      hide: false,
    },
  },
  sideBarConfigurationHide: {
    [SIDE_BAR_OPTIONS.INSIGHTS]: {
      disabled: true,
    },
  },
  test: {
    users: {
      bytepitchTecnico: {
        userName: 'fullyops.dev.technician.bot',
        password: 'X82X!59agzfJeYcEtXqNLSjSS%2FMczh',
        fullName: 'Technician Bot',
      },
      bytepitchAdmin: {
        userName: 'fullyops.dev.admin.bot',
        password: '&VSoqc7MC&WCMJG$PK8*kw6Eq9daTS#V',
        fullName: 'Admin bot',
      },
    },
  },
};
