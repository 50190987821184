import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  IApiServiceConfig,
  API_SERVICE_CONFIG,
} from '@fullyops/data/base/configuration/api-service-config';
import { Observable, map } from 'rxjs';
import { WorkOrderScope } from './work-order-scope';

@Injectable()
export class ApiWorkOrderScopesAdapterService {
  constructor(
    private http: HttpClient,
    @Inject(API_SERVICE_CONFIG) private config: IApiServiceConfig
  ) {}

  private readonly url =
    this.config.url + 'tickets/multimachinesupports/scopes';

  get(): Observable<WorkOrderScope[]> {
    return this.http.get<WorkOrderScope[]>(this.url);
  }

  update(scope: WorkOrderScope): Observable<WorkOrderScope> {
    return this.http.patch<WorkOrderScope>(`${this.url}/${scope.label}`, scope);
  }
}
