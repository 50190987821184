import { Observable, BehaviorSubject, of, combineLatest } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';
import { IListResponse, IPaginatedListRespone } from './IListResponse';
import { Response } from './Response';
import { PaginationQuery } from './PaginationQuery';

/**
 * @typeparam T model type
 * @typeparam S serialised type
 */
export abstract class Endpoint<T extends { id: string | number }, S> {
  combineStores<T2, T3>(
    store1: BehaviorSubject<Response<T[]>>,
    store2: BehaviorSubject<Response<T2[]>>,
    store3: BehaviorSubject<Response<T3[]>>
  ): Observable<Response<[T[], T2[], T3[]]>>;
  combineStores<T2>(
    store1: BehaviorSubject<Response<T[]>>,
    store2: BehaviorSubject<Response<T2[]>>
  ): Observable<Response<[T[], T2[]]>>;

  combineStores<T2, T3>(
    store1: BehaviorSubject<Response<T[]>>,
    store2: BehaviorSubject<Response<T2[]>>,
    store3?: BehaviorSubject<Response<T3[]>>
  ) {
    if (store3 !== undefined) {
      return combineLatest([store1, store2, store3]).pipe(
        map(([r1, r2, r3]) => {
          type Data = [T[], T2[], T3[]] | null;
          const data: Data =
            r1.data === null || r2.data === null || r3.data === null
              ? null
              : [r1.data, r2.data, r3.data];
          const error = r1.error || r2.error || r3.error;
          return new Response(data, error);
        }) // TODO: distinct until changed
      );
    } else {
      return combineLatest([store1, store2]).pipe(
        map(([response1, response2]) => {
          type Data = [T[], T2[]] | null;
          const data: Data =
            response1.data === null || response2.data === null
              ? null
              : [response1.data, response2.data];
          const error = response1.error || response2.error;
          return new Response(data, error);
        }) // TODO: distinct until changed
      );
    }
  }

  createStore() {
    return new BehaviorSubject<Response<T[]>>(new Response<T[]>());
  }

  constructor(private deserialiser: (s: S) => T) {}

  protected list(
    source: Observable<IListResponse<S>>,
    store?: BehaviorSubject<Response<T[]>>
  ): Observable<Response<T[]>> {
    return source.pipe(
      map((serialised) =>
        serialised.results.map((response) => this.deserialiser(response))
      ),
      map((newTs) => new Response<T[]>(newTs, null)),
      catchError((error) => {
        console.error(error);
        return of(new Response<T[]>(null, error));
      }),
      tap((response) => store !== undefined && store.next(response))
    );
  }

  protected paginatedList(
    query: PaginationQuery,
    source: Observable<IPaginatedListRespone<S>>,
    store?: BehaviorSubject<Response<T[]>>,
    previous?: () => Observable<Response<T[]>>
  ): Observable<Response<T[]>> {
    return source.pipe(
      map(
        (r) =>
          new Response(
            r.results.map((s) => this.deserialiser(s)),
            null,
            r.count,
            r.next,
            r.previous
          )
      ),
      catchError((error) => {
        console.error(error);
        if (error.status === 404 && query.page > 1 && previous !== undefined) {
          return previous();
        } else {
          return of(new Response<T[]>(null, error));
        }
      }),
      tap((response) => store !== undefined && store.next(response))
    );
  }

  protected retrieve(
    source: Observable<S>,
    store?: BehaviorSubject<Response<T[]>>
  ): Observable<Response<T>> {
    return source.pipe(
      map((response) => this.deserialiser(response)),
      tap((instance) => {
        if (store !== undefined && store.value.data !== null) {
          const newTs: T[] = store.value.data.map((t) =>
            t.id === instance.id ? instance : t
          );
          store.next(new Response<T[]>(newTs));
        }
      }),
      map((response) => new Response(response)),
      catchError((e) => {
        console.error(e);
        return of(new Response<T>(null, e));
      })
    );
  }

  protected add(
    source: Observable<S>,
    store?: BehaviorSubject<Response<T[]>>
  ): Observable<Response<T>> {
    return source.pipe(
      map((response) => this.deserialiser(response)),
      tap((t) => {
        if (store !== undefined) {
          const oldTs = store.value.data || [];
          const newTs = oldTs.concat([t]);
          store.next(new Response<T[]>(newTs));
        }
      }),
      map((t) => new Response<T>(t)),
      catchError((e) => {
        console.error(e);
        return of(new Response<T>(null, e));
      })
    );
  }

  protected remove(
    source: Observable<{}>,
    id: string,
    store?: BehaviorSubject<Response<T[]>>
  ): Observable<Response<{}>> {
    return source.pipe(
      tap((_) => {
        if (store !== undefined && store.value.data !== null) {
          const newTs: T[] = store.value.data.filter((t) => t.id !== id);
          store.next(new Response<T[]>(newTs));
        }
      }),
      map((response) => new Response(response)),
      catchError((e) => {
        console.error(e);
        return of(new Response<{}>(null, e));
      })
    );
  }

  protected update(
    source: Observable<S>,
    instance: T,
    store?: BehaviorSubject<Response<T[]>>
  ): Observable<Response<{}>> {
    return source.pipe(
      map((response) => this.deserialiser(response)),
      tap((_) => {
        if (store !== undefined && store.value.data !== null) {
          const newTs: T[] = store.value.data.map((t) =>
            t.id === instance.id ? instance : t
          );
          store.next(new Response<T[]>(newTs));
        }
      }),
      map((response) => new Response(response)),
      catchError((e) => {
        console.error(e);
        return of(new Response<{}>(null, e));
      })
    );
  }

  reset(store: BehaviorSubject<Response<T[]>>) {
    store.next(new Response<T[]>());
  }

  // fail(store: BehaviorSubject<HttpErrorResponse>) {
  //   store.next(new HttpErrorResponse());
  // }
}
