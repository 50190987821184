import { InjectionToken } from '@angular/core';
import { IApiServiceConfig } from './IApiServiceConfig';

export const createApiServiceConfig = (url: string): IApiServiceConfig => ({
  url,
});

export const API_SERVICE_CONFIG = new InjectionToken<IApiServiceConfig>(
  'ApiService config'
);

export const defaultApiServiceConfig: IApiServiceConfig =
  createApiServiceConfig('//api.fullyops.com/');
