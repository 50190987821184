import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { UnsavedContact } from '@fullyops/legacy/data';

class ContactForm {
  name = new UntypedFormControl();
  email = new UntypedFormControl();
  address = new UntypedFormControl();
  phone = new UntypedFormControl();
  notes = new UntypedFormControl();
  company = new UntypedFormControl();
  jobPosition = new UntypedFormControl();

  constructor(contact: UnsavedContact, dis: boolean) {
    const fb = new UntypedFormBuilder();
    this.name = fb.control(
      { value: contact.name, disabled: dis },
      Validators.required
    );
    this.email = fb.control({ value: contact.email, disabled: dis }, []);
    this.address = fb.control({ value: contact.address, disabled: dis }, []);
    this.phone = fb.control({ value: contact.mobileNumber, disabled: dis }, []);
    this.notes = fb.control({ value: contact.notes, disabled: dis }, []);
    this.company = fb.control(
      { value: contact.companyId, disabled: dis },
      Validators.required
    );
    this.jobPosition = fb.control(
      { value: contact.jobPosition, disabled: dis },
      []
    );
  }
}

@Injectable()
export class ContactFormService {
  fb = new UntypedFormBuilder();

  private form: BehaviorSubject<UntypedFormGroup> =
    new BehaviorSubject<UntypedFormGroup>(new UntypedFormGroup({}));
  form$: Observable<UntypedFormGroup> = this.form.asObservable();

  constructor() {}

  initForm(draftContact: UnsavedContact, disabled: boolean) {
    this.form.next(this.fb.group(new ContactForm(draftContact, disabled)));
  }

  getDraft() {
    const company = (this.form.getValue().get('company') as UntypedFormControl)
      .value;
    return new UnsavedContact(
      (this.form.getValue().get('name') as UntypedFormControl).value,
      (this.form.getValue().get('email') as UntypedFormControl).value,
      (this.form.getValue().get('address') as UntypedFormControl).value,
      (this.form.getValue().get('phone') as UntypedFormControl).value,
      (this.form.getValue().get('notes') as UntypedFormControl).value,
      company ? company.id : '',
      (this.form.getValue().get('jobPosition') as UntypedFormControl).value
    );
  }

  markAllAsTouched() {
    const currentContact = this.form.getValue();
    currentContact.markAllAsTouched();

    this.form.next(currentContact);
  }
}
