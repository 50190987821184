import { UiServiceV2 } from './ui.service-v2.service';
import { PageResponseWrapper } from '../../data/api/types/HttpResponses';
import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { API_SERVICE_CONFIG } from '../../data/api/ApiServiceConfig';
import { IApiServiceConfig } from '../../data/api/IApiServiceConfig';
import {
  ClientEquipmentRequest,
  ClientEquipmentResponse,
} from '../../data/api/types/ClientEquipment';
import { BehaviorSubject } from 'rxjs';
import { SelectOptionType } from '../ui-shared/form-select/form-select.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MachineModalComponent } from './machine-modal/machine-modal.component';
import { FormControl } from '@angular/forms';

type QueryParameters = {
  brand?: string;
  companyId?: string;
  createdBy?: string;
  equipmentData?: string;
  includeDeleted?: boolean;
  model?: string;
  order?: 'ASC' | 'DESC';
  orderBy?:
    | 'CREATED_AT'
    | 'UPDATED_AT'
    | 'UUID'
    | 'BRAND'
    | 'MODEL'
    | 'SERIAL_NUMBER'
    | 'WARRANTY'
    | 'TYPE'
    | 'HAS_WARRANTY'
    | 'EQUIPMENT_DATA';
  page?: number;
  serialNumber?: string;
  size?: number;
  type?: string;
  updatedBy?: string;
};

@Injectable({
  providedIn: 'root',
})
export class ClientEquipmentControllerV2 extends UiServiceV2 {
  constructor(
    @Inject(API_SERVICE_CONFIG) private config: IApiServiceConfig,
    private http: HttpClient,
    public dialog: MatDialog
  ) {
    super();
    this.onListUpdate_UpdateListOptions();
  }

  private readonly url = this.config.url + 'clients/equipments';
  list$: BehaviorSubject<Array<ClientEquipmentResponse>> = new BehaviorSubject(
    []
  );
  listOptions$: BehaviorSubject<Array<SelectOptionType>> = new BehaviorSubject(
    []
  );

  getAll(params: { queryParameters?: QueryParameters }) {
    let query = '';
    if (params.queryParameters) {
      const queries = this.createQueryParams(params.queryParameters);
      query = `?${queries}`;
    }

    const request = this.http.get<PageResponseWrapper<ClientEquipmentResponse>>(
      `${this.url}${query}`
    );

    request.subscribe((response) => {
      this.list$.next(response.results);
    });

    return request;
  }

  create(data: ClientEquipmentRequest) {
    return this.http.post<ClientEquipmentResponse>(this.url, data);
  }

  updateById(params: {
    clientEquipment: ClientEquipmentRequest;
    clientEquipmentId: string;
  }) {
    return this.http.patch<ClientEquipmentResponse>(
      `${this.url}/${params.clientEquipmentId}`,
      params.clientEquipment
    );
  }

  deleteById(params: { clientEquipmentId: string }) {
    return this.http.delete(`${this.url}/${params.clientEquipmentId}`);
  }

  getClientEquipmentListOptions$(queryParameters?: QueryParameters) {
    this.getAll({ queryParameters });
    return this.listOptions$;
  }

  private formatToOptions(
    clientEquipments: Array<ClientEquipmentResponse>
  ): SelectOptionType[] {
    return clientEquipments.map(({ id, name }) => ({ id, name }));
  }

  createMachineByPopUp({
    companyId,
    machineFormControl,
  }: {
    companyId: string;
    machineFormControl: FormControl;
  }) {
    const dialogRef = this.dialog.open<
      MachineModalComponent,
      any,
      ClientEquipmentResponse
    >(MachineModalComponent, {
      data: {
        clientEquipments$: this.list$,
        companyId,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const formatToOption = this.formatToOptions([result])[0];
        machineFormControl.setValue(formatToOption);
      } else {
        machineFormControl.setValue('');
      }
    });
  }

  onListUpdate_UpdateListOptions() {
    this.list$.subscribe((list) => {
      this.listOptions$.next(this.formatToOptions(list));
    });
  }
}
