import { Component, Input, OnInit } from '@angular/core';
import { ProductConfig, ProductConfigSimulation } from '@fullyops/legacy/data';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ComponentInOutAnimation } from '@fullyops/legacy/ui/ui-shared/utils/component-base-animation';

@Component({
  selector: 'crm-simulations-info',
  templateUrl: './simulations-info.component.html',
  styleUrls: ['./simulations-info.component.scss'],
  animations: [ComponentInOutAnimation.AnimTrigger],
})
export class SimulationsInfoComponent implements OnInit {
  @Input() simulations$ = new BehaviorSubject<ProductConfigSimulation[]>([]);
  @Input() productsConfigs$ = new BehaviorSubject<ProductConfig[]>([]);

  totalCost = 0;

  ngOnInit() {
    combineLatest([this.simulations$, this.productsConfigs$])
      .pipe(tap((_) => (this.totalCost = 0)))
      .subscribe(([simulations, configurations]) => {
        simulations.forEach(
          (simulation) => (this.totalCost += simulation.total)
        );
        configurations.forEach(
          (configuration) => (this.totalCost += configuration.total)
        );
      });
  }
}
