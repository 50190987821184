import { COLORS } from './../ui-shared/utils/styles';
import { I18NextPipe } from 'angular-i18next';
import { HotToastService } from '@ngneat/hot-toast';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  constructor(
    public snackBar: HotToastService,
    public i18nextPipe: I18NextPipe
  ) {}

  openErrorFormMissingFields() {
    this.snackBar.error(
      this.i18nextPipe.transform('forms:SomeFieldIsInvalid'),
      {
        position: 'bottom-right',
        duration: 5000,
        dismissible: true,
        style: {
          color: 'white',
          backgroundColor: COLORS.red,
        },
        icon: '⚠️',
      }
    );
  }

  openErrorFormNothingChanged() {
    this.snackBar.error(this.i18nextPipe.transform('forms:thereAreNoChanges'), {
      position: 'bottom-right',
      duration: 5000,
      dismissible: true,
      style: {
        color: 'white',
        backgroundColor: COLORS.red,
      },
      icon: '⚠️',
    });
  }

  openSnackBar(props: { message: string }) {
    this.snackBar.success(this.i18nextPipe.transform(props.message), {
      position: 'bottom-right',
      duration: 5000,
      dismissible: true,
      style: {
        color: 'white',
        backgroundColor: COLORS.green,
      },
      icon: '✅',
    });
  }

  openError(props: { message: string }) {
    this.snackBar.error(this.i18nextPipe.transform(props.message), {
      position: 'bottom-right',
      duration: 5000,
      dismissible: true,
      style: {
        color: 'white',
        backgroundColor: COLORS.red,
      },
      icon: '⚠️',
    });
  }
}
