import { ISerialisedUnsavedTicketRequest } from './serialisations/ISerialisedTicketRequest';
import { UnsavedTicketRequestPart } from './UnsavedTicketRequestPart';

export class UnsavedTicketRequest {
  constructor(
    readonly name: string,
    readonly priority: string,
    readonly statusId: string,
    readonly assigneeIds: string[],
    readonly companyId: string,
    readonly contactIds: string[],
    readonly extraInfo: string,
    readonly machine: string,
    readonly parts: UnsavedTicketRequestPart[],
    readonly createdAt: Date,
    readonly closedAt: Date,
    readonly supportTicketId: string
  ) {
    if (name) this.name = name.trim();
    if (priority) this.priority = priority.trim();
    if (extraInfo) this.extraInfo = extraInfo.trim();
    if (machine) this.machine = machine.trim();
  }

  static fromSerialised(serialised: ISerialisedUnsavedTicketRequest) {
    const parts = serialised.parts.map(UnsavedTicketRequestPart.fromSerialised);

    return new UnsavedTicketRequest(
      serialised.name,
      serialised.priority,
      serialised.statusId,
      serialised.assigneeIds,
      serialised.companyId,
      serialised.contactIds,
      serialised.extraInfo,
      serialised.machine,
      parts,
      new Date(serialised.createdAt),
      new Date(serialised.closedAt),
      serialised.supportTicketId
    );
  }

  toSerialised(): ISerialisedUnsavedTicketRequest {
    const partsObj = this.parts.map((part) => part.toSerialised());

    return {
      name: this.name,
      priority: this.priority,
      statusId: this.statusId,
      assigneeIds: this.assigneeIds,
      companyId: this.companyId,
      contactIds: this.contactIds,
      extraInfo: this.extraInfo,
      machine: this.machine,
      parts: partsObj,
      createdAt: this.createdAt.toISOString(),
      closedAt: this.closedAt ? this.closedAt.toISOString() : null,
      supportTicketId: this.supportTicketId,
    };
  }

  clone(
    partialUnsavedTicketRequest: PartialUnsavedTicketRequest
  ): UnsavedTicketRequest {
    const resolve = (key: keyof UnsavedTicketRequest) =>
      partialUnsavedTicketRequest.hasOwnProperty(key)
        ? partialUnsavedTicketRequest[key]
        : this[key];

    return new UnsavedTicketRequest(
      resolve('name'),
      resolve('priority'),
      resolve('statusId'),
      resolve('assigneeIds'),
      resolve('companyId'),
      resolve('contactIds'),
      resolve('extraInfo'),
      resolve('machine'),
      resolve('parts'),
      resolve('createdAt'),
      resolve('closedAt'),
      resolve('supportTicketId')
    );
  }
}

type PartialUnsavedTicketRequest = Partial<
  Pick<
    UnsavedTicketRequest,
    | 'name'
    | 'priority'
    | 'statusId'
    | 'assigneeIds'
    | 'companyId'
    | 'contactIds'
    | 'extraInfo'
    | 'supportTicketId'
    | 'machine'
    | 'parts'
    | 'createdAt'
    | 'closedAt'
  >
>;
