import { Injectable, Inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { API_SERVICE_CONFIG } from '../api/ApiServiceConfig';
import { IApiServiceConfig } from '../api/IApiServiceConfig';
import { CookieService } from 'ngx-cookie-service';
import { catchError, tap } from 'rxjs/operators';
import { AuthEndpoint } from '../api/v0/AuthEndpoint';

@Injectable()
export class AccessTokenInterceptor implements HttpInterceptor {
  private headerName = 'Authorization';

  constructor(
    @Inject(API_SERVICE_CONFIG) private apiServiceConfig: IApiServiceConfig,
    private authEndpoint: AuthEndpoint,
    private cookieService: CookieService,
    private router: Router
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.url.startsWith(this.apiServiceConfig.url)) {
      if (request.url.includes('authenticate/')) {
        return next.handle(request);
      }
      if (request.url.includes('login')) {
        return next.handle(
          request.clone({
            withCredentials: true,
          })
        );
      } else {
        const nextRequest = this.addAuthenticationToken(request);

        return next.handle(nextRequest).pipe(
          tap({
            error: (error) => {
              if (error.status === 401) {
                this.cookieService.delete('jwt', '/');
                this.cookieService.delete('tenant');
                this.authEndpoint.setAuthorize(false);
                this.router.navigate(['/login']);
              }
              return of(error);
            },
          })
        );
      }
    } else {
      return next.handle(request);
    }
  }

  addAuthenticationToken(request: HttpRequest<any>) {
    const accessToken = this.cookieService.getAll()['jwt'];
    const tenant = this.cookieService.getAll()['tenant'];
    // If access token is null this means that user is not logged in
    // If tenant token is null this means that the user must have logged in before this latest patch
    if (!accessToken || !tenant) {
      return request;
    }

    // Clone the request, because the original request is immutable
    return request.clone({
      headers: request.headers
        .set(this.headerName, `Bearer ${accessToken}`)
        .set('tenantName', tenant),
    });
  }
}
