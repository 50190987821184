import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PaginationResult } from '@fullyops/legacy/ui/ui-shared/utils/crm-types';
import { ComponentInOutAnimation } from '@fullyops/legacy/ui/ui-shared/utils/component-base-animation';
import { SaleTicketResponse } from '@fullyops/legacy/data/api/types/Ticket';
import { SupportTicketResponse } from '@fullyops/legacy/data/api/types/SupportTicket';
import { RequestTicketResponse } from '@fullyops/legacy/data/api/types/RequestTicke';

export type ListTicketsPage = PaginationResult<
  SaleTicketResponse | SupportTicketResponse | RequestTicketResponse
>;

@Component({
  selector: 'crm-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.scss'],
  animations: [ComponentInOutAnimation.AnimTrigger],
})
export class TicketsComponent implements OnInit {
  @Input() title: string;
  @Input() listTicketsPage$ = new BehaviorSubject<ListTicketsPage>(null);
  @Output() openEvent = new EventEmitter<string>();
  @Output() pageChangeEvent = new EventEmitter<number>();

  displayedColumns = ['name', 'status', 'priority', 'assignees'];

  ngOnInit() {}

  onOpen(id: string) {
    this.openEvent.emit(id);
  }

  handlePageSelection(pageIndex: number) {
    this.pageChangeEvent.emit(pageIndex);
  }
}
