<crm-breadcrumb
  [header]="title"
  [actionButtons]="actions"
  (actionEvent)="onActionEvent($event)"
></crm-breadcrumb>

<crm-container [maxSize]="'xl'">
  <div [@inOut]="'in'" class="content-wraper" *ngIf="listActivitiesPagination$">
    <div class="table-container">
      <div class="table-container-header">
        <ng-container *ngIf="companies$ | async as companies">
          <ng-container *ngIf="users$ | async as users">
            <ng-container *ngIf="contacts$ | async as contacts">
              <crm-activities-filters
                [filters$]="filters$"
                [companies]="$any(companies)"
                [users]="$any(users)"
                [contacts]="$any(contacts)"
                (filtersChangeEvent)="onFiltersChange($event)"
              ></crm-activities-filters>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>

      <crm-table
        [paginatedList$]="listActivitiesPagination$"
        [displayedColumns]="displayedColumns"
        (onPageChange)="onPageChange($event)"
        (onClick)="onOpen($event)"
      >
        <!-- Name Column-->
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef>
            {{ 'name' | i18nextCap }}
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <ng-container [ngSwitch]="element.activityType">
              <mat-icon
                *ngSwitchCase="ActivityType.CALL"
                class="rounded"
                aria-hidden="false"
                aria-label="call"
                matTooltip="{{ element.activityType | i18nextCap }}"
                [ngClass]="{ done: element.done }"
                >call</mat-icon
              >
              <mat-icon
                *ngSwitchCase="ActivityType.EMAIL"
                class="rounded"
                aria-hidden="false"
                aria-label="email"
                matTooltip="{{ element.activityType | i18nextCap }}"
                [ngClass]="{ done: element.done }"
                >email</mat-icon
              >
              <mat-icon
                *ngSwitchCase="ActivityType.REMINDER"
                class="rounded"
                aria-hidden="false"
                aria-label="REMINDER"
                matTooltip="{{ element.activityType | i18nextCap }}"
                [ngClass]="{ done: element.done }"
                >timelapse</mat-icon
              >
              <mat-icon
                *ngSwitchCase="ActivityType.MEETING"
                class="rounded"
                aria-hidden="false"
                aria-label="meeting"
                matTooltip="{{ element.activityType | i18nextCap }}"
                [ngClass]="{ done: element.done }"
                >people</mat-icon
              >
              <mat-icon
                *ngSwitchCase="ActivityType.TASK"
                class="rounded"
                aria-hidden="false"
                aria-label="task"
                matTooltip="{{ element.activityType | i18nextCap }}"
                [ngClass]="{ done: element.done }"
                >assignment</mat-icon
              >
            </ng-container>
            <div class="cell-row">
              <span class="title">{{ element.name }}</span>
              <span *ngIf="element.activityType" class="subtitle"
                >{{ element.startsAt | date: 'd MMM y' }}
              </span>
            </div>
          </mat-cell>
        </ng-container>

        <!-- Link Column-->
        <ng-container matColumnDef="link">
          <mat-header-cell *matHeaderCellDef>
            {{ 'ticket' | i18nextCap }}
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <div class="cell-row" *ngIf="element.activityLinkResponse">
              <ng-container [ngSwitch]="element.activityLinkResponse.linkType">
                <span *ngSwitchCase="ActivityLinkType.SALE" class="title">{{
                  element.activityLinkResponse.saleTicket.name
                }}</span>
                <span *ngSwitchCase="ActivityLinkType.SUPPORT" class="title">{{
                  element.activityLinkResponse.supportTicket.name
                }}</span>
                <span *ngSwitchCase="ActivityLinkType.REQUEST" class="title">{{
                  element.activityLinkResponse.requestTicket.name
                }}</span>
              </ng-container>
              <span class="subtitle">{{
                element.activityLinkResponse.linkType | i18nextCap
              }}</span>
            </div>
            <div class="cell-row" *ngIf="!element.activityLinkResponse">-</div>
          </mat-cell>
        </ng-container>

        <!-- Company Column-->
        <ng-container matColumnDef="company">
          <mat-header-cell *matHeaderCellDef>
            {{ 'company' | i18nextCap }}
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <div class="cell-row">
              <span class="title">{{ element.company.name }}</span>
              <span *ngIf="element.contacts.length" class="subtitle"
                >Contacto: {{ element.contacts[0].name }}</span
              >
            </div>
          </mat-cell>
        </ng-container>

        <!-- Assignees Column-->
        <ng-container matColumnDef="assignees">
          <mat-header-cell *matHeaderCellDef>
            {{ 'assignees' | i18nextCap }}
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <crm-assignees-icon-list
              [assignees]="element.assignees"
              [size]="25"
            ></crm-assignees-icon-list>
          </mat-cell>
        </ng-container>
      </crm-table>
    </div>
  </div>
</crm-container>
