import {
  trigger,
  state,
  style,
  animate,
  transition,
  query,
  group,
} from '@angular/animations';

export const ComponentInOutAnimation = {
  AnimTrigger: [
    trigger('inOut', [
      state('in', style({ opacity: 1 })),
      state('out', style({ opacity: 0 })),
      transition('* => in', [
        group([
          query(':self', [
            style({ opacity: 0 }),
            group([animate('700ms ease-out', style({ opacity: 1 }))]),
          ]),
        ]),
      ]),
    ]),
  ],
};
