import { map } from 'rxjs/operators';
import { UiServiceV2 } from './ui.service-v2.service';
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_SERVICE_CONFIG } from '@fullyops/legacy/data/api/ApiServiceConfig';
import { IApiServiceConfig } from '@fullyops/legacy/data/api/IApiServiceConfig';
import { ResponseWrapper } from '@fullyops/legacy/data/api/v0/InstallationTicketStatusEndpoint';
import { Report, ReportType } from '@fullyops/legacy/data/api/types/Report';

@Injectable({
  providedIn: 'root',
})
export class ReportControllerControllerV2 extends UiServiceV2 {
  constructor(
    private http: HttpClient,
    @Inject(API_SERVICE_CONFIG) private config: IApiServiceConfig
  ) {
    super();
  }

  private readonly endpointUrl = this.config.url + 'reports';

  handleSignatureUpload(props: {
    file: FormData;
    language?: string;
    reportType: ReportType;
    reportId: string;
  }) {
    const { reportType, reportId, file, language } = props;

    const data = new FormData();
    data.append('file', file.get('file'));
    data.append('reportId', reportId);
    data.append('reportType ', reportType);

    if (language) {
      data.append('language', language);
    }

    return this.http.post<Report>(
      `${this.endpointUrl}/sign/${reportType}/${reportId}`,
      data
    );
  }

  getTicketReports(params: { reportType: ReportType; ticketId: string }) {
    const { reportType, ticketId } = params;

    return this.http.get<ResponseWrapper<Report>>(
      `${this.endpointUrl}/${reportType}`,
      {
        params: {
          reportType,
          ticketId,
        },
      }
    );
  }

  generatePreview(props: {
    reportType: ReportType;
    ticketId: string;
    language?: string;
  }) {
    const { reportType, ticketId, language } = props;
    return this.http.post<Report>(
      `${this.endpointUrl}/preview/${reportType}/${ticketId}${
        language ? `?language=${language}` : ''
      }`,
      {
        language,
        reportType,
        ticketId,
      }
    );
  }

  sendEmailReport(props: { reportId: string }) {
    const { reportId } = props;
    return this.http.post<string>(`${this.endpointUrl}/email/${reportId}`, {
      reportId,
    });
  }

  sendEmailReportWithCustomEmails(props: {
    reportId: string;
    recipients: string[];
    bccRecipients: string[];
  }) {
    const { reportId, recipients, bccRecipients } = props;
    return this.http.post<string>(`${this.endpointUrl}/email/${reportId}`, {
      recipients,
      bccRecipients,
    });
  }

  download(props: { reportId: string; filename: string; internal?: boolean }) {
    const { reportId, filename, internal } = props;
    return this.http
      .get(
        `${this.endpointUrl}/download/${reportId}${
          internal ? '?internal=true' : ''
        }`,
        {
          responseType: 'blob' as 'json',
        }
      )
      .pipe(
        map((response: any) => {
          let dataType = response.type;
          let binaryData = [];
          binaryData.push(response);
          let downloadLink = document.createElement('a');
          downloadLink.target = '_blank';
          downloadLink.href = window.URL.createObjectURL(
            new Blob(binaryData, { type: dataType })
          );

          if (filename != null) {
            downloadLink.setAttribute(
              'download',
              internal ? `Internal-${filename}` : filename
            );
          }

          return downloadLink;
        })
      );
  }

  downloadReportAsURL(props: { reportId: string; internal?: boolean }) {
    const { reportId, internal } = props;
    return this.http
      .get(
        `${this.endpointUrl}/download/${reportId}${
          internal ? '?internal=true' : ''
        }`,
        {
          responseType: 'blob',
        }
      )
      .pipe(
        map((response: any) => {
          let dataType = response.type;
          let binaryData = [];
          binaryData.push(response);

          return window.URL.createObjectURL(
            new Blob(binaryData, { type: dataType })
          );
        })
      );
  }
}
