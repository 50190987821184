import { Response } from '../Response';
import { Endpoint } from '../Endpoint';
import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { API_SERVICE_CONFIG } from '../ApiServiceConfig';
import { Observable, BehaviorSubject } from 'rxjs';
import { IApiServiceConfig } from '../IApiServiceConfig';
import { TicketSupportMultimachineAssignee } from '../../models/v0/TicketSupportMultimachineAssignee';
import { ISerialisedTicketSupportMultimachineAssignee } from '../../models/v0/serialisations/ISerialisedTicketSupportMultimachineAssignee';
import { IListResponse } from '../IListResponse';

type TicketSupportMultimachineAssigneeList =
  IListResponse<ISerialisedTicketSupportMultimachineAssignee>;

@Injectable()
export class TicketsSupportMultimachineAssigneeEndpoint extends Endpoint<
  TicketSupportMultimachineAssignee,
  ISerialisedTicketSupportMultimachineAssignee
> {
  private readonly url =
    this.config.url + 'tickets/multimachinesupports/assignees';

  constructor(
    @Inject(API_SERVICE_CONFIG) private config: IApiServiceConfig,
    private http: HttpClient
  ) {
    super(TicketSupportMultimachineAssignee.fromSerialised);
  }

  assignTicketToUser(
    assigneeId: string,
    supportTicketId: string,
    source?: Observable<TicketSupportMultimachineAssignee>,
    store?: BehaviorSubject<Response<TicketSupportMultimachineAssignee[]>>
  ) {
    const body = { assigneeId, supportTicketId };
    const defaultSource = this.http.post<TicketSupportMultimachineAssignee>(
      `${this.url}`,
      body
    );
    return this.add(source || defaultSource, store);
  }

  unassignUserTicket(
    userId: string,
    ticketId: string,
    source?: Observable<TicketSupportMultimachineAssignee>,
    store?: BehaviorSubject<Response<TicketSupportMultimachineAssignee[]>>
  ) {
    const defaultSource = this.http.delete<null>(
      `${this.url}/${ticketId}/${userId}/`
    );
    return this.remove(source || defaultSource, ticketId, store);
  }

  getAll(
    filters: {},
    source?: Observable<TicketSupportMultimachineAssigneeList>,
    store?: BehaviorSubject<Response<TicketSupportMultimachineAssignee[]>>
  ): Observable<Response<TicketSupportMultimachineAssignee[]>> {
    let query = '';
    Object.keys(filters).forEach((key) => (query += `&${key}=${filters[key]}`));

    const defaultSource = this.http.get<TicketSupportMultimachineAssigneeList>(
      `${this.url}?size=100&page=0${query}`
    );
    return this.list(source || defaultSource, store);
  }
}
