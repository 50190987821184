<div class="component" *ngIf="field.inputImages.type != 'avatar'">
  <div class="horizontal-list">
    <div
      class="dropzone"
      [ngStyle]="{ 'height.px': field?.inputImages?.height || 100 }"
    >
      <input
        type="file"
        (change)="addImage($event)"
        [accept]="field?.inputImages?.accept"
        id="addImage"
      />

      <div class="dropzone-visual-content">
        <div class="icon" [ngClass]="isDragOver ? 'hide-icon' : 'blue-icon'">
          <fa-icon [icon]="faCloudArrowUp" size="xl"></fa-icon>
        </div>
        <p class="normal-text" *ngIf="!isDragOver">
          {{ 'dragAndDropFile' | i18nextCap }}
        </p>
        <p class="normal-text" *ngIf="isDragOver">
          {{ 'releaseToUploadFile' | i18nextCap }}
        </p>
      </div>
    </div>

    <div
      *ngFor="let image of formGroup.controls[field.name].value; let i = index"
      class="dropzone"
      [ngStyle]="{ 'height.px': field?.inputImages?.height || 100 }"
      [ngClass]="{ 'have-image': image }"
    >
      <div
        class="imagePreview"
        *ngIf="image && image !== ''"
        (click)="onImageClick(image)"
      >
        <div *ngIf="getFileTypeName(image) == 'image'; else notImageBlock">
          <img [src]="getImageUrl(image)" />
        </div>

        <ng-template #notImageBlock>
          <div class="filePreview">
            <fa-icon
              [icon]="['fas', getFileTypeName(image)]"
              size="2xl"
              class="filePreviewColor"
            ></fa-icon>

            <span>{{ formatFileName(image.file.fileName) }}</span>
          </div>
        </ng-template>
      </div>

      <div class="dropzone-visual-content" *ngIf="!image">
        <div class="icon red-icon">
          <fa-icon [icon]="faTriangleExclamation" size="xl"></fa-icon>
        </div>
        <p class="normal-text">
          {{ 'Erro ao carregar a imagem' | i18nextCap }}
        </p>
      </div>

      <div class="delete-image-button-div">
        <button type="button" (click)="removeImage(i)">
          <div class="icon white-icon">
            <fa-icon [icon]="faTrashCan" size="lg"></fa-icon>
          </div>
        </button>
      </div>
    </div>
  </div>

  <!-- <div>
    <div
      [ngClass]="{
        'drop-input-margin-bottom':
          (formGroup.controls[field.name].errors &&
            !formGroup.controls[field.name].touched) ||
          !formGroup.controls[field.name].errors
      }"
    >
      <h4>{{ label | i18nextCap }}</h4>

      <div class="field-and-info-icon">
        <div style="width: 100%">
          <div
            [ngStyle]="{ 'height.px': field?.inputImages?.height || 100 }"
            class="dropzone"
            [ngClass]="{
              'dropzone-error':
                formGroup.controls[field.name].errors &&
                formGroup.controls[field.name].touched
            }"
          >
            <input type="file" (change)="addImage($event)" [accept]="accept" />

            <div class="dropzone-visual-content">
              <div
                class="icon"
                [ngClass]="isDragOver ? 'hide-icon' : 'blue-icon'"
              >
                <fa-icon [icon]="faCloudArrowUp" size="xl"></fa-icon>
              </div>
              <p class="normal-text" *ngIf="!isDragOver">
                {{ 'dragAndDropFile' | i18nextCap }}
              </p>
              <p class="normal-text" *ngIf="isDragOver">
                {{ 'releaseToUploadFile' | i18nextCap }}
              </p>
            </div>
          </div>
          <mat-error
            *ngIf="
              formGroup.controls[field.name].errors &&
              formGroup.controls[field.name].touched
            "
            class="mat-error-custom"
          >
            {{ errorMessage }}
          </mat-error>
        </div>
      </div>
    </div>
  </div> -->

  <!-- <div
    *ngFor="let imageURL of formGroup.controls[field.name].value; let i = index"
    class="dropzone"
    [ngStyle]="{ 'height.px': field?.inputImages?.height || 100 }"
    [ngClass]="{ 'have-image': imageURL }"
  >
    <div class="imagePreview" *ngIf="imageURL && imageURL !== ''">
      <img [src]="imageURL" />
    </div>

    <div class="dropzone-visual-content" *ngIf="!imageURL">
      <div class="icon red-icon">
        <fa-icon [icon]="faTriangleExclamation" size="xl"></fa-icon>
      </div>
      <p class="normal-text">
        {{ 'Erro ao carregar a imagem' | i18nextCap }}
      </p>
    </div>

    <div class="delete-image-button-div">
      <button type="button">
        <div class="icon white-icon">
          <fa-icon [icon]="faTrashCan" size="lg"></fa-icon>
        </div>
      </button>
    </div>
  </div> -->
</div>

<div class="avatar" *ngIf="field.inputImages.type == 'avatar'">
  <div class="edit-icon">
    <fa-icon [icon]="faPen" size="xs"></fa-icon>
  </div>
  <input type="file" (change)="addImage($event)" accept="image/" />

  <div class="imagePreview">
    <img [src]="getImageUrl(formGroup.controls[field.name].value)" />
  </div>
</div>
