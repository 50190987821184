import { ISerialisedUnsavedSubProduct } from './serialisations/ISerialisedSubProduct';
import { UnsavedSubProductVariant } from './UnsavedSubProductVariant';

export class UnsavedSubProduct {
  constructor(
    readonly name: string,
    readonly variants: UnsavedSubProductVariant[],
    readonly productId: string
  ) {
    if (name) this.name = name.trim();
  }

  static fromSerialised(serialised: ISerialisedUnsavedSubProduct) {
    const variants = serialised.variants.map(
      UnsavedSubProductVariant.fromSerialised
    );

    return new UnsavedSubProduct(
      serialised.name,
      variants,
      serialised.productId
    );
  }

  toSerialised(): ISerialisedUnsavedSubProduct {
    const variantsObj = this.variants.map((variant) => variant.toSerialised());

    return {
      name: this.name,
      variants: variantsObj,
      productId: this.productId,
    };
  }

  clone(partialUnsavedProduct: PartialUnsavedSubProduct): UnsavedSubProduct {
    const resolve = (key: keyof UnsavedSubProduct) =>
      partialUnsavedProduct.hasOwnProperty(key)
        ? partialUnsavedProduct[key]
        : this[key];

    return new UnsavedSubProduct(
      resolve('name'),
      resolve('variants'),
      resolve('productId')
    );
  }
}

type PartialUnsavedSubProduct = Partial<
  Pick<UnsavedSubProduct, 'name' | 'variants' | 'productId'>
>;
