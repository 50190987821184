import { ClientEquipment } from './ClientEquipment';
import { ISerialisedUnsavedInterventionAnomaly } from './serialisations/ISerialisedInterventionAnomaly';
import { UnsavedAnomaly } from './UnsavedAnomaly';
import { User } from './User';

export class UnsavedInterventionAnomaly {
  constructor(
    readonly id: string,
    readonly type: null, // TODO: Switch to AnomalyType when we start using them
    readonly createdAt: string,
    readonly createdBy: User,
    readonly interventionId: string,
    readonly updatedAt: string,
    readonly updatedBy: User,
    readonly description: string,
    readonly deleted: boolean
  ) {}

  static fromSerialised(serialised: ISerialisedUnsavedInterventionAnomaly) {
    const createdBy = User.fromSerialised(serialised.createdBy);
    const updatedBy = User.fromSerialised(serialised.updatedBy);

    return new UnsavedInterventionAnomaly(
      serialised.id,
      null, // TODO: Switch to AnomalyType when we start using them
      serialised.createdAt,
      createdBy,
      serialised.interventionId,
      serialised.updatedAt,
      updatedBy,
      serialised.description,
      serialised.deleted
    );
  }

  toSerialised(): ISerialisedUnsavedInterventionAnomaly {
    const createdByObj = this.createdBy.toSerialised();
    const updatedByObj = this.updatedBy.toSerialised();

    return {
      id: this.id,
      type: null, // TODO: Switch to AnomalyType when we start using them
      createdAt: this.createdAt,
      createdBy: createdByObj,
      interventionId: this.interventionId,
      updatedAt: this.updatedAt,
      updatedBy: updatedByObj,
      description: this.description,
      deleted: this.deleted,
    };
  }
  clone(
    partialUnsavedInterventionAnomaly: PartialUnsavedInterventionAnomaly
  ): UnsavedInterventionAnomaly {
    const resolve = (key: keyof UnsavedInterventionAnomaly) =>
      partialUnsavedInterventionAnomaly.hasOwnProperty(key)
        ? partialUnsavedInterventionAnomaly[key]
        : this[key];

    return new UnsavedInterventionAnomaly(
      resolve('id'),
      resolve('type'), // TODO: Switch to AnomalyType when we start using them
      resolve('createdAt'),
      resolve('createdBy'),
      resolve('interventionId'),
      resolve('updatedAt'),
      resolve('updatedBy'),
      resolve('description'),
      resolve('deleted')
    );
  }
}
type PartialUnsavedInterventionAnomaly = Partial<
  // tslint:disable-next-line: max-line-length
  Pick<
    UnsavedInterventionAnomaly,
    | 'id'
    | 'type'
    | 'createdAt'
    | 'createdBy'
    | 'interventionId'
    | 'updatedAt'
    | 'updatedBy'
    | 'description'
    | 'deleted'
  >
>;
