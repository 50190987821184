<mat-progress-bar
  *ngIf="loading$ | async"
  mode="indeterminate"
></mat-progress-bar>
<div class="align-center">
  <form class="login" [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
    <img
      class="client-logo"
      src="assets/svg/fullyops-logo-white.svg"
      alt="FullyOps Logo"
    />
    <br />
    <header>
      <h2>Reset Password</h2>
      <p>Enter your new password and confirm.</p>
    </header>

    <mat-form-field appearance="outline">
      <mat-label>New Password</mat-label>
      <input matInput type="password" formControlName="newPassword" />
      <mat-error>{{ 'validation:youMustEnterAValue' | i18nextCap }}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Confirm Password</mat-label>
      <input matInput type="password" formControlName="confirmPassword" />
      <mat-error>{{ 'validation:youMustEnterAValue' | i18nextCap }}</mat-error>
    </mat-form-field>

    <div class="form-group">
      <button
        class="btn-flip"
        data-front="Reset Password"
        data-back="Submit"
      ></button>
      <p>Just Remembered? <a routerLink="/login">Sign in</a></p>
    </div>
  </form>
</div>
