import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import {
  ResponseState,
  SupportPipelineStatus,
  RequestPipelineStatus,
} from '@fullyops/legacy/data';
import { BehaviorSubject } from 'rxjs';
import { ActionButtons } from '@fullyops/legacy/ui/ui-shared/utils/crm-types';
import { ComponentInOutAnimation } from '@fullyops/legacy/ui/ui-shared/utils/component-base-animation';
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { map } from 'rxjs/operators';
import { DropTicketProps } from '../board/board.component';
import { SupportTicketResponse } from '@fullyops/legacy/data/api/types/SupportTicket';

type ValidatorResponse = {
  [teste in keyof SupportTicketResponse | 'reports']?: { error: string };
};

@Component({
  selector: 'crm-pipeline',
  templateUrl: './pipeline.component.html',
  styleUrls: ['./pipeline.component.scss'],
  animations: [ComponentInOutAnimation.AnimTrigger],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PipelineComponent implements OnInit, OnDestroy {
  constructor(private changeDetectorRef: ChangeDetectorRef) {}
  @Input() pipeline$: BehaviorSubject<any>;

  @Output() openEvent = new EventEmitter<string>();
  @Output() dropTicket = new EventEmitter<DropTicketProps>();
  @Output() loadMoreTickets = new EventEmitter<string>();

  statuses$ = new BehaviorSubject<
    SupportPipelineStatus[] | RequestPipelineStatus[]
  >([]);

  // Response Possible Statuses
  status = ResponseState;

  actions: ActionButtons = {};

  ngOnInit() {
    this.pipeline$
      .pipe(
        // filter(response => response.data !== null),
        // map(response => response.data as Status[]),
        map((statuses) =>
          statuses.sort((a, b) => (a.position > b.position ? 1 : -1))
        )
      )
      .subscribe(this.statuses$);

    this.initActionButtons();
  }

  initActionButtons() {
    this.actions.primary = {
      label: 'createNewTicket',
      color: 'primary',
    };
  }

  moveCard(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }

  moveCardBack(event: CdkDragDrop<string[]>) {
    transferArrayItem(
      event.container.data,
      event.previousContainer.data,
      event.currentIndex,
      event.previousIndex
    );
    this.changeDetectorRef.detectChanges();
  }

  async drop(event: CdkDragDrop<string[]>) {
    this.moveCard(event);
    return this.dropTicket.emit({
      cdkDragDrop: event,
      newStatusId: event.container.id,
      previousStatus: event.previousContainer.id,
    });
  }

  /**
   * Event Handlers
   */

  onActionEvent(event: string) {}

  onOpen(ticket) {
    this.openEvent.emit(ticket.id);
  }

  trackByFn(index, item) {
    return item.id;
  }

  loadMoreTicketsEvent(statusId) {
    this.loadMoreTickets.emit(statusId);
  }

  ngOnDestroy() {}
}
