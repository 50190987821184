import { BehaviorSubject } from 'rxjs';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  OnChanges,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import {
  faArrowRotateRight,
  faFloppyDisk,
  faPen,
  faScrewdriverWrench,
  faToolbox,
  faTrash,
  faTrashCan,
  faTruckField,
  faUser,
  faWarehouse,
} from '@fortawesome/free-solid-svg-icons';
import {
  PartWithNoUser,
  UserWithParts,
} from '@fullyops/legacy/pages/stock/stock.component';
import { ComponentInOutAnimation } from '@fullyops/legacy/ui/ui-shared/utils/component-base-animation';
import { UpdateUserPartData } from '../ui-user-part-controller-v2.service';
import { PartResponse } from '@fullyops/legacy/data/api/types/Part';

@Component({
  selector: 'crm-stock-item-card',
  templateUrl: './stock-item-card.component.html',
  styleUrls: ['./stock-item-card.component.scss'],
  animations: [ComponentInOutAnimation.AnimTrigger],
})
export class StockItemCardComponent implements OnInit {
  // @Input() part: PartWithNoUser;
  @Input() userId: string;
  @Input() usersWithParts$: BehaviorSubject<UserWithParts[]>;
  @Input() usersWithPartsCurrentIndex: number;
  @Input() currentPartIndex: number;

  @Output() updateUserPartEvent = new EventEmitter<UpdateUserPartData>();
  @Output() deleteUserPart = new EventEmitter();

  quantity = new UntypedFormControl();
  part: PartWithNoUser | null = null;

  faUser = faUser;
  faTruckField = faTruckField;
  faToolbox = faToolbox;
  faScrewdriverWrench = faScrewdriverWrench;
  faWarehouse = faWarehouse;
  faTrashCan = faTrashCan;
  faPen = faPen;
  faFloppyDisk = faFloppyDisk;
  faArrowRotateRight = faArrowRotateRight;

  isEditing = false;

  constructor() {}

  ngOnInit() {
    this.setPart();
    this.quantity.setValue(this.part.quantity);
    this.quantity.disable();

    this.usersWithParts$.subscribe(() => {
      this.quantity.setValue(this.part.quantity);
    });
  }

  setPart() {
    this.part =
      this.usersWithParts$.value[this.usersWithPartsCurrentIndex].parts[
        this.currentPartIndex
      ];
  }

  toggleIsEditing() {
    if (this.quantity.disabled) {
      this.quantity.enable();
    } else {
      this.quantity.disable();
    }
  }

  updateUserPart() {
    this.toggleIsEditing();
    this.updateUserPartEvent.emit({
      partId: this.part.part.id,
      quantity: this.quantity.value,
      userId: this.userId,
    });
  }

  revert(event) {
    this.quantity.setValue(this.part.quantity);
    this.quantity.disable();
  }

  deleteUserPartClick() {
    this.deleteUserPart.emit();
  }
}
