import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ContactResponse } from '@fullyops/legacy/data/api/types/Contact';
import { faUser, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { TemplateFormFields } from '@fullyops/legacy/ui/ui-shared/form-template/form-template.component';
import {
  DeleteContactProps,
  EditContactProps,
} from '../company-form/company-form.component';

@Component({
  selector: 'crm-company-contact-expansion-panel',
  templateUrl: './company-contact-expansion-panel.component.html',
  styleUrls: ['./company-contact-expansion-panel.component.scss'],
})
export class CompanyContactExpansionPanelComponent implements OnInit {
  constructor() {}
  @Input() contact: ContactResponse;
  @Input() index: number;
  @Output() editContactEvent = new EventEmitter<EditContactProps>();
  @Output() deleteContactEvent = new EventEmitter<DeleteContactProps>();

  contactForm = new UntypedFormGroup({
    address: new UntypedFormControl(''),
    email: new UntypedFormControl(''),
    name: new UntypedFormControl('', [Validators.required]),
    notes: new UntypedFormControl(''),
    phone: new UntypedFormControl(''),
    jobPosition: new UntypedFormControl(''),
  });
  faUser = faUser;
  contactFields: TemplateFormFields<any> = [
    [
      {
        name: 'name',
        type: 'text',
        label: 'name',
        size: 6,
      },
      {
        name: 'jobPosition',
        type: 'text',
        label: 'jobPosition',
        size: 6,
      },
      {
        name: 'address',
        type: 'text',
        label: 'address',
        size: 12,
      },
      {
        name: 'email',
        type: 'text',
        label: 'email',
        size: 12,
      },
      {
        name: 'phone',
        type: 'text',
        label: 'phone',
        size: 12,
      },
      {
        name: 'notes',
        type: 'textarea',
        label: 'notes',
        size: 12,
      },
    ],
  ];

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.contactForm.reset({
      name: this.contact.name,
      address: this.contact.address,
      email: this.contact.email,
      phone: this.contact.mobileNumber,
      notes: this.contact.notes,
      jobPosition: this.contact.jobPosition,
    });
  }

  editContact() {
    const { deleted, company, id } = this.contact;
    const newContact: ContactResponse = {
      address: this.contactForm.controls['address'].value,
      email: this.contactForm.controls['email'].value,
      name: this.contactForm.controls['name'].value,
      notes: this.contactForm.controls['notes'].value,
      mobileNumber: this.contactForm.controls['phone'].value,
      jobPosition: this.contactForm.controls['jobPosition'].value,
      company,
      deleted,
      id,
    };
    this.editContactEvent.emit({
      contact: newContact,
      index: this.index,
      contactForm: this.contactForm,
    });
  }

  deleteContact() {
    this.deleteContactEvent.emit({
      contactId: this.contact.id,
      index: this.index,
    });
  }
}
