import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Company, Contact, User, ActivityType } from '@fullyops/legacy/data';
import { BehaviorSubject } from 'rxjs';
import { ComponentInOutAnimation } from '@fullyops/legacy/ui/ui-shared/utils/component-base-animation';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivityTypeIcon } from '@fullyops/legacy/ui/ui-shared/utils/crm-types';
import { TemplateFormFields } from '@fullyops/legacy/ui/ui-shared/form-template/form-template.component';

type Clients$ = {
  id: Company;
  name: string;
};

export type CurrentFilters = {
  name?: string;
  contactIds?: string[];
  companyIds?: string[];
  startsAt?: string;
  endsAt?: string;
  assigneeIds?: string[];
  activityType?: string;
  isDone?: 'onlyDone' | 'onlyNotDone';
};

export type ActivitiesFilters = {
  name?: string;
  contacts?: Contact[];
  companies?: Company[];
  startsAt?: string;
  endsAt?: string;
  assignees?: User[];
  activityType?: string;
  isDone?: 'onlyDone' | 'onlyNotDone';
};

@Component({
  selector: 'crm-activities-filters',
  templateUrl: './activities-filters.component.html',
  styleUrls: ['./activities-filters.component.scss'],
  animations: [ComponentInOutAnimation.AnimTrigger],
})
export class ActivitiesFiltersComponent implements OnInit {
  constructor() {}

  @Input() filters$ = new BehaviorSubject<CurrentFilters>({});
  @Input() users: User[];
  @Input() contacts: Contact[];
  @Input() companies: Company[];
  @Output() filtersChangeEvent = new EventEmitter<{}>();

  filtersForm: UntypedFormGroup;
  filtersFormFields: TemplateFormFields<any> = [];
  currentFilters$ = new BehaviorSubject<{}>({});

  clients$ = new BehaviorSubject<any[]>(null);
  assignees$ = new BehaviorSubject<any[]>([]);
  contacts$ = new BehaviorSubject<any[]>([]);

  activityStatus$ = new BehaviorSubject<any[]>([]);
  activityTypes$ = new BehaviorSubject(
    Object.keys(ActivityType).map((key) => ({
      id: key,
      name: ActivityType[key],
      icon: ActivityTypeIcon[key],
    }))
  );

  ngOnInit() {
    this.initFormGroup();
    this.initFormFields();
  }

  initFormGroup() {
    this.filtersForm = new UntypedFormGroup({
      name: new UntypedFormControl(''),
      contacts: new UntypedFormControl(''),
      companies: new UntypedFormControl(''),
      startsAt: new UntypedFormControl(''),
      endsAt: new UntypedFormControl(''),
      assignees: new UntypedFormControl(''),
      activityType: new UntypedFormControl(''),
      isDone: new UntypedFormControl(''),
    });

    this.filters$.subscribe((filters) => {
      let companies = [];
      let assignees = [];
      let contacts = [];
      let endsAt = null;
      let startsAt = null;

      if (filters.companyIds) {
        companies = filters.companyIds.map((companyId) => {
          return this.companies.find((comp) => comp.id == companyId);
        });
      }

      if (filters.assigneeIds) {
        assignees = filters.assigneeIds.map((assigneeId) => {
          return this.users.find((user) => user.id == assigneeId);
        });
      }

      if (filters.contactIds) {
        contacts = filters.contactIds.map((contactId) => {
          return this.contacts.find((c) => c.id == contactId);
        });
      }

      if (filters.endsAt) endsAt = new Date(filters?.endsAt);
      if (filters.startsAt) startsAt = new Date(filters?.startsAt);

      Object.entries(filters).forEach(([key, value]) => {
        switch (key) {
          case 'assigneeIds':
            this.filtersForm.get('assignees').patchValue(assignees);
            break;
          case 'companyIds':
            this.filtersForm.get('companies').patchValue(companies);
            break;
          case 'contactIds':
            this.filtersForm.get('contacts').patchValue(contacts);
            break;
          case 'endsAt':
            this.filtersForm.get('endsAt').patchValue(endsAt);
            break;
          case 'startsAt':
            this.filtersForm.get('startsAt').patchValue(startsAt);
            break;
          default:
            this.filtersForm.get(key).patchValue(value);
            break;
        }
      });

      this.updateChipsFilters();
    });
  }

  initFormFields() {
    this.activityStatus$.next([
      { id: true, name: 'onlyDone' },
      { id: false, name: 'onlyNotDone' },
    ]);

    this.clients$.next(
      this.companies.map((company) => {
        return {
          id: company,
          name: company.name,
        };
      })
    );

    this.assignees$.next(
      this.users.map((user: User) => ({
        id: user,
        name: `${user.firstName} ${user.lastName}`,
      }))
    );

    this.contacts$.next(
      this.contacts.map((c: Contact) => ({
        id: c,
        name: `${c.name}`,
      }))
    );

    this.filtersFormFields = [
      [
        { name: 'name', type: 'text', label: 'name', size: 12 },
        {
          name: 'contacts',
          type: 'multiple-select',
          label: 'contacts',
          size: 12,
          items$: this.contacts$,
          nullable: false,
          translate: false,
        },
        {
          name: 'activityType',
          type: 'select',
          label: 'activityType',
          size: 12,
          items$: this.activityTypes$,
          nullable: false,
          translate: true,
        },
        {
          name: 'companies',
          type: 'autocomplete',
          label: 'companies',
          size: 12,
          items$: new BehaviorSubject(this.companies),
          nullable: false,
          translate: false,
        },
        { name: 'startsAt', type: 'date', label: 'startsAt', size: 12 },
        { name: 'endsAt', type: 'date', label: 'endsAt', size: 12 },
        {
          name: 'assignees',
          type: 'multiple-select',
          label: 'assignees',
          size: 12,
          items$: this.assignees$,
          nullable: false,
          translate: false,
        },
        {
          name: 'isDone',
          type: 'select',
          items$: this.activityStatus$,
          label: 'isDone',
          size: 12,
          translate: true,
        },
      ],
    ];
  }

  getFilterFormValueFormatted() {
    return Object.entries(this.filtersForm.value).reduce(
      (acc, [key, value]) => {
        if (value === undefined || value === null || value === '') return acc;

        if (Array.isArray(value)) {
          if (value.length == 0) return acc;
        }

        switch (key) {
          case 'companies':
            acc['companyIds'] = (value as Company[]).map((u) => u.id);
            break;
          case 'contacts':
            acc['contactIds'] = (value as Contact[]).map((c) => c.id);
            break;
          case 'assignees':
            acc['assigneeIds'] = (value as User[]).map((u) => u.id);
            break;
          case 'endsAt':
            acc['endsAt'] = (value as Date).toISOString();
            break;
          case 'startsAt':
            acc['startsAt'] = (value as Date).toISOString();
            break;
          default:
            acc[key] = value;
            break;
        }
        return acc;
      },
      {}
    );
  }

  concatMultipleItems(list: { name: string }[], maxItems = 2) {
    let temp = '';
    if (list.length >= maxItems) {
      temp = `${list[0].name}, ${list[1].name}`;

      if (list.length > maxItems) temp = `${temp}, +${list.length - maxItems}`;
    } else {
      temp = `${list[0].name}`;
    }
    return temp;
  }

  updateChipsFilters() {
    const filters = this.filtersForm.value as ActivitiesFilters;
    let currentFilters = {};

    Object.keys(filters).forEach((key: keyof ActivitiesFilters) => {
      if (
        filters[key] === undefined ||
        filters[key] === null ||
        filters[key] === ''
      )
        return;
      const value = filters[key];

      if (Array.isArray(value)) {
        if (value.length == 0) return;
      }

      if (key == 'companies') {
        return (currentFilters['companyIds'] = this.concatMultipleItems(
          filters[key]
        ));
      }

      if (key == 'contacts') {
        return (currentFilters['contactIds'] = this.concatMultipleItems(
          filters[key]
        ));
      }

      if (key == 'assignees') {
        return (currentFilters['assigneeIds'] = this.concatMultipleItems(
          filters[key].map((u) => ({ name: u.firstName + ' ' + u.lastName }))
        ));
      }

      if (key == 'endsAt' || key == 'startsAt') {
        return (currentFilters[key] = new Date(
          filters[key]
        ).toLocaleDateString());
      }

      if (key == 'isDone') {
        return (currentFilters[key] = filters[key]
          ? 'onlyDone'
          : 'onlyNotDone');
      }

      currentFilters[key] = value;
    });

    return this.currentFilters$.next(currentFilters);
  }

  applyFilters() {
    this.filtersChangeEvent.emit(this.getFilterFormValueFormatted());
  }

  // tslint:disable-next-line: no-shadowed-variable
  removeFilter(filter: keyof CurrentFilters | keyof ActivitiesFilters) {
    if (filter === 'assigneeIds') filter = 'assignees';
    if (filter === 'companyIds') filter = 'companies';
    if (filter == 'contactIds') filter = 'contacts';
    this.filtersForm.get(filter).patchValue('');
    this.applyFilters();
  }
}
