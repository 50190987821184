import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ComponentInOutAnimation } from '@fullyops/legacy/ui/ui-shared/utils/component-base-animation';
import { ProductConfig, ProductConfigListItem } from '@fullyops/legacy/data';

@Component({
  selector: 'crm-product-configuration-list',
  templateUrl: './product-configuration-list.component.html',
  styleUrls: ['./product-configuration-list.component.scss'],
  animations: [ComponentInOutAnimation.AnimTrigger],
})
export class ProductConfigurationListComponent {
  @Input() listItem: ProductConfigListItem;
  @Output() openConfigurationEvent = new EventEmitter<string>();
}
