import { ISerialisedUnsavedProductConfig } from './serialisations/ISerialisedProductConfig';
import { Product } from './Product';
import { ReportSection } from './ReportSection';

export class UnsavedProductConfig {
  constructor(
    readonly name: string,
    readonly status: string,
    readonly product: Product,
    readonly additionalInfo: ReportSection[]
  ) {
    if (name) this.name = name.trim();
    if (status) this.status = status.trim();
  }

  static fromSerialised(serialised: ISerialisedUnsavedProductConfig) {
    const product = Product.fromSerialised(serialised.product);
    const additionalInfo = serialised.additionalInfo.map(
      ReportSection.fromSerialised
    );

    return new UnsavedProductConfig(
      serialised.name,
      serialised.status,
      product,
      additionalInfo
    );
  }

  toSerialised(): ISerialisedUnsavedProductConfig {
    const productObj = this.product.toSerialised();
    const additionalInfoObj = this.additionalInfo.map((info) =>
      info.toSerialised()
    );

    return {
      name: this.name,
      status: this.status,
      product: productObj,
      additionalInfo: additionalInfoObj,
    };
  }

  clone(partialProduct: PartialUnsavedProductConfig): UnsavedProductConfig {
    const resolve = (key: keyof UnsavedProductConfig) =>
      partialProduct.hasOwnProperty(key) ? partialProduct[key] : this[key];

    return new UnsavedProductConfig(
      resolve('name'),
      resolve('status'),
      resolve('product'),
      resolve('additionalInfo')
    );
  }
}

type PartialUnsavedProductConfig = Partial<
  Pick<UnsavedProductConfig, 'name' | 'status' | 'product' | 'additionalInfo'>
>;
