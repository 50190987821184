import { ISerialisedHistory } from './serialisations/ISerialisedHistory';
import { HistoryLink } from './HistoryLink';

export class History {
  constructor(readonly id: string, readonly historyLink: HistoryLink) {}

  static fromSerialised(serialised: ISerialisedHistory) {
    if (!serialised) {
      return null;
    }

    const historyLink = HistoryLink.fromSerialised(
      serialised.historyLinkResponse
    );

    return new History(serialised.id, historyLink);
  }

  toSerialised(): ISerialisedHistory {
    const linkObj = this.historyLink.toSerialised();

    return {
      id: this.id,
      historyLinkResponse: linkObj,
    };
  }

  clone(partialHistory: PartialHistory): History {
    const resolve = (key: keyof History) =>
      partialHistory.hasOwnProperty(key) ? partialHistory[key] : this[key];

    return new History(resolve('id'), resolve('historyLink'));
  }
}

type PartialHistory = Partial<Pick<History, 'id' | 'historyLink'>>;
