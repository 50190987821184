import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { ListItem, ListItemColumn } from '../utils/crm-types';
import { map, debounceTime } from 'rxjs/operators';

@Component({
  selector: 'crm-search-filter',
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.scss'],
})
export class SearchFilterComponent implements OnInit {
  constructor() {}
  @Input() items$ = new BehaviorSubject<any[]>([]);
  @Input() debouceTime: number;
  @Output() filtersChangeEvent = new EventEmitter<[]>();

  searchCtrl = new UntypedFormControl();

  ngOnInit() {
    this.handleSearchChanges();
  }

  handleSearchChanges() {
    this.searchCtrl.valueChanges
      .pipe(
        this.debouceTime ? debounceTime(this.debouceTime) : map(() => 0),
        map((item) => (item ? this.filterItems(item) : this.items$.getValue()))
      )
      .subscribe((items) => this.filtersChangeEvent.emit(items as []));
  }

  filterItems(value: string) {
    const filterValue = value.toLowerCase();

    const results = this.items$
      .getValue()
      .filter((item: ListItem) =>
        Object.values(item.columns).reduce(
          (acc: boolean, curr: ListItemColumn) =>
            typeof curr.value === 'string'
              ? (acc =
                  acc ||
                  JSON.stringify(curr).toLowerCase().includes(filterValue))
              : !curr.value
              ? (acc = acc || false)
              : (acc =
                  acc ||
                  JSON.stringify(curr).toLowerCase().includes(filterValue)),
          false
        )
      );

    return results;
  }
}
