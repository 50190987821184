import {
  Component,
  Input,
  OnInit,
  Output,
  ChangeDetectorRef,
  EventEmitter,
} from '@angular/core';

import { FeaturePermissionToggleServiceV2 } from '../v2/feature-toggles/ui-feature-permission-toggle.service';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { TemplateFormFields } from '@fullyops/legacy/ui/ui-shared/form-template/form-template.component';
import { BehaviorSubject } from 'rxjs';
import { I18NextPipe } from 'angular-i18next';
import { ActivityType, TicketType } from '@fullyops/legacy/data/models/types';
import {
  ActionButtons,
  ActivityTypeIcon,
  PermissionType,
  PermissionsType,
  RoleGroup,
  TicketTypeIcon,
} from '@fullyops/legacy/ui/ui-shared/utils/crm-types';
import { FeaturePermissionToggle } from '@fullyops/legacy/data/api/types/FeaturePermissionToggle';
import { UiAuthService } from '../ui-auth.service';
import {
  QueryParams as SupportTicketControllerQueryParams,
  SupportTicketController,
} from '../ui-support-ticket-controller.service';
import {
  QueryParams as RequestTicketControllerQueryParams,
  RequestTicketControllerV2,
} from '../ui-request-ticket-controller-v2.service';
import { Contact, TicketTypeV2 } from '@fullyops/legacy/data';
import { CompanyResponse } from '@fullyops/legacy/data/api/types/Company';
import { CompanyControllerV2 } from '../ui-company-controller-v2.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { UiContactsService } from '../ui-contacts.service';
import { UserControllerV2 } from '../ui-user-controller-v2.service';
import { UserResponse } from '@fullyops/legacy/data/api/types/User';
import { SnackbarService } from '../ui-snackbar.service';
import { ActivityControllerV2 } from '../ui-activity-controller-v2.service';
import {
  ActivityPatchRequest,
  ActivityPostRequest,
  ActivityResponse,
} from '@fullyops/legacy/data/api/types/Activity';
import { Router } from '@angular/router';
import { RequestTicketResponse } from '@fullyops/legacy/data/api/types/RequestTicke';
import { SaleTicketResponse } from '@fullyops/legacy/data/api/types/Ticket';
import { SaleTicketControllerV2 } from '../ui-sale-ticket-controller-v2.service';
import { diff } from 'deep-object-diff';
import { SupportTicketResponse } from '@fullyops/legacy/data/api/types/SupportTicket';
import { TimeZoneDateFormatterService } from '@fullyops/core/services/date-formatter.service';

type TicketAutocompleteData = {
  id: string;
  name: string;
  company: CompanyResponse;
};

@Component({
  selector: 'crm-activity-form-v2',
  templateUrl: './activity-form-v2.component.html',
  styleUrls: ['./activity-form-v2.component.scss'],
})
export class ActivityFormV2Component implements OnInit {
  constructor(
    public featurePermissionToggleServiceV2: FeaturePermissionToggleServiceV2,
    public i18nextPipe: I18NextPipe,
    public authService: UiAuthService,
    public supportTicketController: SupportTicketController,
    public requestTicketControllerV2: RequestTicketControllerV2,
    public companyControllerV2: CompanyControllerV2,
    public changeDetectorRef: ChangeDetectorRef,
    protected contactsService: UiContactsService,
    public userControllerV2: UserControllerV2,
    public snackbarService: SnackbarService,
    public activityControllerV2: ActivityControllerV2,
    public router: Router,
    public saleTicketControllerV2: SaleTicketControllerV2,
    public timezoneDate: TimeZoneDateFormatterService
  ) {}
  @Input() isNew: boolean;
  @Input() activity: ActivityResponse;
  @Output() createActivity: EventEmitter<ActivityPostRequest> =
    new EventEmitter();
  @Output() updateActivity: EventEmitter<ActivityPatchRequest> =
    new EventEmitter();
  @Output() deleteActivity: EventEmitter<any> = new EventEmitter();

  @Input() updateDate: string;

  //  Form
  formGroup = new UntypedFormGroup({});
  formFields: TemplateFormFields<any> = [];
  formFieldsRight: TemplateFormFields<any> = [];

  //  ActivitiesTypes
  activityTypes$ = new BehaviorSubject([]);

  companies$ = new BehaviorSubject<CompanyResponse[]>([]);
  companyContacts$ = new BehaviorSubject<any[]>([]);

  ticketTypes$ = new BehaviorSubject([]);
  tickets$ = new BehaviorSubject([]);
  users$ = new BehaviorSubject<{ id: string; name: string }[]>([]);

  companyButtonDisabled$ = new BehaviorSubject<boolean>(true);
  isSubmitDisabled$ = new BehaviorSubject(true);

  actions: ActionButtons = {};

  userCanEditForm: boolean = true;

  ngOnInit() {
    this.loadUsers();
    this.initFormGroup();
    this.initFormFields();

    this.loadCompanies({});

    this.onTicketChange();
    this.onTicketTypeChange();
    this.onCompanyChange();

    this.onFormChangeEnableSubmit();
    this.setActions();
    this.disableFormIfCantEdit();
  }

  getToolTipSubmitBtnMessage() {
    if (!this.userCanEditForm) return 'forms:noPermissionToEditThisActivity';
    else return 'forms:thereAreNoChanges';
  }

  disableFormIfCantEdit() {
    if (!this.isNew) {
      this.authService
        .hasPermission([PermissionType.CAN_EDIT_ACTIVITIES])
        .subscribe((canEditAllActivities) => {
          const isCreatedByThisUser =
            this.activity.createdBy.id == this.authService.currentUser.id;

          if (!canEditAllActivities && isCreatedByThisUser) {
            return this.authService
              .hasPermission([PermissionType.CAN_EDIT_OWN_ACTIVITIES])
              .subscribe((canEditOwnActivities) => {
                if (!canEditOwnActivities) {
                  this.formGroup.disable();
                  this.userCanEditForm = false;
                }
              });
          }

          if (!canEditAllActivities) {
            this.formGroup.disable();
            this.userCanEditForm = false;
          }
        });
    }
  }

  userCanDeleteThisActivity() {
    const isCreatedByThisUser =
      this.activity.createdBy.id == this.authService.currentUser.id;

    const canDeleteActivities = this.authService.hasPermissionNoAsync([
      'CAN_DELETE_ACTIVITIES',
    ]);

    const canDeleteOwnActivities = this.authService.hasPermissionNoAsync([
      'CAN_DELETE_OWN_ACTIVITIES',
    ]);

    if (!canDeleteActivities) {
      if (canDeleteOwnActivities && isCreatedByThisUser) {
        return true;
      }

      return false;
    }
    return true;
  }

  setActions() {
    if (!this.isNew) {
      if (this.userCanDeleteThisActivity()) {
        this.actions.warn = {
          label: 'delete',
          color: 'warn',
          dataTestId: 'delete-ticket-test',
        };
      }
    }
  }

  newDate = (props: { minutes: number }) => {
    const date = this.timezoneDate.currentDate();
    const next15Minutes = 15 * Math.ceil(date.minute() / 15);
    date.minutes(props.minutes ? next15Minutes + props.minutes : next15Minutes);
    date.seconds(0);
    return date.toISOString();
  };

  initFormGroup() {
    if (this.isNew) {
      this.formGroup = new UntypedFormGroup({
        activityType: new UntypedFormControl('', [Validators.required]),
        assigneeIds: new UntypedFormControl(
          [this.authService.currentUser.id],
          [Validators.required]
        ),
        company: new UntypedFormControl(null, [Validators.required]),
        contactIds: new UntypedFormControl(''),
        done: new UntypedFormControl(false),
        endsAt: new UntypedFormControl(this.newDate({ minutes: 30 })),
        internalObservation: new UntypedFormControl(''),
        name: new UntypedFormControl('', [Validators.required]),
        startsAt: new UntypedFormControl(this.newDate({ minutes: 0 })),
        ticket: new UntypedFormControl(''),
        ticketType: new UntypedFormControl(null),
      });
    }

    if (!this.isNew) {
      this.loadContacts({ companyId: this.activity.company.id });
      const ticket = this.activity.activityLinkResponse
        ? this.formatCompanyToInput({
            ticket:
              this.activity.activityLinkResponse.requestTicket ||
              this.activity.activityLinkResponse.saleTicket ||
              this.activity.activityLinkResponse.supportTicket,
          })
        : null;

      this.formGroup = new UntypedFormGroup({
        createdAt: new UntypedFormControl(
          this.timezoneDate
            .formatInTimezone(this.activity.createdAt)
            .format('DD/MM/YYYY - h:mm A')
        ),
        activityType: new UntypedFormControl(this.activity.activityType, [
          Validators.required,
        ]),
        assigneeIds: new UntypedFormControl(
          this.activity.assignees.map((user) => user.id),
          [Validators.required]
        ),
        company: new UntypedFormControl(this.activity.company, [
          Validators.required,
        ]),
        contactIds: new UntypedFormControl(
          this.activity.contacts.map((user) => user.id)
        ),
        done: new UntypedFormControl(this.activity.done),
        endsAt: new UntypedFormControl(this.activity.endsAt),
        internalObservation: new UntypedFormControl(
          this.activity.internalObservation
        ),
        name: new UntypedFormControl(this.activity.name, [Validators.required]),
        startsAt: new UntypedFormControl(this.activity.startsAt, [
          Validators.required,
        ]),
        ticket: new UntypedFormControl(ticket),
        ticketType: new UntypedFormControl(
          this.activity.activityLinkResponse
            ? this.activity.activityLinkResponse.linkType
            : null
        ),
      });

      const { controls } = this.formGroup;

      if (this.activity.company) {
        this.companyButtonDisabled$.next(false);
      }

      controls['createdAt'].disable();
      if (controls['done'].value) {
        this.formGroup.disable();
        controls['done'].enable();
      }
    }
  }

  loadActivitiesType() {
    const activitiesType = Object.keys(ActivityType)
      .map((key) => ({
        id: key,
        name: ActivityType[key],
        icon: ActivityTypeIcon[key],
      }))
      .sort(this.translateAndSort);

    this.activityTypes$.next(activitiesType);
  }

  loadTicketType() {
    const hasPermission = (permission: PermissionsType[]) => {
      return this.authService.hasPermissionNoAsync(permission);
    };

    const isFeatureEnabled = (
      featureLabel: FeaturePermissionToggle['label'],
      featuresPermissionToggle
    ) => {
      return this.featurePermissionToggleServiceV2.isFeatureEnabledNoAsync({
        store: featuresPermissionToggle,
        featureLabel,
      });
    };

    this.featurePermissionToggleServiceV2
      .getAllV2()
      .subscribe((featuresPermissionToggle) => {
        let ticketTypes = Object.keys(TicketType).map((key) => ({
          id: key,
          name: TicketType[key],
          icon: TicketTypeIcon[key],
        }));

        ticketTypes = ticketTypes.filter((ticketType) => {
          if (ticketType.name === TicketType['REQUEST']) {
            if (
              !hasPermission([
                'CAN_ACCESS_REQUEST_TICKETS',
                'CAN_ACCESS_OWN_REQUEST_TICKETS',
              ]) ||
              !isFeatureEnabled(
                'PIPELINES_SUPPORT_TICKET',
                featuresPermissionToggle
              )
            ) {
              return false;
            }
          }

          if (ticketType.name === TicketType['SALE']) {
            if (
              !hasPermission([
                'CAN_ACCESS_SALE_TICKETS',
                'CAN_ACCESS_OWN_SALE_TICKETS',
              ]) ||
              !isFeatureEnabled(
                'PIPELINES_SALE_TICKET',
                featuresPermissionToggle
              )
            ) {
              return false;
            }
          }

          if (ticketType.name === TicketType['SUPPORT']) {
            if (
              !hasPermission(['CAN_ACCESS_SUPPORT_TICKETS']) ||
              !isFeatureEnabled(
                'PIPELINES_SUPPORT_TICKET',
                featuresPermissionToggle
              )
            ) {
              return false;
            }
          }

          return true;
        });

        ticketTypes.unshift({
          icon: '',
          id: null,
          name: 'unassign',
        });

        this.ticketTypes$.next(ticketTypes);
      });
  }

  loadAndUpdateListOfTickets() {
    const requestTickets = (ticketType: TicketType | null | '') => {
      if (!ticketType) return this.resetTickets();
      if (ticketType == TicketType['SUPPORT']) return this.loadSupportTickets();
      if (ticketType == TicketType['REQUEST']) return this.loadRequestTickets();
      if (ticketType == TicketType['SALE']) return this.loadSaleTickets();
    };

    const ticketType = this.formGroup.controls['ticketType'].value;
    requestTickets(ticketType as TicketType | null | '');
  }

  resetTicketIdField() {
    this.formGroup.controls['ticket'].enable();
    this.formGroup.controls['ticket'].setValue(undefined);
    if (!this.formGroup.controls['ticketType'].value) {
      this.formGroup.controls['ticket'].disable();
    }
  }

  resetTickets() {
    this.tickets$.next([]);
  }

  formatCompanyToInput(params: {
    ticket: SupportTicketResponse | RequestTicketResponse | SaleTicketResponse;
  }) {
    const { ticket } = params;
    return {
      id: ticket.id,
      name: `[${ticket.identifier}] ${ticket.name}`,
      company: ticket.company,
    };
  }

  loadSupportTickets() {
    const queryParams: SupportTicketControllerQueryParams = {
      orderBy: 'IDENTIFIER',
      page: -1,
    };

    this.supportTicketController
      .getAllSupportTickets({ queryParams })
      .subscribe((res) => {
        const tickets = res.results
          .filter(
            (ticket) =>
              ticket.status.name !== 'Done' && ticket.status.name !== 'Closed'
          )
          .map((ticket) => this.formatCompanyToInput({ ticket }));

        this.tickets$.next(tickets);
      });
  }

  loadRequestTickets() {
    const queryParams: RequestTicketControllerQueryParams = {
      orderBy: 'IDENTIFIER',
      page: -1,
    };

    this.requestTicketControllerV2.getAll({ queryParams }).subscribe((res) => {
      const tickets = res.results
        .filter(
          (ticket) =>
            ticket.status.name !== 'Done' && ticket.status.name !== 'Closed'
        )
        .map((ticket) => this.formatCompanyToInput({ ticket }));
      this.tickets$.next(tickets);
    });
  }

  loadSaleTickets() {
    this.saleTicketControllerV2
      .getAllSaleTickets({
        queryParams: { page: -1 },
      })
      .subscribe((res) => {
        const tickets = res.results
          .filter(
            (ticket) =>
              ticket.status.name !== 'Done' && ticket.status.name !== 'Closed'
          )
          .map((ticket) => this.formatCompanyToInput({ ticket }));
        this.tickets$.next(tickets);
      });
  }

  initFormFields() {
    this.loadTicketType();
    this.loadActivitiesType();
    this.loadAndUpdateListOfTickets();

    if (this.isNew) {
      this.formFields = [
        [
          {
            name: 'name',
            label: 'name',
            type: 'text',
            size: 12,
          },
          {
            name: 'ticketType',
            label: 'ticketType',
            type: 'select',
            size: 4,
            items$: this.ticketTypes$,
            nullable: false,
            translate: true,
            dataTestId: 'activity-ticket-type-input',
          },
          {
            name: 'ticket',
            label: 'ticketName',
            type: 'autocomplete',
            size: 8,
            items$: this.tickets$,
            nullable: false,
            translate: false,
            dataTestId: 'activity-ticket-name-input',
          },
          {
            name: 'company',
            type: 'autocomplete',
            label: 'company',
            size: 12,
            items$: this.companies$,
            nullable: false,
            translate: false,
            button: {
              iconName: 'account_balance',
              onClick: () => this.redirectToCompanyPage(),
              disabled$: this.companyButtonDisabled$,
            },
          },
          {
            name: 'contactIds',
            type: 'multiple-select',
            label: 'contacts',
            size: 12,
            items$: this.companyContacts$,
            nullable: false,
            translate: false,
            displayValue: (contact: Contact) => {
              return `${contact.name}  ${
                contact.jobPosition ? `(${contact.jobPosition})` : ''
              }`;
            },
          },
          {
            name: 'internalObservation',
            type: 'textarea',
            label: 'internalObservation',
            size: 12,
          },
        ],
      ];
    }

    if (!this.isNew) {
      this.formFields = [
        [
          {
            name: 'name',
            label: 'name',
            type: 'text',
            size: 6,
          },
          {
            name: 'createdAt',
            label: 'createdAt',
            type: 'text',
            size: 6,
          },
          {
            name: 'ticketType',
            label: 'ticketType',
            type: 'select',
            size: 4,
            items$: this.ticketTypes$,
            nullable: false,
            translate: true,
          },
          {
            name: 'ticket',
            label: 'ticketName',
            type: 'autocomplete',
            size: 8,
            items$: this.tickets$,
            nullable: false,
            translate: false,
          },
          {
            name: 'company',
            type: 'autocomplete',
            label: 'company',
            size: 12,
            items$: this.companies$,
            nullable: false,
            translate: false,
            button: {
              iconName: 'account_balance',
              onClick: () => this.redirectToCompanyPage(),
              disabled$: this.companyButtonDisabled$,
            },
          },
          {
            name: 'contactIds',
            type: 'multiple-select',
            label: 'contacts',
            size: 12,
            items$: this.companyContacts$,
            nullable: false,
            translate: false,
            displayValue: (contact: Contact) => {
              return `${contact.name}  ${
                contact.jobPosition ? `(${contact.jobPosition})` : ''
              }`;
            },
          },
          {
            name: 'internalObservation',
            type: 'textarea',
            label: 'internalObservation',
            size: 12,
          },
        ],
      ];
    }

    this.formFieldsRight = [
      [
        {
          name: 'activityType',
          label: 'activityType',
          type: 'select',
          size: 12,
          items$: this.activityTypes$,
          nullable: false,
          translate: true,
        },
        {
          name: 'assigneeIds',
          label: 'assignee',
          type: 'multiple-select',
          size: 12,
          items$: this.users$,
          translate: false,
        },
        { name: 'startsAt', label: 'startsAt', type: 'fulldate', size: 12 },
        {
          name: 'endsAt',
          label: 'endsAt',
          type: 'fulldate',
          size: 12,
          fullDate: {
            canNotBeBeforeThan: 'startsAt',
          },
        },
        { name: 'done', label: 'done', type: 'slide', size: 12 },
      ],
    ];
  }

  translateAndSort = (a, b) => {
    const nameA = this.i18nextPipe.transform(a.name.toUpperCase());
    const nameB = this.i18nextPipe.transform(b.name.toUpperCase());
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  };

  loadCompanies(params: { name?: string }) {
    let queryParams = {};
    if (params.name) queryParams['name'] = params.name;
    this.companyControllerV2.getAll({ queryParams }).subscribe((res) => {
      this.companies$.next(res.results);
      this.changeDetectorRef.detectChanges();
    });
  }

  loadContacts(params: { companyId: string }) {
    const { companyId } = params;
    this.contactsService.getAll({ companyId }).subscribe((res) => {
      this.companyContacts$.next(res.data);
    });
  }

  resetContacts() {
    this.formGroup.controls['contactIds'].patchValue(null);
    this.companyContacts$.next([]);
  }

  onCompanyChange() {
    this.formGroup.controls['company'].valueChanges
      .pipe(debounceTime(450), distinctUntilChanged())
      .subscribe((value) => {
        if (!value) {
          if (!this.companyButtonDisabled$.value) {
            this.companyButtonDisabled$.next(true);
          }
          return this.resetContacts();
        }

        if (typeof value == 'string') {
          return this.loadCompanies({ name: value });
        }

        if (value.id && this.companyButtonDisabled$.value) {
          this.companyButtonDisabled$.next(false);
        }

        this.loadContacts({ companyId: (value as CompanyResponse).id });
      });
  }

  onTicketTypeChange() {
    this.formGroup.controls['ticketType'].valueChanges.subscribe(
      (value: TicketType | null | '') => {
        this.resetTicketIdField();
        this.loadAndUpdateListOfTickets();
        if (!value) {
          this.formGroup.controls['ticket'].removeValidators(
            Validators.required
          );
          this.formGroup.controls['ticket'].updateValueAndValidity();
          this.formGroup.controls['company'].enable();
          return;
        }
        this.formGroup.controls['ticket'].addValidators(Validators.required);
        this.formGroup.controls['ticket'].updateValueAndValidity();
        this.formGroup.controls['company'].setValue(null);
        this.formGroup.controls['company'].disable();
      }
    );
  }

  onTicketChange() {
    this.formGroup.controls['ticket'].valueChanges.subscribe(
      (ticket: TicketAutocompleteData) => {
        if (!ticket) {
          this.formGroup.controls['company'].patchValue(null);
          return;
        }

        this.formGroup.controls['company'].patchValue(ticket.company);
        this.formGroup.controls['company'].disable();
      }
    );
  }

  loadUsers() {
    this.userControllerV2
      .getUsers({
        queryParameters: {},
      })
      .subscribe((res) => {
        const users = res.results.map((user: UserResponse) => ({
          id: user.id,
          name: `${user.firstName} ${user.lastName}`,
        }));
        this.users$.next(users);
      });
  }

  getNewActivityData() {
    const { controls } = this.formGroup;
    const ticket = controls['ticket'].value;
    const company: CompanyResponse | null = controls['company'].value;
    const endsAt = controls['endsAt'].value;
    const startsAt = controls['startsAt'].value;

    const newActivity: ActivityPostRequest = {
      activityType: controls['activityType'].value,
      assigneeIds: controls['assigneeIds'].value,
      companyId: company ? company?.id : null,
      contactIds: controls['contactIds'].value || [],
      done: controls['done'].value,
      internalObservation: controls['internalObservation'].value,
      name: controls['name'].value,
      ticketId: ticket ? ticket?.id : null,
      ticketType: controls['ticketType'].value,
      endsAt: endsAt
        ? this.timezoneDate
            .formatInTimezone(controls['endsAt'].value)
            .toISOString()
        : null,
      startsAt: startsAt
        ? this.timezoneDate
            .formatInTimezone(controls['startsAt'].value)
            .toISOString()
        : null,
    };
    return newActivity;
  }

  getUpdateActivityData() {
    const { controls } = this.formGroup;
    const ticket = controls['ticket'].value;
    const company: CompanyResponse | null = controls['company'].value;
    const endsAt = controls['endsAt'].value;
    const startsAt = controls['startsAt'].value;

    const updateActivityData: ActivityPatchRequest = {
      activityType: controls['activityType'].value,
      assigneeIds: controls['assigneeIds'].value,
      companyId: company ? company?.id : null,
      contactIds: controls['contactIds'].value || [],
      done: controls['done'].value,
      internalObservation: controls['internalObservation'].value,
      name: controls['name'].value,
      ticketId: ticket ? ticket?.id : null,
      ticketType: controls['ticketType'].value,
      endsAt: endsAt
        ? this.timezoneDate
            .formatInTimezone(controls['endsAt'].value)
            .toISOString()
        : null,
      startsAt: startsAt
        ? this.timezoneDate
            .formatInTimezone(controls['startsAt'].value)
            .toISOString()
        : null,
      id: this.activity.id,
    };

    return updateActivityData;
  }

  onSubmit() {
    this.formGroup.markAllAsTouched();

    if (!this.formGroup.valid) {
      return this.snackbarService.openErrorFormMissingFields();
    }

    if (this.isNew) return this.createActivity.emit(this.getNewActivityData());

    if (!this.isNew)
      return this.updateActivity.emit(this.getUpdateActivityData());
  }

  redirectToCompanyPage() {
    const company = this.formGroup.controls['company'].value as CompanyResponse;

    return this.authService
      .hasPermission([PermissionType.CAN_EDIT_COMPANIES])
      .subscribe((canEditCompanies) => {
        return this.router.navigate([
          `companies/${company.id}${canEditCompanies ? '/edit' : ''}`,
        ]);
      });
  }

  redirectToActivitiesPage() {
    return this.router.navigate([`activities`]);
  }

  redirectToActivityPage(params: { activityId: string }) {
    return this.router.navigate([`activities/${params.activityId}/edit`]);
  }

  onActionEvent(event: string) {
    switch (event) {
      case 'delete':
        this.deleteActivity.emit();
        break;
      default:
        break;
    }
  }

  onFormChangeEnableSubmit() {
    this.formGroup.valueChanges.subscribe((valuesTouched) => {
      const originalValues = {};

      if (!this.isNew) {
        Object.keys(valuesTouched).forEach((key) => {
          if (key == 'assigneeIds') {
            return (originalValues[key] = this.activity.assignees.map(
              (e) => e.id
            ));
          }

          if (key == 'contactIds') {
            return (originalValues[key] = this.activity.contacts.map(
              (e) => e.id
            ));
          }

          //  Prevent differences btw null and undefined
          if (!valuesTouched[key] && !this.activity[key]) {
            return (originalValues[key] = valuesTouched[key]);
          }

          originalValues[key] = this.activity[key];
        });
      }

      const differences = diff(originalValues, valuesTouched);

      if (Object.keys(differences).length == 0) {
        if (!this.isSubmitDisabled$.value) this.isSubmitDisabled$.next(true);
      } else {
        if (this.isSubmitDisabled$.value) this.isSubmitDisabled$.next(false);
      }
    });
  }
}
