export const errorsMessages = {
  companySameEmail: 'A company with the same email address already exists',
  companyEmailInvalid: 'email: company email is invalid',
};

export const CompanyRequestErrorMessages = {
  400: {
    default: 'error:error_occured',
    [errorsMessages.companySameEmail]: 'error:companySameEmail',
    [errorsMessages.companyEmailInvalid]: 'error:companyEmailIsInvalid',
  },
  404: { default: 'error:error_occured' },
  500: { default: 'error:error_occured' },
};

export const companyDynamicErrorMessage = ({
  errorMessage,
  endPoint,
}: {
  errorMessage: string | any;
  endPoint: string;
}) => {
  if (endPoint != '/companies') {
    return null;
  }
  if (errorMessage) {
    if (errorMessage.includes(errorsMessages.companySameEmail)) {
      return CompanyRequestErrorMessages[400][errorsMessages.companySameEmail];
    }

    if (errorMessage.includes(errorsMessages.companyEmailInvalid)) {
      return CompanyRequestErrorMessages[400][
        errorsMessages.companyEmailInvalid
      ];
    }
  }

  return;
};
