import { HttpErrorResponse } from '@angular/common/http';

export enum ResponseState {
  Loading = 'loading',
  Loaded = 'loaded',
  Error = 'error',
}

export class Response<T> {
  public readonly count: number;
  public readonly next: string | null;
  public readonly previous: string | null;

  constructor(
    public readonly data: T | null = null,
    public readonly error: HttpErrorResponse | null = null,
    count?: number,
    next?: string | null,
    previous?: string | null
  ) {
    this.count =
      count !== undefined ? count : Array.isArray(data) ? data.length : 1;
    this.next = next === undefined ? null : next;
    this.previous = previous === undefined ? null : previous;
  }

  get state(): ResponseState {
    if (this.error === null && this.data === null) {
      return ResponseState.Loading;
    } else if (this.error == null && this.data !== null) {
      return ResponseState.Loaded;
    }
    return ResponseState.Error;
  }
}
