import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import {
  ActionButtons,
  PermissionType,
  PaginationResult,
} from '@fullyops/legacy/ui/ui-shared/utils/crm-types';
import { ComponentInOutAnimation } from '@fullyops/legacy/ui/ui-shared/utils/component-base-animation';
import { ResponseState } from '@fullyops/legacy/data';
import { BehaviorSubject } from 'rxjs';
import { PartResponse } from '@fullyops/legacy/data/api/types/Part';

@Component({
  selector: 'crm-parts',
  templateUrl: './parts.component.html',
  styleUrls: ['./parts.component.scss'],
  animations: [ComponentInOutAnimation.AnimTrigger],
})
export class PartsComponent implements OnInit {
  @Input() title: string;
  @Input() listPartsPagination$ = new BehaviorSubject<
    PaginationResult<PartResponse>
  >(null);
  @Input() filters$ = new BehaviorSubject<{ [key: string]: string }>({});
  @Output() openEvent = new EventEmitter<string>();
  @Output() addNewEvent = new EventEmitter();
  @Output() pageChangeEvent = new EventEmitter<number>();
  @Output() filtersChangeEvent = new EventEmitter<{}>();

  // Response Possible States
  state = ResponseState;

  actions: ActionButtons = {};
  partsList$ = new BehaviorSubject<any[]>([]);

  displayedColumns = ['name', 'price'];

  ngOnInit() {
    this.initActionButtons();
  }

  initActionButtons() {
    this.actions.primary = {
      label: 'addNew',
      color: 'primary',
      permissions: [PermissionType.CAN_EDIT_PARTS],
    };
  }

  onActionEvent(event: string) {
    if (event === 'addNew') {
      this.onAddNew();
    }
  }

  onOpen(id: string) {
    this.openEvent.emit(id);
  }

  onAddNew() {
    this.addNewEvent.emit();
  }

  onFiltersChange(filters) {
    this.filtersChangeEvent.emit(filters);
  }

  onPageChange(pageIndex: number) {
    this.pageChangeEvent.emit(pageIndex);
  }
}
