import { Response } from '../Response';
import { Endpoint } from '../Endpoint';
import { HttpClient } from '@angular/common/http';
import { IListResponse } from '../IListResponse';
import { Injectable, Inject } from '@angular/core';
import { API_SERVICE_CONFIG } from '../ApiServiceConfig';

import { Observable, BehaviorSubject } from 'rxjs';

import { IApiServiceConfig } from '../IApiServiceConfig';
import { map, tap } from 'rxjs/operators';
import { InterventionAnomaly } from '../../models/v0/InterventionAnomaly';
import { ISerialisedInterventionAnomaly } from '../../models/v0/serialisations/ISerialisedInterventionAnomaly';
import { UnsavedInterventionAnomaly } from '@fullyops/legacy/data';

type InterventionAnomalyList = IListResponse<ISerialisedInterventionAnomaly>;

@Injectable()
export class InterventionsAnomalyEndpoint extends Endpoint<
  InterventionAnomaly,
  ISerialisedInterventionAnomaly
> {
  private readonly url = this.config.url + 'interventions/anomalies';

  constructor(
    @Inject(API_SERVICE_CONFIG) private config: IApiServiceConfig,
    private http: HttpClient
  ) {
    super(InterventionAnomaly.fromSerialised);
  }

  getAll(
    filters: {},
    source?: Observable<InterventionAnomalyList>,
    store?: BehaviorSubject<Response<InterventionAnomaly[]>>
  ): Observable<Response<InterventionAnomaly[]>> {
    let query = '';
    Object.keys(filters).forEach((key) => (query += `&${key}=${filters[key]}`));

    const defaultSource = this.http.get<InterventionAnomalyList>(
      `${this.url}?size=100&page=0${query}`
    );
    return this.list(source || defaultSource, store);
  }

  get(
    id: string,
    source?: Observable<ISerialisedInterventionAnomaly>,
    store?: BehaviorSubject<Response<InterventionAnomaly[]>>
  ): Observable<Response<InterventionAnomaly>> {
    const defaultSource = this.http.get<ISerialisedInterventionAnomaly>(
      `${this.url}${id}/`
    );
    return this.retrieve(source || defaultSource, store);
  }

  post(
    interventionAnomaly: UnsavedInterventionAnomaly,
    source?: Observable<ISerialisedInterventionAnomaly>,
    store?: BehaviorSubject<Response<InterventionAnomaly[]>>
  ): Observable<Response<InterventionAnomaly>> {
    const body = {
      description: interventionAnomaly.description,
      interventionId: interventionAnomaly.interventionId,
    };
    const defaultSource = this.http.post<ISerialisedInterventionAnomaly>(
      this.url,
      body
    );
    return this.add(source || defaultSource, store);
  }

  patch(
    id: string,
    part: UnsavedInterventionAnomaly,
    source?: Observable<ISerialisedInterventionAnomaly>,
    store?: BehaviorSubject<Response<InterventionAnomaly[]>>
  ): Observable<Response<InterventionAnomaly>> {
    const { ...body } = part.toSerialised();
    const defaultSource = this.http.patch<ISerialisedInterventionAnomaly>(
      `${this.url}${id}/`,
      body
    );

    return defaultSource.pipe(
      tap((instance) =>
        this.update(
          source || defaultSource,
          InterventionAnomaly.fromSerialised(instance),
          store
        )
      ),
      map(
        (serialiser) =>
          new Response(InterventionAnomaly.fromSerialised(serialiser))
      )
    );
  }

  delete(
    interventionId: string,
    anomalyId: string,
    clientEquipmentId: string,
    source?: Observable<{}>,
    store?: BehaviorSubject<Response<InterventionAnomaly[]>>
  ): Observable<Response<{}>> {
    const defaultSource = this.http.delete<{}>(
      `${this.url}/${interventionId}/${anomalyId}/${clientEquipmentId}/`
    );
    return this.remove(
      source || defaultSource,
      interventionId + anomalyId + clientEquipmentId,
      store
    );
  }
}
