import { Response } from '../Response';
import { Endpoint } from '../Endpoint';
import { HttpClient } from '@angular/common/http';
import { IListResponse } from '../IListResponse';
import { Injectable, Inject } from '@angular/core';
import { API_SERVICE_CONFIG } from '../ApiServiceConfig';

import { Observable, BehaviorSubject } from 'rxjs';

import { Permission } from '../../models/v0/Permission';
import { ISerialisedPermission } from '../../models/v0/serialisations/ISerialisedPermission';
import { IApiServiceConfig } from '../IApiServiceConfig';
import { UnsavedPermission } from '../../models/v0/UnsavedPermission';
import { tap } from 'rxjs/operators';

type PermissionList = IListResponse<ISerialisedPermission>;

@Injectable()
export class PermissionsEndpoint extends Endpoint<
  Permission,
  ISerialisedPermission
> {
  private readonly url = this.config.url + 'permissions/';

  constructor(
    @Inject(API_SERVICE_CONFIG) private config: IApiServiceConfig,
    private http: HttpClient
  ) {
    super(Permission.fromSerialised);
  }

  getAll(
    source?: Observable<PermissionList>,
    store?: BehaviorSubject<Response<Permission[]>>
  ): Observable<Response<Permission[]>> {
    const defaultSource = this.http.get<PermissionList>(this.url);
    return this.list(source || defaultSource, store);
  }

  get(
    id: string,
    source?: Observable<ISerialisedPermission>,
    store?: BehaviorSubject<Response<Permission[]>>
  ): Observable<Response<Permission>> {
    const defaultSource = this.http.get<ISerialisedPermission>(
      `${this.url}${id}/`
    );
    return this.retrieve(source || defaultSource, store);
  }

  post(
    user: UnsavedPermission,
    source?: Observable<ISerialisedPermission>,
    store?: BehaviorSubject<Response<Permission[]>>
  ): Observable<Response<Permission>> {
    const defaultSource = this.http.post<ISerialisedPermission>(
      this.url,
      user.toSerialised()
    );
    return this.add(source || defaultSource, store);
  }

  patch(
    id: string,
    user: UnsavedPermission,
    source?: Observable<ISerialisedPermission>,
    store?: BehaviorSubject<Response<Permission[]>>
  ) {
    const defaultSource = this.http.patch<ISerialisedPermission>(
      `${this.url}${id}/`,
      user
    );

    return defaultSource.pipe(
      tap((instance) =>
        this.update(
          source || defaultSource,
          Permission.fromSerialised(instance),
          store
        )
      )
    );
  }

  delete(
    id: string,
    source?: Observable<{}>,
    store?: BehaviorSubject<Response<Permission[]>>
  ): Observable<Response<{}>> {
    const defaultSource = this.http.delete<{}>(`${this.url}${id}/`);
    return this.remove(source || defaultSource, id, store);
  }
}
