import { UnsavedUser } from '@fullyops/legacy/data';
import { ISerialisedUnsavedInterventionPart } from './serialisations/ISerialisedInterventionPart';
import { UnsavedPart } from './UnsavedPart';

export class UnsavedInterventionPart {
  constructor(
    readonly id: string,
    readonly createdAt: string,
    readonly createdBy: UnsavedUser,
    readonly interventionId: string,
    readonly part: UnsavedPart,
    readonly quantity: number,
    readonly updatedAt: string,
    readonly updatedBy: UnsavedUser
  ) {}

  static fromSerialised(serialised: ISerialisedUnsavedInterventionPart) {
    const part = UnsavedPart.fromSerialised(serialised.part);
    const createdBy = UnsavedUser.fromSerialised(serialised.createdBy);
    const updatedBy = UnsavedUser.fromSerialised(serialised.updatedBy);

    return new UnsavedInterventionPart(
      undefined,
      undefined,
      createdBy,
      serialised.interventionId,
      part,
      serialised.quantity,
      undefined,
      updatedBy
    );
  }

  toSerialised(): ISerialisedUnsavedInterventionPart {
    const partObj = this.part.toSerialised();
    const createdByObj = this.createdBy.toSerialised();
    const updatedByObj = this.updatedBy.toSerialised();

    return {
      ...this,
      part: partObj,
      createdBy: createdByObj,
      updatedBy: updatedByObj,
    };
  }
  clone(
    partialUnsavedInterventionPart: PartialUnsavedInterventionPart
  ): UnsavedInterventionPart {
    const resolve = (key: keyof UnsavedInterventionPart) =>
      partialUnsavedInterventionPart.hasOwnProperty(key)
        ? partialUnsavedInterventionPart[key]
        : this[key];

    return new UnsavedInterventionPart(
      resolve('id'),
      resolve('createdAt'),
      resolve('createdBy'),
      resolve('interventionId'),
      resolve('part'),
      resolve('quantity'),
      resolve('updatedAt'),
      resolve('updatedBy')
    );
  }
}
type PartialUnsavedInterventionPart = Partial<
  Pick<
    UnsavedInterventionPart,
    | 'id'
    | 'createdAt'
    | 'createdBy'
    | 'interventionId'
    | 'part'
    | 'quantity'
    | 'updatedAt'
    | 'updatedBy'
  >
>;
