import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { API_SERVICE_CONFIG } from '../ApiServiceConfig';
import { Endpoint } from '../Endpoint';
import { IListResponse } from '../IListResponse';
import { Response } from '../Response';

import { BehaviorSubject, Observable } from 'rxjs';

import { map, tap } from 'rxjs/operators';
import { Activity } from '../../models/v0/Activity';
import { ISerialisedActivity } from '../../models/v0/serialisations/ISerialisedActivity';
import { UnsavedActivity } from '../../models/v0/UnsavedActivity';
import { IApiServiceConfig } from '../IApiServiceConfig';

type ActivityList = IListResponse<ISerialisedActivity>;

export interface Filters {
  readonly assignee?: string;
  readonly company?: string;
  readonly contact?: string;
  readonly ticketId?: string;
  readonly ticketType?: string;
  orderBy?: string;
}

@Injectable()
export class ActivitiesEndpoint extends Endpoint<
  Activity,
  ISerialisedActivity
> {
  private readonly url = this.config.url + 'activities';

  constructor(
    @Inject(API_SERVICE_CONFIG) private config: IApiServiceConfig,
    private http: HttpClient
  ) {
    super(Activity.fromSerialised);
  }

  getAll(
    filters?: Filters,
    source?: Observable<ActivityList>,
    store?: BehaviorSubject<Response<Activity[]>>
  ): Observable<Response<Activity[]>> {
    if (!filters.orderBy) filters.orderBy = 'NAME';
    let filtersStr = '';
    Object.keys(filters).forEach(
      (key) => (filtersStr += `&${key}=${filters[key]}`)
    );

    const defaultSource = this.http.get<ActivityList>(
      `${this.url}?&pageNumber=0${filtersStr}`
    );
    return this.list(source || defaultSource, store);
  }

  get(
    id: string,
    source?: Observable<ISerialisedActivity>,
    store?: BehaviorSubject<Response<Activity[]>>
  ): Observable<Response<Activity>> {
    const defaultSource = this.http.get<ISerialisedActivity>(
      `${this.url}/${id}/`
    );
    return this.retrieve(source || defaultSource, store);
  }

  post(
    activity: UnsavedActivity,
    source?: Observable<ISerialisedActivity>,
    store?: BehaviorSubject<Response<Activity[]>>
  ): Observable<Response<Activity>> {
    const defaultSource = this.http.post<ISerialisedActivity>(
      this.url,
      activity.toSerialised()
    );
    return this.add(source || defaultSource, store);
  }

  patch(
    id: string,
    activity: UnsavedActivity,
    source?: Observable<ISerialisedActivity>,
    store?: BehaviorSubject<Response<Activity[]>>
  ) {
    const defaultSource = this.http.patch<ISerialisedActivity>(
      `${this.url}/${id}/`,
      activity
    );

    return defaultSource.pipe(
      tap((instance) =>
        this.update(
          source || defaultSource,
          Activity.fromSerialised(instance),
          store
        )
      ),
      map((serialiser) => new Response(Activity.fromSerialised(serialiser)))
    );
  }

  delete(
    id: string,
    source?: Observable<{}>,
    store?: BehaviorSubject<Response<Activity[]>>
  ): Observable<Response<{}>> {
    const defaultSource = this.http.delete<{}>(`${this.url}/${id}/`);
    return this.remove(source || defaultSource, id, store);
  }
}
