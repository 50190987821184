import {
  InterventionPatchRequest,
  InterventionPostRequest,
  InterventionResponse,
  InterventionTypeResponse,
  InterventionUncataloguedPartResponse,
} from '@fullyops/legacy/data/api/types/Intervention';
import {
  Component,
  Input,
  EventEmitter,
  Output,
  OnInit,
  ChangeDetectorRef,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { PartUncataloguedControllerV2 } from '../../ui-part-uncatalogued-controller-v2.service';
import { TenantConfigurationController } from '../../ui-tenant-configuration-v2-controller.service';
import { PartControllerV2 } from '../../ui-part-controller-v2.service';
import { UiAuthService } from '../../ui-auth.service';
import { PermissionsType } from '@fullyops/legacy/ui/ui-shared/utils/crm-types';
import { InterventionTypeController } from '../../ui-intervention-type-controller-v2.service';
import { AbstractControl, FormControl } from '@angular/forms';
import { SelectOptionType } from '@fullyops/legacy/ui/ui-shared/form-select/form-select.component';
import { AnomalyController } from '../../ui-anomaly-controller.service';
import {
  ActionCorrectiveForm,
  CreateCorrectiveActionType,
  EditCorrectiveAction,
} from '../actions-forms/action-corrective-form/action-corrective-form.component';

import { SupportTicketResponse } from '@fullyops/legacy/data/api/types/SupportTicket';
import { ClientEquipmentResponse } from '@fullyops/legacy/data/api/types/ClientEquipment';
import { AnomalyResponse } from '@fullyops/legacy/data/api/types/Anomaly';
import {
  PartPostRequest,
  PartResponse,
} from '@fullyops/legacy/data/api/types/Part';
import { BehaviorSubject } from 'rxjs';
import { InterventionControllerV2 } from '@fullyops/legacy/ui/ui-crm/ui-intervention-controller-v2.service';
import { ClientEquipmentControllerV2 } from '@fullyops/legacy/ui/ui-crm/ui-client-equipment-controller-v2.service';
import {
  ActionPreventiveForm,
  CreatePreventiveActionType,
  EditPreventiveAction,
} from '../actions-forms/action-preventive-form/action-preventive-form.component';
import {
  ActionGeneralForm,
  CreateGeneralAction,
  EditGeneralAction,
} from '@fullyops/legacy/ui/ui-crm/work-order-action/actions-forms/action-general-form/action-general-form.component';
import { Part } from '@fullyops/legacy/data/index';
import { AutocompleteListItemType } from '@fullyops/legacy/ui/ui-shared/form-autocomplete-list/form-autocomplete-list.component';
import { UncataloguedPartPostRequest } from '@fullyops/legacy/data/api/types/UncataloguedPart';

export interface ActionCreatePart {
  partName: string;
  formControl: FormControl<Array<AutocompleteListItemType>>;
  addFormControl: AbstractControl<any, any>;
}

export interface ActionCreateUncataloguedPart {
  partName: string;
  formControl: FormControl<Array<AutocompleteListItemType>>;
  addFormControl: AbstractControl<any, any>;
}

export interface InterventionData {
  description: string;
  userObservation: string;
  internalObservation: string;
  clientEquipment: ClientEquipmentResponse;
  anomalies: AnomalyResponse[];
  parts: PartResponse[];
  uncataloguedParts: InterventionUncataloguedPartResponse[];
}

export interface PartWithOwner extends PartResponse {
  owner: { name: string; id: string };
}

export type RequestPartsUncataloguedType = {
  name?: string;
};

export type RequestGeneralPartsType = {
  name?: string;
};

@Component({
  selector: 'crm-work-order-actions',
  templateUrl: './work-order-actions.component.html',
  styleUrls: ['./work-order-actions.component.scss'],
})
export class WorkOrderActionsComponent implements OnInit {
  actionTypes$ = new BehaviorSubject<Array<InterventionTypeResponse>>([]);
  clientEquipmentListOptions$: BehaviorSubject<SelectOptionType[]> =
    new BehaviorSubject([]);
  anomaliesOptions$ = new BehaviorSubject<SelectOptionType[]>([]);
  partsOptions$ = new BehaviorSubject<SelectOptionType[]>([]);
  uncataloguedPartsOptions$ = new BehaviorSubject<SelectOptionType[]>([]);
  permissionToCreateAction: PermissionsType[] = ['CAN_CREATE_INTERVENTIONS'];
  permissionToSeeIntervention: PermissionsType[] = [
    'CAN_ACCESS_OWN_INTERVENTIONS',
    'CAN_ACCESS_INTERVENTIONS',
  ];

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    protected interventionControllerV2: InterventionControllerV2,
    protected partUncataloguedControllerV2: PartUncataloguedControllerV2,
    private tenantConfigurationController: TenantConfigurationController,
    protected partControllerV2: PartControllerV2,
    public auth: UiAuthService,
    public interventionTypeController: InterventionTypeController,
    private clientEquipmentController: ClientEquipmentControllerV2,
    private anomalyController: AnomalyController
  ) {}
  @Output() openRequestTab = new EventEmitter<any>();
  @Input() ticketId: string;
  @Input() ticket$: BehaviorSubject<SupportTicketResponse>;
  @Input() isDetailPage: boolean;

  @Input() openedIndex: number;
  @Output() setAccordionTabIndex = new EventEmitter<any>();

  @ViewChildren(ActionCorrectiveForm)
  actionCorrectiveForms: QueryList<ActionCorrectiveForm>;

  @ViewChildren(ActionPreventiveForm)
  actionPreventiveForm: QueryList<ActionPreventiveForm>;

  @ViewChildren(ActionGeneralForm)
  actionGeneralForm: QueryList<ActionGeneralForm>;

  showUncataloguedPart$ = new BehaviorSubject<boolean>(null);

  ngOnInit(): void {
    this.initCLientEquipments(this.ticket$.value.company.id);
    this.initAnomaliesList();
    this.initPartsList();
    this.initActionTypes();
    this.setUncataloguedPartConfiguration();
  }

  setUncataloguedPartConfiguration() {
    this.tenantConfigurationController
      .getConfigurations({ groupLabel: 'SUPPORT_TICKET_PIPELINE' })
      .subscribe((res) => {
        const filteredConfigurations = res.results.filter(({ deprecated }) =>
          deprecated ? false : true
        );

        const ENABLE_UNCATALOGUED_PARTS = filteredConfigurations.find(
          ({ label }) => label == 'ENABLE_UNCATALOGUED_PARTS'
        );

        if (ENABLE_UNCATALOGUED_PARTS.value == 'true') {
          this.showUncataloguedPart$.next(true);
          this.initUncataloguedPartOptions();
        } else {
          this.showUncataloguedPart$.next(false);
        }
      });
  }

  getDirtyActionForm() {
    const interventionType = this.ticket$.value.interventionType;

    if (interventionType == 'CORRECTIVE') {
      return this.actionCorrectiveForms.filter(
        ({ formGroup }) => formGroup.dirty
      );
    }
    if (interventionType == 'GENERAL') {
      return this.actionGeneralForm.filter(({ formGroup }) => formGroup.dirty);
    }
    if (interventionType == 'PREVENTIVE') {
      return this.actionPreventiveForm.filter(
        ({ formGroup }) => formGroup.dirty
      );
    }
  }

  setOpenedIndex(index) {
    this.setAccordionTabIndex.emit(index);
  }

  loadInterventions(props: { interventionIdToOpenAfterLoad?: string }) {
    this.interventionControllerV2
      .getInterventions({
        queryParameters: {
          supportTicketId: this.ticketId,
          orderBy: 'CREATED_AT',
        },
      })
      .subscribe((response) => {
        const newTicket = { ...this.ticket$.value };
        newTicket.interventions = response.results;
        this.ticket$.next(newTicket);

        if (props.interventionIdToOpenAfterLoad) {
          const index = this.ticket$.value.interventions.findIndex(
            (intervention) =>
              intervention.id == props.interventionIdToOpenAfterLoad
          );
          // this.setOpenedIndex(-2);
        }
        this.setOpenedIndex(-2);
      });
  }

  addIntervention(intervention: InterventionPostRequest) {
    this.interventionControllerV2
      .createIntervention({
        intervention,
      })
      .subscribe(() => {
        this.loadInterventions({});
        this.gotoTop();
      });
  }

  editIntervention(intervention: InterventionPatchRequest, index: number) {
    this.interventionControllerV2
      .updateInterventionById({
        intervention,
      })
      .subscribe((interventionResponse) => {
        const newTicket = { ...this.ticket$.value };
        newTicket.interventions[index] = interventionResponse;
        this.ticket$.next(newTicket);
        this.setOpenedIndex(-2);
        this.gotoTop();
      });
  }

  deleteIntervention(index: number) {
    const currentIntervention = this.ticket$.value.interventions[index];

    this.interventionControllerV2
      .deleteInterventionById({ id: currentIntervention.id })
      .subscribe(() => {
        const newTicket = { ...this.ticket$.value };
        newTicket.interventions.splice(index, 1);
        this.ticket$.next(newTicket);
        this.setOpenedIndex(-2);
        this.gotoTop();
      });
  }

  navigateToRequestPartTab() {
    this.changeDetectorRef.checkNoChanges();
    this.openRequestTab.emit();
  }

  gotoTop() {
    document
      .querySelector('.mat-sidenav-content')
      .scrollTo({ behavior: 'smooth', left: 0, top: 0 });
  }

  createCorrectiveAction({
    intervention,
    correctiveActionForm,
  }: CreateCorrectiveActionType) {
    this.interventionControllerV2
      .createIntervention({
        intervention,
      })
      .subscribe(() => {
        this.loadInterventions({});
        this.gotoTop();
        correctiveActionForm.initFormValues();
      });
  }

  editCorrectiveAction({ intervention }: EditCorrectiveAction) {
    this.interventionControllerV2
      .updateInterventionById({ intervention })
      .subscribe((res) => {
        this.onEditSuccessUpdateActionOnTicketState(res);
      });
  }

  createPreventiveAction({
    intervention,
    preventiveActionForm,
  }: CreatePreventiveActionType) {
    this.interventionControllerV2
      .createIntervention({
        intervention,
      })
      .subscribe(() => {
        this.loadInterventions({});
        this.gotoTop();
        preventiveActionForm.reset({
          description: '',
          internalObservation: '',
          equipment: null,
          parts: [],
          uncataloguedParts: [],
          userObservation: '',
        });
      });
  }

  editPreventiveAction({ intervention }: EditPreventiveAction) {
    this.interventionControllerV2
      .updateInterventionById({ intervention })
      .subscribe((res) => {
        this.onEditSuccessUpdateActionOnTicketState(res);
      });
  }

  createGeneralAction({
    intervention,
    generalActionForm,
  }: CreateGeneralAction) {
    this.interventionControllerV2
      .createIntervention({
        intervention,
      })
      .subscribe(() => {
        this.loadInterventions({});
        this.gotoTop();
        generalActionForm.reset({
          anomalies: [],
          description: '',
          internalObservation: '',
          equipment: null,
          parts: [],
          uncataloguedParts: [],
          userObservation: '',
        });
      });
  }

  editGeneralAction({ intervention }: EditGeneralAction) {
    this.interventionControllerV2
      .updateInterventionById({ intervention })
      .subscribe((res) => {
        this.onEditSuccessUpdateActionOnTicketState(res);
      });
  }

  onEditSuccessUpdateActionOnTicketState(action: InterventionResponse) {
    const newValue = { ...this.ticket$.value };
    const actionIndex = newValue.interventions.findIndex(
      ({ id }) => id == action.id
    );

    if (actionIndex !== -1) {
      newValue.interventions[actionIndex] = action;
    }
    this.ticket$.next(newValue);
  }

  createMachineByPopUp(machineFormControl: FormControl) {
    this.clientEquipmentController.createMachineByPopUp({
      companyId: this.ticket$.value.company.id,
      machineFormControl,
    });
  }

  initCLientEquipments(companyId: string) {
    this.clientEquipmentController
      .getClientEquipmentListOptions$({ companyId })
      .subscribe((res) => this.clientEquipmentListOptions$.next(res));
  }

  initAnomaliesList() {
    this.anomalyController.getAnomaliesSelectOptions$().subscribe((res) => {
      this.anomaliesOptions$.next(res);
    });
  }

  initPartsList(name = undefined) {
    const params = name ? { name } : {};
    this.partControllerV2.getPartsOptionsList$(params).subscribe((res) => {
      this.partsOptions$.next(res);
    });
  }

  initUncataloguedPartOptions(name = undefined) {
    const params = name ? { name } : {};
    this.partUncataloguedControllerV2
      .getUncataloguedPartsOptionsList$(params)
      .subscribe((res) => {
        this.uncataloguedPartsOptions$.next(res);
      });
  }

  initActionTypes() {
    this.interventionTypeController
      .getInterventionType$({})
      .subscribe((res) => {
        this.actionTypes$.next(res);
      });
  }

  createPart({ partName, formControl, addFormControl }: ActionCreatePart) {
    const newPart: PartPostRequest = {
      name: partName,
      barCode: null,
      category: null,
      discontinued: false,
      externalId: null,
      price: 0,
      ref: partName,
      suppliers: null,
      unitMeasureType: null,
    };

    this.partControllerV2.createPart({ newPart }).subscribe((partResponse) => {
      const newValue = [...formControl.value];
      newValue.push({ quantity: 1, itemData: partResponse });
      formControl.setValue(newValue);
      formControl.markAsDirty();
      addFormControl.setValue('');
      this.changeDetectorRef.detectChanges();
    });
  }

  createUncataloguedPart({
    partName,
    formControl,
    addFormControl,
  }: ActionCreateUncataloguedPart) {
    const newFreePart: UncataloguedPartPostRequest = {
      name: partName,
    };

    this.partUncataloguedControllerV2
      .create({ newFreePart })
      .subscribe((partResponse) => {
        const newValue = [...formControl.value];
        newValue.push({ quantity: 1, itemData: partResponse });
        formControl.setValue(newValue);
        formControl.markAsDirty();
        addFormControl.setValue('');
        this.changeDetectorRef.detectChanges();
      });
  }
}
