<div class="variants-container">
  <button
    *ngIf="!preview && !formGroup"
    mat-button
    type="button"
    class="new-variant primary"
    (click)="addVariant()"
  >
    <span> <mat-icon>add</mat-icon>Add Variant </span>
  </button>
  <ng-container *ngIf="variants">
    <button
      class="card-link"
      mat-button
      type="button"
      *ngFor="let variant of variants"
      (click)="selectVariant(variant.id)"
    >
      <mat-card [class.selected]="variant.id === selectedVariant">
        <img
          *ngIf="!variant.imageUri"
          mat-card-image
          src="assets/images/not-found.png"
          [alt]="variant.name"
        />
        <img
          *ngIf="variant.imageUri"
          mat-card-image
          [src]="variant.imageUri"
          [alt]="variant.name"
        />
        <mat-card-content>
          <mat-card-title>{{ variant.name }}</mat-card-title>
          <mat-card-subtitle>
            <p>Product Variant</p>
            <p><b>Price:</b> {{ variant.price }} €</p>
            <p><b>Stock:</b> {{ variant.stock }} Unt.</p>
          </mat-card-subtitle>
          <p>
            {{ variant.description }}
          </p>
        </mat-card-content>
        <mat-icon class="variant-check">check_circle_outline</mat-icon>
      </mat-card>
    </button>
  </ng-container>
</div>
