import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import {
  Activity,
  ActivityType,
  ActivityLinkType,
} from '@fullyops/legacy/data';
import { ComponentInOutAnimation } from '@fullyops/legacy/ui/ui-shared/utils/component-base-animation';
import { PermissionType } from '@fullyops/legacy/ui/ui-shared/utils/crm-types';

interface ActivityItem extends Activity {
  readonly icon: string;
  readonly completed: boolean;
}

@Component({
  selector: 'crm-history-activity',
  templateUrl: './history-activity.component.html',
  styleUrls: ['./history-activity.component.scss'],
  animations: [ComponentInOutAnimation.AnimTrigger],
})
export class HistoryActivityComponent implements OnInit {
  @Input() activity: ActivityItem;
  @Output() openActivityEvent = new EventEmitter<string>();
  @Output() openTicketEvent = new EventEmitter<{
    ticketId: string;
    ticketType: ActivityLinkType;
  }>();

  activityPermissions = [
    PermissionType.CAN_ACCESS_ACTIVITIES,
    PermissionType.CAN_ACCESS_OWN_ACTIVITIES,
  ];
  ticketPermissions = [];
  ActivityType = ActivityType;

  ngOnInit() {
    switch (this.activity.activityType) {
      case ActivityLinkType.REQUEST:
        this.ticketPermissions.push(
          PermissionType.CAN_ACCESS_REQUEST_TICKETS,
          PermissionType.CAN_ACCESS_OWN_REQUEST_TICKETS
        );
        break;
      case ActivityLinkType.SUPPORT:
        this.ticketPermissions.push(PermissionType.CAN_ACCESS_SUPPORT_TICKETS);
        break;
      default:
        break;
    }
  }

  openActivity(activityId: string) {
    this.openActivityEvent.emit(activityId);
  }

  openTicket(ticketId: string, ticketType: ActivityLinkType) {
    this.openTicketEvent.emit({ ticketId, ticketType });
  }
}
