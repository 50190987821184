import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as _ from 'lodash';
import { PaginationResult } from '../utils/crm-types';

@Component({
  selector: 'crm-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
})
export class PaginatorComponent implements OnInit {
  @Input() paginatedList$ = new BehaviorSubject<PaginationResult<any> | null>(
    null
  );
  @Output() onPageChange = new EventEmitter<number>();

  pages$ = new BehaviorSubject<number[]>([]);

  ngOnInit() {
    this.paginatedList$.subscribe(({ currentPage, totalPages }) => {
      // Set pages obtained so far, start with 1 since we have currentPage
      let pagesCount = 1;
      // Maintain a copy of pagesCount.
      // Used to detect whether any new pages were added in the iteration
      let newPagesCount = 1;
      // Set beginning and end as currentPage
      let start = currentPage + 1,
        end = currentPage + 1;
      // Continue iteration till enough pages are obtained
      while (pagesCount < 5) {
        if (end + 1 <= totalPages) {
          // Ok to take one more page towards end
          end++;
          newPagesCount++;
        }
        if (start - 1 > 0) {
          //Ok to take one more page towards start
          start--;
          newPagesCount++;
        }
        /* 
           Break loop if no additional pages were 
           obtained in this iteration
           We have obtained maximum number of possible pages
        */
        if (newPagesCount == pagesCount) break;
        else pagesCount = newPagesCount;
      }

      this.pages$.next(_.range(start, start + pagesCount));
    });
  }

  handlePageChange(pageIndex: number) {
    this.onPageChange.emit(pageIndex);
  }

  handleFirstPage() {
    this.handlePageChange(0);
  }

  handlePrevPage() {
    this.handlePageChange(this.paginatedList$.value.currentPage - 1);
  }

  handleNextPage() {
    this.handlePageChange(this.paginatedList$.value.currentPage + 1);
  }

  handleLastPage() {
    this.handlePageChange(this.paginatedList$.value.totalPages - 1);
  }
}
