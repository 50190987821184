import {
  ISerialisedProductConfig,
  ISerialisedProductConfigListItem,
} from './serialisations/ISerialisedProductConfig';
import { Product } from './Product';
import { ProductConfig } from './ProductConfig';

export class ProductConfigListItem {
  constructor(
    readonly configurations: ProductConfig[],
    readonly product: Product
  ) {}

  static fromSerialised(serialised: ISerialisedProductConfigListItem) {
    const configurations = serialised.configurations.map(
      ProductConfig.fromSerialised
    );
    const product = Product.fromSerialised(serialised.product);

    return new ProductConfigListItem(configurations, product);
  }

  toSerialised(): ISerialisedProductConfigListItem {
    const configurationsObj = this.configurations.map((config) =>
      config.toSerialised()
    );
    const productObj = this.product.toSerialised();

    return {
      configurations: configurationsObj,
      product: productObj,
    };
  }

  clone(partialProduct: PartialProductConfigListItem): ProductConfigListItem {
    const resolve = (key: keyof ProductConfigListItem) =>
      partialProduct.hasOwnProperty(key) ? partialProduct[key] : this[key];

    return new ProductConfigListItem(
      resolve('configurations'),
      resolve('product')
    );
  }
}

type PartialProductConfigListItem = Partial<
  Pick<ProductConfigListItem, 'configurations' | 'product'>
>;
