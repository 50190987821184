<mat-expansion-panel>
  <mat-expansion-panel-header>
    <div class="working-period-title-box">
      <div class="icon-blue icon-w30">
        <fa-icon [icon]="faBusinessTime" size="xl"></fa-icon>
      </div>
      <p class="working-period-title">{{ 'forms:workPeriod' | i18nextCap }}</p>
    </div>
  </mat-expansion-panel-header>
  <div class="working-period">
    <div>
      <div
        class="row no-gutters working-period-item align-items-center"
        *ngFor="
          let workingPeriod of this.ticket$.value.workPeriods;
          let i = index
        "
      >
        <div class="col-sm-3 d-flex">
          <crm-assignees-icon-list
            [assignees]="workingPeriod.assignees.map(this.mapAssigneeToUser)"
            [size]="32"
          ></crm-assignees-icon-list>
        </div>
        <div class="col-sm-7">
          <div class="working-period-detail-data-row">
            <p class="date-and-time-text">
              {{
                getDiffTimeFormatted(
                  workingPeriod.endingHour,
                  workingPeriod.startingHour
                ) +
                  ' - ' +
                  formatTimeString(workingPeriod.startingHour) +
                  ' ' +
                  formatDateString(workingPeriod.startingHour)
              }}
            </p>
          </div>
        </div>
        <div class="col-2" *ngIf="!isDetailPage">
          <div class="d-flex justify-content-end">
            <div
              class="custom-button-icon custom-button-icon-red"
              (click)="removeWorkPeriod(workingPeriod.id)"
              [attr.data-testid]="'delete-work-period'"
            >
              <mat-icon>clear</mat-icon>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="!isCreatingNewWorkPeriod && !isDetailPage">
      <mat-divider></mat-divider>
      <div class="working-period-row">
        <button
          mat-flat-button
          color="primary"
          class="full-width"
          (click)="openNewWorkPeriodEditor()"
          [attr.data-testid]="'button-add-work-period'"
        >
          {{ 'addWorkPeriod' | i18nextCap }}
        </button>
        <!-- <div class="working-period-title-box">
        <div class="icon-blue">
          <fa-icon [icon]="faBusinessTime" size="xl"></fa-icon>
        </div>
        <p class="working-period-title">{{ 'forms:workPeriod' | i18nextCap }}</p>
      </div>
      <div *ngIf="!isCreatingNewWorkPeriod && !isDetailPage">
        <div
          class="custom-button-icon custom-button-icon-green"
          (click)="openNewWorkPeriodEditor()"
          [attr.data-testid]="'button-add-work-period'"
        >
          <mat-icon>add</mat-icon>
        </div>
      </div> -->
      </div>
      <mat-divider></mat-divider>
    </div>

    <!-- New workperiod  -->
    <div *ngIf="isCreatingNewWorkPeriod && !isDetailPage">
      <mat-divider></mat-divider>
      <div class="working-period-row">
        <div class="remove-negative-margin new-work-period-form">
          <form
            class="remove-form-margin-and-padding"
            (ngSubmit)="createWorkPeriod()"
            [attr.data-testid]="'work-period-form'"
          >
            <crm-form-template
              [formGroup]="newWorkPeriodForm"
              [formFields]="formFields"
            >
            </crm-form-template>
            <div class="d-flex justify-content-around">
              <button
                mat-flat-button
                type="button"
                class="mat-flat-button-style custom-button-icon-red"
                name="cancel"
                (click)="closeNewWorkPeriodEditor()"
              >
                {{ 'cancel' | i18nextCap }}
              </button>
              <button
                mat-flat-button
                type="submit"
                class="mat-flat-button-style custom-button-icon-green"
                name="add"
              >
                {{ 'save' | i18nextCap }}
              </button>
            </div>
          </form>
        </div>
        <mat-divider></mat-divider>
      </div>

      <mat-divider></mat-divider>
    </div>

    <ng-container
      *ngIf="getAssigneesTotalWorkedTime() as assigneesTotalWorkedTime"
    >
      <div class="working-period-worked">
        <div
          class="working-period-worked-row"
          *ngFor="let assignee of assigneesTotalWorkedTime"
        >
          <p class="working-period-worked-employee">
            {{ getUserFullName($any(assignee)) }}
          </p>
          <p class="working-period-worked-employee">
            {{
              convertMiliSecondsToTime(assignee.totalWorkedTimeInMilliseconds)
            }}
          </p>
        </div>

        <mat-divider *ngIf="assigneesTotalWorkedTime.length > 0"></mat-divider>

        <div class="working-period-worked-row">
          <p class="working-period-worked-total-title">Total:</p>
          <p class="working-period-worked-total-value">
            {{
              convertMiliSecondsToTime(
                getTotalWorkedTime(assigneesTotalWorkedTime)
              )
            }}
          </p>
        </div>
      </div>
    </ng-container>
  </div>
</mat-expansion-panel>
