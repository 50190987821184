import { Response } from '../Response';
import { Endpoint } from '../Endpoint';
import { HttpClient } from '@angular/common/http';
import { IListResponse } from '../IListResponse';
import { Injectable, Inject } from '@angular/core';
import { API_SERVICE_CONFIG } from '../ApiServiceConfig';

import { Observable, BehaviorSubject } from 'rxjs';

import { Role } from '../../models/v0/Role';
import { ISerialisedRole } from '../../models/v0/serialisations/ISerialisedRole';
import { IApiServiceConfig } from '../IApiServiceConfig';
import { UnsavedRole } from '../../models/v0/UnsavedRole';
import { map, tap } from 'rxjs/operators';

type RoleList = IListResponse<ISerialisedRole>;

@Injectable()
export class RolesEndpoint extends Endpoint<Role, ISerialisedRole> {
  private readonly url = this.config.url + 'roles/';

  constructor(
    @Inject(API_SERVICE_CONFIG) private config: IApiServiceConfig,
    private http: HttpClient
  ) {
    super(Role.fromSerialised);
  }

  getAll(
    filters: {},
    source?: Observable<RoleList>,
    store?: BehaviorSubject<Response<Role[]>>
  ): Observable<Response<Role[]>> {
    const defaultSource = this.http.get<RoleList>(this.url);
    return this.list(source || defaultSource, store);
  }

  get(
    id: string,
    source?: Observable<ISerialisedRole>,
    store?: BehaviorSubject<Response<Role[]>>
  ): Observable<Response<Role>> {
    const defaultSource = this.http.get<ISerialisedRole>(`${this.url}${id}/`);
    return this.retrieve(source || defaultSource, store);
  }

  post(
    user: UnsavedRole,
    source?: Observable<ISerialisedRole>,
    store?: BehaviorSubject<Response<Role[]>>
  ): Observable<Response<Role>> {
    const defaultSource = this.http.post<ISerialisedRole>(
      this.url,
      user.toSerialised()
    );
    return this.add(source || defaultSource, store);
  }

  patch(
    id: string,
    user: UnsavedRole,
    source?: Observable<ISerialisedRole>,
    store?: BehaviorSubject<Response<Role[]>>
  ) {
    const defaultSource = this.http.patch<ISerialisedRole>(
      `${this.url}${id}/`,
      user
    );

    return defaultSource.pipe(
      tap((instance) =>
        this.update(
          source || defaultSource,
          Role.fromSerialised(instance),
          store
        )
      ),
      map((serialiser) => new Response(Role.fromSerialised(serialiser)))
    );
  }

  delete(
    id: string,
    source?: Observable<{}>,
    store?: BehaviorSubject<Response<Role[]>>
  ): Observable<Response<{}>> {
    const defaultSource = this.http.delete<{}>(`${this.url}${id}/`);
    return this.remove(source || defaultSource, id, store);
  }
}
