import { Injectable } from '@angular/core';
import { TicketsSaleStatusesEndpoint } from '@fullyops/legacy/data';

@Injectable({
  providedIn: 'root',
})
export class UiTicketsSalesStatusesService {
  readonly statuses$ = this.statuses.createStore();

  constructor(protected statuses: TicketsSaleStatusesEndpoint) {}

  loadAll() {
    this.loadStatuses();
  }

  loadStatuses() {
    this.statuses
      .getAll(undefined, this.statuses$)
      .subscribe({ error: console.error });
  }
}
