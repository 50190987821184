import { ISerialisedClientEquipment } from './serialisations/ISerialisedClientEquipment';
import { Company } from './Company';
import { UnsavedClientEquipment } from './UnsavedClientEquipment';
import { User } from './User';

export class ClientEquipment {
  constructor(
    readonly brand: string,
    readonly company: Company,
    readonly createdAt: string,
    readonly createdBy: User | null,
    readonly equipmentData: string,
    readonly hasWarranty: boolean,
    readonly id: string,
    readonly model: string,
    readonly serialNumber: string,
    readonly type: string,
    readonly updatedAt: string,
    readonly updatedBy: User | null,
    readonly warranty: string
  ) {
    if (brand) this.brand = brand.trim();
    if (equipmentData) this.equipmentData = equipmentData.trim();
    if (model) this.model = model.trim();
    if (serialNumber) this.serialNumber = serialNumber.trim();
    if (type) this.type = type.trim();
    if (warranty) this.warranty = warranty.trim();
  }

  static fromSerialised(serialised: ISerialisedClientEquipment) {
    if (!serialised) {
      return null;
    }

    const company = Company.fromSerialised(serialised.company);
    const createdBy = serialised.createdBy
      ? User.fromSerialised(serialised.createdBy)
      : null;
    const updatedBy = serialised.updatedBy
      ? User.fromSerialised(serialised.updatedBy)
      : null;
    const warranty = serialised.warranty ? serialised.warranty : '';

    return new ClientEquipment(
      serialised.brand,
      company,
      serialised.createdAt,
      createdBy,
      serialised.equipmentData,
      serialised.hasWarranty,
      serialised.id,
      serialised.model,
      serialised.serialNumber,
      serialised.type,
      serialised.updatedAt,
      updatedBy,
      warranty
    );
  }

  toSerialised(): ISerialisedClientEquipment {
    const serialisedCompany = this.company.toSerialised();
    const serialisedCreatedBy = this.createdBy.toSerialised();
    const serialisedUpdatedBy = this.updatedBy.toSerialised();

    return {
      brand: this.brand,
      company: serialisedCompany,
      createdAt: this.createdAt,
      createdBy: serialisedCreatedBy,
      equipmentData: this.equipmentData,
      hasWarranty: this.hasWarranty,
      id: this.id,
      model: this.model,
      serialNumber: this.serialNumber,
      type: this.type,
      updatedAt: this.updatedAt,
      updatedBy: serialisedUpdatedBy,
      warranty: this.warranty,
    };
  }

  toDraft(): UnsavedClientEquipment {
    return UnsavedClientEquipment.fromSerialised({
      ...this.toSerialised(),
      companyId: this.company.id,
    });
  }

  clone(partialClientEquipment: PartialClientEquipment): ClientEquipment {
    const resolve = (key: keyof ClientEquipment) =>
      partialClientEquipment.hasOwnProperty(key)
        ? partialClientEquipment[key]
        : this[key];
    return new ClientEquipment(
      resolve('brand'),
      resolve('company'),
      resolve('createdAt'),
      resolve('createdBy'),
      resolve('equipmentData'),
      resolve('hasWarranty'),
      resolve('id'),
      resolve('model'),
      resolve('serialNumber'),
      resolve('type'),
      resolve('updatedAt'),
      resolve('updatedBy'),
      resolve('warranty')
    );
  }
}

type PartialClientEquipment = Partial<
  Pick<
    ClientEquipment,
    | 'brand'
    | 'company'
    | 'createdAt'
    | 'createdBy'
    | 'equipmentData'
    | 'hasWarranty'
    | 'id'
    | 'model'
    | 'serialNumber'
    | 'type'
    | 'updatedAt'
    | 'updatedBy'
    | 'warranty'
  >
>;
