import { Injectable } from '@angular/core';
import { TicketsSaleProductsConfigSimulationEndpoint } from '@fullyops/legacy/data';

@Injectable({
  providedIn: 'root',
})
export class UiTicketSaleProductsConfigurationSimulationService {
  readonly store$ = this.endpoint.createStore();

  constructor(
    protected endpoint: TicketsSaleProductsConfigSimulationEndpoint
  ) {}

  loadAll(ticketId: string) {
    this.endpoint
      .getAll(ticketId)
      .subscribe((response) => this.store$.next(response));
  }
}
