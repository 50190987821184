import { ISerialisedUnsavedUserPart } from './serialisations/ISerialisedUserPart';
import { UnsavedPart } from './UnsavedPart';

export class UnsavedUserPart {
  constructor(readonly partId: string, readonly quantity: number) {}

  static fromSerialised(serialised: ISerialisedUnsavedUserPart) {
    return new UnsavedUserPart(serialised.partId, serialised.quantity);
  }

  toSerialised(): ISerialisedUnsavedUserPart {
    return {
      partId: this.partId,
      quantity: this.quantity,
    };
  }
  clone(partialUnsavedUserPart: PartialUnsavedUserPart): UnsavedUserPart {
    const resolve = (key: keyof UnsavedUserPart) =>
      partialUnsavedUserPart.hasOwnProperty(key)
        ? partialUnsavedUserPart[key]
        : this[key];

    return new UnsavedUserPart(resolve('partId'), resolve('quantity'));
  }
}
type PartialUnsavedUserPart = Partial<
  Pick<UnsavedUserPart, 'partId' | 'quantity'>
>;
