import { ISerialisedLossMotive } from './serialisations/ISerialisedLossMotive';
import { UnsavedLossMotive } from './UnsavedLossMotive';

export class LossMotive {
  constructor(
    readonly id: string,
    readonly name: string,
    readonly description: string
  ) {
    if (name) this.name = name.trim();
    if (description) this.description = description.trim();
  }

  static fromSerialised(serialised: ISerialisedLossMotive) {
    return new LossMotive(
      serialised.id,
      serialised.name,
      serialised.description
    );
  }

  toSerialised(): ISerialisedLossMotive {
    return {
      id: this.id,
      name: this.name,
      description: this.description,
    };
  }

  toDraft(): UnsavedLossMotive {
    return UnsavedLossMotive.fromSerialised({
      ...this.toSerialised(),
    });
  }

  clone(partialLossMotive: PartialLossMotive): LossMotive {
    const resolve = (key: keyof LossMotive) =>
      partialLossMotive.hasOwnProperty(key)
        ? partialLossMotive[key]
        : this[key];

    return new LossMotive(
      resolve('id'),
      resolve('name'),
      resolve('description')
    );
  }
}

type PartialLossMotive = Partial<
  Pick<LossMotive, 'id' | 'name' | 'description'>
>;
