import { User } from './User';
import { ISerialisedWorkPeriodAssignees } from './serialisations/ISerialisedWorkPeriodAssignees';

export class WorkPeriodAssignees {
  constructor(
    readonly id: string,
    readonly assignee: User,
    readonly createdAt: string,
    readonly createdBy: User,
    readonly updatedAt: string,
    readonly updatedBy: User,
    readonly workPeriodId: string
  ) {}

  static fromSerialised(serialised: ISerialisedWorkPeriodAssignees) {
    const assignee = User.fromSerialised(serialised.assignee);
    const createdBy = User.fromSerialised(serialised.createdBy);
    const updatedBy = User.fromSerialised(serialised.updatedBy);

    return new WorkPeriodAssignees(
      serialised.id,
      assignee,
      serialised.createdAt,
      createdBy,
      serialised.updatedAt,
      updatedBy,
      serialised.workPeriodId
    );
  }

  toSerialised(): ISerialisedWorkPeriodAssignees {
    const assigneeObj = this.assignee.toSerialised();
    const createdByObj = this.createdBy.toSerialised();
    const updatedByObj = this.updatedBy.toSerialised();

    return {
      ...this,
      assignee: assigneeObj,
      createdBy: createdByObj,
      updatedBy: updatedByObj,
    };
  }

  clone(
    partialWorkPeriodAssignees: PartialWorkPeriodAssignees
  ): WorkPeriodAssignees {
    const resolve = (key: keyof WorkPeriodAssignees) =>
      partialWorkPeriodAssignees.hasOwnProperty(key)
        ? partialWorkPeriodAssignees[key]
        : this[key];

    return new WorkPeriodAssignees(
      resolve('id'),
      resolve('assignee'),
      resolve('createdAt'),
      resolve('createdBy'),
      resolve('updatedAt'),
      resolve('updatedBy'),
      resolve('workPeriodId')
    );
  }
}
type PartialWorkPeriodAssignees = Partial<
  Pick<
    WorkPeriodAssignees,
    | 'id'
    | 'assignee'
    | 'createdAt'
    | 'createdBy'
    | 'updatedAt'
    | 'updatedBy'
    | 'workPeriodId'
  >
>;
