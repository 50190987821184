import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { SubProduct } from '@fullyops/legacy/data';
import { ComponentInOutAnimation } from '@fullyops/legacy/ui/ui-shared/utils/component-base-animation';
import { UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'crm-product-configuration-form-sub-products',
  templateUrl: './product-configuration-form-sub-products.component.html',
  styleUrls: ['./product-configuration-form-sub-products.component.scss'],
  animations: [ComponentInOutAnimation.AnimTrigger],
})
export class ProductConfigurationFormSubProductsComponent implements OnInit {
  @Input() preview = false;
  @Input() formGroup: UntypedFormGroup;
  @Input() subProducts$ = new BehaviorSubject<SubProduct[]>([]);

  step = 0;

  ngOnInit() {}

  onVariantSelected(index: number) {
    this.formGroup.controls[index].get('checked').setValue(true);
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }
}
