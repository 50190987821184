import { Injectable } from '@angular/core';
import { PasswordEndpoint, ResetPasswordPayload } from '@fullyops/legacy/data';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class UiPasswordService {
  constructor(protected passwordEndpoint: PasswordEndpoint) {}

  forgotPassword(username: string): Observable<{}> {
    const tenantName = window.location.host.split('.')[0].replace(':', '');
    return this.passwordEndpoint.forgotPassword(username, tenantName);
  }

  resetPassword(resetPassword: ResetPasswordPayload): Observable<{}> {
    const tenantName = window.location.host.split('.')[0].replace(':', '');
    return this.passwordEndpoint.resetPassword(resetPassword, tenantName);
  }
}
