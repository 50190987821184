import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ControlsOf } from '@fullyops/shared/util/controls-of';
import { WorkOrderCategoriesService } from '@fullyops/domain/work-order-categories/services/work-order-categories.service';
import { CreateWorkOrderCategoryModel } from '@fullyops/domain/work-order-categories/models/work-order-category.model';
import { slugify } from 'transliteration';

@Component({
  selector: 'create-work-order-categories',
  templateUrl: './create-work-order-categories.component.html',
  styleUrls: ['./create-work-order-categories.component.scss'],
})
export class CreateWorkOrderCategoriesComponent implements OnInit {
  @Output() workOrderCategoryCreated$ = new EventEmitter<void>();

  formGroup = new FormGroup<ControlsOf<CreateWorkOrderCategoryModel>>({
    label: new FormControl<string>('', [
      Validators.required,
      this.duplicateCategoryLabelValidator(),
    ]),
    name: new FormControl<string>('', [
      Validators.required,
      this.duplicateCategoryNameValidator(),
    ]),
    color: new FormControl<string | null>(null, [
      Validators.pattern('^#[a-f0-9]{6}'),
    ]),
    externalId: new FormControl<string | null>(null, [
      this.duplicateCategoryExternalIdValidator(),
    ]),
  });

  constructor(private service: WorkOrderCategoriesService) {}

  ngOnInit(): void {
    this.formGroup.controls.name.valueChanges.subscribe((name) => {
      if (!this.formGroup.controls.label.dirty) {
        this.formGroup.controls.label.patchValue(
          slugify(name, { lowercase: true, separator: '_' })
        );
      }
    });
  }

  onSubmit() {
    if (this.formGroup.valid) {
      this.service.create(this.formGroup.getRawValue()).subscribe({
        complete: () => this.workOrderCategoryCreated$.emit(),
      });
    }
  }

  onCancel() {}

  private duplicateCategoryLabelValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const categoryLabel = control.value;
      const categoryExists = this.service.existsByLabel(categoryLabel);
      return categoryExists
        ? { duplicateCategory: { value: categoryLabel } }
        : null;
    };
  }

  private duplicateCategoryNameValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const categoryName = control.value;
      const categoryExists = this.service.existsByName(categoryName);
      return categoryExists
        ? { duplicateCategory: { value: categoryName } }
        : null;
    };
  }

  private duplicateCategoryExternalIdValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const categoryExternalId = control.value;
      if (categoryExternalId == null) {
        return null;
      }

      const categoryExists =
        this.service.existsByExternalId(categoryExternalId);
      return categoryExists
        ? { duplicateCategory: { value: categoryExternalId } }
        : null;
    };
  }

  protected readonly externalIdToolTipText = 'categoryExternalIdToolTip';
}
