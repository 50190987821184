import { Injectable } from '@angular/core';
import {
  InterventionTypeRequest,
  InterventionTypeResponse,
} from '@fullyops/data/action-types/entities/action-type-entity';
import { createStore, withProps } from '@ngneat/elf';
import {
  getEntityByPredicate,
  selectAllEntities,
  setEntities,
  updateEntities,
  withEntities,
} from '@ngneat/elf-entities';
import { withRequestsStatus } from '@ngneat/elf-requests';

export interface ActionTypeProps {}

export const store = createStore(
  { name: 'action-types' },
  withProps<ActionTypeProps>({}),
  withEntities<InterventionTypeResponse, 'label'>({ idKey: 'label' }),
  withRequestsStatus<'action-types'>()
);

export const actionTypes$ = store.pipe(selectAllEntities());

@Injectable({ providedIn: 'root' })
export class ActionTypesRepository {
  set(actions: InterventionTypeResponse[]) {
    store.update(setEntities(actions));
  }

  update(data: InterventionTypeResponse) {
    store.update(updateEntities(data.label, data));
  }

  getByLabel(label: string): InterventionTypeResponse | null {
    return store.query(
      getEntityByPredicate(({ label: actionLabel }) => actionLabel == label)
    );
  }
}
