<mat-progress-bar
  *ngIf="loading$ | async"
  mode="indeterminate"
></mat-progress-bar>

<form class="login" [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()">
  <img
    class="client-logo"
    src="assets/svg/fullyops-logo-white.svg"
    alt="FullyOps Logo"
  />
  <br />
  <ng-container *ngIf="!formSent; else sbmtd">
    <header>
      <h2>{{ 'translation:forgotPassword' | i18nextCap }}</h2>
      <p>{{ 'translation:forgotPasswordText' | i18nextCap }}</p>
    </header>

    <mat-form-field appearance="outline">
      <mat-label>{{ 'validation:username' | i18nextCap }}</mat-label>
      <input matInput type="text" name="username" formControlName="username" />
      <mat-error
        *ngIf="this.forgotPasswordForm.controls.username.hasError('required')"
        >{{ 'validation:youMustEnterAValue' | i18nextCap }}</mat-error
      >
      <mat-error
        *ngIf="
          this.forgotPasswordForm.controls.username.hasError('userNotFound')
        "
        >{{ 'validation:userNotFound' | i18nextCap }}</mat-error
      >
    </mat-form-field>
    <div class="form-group">
      <button class="btn-flip" data-front="Send" data-back="Submit"></button>
      <p>
        {{ 'translation:justRemembered' | i18nextCap }}
        <a routerLink="/login">{{ 'translation:signIn' | i18nextCap }}</a>
      </p>
    </div>
  </ng-container>

  <ng-template #sbmtd>
    <header>
      <h2>{{ 'translation:requestPassword' | i18nextCap }}</h2>
      <p>{{ 'translation:requestPasswordText' | i18nextCap }}</p>
    </header>
    <p>
      <a routerLink="/login">{{ 'translation:signIn' | i18nextCap }}</a>
    </p>
  </ng-template>
</form>
