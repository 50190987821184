import { ISerialisedUnsavedInterventionUserParts } from './serialisations/ISerialisedInterventionUserParts';
import { Part } from './Part';
import { TicketRequestPart } from './TicketRequestPart';
import { User } from './User';

export class UnsavedInterventionUserParts {
  constructor(
    readonly createdAt: string,
    readonly createdBy: User,
    readonly interventionId: string,
    readonly part: Part,
    readonly quantity: number,
    readonly updatedAt: string,
    readonly updatedBy: User,
    readonly user: User
  ) {}

  static fromSerialised(serialised: ISerialisedUnsavedInterventionUserParts) {
    const part = Part.fromSerialised(serialised.part);
    const createdBy = User.fromSerialised(serialised.createdBy);
    const updatedBy = User.fromSerialised(serialised.updatedBy);
    const user = User.fromSerialised(serialised.user);

    return new UnsavedInterventionUserParts(
      serialised.createdAt,
      createdBy,
      serialised.interventionId,
      part,
      serialised.quantity,
      serialised.updatedAt,
      updatedBy,
      user
    );
  }

  toSerialised(): ISerialisedUnsavedInterventionUserParts {
    const partObj = this.part.toSerialised();
    const userObj = this.user.toSerialised();
    const createdByObj = this.createdBy.toSerialised();
    const updatedByObj = this.updatedBy.toSerialised();

    return {
      createdAt: this.createdAt,
      createdBy: createdByObj,
      interventionId: this.interventionId,
      part: partObj,
      quantity: this.quantity,
      updatedAt: this.updatedAt,
      updatedBy: updatedByObj,
      user: userObj,
    };
  }

  clone(
    partialUnsavedInterventionUserParts: PartialUnsavedInterventionUserParts
  ): UnsavedInterventionUserParts {
    const resolve = (key: keyof UnsavedInterventionUserParts) =>
      partialUnsavedInterventionUserParts.hasOwnProperty(key)
        ? partialUnsavedInterventionUserParts[key]
        : this[key];

    return new UnsavedInterventionUserParts(
      resolve('createdAt'),
      resolve('createdBy'),
      resolve('interventionId'),
      resolve('part'),
      resolve('quantity'),
      resolve('updatedAt'),
      resolve('updatedBy'),
      resolve('user')
    );
  }
}
type PartialUnsavedInterventionUserParts = Partial<
  Pick<
    UnsavedInterventionUserParts,
    | 'createdAt'
    | 'createdBy'
    | 'interventionId'
    | 'part'
    | 'quantity'
    | 'updatedAt'
    | 'updatedBy'
    | 'user'
  >
>;
