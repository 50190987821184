<crm-breadcrumb
  [header]="entity ? 'editAnomaly' : 'newAnomaly'"
  [actionButtons]="actions"
  (actionEvent)="onActionEvent($event)"
></crm-breadcrumb>
<ng-container *ngIf="anomalyFormService.form$ | async as anomalyForm">
  <form
    #ngForm="ngForm"
    [@inOut]="'in'"
    [formGroup]="anomalyForm"
    (ngSubmit)="onSubmit()"
  >
    <br /><br />
    <div class="form-row">
      <div class="col-md-8">
        <crm-form-template
          [formGroup]="anomalyForm"
          [formFields]="formFields"
        ></crm-form-template>
      </div>
      <div class="col-md-4"></div>
    </div>
    <footer></footer>
  </form>
</ng-container>
