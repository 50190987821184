import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import {
  FieldType,
  ActionButtons,
  PermissionType,
} from '@fullyops/legacy/ui/ui-shared/utils/crm-types';
import { ComponentInOutAnimation } from '@fullyops/legacy/ui/ui-shared/utils/component-base-animation';
import {
  Response,
  ResponseState,
  ClientEquipment,
} from '@fullyops/legacy/data';
import { BehaviorSubject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'crm-machines',
  templateUrl: './machines.component.html',
  styleUrls: ['./machines.component.scss'],
  animations: [ComponentInOutAnimation.AnimTrigger],
})
export class MachinesComponent implements OnInit {
  @Input() title: string;
  @Input() machines$ = new BehaviorSubject<Response<ClientEquipment[]>>(
    new Response()
  );
  @Output() openEvent = new EventEmitter<string>();
  @Output() addNewEvent = new EventEmitter();

  // Response Possible States
  state = ResponseState;

  actions: ActionButtons = {};
  machinesList$ = new BehaviorSubject<[]>([]);

  ngOnInit() {
    this.initMachinesList();

    this.initActionButtons();
  }

  initMachinesList() {
    this.machines$
      .pipe(
        filter((response) => response.data !== null),
        map((response) => response.data as ClientEquipment[]),
        map((contacts) => contacts.map(this.parseMachine))
      )
      .subscribe(this.machinesList$);
  }

  initActionButtons() {
    this.actions.primary = {
      label: 'addNew',
      color: 'primary',
      permissions: [PermissionType.CAN_EDIT_CLIENT_EQUIPMENTS],
    };
  }

  parseMachine(machine: ClientEquipment) {
    return {
      id: machine.id,
      columns: {
        brand: { label: 'brand', type: FieldType.Text, value: machine.brand },
        model: { label: 'model', type: FieldType.Text, value: machine.model },
        serialNumber: {
          label: 'serialNumber',
          type: FieldType.Text,
          value: machine.serialNumber,
        },
        type: { label: 'type', type: FieldType.Price, value: machine.type },
      },
    };
  }

  onActionEvent(event: string) {
    if (event === 'addNew') {
      this.onAddNew();
    }
  }

  onOpen(id: string) {
    this.openEvent.emit(id);
  }

  onAddNew() {
    this.addNewEvent.emit();
  }
}
