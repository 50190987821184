import { Intervention } from './Intervention';
import { Part } from './Part';
import { ISerialisedInterventionPart } from './serialisations/ISerialisedInterventionPart';
import { UnsavedInterventionPart } from './UnsavedInterventionPart';
import { User } from './User';

export class InterventionPart {
  constructor(
    readonly id: string,
    readonly createdAt: string,
    readonly createdBy: User,
    readonly interventionId: string,
    readonly part: Part,
    readonly quantity: number,
    readonly updatedAt: string,
    readonly updatedBy: User
  ) {}

  static fromSerialised(serialised: ISerialisedInterventionPart) {
    // Once the endpoint results don't come with an id, we have
    // to create our own id using the 'user id' and the 'part id'.
    // It's sad but is true :)
    const interventionPartKey = serialised.interventionId + serialised.part.id;
    const part = Part.fromSerialised(serialised.part);
    const createdBy = User.fromSerialised(serialised.createdBy);
    const updatedBy = User.fromSerialised(serialised.updatedBy);

    return new InterventionPart(
      interventionPartKey,
      serialised.createdAt,
      createdBy,
      serialised.interventionId,
      part,
      serialised.quantity,
      serialised.updatedAt,
      updatedBy
    );
  }

  toSerialised(): ISerialisedInterventionPart {
    const partObj = this.part.toSerialised();
    const createdByObj = this.createdBy.toSerialised();
    const updatedByObj = this.updatedBy.toSerialised();

    return {
      id: this.id,
      createdAt: this.createdAt,
      createdBy: createdByObj,
      interventionId: this.interventionId,
      part: partObj,
      quantity: this.quantity,
      updatedAt: this.updatedAt,
      updatedBy: updatedByObj,
    };
  }

  toDraft(): UnsavedInterventionPart {
    const interventionPart = this.toSerialised();

    return UnsavedInterventionPart.fromSerialised({
      part: {
        ...interventionPart.part,
      },
      quantity: interventionPart.quantity,
      interventionId: interventionPart.interventionId,
      createdBy: undefined,
      updatedBy: undefined,
    });
  }

  clone(partialInterventionPart: InterventionPartPartial): InterventionPart {
    const resolve = (key: keyof InterventionPart) =>
      partialInterventionPart.hasOwnProperty(key)
        ? partialInterventionPart[key]
        : this[key];

    return new InterventionPart(
      resolve('id'),
      resolve('createdAt'),
      resolve('createdBy'),
      resolve('interventionId'),
      resolve('part'),
      resolve('quantity'),
      resolve('updatedAt'),
      resolve('updatedBy')
    );
  }
}
type InterventionPartPartial = Partial<
  Pick<
    InterventionPart,
    | 'id'
    | 'createdAt'
    | 'createdBy'
    | 'interventionId'
    | 'part'
    | 'quantity'
    | 'updatedAt'
    | 'updatedBy'
  >
>;
