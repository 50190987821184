import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-button-v2',
  templateUrl: './button-v2.component.html',
  styleUrls: ['./button-v2.component.scss'],
})
export class ButtonV2Component implements OnInit {
  @Input() text: string;
  @Input() color: 'red' | 'blue' | 'green';
  @Input() isDisabled: boolean;

  @Output() onClick = new EventEmitter<Function>();

  constructor() {}

  ngOnInit() {}

  getClass() {
    if (this.color) {
      return `button-${this.color}`;
    } else {
      return;
    }
  }

  buttonClick() {
    this.onClick.emit();
  }
}
