import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ComponentInOutAnimation } from '@fullyops/legacy/ui/ui-shared/utils/component-base-animation';
import { Part, UnsavedUserPart, User, UserPart } from '@fullyops/legacy/data';
import { BehaviorSubject } from 'rxjs';
import { ActionButtons } from '@fullyops/legacy/ui/ui-shared/utils/crm-types';
import { map, startWith } from 'rxjs/operators';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'crm-user-parts-form',
  templateUrl: './user-parts-form.component.html',
  styleUrls: ['./user-parts-form.component.scss'],
  animations: [ComponentInOutAnimation.AnimTrigger],
})
export class UserPartsFormComponent implements OnInit {
  @Input() user$: BehaviorSubject<User>;
  @Input() store$: BehaviorSubject<[Part[], UserPart[]]>;
  @Output() addPartEvent = new EventEmitter<UnsavedUserPart>();
  @Output() updatePartEvent = new EventEmitter<UnsavedUserPart>();
  @Output() removePartEvent = new EventEmitter<string>();

  userName = '';
  partSearchCtrl = new UntypedFormControl();

  actions: ActionButtons = {};
  suppliersL$ = new BehaviorSubject<[]>([]);

  filteredParts$ = new BehaviorSubject<[Part[], UserPart[]]>([[], []]);

  constructor() {}

  ngOnInit() {
    this.initParts();
    this.handleSearchChanges();
  }

  initParts() {
    this.store$
      .pipe(
        map((store) =>
          this.partSearchCtrl.value
            ? this._filterParts(this.partSearchCtrl.value)
            : store
        )
      )
      .subscribe(([parts, userParts]: [Part[], UserPart[]]) => {
        this.filteredParts$.next([
          parts.filter(
            (p) =>
              userParts.find((userPart) => userPart.part.id === p.id) ===
              undefined
          ),
          userParts,
        ]);
      });
  }

  handleSearchChanges() {
    this.partSearchCtrl.valueChanges
      .pipe(
        startWith(''),
        map((part) => (part ? this._filterParts(part) : this.store$.getValue()))
      )
      .subscribe(([parts, userParts]: [Part[], UserPart[]]) => {
        this.filteredParts$.next([
          parts.filter(
            (p) =>
              userParts.find((userPart) => userPart.part.id === p.id) ===
              undefined
          ),
          userParts,
        ]);
      });
  }

  private _filterParts(value: string): [Part[], UserPart[]] {
    const filterValue = value.toLowerCase();

    const results = this.store$.getValue();

    return [
      results[0]
        .filter(
          (part) =>
            results[1].find((userPart) => userPart.part.id === part.id) ===
            undefined
        )
        .filter((part) => part.name.toLowerCase().includes(filterValue)),
      results[1].filter((userPart) =>
        userPart.part.name.toLowerCase().includes(filterValue)
      ),
    ];
  }

  onAddPart(part: Part) {
    this.addPartEvent.emit(new UnsavedUserPart(part.id, 1));
  }

  onChangeQuantity(event, userPart: UserPart) {
    this.updatePartEvent.emit(
      new UnsavedUserPart(userPart.part.id, event.target.value)
    );
  }

  onRemovePart(userPart: UserPart) {
    this.removePartEvent.emit(userPart.part.id);
  }
}
