import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import {
  Response,
  ResponseState,
  Activity,
  History,
  ActivityType,
  ActivityLinkType,
  HistoryType,
  TicketRequest,
  TicketSale,
  TicketSupportMultimachine,
  InterventionMachine,
} from '@fullyops/legacy/data';
import { BehaviorSubject } from 'rxjs';
import { ComponentInOutAnimation } from '@fullyops/legacy/ui/ui-shared/utils/component-base-animation';
import { map, filter } from 'rxjs/operators';
import {
  ActivityTypeIcon,
  TicketTypeIcon,
  PermissionType,
} from '@fullyops/legacy/ui/ui-shared/utils/crm-types';
import { TimeZoneDateFormatterService } from '@fullyops/core/services/date-formatter.service';
import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'crm-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
  animations: [ComponentInOutAnimation.AnimTrigger],
})
export class HistoryComponent implements OnInit {
  constructor(public timezoneDate: TimeZoneDateFormatterService) {}
  @Input() history$ = new BehaviorSubject<Response<History[]>>(new Response());
  @Output() openActivityEvent = new EventEmitter<string>();
  @Output() openTicketEvent = new EventEmitter<{
    ticketId: string;
    ticketType: ActivityLinkType;
  }>();

  state = ResponseState;
  activityType = ActivityType;
  HistoryType = HistoryType;

  historyPermissions = [PermissionType.CAN_ACCESS_HISTORY];
  historyList$ = new BehaviorSubject<[]>([]);
  faClockRotateLeft = faClockRotateLeft;

  ngOnInit() {
    this.history$
      .pipe(
        filter((response) => response.data !== null),
        map((response) => response.data as History[]),
        map((history) =>
          history.map(this.parseHistory).filter((h) => h !== undefined)
        )
      )
      .subscribe(this.historyList$);
  }

  parseHistory = (history: History) => {
    switch (history.historyLink.linkType) {
      case HistoryType.ACTIVITY:
        return this.parseActivity(history.historyLink.activity);
      case HistoryType.REQUEST:
        return this.parseTicket(
          history.historyLink.requestTicket,
          HistoryType.REQUEST
        );
      case HistoryType.SUPPORT:
        return this.parseTicket(
          history.historyLink.supportTicket,
          HistoryType.SUPPORT
        );
      case HistoryType.SALE:
        return this.parseTicket(
          history.historyLink.saleTicket,
          HistoryType.SALE
        );
      default:
        return;
    }
  };

  parseTicket(
    ticket: TicketSupportMultimachine | TicketRequest | TicketSale,
    ticketType: HistoryType
  ) {
    const date =
      ticketType === HistoryType.SUPPORT
        ? (ticket as TicketSupportMultimachine).actAt
        : ticketType === HistoryType.REQUEST
        ? (ticket as TicketRequest).createdAt
        : ticketType === HistoryType.SALE
        ? (ticket as TicketSale).saleDate
        : null;

    return {
      id: ticket.id,
      type: ticketType,
      name: ticket.name,
      icon: TicketTypeIcon[ticketType],
      date,
      completed: date < this.timezoneDate.currentDate().toDate(),
    };
  }

  parseIntervention(
    intervention: InterventionMachine,
    interventionType: HistoryType
  ) {
    const date = null;

    return {
      id: intervention.id,
      type: interventionType,
      name: intervention.description,
      icon: TicketTypeIcon[interventionType],
      date,
      completed: date < this.timezoneDate.currentDate().toDate(),
    };
  }

  parseActivity(activity: Activity) {
    return {
      ...activity,
      type: HistoryType.ACTIVITY,
      icon: ActivityTypeIcon[activity.activityType],
      completed: activity.startsAt < this.timezoneDate.currentDate().toDate(),
    };
  }
}
