import { ApiActionTypeAdapterService } from '@fullyops/data/action-types/api-action-types-adapter.service';
import {
  ActionTypesRepository,
  actionTypes$,
} from '../repositories/action-types.repository';
import {
  Observable,
  ReplaySubject,
  catchError,
  map,
  tap,
  throwError,
} from 'rxjs';
import { InterventionTypeResponse } from '@fullyops/data/action-types/entities/action-type-entity';
import { Injectable } from '@angular/core';
import { DomainSelectOptionModel } from '@fullyops/domain/base/entities';

@Injectable({
  providedIn: 'root',
})
export class ActionTypesService {
  actionTypes$: Observable<InterventionTypeResponse[]> = actionTypes$;
  actionTypesOptions$ = this.actionTypes$.pipe(this.formatToOptions$());

  constructor(
    private adapter: ApiActionTypeAdapterService,
    private repository: ActionTypesRepository
  ) {}

  getAll(): Observable<InterventionTypeResponse[]> {
    const results$ = this.adapter.getAll().pipe(
      tap((actions) => {
        this.repository.set(actions);
      })
    );

    const replaySubject = new ReplaySubject<InterventionTypeResponse[]>(1);
    results$.subscribe(replaySubject);

    return replaySubject;
  }

  updateByLabel({ data: newData }: { data: InterventionTypeResponse }) {
    const oldData = this.repository.getByLabel(newData.label);

    this.repository.update(newData);

    const result$ = this.adapter
      .updateByLabel({ label: newData.label, data: newData })
      .pipe(
        tap((actionType) => {
          const isDefaultChanged = oldData.isDefault !== newData.isDefault;
          if (isDefaultChanged) this.getAll();
          else this.repository.update(actionType);
        }),
        catchError((error) => {
          this.repository.update(oldData);
          return throwError(() => error);
        })
      );

    const replaySubject = new ReplaySubject<InterventionTypeResponse>(1);
    result$.subscribe(replaySubject);

    return replaySubject;
  }

  private formatToOptions$() {
    return map((actionTypeOptions: InterventionTypeResponse[]) => {
      return actionTypeOptions.map((actionType) => {
        const option: DomainSelectOptionModel = {
          id: actionType.label,
          name: actionType.name,
          active: actionType.active,
        };
        return option;
      });
    });
  }
}
