<mat-expansion-panel
  [expanded]="indexExpanded === currentIndex"
  [disabled]="isNew && !(canEditAnCreateAsync$ | async)"
  (opened)="setIndexExpanded.emit()"
>
  <mat-expansion-panel-header>
    <mat-panel-title>
      <div class="expansion-header">
        <div
          class="icon green-icon"
          *ngIf="isNew"
          [ngClass]="
            isNew && !(canEditAnCreateAsync$ | async) ? 'low-opacity' : ''
          "
        >
          <fa-icon [icon]="faPlusCircle" size="lg"></fa-icon>
        </div>
        <div class="icon blue-icon" *ngIf="!isNew">
          <fa-icon [icon]="faGears" size="lg"></fa-icon>
        </div>
        <div *ngIf="!isNew">{{ equipment?.name || '' }}</div>
        <div *ngIf="isNew">{{ 'forms:newEquipments' | i18next }}</div>
      </div>
    </mat-panel-title>
  </mat-expansion-panel-header>

  <form #ngForm="ngForm">
    <div class="row">
      <div class="col-md-12">
        <crm-form-template
          [formGroup]="formGroup"
          [formFields]="formFields"
        ></crm-form-template>
      </div>
    </div>

    <div class="buttons-right">
      <div [hideIfNotAllowed]="canDeleteClientEquipment">
        <app-button-v2
          text="delete"
          color="red"
          (onClick)="onDelete()"
          *ngIf="!isNew"
        ></app-button-v2>
      </div>

      <div [hideIfNotAllowed]="canEditAnCreate">
        <app-button-v2
          text="save"
          color="green"
          (onClick)="onEdit()"
          *ngIf="!isNew"
          [attr.data-testid]="'delete-client-equipment-button'"
        ></app-button-v2>
      </div>

      <div [hideIfNotAllowed]="canEditAnCreate">
        <app-button-v2
          text="create"
          color="green"
          (onClick)="onCreate()"
          *ngIf="isNew"
        ></app-button-v2>
      </div>
    </div>
  </form>
</mat-expansion-panel>
