import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  UntypedFormArray,
  ValidatorFn,
  AbstractControl,
} from '@angular/forms';
import {
  ReportSection,
  ReportSubSection,
  UnsavedProduct,
} from '@fullyops/legacy/data';
import { AbstractFormService } from '@fullyops/legacy/ui/ui-shared/utils/crm-types';

class ProductForm extends UntypedFormGroup {
  constructor(product: UnsavedProduct, dis: boolean) {
    super({});

    [
      'name',
      'stock',
      'price',
      'ref',
      'description',
      'catalogueUrl',
      'imageUrls',
    ].forEach((controlName) =>
      this.addControl(
        controlName,
        new UntypedFormControl(
          { value: product[controlName], disabled: dis },
          []
        )
      )
    );

    this.addControl('additionalInfo', new UntypedFormArray([], []));
    this.addControl(
      'technicalInfo',
      new UntypedFormArray(
        Object.keys(product.technicalCharacteristics).map(
          (tableName) =>
            new UntypedFormGroup(
              {
                name: new UntypedFormControl(
                  { value: tableName, disabled: dis },
                  Validators.required
                ),
                table: new UntypedFormArray(
                  product.technicalCharacteristics[tableName].map(
                    (row) =>
                      new UntypedFormArray(
                        row.map(
                          (cell) =>
                            new UntypedFormControl(
                              { value: cell, disabled: dis },
                              []
                            )
                        ),
                        []
                      )
                  ),
                  []
                ),
              },
              Validators.required
            )
        ),
        []
      )
    );

    /**
     *  Set validators
     */
    ['name', 'stock', 'ref', 'description'].forEach((controlName) =>
      (this as UntypedFormGroup)
        .get(controlName)
        .setValidators(Validators.required)
    );

    this.get('catalogueUrl').disable();
  }
}

@Injectable()
export class ProductFormService implements AbstractFormService<UnsavedProduct> {
  form$: BehaviorSubject<UntypedFormGroup>;
  originalDraft: UnsavedProduct;

  constructor() {
    this.form$ = new BehaviorSubject<UntypedFormGroup>(
      new UntypedFormGroup({})
    );
  }

  initForm(draftProduct: UnsavedProduct, disabled: boolean) {
    this.originalDraft = draftProduct;
    this.form$.next(new ProductForm(draftProduct, disabled));
    this.originalDraft.additionalInfo.forEach((section) =>
      this.addSection(section, disabled)
    );
  }

  getDraft() {
    const technicalInfo = {};

    (
      this.form$.getValue().get('technicalInfo') as UntypedFormArray
    ).controls.forEach((table) => {
      table.enable();
      technicalInfo[table.value.name] = table.value.table;
    });

    (
      this.form$.getValue().get('additionalInfo') as UntypedFormArray
    ).controls.map((section) => {
      section.enable();
    });

    return new UnsavedProduct(
      (this.form$.getValue().get('name') as UntypedFormControl).value,
      (this.form$.getValue().get('stock') as UntypedFormControl).value,
      (this.form$.getValue().get('price') as UntypedFormControl).value,
      (this.form$.getValue().get('ref') as UntypedFormControl).value,
      (this.form$.getValue().get('description') as UntypedFormControl).value,
      [],
      this.originalDraft.subproducts,
      technicalInfo,
      (
        this.form$.getValue().get('additionalInfo') as UntypedFormArray
      ).controls.map((section) => ReportSection.fromSerialised(section.value)),
      (this.form$.getValue().get('catalogueUrl') as UntypedFormControl).value,
      (this.form$.getValue().get('imageUrls') as UntypedFormControl).value
    );
  }

  markAllAsTouched() {
    const currentProduct = this.form$.getValue();

    currentProduct.markAllAsTouched();

    this.form$.next(currentProduct);
  }

  addSection(section: ReportSection, dis: boolean) {
    const currentForm = this.form$.getValue();

    const newSection = new UntypedFormGroup({});

    newSection.addControl(
      'section',
      new UntypedFormControl(
        { value: section.section, disabled: dis },
        Validators.required
      )
    );
    newSection.addControl(
      'content',
      new UntypedFormArray([], Validators.required)
    );

    (currentForm.get('additionalInfo') as UntypedFormArray).push(newSection);

    this.form$.next(currentForm);

    section.content.forEach((subsection) =>
      this.addSubsection(
        (currentForm.get('additionalInfo') as UntypedFormArray).length - 1,
        subsection,
        dis
      )
    );
  }

  addSubsection(
    sectionIndex: number,
    subsection: ReportSubSection,
    dis: boolean
  ) {
    const currentForm = this.form$.getValue();
    const newSubsection = new UntypedFormGroup({});

    newSubsection.addControl(
      'title',
      new UntypedFormControl(
        { value: subsection.title, disabled: dis },
        Validators.required
      )
    );
    newSubsection.addControl(
      'content',
      new UntypedFormArray(
        subsection.content.map(
          (line) =>
            new UntypedFormGroup(
              {
                url: new UntypedFormControl(
                  { value: line.url, disabled: dis },
                  []
                ),
                data: new UntypedFormArray(
                  line.data.map(
                    (value) =>
                      new UntypedFormControl(
                        { value, disabled: dis },
                        Validators.required
                      )
                  ),
                  []
                ),
              },
              Validators.required
            )
        )
      )
    );

    (
      (currentForm.get('additionalInfo') as UntypedFormArray).controls[
        sectionIndex
      ].get('content') as UntypedFormArray
    ).push(newSubsection);

    this.form$.next(currentForm);
  }

  removeAccessory() {}
}
