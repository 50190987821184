import { ISerialisedWorkPeriod } from './serialisations/ISerialisedWorkPeriod';
import { UnsavedWorkPeriod } from './UnsavedWorkPeriod';
import { User } from './User';
import { WorkPeriodAssignees } from './WorkPeriodAssignees';

export class WorkPeriod {
  constructor(
    readonly assignees: WorkPeriodAssignees[],
    readonly createdAt: string,
    readonly createdBy: User | null,
    readonly endingHour: string,
    readonly id: string,
    readonly interventionId: string,
    readonly startingHour: string,
    readonly updatedAt: string,
    readonly updatedBy: User | null
  ) {}

  static fromSerialised(serialised: ISerialisedWorkPeriod) {
    const assignees = serialised.assignees.map(
      WorkPeriodAssignees.fromSerialised
    );

    const createdBy = serialised.createdBy
      ? User.fromSerialised(serialised.createdBy)
      : null;
    const updatedBy = serialised.createdBy
      ? User.fromSerialised(serialised.updatedBy)
      : null;

    return new WorkPeriod(
      assignees,
      serialised.createdAt,
      createdBy,
      serialised.endingHour,
      serialised.id,
      serialised.interventionId,
      serialised.startingHour,
      serialised.updatedAt,
      updatedBy
    );
  }

  toSerialised(): ISerialisedWorkPeriod {
    const assigneesObj = this.assignees.map((assignee) =>
      assignee.toSerialised()
    );

    const createdByObj = this.createdBy.toSerialised();
    const updatedByObj = this.updatedBy.toSerialised();

    return {
      ...this,
      assignees: assigneesObj,
      createdBy: createdByObj,
      updatedBy: updatedByObj,
    };
  }

  toDraft(): UnsavedWorkPeriod {
    const workPeriod = this.toSerialised();

    return new UnsavedWorkPeriod(
      workPeriod.assignees.map((assignee) => assignee.assignee.id),
      this.startingHour,
      this.interventionId,
      this.startingHour
    );
  }

  clone(partialWorkPeriod: PartialWorkPeriod): WorkPeriod {
    const resolve = (key: keyof WorkPeriod) =>
      partialWorkPeriod.hasOwnProperty(key)
        ? partialWorkPeriod[key]
        : this[key];

    return new WorkPeriod(
      resolve('assignees'),
      resolve('createdAt'),
      resolve('createdBy'),
      resolve('endingHour'),
      resolve('id'),
      resolve('interventionId'),
      resolve('startingHour'),
      resolve('updatedAt'),
      resolve('updatedBy')
    );
  }
}
type PartialWorkPeriod = Partial<
  Pick<
    WorkPeriod,
    | 'assignees'
    | 'createdAt'
    | 'createdBy'
    | 'endingHour'
    | 'id'
    | 'interventionId'
    | 'startingHour'
    | 'updatedAt'
    | 'updatedBy'
  >
>;
