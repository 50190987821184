import { Injectable } from '@angular/core';
import {
  TicketsSupportMultimachineAssigneeEndpoint,
  TicketSupportMultimachineAssignee,
  Response,
} from '@fullyops/legacy/data';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UiTicketsSupportMultimachineAssigneeService {
  //@ts-ignore

  readonly store$ = this.service.createStore();
  constructor(protected service: TicketsSupportMultimachineAssigneeEndpoint) {}

  assignUser(userId: string, ticketId: string) {
    return this.service.assignTicketToUser(
      userId,
      ticketId,
      undefined,
      this.store$
    );
  }

  unassignUser(userId: string, ticketId: string) {
    return this.service.unassignUserTicket(
      userId,
      ticketId,
      undefined,
      this.store$
    );
  }

  getTicketAssignees(
    id: string
  ): Observable<Response<TicketSupportMultimachineAssignee[]>> {
    return this.service.getAll({ supportTicketId: id });
  }
}
