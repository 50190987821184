import { Response } from '../Response';
import { Endpoint } from '../Endpoint';
import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { API_SERVICE_CONFIG } from '../ApiServiceConfig';

import { Observable } from 'rxjs';

import { Permission } from '../../models/v0/Permission';
import { ISerialisedPermission } from '../../models/v0/serialisations/ISerialisedPermission';
import { IApiServiceConfig } from '../IApiServiceConfig';

@Injectable()
export class UsersPermissionsEndpoint extends Endpoint<
  Permission,
  ISerialisedPermission
> {
  private readonly url = this.config.url + 'users/permissions/';

  constructor(
    @Inject(API_SERVICE_CONFIG) private config: IApiServiceConfig,
    private http: HttpClient
  ) {
    super(Permission.fromSerialised);
  }

  post(
    userId: string,
    roleId: string,
    source?: Observable<ISerialisedPermission>
  ): Observable<Response<Permission>> {
    const defaultSource = this.http.post<ISerialisedPermission>(
      `${this.url}${userId}/${roleId}/`,
      {}
    );
    return this.add(source || defaultSource, null);
  }

  delete(
    userId: string,
    roleId: string,
    source?: Observable<{}>
  ): Observable<Response<{}>> {
    const defaultSource = this.http.delete<{}>(
      `${this.url}${userId}/${roleId}/`
    );
    return this.remove(source || defaultSource, null);
  }
}
