import { ISerialisedSubProduct } from './serialisations/ISerialisedSubProduct';
import { SubProductVariant } from './SubProductVariant';
import { UnsavedSubProduct } from './UnsavedSubProduct';

export class SubProduct {
  constructor(
    readonly id: string,
    readonly name: string,
    readonly variants: SubProductVariant[],
    readonly productId: string
  ) {
    if (name) this.name = name.trim();
  }

  static fromSerialised(serialised: ISerialisedSubProduct) {
    const variants = serialised.variants.map(SubProductVariant.fromSerialised);

    return new SubProduct(
      serialised.id,
      serialised.name,
      variants,
      serialised.productId
    );
  }

  toSerialised(): ISerialisedSubProduct {
    const variantsObj = this.variants.map((variant) => variant.toSerialised());

    return {
      id: this.id,
      name: this.name,
      variants: variantsObj,
      productId: this.productId,
    };
  }

  toDraft(): UnsavedSubProduct {
    const variantsObj = this.variants.map((variant) => variant.toDraft());

    return UnsavedSubProduct.fromSerialised({
      ...this,
      variants: variantsObj,
    });
  }

  clone(partialSubProduct: PartialSubProduct): SubProduct {
    const resolve = (key: keyof SubProduct) =>
      partialSubProduct.hasOwnProperty(key)
        ? partialSubProduct[key]
        : this[key];

    return new SubProduct(
      resolve('id'),
      resolve('name'),
      resolve('variants'),
      resolve('productId')
    );
  }
}

type PartialSubProduct = Partial<
  Pick<SubProduct, 'id' | 'name' | 'variants' | 'productId'>
>;
