import { Injectable } from '@angular/core';
import {
  Product,
  UnsavedProduct,
  ProductsEndpoint,
} from '@fullyops/legacy/data';
import { UiService } from './ui.service';

@Injectable({
  providedIn: 'root',
})
export class UiProductsService extends UiService<
  Product,
  UnsavedProduct,
  ProductsEndpoint
> {
  constructor(protected companiesEndpoint: ProductsEndpoint) {
    super(companiesEndpoint.createStore(), companiesEndpoint);
  }
}
