<form>
  <crm-form-template
    [formGroup]="formGroup"
    [formFields]="formFields"
  ></crm-form-template>

  <div class="action-form-row">
    <div class="creation-info">
      <span *ngIf="intervention">
        {{ 'createdBy' | i18nextCap }}
        {{ getCreatedByName() }}
        {{ 'on' | i18next }}
        {{
          this.intervention.createdAt
            | momentTimezoneToAngularTimezone: 'MMMM D, YYYY HH:mm'
        }}
      </span>
    </div>

    <div class="button-row">
      <div>
        <button
          *ngIf="isNew"
          mat-flat-button
          type="button"
          name="add"
          [disabled]="formGroup.dirty == false"
          (click)="onSubmit()"
          class="button-style green"
          [attr.data-testid]="'intervention-form-add'"
        >
          {{ 'translation:addAction' | i18nextCap }}
        </button>
      </div>

      <div
        [matTooltip]="'youDoNotHavePermissionToDeleteActions' | i18nextCap"
        [matTooltipDisabled]="havePermissionToDeleteIntervention"
        *ngIf="!isNew"
      >
        <button
          mat-flat-button
          type="button"
          name="add"
          (click)="onDeleteAction()"
          class="button-style red"
          [attr.data-testid]="'intervention-form-delete'"
          [disabled]="!havePermissionToDeleteIntervention"
        >
          {{ 'translation:delete' | i18nextCap }}
        </button>
      </div>

      <div *ngIf="!isNew">
        <button
          mat-flat-button
          type="button"
          name="add"
          [disabled]="formGroup.dirty == false"
          (click)="onSubmit()"
          class="button-style green"
          [attr.data-testid]="'intervention-form-save'"
        >
          {{ 'translation:save' | i18nextCap }}
        </button>
      </div>
    </div>
  </div>
</form>
