import { ISerialisedTenant } from './serialisations/ISerialisedTenant';

export class Tenant {
  constructor(
    readonly id: string,
    readonly name: string,
    readonly email: string
  ) {
    if (name) this.name = name.trim();
    if (email) this.email = email.trim();
  }

  static fromSerialised(serialised: ISerialisedTenant) {
    return new Tenant(serialised.id, serialised.name, serialised.email);
  }

  toSerialised(): ISerialisedTenant {
    return {
      id: this.id,
      name: this.name,
      email: this.email,
    };
  }
}
