import { Injectable } from '@angular/core';
import { UserResponse } from '@fullyops/legacy/data/api/types/User';
import { User } from '@fullyops/legacy/data/models/v0/User';

@Injectable({
  providedIn: 'root',
})
export class AssigneeService {
  constructor() {}

  formatUserToUserResponse(users: User[]) {
    // TODO: This is mapping logic that belongs to the domain layer
    // @ts-ignore
    const usersResponse: UserResponse[] = users.map((user) => {
      return {
        active: user.active,
        deleted: null,
        email: user.email,
        firstName: user.firstName,
        id: user.id,
        lastName: user.lastName,
        locale: user.locale,
        phone: user.phone,
        photo: user.photo,
        profilePictureID: user.profilePictureID,
        profilePictureURL: user.profilePictureURL,
        roles: user.roles,
        tenants: user.tenants,
        timeZone: user.timeZone,
        username: user.username,
      };
    });
    return usersResponse;
  }

  getDropdownAssigneeList(params: {
    // TODO: We're mixing UI concepts with the domain layer
    formValue: Array<UserResponse | User>;
    listOfUsers: Array<UserResponse | User>;
  }) {
    if (params.formValue[0] instanceof User) {
      params.formValue = this.formatUserToUserResponse(
        params.formValue as Array<User>
      );
    }

    if (params.listOfUsers[0] instanceof User) {
      params.listOfUsers = this.formatUserToUserResponse(
        params.listOfUsers as Array<User>
      );
    }

    const valuesNotOnList4 = params.formValue.filter((user) => {
      return !params.listOfUsers.some((listUser) => listUser.id == user.id);
    });

    return [...params.listOfUsers, ...valuesNotOnList4];
  }
}
