import { ISerialisedUnsavedTicketSupport } from './serialisations/ISerialisedTicketSupport';
import { ClientInfo } from './ClientInfo';
import { WorkPeriod } from './WorkPeriod';
import { Travel } from './Travel';
import { UnsavedInterventionUserParts } from './UnsavedInterventionUserParts';
import { UnsavedTicketRequestPart } from './UnsavedTicketRequestPart';

export class UnsavedTicketSupport {
  constructor(
    readonly name: string,
    readonly priority: string,
    readonly statusId: string,
    readonly assigneeIds: string[],
    readonly companyId: string,
    readonly contactIds: string[],
    readonly clientInfo: ClientInfo,
    readonly anomalies: string,
    readonly anomalyType: string,
    readonly equipmentData: string,
    readonly internalObservation: string,
    readonly malfunctionType: string,
    readonly workDone: string,
    readonly photos: string[],
    readonly createdAt: Date,
    readonly actAt: Date | null,
    readonly executedAt: Date | null,
    readonly closedAt: Date | null,
    readonly warranty: boolean,
    readonly finished: boolean,
    readonly brand: string,
    readonly model: string,
    readonly serialNumber: string,
    readonly type: string,
    readonly userObservation: string,
    readonly parts: UnsavedTicketRequestPart[],
    readonly travels: Travel[],
    readonly workPeriods: WorkPeriod[],
    readonly userParts: { [userId: string]: UnsavedInterventionUserParts },
    readonly unregisteredParts: string
  ) {
    if (name) this.name = name.trim();
    if (anomalies) this.anomalies = anomalies.trim();
    if (anomalyType) this.anomalyType = anomalyType.trim();
    if (equipmentData) this.equipmentData = equipmentData.trim();
    if (internalObservation)
      this.internalObservation = internalObservation.trim();
    if (malfunctionType) this.malfunctionType = malfunctionType.trim();
    if (workDone) this.workDone = workDone.trim();
    if (brand) this.brand = brand.trim();
    if (model) this.model = model.trim();
    if (serialNumber) this.serialNumber = serialNumber.trim();
    if (type) this.type = type.trim();
    if (userObservation) this.userObservation = userObservation.trim();
    if (unregisteredParts) this.unregisteredParts = unregisteredParts.trim();
  }

  static fromSerialised(serialised: ISerialisedUnsavedTicketSupport) {
    const clientInfo = ClientInfo.fromSerialised(serialised.clientInfo);
    const parts = serialised.parts.map(UnsavedTicketRequestPart.fromSerialised);
    const travels = serialised.travels.map(Travel.fromSerialised);
    const workPeriods = serialised.workPeriods.map(WorkPeriod.fromSerialised);

    const userParts = {};
    Object.keys(serialised.userParts).forEach(
      (key) =>
        (userParts[key] = UnsavedInterventionUserParts.fromSerialised(
          serialised.userParts[key]
        ))
    );

    return new UnsavedTicketSupport(
      serialised.name,
      serialised.priority,
      serialised.statusId,
      serialised.assigneeIds,
      serialised.companyId,
      serialised.contactIds,
      clientInfo,
      serialised.anomalies,
      serialised.anomalyType,
      serialised.equipmentData,
      serialised.internalObservation,
      serialised.malfunctionType,
      serialised.workDone,
      serialised.photos,
      serialised.createdAt ? new Date(serialised.createdAt) : null,
      serialised.actAt ? new Date(serialised.actAt) : null,
      serialised.executedAt ? new Date(serialised.executedAt) : null,
      serialised.closedAt ? new Date(serialised.closedAt) : null,
      serialised.warranty,
      serialised.finished,
      serialised.brand,
      serialised.model,
      serialised.serialNumber,
      serialised.type,
      serialised.userObservation,
      parts,
      travels,
      workPeriods,
      userParts,
      serialised.unregisteredParts
    );
  }

  toSerialised(): ISerialisedUnsavedTicketSupport {
    const clientInfoObj = this.clientInfo.toSerialised();
    const partsObj = this.parts.map((part) => part.toSerialised());
    const travelsObj = this.travels.map((travel) => travel.toSerialised());
    const workPeriodsObj = this.workPeriods.map((period) =>
      period.toSerialised()
    );

    const userPartsObj = {};
    Object.keys(this.userParts).forEach(
      (key) => (userPartsObj[key] = this.userParts[key].toSerialised())
    );

    return {
      name: this.name,
      priority: this.priority,
      statusId: this.statusId,
      assigneeIds: this.assigneeIds,
      companyId: this.companyId,
      contactIds: this.contactIds,
      clientInfo: clientInfoObj,
      anomalies: this.anomalies,
      anomalyType: this.anomalyType,
      equipmentData: this.equipmentData,
      internalObservation: this.internalObservation,
      malfunctionType: this.malfunctionType,
      workDone: this.workDone,
      photos: this.photos,
      createdAt: this.createdAt.toISOString(),
      actAt: this.actAt ? this.actAt.toISOString() : null,
      executedAt: this.executedAt ? this.executedAt.toISOString() : null,
      closedAt: this.closedAt ? this.closedAt.toISOString() : null,
      warranty: this.warranty,
      finished: this.finished,
      brand: this.brand,
      model: this.model,
      serialNumber: this.serialNumber,
      type: this.type,
      userObservation: this.userObservation,
      parts: partsObj,
      travels: travelsObj,
      workPeriods: workPeriodsObj,
      userParts: userPartsObj,
      unregisteredParts: this.unregisteredParts,
    };
  }

  clone(
    partialUnsavedTicketSupport: PartialUnsavedTicketSupport
  ): UnsavedTicketSupport {
    const resolve = (key: keyof UnsavedTicketSupport) =>
      partialUnsavedTicketSupport.hasOwnProperty(key)
        ? partialUnsavedTicketSupport[key]
        : this[key];

    return new UnsavedTicketSupport(
      resolve('name'),
      resolve('priority'),
      resolve('statusId'),
      resolve('assigneeIds'),
      resolve('companyId'),
      resolve('contactIds'),
      resolve('clientInfo'),
      resolve('anomalies'),
      resolve('anomalyType'),
      resolve('equipmentData'),
      resolve('internalObservation'),
      resolve('malfunctionType'),
      resolve('workDone'),
      resolve('photos'),
      resolve('createdAt'),
      resolve('actAt'),
      resolve('executedAt'),
      resolve('closedAt'),
      resolve('warranty'),
      resolve('finished'),
      resolve('brand'),
      resolve('model'),
      resolve('serialNumber'),
      resolve('type'),
      resolve('userObservation'),
      resolve('parts'),
      resolve('travels'),
      resolve('workPeriods'),
      resolve('userParts'),
      resolve('unregisteredParts')
    );
  }
}

type PartialUnsavedTicketSupport = Partial<
  Pick<
    UnsavedTicketSupport,
    | 'name'
    | 'priority'
    | 'statusId'
    | 'assigneeIds'
    | 'companyId'
    | 'contactIds'
    | 'clientInfo'
    | 'anomalies'
    | 'parts'
    | 'anomalyType'
    | 'travels'
    | 'equipmentData'
    | 'internalObservation'
    | 'malfunctionType'
    | 'workDone'
    | 'photos'
    | 'createdAt'
    | 'actAt'
    | 'executedAt'
    | 'closedAt'
    | 'warranty'
    | 'finished'
    | 'brand'
    | 'model'
    | 'serialNumber'
    | 'type'
    | 'userObservation'
    | 'workPeriods'
    | 'userParts'
    | 'unregisteredParts'
  >
>;
