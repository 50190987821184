<ng-container *ngFor="let row of formFields">
  <crm-form-template
    [formGroup]="row.control"
    [formFields]="row.fields"
    (selectionChange)="onChange($event)"
  ></crm-form-template>
</ng-container>
<div *ngIf="formGroup.enabled">
  <crm-form-parts-autocomplete
    [parts$]="autocompletePartsList$"
    (addPartEvent)="onAddPart($event)"
    (createPartEvent)="onCreatePart($event)"
  ></crm-form-parts-autocomplete>
</div>
