import { ISerialisedUnsavedWorkPeriod } from './serialisations/ISerialisedWorkPeriod';

export class UnsavedWorkPeriod {
  constructor(
    readonly assigneesIds: string[],
    readonly endingHour: string,
    readonly interventionId: string,
    readonly startingHour: string
  ) {}

  fromSerialised(serialised: ISerialisedUnsavedWorkPeriod) {
    return new UnsavedWorkPeriod(
      serialised.assigneesIds,
      serialised.endingHour,
      serialised.interventionId,
      serialised.startingHour
    );
  }

  toSerialised(): ISerialisedUnsavedWorkPeriod {
    return {
      ...this,
    };
  }
}
