export enum TicketType {
  SALE = 'SALE',
  SUPPORT = 'SUPPORT',
  REQUEST = 'REQUEST',
}

export type TicketTypeV2 = 'SUPPORT' | 'REQUEST' | 'SALE';

export enum ActivityLinkType {
  SUPPORT = 'SUPPORT',
  REQUEST = 'REQUEST',
  SALE = 'SALE',
}

export enum ActivityType {
  CALL = 'CALL',
  REMINDER = 'REMINDER',
  EMAIL = 'EMAIL',
  MEETING = 'MEETING',
  TASK = 'TASK',
}

export enum HistoryType {
  SUPPORT = 'SUPPORT',
  REQUEST = 'REQUEST',
  ACTIVITY = 'ACTIVITY',
  SALE = 'SALE',
  INTERVENTION = 'INTERVENTION',
}
