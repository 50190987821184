import { NgModule } from '@angular/core';
import { WorkOrderCategoriesService } from '@fullyops/domain/work-order-categories/services/work-order-categories.service';
import { DataModule } from '@fullyops/data/data.module';
import { ActionTypesService } from '@fullyops/domain/action-types/services/action-types.service';

@NgModule({
  exports: [],
  imports: [DataModule],
  providers: [WorkOrderCategoriesService, ActionTypesService],
})
export class DomainModule {}
