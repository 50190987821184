import { ISerialisedTravel } from './serialisations/ISerialisedTravel';
import { User } from './User';
import { TravelAssignee } from './TravelAssignee';

export class Travel {
  constructor(
    readonly assignees: TravelAssignee[] | null,
    readonly createdAt: string,
    readonly createdBy: User | null,
    readonly distance: number,
    readonly endingHour: string,
    readonly id: string,
    readonly startingHour: string,
    readonly stay: boolean,
    readonly supportTicketId: string,
    readonly installationTicketId: string,
    readonly updatedAt: string,
    readonly updatedBy: User | null
  ) {}

  static fromSerialised(serialised: ISerialisedTravel) {
    const createdBy = User.fromSerialised(serialised.createdBy);
    const updatedBy = User.fromSerialised(serialised.updatedBy);

    const assignees = serialised.assignees.map(TravelAssignee.fromSerialised);

    return new Travel(
      assignees,
      serialised.createdAt,
      createdBy,
      serialised.distance,
      serialised.endingHour,
      serialised.id,
      serialised.startingHour,
      serialised.stay,
      serialised.supportTicketId,
      serialised.installationTicketId,
      serialised.updatedAt,
      updatedBy
    );
  }

  toSerialised(): ISerialisedTravel {
    const createdByObj = this.createdBy ? this.createdBy.toSerialised() : null;
    const updatedByObj = this.updatedBy ? this.updatedBy.toSerialised() : null;

    const assigneesObj = this.assignees.map((assignee) =>
      assignee.toSerialised()
    );

    return {
      ...this,
      assignees: assigneesObj,
      createdBy: createdByObj,
      updatedBy: updatedByObj,
    };
  }

  clone(partialTravel: PartialTravel): Travel {
    const resolve = (key: keyof Travel) =>
      partialTravel.hasOwnProperty(key) ? partialTravel[key] : this[key];

    return new Travel(
      resolve('assignees'),
      resolve('createdAt'),
      resolve('createdBy'),
      resolve('distance'),
      resolve('endingHour'),
      resolve('id'),
      resolve('startingHour'),
      resolve('stay'),
      resolve('supportTicketId'),
      resolve('installationTicketId'),
      resolve('updatedAt'),
      resolve('updatedBy')
    );
  }
}
type PartialTravel = Partial<
  Pick<
    Travel,
    | 'assignees'
    | 'createdAt'
    | 'createdBy'
    | 'distance'
    | 'endingHour'
    | 'id'
    | 'startingHour'
    | 'stay'
    | 'supportTicketId'
    | 'installationTicketId'
    | 'updatedAt'
    | 'updatedBy'
  >
>;
