import { Part } from './Part';
import { ISerialisedInterventionUserParts } from './serialisations/ISerialisedInterventionUserParts';
import { UnsavedInterventionUserParts } from './UnsavedInterventionUserParts';
import { User } from './User';

export class InterventionUserParts {
  constructor(
    readonly id: string,
    readonly createdAt: string,
    readonly createdBy: User | null,
    readonly interventionId: string,
    readonly part: Part,
    readonly quantity: number,
    readonly updatedAt: string,
    readonly updatedBy: User,
    readonly user: User | null
  ) {}

  static fromSerialised(serialised: ISerialisedInterventionUserParts) {
    // serialised has a property called part and not parts
    const part = Part.fromSerialised(serialised.part);
    const createdBy = serialised.createdBy
      ? User.fromSerialised(serialised.createdBy)
      : null;
    const updatedBy = serialised.updatedBy
      ? User.fromSerialised(serialised.updatedBy)
      : null;
    const user = User.fromSerialised(serialised.user);

    return new InterventionUserParts(
      serialised.id,
      serialised.createdAt,
      createdBy,
      serialised.interventionId,
      part,
      serialised.quantity,
      serialised.updatedAt,
      updatedBy,
      user
    );
  }

  toSerialised(): ISerialisedInterventionUserParts {
    const partsObj = this.part.toSerialised();
    const userObj = this.user.toSerialised();
    const createdByObj = this.createdBy.toSerialised();
    const updatedByObj = this.updatedBy.toSerialised();

    return {
      id: this.id,
      createdAt: this.createdAt,
      createdBy: createdByObj,
      interventionId: this.interventionId,
      part: partsObj,
      quantity: this.quantity,
      updatedAt: this.updatedAt,
      updatedBy: updatedByObj,
      user: userObj,
    };
  }

  toDraft(): UnsavedInterventionUserParts {
    return UnsavedInterventionUserParts.fromSerialised({ ...this });
    // return UnsavedInterventionUserParts.fromSerialised({
    //   part: undefined,
    //   createdAt: '',
    //   createdBy: undefined,
    //   interventionId: '',
    //   quantity: 0,
    //   updatedAt: '',
    //   updatedBy: undefined,
    //   user: undefined,
    // });
  }

  clone(partialUserParts: PartialUserParts): InterventionUserParts {
    const resolve = (key: keyof InterventionUserParts) =>
      partialUserParts.hasOwnProperty(key) ? partialUserParts[key] : this[key];

    return new InterventionUserParts(
      resolve('id'),
      resolve('createdAt'),
      resolve('createdBy'),
      resolve('interventionId'),
      resolve('part'),
      resolve('quantity'),
      resolve('updatedAt'),
      resolve('updatedBy'),
      resolve('user')
    );
  }
}
type PartialUserParts = Partial<
  Pick<
    InterventionUserParts,
    | 'id'
    | 'createdAt'
    | 'createdBy'
    | 'interventionId'
    | 'part'
    | 'quantity'
    | 'updatedAt'
    | 'updatedBy'
    | 'user'
  >
>;
