import { ISerialisedUnsavedCompany } from './serialisations/ISerialisedCompany';

export class UnsavedCompany {
  constructor(
    readonly type: string,
    readonly name: string,
    readonly email: string,
    readonly address: string,
    readonly nif: string,
    readonly mobileNumber: string,
    readonly zipCode: string,
    readonly countryISOCode: string,
    readonly website: string,
    readonly notes: string,
    readonly distance: string,
    readonly travelTime: number,
    readonly externalId: string,
    readonly assigneeIds: string[],
    readonly city: string
  ) {
    if (type) this.type = type.trim();
    if (name) this.name = name.trim();
    if (email) this.email = email.trim();
    if (address) this.address = address.trim();
    if (nif) this.nif = nif.trim();
    if (mobileNumber) this.mobileNumber = mobileNumber.trim();
    if (zipCode) this.zipCode = zipCode.trim();
    if (countryISOCode) this.countryISOCode = countryISOCode.trim();
    if (website) this.website = website.trim();
    if (notes) this.notes = notes.trim();
    if (externalId) this.externalId = externalId.trim();
  }

  static fromSerialised(
    serialised: ISerialisedUnsavedCompany | PartialUnsavedCompany
  ) {
    return new UnsavedCompany(
      serialised.type || null,
      serialised.name || null,
      serialised.email || null,
      serialised.address || null,
      serialised.nif || null,
      serialised.mobileNumber || null,
      serialised.zipCode || null,
      serialised.countryISOCode || null,
      serialised.website || null,
      serialised.notes || null,
      serialised.distance || null,
      serialised.travelTime || null,
      serialised.externalId || null,
      serialised.assigneeIds || [],
      serialised.city
    );
  }

  toSerialised(): ISerialisedUnsavedCompany {
    return {
      type: this.type,
      name: this.name,
      email: this.email,
      address: this.address,
      nif: this.nif,
      mobileNumber: this.mobileNumber,
      zipCode: this.zipCode,
      countryISOCode: this.countryISOCode,
      website: this.website,
      notes: this.notes,
      distance: this.distance,
      travelTime: this.travelTime,
      externalId: this.externalId,
      assigneeIds: this.assigneeIds,
      city: this.city,
    };
  }

  clone(partialUnsavedCompany: PartialUnsavedCompany): UnsavedCompany {
    const resolve = (key: keyof UnsavedCompany) =>
      partialUnsavedCompany.hasOwnProperty(key)
        ? partialUnsavedCompany[key]
        : this[key];
    return new UnsavedCompany(
      resolve('type'),
      resolve('name'),
      resolve('email'),
      resolve('address'),
      resolve('nif'),
      resolve('mobileNumber'),
      resolve('zipCode'),
      resolve('countryISOCode'),
      resolve('website'),
      resolve('notes'),
      resolve('distance'),
      resolve('travelTime'),
      resolve('externalId'),
      resolve('assigneeIds'),
      resolve('city')
    );
  }
}

type PartialUnsavedCompany = Partial<
  Pick<
    UnsavedCompany,
    | 'type'
    | 'name'
    | 'email'
    | 'address'
    | 'nif'
    | 'mobileNumber'
    | 'zipCode'
    | 'countryISOCode'
    | 'website'
    | 'notes'
    | 'distance'
    | 'travelTime'
    | 'externalId'
    | 'assigneeIds'
    | 'city'
  >
>;
