import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
} from '@angular/core';
import { ResponseState } from '@fullyops/legacy/data';
import { BehaviorSubject } from 'rxjs';
import {
  ActionButtons,
  PaginationResult,
  PermissionType,
} from '@fullyops/legacy/ui/ui-shared/utils/crm-types';
import { ComponentInOutAnimation } from '@fullyops/legacy/ui/ui-shared/utils/component-base-animation';
import { ContactResponse } from '@fullyops/legacy/data/api/types/Contact';

@Component({
  selector: 'crm-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss'],
  animations: [ComponentInOutAnimation.AnimTrigger],
})
export class ContactsComponent implements OnInit, OnDestroy {
  constructor() {}
  @Input() title: string;
  @Input() filters$ = new BehaviorSubject<{ [key: string]: string }>({});
  @Input() listContactsPagination$ = new BehaviorSubject<
    PaginationResult<ContactResponse>
  >(null);
  @Output() openEvent = new EventEmitter<string>();
  @Output() addNewEvent = new EventEmitter();
  @Output() pageChangeEvent = new EventEmitter<number>();
  @Output() filtersChangeEvent = new EventEmitter<{}>();

  // Response Possible States
  state = ResponseState;

  actions: ActionButtons = {};
  contactsList$ = new BehaviorSubject<any[]>([]);

  // Seacrh Fielter
  filteredItems$ = new BehaviorSubject<[]>([]);

  displayedColumns: string[] = [];

  ngOnInit() {
    this.initActionButtons();
    this.displayedColumns = ['name', 'company'];
  }

  initActionButtons() {
    this.actions.primary = {
      label: 'addNew',
      color: 'primary',
      permissions: [PermissionType.CAN_EDIT_CONTACTS],
    };
  }

  /**
   * Event Handlers
   */

  onActionEvent(event: string) {
    if (event === 'addNew') {
      this.onAddNew();
    }
  }

  onOpen(id) {
    this.openEvent.emit(id);
  }

  onAddNew() {
    this.addNewEvent.emit();
  }

  onFiltersChange(filters) {
    this.filtersChangeEvent.emit(filters);
  }

  onPageChange(pageIndex: number) {
    this.pageChangeEvent.emit(pageIndex);
  }

  ngOnDestroy() {}
}
