import { Response } from '../Response';
import { HttpClient } from '@angular/common/http';
import { IApiServiceConfig } from '../IApiServiceConfig';
import { API_SERVICE_CONFIG } from '../ApiServiceConfig';
import { Inject, Injectable } from '@angular/core';
import { TicketStatusRequest, TicketStatusResponse } from '../types/Ticket';
import { BehaviorSubject, Observable } from 'rxjs';
import { ISerialisedStatus } from '../../models/v0/serialisations/ISerialisedStatus';
import { IListResponse } from '../IListResponse';
import { Status } from '../../models/v0/Status';
import { Endpoint } from '../Endpoint';

export type ResponseWrapper<results> = {
  results: results[];
};

type StatusList = IListResponse<ISerialisedStatus>;

@Injectable()
export class InstallationTicketStatusEndpoint extends Endpoint<
  Status,
  ISerialisedStatus
> {
  constructor(
    @Inject(API_SERVICE_CONFIG) private config: IApiServiceConfig,
    private http: HttpClient
  ) {
    super(Status.fromSerialised);
  }

  private readonly url = this.config.url + 'tickets/installations/status';

  getAll(
    source?: Observable<StatusList>,
    store?: BehaviorSubject<Response<Status[]>>
  ) {
    const defaultSource = this.http.get<StatusList>(this.url);
    return this.list(source || defaultSource, store);
  }

  create(body: TicketStatusRequest) {
    return this.http.post<TicketStatusResponse>(`${this.url}`, body);
  }

  getById(id: string) {
    return this.http.get<TicketStatusResponse>(`${this.url}/${id}`);
  }

  deleteById(id: string) {
    return this.http.delete(`${this.url}/${id}`);
  }

  updateBy(body: TicketStatusRequest, id: string) {
    return this.http.patch<TicketStatusResponse>(`${this.url}/${id}`, {
      body,
      id,
    });
  }
}
