import { Injectable } from '@angular/core';
import {
  Company,
  UnsavedCompany,
  CompaniesEndpoint,
  Response,
  CompaniesFilter,
} from '@fullyops/legacy/data';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { UiService } from './ui.service';

@Injectable({
  providedIn: 'root',
})
export class UiCompaniesService extends UiService<
  Company,
  UnsavedCompany,
  CompaniesEndpoint
> {
  constructor(protected companiesEndpoint: CompaniesEndpoint) {
    super(companiesEndpoint.createStore(), companiesEndpoint);
  }

  loadAll({ filters }: { filters: CompaniesFilter }) {
    this.service
      .getAll(filters, undefined, this.store$)
      .subscribe({ error: console.error });
  }

  getSelf(): Observable<Company> {
    return this.service.getAll({ type: ['SELF'], page: -1 }).pipe(
      filter((response) => response.data !== null),
      map((response) => response.data[0] as Company)
    );
  }

  getClients(filters: CompaniesFilter): Observable<Response<Company[]>> {
    return this.service.getAll({
      ...filters,
      type: ['CLIENT', 'BOTH', 'SELF'],
    });
  }

  getSuppliers(filters: CompaniesFilter): Observable<Response<Company[]>> {
    return this.service.getAll({
      ...filters,
      type: ['SUPPLIER', 'BOTH', 'SELF'],
    });
  }
}
