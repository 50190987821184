import { ISerialisedLabel } from './serialisations/ISerialisedLabel';

export class Label {
  constructor(
    readonly id: string,
    readonly label: string,
    readonly color: string
  ) {
    if (label) this.label = label.trim();
    if (color) this.color = color.trim();
  }

  static fromSerialised(serialised: ISerialisedLabel) {
    return new Label(serialised.id, serialised.label, serialised.color);
  }

  toSerialised(): ISerialisedLabel {
    return {
      id: this.id,
      label: this.label,
      color: this.color,
    };
  }
  clone(partialLabel: PartialLabel): Label {
    const resolve = (key: keyof Label) =>
      partialLabel.hasOwnProperty(key) ? partialLabel[key] : this[key];

    return new Label(resolve('id'), resolve('label'), resolve('color'));
  }
}
type PartialLabel = Partial<Pick<Label, 'id' | 'label' | 'color'>>;
