import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { InterventionPostRequest } from '@fullyops/legacy/data/api/types/Intervention';
import { AnomalyController } from '@fullyops/legacy/ui/ui-crm/ui-anomaly-controller.service';
import { SnackbarService } from '@fullyops/legacy/ui/ui-crm/ui-snackbar.service';
import { AutocompleteListItemType } from '@fullyops/legacy/ui/ui-shared/form-autocomplete-list/form-autocomplete-list.component';
import { SelectOptionType } from '@fullyops/legacy/ui/ui-shared/form-select/form-select.component';
import { TemplateFormFields } from '@fullyops/legacy/ui/ui-shared/form-template/form-template.component';
import { BehaviorSubject } from 'rxjs';
import { ActionForm } from '../action-form-component.component';
import { UiAuthService } from '@fullyops/legacy/ui';
import {
  ActionCreatePart,
  ActionCreateUncataloguedPart,
} from '../../work-order-actions/work-order-actions.component';

export type ActionGeneralFormType = FormGroup<{
  title: FormControl<string>;
  anomalies: FormControl<SelectOptionType[]>;
  equipment: FormControl<SelectOptionType | null>;
  parts: FormControl<AutocompleteListItemType[]>;
  uncataloguedParts: FormControl<AutocompleteListItemType[]>;
  description: FormControl<string>;
  userObservation: FormControl<string>;
  internalObservation: FormControl<string>;
}>;

export type CreateGeneralAction = {
  intervention: InterventionPostRequest;
  generalActionForm: ActionGeneralFormType;
};

export type EditGeneralAction = {
  intervention: InterventionPostRequest;
};

@Component({
  selector: 'crm-action-general-form',
  templateUrl: './action-general-form.component.html',
  styleUrls: ['./action-general-form.component.scss'],
})
export class ActionGeneralForm extends ActionForm implements OnInit {
  @Input() clientEquipmentListOptions$: BehaviorSubject<SelectOptionType[]>;
  @Input() anomaliesOptions$: BehaviorSubject<SelectOptionType[]>;
  @Input() partOptions$: BehaviorSubject<SelectOptionType[]>;
  @Input() uncataloguedPartsOptions$: BehaviorSubject<SelectOptionType[]>;
  @Input() showUncataloguedPart: boolean;

  @Output() createGeneralAction = new EventEmitter<CreateGeneralAction>();
  @Output() createMachineByPopUp = new EventEmitter<FormControl>();
  @Output() editGeneralAction = new EventEmitter<EditGeneralAction>();
  @Output() onCreatePart = new EventEmitter<ActionCreatePart>();
  @Output() onCreateUncataloguedPart =
    new EventEmitter<ActionCreateUncataloguedPart>();
  @Output() updatePartsList = new EventEmitter<string>();
  @Output() updateUncataloguedPartsList = new EventEmitter<string>();

  constructor(
    private formBuilder: FormBuilder,
    private anomalyController: AnomalyController,
    snackbar: SnackbarService,
    auth: UiAuthService
  ) {
    super(snackbar, auth);
  }

  formGroup: ActionGeneralFormType = this.formBuilder.group({
    title: this.formBuilder.control('', Validators.required),
    anomalies: this.formBuilder.control([]),
    equipment: this.formBuilder.control(null),
    parts: this.formBuilder.control([]),
    uncataloguedParts: this.formBuilder.control([]),
    description: this.formBuilder.control('', Validators.required),
    internalObservation: this.formBuilder.control(''),
    userObservation: this.formBuilder.control(''),
  });

  formFields: TemplateFormFields<ActionGeneralFormType> = [];

  ngOnInit(): void {
    super.ngOnInit();
    this.initFormValues();
    this.initFormFields();
  }

  initFormValues() {
    if (!this.isNew) {
      const intervention = this.intervention;
      const equipment = intervention.equipment
        ? {
            id: intervention?.equipment?.equipment?.id,
            name: intervention?.equipment?.equipment?.name,
          }
        : null;

      this.formGroup.reset({
        anomalies: intervention.anomalies.map((anomaly) => ({
          id: anomaly.id,
          name: anomaly.description,
        })),
        description: intervention.description,
        equipment,
        internalObservation: intervention.internalObservation,
        parts: intervention.parts.map((part) => ({
          itemData: part.part,
          quantity: part.quantity,
        })),
        title: intervention.title,
        uncataloguedParts: intervention.uncataloguedParts.map((uPart) => ({
          itemData: uPart.uncataloguedPart,
          quantity: uPart.quantity,
        })),
        userObservation: intervention.userObservation,
      });
    } else {
      this.formGroup.reset({
        anomalies: [],
        description: '',
        internalObservation: '',
        equipment: null,
        parts: [],
        uncataloguedParts: [],
        userObservation: '',
        title: '',
      });
    }
  }

  initFormFields() {
    this.formFields = [
      [
        { label: 'title', name: 'title', type: 'text', size: 12 },
        {
          name: 'equipment',
          label: 'equipment',
          type: 'autocomplete',
          size: 12,
          items$: this.clientEquipmentListOptions$,
          autocomplete: {
            firstOption: {
              value: 'newMachine',
              label: 'newMachine',
              fontAwesomeIcon: faPlusCircle,
              iconColor: 'green',
              onClick: () => {
                this.createMachineByPopUp.emit(
                  this.formGroup.controls['equipment']
                );
              },
            },
          },
        },
        {
          name: 'anomalies',
          label: 'anomalies',
          type: 'chips-autocomplete',
          size: 12,
          items$: this.anomaliesOptions$,
          autocomplete: {
            onInputChange: {
              debounceTime: 500,
              callback: (e) => {
                this.anomalyController.refetchByName({ description: e });
              },
            },
            onTypeEnter: {
              callback: async ({ chipsAutocompleteCtrl, inputElement }) => {
                await this.anomalyController.addAnomalyOnForm({
                  description: inputElement.value,
                  formControl: this.formGroup.controls['anomalies'],
                  chipsAutocompleteCtrl,
                  inputElement,
                });
              },
            },
            chipsDisplayWith: ({ name }: SelectOptionType) => name,
            saveFullOptionValue: true,
          },
        },
        {
          label: 'parts',
          name: 'parts',
          type: 'autocomplete-list',
          items$: this.partOptions$,
          size: 12,
          autocomplete: {
            firstOption: {
              label: 'requestPart',
              fontAwesomeIcon: faPlusCircle,
              iconColor: 'green',
              onClick: this.navigateToRequestTab,
            },
            onTypeEnter: {
              callback: (e) =>
                this.onCreatePart.emit({
                  partName: e.inputElement.value,
                  formControl: this.formGroup.controls['parts'],
                  addFormControl: e.formControl,
                }),
            },
            onInputChange: {
              debounceTime: 300,
              callback: (e) => this.updatePartsList.emit(e.newItem),
            },
          },
        },
        {
          label: 'uncataloguedParts',
          name: 'uncataloguedParts',
          type: 'autocomplete-list',
          items$: this.uncataloguedPartsOptions$,
          size: 12,
          hidden: !this.showUncataloguedPart,
          autocomplete: {
            onTypeEnter: {
              callback: (e) =>
                this.onCreateUncataloguedPart.emit({
                  partName: e.inputElement.value,
                  formControl: this.formGroup.controls['uncataloguedParts'],
                  addFormControl: e.formControl,
                }),
            },
            onInputChange: {
              debounceTime: 300,
              callback: (e) => this.updateUncataloguedPartsList.emit(e.newItem),
            },
          },
        },
        { label: 'workDone', name: 'description', type: 'textarea', size: 12 },
        {
          label: 'userObservation',
          name: 'userObservation',
          type: 'textarea',
          size: 12,
        },
        {
          label: 'internalObservation',
          name: 'internalObservation',
          type: 'textarea',
          size: 12,
        },
      ],
    ];
  }

  onCreateAction() {
    this.createGeneralAction.emit({
      intervention: this.formatToRequest(),
      generalActionForm: this.formGroup,
    });
  }

  onEditAction() {
    this.editGeneralAction.emit({ intervention: this.formatToRequest() });
  }

  formatToRequest(): InterventionPostRequest {
    const { controls } = this.formGroup;
    const interventionId = this.intervention?.id || null;
    const equipment = {
      equipmentId: controls.equipment?.value?.id,
      interventionId: this.intervention?.id,
    };

    const newInterventionData: InterventionPostRequest = {
      anomalies: controls.anomalies.value.map(({ id }) => ({
        anomalyId: id,
        interventionId,
      })),
      closedAt: this.intervention?.closedAt || null,
      description: controls.description.value,
      equipment: controls.equipment?.value?.id ? equipment : null,
      id: interventionId,
      internalObservation: controls.internalObservation.value,
      parts:
        controls.parts?.value?.map(({ itemData, quantity }) => ({
          interventionId,
          partId: itemData.id,
          quantity,
        })) || [],
      supportTicketId: this.ticket.id,
      uncataloguedParts:
        controls.uncataloguedParts?.value?.map(({ itemData, quantity }) => ({
          interventionId,
          quantity,
          uncataloguedPartId: itemData.id,
        })) || [],
      userObservation: controls.userObservation.value,
      userParts: null, //Working in progress,
      type: this.ticket.interventionType,
      title: controls.title.value,
    };

    return newInterventionData;
  }
}
