import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectorRef,
} from '@angular/core';
import { ComponentInOutAnimation } from '@fullyops/legacy/ui/ui-shared/utils/component-base-animation';
import {
  ActionButtons,
  PermissionType,
} from '@fullyops/legacy/ui/ui-shared/utils/crm-types';
import { PartFormService } from './part-form.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Company, Part, UnsavedPart } from '@fullyops/legacy/data';
import { FormComponent } from '@fullyops/legacy/ui/ui-shared/utils/form.component';
import { BehaviorSubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { HotToastService } from '@ngneat/hot-toast';
import { I18NextPipe } from 'angular-i18next';
import { SnackbarService } from '../ui-snackbar.service';
import { UiAuthService } from '../ui-auth.service';
import { PartControllerV2 } from '../ui-part-controller-v2.service';
import { ActivatedRoute } from '@angular/router';
import { PartResponse } from '@fullyops/legacy/data/api/types/Part';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  FormTemplateFields,
  TemplateFormFields,
} from '@fullyops/legacy/ui/ui-shared/form-template/form-template.component';
import { diff } from 'deep-object-diff';

@Component({
  selector: 'crm-part-form',
  templateUrl: './part-form.component.html',
  styleUrls: ['./part-form.component.scss'],
  providers: [PartFormService],
  animations: [ComponentInOutAnimation.AnimTrigger],
})
export class PartFormComponent implements OnInit {
  @Input() preview: boolean;
  @Input() isNew: boolean;
  @Input() part: PartResponse;
  @Input() companies$: BehaviorSubject<Company[]>;
  @Output() createPart = new EventEmitter<string>();
  @Output() editPart = new EventEmitter<string>();
  @Output() deletePart = new EventEmitter<string>();
  companiesList$ = new BehaviorSubject<{ id: string; name: string }[]>([]);
  isDirty$ = new BehaviorSubject<boolean>(false);

  constructor(
    public partFormService: PartFormService,
    public snackBar: SnackbarService,
    public authService: UiAuthService,
    public partControllerV2: PartControllerV2,
    protected route: ActivatedRoute,
    public changeDetectorRef: ChangeDetectorRef
  ) {}

  id = null;
  formFields: TemplateFormFields<any> = [];
  formGroup: FormGroup = null;
  actions: ActionButtons = {};

  ngOnInit() {
    this.loadCompaniesOnList();
    this.initFormGroup();
    this.initFields();
    this.onFormChangeUpdateDirtyState();
    this.loadActions();
  }

  loadActions() {
    if (this.preview == true) {
      return;
    }

    if (this.part) {
      this.actions.warn = { label: 'delete', color: 'warn' };
    }
  }

  loadCompaniesOnList() {
    this.companies$
      .pipe(
        map((companies) =>
          companies.map((company) => ({
            id: company.id,
            name: company.name,
          }))
        )
      )
      .subscribe((res) => this.companiesList$.next(res));
  }

  initFormGroup() {
    const { part } = this;
    this.formGroup = new FormGroup({
      barCode: new FormControl(part?.barCode || null),
      externalId: new FormControl(part?.externalId || null),
      name: new FormControl(part?.name || null, [Validators.required]),
      price: new FormControl(part?.price || 0, [Validators.required]),
      ref: new FormControl(part?.ref || null, [Validators.required]),
      discontinued: new FormControl(part?.discontinued || false),
    });

    this.formGroup.controls['discontinued'].disable();
    if (this.preview == true) {
      this.formGroup.disable();
    }
  }

  initFields() {
    this.formFields = [
      [
        { name: 'name', type: 'text', label: 'name', size: 12 },
        { name: 'barCode', type: 'text', label: 'barCode', size: 6 },
        { name: 'externalId', type: 'text', label: 'externalId', size: 6 },
        { name: 'ref', type: 'text', label: 'reference', size: 6 },
        { name: 'price', type: 'number', label: 'price', size: 6 },
        {
          name: 'discontinued',
          type: 'slide',
          label: 'discontinued',
          size: 12,
        },
      ],
    ];
  }

  getFormattedData() {
    return this.formGroup.value;
  }

  create() {
    return this.createPart.emit(this.getFormattedData());
  }

  edit() {
    return this.editPart.emit(this.getFormattedData());
  }

  onSubmit() {
    this.formGroup.markAllAsTouched();

    if (!this.formGroup.valid) return;

    this.part ? this.edit() : this.create();
  }

  onFormChangeUpdateDirtyState() {
    const { isDirty$, formGroup } = this;

    formGroup.valueChanges.subscribe((newValue) => {
      const oldValueToCompare = {};

      if (this.part) {
        Object.keys(newValue).forEach((key) => {
          oldValueToCompare[key] = this.part[key];
        });
      }

      const differences = diff(oldValueToCompare, newValue);

      if (Object.keys(differences).length > 0) {
        return !isDirty$.value ? isDirty$.next(true) : null;
      } else {
        return isDirty$.value ? isDirty$.next(false) : null;
      }
    });
  }

  onActionEvent(event) {
    if (event == 'delete') return this.deletePart.emit();
  }
}
