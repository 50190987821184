import { UnsavedInterventionAnomaly } from '@fullyops/legacy/data';
import { Anomaly } from './Anomaly';
import { ClientEquipment } from './ClientEquipment';
import { ISerialisedInterventionAnomaly } from './serialisations/ISerialisedInterventionAnomaly';
import { User } from './User';

export class InterventionAnomaly {
  constructor(
    readonly id: string,
    readonly type: null, // TODO: Switch to AnomalyType when we start using them
    readonly createdAt: string,
    readonly createdBy: User | null,
    readonly interventionId: string,
    readonly updatedAt: string,
    readonly updatedBy: User | null,
    readonly description: string,
    readonly deleted: boolean
  ) {}

  static fromSerialised(serialised: ISerialisedInterventionAnomaly) {
    const createdBy = serialised.createdBy
      ? User.fromSerialised(serialised.createdBy)
      : null;
    const updatedBy = serialised.updatedBy
      ? User.fromSerialised(serialised.updatedBy)
      : null;

    return new InterventionAnomaly(
      serialised.id,
      null, // TODO: Switch to AnomalyType when we start using them
      serialised.createdAt,
      createdBy,
      serialised.interventionId,
      serialised.updatedAt,
      updatedBy,
      serialised.description,
      serialised.deleted
    );
  }

  toSerialised(): ISerialisedInterventionAnomaly {
    const createdByObj = this.createdBy.toSerialised();
    const updatedByObj = this.updatedBy.toSerialised();

    return {
      id: this.id,
      type: null, // TODO: Switch to AnomalyType when we start using them
      createdAt: this.createdAt,
      createdBy: createdByObj,
      interventionId: this.interventionId,
      updatedAt: this.updatedAt,
      updatedBy: updatedByObj,
      description: this.description,
      deleted: this.deleted,
    };
  }

  toDraft(): UnsavedInterventionAnomaly {
    const interventionAnomaly = this.toSerialised();

    return UnsavedInterventionAnomaly.fromSerialised({
      id: undefined,
      type: null, // TODO: Switch to AnomalyType when we start using them
      createdAt: undefined,
      createdBy: interventionAnomaly.createdBy,
      interventionId: this.interventionId,
      updatedAt: undefined,
      updatedBy: interventionAnomaly.updatedBy,
      description: this.description,
      deleted: this.deleted,
    });
  }

  clone(
    partialInterventionAnomaly: InterventionAnomalyPartial
  ): InterventionAnomaly {
    const resolve = (key: keyof InterventionAnomaly) =>
      partialInterventionAnomaly.hasOwnProperty(key)
        ? partialInterventionAnomaly[key]
        : this[key];

    return new InterventionAnomaly(
      resolve('id'),
      resolve('type'), // TODO: Switch to AnomalyType when we start using them
      resolve('createdAt'),
      resolve('createdBy'),
      resolve('interventionId'),
      resolve('updatedAt'),
      resolve('updatedBy'),
      resolve('description'),
      resolve('deleted')
    );
  }
}
type InterventionAnomalyPartial = Partial<
  Pick<
    InterventionAnomaly,
    | 'id'
    | 'type'
    | 'createdAt'
    | 'createdBy'
    | 'interventionId'
    | 'updatedAt'
    | 'updatedBy'
    | 'description'
    | 'deleted'
  >
>;
