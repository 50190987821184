import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import {
  FieldType,
  ActionButtons,
} from '@fullyops/legacy/ui/ui-shared/utils/crm-types';
import { ComponentInOutAnimation } from '@fullyops/legacy/ui/ui-shared/utils/component-base-animation';
import {
  Response,
  ResponseState,
  InterventionUserParts,
} from '@fullyops/legacy/data';
import { BehaviorSubject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'crm-users-parts',
  templateUrl: './users-parts.component.html',
  styleUrls: ['./users-parts.component.scss'],
  animations: [ComponentInOutAnimation.AnimTrigger],
})
export class UsersPartsComponent implements OnInit {
  @Input() title: string;
  @Input() usersParts$ = new BehaviorSubject<Response<InterventionUserParts[]>>(
    new Response([])
  );
  @Output() openEvent = new EventEmitter<string>();

  // Response Possible States
  state = ResponseState;

  actions: ActionButtons = {};
  usersPartsList$ = new BehaviorSubject<[]>([]);

  ngOnInit() {
    this.initPartsList();
  }

  initPartsList() {
    this.usersParts$
      .pipe(
        filter((response) => response.data !== null),
        map((response) => response.data as InterventionUserParts[]),
        map((userParts) => userParts.map(this.parseUserParts))
      )
      .subscribe(this.usersPartsList$);
  }

  parseUserParts(userParts: any) {
    return {
      id: userParts.user.id,
      columns: {
        name: {
          label: 'name',
          type: FieldType.User,
          value: `${userParts.user.firstName} ${userParts.user.lastName}`,
        },
        numParts: {
          label: 'numParts',
          type: FieldType.Text,
          value: userParts.parts.length,
        },
      },
    };
  }

  onOpen(id: string) {
    this.openEvent.emit(id);
  }
}
