<div class="client-equipment-modal">
  <crm-form-template
    [formGroup]="newClientEquipmentForm"
    [formFields]="formFields"
  >
  </crm-form-template>

  <div class="client-equipment-row-buttons">
    <button
      mat-flat-button
      type="button"
      (click)="cancel()"
      class="mat-flat-button-style custom-button-icon-red"
    >
      {{ 'forms:cancel' | i18nextCap }}
    </button>
    <button
      mat-flat-button
      type="button"
      (click)="createMachine()"
      class="mat-flat-button-style custom-button-icon-green"
    >
      {{ 'forms:create' | i18nextCap }}
    </button>
  </div>
</div>
