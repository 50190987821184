import { ISerialisedContact } from './serialisations/ISerialisedContact';
import { Company } from './Company';
import { UnsavedContact } from './UnsavedContact';

type stringTrimmed = string;
export class Contact {
  constructor(
    readonly id: string,
    readonly name: string,
    readonly email: string,
    readonly address: string,
    readonly mobileNumber: string,
    readonly notes: string,
    readonly company: Company,
    readonly jobPosition: string
  ) {
    if (name) this.name = name.trim();
    if (email) this.email = email.trim();
    if (address) this.address = address.trim();
    if (mobileNumber) this.mobileNumber = mobileNumber.trim();
    if (notes) this.notes = notes.trim();
  }

  static fromSerialised(serialised: ISerialisedContact) {
    const company = Company.fromSerialised(serialised.company);

    return new Contact(
      serialised.id,
      serialised.name,
      serialised.email,
      serialised.address,
      serialised.mobileNumber,
      serialised.notes,
      company,
      serialised.jobPosition
    );
  }

  toSerialised(): ISerialisedContact {
    const serialisedCompany = this.company.toSerialised();

    return {
      id: this.id,
      name: this.name,
      email: this.email,
      address: this.address,
      mobileNumber: this.mobileNumber,
      notes: this.notes,
      company: serialisedCompany,
      jobPosition: this.jobPosition,
    };
  }

  toDraft(): UnsavedContact {
    return UnsavedContact.fromSerialised({
      ...this.toSerialised(),
      companyId: this.company.id,
    });
  }

  clone(partialContact: PartialContact): Contact {
    const resolve = (key: keyof Contact) =>
      partialContact.hasOwnProperty(key) ? partialContact[key] : this[key];
    return new Contact(
      resolve('id'),
      resolve('name'),
      resolve('email'),
      resolve('address'),
      resolve('mobileNumber'),
      resolve('notes'),
      resolve('company'),
      resolve('jobPosition')
    );
  }
}

type PartialContact = Partial<
  Pick<
    Contact,
    | 'id'
    | 'name'
    | 'email'
    | 'address'
    | 'mobileNumber'
    | 'notes'
    | 'company'
    | 'jobPosition'
  >
>;
