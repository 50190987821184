import { ISerialisedUnsavedIntervention } from './serialisations/ISerialisedIntervention';
import { UnsavedInterventionUserParts } from './UnsavedInterventionUserParts';
import { TicketRequestPart } from './TicketRequestPart';

export class UnsavedIntervention {
  constructor(
    readonly description: string,
    readonly parts: TicketRequestPart[],
    readonly userParts: { [userId: string]: UnsavedInterventionUserParts },
    readonly internalObservation: string,
    readonly userObservation: string,
    readonly supportTicketId: string,
    readonly installationTicketId: string,
    readonly unregisteredParts: string,
    readonly closedAt: string
  ) {
    if (description) this.description = description.trim();
    if (internalObservation)
      this.internalObservation = internalObservation.trim();
    if (userObservation) this.userObservation = userObservation.trim();
    if (unregisteredParts) this.unregisteredParts = unregisteredParts.trim();
  }

  static fromSerialised(serialised: ISerialisedUnsavedIntervention) {
    const parts = serialised.parts.map(TicketRequestPart.fromSerialised);

    const userParts = {};
    Object.keys(serialised.userParts).forEach(
      (key) =>
        (userParts[key] = UnsavedInterventionUserParts.fromSerialised(
          serialised.userParts[key]
        ))
    );

    return new UnsavedIntervention(
      serialised.description,
      parts,
      userParts,
      serialised.internalObservation,
      serialised.userObservation,
      serialised.supportTicketId,
      serialised.installationTicketId,
      serialised.unregisteredParts,
      serialised.closedAt
    );
  }

  toSerialised(): ISerialisedUnsavedIntervention {
    const partsObj = this.parts.map((part) => part.toSerialised());

    const userPartsObj = {};
    Object.keys(this.userParts).forEach(
      (key) => (userPartsObj[key] = this.userParts[key].toSerialised())
    );

    return {
      description: this.description,
      parts: partsObj,
      userParts: userPartsObj,
      internalObservation: this.internalObservation,
      userObservation: this.userObservation,
      supportTicketId: this.supportTicketId,
      installationTicketId: this.installationTicketId,
      unregisteredParts: this.unregisteredParts,
      closedAt: this.closedAt,
    };
  }

  clone(
    partialUnsavedIntervention: PartialUnsavedIntervention
  ): UnsavedIntervention {
    const resolve = (key: keyof UnsavedIntervention) =>
      partialUnsavedIntervention.hasOwnProperty(key)
        ? partialUnsavedIntervention[key]
        : this[key];

    return new UnsavedIntervention(
      resolve('description'),
      resolve('parts'),
      resolve('userParts'),
      resolve('internalObservation'),
      resolve('userObservation'),
      resolve('supportTicketId'),
      resolve('installationTicketId'),
      resolve('unregisteredParts'),
      resolve('closedAt')
    );
  }
}
type PartialUnsavedIntervention = Partial<
  Pick<
    UnsavedIntervention,
    | 'description'
    | 'parts'
    | 'userParts'
    | 'internalObservation'
    | 'userObservation'
    | 'supportTicketId'
    | 'installationTicketId'
    | 'unregisteredParts'
    | 'closedAt'
  >
>;
