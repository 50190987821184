import { Injectable } from '@angular/core';
import { WorkOrderCategoriesService } from '@fullyops/domain/work-order-categories/services/work-order-categories.service';
import { BehaviorSubject } from 'rxjs';
import { SelectOptionModel } from '../models/form-model.models';
import { ActionTypesService } from '@fullyops/domain/action-types/services/action-types.service';

@Injectable({ providedIn: 'root' })
export class ActionTypeUtils {
  constructor(private service: ActionTypesService) {}

  private actionTypesOptions$ = new BehaviorSubject<SelectOptionModel[]>([]);

  getOptions$() {
    this.service.actionTypesOptions$.subscribe((actionTypes) => {
      this.actionTypesOptions$.next(actionTypes);
    });

    return this.actionTypesOptions$;
  }
}
