import { ISerialisedUnsavedPart } from './serialisations/ISerialisedPart';

export class UnsavedPart {
  constructor(
    readonly id: string,
    readonly name: string,
    readonly ref: string,
    readonly price: number
  ) {
    if (name) this.name = name.trim();
    if (ref) this.ref = ref.trim();
  }

  static fromSerialised(serialised: ISerialisedUnsavedPart) {
    return new UnsavedPart(
      serialised.id,
      serialised.name,
      serialised.ref,
      serialised.price
    );
  }

  toSerialised(): ISerialisedUnsavedPart {
    return {
      id: this.id,
      name: this.name,
      ref: this.ref,
      price: this.price,
    };
  }

  clone(partialUnsavedPart: PartialUnsavedPart): UnsavedPart {
    const resolve = (key: keyof UnsavedPart) =>
      partialUnsavedPart.hasOwnProperty(key)
        ? partialUnsavedPart[key]
        : this[key];

    return new UnsavedPart(
      resolve('id'),
      resolve('name'),
      resolve('ref'),
      resolve('price')
    );
  }
}
type PartialUnsavedPart = Partial<
  Pick<UnsavedPart, 'id' | 'name' | 'ref' | 'price'>
>;
