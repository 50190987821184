import { User } from './User';
import { ISerialisedTicketSupportMultimachineAssignee } from './serialisations/ISerialisedTicketSupportMultimachineAssignee';
import { ISerialisedTicketInstallationAssignee } from './serialisations/ISerialisedTicketInstallationAssignee';

export class TicketInstallationAssignee {
  constructor(
    readonly id: string,
    readonly assignee: User,
    readonly createdAt: string,
    readonly createdBy: User,
    readonly deleted: boolean,
    readonly installationTicketId: string,
    readonly updatedAt: string,
    readonly updatedBy: User
  ) {}

  static fromSerialised(serialised: ISerialisedTicketInstallationAssignee) {
    const assignee = User.fromSerialised(serialised.assignee);
    const createdBy = User.fromSerialised(serialised.createdBy);
    const updatedBy = User.fromSerialised(serialised.updatedBy);

    return new TicketInstallationAssignee(
      '',
      assignee,
      serialised.createdAt,
      createdBy,
      serialised.deleted,
      serialised.installationTicketId,
      serialised.updatedAt,
      updatedBy
    );
  }

  toSerialised(): ISerialisedTicketInstallationAssignee {
    const assigneeObj = this.assignee.toSerialised();
    const createdByObj = this.createdBy.toSerialised();
    const updatedByObj = this.updatedBy.toSerialised();

    return {
      id: '',
      assignee: assigneeObj,
      createdBy: createdByObj,
      updatedBy: updatedByObj,
      installationTicketId: this.installationTicketId,
      ...this,
    };
  }

  clone(
    partialTicketSupport: PartialTicketInstallationAssignee
  ): TicketInstallationAssignee {
    const resolve = (key: keyof TicketInstallationAssignee) =>
      partialTicketSupport.hasOwnProperty(key)
        ? partialTicketSupport[key]
        : this[key];

    return new TicketInstallationAssignee(
      resolve('id'),
      resolve('assignee'),
      resolve('createdAt'),
      resolve('createdBy'),
      resolve('deleted'),
      resolve('installationTicketId'),
      resolve('updatedAt'),
      resolve('updatedBy')
    );
  }
}

type PartialTicketInstallationAssignee = Partial<
  Pick<
    TicketInstallationAssignee,
    | 'id'
    | 'assignee'
    | 'createdAt'
    | 'createdBy'
    | 'deleted'
    | 'installationTicketId'
    | 'updatedAt'
    | 'updatedBy'
  >
>;
