import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Part } from '@fullyops/legacy/data';
import { BehaviorSubject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'crm-form-parts-autocomplete',
  templateUrl: './form-parts-autocomplete.component.html',
  styleUrls: ['./form-parts-autocomplete.component.scss'],
})
export class FormPartsAutocompleteComponent implements OnInit {
  @Input() canAddParts = true;
  @Input() parts$: BehaviorSubject<Part[]>;
  @Output() addPartEvent = new EventEmitter();
  @Output() createPartEvent = new EventEmitter<string>();

  partSearchCtrl = new UntypedFormControl();
  filteredParts$ = new BehaviorSubject<Part[]>([]);

  showAddButton = false;

  ngOnInit() {
    this.handleSearchChanges();

    this.parts$.subscribe(this.filteredParts$);
  }

  handleSearchChanges() {
    this.partSearchCtrl.valueChanges
      .pipe(
        startWith(''),
        map((part) => (part ? this._filterParts(part) : this.parts$.getValue()))
      )
      .subscribe(this.filteredParts$);
  }

  private _filterParts(value: string): Part[] {
    const filterValue = value.toLowerCase();

    const results = this.parts$
      .getValue()
      .filter((state) => state.name.toLowerCase().includes(filterValue));

    this.showAddButton = results.length === 0;
    return results;
  }

  onAddPart({ option }) {
    this.addPartEvent.emit(
      this.parts$.getValue().find((part) => part.ref === option.value)
    );
    this.partSearchCtrl.patchValue('');
  }

  onCreatePart() {
    this.createPartEvent.emit(this.partSearchCtrl.value);
    this.partSearchCtrl.patchValue('');
  }
}
