import { ISerialisedIntervention } from './serialisations/ISerialisedIntervention';
import { UnsavedIntervention } from './UnsavedIntervention';
import { InterventionUserParts } from './InterventionUserParts';
import { TicketRequestMachine } from './TicketRequestMachine';
import { TicketRequestPart } from './TicketRequestPart';
import { UnsavedInterventionUserParts } from './UnsavedInterventionUserParts';
import { InterventionMachine } from './InterventionMachine';
import { InterventionAnomaly } from './InterventionAnomaly';
import { WorkPeriod } from './WorkPeriod';

export class Intervention {
  constructor(
    readonly id: string,
    readonly description: string,
    readonly anomalies: InterventionAnomaly[],
    readonly closedAt: string,
    readonly parts: TicketRequestPart[],
    readonly userParts: { [key: string]: InterventionUserParts },
    readonly internalObservation: string,
    readonly deleted: boolean,
    readonly supportTicketId: string,
    readonly installationTicketId: string,
    readonly unregisteredParts: string,
    readonly userObservation: string,
    readonly workPeriods: WorkPeriod[]
  ) {
    if (description) this.description = description.trim();
    if (internalObservation)
      this.internalObservation = internalObservation.trim();
    if (unregisteredParts) this.unregisteredParts = unregisteredParts.trim();
    if (userObservation) this.userObservation = userObservation.trim();
  }

  static fromSerialised(serialised: ISerialisedIntervention) {
    const parts = serialised.parts.map(TicketRequestPart.fromSerialised);

    const anomalies = serialised.anomalies.map(
      InterventionAnomaly.fromSerialised
    );

    const workPeriods = serialised.workPeriods.map(WorkPeriod.fromSerialised);

    const userParts = {};
    Object.keys(serialised.userParts).forEach(
      (key) =>
        (userParts[key] = InterventionUserParts.fromSerialised(
          serialised.userParts[key]
        ))
    );

    return new Intervention(
      serialised.id,
      serialised.description,
      anomalies,
      serialised.closedAt,
      parts,
      userParts,
      serialised.internalObservation,
      serialised.deleted,
      serialised.supportTicketId,
      serialised.installationTicketId,
      serialised.unregisteredParts,
      serialised.userObservation,
      workPeriods
    );
  }

  toSerialised(): ISerialisedIntervention {
    const partsObj = this.parts.map((part) => part.toSerialised());

    const anomaliesObj = this.anomalies.map((anomaly) =>
      anomaly.toSerialised()
    );

    const workPeriodsObj = this.workPeriods.map((workPeriod) =>
      workPeriod.toSerialised()
    );

    const userPartsObj = {};
    Object.keys(this.userParts).forEach(
      (key) => (userPartsObj[key] = this.userParts[key].toSerialised())
    );

    return {
      id: this.id,
      anomalies: anomaliesObj,
      closedAt: this.closedAt,
      description: this.description,
      parts: partsObj,
      userParts: userPartsObj,
      internalObservation: this.internalObservation,
      deleted: this.deleted,
      supportTicketId: this.supportTicketId,
      installationTicketId: this.installationTicketId,
      unregisteredParts: this.unregisteredParts,
      userObservation: this.userObservation,
      workPeriods: workPeriodsObj,
    };
  }

  toDraft(): UnsavedIntervention {
    const userParts: { [userId: string]: UnsavedInterventionUserParts } = {};

    Object.keys(this.userParts).forEach(
      (key) =>
        (userParts[key] = UnsavedInterventionUserParts.fromSerialised(
          this.userParts[key].toDraft()
        ))
    );

    return UnsavedIntervention.fromSerialised({
      ...this,
      parts: this.parts.map((part) => part.toDraft()),
      userParts,
      internalObservation: this.internalObservation,
    });
  }

  clone(partialIntervention: PartialIntervention): Intervention {
    const resolve = (key: keyof Intervention) =>
      partialIntervention.hasOwnProperty(key)
        ? partialIntervention[key]
        : this[key];

    return new Intervention(
      resolve('id'),
      resolve('description'),
      resolve('anomalies'),
      resolve('closedAt'),
      resolve('parts'),
      resolve('userParts'),
      resolve('internalObservation'),
      resolve('deleted'),
      resolve('supportTicketId'),
      resolve('installationTicketId'),
      resolve('unregisteredParts'),
      resolve('userObservation'),
      resolve('workPeriods')
    );
  }
}
type PartialIntervention = Partial<
  Pick<
    Intervention,
    | 'id'
    | 'description'
    | 'anomalies'
    | 'closedAt'
    | 'parts'
    | 'userParts'
    | 'internalObservation'
    | 'deleted'
    | 'supportTicketId'
    | 'installationTicketId'
    | 'unregisteredParts'
    | 'userObservation'
    | 'workPeriods'
  >
>;
