import { map } from 'rxjs/operators';
import { FeaturePermissionToggle } from '@fullyops/legacy/data/api/types/FeaturePermissionToggle';
import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { API_SERVICE_CONFIG } from '@fullyops/legacy/data';
import { IApiServiceConfig } from '@fullyops/legacy/data/api/IApiServiceConfig';
import { PageResponseWrapper } from '@fullyops/legacy/data/api/types/HttpResponses';
import { BehaviorSubject } from 'rxjs';
import { UiServiceV2 } from '@fullyops/legacy/ui/ui-crm/ui.service-v2.service';

@Injectable({
  providedIn: 'root',
})
export class FeaturePermissionToggleServiceV2 extends UiServiceV2 {
  constructor(
    @Inject(API_SERVICE_CONFIG) private config: IApiServiceConfig,
    private http: HttpClient
  ) {
    super();
  }

  private featuresPermissions$: BehaviorSubject<FeaturePermissionToggle[]> =
    new BehaviorSubject(null);

  private readonly url = this.config.url + 'toggles/feature';

  private getAllRequest() {
    return this.http.get<PageResponseWrapper<FeaturePermissionToggle>>(
      `${this.url}`
    );
  }

  getAllV2() {
    if (this.featuresPermissions$.value === null) {
      this.featuresPermissions$.next([]);
      this.getAllRequest().subscribe((res) => {
        this.featuresPermissions$.next(res.results);
      });
    }
    return this.featuresPermissions$;
  }

  getByLabel(label: string) {
    return this.http.get<FeaturePermissionToggle>(`${this.url}/${label}`);
  }

  isFeatureDisabled(props: { featureLabel: FeaturePermissionToggle['label'] }) {
    this.getAllV2();
    const { featureLabel } = props;

    const response = this.featuresPermissions$.pipe(
      map((features) => {
        const found = features.find(
          (feature) => feature.label === featureLabel
        );
        return found;
      }),
      map((feature) => feature?.enabled === false)
    );

    return response;
  }

  isFeatureEnabled(props: { featureLabel: FeaturePermissionToggle['label'] }) {
    this.getAllV2();
    const { featureLabel } = props;

    const response = this.featuresPermissions$.pipe(
      map((features) => {
        const found = features.find(
          (feature) => feature.label === featureLabel
        );
        return found;
      }),
      map((feature) => feature?.enabled == true)
    );

    return response;
  }

  isFeatureEnabledNoAsync(props: {
    store: FeaturePermissionToggle[];
    featureLabel: FeaturePermissionToggle['label'];
  }) {
    return props.store
      .filter((feature) => feature.enabled)
      .some((feature) => feature.label === props.featureLabel);
  }
}
