import { ISerialisedAnomaly } from './serialisations/ISerialisedAnomaly';
import { UnsavedAnomaly } from './UnsavedAnomaly';
import { User } from './User';

export class Anomaly {
  constructor(
    readonly createdAt: string,
    readonly createdBy: User | null,
    readonly description: string,
    readonly id: string,
    readonly photos: string[],
    readonly type: string,
    readonly updatedAt: string,
    readonly updatedBy: User | null
  ) {
    if (description) this.description = description.trim();
    if (type) this.type = type.trim();
  }

  static fromSerialised(serialised: ISerialisedAnomaly) {
    const createdBy = serialised.createdBy
      ? User.fromSerialised(serialised.createdBy)
      : null;
    const updatedBy = serialised.updatedBy
      ? User.fromSerialised(serialised.updatedBy)
      : null;

    return new Anomaly(
      serialised.createdAt,
      createdBy,
      serialised.description,
      serialised.id,
      serialised.photos ? serialised.photos : [],
      serialised.type,
      serialised.updatedAt,
      updatedBy
    );
  }

  toSerialised(): ISerialisedAnomaly {
    const createdByObj = this.createdBy.toSerialised();
    const updatedByObj = this.updatedBy.toSerialised();

    return {
      createdAt: this.createdAt,
      createdBy: createdByObj,
      description: this.description,
      id: this.id,
      photos: this.photos,
      type: this.type,
      updatedAt: this.updatedAt,
      updatedBy: updatedByObj,
    };
  }

  toDraft(): UnsavedAnomaly {
    return UnsavedAnomaly.fromSerialised({
      ...this.toSerialised(),
    });
  }

  clone(partialAnomaly: PartialAnomaly): Anomaly {
    const resolve = (key: keyof Anomaly) =>
      partialAnomaly.hasOwnProperty(key) ? partialAnomaly[key] : this[key];

    return new Anomaly(
      resolve('createdAt'),
      resolve('createdBy'),
      resolve('description'),
      resolve('id'),
      resolve('photos'),
      resolve('type'),
      resolve('updatedAt'),
      resolve('updatedBy')
    );
  }
}
type PartialAnomaly = Partial<
  Pick<
    Anomaly,
    | 'createdAt'
    | 'createdBy'
    | 'description'
    | 'id'
    | 'photos'
    | 'type'
    | 'updatedAt'
    | 'updatedBy'
  >
>;
