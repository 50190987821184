import { ISerialisedStatus } from './serialisations/ISerialisedStatus';

export class Status {
  constructor(
    readonly id: string,
    readonly color: string,
    readonly deleted: boolean,
    readonly isFinal: boolean,
    readonly isStart: boolean,
    readonly name: string,
    readonly position: number,
    readonly precedeStatuses: string[],
    readonly succeedStatuses: string[]
  ) {
    if (name) this.name = name.trim();
    if (color) this.color = color.trim();
  }

  static fromSerialised(serialised: ISerialisedStatus) {
    return new Status(
      serialised.id,
      serialised.color,
      serialised.deleted,
      serialised.isFinal,
      serialised.isStart,
      serialised.name,
      serialised.position,
      serialised.precedeStatuses,
      serialised.succeedStatuses
    );
  }

  toSerialised(): ISerialisedStatus {
    return {
      id: this.id,
      color: this.color,
      deleted: this.deleted,
      isFinal: this.isFinal,
      isStart: this.isStart,
      name: this.name,
      position: this.position,
      precedeStatuses: this.precedeStatuses,
      succeedStatuses: this.succeedStatuses,
    };
  }

  clone(partialStatus: PartialStatus): Status {
    const resolve = (key: keyof Status) =>
      partialStatus.hasOwnProperty(key) ? partialStatus[key] : this[key];

    return new Status(
      resolve('id'),
      resolve('color'),
      resolve('deleted'),
      resolve('isFinal'),
      resolve('isStart'),
      resolve('name'),
      resolve('position'),
      resolve('precedeStatuses'),
      resolve('succeedStatuses')
    );
  }
}
type PartialStatus = Partial<
  Pick<
    Status,
    | 'id'
    | 'color'
    | 'deleted'
    | 'isFinal'
    | 'isStart'
    | 'name'
    | 'position'
    | 'precedeStatuses'
    | 'succeedStatuses'
  >
>;
