import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { UnsavedPart } from '@fullyops/legacy/data';

class PartForm {
  name = new UntypedFormControl();
  ref = new UntypedFormControl();
  price = new UntypedFormControl();

  constructor(part: UnsavedPart, dis: boolean) {
    const fb = new UntypedFormBuilder();
    this.name = fb.control(
      { value: part.name, disabled: dis },
      Validators.required
    );
    this.ref = fb.control(
      { value: part.ref, disabled: dis },
      Validators.required
    );
    this.price = fb.control({ value: part.price, disabled: dis }, []);
  }
}

@Injectable()
export class PartFormService {
  fb = new UntypedFormBuilder();

  private form: BehaviorSubject<UntypedFormGroup> =
    new BehaviorSubject<UntypedFormGroup>(new UntypedFormGroup({}));
  form$: Observable<UntypedFormGroup> = this.form.asObservable();

  constructor() {}

  initForm(draftPart: UnsavedPart, disabled: boolean) {
    this.form.next(this.fb.group(new PartForm(draftPart, disabled)));
  }

  getDraft() {
    return new UnsavedPart(
      null,
      (this.form.getValue().get('name') as UntypedFormControl).value,
      (this.form.getValue().get('ref') as UntypedFormControl).value,
      (this.form.getValue().get('price') as UntypedFormControl).value
    );
  }

  markAllAsTouched() {
    const currentPart = this.form.getValue();
    currentPart.markAllAsTouched();

    this.form.next(currentPart);
  }
}
