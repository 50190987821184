<mat-card class="items-component">
  <div class="items-component-title-div">
    <div class="title">
      <span>{{ 'translation:' + field.label | i18nextCap }}</span>
    </div>
  </div>

  <div class="items-list" *ngIf="formControl?.value?.length > 0">
    <mat-card
      class="item"
      *ngFor="let item of formControl.value; let i = index"
    >
      <div class="item-left">
        <div class="item-left-left">
          <div class="icon blue-icon">
            <fa-icon [icon]="faScrewdriverWrench" size="lg"></fa-icon>
          </div>

          <div class="text">
            <span>{{ item.itemData.name }}</span>

            <span class="ref" *ngIf="item?.itemData?.ref">
              Ref: {{ item.itemData.ref }}
            </span>
          </div>
        </div>

        <div class="update-quantity-input">
          <button
            mat-flat-button
            class="left"
            type="button"
            (click)="updateQuantity(i, +item.quantity - 1)"
            [disabled]="addItemFormGroup.disabled"
          >
            -
          </button>
          <input
            matInput
            type="number"
            (change)="updateQuantity(i, $any($event).target.valueAsNumber)"
            [value]="item.quantity"
            [disabled]="addItemFormGroup.disabled"
          />

          <button
            mat-flat-button
            class="right"
            type="button"
            (click)="updateQuantity(i, +item.quantity + 1)"
            [disabled]="addItemFormGroup.disabled"
          >
            +
          </button>
        </div>
      </div>

      <div class="item-right">
        <button
          mat-icon-button
          (click)="removeItem(i)"
          type="button"
          [disabled]="addItemFormGroup.disabled"
        >
          <!-- [hideIfNotAllowed]="[CAN_EDIT_INTERVENTION_PARTS]" -->
          <div class="icon red-icon">
            <fa-icon [icon]="faTrashCan"></fa-icon>
          </div>
        </button>
      </div>
    </mat-card>
  </div>

  <crm-form-template
    [formGroup]="addItemFormGroup"
    [formFields]="addItemField"
  ></crm-form-template>
</mat-card>
