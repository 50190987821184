import { InterventionMachine } from './InterventionMachine';
import { ISerialisedInstallationTicket } from './serialisations/ISerialisedInstallationTicket';
import { Status } from './Status';
import { Company } from './Company';
import { User } from './User';
import { UnsavedTicketInstallation } from './UnsavedTicketInstallation';
import { Contact } from './Contact';
import { ClientInfo } from './ClientInfo';

export class TicketInstallation {
  constructor(
    readonly assignees: User[],
    readonly closedAt: string,
    readonly company: Company,
    readonly contacts: Contact[],
    readonly clientInfo: ClientInfo,
    readonly createdAt: Date,
    readonly createdBy: User | null,
    readonly deleted: boolean,
    readonly deletedAt: string,
    readonly equipments: Object, //  Need to create another model
    readonly executedAt: string,
    readonly id: string,
    readonly identifier: number,
    readonly interventions: InterventionMachine[],
    readonly name: string,
    readonly priority: 'LOW' | 'MEDIUM' | 'HIGH' | 'URGENT',
    readonly status: Status,
    readonly updatedAt: string,
    readonly updatedBy: User | null
  ) {
    if (executedAt) this.executedAt = executedAt.trim();
    if (name) this.name = name.trim();
  }

  static fromSerialised(serialised: ISerialisedInstallationTicket) {
    const assignees = serialised.assignees.map(User.fromSerialised);
    const company = Company.fromSerialised(serialised.company);
    const contacts = serialised.contacts.map(Contact.fromSerialised);
    const clientInfo = ClientInfo.fromSerialised(serialised.clientInfo);
    const interventions = serialised.interventions.map(
      InterventionMachine.fromSerialised
    );
    const status = Status.fromSerialised(serialised.status);
    const createdBy = serialised.createdBy
      ? User.fromSerialised(serialised.createdBy)
      : null;

    const updatedBy = serialised.updatedBy
      ? User.fromSerialised(serialised.updatedBy)
      : null;

    return new TicketInstallation(
      assignees,
      serialised.closedAt,
      company,
      contacts,
      clientInfo,
      serialised.createdAt ? new Date(serialised.createdAt) : null,
      createdBy,
      serialised.deleted,
      serialised.deletedAt,
      serialised.equipments, //  Need to create another model
      serialised.executedAt,
      serialised.id,
      serialised.identifier,
      interventions,
      serialised.name,
      serialised.priority,
      status,
      serialised.updatedAt,
      updatedBy
    );
  }

  toSerialised(): ISerialisedInstallationTicket {
    const assigneesObj = this.assignees.map((assignee) =>
      assignee.toSerialised()
    );
    const contactsObj = this.contacts.map((contact) => contact.toSerialised());
    const serialisedCompany = this.company.toSerialised();
    const serialisedCreatedBy = this.createdBy.toSerialised();
    const serialisedUpdatedBy = this.updatedBy.toSerialised();
    const statusObj = this.status.toSerialised();
    const clientInfoObj = this.clientInfo.toSerialised();

    return {
      assignees: assigneesObj,
      closedAt: this.closedAt,
      company: serialisedCompany,
      contacts: contactsObj,
      clientInfo: clientInfoObj,
      createdAt: this.createdAt.toISOString(),
      createdBy: serialisedCreatedBy,
      deleted: this.deleted,
      deletedAt: this.deletedAt,
      equipments: this.equipments, //  Need to create another model
      executedAt: this.executedAt,
      id: this.id,
      identifier: this.identifier,
      interventions: this.interventions,
      name: this.name,
      priority: this.priority,
      status: statusObj,
      updatedAt: this.updatedAt,
      updatedBy: serialisedUpdatedBy,
    };
  }

  toDraft(): UnsavedTicketInstallation {
    const installationTicket = this.toSerialised();

    const interventions = this.interventions.map((intervention) =>
      intervention.toDraft()
    );

    return UnsavedTicketInstallation.fromSerialised({
      ...installationTicket,
      assigneeIds: this.assignees.map((assignee) => assignee.id),
      contactIds: this.contacts.map((contact) => contact.id),
      interventions,
      statusId: this.status.id,
      companyId: this.company.id,
    });
  }

  clone(
    partialTicketInstallation: PartialTicketInstallation
  ): TicketInstallation {
    const resolve = (key: keyof TicketInstallation) =>
      partialTicketInstallation.hasOwnProperty(key)
        ? partialTicketInstallation[key]
        : this[key];

    return new TicketInstallation(
      resolve('assignees'),
      resolve('closedAt'),
      resolve('company'),
      resolve('contacts'),
      resolve('clientInfo'),
      resolve('createdAt'),
      resolve('createdBy'),
      resolve('deleted'),
      resolve('deletedAt'),
      resolve('equipments'),
      resolve('executedAt'),
      resolve('id'),
      resolve('identifier'),
      resolve('interventions'),
      resolve('name'),
      resolve('priority'),
      resolve('status'),
      resolve('updatedAt'),
      resolve('updatedBy')
    );
  }
}

type PartialTicketInstallation = Partial<
  Pick<
    TicketInstallation,
    | 'assignees'
    | 'closedAt'
    | 'company'
    | 'contacts'
    | 'clientInfo'
    | 'createdAt'
    | 'createdBy'
    | 'deleted'
    | 'deletedAt'
    | 'equipments'
    | 'executedAt'
    | 'id'
    | 'identifier'
    | 'interventions'
    | 'name'
    | 'priority'
    | 'status'
    | 'updatedAt'
    | 'updatedBy'
  >
>;
