import { Response } from '../Response';
import { Endpoint } from '../Endpoint';
import { HttpClient } from '@angular/common/http';
import { IListResponse } from '../IListResponse';
import { Injectable, Inject } from '@angular/core';
import { API_SERVICE_CONFIG } from '../ApiServiceConfig';
import { IApiServiceConfig } from '../IApiServiceConfig';

import { Observable, BehaviorSubject } from 'rxjs';

import { ProductConfig } from '../../models/v0/ProductConfig';
import { UnsavedProductConfig } from '../../models/v0/UnsavedProductConfig';
import {
  ISerialisedProductConfig,
  ISerialisedProductConfigListItem,
} from '../../models/v0/serialisations/ISerialisedProductConfig';
import { ProductConfigListItem } from '../../models/v0/ProductConfigListItem';
import { map } from 'rxjs/operators';
import { Label } from '../../models/v0/Label';

type ProductConfigList = IListResponse<ISerialisedProductConfig>;

@Injectable()
export class ProductsConfigEndpoint extends Endpoint<
  ProductConfig,
  ISerialisedProductConfig
> {
  private readonly url = this.config.url + 'product/configurations/';

  constructor(
    @Inject(API_SERVICE_CONFIG) private config: IApiServiceConfig,
    private http: HttpClient
  ) {
    super(ProductConfig.fromSerialised);
  }

  getAll(filter?: {}): Observable<Response<ProductConfigListItem[]>> {
    const defaultSource = this.http.get<
      IListResponse<ISerialisedProductConfigListItem>
    >(this.url);

    return defaultSource.pipe(
      map((serialised) =>
        serialised.results.map((response) =>
          ProductConfigListItem.fromSerialised(response)
        )
      ),
      map((newTs) => new Response<ProductConfigListItem[]>(newTs, null))
    );
  }

  get(
    id: string,
    source?: Observable<ISerialisedProductConfig>,
    store?: BehaviorSubject<Response<ProductConfig[]>>
  ): Observable<Response<ProductConfig>> {
    const defaultSource = this.http.get<ISerialisedProductConfig>(
      `${this.url}${id}/`
    );
    return this.retrieve(source || defaultSource, store);
  }

  post(
    productConfig: UnsavedProductConfig,
    source?: Observable<ISerialisedProductConfig>,
    store?: BehaviorSubject<Response<ProductConfig[]>>
  ): Observable<Response<ProductConfig>> {
    const serialization = productConfig.toSerialised();
    const body = {
      ...serialization,
      product: {
        ...serialization.product,
        labels: serialization.product.labels.map((label) => label.id),
      },
    };
    const defaultSource = this.http.post<ISerialisedProductConfig>(
      this.url,
      body
    );
    return this.add(source || defaultSource, store);
  }

  patch() {}

  delete(
    id: string,
    source?: Observable<{}>,
    store?: BehaviorSubject<Response<ProductConfig[]>>
  ): Observable<Response<{}>> {
    const defaultSource = this.http.delete<{}>(`${this.url}${id}/`);
    return this.remove(source || defaultSource, id, store);
  }

  convert(
    simulationId: string,
    source?: Observable<ISerialisedProductConfig>,
    store?: BehaviorSubject<Response<ProductConfig[]>>
  ): Observable<Response<ProductConfig>> {
    const defaultSource = this.http.post<ISerialisedProductConfig>(
      `${this.url}convert/simulation/${simulationId}`,
      {}
    );
    return this.add(source || defaultSource, store);
  }
}
