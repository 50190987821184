import { CompanyContactExpansionPanelComponent } from './company-contact-expansion-panel/company-contact-expansion-panel.component';
import { TripsComponent } from './trips/trips.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiModule } from '../ui.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivitiesComponent } from './activities/activities.component';
import { CompaniesComponent } from './companies/companies.component';
import { CompanyFormComponent } from './company-form/company-form.component';
import { ContactsComponent } from './contacts/contacts.component';
import { ContactFormComponent } from './contact-form/contact-form.component';
import { ProductsComponent } from './products/products.component';
import { ProductFormComponent } from './product-form/product-form.component';
import { LossMotiveFormComponent } from './loss-motive-form/loss-motive-form.component';
import { SubProductsComponent } from './sub-products/sub-products.component';
import { SubProductFormComponent } from './sub-product-form/sub-product-form.component';
import { SubProductVariantFormComponent } from './sub-product-variant-form/sub-product-variant-form.component';
import { VariantListFormComponent } from './variant-list-form/variant-list-form.component';
import { PipelineComponent } from './pipeline/pipeline.component';
import { PipelineTicketComponent } from './pipeline-ticket/pipeline-ticket.component';
import { BoardComponent } from './board/board.component';
import { TicketsComponent } from './tickets/tickets.component';
import { TicketSupportFormCreateComponent } from './ticket-support-form-create/ticket-support-form-create.component';
import { TicketRequestFormComponent } from './ticket-request-form/ticket-request-form.component';
import { ReportListComponent } from './report-list/report-list.component';
import { TicketInstallationReportComponent } from './ticket-installation-report/ticket-installation-report.component';
import { TicketRequestFormPartsComponent } from './ticket-request-form-parts/ticket-request-form-parts.component';
import { InterventionPartsComponent } from './ticket-support-request-parts/ticket-support-request-parts.component';
import { HistoryComponent } from './history/history.component';
import { UsersComponent } from './users/users.component';
import { UserFormComponent } from './user-form/user-form.component';
import { HistoryActivityComponent } from './history-activity/history-activity.component';
import { HistoryTicketComponent } from './history-ticket/history-ticket.component';
import { SubProductVariantsComponent } from './sub-product-variants/sub-product-variants.component';
import { MachinesComponent } from './machines/machines.component';
import { PartsComponent } from './parts/parts.component';
import { AnomalyFormComponent } from './anomaly-form/anomaly-form.component';
import { PartFormComponent } from './part-form/part-form.component';
import { UsersPartsComponent } from './users-parts/users-parts.component';
import { UserPartsFormComponent } from './user-parts-form/user-parts-form.component';
import { ProductConfigurationsComponent } from './product-configurations/product-configurations.component';
import { ProductConfigurationListComponent } from './product-configuration-list/product-configuration-list.component';
import { ProductConfigurationFormComponent } from './product-configuration-form/product-configuration-form.component';
import { ProductConfigurationFormSubProductsComponent } from './product-configuration-form-sub-products/product-configuration-form-sub-products.component';
import { ProductConfigurationListCardComponent } from './product-configuration-list-card/product-configuration-list-card.component';
import { TicketSaleFormComponent } from './ticket-sale-form/ticket-sale-form.component';
import { SimulationsInfoComponent } from './simulations-info/simulations-info.component';
import { SimulationsListComponent } from './simulations-list/simulations-list.component';
import { ProductFormDetailsComponent } from './product-form-details/product-form-details.component';
import { ProductFormDetailsSubsectionComponent } from './product-form-details-subsection/product-form-details-subsection.component';
import { ProductFormDetailsSubsectionItemComponent } from './product-form-details-subsection-item/product-form-details-subsection-item.component';
import { ProductFormTechnicalInfoComponent } from './product-form-technical-info/product-form-technical-info.component';
import { ClientEquipmentFormComponent } from './client-equipment-form/client-equipment-form.component';
import { PipelineFiltersComponent } from './pipeline-filters/pipeline-filters.component';
import { ActivitiesFiltersComponent } from './activities-filter/activities-filters.component';
import { ContactsFiltersComponent } from './contacts-filter/contacts-filters.component';
import { CompaniesFiltersComponent } from './companies-filter/companies-filters.component';
import { LossMotivesComponent } from './loss-motives/loss-motives.component';
import { TicketSupportFormComponent } from './ticket-support-form/ticket-support-form.component';
import { MachineModalComponent } from './machine-modal/machine-modal.component';
import { WorkingPeriodComponent } from './working-period/working-period.component';
import { TravelCardV2Component } from './travel-card-v2/travel-card-v2.component';
import { AvatarModule } from 'ngx-avatars';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { StockItemCardComponent } from './stock-item-card/stock-item-card.component';
import { ConfigurationsFormComponent } from './configurations-form/configurations-form.component';
import { CompanyClientEquipmentAccordion } from './company-client-equipment-accordion/company-client-equipment-accordion.component';
import { DropInputComponent } from './drop-input/drop-input.component';
import { DropReportInputComponent } from './drop-report-input/drop-report-input.component';
import { ActivityFormV2Component } from './activity-form-v2/activity-form-v2.component';
import { MachineFormComponent } from './machine-form/machine-form.component';
import { ListItemsV2Component } from './list-items-v2/list-items-v2.component';
import { ReportListDialogComponent } from './report-list/report-list-dialog/report-list-dialog.component';
import { RouterModule } from '@angular/router';
import { SelectEmailDialogComponent } from './select-email-dialog/select-email-dialog.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { PartsFiltersComponent } from './parts-filters/parts-filters.component';
import { ActionCorrectiveForm } from './work-order-action/actions-forms/action-corrective-form/action-corrective-form.component';
import { ActionPreventiveForm } from './work-order-action/actions-forms/action-preventive-form/action-preventive-form.component';
import { SharedModule } from '@fullyops/shared/shared.module';
import { WorkOrderActionsComponent } from '@fullyops/legacy/ui/ui-crm/work-order-action/work-order-actions/work-order-actions.component';
import { ActionGeneralForm } from '@fullyops/legacy/ui/ui-crm/work-order-action/actions-forms/action-general-form/action-general-form.component';

const components = [
  ActivitiesComponent,
  ActivityFormV2Component,
  HistoryComponent,
  HistoryActivityComponent,
  HistoryTicketComponent,
  ContactsComponent,
  CompaniesComponent,
  ProductsComponent,
  MachinesComponent,
  PartsComponent,
  AnomalyFormComponent,
  PartFormComponent,
  ClientEquipmentFormComponent,
  ContactFormComponent,
  CompanyFormComponent,
  CompanyClientEquipmentAccordion,
  ConfigurationsFormComponent,
  CompanyContactExpansionPanelComponent,
  ProductFormComponent,
  ProductFormTechnicalInfoComponent,
  LossMotivesComponent,
  LossMotiveFormComponent,
  ProductFormDetailsComponent,
  ProductFormDetailsSubsectionComponent,
  ProductFormDetailsSubsectionItemComponent,
  SubProductFormComponent,
  SubProductVariantFormComponent,
  SubProductVariantsComponent,
  SubProductsComponent,
  SimulationsInfoComponent,
  SimulationsListComponent,
  VariantListFormComponent,
  ProductConfigurationFormComponent,
  ProductConfigurationsComponent,
  ProductConfigurationListComponent,
  ProductConfigurationFormSubProductsComponent,
  ProductConfigurationListCardComponent,
  TicketSaleFormComponent,
  TicketRequestFormComponent,
  TicketRequestFormPartsComponent,
  WorkOrderActionsComponent,
  ReportListComponent,
  ReportListDialogComponent,
  TicketInstallationReportComponent,
  TicketSupportFormCreateComponent,
  InterventionPartsComponent,
  PipelineComponent,
  PipelineTicketComponent,
  BoardComponent,
  TicketsComponent,
  UsersComponent,
  UserFormComponent,
  UsersPartsComponent,
  UserPartsFormComponent,
  PipelineFiltersComponent,
  ActivitiesFiltersComponent,
  TicketSupportFormComponent,
  ContactsFiltersComponent,
  CompaniesFiltersComponent,
  MachineModalComponent,
  WorkingPeriodComponent,
  TripsComponent,
  TravelCardV2Component,
  StockItemCardComponent,
  DropInputComponent,
  DropReportInputComponent,
  MachineFormComponent,
  ListItemsV2Component,
  SelectEmailDialogComponent,
  PartsFiltersComponent,
  ActionCorrectiveForm,
  ActionPreventiveForm,
  ActionGeneralForm,
];

@NgModule({
  declarations: components,
  exports: components,
  imports: [
    CommonModule,
    UiModule,
    FormsModule,
    ReactiveFormsModule,
    AvatarModule,
    FontAwesomeModule,
    RouterModule,
    InfiniteScrollModule,
    SharedModule,
  ],
})
export class UiCrmModule {}
