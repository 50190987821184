import { UiServiceV2 } from './ui.service-v2.service';
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_SERVICE_CONFIG } from '@fullyops/legacy/data/api/ApiServiceConfig';
import { IApiServiceConfig } from '@fullyops/legacy/data/api/IApiServiceConfig';
import { PageResponseWrapper } from '@fullyops/legacy/data/api/types/HttpResponses';
import {
  WorkPeriodRequest,
  WorkPeriodResponse,
} from '@fullyops/legacy/data/api/types/Work';

type QueryParams = {
  createdBy?: string;
  includeDeleted?: boolean;
  order?: 'ASC' | 'DESC';
  orderBy?:
    | 'CREATED_AT'
    | 'UPDATED_AT'
    | 'UUID'
    | 'STARTING_HOUR'
    | 'ENDING_HOUR';
  page?: number;
  size?: number;
  supportTicketId?: string;
  updatedBy?: string;
};

@Injectable({
  providedIn: 'root',
})
export class WorkPeriodControllerV2 extends UiServiceV2 {
  constructor(
    private http: HttpClient,
    @Inject(API_SERVICE_CONFIG) private config: IApiServiceConfig
  ) {
    super();
  }

  private readonly endpointUrl = this.config.url + 'workperiods';

  getAllWorkPeriod(params: { queryParams: QueryParams }) {
    let query = '';
    if (params.queryParams) {
      query = `?${this.createQueryParams(params.queryParams)}`;
    }
    return this.http.get<PageResponseWrapper<WorkPeriodResponse>>(
      `${this.endpointUrl}${query}`
    );
  }

  deleteById(params: { id: string }) {
    return this.http.delete(`${this.endpointUrl}/${params.id}`);
  }

  createWorkPeriod(params: { newWorkPeriod: WorkPeriodRequest }) {
    return this.http.post<WorkPeriodResponse>(
      `${this.endpointUrl}`,
      params.newWorkPeriod
    );
  }
}
