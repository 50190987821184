import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { UnsavedLossMotive } from '@fullyops/legacy/data';

class LossMotiveForm extends UntypedFormGroup {
  constructor(lossMotive: UnsavedLossMotive, dis: boolean) {
    super({});
    Object.keys(lossMotive).forEach((key) =>
      this.addControl(
        key,
        new UntypedFormControl({ value: lossMotive[key], disabled: dis }, [])
      )
    );
  }
}

@Injectable()
export class LossMotiveFormService {
  private initialLossMotive: UnsavedLossMotive;
  private form: BehaviorSubject<UntypedFormGroup> =
    new BehaviorSubject<UntypedFormGroup>(new UntypedFormGroup({}));

  form$: Observable<UntypedFormGroup> = this.form.asObservable();

  constructor() {}

  initForm(draftLossMotive: UnsavedLossMotive, disabled: boolean) {
    this.initialLossMotive = draftLossMotive;

    this.form.next(new LossMotiveForm(draftLossMotive, disabled));
  }

  getDraft() {
    return this.initialLossMotive.clone({
      name: this.form.getValue().get('name').value,
      description: this.form.getValue().get('description').value,
    });
  }

  markAllAsTouched() {
    const currentLossMotive = this.form.getValue();
    currentLossMotive.markAllAsTouched();

    this.form.next(currentLossMotive);
  }
}
