<div class="autocomplete-div-field">
  <mat-form-field class="d-block f-right full-width">
    <mat-label>{{ 'forms:' + field.label | i18nextCap }}</mat-label>
    <input
      type="text"
      matInput
      [formControl]="$any(formGroup.controls[field.name])"
      [matAutocomplete]="auto"
      [name]="field.name"
      [placeholder]="field.placeholder ? (field.placeholder | i18nextCap) : ''"
      [attr.data-testid]="field.dataTestId"
      (focusout)="checkValueOnFocusOut()"
      (keydown.enter)="onEnter($event)"
    />
    <mat-autocomplete
      #auto="matAutocomplete"
      [displayWith]="displayFn.bind(this)"
      (optionSelected)="onSelected($event)"
    >
      <ng-container *ngIf="field.autocomplete">
        <mat-option
          *ngIf="field.autocomplete?.firstOption"
          [value]="field.autocomplete?.firstOption.value || null"
        >
          <div class="custom-first-option">
            <div
              class="fa-icon-div"
              [ngClass]="
                field.autocomplete?.firstOption.iconColor
                  ? field.autocomplete?.firstOption.iconColor + '-icon'
                  : ''
              "
            >
              <fa-icon
                *ngIf="field.autocomplete?.firstOption.fontAwesomeIcon"
                [icon]="field.autocomplete?.firstOption.fontAwesomeIcon"
                [size]="'xl'"
              ></fa-icon>
            </div>
            {{ 'forms:' + field.autocomplete?.firstOption.label | i18nextCap }}
          </div>
        </mat-option>
      </ng-container>
      <ng-container *ngFor="let item of filteredOptions | async">
        <mat-option
          [value]="
            field.autocomplete?.value ? field.autocomplete?.value(item) : item
          "
          *ngIf="!isItemAlreadySelected(item)"
        >
          <mat-icon *ngIf="item.icon">{{ item.icon }}</mat-icon>
          <ng-container
            *ngIf="
              field.translate &&
              (field.displayValue ? field.displayValue(item) : item.name)
            "
          >
            <div class="item-option">
              <span>{{
                'forms:' +
                  (field.displayValue ? field.displayValue(item) : item.name)
                  | i18nextCap
              }}</span>
              <span class="ref" *ngIf="item.ref">Ref: {{ item.ref }}</span>
            </div>
          </ng-container>
          <ng-container
            *ngIf="
              !field.translate &&
              (field.displayValue ? field.displayValue(item) : item.name)
            "
          >
            <div class="item-option">
              <span>{{
                field.displayValue ? field.displayValue(item) : item.name
              }}</span>
              <span class="ref" *ngIf="item.ref">Ref: {{ item.ref }}</span>
            </div>
          </ng-container>
        </mat-option>
      </ng-container>
    </mat-autocomplete>

    <mat-error
      *ngIf="formGroup.controls[field.name].hasError('required'); else noFound"
      >{{ 'validation:youMustEnterAValue' | i18nextCap }}</mat-error
    >
    <ng-template #noFound>
      <mat-error *ngIf="formGroup.controls[field.name].hasError('noFound')">{{
        'validation:selectItemThatExistsInList' | i18nextCap
      }}</mat-error>
    </ng-template>
  </mat-form-field>
  <div *ngIf="field.button">
    <button
      mat-flat-button
      color="primary"
      type="button"
      (click)="field.button.onClick()"
      [disabled]="
        field.button.disabled$ ? (field.button.disabled$ | async) : undefined
      "
      [attr.data-testid]="field.button.testId || ''"
    >
      <mat-icon *ngIf="field.button.iconName">
        {{ field.button.iconName }}
      </mat-icon>
      <div class="fa-icon-div" *ngIf="field.button.fontAwesomeIconName">
        <fa-icon [icon]="field.button.fontAwesomeIconName" size="lg"></fa-icon>
      </div>
    </button>
  </div>
</div>
