import { TicketSupportMultimachine } from './TicketSupportMultimachine';
import { TicketRequest } from './TicketRequest';
import { TicketSale } from './TicketSale';
import { ISerialisedActivityLink } from './serialisations/ISerialisedActivityLink';
import { ISerialisedTicketRequest } from './serialisations/ISerialisedTicketRequest';
import { ISerialisedTicketSale } from './serialisations/ISerialisedTicketSale';
import { ActivityLinkType } from '../types';
import { ISerialisedTicketSupportMultimachine } from './serialisations/ISerialisedTicketSupportMultimachine';

export class ActivityLink {
  constructor(
    readonly linkType: ActivityLinkType,
    readonly ticket: TicketRequest | TicketSupportMultimachine | TicketSale
  ) {}

  static fromSerialised(serialised: ISerialisedActivityLink) {
    const ticket =
      serialised.linkType === ActivityLinkType.SUPPORT
        ? TicketSupportMultimachine.fromSerialised(serialised.supportTicket)
        : serialised.linkType === ActivityLinkType.REQUEST
        ? TicketRequest.fromSerialised(serialised.requestTicket)
        : serialised.linkType === ActivityLinkType.SALE
        ? TicketSale.fromSerialised(serialised.saleTicket)
        : null;

    return new ActivityLink(serialised.linkType, ticket);
  }

  toSerialised(): ISerialisedActivityLink {
    const ticketSupportObj =
      this.linkType === ActivityLinkType.SUPPORT
        ? (this.ticket.toSerialised() as ISerialisedTicketSupportMultimachine)
        : null;
    const ticketRequestObj =
      this.linkType === ActivityLinkType.REQUEST
        ? (this.ticket.toSerialised() as ISerialisedTicketRequest)
        : null;
    const ticketSaleObj =
      this.linkType === ActivityLinkType.SALE
        ? (this.ticket.toSerialised() as ISerialisedTicketSale)
        : null;

    return {
      linkType: this.linkType,
      supportTicket: ticketSupportObj,
      requestTicket: ticketRequestObj,
      saleTicket: ticketSaleObj,
    };
  }
}
