import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import {
  FieldType,
  ActionButtons,
  PermissionType,
} from '@fullyops/legacy/ui/ui-shared/utils/crm-types';
import { ComponentInOutAnimation } from '@fullyops/legacy/ui/ui-shared/utils/component-base-animation';
import {
  Response,
  ResponseState,
  User,
  ProductConfig,
} from '@fullyops/legacy/data';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'crm-product-configurations',
  templateUrl: './product-configurations.component.html',
  styleUrls: ['./product-configurations.component.scss'],
  animations: [ComponentInOutAnimation.AnimTrigger],
})
export class ProductConfigurationsComponent implements OnInit {
  @Input() title: string;
  @Input() productConfigs$ = new BehaviorSubject<Response<ProductConfig[]>>(
    new Response()
  );
  @Output() openEvent = new EventEmitter<string>();
  @Output() addNewEvent = new EventEmitter();

  // Response Possible States
  state = ResponseState;

  actions: ActionButtons = {};

  ngOnInit() {
    this.initActionButtons();
  }

  initActionButtons() {
    this.actions.primary = {
      label: 'addNew',
      color: 'primary',
      permissions: [PermissionType.CAN_EDIT_PRODUCT_CONFIGURATIONS],
    };
  }

  onActionEvent(event: string) {
    if (event === 'addNew') {
      this.onAddNew();
    }
  }

  onOpen(id: string) {
    this.openEvent.emit(id);
  }

  onAddNew() {
    this.addNewEvent.emit();
  }
}
