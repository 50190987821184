import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';
import { FormArray, UntypedFormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'crm-upload-image',
  templateUrl: './upload-image.component.html',
  styleUrls: ['./upload-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadImageComponent implements OnInit {
  @Input() disabled = false;
  @Input() imageControl: UntypedFormControl;
  @Input() uploadService: (
    file: File,
    extension: string
  ) => BehaviorSubject<{ url: string }>;

  currentImage$ = new BehaviorSubject('');

  ngOnInit() {
    this.currentImage$.next(this.imageControl.value);
  }

  uploadImage(event) {
    this.uploadService(event.target.files[0], 'png').subscribe((response) => {
      this.currentImage$.next(response.url);
      this.imageControl.patchValue(response.url);
    });
  }
}
