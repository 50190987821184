import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, FormGroup } from '@angular/forms';
import {
  ProductConfig,
  ProductConfigListItem,
  ProductConfigSimulation,
} from '@fullyops/legacy/data';
import { BehaviorSubject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ComponentInOutAnimation } from '@fullyops/legacy/ui/ui-shared/utils/component-base-animation';
import {
  ActionButton,
  FieldType,
} from '@fullyops/legacy/ui/ui-shared/utils/crm-types';

@Component({
  selector: 'crm-simulations-list',
  templateUrl: './simulations-list.component.html',
  styleUrls: ['./simulations-list.component.scss'],
  animations: [ComponentInOutAnimation.AnimTrigger],
})
export class SimulationsListComponent implements OnInit {
  @Input() productsConfigsList$ = new BehaviorSubject<ProductConfigListItem[]>(
    []
  );
  @Input() productsConfigs$ = new BehaviorSubject<ProductConfig[]>([]);
  @Input() simulations$ = new BehaviorSubject<ProductConfigSimulation[]>([]);
  @Output() addProductConfigEvent = new EventEmitter<string>();
  @Output() openProductConfigEvent = new EventEmitter<string>();
  @Output() removeProductConfigEvent = new EventEmitter<string>();
  @Output() createSimulationEvent = new EventEmitter();
  @Output() openSimulationEvent = new EventEmitter<string>();
  @Output() removeSimulationEvent = new EventEmitter<string>();

  simulationsList$ = new BehaviorSubject<[]>([]);
  actionButtons: ActionButton[] = [];

  productConfigControl = new UntypedFormControl();

  ngOnInit() {
    this.productsConfigs$
      .pipe(map((productsConfigs) => productsConfigs.map(this.parsePart)))
      .subscribe(this.simulationsList$);

    this.actionButtons.push({
      label: 'open',
      color: 'primary-color',
    });

    this.actionButtons.push({
      label: 'remove',
      color: 'warn-color',
    });
  }

  addProductConfig({ option }) {
    this.addProductConfigEvent.emit(option.value);
    this.productConfigControl.patchValue('');
  }

  parsePart(productConfig: ProductConfig) {
    return {
      id: productConfig.id,
      columns: {
        name: {
          label: 'configName',
          type: FieldType.Text,
          value: productConfig.name,
        },
        productName: {
          label: 'productName',
          type: FieldType.Text,
          value: productConfig.product.name,
        },
        status: {
          label: 'status',
          type: FieldType.Text,
          value: productConfig.status,
        },
        features: {
          label: 'features',
          type: FieldType.Text,
          value: productConfig.product.subproducts.length,
        },
      },
    };
  }

  onCreateSimulation() {
    this.createSimulationEvent.emit();
  }

  onSimulationsListEvent(event: { action: string; id: string }) {
    switch (event.action) {
      case 'open':
        this.openSimulationEvent.emit(event.id);
        break;
      case 'remove':
        this.removeSimulationEvent.emit(event.id);
        break;
    }
  }

  onProductConfigsEvent(event: { action: string; id: string }) {
    switch (event.action) {
      case 'open':
        this.openProductConfigEvent.emit(event.id);
        break;
      case 'remove':
        this.removeProductConfigEvent.emit(event.id);
        break;
    }
  }
}
