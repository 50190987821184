import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { IconService } from '@fullyops/legacy/ui';

@Component({
  selector: 'app-component',
  templateUrl: './app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  constructor(private iconService: IconService) {}

  ngOnInit() {
    this.iconService.registerIcons();
  }
}
