import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { UiAuthService } from '@fullyops/legacy/ui';
import { Observable, of } from 'rxjs';
import { map, take, catchError, delay, last } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  state: RouterStateSnapshot = null;

  constructor(private router: Router, private authService: UiAuthService) {
    this.authService.authorizedObs.subscribe((authorized) => {
      if (!authorized) {
        this.state
          ? this.router.navigate(['/login'], {
              queryParams: { returnUrl: this.state.url },
            })
          : this.router.navigate(['/login']);
      }
    });
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    this.state = state;

    return this.authService.authorizedObs.pipe(
      map((authorized) => {
        if (!authorized) {
          this.router.navigate(['/login'], {
            queryParams: { returnUrl: state.url },
          });
        }
        return authorized;
      })
    );
  }
}
