<form [formGroup]="formGroup" (submit)="onSubmit()">
  <div class="col-md-12">
    <div class="text-div-field">
      <mat-form-field class="d-block full-width">
        <mat-label>{{ 'forms:name' | i18nextCap }}</mat-label>
        <input matInput [name]="'name'" [formControlName]="'name'" />
        <mat-error
          *ngIf="formGroup.controls['name']?.errors?.duplicateCategory"
          >{{ 'validation:duplicateCategory' | i18nextCap }}</mat-error
        >
      </mat-form-field>
    </div>
  </div>
  <div class="col-md-12">
    <div class="text-div-field">
      <mat-form-field class="d-block full-width">
        <mat-label>{{ 'forms:label' | i18nextCap }}</mat-label>
        <input matInput [name]="'label'" [formControlName]="'label'" />
        <mat-error
          *ngIf="formGroup.controls['label']?.errors?.duplicateCategory"
          >{{ 'validation:duplicateCategory' | i18nextCap }}</mat-error
        >
      </mat-form-field>
    </div>
  </div>
  <div class="col-md-12">
    <div
      class="text-div-field"
      [matTooltip]="externalIdToolTipText | i18nextCap"
      matTooltipPosition="right"
    >
      <mat-form-field class="d-block full-width">
        <mat-label>{{ 'forms:externalId' | i18nextCap }}</mat-label>
        <input
          matInput
          [name]="'externalId'"
          [formControlName]="'externalId'"
        />
        <mat-error
          *ngIf="formGroup.controls['externalId']?.errors?.duplicateCategory"
          >{{ 'validation:duplicateCategory' | i18nextCap }}</mat-error
        >
      </mat-form-field>
    </div>
  </div>
  <div class="col-md-12">
    <div class="color-div-field">
      <mat-label>{{ 'forms:color' | i18nextCap }}</mat-label>

      <ngx-colors
        ngx-colors-trigger
        [hideTextInput]="false"
        [hideColorPicker]="false"
        [colorPickerControls]="'no-alpha'"
        formControlName="color"
      ></ngx-colors>
    </div>
  </div>
  <div class="form-actions">
    <button
      mat-button
      type="button"
      (click)="onCancel()"
      color="warn"
      mat-dialog-close
    >
      {{ 'forms:cancel' | i18nextCap }}
    </button>
    <button
      mat-flat-button
      type="submit"
      color="success"
      [disabled]="!formGroup.valid"
    >
      {{ 'forms:update' | i18nextCap }}
    </button>
  </div>
</form>
