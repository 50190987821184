import { Response } from '../Response';
import { Endpoint } from '../Endpoint';
import { HttpClient } from '@angular/common/http';
import { IListResponse } from '../IListResponse';
import { Injectable, Inject } from '@angular/core';
import { API_SERVICE_CONFIG } from '../ApiServiceConfig';
import { IApiServiceConfig } from '../IApiServiceConfig';

import { Observable, BehaviorSubject } from 'rxjs';

import { Status } from '../../models/v0/Status';
import { UnsavedStatus } from '../../models/v0/UnsavedStatus';
import { ISerialisedStatus } from '../../models/v0/serialisations/ISerialisedStatus';

type StatusList = IListResponse<ISerialisedStatus>;

@Injectable()
export class TicketsRequestStatusesEndpoint extends Endpoint<
  Status,
  ISerialisedStatus
> {
  private readonly url = this.config.url + 'tickets/requests/status';

  constructor(
    @Inject(API_SERVICE_CONFIG) private config: IApiServiceConfig,
    private http: HttpClient
  ) {
    super(Status.fromSerialised);
  }

  getAll(
    source?: Observable<StatusList>,
    store?: BehaviorSubject<Response<Status[]>>
  ): Observable<Response<Status[]>> {
    const defaultSource = this.http.get<StatusList>(this.url);
    return this.list(source || defaultSource, store);
  }

  get(
    id: string,
    source?: Observable<ISerialisedStatus>,
    store?: BehaviorSubject<Response<Status[]>>
  ): Observable<Response<Status>> {
    const defaultSource = this.http.get<ISerialisedStatus>(`${this.url}${id}/`);
    return this.retrieve(source || defaultSource, store);
  }

  post(
    status: UnsavedStatus,
    source?: Observable<ISerialisedStatus>,
    store?: BehaviorSubject<Response<Status[]>>
  ): Observable<Response<Status>> {
    const defaultSource = this.http.post<ISerialisedStatus>(
      this.url,
      status.toSerialised()
    );
    return this.add(source || defaultSource, store);
  }

  patch(
    id: string,
    status: UnsavedStatus,
    source?: Observable<ISerialisedStatus>,
    store?: BehaviorSubject<Response<Status[]>>
  ) {
    const defaultSource = this.http.patch<ISerialisedStatus>(
      `${this.url}${id}/`,
      status
    );
    return this.update(source || defaultSource, null, store);
  }

  delete(
    id: string,
    source?: Observable<{}>,
    store?: BehaviorSubject<Response<Status[]>>
  ): Observable<Response<{}>> {
    const defaultSource = this.http.delete<{}>(`${this.url}${id}/`);
    return this.remove(source || defaultSource, id, store);
  }
}
