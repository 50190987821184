import { Injectable } from '@angular/core';
import {
  Response,
  Label,
  UnsavedLabel,
  LabelsEndpoint,
} from '@fullyops/legacy/data';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UiLabelsService {
  readonly labels$ = this.labels.createStore();

  constructor(protected labels: LabelsEndpoint) {}

  loadAll() {
    this.loadLabels();
  }

  loadLabels() {
    this.labels
      .getAll(undefined, this.labels$)
      .subscribe({ error: console.error });
  }

  getLabel(id: string): Observable<Response<Label>> {
    return this.labels.get(id, undefined, this.labels$);
  }

  createLabel(label: UnsavedLabel) {
    return this.labels.post(label, undefined, this.labels$);
  }
}
