import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { ComponentInOutAnimation } from '@fullyops/legacy/ui/ui-shared/utils/component-base-animation';
import { FormControl, NgForm, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import {
  faFloppyDisk,
  faPen,
  faTrashCan,
} from '@fortawesome/free-solid-svg-icons';

export type AddItemType = { newItemControl: FormControl };
export type EditItemType = {
  id: string;
  index: number;
  value: any;
};
export type DeleteItemType = {
  id: string;
  index: number;
};

@Component({
  selector: 'crm-list-items-v2',
  templateUrl: './list-items-v2.component.html',
  styleUrls: ['./list-items-v2.component.scss'],
  animations: [ComponentInOutAnimation.AnimTrigger],
})
export class ListItemsV2Component implements OnInit {
  constructor() {}

  @Output() addItem = new EventEmitter<AddItemType>();
  @Output() editItem = new EventEmitter<EditItemType>();
  @Output() deleteItem = new EventEmitter<DeleteItemType>();
  @Output() toggleItem = new EventEmitter<{ id; isActive }>();
  @Input() items$: BehaviorSubject<any[]>;
  @Input() titlePropertyName: string = 'name';
  @Input() addItemLabel: string;

  @ViewChildren('titleCard') titleCard: QueryList<ElementRef>;
  @ViewChildren('cardContent') cardContent: QueryList<ElementRef>;

  editingNameIndex = -1;
  newItemControl = new FormControl('', [Validators.required]);

  faPen = faPen;
  faFloppyDisk = faFloppyDisk;
  faTrashCan = faTrashCan;

  showAddItemForm = false;
  canEditItemName = false;
  canDeleteItemName = false;
  canToggleItem = false;

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event: MouseEvent): void {
    if (event.button != 0) return;
    if (!this.canEditItemName) return;
    const titleFound = this.titleCard.find((element) => {
      return element.nativeElement.contains(event.target);
    });
    if (!titleFound) {
      // clicked outside => close dropdown list
      const cardFound = this.cardContent.find((element) => {
        return element.nativeElement.contains(event.target);
      });
      if (cardFound) return;
      this.editingNameIndex = -1;
      return;
    } else {
      this.editingNameIndex = titleFound.nativeElement.attributes.index.value;
    }
  }

  ngOnInit() {
    this.showAddItemForm = this.addItem.observers.length > 0;
    this.canEditItemName = this.editItem.observers.length > 0;
    this.canDeleteItemName = this.deleteItem.observers.length > 0;
    this.canToggleItem = this.toggleItem.observers.length > 0;
  }

  onAddItem() {
    this.addItem.emit({ newItemControl: this.newItemControl });
  }

  onEditItem(id, index, form: NgForm) {
    this.editItem.emit({
      id,
      index,
      value: form.controls['name'].value,
    });
  }

  enableEditName(index) {
    this.editingNameIndex = index;
  }

  closeEdition() {
    this.editingNameIndex = -1;
  }

  onDeleteItem(id, index) {
    this.deleteItem.emit({ id, index });
  }

  onToggleItem(id, isActive) {
    this.toggleItem.emit({ id, isActive });
  }
}
