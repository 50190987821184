import { ReportContent } from './ReportContent';
import { ISerialisedReportSubSection } from './serialisations/ISerialisedReportSection';

export class ReportSubSection {
  constructor(readonly title: string, readonly content: ReportContent[]) {
    if (title) this.title = title.trim();
  }

  static fromSerialised(serialised: ISerialisedReportSubSection) {
    const content = serialised.content.map(ReportContent.fromSerialised);

    return new ReportSubSection(serialised.title, content);
  }

  toSerialised(): ISerialisedReportSubSection {
    const contentObj = this.content.map((c) => c.toSerialised());

    return {
      title: this.title,
      content: contentObj,
    };
  }

  clone(partialReportSubSection: PartialReportSubSection): ReportSubSection {
    const resolve = (key: keyof ReportSubSection) =>
      partialReportSubSection.hasOwnProperty(key)
        ? partialReportSubSection[key]
        : this[key];

    return new ReportSubSection(resolve('title'), resolve('content'));
  }
}
type PartialReportSubSection = Partial<
  Pick<ReportSubSection, 'title' | 'content'>
>;
