import { UiServiceV2 } from './ui.service-v2.service';
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_SERVICE_CONFIG } from '@fullyops/legacy/data/api/ApiServiceConfig';
import { IApiServiceConfig } from '@fullyops/legacy/data/api/IApiServiceConfig';
import {
  ContactRequest,
  ContactResponse,
} from '@fullyops/legacy/data/api/types/Contact';
import { PageResponseWrapper } from '@fullyops/legacy/data/api/types/HttpResponses';

export type QueryParams = {
  addressId?: string;
  companyId?: string;
  createdBy?: string;
  email?: string;
  includeDeleted?: boolean;
  mobileNumber?: string;
  name?: string;
  notes?: string;
  order?: 'ASC' | 'DESC';
  orderBy?:
    | 'CREATED_AT'
    | 'UPDATED_AT'
    | 'UUID'
    | 'NAME'
    | 'EMAIL'
    | 'MOBILE_NUMBER'
    | 'NOTES';
  page?: number;
  size?: number;
  updatedBy?: string;
};

@Injectable({
  providedIn: 'root',
})
export class ContactControllerV2 extends UiServiceV2 {
  constructor(
    private http: HttpClient,
    @Inject(API_SERVICE_CONFIG) private config: IApiServiceConfig
  ) {
    super();
  }

  private readonly endpointUrl = this.config.url + 'contacts';

  getAll(params: { queryParams?: QueryParams }) {
    let query = '';
    if (params.queryParams) {
      query = `?${this.createQueryParams(params.queryParams)}`;
    }

    return this.http.get<PageResponseWrapper<ContactResponse>>(
      `${this.endpointUrl}${query}`
    );
  }

  create(params: { newContact?: ContactRequest }) {
    return this.http.post<ContactResponse>(this.endpointUrl, params.newContact);
  }

  updateById(params: { contact?: ContactRequest; contactId: string }) {
    const { contactId, contact } = params;
    return this.http.patch<ContactResponse>(
      `${this.endpointUrl}/${contactId}`,
      contact
    );
  }

  deleteById(params: { contactId: string }) {
    return this.http.delete(`${this.endpointUrl}/${params.contactId}`);
  }

  downloadImportTemplate() {
    return this.http.post(
      `${this.endpointUrl}/import/download-template`,
      {},
      { params: {}, responseType: 'text' }
    );
  }

  importByCsv(file: File) {
    const data = new FormData();
    data.append('file', file);
    return this.http.post(`${this.endpointUrl}/import`, data);
  }

  exportCsv() {
    return this.http.post(
      `${this.endpointUrl}/export`,
      {},
      { responseType: 'text' }
    );
  }
}
