import { UiServiceV2 } from './ui.service-v2.service';
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_SERVICE_CONFIG } from '@fullyops/legacy/data/api/ApiServiceConfig';
import { IApiServiceConfig } from '@fullyops/legacy/data/api/IApiServiceConfig';

type QueryParams = {
  createdBy?: string;
  includeDeleted?: boolean;
  order?: 'ASC' | 'DESC';
  orderBy?:
    | 'CREATED_AT'
    | 'UPDATED_AT'
    | 'UUID'
    | 'STARTING_HOUR'
    | 'ENDING_HOUR';
  page?: number;
  size?: number;
  supportTicketId?: string;
  updatedBy?: string;
};

@Injectable({
  providedIn: 'root',
})
export class WorkPeriodAssigneeControllerV2 extends UiServiceV2 {
  constructor(
    private http: HttpClient,
    @Inject(API_SERVICE_CONFIG) private config: IApiServiceConfig
  ) {
    super();
  }

  private readonly endpointUrl = this.config.url + 'workperiods/assignees';

  assignUserToWorkPeriod(params: { assigneeId: string; workPeriodId: string }) {
    return this.http.post(`${this.endpointUrl}`, params);
  }
}
