import { Injectable, Inject } from '@angular/core';
import { MAT_DATE_LOCALE, NativeDateAdapter } from '@angular/material/core';
import * as moment from 'moment';
import { UiAuthService } from '@fullyops/legacy/ui/ui-crm/ui-auth.service';
import { TimeZoneDateFormatterService } from '@fullyops/core/services/date-formatter.service';

@Injectable()
export class FedDateAdapter extends NativeDateAdapter {
  constructor(
    protected uiAuthService: UiAuthService,
    protected timeZoneDateFormatterService: TimeZoneDateFormatterService,
    @Inject(MAT_DATE_LOCALE) matDateLocale: string
  ) {
    super(matDateLocale);
  }

  hour: number = null;
  minute: number = null;

  format(date: Date): string {
    this.hour = date.getHours();
    this.minute = date.getMinutes();
    return this.timeZoneDateFormatterService
      .formatInTimezone(date)
      .format('DD/MM/YYYY');
  }

  parse(value: any): Date | null {
    if (!moment(value, 'DD/MM/YYYY', true).isValid()) {
      if (!value) return;
      return this.invalid();
    }
    return moment(value, 'DD/MM/YYYY', true).toDate();
  }

  createDate(year: number, month: number, date: number): Date {
    return this.timeZoneDateFormatterService
      .formatInTimezone([year, month, date, this.hour, this.minute])
      .toDate();
  }
}
