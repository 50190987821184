import { Directive, ElementRef, OnInit, Input } from '@angular/core';
import { PermissionType } from '@fullyops/legacy/ui/ui-shared/utils/crm-types';
import { UiAuthService } from '@fullyops/legacy/ui/ui-crm/ui-auth.service';
import { filter } from 'rxjs/operators';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[disableLinkIfNotAllowed]',
})
export class DisableLinkIfNotAllowedDirective implements OnInit {
  @Input('disableLinkIfNotAllowed') permissions: PermissionType[];

  constructor(private el: ElementRef, private authService: UiAuthService) {}

  ngOnInit() {
    if (!this.permissions) {
      return;
    }

    this.authService
      .hasPermission(this.permissions)
      .pipe(filter((permission) => permission === false))
      .subscribe((_) => {
        this.el.nativeElement.style.cursor = 'default';
        this.el.nativeElement.removeAllListeners();
      });
  }
}
