import { Component, Input, OnInit } from '@angular/core';

export type ContainerSizesType = 'sm' | 'md' | 'lg' | 'xl' | '2xl';

@Component({
  selector: 'crm-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss'],
})
export class ContainerComponent implements OnInit {
  constructor() {}
  @Input() maxSize: ContainerSizesType;
  @Input() removeNegativeMargin: boolean;
  @Input() noPadding: boolean;
  @Input() noPaddingTop: boolean;

  ngOnInit() {}
}
