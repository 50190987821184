<section [formGroup]="formGroup" class="add-negative-margin">
  <ng-container *ngFor="let row of formFields">
    <div class="row form-field-container remove-negative-margin">
      <ng-container *ngFor="let field of row; let i = index">
        <ng-container [ngSwitch]="field.type" *ngIf="!field.hidden">
          <div *ngSwitchCase="'separator'" class="form-separator col-md-12">
            <p>
              {{ 'forms:' + field.label | i18nextCap }}
              {{ index }}
              {{ field.counter ? field.counter : '' }}
            </p>
          </div>
          <div *ngSwitchCase="'upload'" [className]="'col-md-' + field.size">
            <mat-form-field class="d-block">
              <mat-label>{{ 'forms:' + field.label | i18nextCap }}</mat-label>
              <input
                matInput
                [name]="field.name"
                [formControlName]="field.name"
              />
              <input
                style="display: none"
                type="file"
                (change)="uploadPDF($event, field.name)"
                #fileInput
              />
              <button
                mat-icon-button
                matSuffix
                type="button"
                color="primary"
                (click)="fileInput.click()"
              >
                <mat-icon>publish</mat-icon>
              </button>
              <mat-error>{{
                'validation:youMustEnterAValue' | i18nextCap
              }}</mat-error>
            </mat-form-field>
          </div>
          <div *ngSwitchCase="'text'" [className]="'col-md-' + field.size">
            <div class="text-div-field">
              <mat-form-field class="d-block full-width">
                <mat-label>{{ 'forms:' + field.label | i18nextCap }}</mat-label>
                <input
                  matInput
                  [name]="field.name"
                  [formControlName]="field.name"
                  (blur)="onSelectChange($event)"
                  (keyup.enter)="onSelectChange($event)"
                  [matTooltip]="field.matTooltip | i18nextCap"
                  [attr.data-testid]="this.field?.dataTestId"
                />
                <mat-hint *ngIf="field.helperText">{{
                  field.helperText | i18nextCap
                }}</mat-hint>
                <mat-error *ngIf="">
                  {{
                    formGroup.controls[field.name]?.errors
                      ? formGroup.controls[field.name]?.errors?.message &&
                        !formGroup.controls[field.name]?.errors?.email
                        ? ('error:' +
                            formGroup.controls[field.name].errors.message
                          | i18nextCap)
                        : ('validation:youMustEnterAValue' | i18nextCap)
                      : ('validation:youMustEnterAValue' | i18nextCap)
                  }}
                </mat-error>
                <mat-error
                  *ngIf="formGroup.controls[field.name]?.errors?.email"
                  >{{
                    'validation:emailMustBeProperValue' | i18nextCap
                  }}</mat-error
                >
                <mat-error
                  *ngIf="formGroup.controls[field.name]?.errors?.required"
                  >{{ 'validation:youMustEnterAValue' | i18nextCap }}</mat-error
                >
              </mat-form-field>
              <div *ngIf="this.field.button">
                <button
                  mat-flat-button
                  color="primary"
                  type="button"
                  (click)="this.field.button.onClick()"
                  [disabled]="
                    this.field.button.disabled$
                      ? (this.field.button.disabled$ | async)
                      : undefined
                  "
                >
                  <mat-icon>{{ this.field.button.iconName }}</mat-icon>
                </button>
              </div>
            </div>
          </div>
          <div *ngSwitchCase="'button'" [className]="'col-md-' + field.size">
            <button
              mat-flat-button
              type="button"
              [color]="field.color || 'warn'"
              (click)="
                field.onClick ? field.onClick($event) : onButtonClick($event)
              "
              name="{{ field.label }}"
            >
              {{ 'forms:' + field.label | i18nextCap }}
            </button>
          </div>
          <div *ngSwitchCase="'price'" [className]="'col-md-' + field.size">
            <mat-form-field class="d-block">
              <mat-label>{{ 'forms:' + field.label | i18nextCap }}</mat-label>
              <span class="currency" matSuffix>€ &nbsp;</span>
              <input
                class="price"
                matInput
                type="number"
                [name]="field.name"
                [formControlName]="field.name"
              />
              <mat-error>{{
                'validation:youMustEnterAValue' | i18nextCap
              }}</mat-error>
            </mat-form-field>
          </div>
          <div *ngSwitchCase="'number'" [className]="'col-md-' + field.size">
            <mat-form-field class="d-block">
              <mat-label>{{ 'forms:' + field.label | i18nextCap }}</mat-label>
              <input
                class="number"
                matInput
                type="number"
                min="0"
                step="1"
                [name]="field.name"
                [formControlName]="field.name"
                (blur)="onInputChange()"
                (keyup.enter)="onInputChange()"
              />
              <mat-error>{{
                'validation:youMustEnterAValue' | i18nextCap
              }}</mat-error>
            </mat-form-field>
          </div>
          <div *ngSwitchCase="'checkbox'" [className]="'col-md-' + field.size">
            <mat-checkbox
              [name]="field.name"
              [formControlName]="field.name"
              (change)="onSelectChange($event)"
              >{{ 'forms:' + field.label | i18nextCap }}
            </mat-checkbox>
          </div>

          <div
            *ngSwitchCase="'slide'"
            [className]="'col-md-' + field.size + ' padding-bottom'"
          >
            <mat-slide-toggle
              [name]="field.name"
              [formControlName]="field.name"
              (change)="onSelectChange($event)"
              color="primary"
            >
              {{ 'forms:' + field.label | i18nextCap }}
            </mat-slide-toggle>
          </div>

          <div *ngSwitchCase="'textarea'" [className]="'col-md-' + field.size">
            <mat-form-field class="d-block">
              <mat-label>{{ 'forms:' + field.label | i18nextCap }}</mat-label>
              <textarea
                matInput
                [name]="field.name"
                [formControlName]="field.name"
                (blur)="onSelectChange($event)"
                (keyup.enter)="onSelectChange($event)"
              ></textarea>
              <mat-error>{{
                'validation:youMustEnterAValue' | i18nextCap
              }}</mat-error>
            </mat-form-field>
          </div>
          <div *ngSwitchCase="'select'" [className]="'col-md-' + field.size">
            <crm-form-select
              [formGroup]="formGroup"
              [field]="field"
              (selectionChange)="onSelectChange($event)"
            ></crm-form-select>
          </div>
          <div
            *ngSwitchCase="'multiple-select'"
            [className]="'col-md-' + field.size"
          >
            <crm-form-select
              [formGroup]="formGroup"
              [field]="field"
              [multiple]="true"
              (selectionChange)="onSelectChange($event)"
            ></crm-form-select>
          </div>
          <div *ngSwitchCase="'fulldate'" [className]="'col-md-' + field.size">
            <crm-form-date
              [formGroup]="formGroup"
              [field]="field"
              (selectionChange)="onSelectChange($event)"
            ></crm-form-date>
          </div>
          <div *ngSwitchCase="'date'" [className]="'col-md-' + field.size">
            <crm-form-date
              [dateOnly]="true"
              [formGroup]="formGroup"
              [field]="field"
              (selectionChange)="onSelectChange($event)"
            ></crm-form-date>
          </div>

          <div
            *ngSwitchCase="'autocomplete'"
            [className]="'col-md-' + field.size"
          >
            <crm-form-autocomplete
              [formGroup]="formGroup"
              [field]="field"
            ></crm-form-autocomplete>
          </div>

          <div
            *ngSwitchCase="'chips-autocomplete'"
            [className]="'col-md-' + field.size"
          >
            <crm-form-chips-autocomplete
              [formGroup]="formGroup"
              [field]="field"
            ></crm-form-chips-autocomplete>
          </div>

          <div
            *ngSwitchCase="'autocomplete-list'"
            [className]="'col-md-' + field.size"
          >
            <crm-form-autocomplete-list
              [formGroup]="formGroup"
              [field]="field"
            ></crm-form-autocomplete-list>
          </div>

          <div *ngSwitchCase="'images'" [className]="'col-md-' + field.size">
            <crm-input-images
              [field]="field"
              [formGroup]="formGroup"
            ></crm-input-images>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
</section>
