import { ResponseWrapper } from '@fullyops/legacy/data/api/v0/InstallationTicketStatusEndpoint';
import { UiServiceV2 } from './ui.service-v2.service';
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_SERVICE_CONFIG } from '@fullyops/legacy/data/api/ApiServiceConfig';
import { IApiServiceConfig } from '@fullyops/legacy/data/api/IApiServiceConfig';
import {
  SupportTicketPostRequest,
  SupportTicketRequest,
  SupportTicketResponse,
} from '@fullyops/legacy/data/api/types/SupportTicket';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { PageResponseWrapper } from '@fullyops/legacy/data/api/types/HttpResponses';
import { map } from 'rxjs/operators';

export type QueryParams = {
  actAt?: string;
  companyIds?: string[];
  createdBy?: string;
  endsAt?: string;
  finalizedIn?: string;
  identifier?: string;
  includeDeleted?: boolean;
  isFinal?: boolean;
  isFinished?: boolean;
  name?: string;
  order?: 'ASC' | 'DESC';
  orderBy?:
    | 'COMPANY'
    | 'STATUS'
    | 'STARTS_AT'
    | 'ENDS_AT'
    | 'ACT_AT'
    | 'NAME'
    | 'IDENTIFIER'
    | 'PRIORITY';
  page?: number;
  priority?: 'LOW' | 'MEDIUM' | 'HIGH' | 'URGENT';
  size?: number;
  startsAt?: string;
  statusIds?: string[];
  updatedBy?: string;
  userIds?: string[];
  warranty?: boolean;
  combinedTextSearch?: string;
};

@Injectable({
  providedIn: 'root',
})
export class SupportTicketController extends UiServiceV2 {
  constructor(
    private http: HttpClient,
    protected dialog: MatDialog,
    @Inject(API_SERVICE_CONFIG) private config: IApiServiceConfig
  ) {
    super();
  }

  private readonly endpointUrl =
    this.config.url + 'tickets/multimachinesupports';

  getAllSupportTickets(params: { queryParams: QueryParams }) {
    let query = '';
    if (params.queryParams) {
      query = `?${this.createQueryParams(params.queryParams)}`;
    }

    return this.http
      .get<PageResponseWrapper<SupportTicketResponse>>(
        `${this.endpointUrl}${query}`
      )
      .pipe(
        map((response) => ({
          ...response,
          results: response.results.map((t) => ({
            ...t,
            identifier: `WO-${t.identifier}`,
          })),
        }))
      );
  }

  updateSupportTicket(params: {
    ticketId: string;
    data: SupportTicketRequest;
  }) {
    const { ticketId, data } = params;

    return this.http.patch<SupportTicketResponse>(
      `${this.endpointUrl}/${ticketId}`,
      data
    );
  }

  getSupportTicketById(ticketId: string) {
    return this.http.get<SupportTicketResponse>(
      `${this.endpointUrl}/${ticketId}`
    );
  }

  createSupportTicket(params: { newTicket: SupportTicketPostRequest }) {
    return this.http.post<SupportTicketResponse>(
      this.endpointUrl,
      params.newTicket
    );
  }

  deleteSupportTicketById(params: { ticketId: string }) {
    return this.http.delete(`${this.endpointUrl}/${params.ticketId}`);
  }
}
