import { UiServiceV2 } from './ui.service-v2.service';
import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { API_SERVICE_CONFIG } from '@fullyops/legacy/data/api/ApiServiceConfig';
import { IApiServiceConfig } from '@fullyops/legacy/data/api/IApiServiceConfig';
import { CheckPasswordResetTokenResponse } from '@fullyops/legacy/data/api/types/Password';

@Injectable({
  providedIn: 'root',
})
export class PasswordResetControllerV2 extends UiServiceV2 {
  constructor(
    @Inject(API_SERVICE_CONFIG) private config: IApiServiceConfig,
    private http: HttpClient
  ) {
    super();
  }

  private readonly url = this.config.url + 'password';

  checkPasswordResetToken(params: {
    token: string;
    username: string;
    tenantName: string;
  }) {
    return this.http.post<CheckPasswordResetTokenResponse>(
      `${this.url}/reset/token/validate`,
      params,
      { headers: { tenantName: params.tenantName } }
    );
  }

  resetPassword(params: {
    duplicatePassword: string;
    newPassword: string;
    token: string;
    username: string;
    tenantName: string;
  }) {
    return this.http.post(`${this.url}/reset`, params, {
      headers: { tenantName: params.tenantName },
    });
  }
}
