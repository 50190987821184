import { UiServiceV2 } from './ui.service-v2.service';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { API_SERVICE_CONFIG } from '@fullyops/legacy/data/api/ApiServiceConfig';
import { IApiServiceConfig } from '@fullyops/legacy/data/api/IApiServiceConfig';
import { PriorityType } from '../ui-shared/utils/types';
import {
  SupportTicketPostRequest,
  SupportTicketRequest,
  SupportTicketResponse,
} from '@fullyops/legacy/data/api/types/SupportTicket';
import { SupportTicketContactResponse } from '@fullyops/legacy/data/api/types/Contact';

@Injectable({
  providedIn: 'root',
})
export class SupportTicketContactController extends UiServiceV2 {
  constructor(
    private http: HttpClient,
    @Inject(API_SERVICE_CONFIG) private config: IApiServiceConfig
  ) {
    super();
  }

  private readonly endpointUrl =
    this.config.url + 'tickets/multimachinesupports/contacts';

  create(params: { contactId: string; supportTicketId: string }) {
    return this.http.post<SupportTicketContactResponse>(
      this.endpointUrl,
      params
    );
  }

  delete(params: { contactId: string; supportTicketId: string }) {
    return this.http.delete(
      `${this.endpointUrl}/${params.supportTicketId}/${params.contactId}`
    );
  }
}
