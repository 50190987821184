import { Component } from '@angular/core';

@Component({
  selector: 'crm-report-request-modal',
  templateUrl: './report-request-modal.component.html',
  styleUrls: ['./report-request-modal.component.scss'],
})
export class ReportRequestModalComponent {
  data: FormData = null;

  onSignatureChange(file: FormData) {
    this.data = file;
  }
}
