import { Injectable } from '@angular/core';
import {
  Response,
  SubProductsEndpoint,
  UnsavedSubProduct,
  SubProduct,
} from '@fullyops/legacy/data';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UiSubProductsService {
  readonly subProducts$ = this.subProducts.createStore();

  constructor(protected subProducts: SubProductsEndpoint) {}

  loadAll() {
    this.loadSubProducts();
  }

  loadSubProducts() {
    this.subProducts
      .getAll(undefined, this.subProducts$)
      .subscribe({ error: console.error });
  }

  getSubProduct(id: string): Observable<Response<SubProduct>> {
    return this.subProducts.get(id, undefined, this.subProducts$);
  }

  createSubProduct(subProduct: UnsavedSubProduct) {
    return this.subProducts.post(subProduct, undefined, this.subProducts$);
  }

  updateSubProduct(productId: string, unsavedSubProducts: UnsavedSubProduct) {
    return this.subProducts.patch(
      productId,
      unsavedSubProducts,
      undefined,
      this.subProducts$
    );
  }

  removeSubProduct(id: string): Observable<Response<{}>> {
    return this.subProducts.delete(id, undefined, this.subProducts$);
  }
}
