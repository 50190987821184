import { Response } from '../Response';
import { Endpoint } from '../Endpoint';
import { HttpClient } from '@angular/common/http';
import { IListResponse } from '../IListResponse';
import { Injectable, Inject } from '@angular/core';
import { API_SERVICE_CONFIG } from '../ApiServiceConfig';

import { Observable, BehaviorSubject } from 'rxjs';

import { History } from '../../models/v0/History';
import { ISerialisedHistory } from '../../models/v0/serialisations/ISerialisedHistory';
import { IApiServiceConfig } from '../IApiServiceConfig';

type HistoryList = IListResponse<ISerialisedHistory>;

interface HistoryFilters {
  readonly assignee?: string;
  readonly company?: string;
  readonly contact?: string;
  readonly ticketId?: string;
  readonly ticketType?: string;
  readonly created?: string;
  readonly order?: string;
}

@Injectable()
export class HistoriesEndpoint extends Endpoint<History, ISerialisedHistory> {
  private readonly url = this.config.url + 'history';

  constructor(
    @Inject(API_SERVICE_CONFIG) private config: IApiServiceConfig,
    private http: HttpClient
  ) {
    super(History.fromSerialised);
  }

  getAll(
    historyFilters?: HistoryFilters,
    source?: Observable<HistoryList>,
    store?: BehaviorSubject<Response<History[]>>
  ): Observable<Response<History[]>> {
    let filters = '';
    Object.keys(historyFilters).forEach(
      (key) => (filters += `&${key}=${historyFilters[key]}`)
    );

    const defaultSource = this.http.get<HistoryList>(
      `${this.url}?pageNumber=0${filters}`
    );
    return this.list(source || defaultSource, store);
  }

  get(
    id: string,
    source?: Observable<ISerialisedHistory>,
    store?: BehaviorSubject<Response<History[]>>
  ): Observable<Response<History>> {
    const defaultSource = this.http.get<ISerialisedHistory>(
      `${this.url}/${id}/`
    );
    return this.retrieve(source || defaultSource, store);
  }
}
