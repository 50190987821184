import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

interface WindowWithCSS extends Window {
  CSS: { supports: (sel: string, val: string) => boolean };
}

@Injectable({
  providedIn: 'root',
})
export class WindowService {
  readonly isBrowser: boolean;

  constructor(@Inject(PLATFORM_ID) platformId) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  get window(): WindowWithCSS | null {
    return (this.isBrowser && (window as WindowWithCSS)) || null;
  }
}
