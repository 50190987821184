import {
  Component,
  Input,
  OnInit,
  ChangeDetectionStrategy,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { ComponentInOutAnimation } from '../utils/component-base-animation';

@Component({
  selector: 'crm-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  animations: [ComponentInOutAnimation.AnimTrigger],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarouselComponent implements OnInit {
  @Input() preview = false;
  @Input() imagesControl: UntypedFormControl;
  @Input() uploadService: (
    file: File,
    extension: string
  ) => BehaviorSubject<{ url: string }>;

  currentImageIndex = 0;
  currentImages$ = new BehaviorSubject([]);

  ngOnInit() {
    this.currentImages$.next(this.imagesControl.value);

    /* TODO - IDEAS
     *
     * thumbnails/dots
     * slideshow (play/pause)
     * image caption (use filename?/implement interface for image object)
     */
  }

  onPreviousClick(event: any) {
    event.preventDefault();
    const previous = this.currentImageIndex - 1;
    this.currentImageIndex =
      previous < 0 ? this.imagesControl.value?.length - 1 : previous;
  }

  onNextClick(event: any) {
    event.preventDefault();
    const next = this.currentImageIndex + 1;
    this.currentImageIndex =
      next === this.imagesControl.value?.length ? 0 : next;
  }

  onRemoveImgClick() {
    let updated = this.imagesControl.value;
    updated.splice(this.currentImageIndex, 1);
    this.currentImages$.next(updated);
    this.imagesControl.patchValue(updated);
  }

  onAddImg(event: any) {
    this.uploadService(event.target.files[0], 'png').subscribe((response) => {
      let imgUrls = [];
      this.imagesControl.value
        ? (imgUrls = [response.url, ...this.imagesControl.value])
        : (imgUrls = [response.url]);
      this.currentImages$.next(imgUrls);
      this.imagesControl.patchValue(imgUrls);
    });
  }
}
