<div class="product-image">
  <input type="file" (change)="uploadImage($event)" #fileInput />
  <button
    *ngIf="!disabled"
    mat-button
    type="button"
    color="primary"
    (click)="fileInput.click()"
  >
    <mat-icon>cloud_upload</mat-icon>
  </button>
  <img *ngIf="currentImage$ | async as currentImage" [src]="currentImage" />
  <span *ngIf="!imageControl.value">{{ 'forms:addImage' | i18nextCap }}</span>
</div>
