import { NgModule } from '@angular/core';

import { WorkOrderCategoriesRoutingModule } from './work-order-categories.routing.module';
import { SharedModule } from '@fullyops/shared/shared.module';
import { DataModule } from '@fullyops/legacy/data/data.module';
import { AsyncPipe, NgForOf } from '@angular/common';
import { WorkOrderCategoriesComponent } from '@fullyops/features/work-order-categories/containers/work-order-categories/work-order-categories.component';
import { UiModule } from '@fullyops/legacy/ui';
import { NgxColorsModule } from 'ngx-colors';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CreateWorkOrderCategoriesComponent } from '@fullyops/features/work-order-categories/containers/create-work-order-categories/create-work-order-categories.component';
import { ReactiveFormsModule } from '@angular/forms';
import { EditWorkOrderCategoriesComponent } from '@fullyops/features/work-order-categories/containers/edit-work-order-categories/edit-work-order-categories.component';
import { DomainModule } from '@fullyops/domain/domain.module';
@NgModule({
  imports: [
    WorkOrderCategoriesRoutingModule,
    SharedModule,
    DataModule,
    NgForOf,
    AsyncPipe,
    UiModule,
    NgxColorsModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    DomainModule,
  ],
  declarations: [
    WorkOrderCategoriesComponent,
    CreateWorkOrderCategoriesComponent,
    EditWorkOrderCategoriesComponent,
  ],
  exports: [
    WorkOrderCategoriesComponent,
    CreateWorkOrderCategoriesComponent,
    EditWorkOrderCategoriesComponent,
  ],
})
export class WorkOrderCategoriesModule {}
