import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { ComponentInOutAnimation } from '@fullyops/legacy/ui/ui-shared/utils/component-base-animation';

@Component({
  selector: 'crm-product-form-details-subsection',
  templateUrl: './product-form-details-subsection.component.html',
  styleUrls: ['./product-form-details-subsection.component.scss'],
  animations: [ComponentInOutAnimation.AnimTrigger],
})
export class ProductFormDetailsSubsectionComponent {
  @Input() preview = false;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() createEntryLabel: string;
  @Input() subsectionForm: UntypedFormGroup;
  @Input() uploadService: (
    file: File,
    extension: string
  ) => BehaviorSubject<{ url: string }>;
  @Output() removeEvent = new EventEmitter();

  get content(): UntypedFormArray {
    return this.subsectionForm.get('content') as UntypedFormArray;
  }

  removeSubsection() {
    this.removeEvent.emit();
  }

  removeSubsectionItem(index: number) {
    this.content.controls.splice(index, 1);
  }

  addSubsectionItem() {
    this.content.push(
      new UntypedFormGroup(
        {
          url: new UntypedFormControl(null, []),
          data: new UntypedFormArray([], []),
        },
        Validators.required
      )
    );
  }
}
