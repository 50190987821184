import { Response } from '../Response';
import { Endpoint } from '../Endpoint';
import { HttpClient } from '@angular/common/http';
import { IListResponse } from '../IListResponse';
import { Injectable, Inject } from '@angular/core';
import { API_SERVICE_CONFIG } from '../ApiServiceConfig';

import { Observable, BehaviorSubject } from 'rxjs';
import { IApiServiceConfig } from '../IApiServiceConfig';

import { map, tap } from 'rxjs/operators';
import { ISerialisedInterventionPart } from '../../models/v0/serialisations/ISerialisedInterventionPart';
import { InterventionPart } from '../../models/v0/InterventionPart';
import { UnsavedInterventionPart } from '../../models/v0/UnsavedInterventionPart';

type PartList = IListResponse<ISerialisedInterventionPart>;

@Injectable()
export class InterventionPartsEndpoint extends Endpoint<
  InterventionPart,
  ISerialisedInterventionPart
> {
  private readonly url = this.config.url + 'interventions/parts';

  constructor(
    @Inject(API_SERVICE_CONFIG) private config: IApiServiceConfig,
    private http: HttpClient
  ) {
    super(InterventionPart.fromSerialised);
  }

  getAll(
    filters: {},
    source?: Observable<PartList>,
    store?: BehaviorSubject<Response<InterventionPart[]>>
  ): Observable<Response<InterventionPart[]>> {
    let query = '';
    Object.keys(filters).forEach((key) => (query += `&${key}=${filters[key]}`));

    const defaultSource = this.http.get<PartList>(
      `${this.url}?size=100&page=0${query}`
    );
    return this.list(source || defaultSource, store);
  }

  get(
    id: string,
    source?: Observable<ISerialisedInterventionPart>,
    store?: BehaviorSubject<Response<InterventionPart[]>>
  ): Observable<Response<InterventionPart>> {
    const defaultSource = this.http.get<ISerialisedInterventionPart>(
      `${this.url}/${id}/`
    );
    return this.retrieve(source || defaultSource, store);
  }

  post(
    interventionId: string,
    partId: string,
    source?: Observable<ISerialisedInterventionPart>,
    store?: BehaviorSubject<Response<InterventionPart[]>>
  ): Observable<Response<InterventionPart>> {
    const body = {
      interventionId: interventionId,
      partId: partId,
      quantity: 1,
    };

    const defaultSource = this.http.post<ISerialisedInterventionPart>(
      this.url,
      body
    );
    return this.add(source || defaultSource, store);
  }

  patch(
    interventionId: string,
    partId: string,
    quantity: number,
    source?: Observable<ISerialisedInterventionPart>,
    store?: BehaviorSubject<Response<InterventionPart[]>>
  ): Observable<Response<InterventionPart>> {
    const body = {
      quantity,
    };
    const defaultSource = this.http.patch<ISerialisedInterventionPart>(
      `${this.url}/${interventionId}/${partId}/`,
      body
    );

    return defaultSource.pipe(
      tap((instance) =>
        this.update(
          source || defaultSource,
          InterventionPart.fromSerialised(instance),
          store
        )
      ),
      map(
        (serialiser) =>
          new Response(InterventionPart.fromSerialised(serialiser))
      )
    );
  }

  delete(
    interventionId: string,
    partId: string,
    source?: Observable<{}>,
    store?: BehaviorSubject<Response<InterventionPart[]>>
  ): Observable<Response<{}>> {
    const defaultSource = this.http.delete<{}>(
      `${this.url}/${interventionId}/${partId}/`
    );
    return this.remove(source || defaultSource, interventionId + partId, store);
  }
}
