<div class="assignees">
  <ng-template ngFor let-assignee let-i="index" [ngForOf]="assignees">
    <user-profile-image-component
      *ngIf="i < maxDisplay"
      [user]="assignee"
      [size]="size"
      [textOnly]="true"
    ></user-profile-image-component>
  </ng-template>
  <user-profile-image-component
    *ngIf="assignees.length - maxDisplay > 0"
    [user]="{ firstName: '+', lastName: assignees.length - maxDisplay }"
    [size]="size"
    [textOnly]="true"
  ></user-profile-image-component>
</div>
