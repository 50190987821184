import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormArray,
  Validators,
} from '@angular/forms';
import {
  UnsavedSubProduct,
  UnsavedSubProductVariant,
} from '@fullyops/legacy/data';

class SubProductVariantForm extends UntypedFormGroup {
  constructor(variant: UnsavedSubProductVariant, dis: boolean) {
    super({});
    Object.keys(variant)
      .filter((key) => key !== 'accessories')
      .forEach((key) =>
        this.addControl(
          key,
          new UntypedFormControl({ value: variant[key], disabled: dis }, [])
        )
      );

    this.addControl('accessories', new UntypedFormArray([], []));
  }
}

@Injectable()
export class SubProductVariantFormService {
  fb = new UntypedFormBuilder();

  private initialVariant: BehaviorSubject<UnsavedSubProductVariant>;
  private subProductVariantForm: BehaviorSubject<UntypedFormGroup> =
    new BehaviorSubject<UntypedFormGroup>(new UntypedFormGroup({}));
  subProductVariantForm$: Observable<UntypedFormGroup> =
    this.subProductVariantForm.asObservable();

  constructor() {}

  initForm(draftVariant: UnsavedSubProductVariant, disabled: boolean) {
    this.initialVariant = new BehaviorSubject(draftVariant);

    this.subProductVariantForm.next(
      new SubProductVariantForm(draftVariant, disabled)
    );
  }

  getSubProductDraft(): UnsavedSubProductVariant {
    const variantDraft = (
      this.subProductVariantForm.getValue() as UntypedFormGroup
    ).value;
    return this.initialVariant.getValue().clone({
      name: variantDraft.name,
      price: variantDraft.price,
      stock: variantDraft.stock,
      description: variantDraft.description,
      accessories: variantDraft.accessories,
      imageUri: variantDraft.imageUri,
    });
  }

  markAllAsTouched() {
    const currentProduct = this.subProductVariantForm.getValue();
    currentProduct.markAllAsTouched();

    this.subProductVariantForm.next(currentProduct);
  }
}
