<!-- carousel -->
<div class="carousel">
  <ng-container
    *ngFor="let currentImage of currentImages$ | async; let i = index"
  >
    <img
      *ngIf="i === currentImageIndex"
      [@inOut]="'in'"
      [src]="currentImage"
      class="slide"
    />
  </ng-container>

  <!-- arrows -->
  <button class="control prev" (click)="onPreviousClick($event)">
    <span class="arrow left"></span>
  </button>
  <button class="control next" (click)="onNextClick($event)">
    <span class="arrow right"></span>
  </button>
</div>

<!-- add/remove images -->
<div class="menu" *ngIf="!preview">
  <button
    *ngIf="this.imagesControl.value && this.imagesControl.value.length > 0"
    mat-button
    color="warn"
    type="button"
    (click)="onRemoveImgClick()"
  >
    <mat-icon>clear</mat-icon>
  </button>

  <input #fileInput type="file" (change)="onAddImg($event)" />
  <button mat-button type="button" color="primary" (click)="fileInput.click()">
    <mat-icon>cloud_upload</mat-icon>
  </button>
</div>
