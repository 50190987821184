<crm-breadcrumb
  [header]="header"
  [actionButtons]="actions"
  (actionEvent)="onActionEvent($event)"
></crm-breadcrumb>
<ng-container *ngIf="formService.form$ | async as productConfigForm">
  <crm-container [maxSize]="'xl'">
    <form
      #ngForm="ngForm"
      [@inOut]="'in'"
      [formGroup]="productConfigForm"
      (ngSubmit)="onSubmit()"
    >
      <mat-tab-group>
        <mat-tab>
          <ng-template mat-tab-label>{{
            'forms:product' | i18nextCap
          }}</ng-template>
          <div class="form-row">
            <div class="col-md-6">
              <div class="product-image">
                <span *ngIf="!productImage">{{
                  'forms:addImage' | i18nextCap
                }}</span>
                <img
                  *ngIf="productImage"
                  class="product-image"
                  [src]="productImage"
                />
              </div>
            </div>
            <div class="col-md-6">
              <crm-form-template
                [formGroup]="productConfigForm"
                [formFields]="formFields"
              ></crm-form-template>
              <crm-form-template
                [formGroup]="$any(productConfigForm.controls.product)"
                [formFields]="productFields"
              ></crm-form-template>
            </div>
            <button
              type="submit"
              mat-flat-button
              color="primary"
              [disabled]="preview"
            >
              {{ 'forms:save' | i18nextCap }}
            </button>
          </div>
        </mat-tab>

        <mat-tab [disabled]="!productSelected">
          <ng-template mat-tab-label>{{
            'forms:subProducts' | i18nextCap
          }}</ng-template>
          <crm-product-configuration-form-sub-products
            [formGroup]="$any(productConfigForm.controls.subproductConfigs)"
            [subProducts$]="productConfigFormService.subProducts$"
          ></crm-product-configuration-form-sub-products>
        </mat-tab>

        <mat-tab [disabled]="!productSelected">
          <ng-template mat-tab-label>{{
            'forms:additionalInformation' | i18nextCap
          }}</ng-template>
          <crm-product-form-details
            [preview]="preview"
            title="additionalInformation"
            subtitle="reportSections"
            createEntryLabel="addSection"
            [sectionsForm]="$any(productConfigForm.controls.additionalInfo)"
            [uploadService]="uploadService"
          ></crm-product-form-details>
        </mat-tab>
      </mat-tab-group>
    </form>
  </crm-container>
</ng-container>
