import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { DOCUMENT } from '@angular/common';
import { API_SERVICE_CONFIG } from '@fullyops/legacy/data';
import { IApiServiceConfig } from '@fullyops/legacy/data/api/IApiServiceConfig';

@Injectable()
export class CustomApplicationReferrerInterceptor implements HttpInterceptor {
  private static REFERRER_HEADER_NAME: string = 'X-Fullyops-Referrer';

  constructor(
    @Inject(DOCUMENT) private document,
    @Inject(API_SERVICE_CONFIG) private apiServiceConfig: IApiServiceConfig
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.url.startsWith(this.apiServiceConfig.url)) {
      return next.handle(
        request.clone({
          headers: request.headers.set(
            CustomApplicationReferrerInterceptor.REFERRER_HEADER_NAME,
            this.document.location.toString()
          ),
        })
      );
    }

    return next.handle(request);
  }
}
