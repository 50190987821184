import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AsyncPipe, JsonPipe, NgForOf, NgIf } from '@angular/common';
import { InternalErrorComponent } from '@fullyops/features/errors/internal-error/components/internal-error.component';

const routes: Routes = [
  { path: 'internal-error', component: InternalErrorComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes), NgForOf, AsyncPipe, JsonPipe, NgIf],
  exports: [RouterModule],
  declarations: [InternalErrorComponent],
})
export class ErrorsRoutingModule {}
