import { Response } from '../Response';
import { Endpoint } from '../Endpoint';
import { HttpClient } from '@angular/common/http';
import { IListResponse } from '../IListResponse';
import { Injectable, Inject } from '@angular/core';
import { API_SERVICE_CONFIG } from '../ApiServiceConfig';
import { IApiServiceConfig } from '../IApiServiceConfig';

import { Observable, BehaviorSubject, of } from 'rxjs';

import { TicketSale } from '../../models/v0/TicketSale';
import { UnsavedTicketSale } from '../../models/v0/UnsavedTicketSale';
import { ISerialisedTicketSale } from '../../models/v0/serialisations/ISerialisedTicketSale';
import { tap, map } from 'rxjs/operators';

type TicketSaleList = IListResponse<ISerialisedTicketSale>;

@Injectable()
export class TicketsSaleEndpoint extends Endpoint<
  TicketSale,
  ISerialisedTicketSale
> {
  private readonly url = this.config.url + 'tickets/sales/';

  constructor(
    @Inject(API_SERVICE_CONFIG) private config: IApiServiceConfig,
    private http: HttpClient
  ) {
    super(TicketSale.fromSerialised);
  }

  getAll(
    filters: {},
    source?: Observable<TicketSaleList>,
    store?: BehaviorSubject<Response<TicketSale[]>>
  ): Observable<Response<TicketSale[]>> {
    let query = '';
    Object.keys(filters).forEach((key) => (query += `&${key}=${filters[key]}`));

    const defaultSource = this.http.get<TicketSaleList>(
      `${this.url}?page=0${query}`
    );
    return this.list(source || defaultSource, store);
  }

  get(
    id: string,
    source?: Observable<ISerialisedTicketSale>,
    store?: BehaviorSubject<Response<TicketSale[]>>
  ): Observable<Response<TicketSale>> {
    const defaultSource = this.http.get<ISerialisedTicketSale>(
      `${this.url}${id}/`
    );
    return this.retrieve(source || defaultSource, store);
  }

  post(
    ticketSale: UnsavedTicketSale,
    source?: Observable<ISerialisedTicketSale>,
    store?: BehaviorSubject<Response<TicketSale[]>>
  ): Observable<Response<TicketSale>> {
    const defaultSource = this.http.post<ISerialisedTicketSale>(
      this.url,
      ticketSale.toSerialised()
    );
    return this.add(source || defaultSource, store);
  }

  patch(
    id: string,
    ticketSale: UnsavedTicketSale,
    source?: Observable<ISerialisedTicketSale>,
    store?: BehaviorSubject<Response<TicketSale[]>>
  ) {
    const body = ticketSale.toSerialised();
    const defaultSource = this.http.patch<ISerialisedTicketSale>(
      `${this.url}${id}/`,
      body
    );

    return defaultSource.pipe(
      tap((instance) =>
        this.update(
          source || defaultSource,
          TicketSale.fromSerialised(instance),
          store
        )
      ),
      map((serialiser) => new Response(TicketSale.fromSerialised(serialiser)))
    );
  }

  delete(
    id: string,
    source?: Observable<{}>,
    store?: BehaviorSubject<Response<TicketSale[]>>
  ): Observable<Response<{}>> {
    const defaultSource = this.http.delete<{}>(`${this.url}${id}/`);
    return this.remove(source || defaultSource, id, store);
  }
}
