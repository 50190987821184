import { Injectable } from '@angular/core';
import {
  Response,
  ProductsConfigEndpoint,
  UnsavedProductConfig,
  ProductConfig,
  ProductConfigListItem,
} from '@fullyops/legacy/data';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UiProductsConfigurationService {
  readonly productsConfigStore$ = this.productsConfig.createStore();
  readonly productsConfigs$ = new BehaviorSubject<
    Response<ProductConfigListItem[]>
  >(new Response<ProductConfigListItem[]>());

  constructor(protected productsConfig: ProductsConfigEndpoint) {}

  loadAll() {
    this.loadProductsConfig();
  }

  loadProductsConfig() {
    this.productsConfig
      .getAll()
      .subscribe((response) => this.productsConfigs$.next(response));
  }

  get(id: string): Observable<Response<ProductConfig>> {
    return this.productsConfig.get(id, undefined, this.productsConfigStore$);
  }

  create(productsConfig: UnsavedProductConfig) {
    return this.productsConfig.post(
      productsConfig,
      undefined,
      this.productsConfigStore$
    );
  }

  update(
    id: string,
    unsavedModel: UnsavedProductConfig
  ): Observable<Response<ProductConfig>> {
    return;
  }

  remove(id: string): Observable<Response<{}>> {
    return this.productsConfig.delete(id, undefined, this.productsConfigStore$);
  }

  convert(simulationId: string) {
    return this.productsConfig.convert(
      simulationId,
      undefined,
      this.productsConfigStore$
    );
  }
}
