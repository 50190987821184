import { ISerialisedUnsavedUpload } from './serialisations/ISerialisedUpload';

export class UnsavedUpload {
  constructor(readonly file: FormData) {}

  static fromSerialised(serialised: ISerialisedUnsavedUpload) {
    return new UnsavedUpload(serialised.file);
  }

  toSerialised() {
    return this.file;
  }
}
