import { Injectable } from '@angular/core';
import { v4 as getUuid } from 'uuid';

// TODO: Move to a shared module
@Injectable({
  providedIn: 'root',
})
export class UuidGeneratorService {
  getUuid(): string {
    return getUuid();
  }
}
