<crm-table
  [paginatedList$]="listTicketsPage$"
  [displayedColumns]="displayedColumns"
  (onPageChange)="handlePageSelection($event)"
  (onClick)="onOpen($event)"
>
  <!-- Name Column-->
  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef>
      {{ 'name' | i18nextCap }}
    </mat-header-cell>
    <mat-cell *matCellDef="let element">
      <div class="cell-row">
        <span class="title">[{{ element.identifier }}] {{ element.name }}</span>
        <span class="subtitle" *ngIf="element?.company?.name"
          >{{ element.company.name }}
        </span>
        <span class="subtitle" *ngIf="element?.supplier?.name"
          >{{ element.supplier.name }}
        </span>
      </div>
    </mat-cell>
  </ng-container>

  <!-- Status Column-->
  <ng-container matColumnDef="status">
    <mat-header-cell *matHeaderCellDef>
      {{ 'status' | i18nextCap }}
    </mat-header-cell>
    <mat-cell *matCellDef="let element">
      <div class="cell-row">
        <label [ngClass]="['mat-label', element.status.color]">
          {{ element.status.name | i18nextCap }}
        </label>
      </div>
    </mat-cell>
  </ng-container>

  <!-- Priority Column-->
  <ng-container matColumnDef="priority">
    <mat-header-cell *matHeaderCellDef>
      {{ 'priority' | i18nextCap }}
    </mat-header-cell>
    <mat-cell *matCellDef="let element">
      <div class="cell-row">
        <span class="title"
          ><img
            [attr.alt]="element.priority"
            [src]="
              'assets/svg/priorities/' +
              element.priority.toLocaleLowerCase() +
              '.svg'
            "
          />
          {{ element.priority | i18nextCap }}</span
        >
      </div>
    </mat-cell>
  </ng-container>

  <!-- Assignees Column-->
  <ng-container matColumnDef="assignees">
    <mat-header-cell *matHeaderCellDef>
      {{ 'assignees' | i18nextCap }}
    </mat-header-cell>
    <mat-cell *matCellDef="let element">
      <crm-assignees-icon-list
        [assignees]="element.assignees"
        [size]="25"
      ></crm-assignees-icon-list>
    </mat-cell>
  </ng-container>
</crm-table>
