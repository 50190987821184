import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
  ValidatorFn,
  AbstractControl,
  UntypedFormArray,
} from '@angular/forms';
import {
  UnsavedTicketSupportMultimachine,
  Travel,
  ClientInfo,
  ClientEquipment,
  Part,
} from '@fullyops/legacy/data';

class TicketSupportMultimachineMockupForm {
  ticketInfo = new UntypedFormGroup({});
  ticketDetails = new UntypedFormGroup({});
  ticketMachines = new UntypedFormGroup({});
  ticketParts = new UntypedFormGroup({});
  ticketInterventions = new UntypedFormGroup({});

  assigneeId = new UntypedFormControl({});
  priority = new UntypedFormControl({});
  statusId = new UntypedFormControl({});
  actAt = new UntypedFormControl({});
  actTime = new UntypedFormControl({});
  createdAt = new UntypedFormControl({});

  constructor(
    ticket: UnsavedTicketSupportMultimachine,
    dis: boolean,
    disableCompany: boolean
  ) {
    const fb = new UntypedFormBuilder();

    this.ticketInfo.addControl(
      'name',
      fb.control({ value: ticket.name, disabled: dis }, Validators.required)
    );
    this.ticketInfo.addControl(
      'createdAt',
      fb.control({ value: ticket.createdAt, disabled: true }, [])
    );
    this.ticketInfo.addControl(
      'malfunctionType',
      fb.control(
        { value: ticket.malfunctionType, disabled: dis },
        Validators.required
      )
    );
    this.ticketInfo.addControl(
      'companyId',
      fb.control(
        { value: ticket.companyId, disabled: disableCompany || dis },
        Validators.required
      )
    );
    this.ticketInfo.addControl(
      'clientAddress',
      fb.control({ value: ticket.clientInfo.address, disabled: dis }, [])
    );
    this.ticketInfo.addControl(
      'clientDistance',
      fb.control({ value: ticket.clientInfo.distance, disabled: dis }, [])
    );
    this.ticketInfo.addControl(
      'clientEmail',
      fb.control({ value: '', disabled: true }, [])
    );
    this.ticketInfo.addControl(
      'clientPhone',
      fb.control({ value: '', disabled: true }, [])
    );
    this.ticketInfo.addControl(
      'contactId',
      fb.control({ value: ticket.contactIds, disabled: dis })
    );
    this.ticketDetails.addControl(
      'actAt',
      fb.control({ value: ticket.actAt, disabled: dis }, [])
    );
    this.ticketDetails.addControl(
      'assignees',
      fb.control({ value: ticket.assigneeIds, disabled: dis }, [])
    );
    this.ticketDetails.addControl(
      'clientInfo',
      fb.control({ value: ticket.clientInfo, disabled: dis }, [])
    );
    this.ticketDetails.addControl(
      'closedAt',
      fb.control({ value: ticket.closedAt, disabled: dis }, [])
    );
    this.ticketDetails.addControl(
      'company',
      fb.control({ value: ticket.companyId, disabled: dis }, [])
    );
    this.ticketDetails.addControl(
      'contacts',
      fb.control({ value: ticket.contactIds, disabled: dis }, [])
    );
    this.ticketDetails.addControl(
      'createdAt',
      fb.control({ value: ticket.createdAt, disabled: dis }, [])
    );
    this.ticketDetails.addControl(
      'executedAt',
      fb.control({ value: ticket.executedAt, disabled: dis }, [])
    );
    this.ticketDetails.addControl(
      'finished',
      fb.control({ value: ticket.finished, disabled: dis }, [])
    );
    this.ticketDetails.addControl(
      'name',
      fb.control({ value: ticket.name, disabled: dis }, [])
    );
    this.ticketDetails.addControl(
      'companyTravelTime',
      fb.control({ value: 0, disabled: dis }, [])
    );
    this.ticketDetails.addControl(
      'priority',
      fb.control({ value: ticket.priority, disabled: dis }, [])
    );

    //this.ticketDetails.addControl(
    //  'status',
    //  fb.control({ value: ticket.statusId, disabled: dis }, [])
    //);

    this.ticketInterventions.addControl(
      'machine',
      fb.control({ value: '', disabled: dis })
    );

    this.assigneeId = fb.control({
      value: ticket.assigneeIds.map((assignee) => assignee),
      disabled: dis,
    });
    this.priority = fb.control(
      { value: ticket.priority, disabled: dis },
      Validators.required
    );
    this.statusId = fb.control(
      { value: ticket.statusId, disabled: dis },
      Validators.required
    );
    this.actAt = fb.control({ value: ticket.actAt, disabled: dis }, []);
    this.actTime = fb.control({ value: '00:00', disabled: dis }, []);
    this.createdAt = fb.control(
      { value: ticket.createdAt, disabled: true },
      []
    );

    const usersParts = new UntypedFormGroup({});

    this.assigneeId.valueChanges.subscribe((assignees: string[]) => {
      const usersPartsGroup = this.ticketParts.get(
        'usersParts'
      ) as UntypedFormGroup;

      assignees.forEach((assigneeId) => {
        if (!usersPartsGroup[assigneeId]) {
          usersPartsGroup.addControl(assigneeId, fb.array([], []));
        }
      });

      Object.keys(usersPartsGroup.controls).forEach((assigneeId: string) => {
        if (assignees.indexOf(assigneeId) === -1) {
          usersPartsGroup.removeControl(assigneeId);
        }
      });
    });

    const parts = fb.array([]);

    this.ticketParts.addControl('parts', parts);
    this.ticketParts.addControl('usersParts', usersParts);
    this.ticketParts.addControl(
      'unregisteredParts',
      fb.control({ value: ticket.unregisteredParts, disabled: dis }, [])
    );

    const travels = fb.array(
      ticket.travels.map((travel: Travel) =>
        fb.group({
          id: fb.control({ value: travel.id, disabled: dis }, []),
          assignees: fb.control(
            { value: travel.assignees, disabled: dis },
            Validators.required
          ),
          distance: fb.control(
            { value: travel.distance, disabled: dis },
            Validators.required
          ),
          stay: fb.control(
            { value: travel.stay, disabled: dis },
            Validators.required
          ),
          startingHour: fb.control(
            { value: travel.startingHour, disabled: dis },
            Validators.required
          ),
          endingHour: fb.control(
            { value: travel.endingHour, disabled: dis },
            Validators.required
          ),
        })
      ),
      []
    );

    this.ticketDetails.addControl('travels', travels);
  }
}

@Injectable()
export class TicketSupportFormService {
  fb = new UntypedFormBuilder();

  private form: BehaviorSubject<UntypedFormGroup> =
    new BehaviorSubject<UntypedFormGroup>(new UntypedFormGroup({}));
  private initialTicket: UnsavedTicketSupportMultimachine;
  form$: Observable<UntypedFormGroup> = this.form.asObservable();

  constructor() {}

  initForm(
    draftTicketSupportMultimachine: UnsavedTicketSupportMultimachine,
    disabled: boolean
  ) {
    this.initialTicket = draftTicketSupportMultimachine;
    this.initialTicket.companyId;
    this.form.next(
      this.fb.group(
        new TicketSupportMultimachineMockupForm(
          draftTicketSupportMultimachine,
          disabled,
          this.initialTicket.companyId ? true : false
        )
      )
    );
  }

  getDraft() {
    const ticketInfoGroup = this.form
      .getValue()
      .get('ticketInfo') as UntypedFormControl;
    const ticketDetailsGroup = this.form
      .getValue()
      .get('ticketDetails') as UntypedFormControl;

    return this.initialTicket.clone({
      name: (ticketInfoGroup.get('name') as UntypedFormControl).value,
      priority: (this.form.getValue().get('priority') as UntypedFormControl)
        .value,
      assigneeIds: (
        this.form.getValue().get('assigneeId') as UntypedFormControl
      ).value,
      companyId: (ticketInfoGroup.get('companyId') as UntypedFormControl).value,
      clientInfo: new ClientInfo(
        (ticketInfoGroup.get('clientAddress') as UntypedFormControl).value,
        (ticketInfoGroup.get('clientCity') as UntypedFormControl).value,
        (ticketInfoGroup.get('countryISOCode') as UntypedFormControl).value,
        (ticketInfoGroup.get('distance') as UntypedFormControl).value,
        (ticketInfoGroup.get('zipCode') as UntypedFormControl).value
      ),
      contactIds: (ticketInfoGroup.get('contactId') as UntypedFormControl)
        .value,
      malfunctionType: (
        ticketInfoGroup.get('malfunctionType') as UntypedFormControl
      ).value,
      actAt: (this.form.getValue().get('actAt') as UntypedFormControl).value,
      finished: (ticketDetailsGroup.get('finished') as UntypedFormControl)
        .value,
      statusId: (this.form.getValue().get('statusId') as UntypedFormControl)
        .value,
      travels: (
        ticketDetailsGroup.get('travels') as UntypedFormArray
      ).value.map(
        (travel) =>
          new Travel(
            [],
            '',
            travel.createdBy,
            0,
            '',
            '',
            '',
            travel.stay,
            '',
            '',
            '',
            travel.updatedBy
          )
      ),
    });
  }

  addMachine(machine: ClientEquipment, userId: string) {
    const currentTicket = this.form.getValue();
    const currentMachines = (
      currentTicket.get('ticketMachines') as UntypedFormGroup
    ).get(userId) as UntypedFormArray;
    currentMachines.push(
      this.fb.group({
        machineId: this.fb.control({ value: machine.id, disabled: false }, []),
        brand: this.fb.control({ value: machine.brand, disabled: true }, []),
        model: this.fb.control({ value: machine.model, disabled: true }, []),
        serialNumber: this.fb.control(
          { value: machine.serialNumber, disabled: true },
          []
        ),
        type: this.fb.control({ value: machine.type, disabled: true }, []),
        anomalies: this.fb.control(
          { value: 0, disabled: false },
          Validators.required
        ),
        interventions: this.fb.control(
          { value: 0, disabled: false },
          Validators.required
        ),
      })
    );
    currentTicket.markAsDirty();
    this.form.next(currentTicket);
  }

  addPart(part: Part, userId: string) {
    const currentTicket = this.form.getValue();
    const currentParts = (
      currentTicket.get('ticketParts').get('usersParts') as UntypedFormGroup
    ).get(userId) as UntypedFormArray;
    currentParts.push(
      this.fb.group({
        partId: this.fb.control({ value: part.id, disabled: false }, []),
        name: this.fb.control({ value: part.name, disabled: true }, []),
        price: this.fb.control({ value: part.price, disabled: true }, []),
        ref: this.fb.control({ value: part.ref, disabled: true }, []),
        quantity: this.fb.control(
          { value: 1, disabled: false },
          Validators.required
        ),
        costSubtotal: this.fb.control(
          { value: part.price, disabled: true },
          []
        ),
      })
    );
    currentTicket.markAsDirty();
    this.form.next(currentTicket);
  }

  deleteMachine(machineId: string, userId: string) {
    const currentTicket = this.form.getValue();
    const currentUserMachines = currentTicket
      .get('ticketMachines')
      .get(userId) as UntypedFormArray;
    const index = currentUserMachines.controls.findIndex(
      (control) => control.get('machineId').value === machineId
    );

    currentUserMachines.removeAt(index);
    currentTicket.markAsDirty();
    this.form.next(currentTicket);
  }

  deletePart(partId: string, userId: string) {
    const currentTicket = this.form.getValue();
    const currentUserParts = currentTicket
      .get('ticketParts')
      .get('usersParts')
      .get(userId) as UntypedFormArray;
    const index = currentUserParts.controls.findIndex(
      (control) => control.get('partId').value === partId
    );

    currentUserParts.removeAt(index);
    currentTicket.markAsDirty();
    this.form.next(currentTicket);
  }

  markAllAsTouched() {
    const currentTicketSupport = this.form.getValue();
    currentTicketSupport.markAllAsTouched();

    this.form.next(currentTicketSupport);
  }
}
