import { PipelineComponent } from './../pipeline/pipeline.component';
import {
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import {
  TicketRequest,
  TicketSupport,
  Response,
  Status,
  Company,
  User,
  TicketTypeV2,
} from '@fullyops/legacy/data';
import { BehaviorSubject } from 'rxjs';
import {
  ActionButtons,
  PermissionType,
  CustomFilter,
} from '@fullyops/legacy/ui/ui-shared/utils/crm-types';
import { ComponentInOutAnimation } from '@fullyops/legacy/ui/ui-shared/utils/component-base-animation';
import { ListTicketsPage } from '../tickets/tickets.component';
import { CdkDragDrop } from '@angular/cdk/drag-drop';

type Ticket = TicketSupport | TicketRequest;

@Component({
  selector: 'crm-board',
  templateUrl: './board.component.html',
  styleUrls: ['./board.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [ComponentInOutAnimation.AnimTrigger],
})
export class BoardComponent implements OnInit {
  @Input() title: string;
  @Input() customFilterFields: CustomFilter[] = [];
  @Input() filters$: BehaviorSubject<{}>;
  @Input() companies$: BehaviorSubject<Company[]>;
  @Input() users$: BehaviorSubject<User[]>;
  @Input() pipeline$: BehaviorSubject<any>;
  @Input() tickets$: BehaviorSubject<Response<Ticket[]>>;
  @Input() statuses$: BehaviorSubject<Status[]>;
  @Input() ticketType: TicketTypeV2;
  @Input() listTicketsPage$ = new BehaviorSubject<ListTicketsPage>(null);
  @Input() usersOptions$: BehaviorSubject<{ id: string; name: string }[]>;

  @Output() addNewEvent = new EventEmitter();
  @Output() openEvent = new EventEmitter<string>();
  @Output() filtersChangeEvent = new EventEmitter<{}>();
  @Output() pageChangeEvent = new EventEmitter<number>();
  @Output() dropTicket = new EventEmitter<DropTicketProps>();
  @Output() loadMoreTickets = new EventEmitter<string>();
  @ViewChild(PipelineComponent) public pipelineComponent!: PipelineComponent;

  actions: ActionButtons = {};
  tabs = ['Pipeline', 'Tickets'];

  ngOnInit() {
    this.initActionButtons();
  }

  getTickets(value) {
    const tickets = value.reduce((acc, item) => {
      acc.push(...item.tickets);
      return acc;
    }, []);

    return tickets;
  }

  initActionButtons() {
    // todo: change it later
    if (['workOrder'].includes(this.title)) {
      this.actions.primary = {
        label: 'create',
        color: 'primary',
        permissions: [PermissionType.CAN_CREATE_SUPPORT_TICKETS],
        dataTestId: 'create-workOrder',
      };
    } else if (this.title === 'installation') {
      this.actions.primary = {
        label: 'newInstallation',
        color: 'primary',
        permissions: [PermissionType.CAN_EDIT_INSTALLATION_TICKETS],
      };
    } else if (this.title === 'sale') {
      this.actions.primary = {
        label: 'create',
        color: 'primary',
        permissions: [PermissionType.CAN_CREATE_SALE_TICKETS],
      };
    } else if (this.title == 'request') {
      this.actions.primary = {
        label: 'create',
        color: 'primary',
        permissions: [
          PermissionType.CAN_EDIT_REQUEST_TICKETS,
          PermissionType.CAN_EDIT_OWN_REQUEST_TICKETS,
        ],
      };
    }
  }

  /**
   * Event Handlers
   */

  onActionEvent(event: string) {
    if (event === this.actions.primary.label) {
      this.onAddNew();
    }
  }

  onOpen(id) {
    this.openEvent.emit(id);
  }

  onAddNew() {
    this.addNewEvent.emit();
  }

  onFiltersChange(filters) {
    this.filtersChangeEvent.emit(filters);
  }

  onDropTicket(props: DropTicketProps) {
    this.dropTicket.emit(props);
  }

  onloadMoreTickets(statusId: string) {
    this.loadMoreTickets.emit(statusId);
  }

  handlePageSelection(pageIndex: number) {
    this.pageChangeEvent.emit(pageIndex);
  }
}

export type DropTicketProps = {
  cdkDragDrop: CdkDragDrop<string[]>;
  newStatusId: string;
  previousStatus: string;
};
