<h2 mat-dialog-title>{{ data.title | i18nextCap }}</h2>
<mat-dialog-content>
  <ng-container
    *ngIf="data.noTranslation; then message; else translatedMessage"
  ></ng-container>

  <ng-template #message
    ><span>{{ data.message }}</span></ng-template
  >
  <ng-template #translatedMessage
    ><span>
      {{ data.message | i18nextCap: data.translationData }}
    </span></ng-template
  >

  <ng-container
    *ngIf="data.redirectMessage && data.redirectHref && data.redirectHrefText"
  >
    <p class="redirect-text">
      <span>
        {{ data.redirectMessage | i18nextCap }}
      </span>
      <a
        class="btn"
        [routerLink]="[data.redirectHref]"
        (click)="dialogRef.close()"
      >
        {{ data.redirectHrefText | i18nextCap }}
      </a>
    </p>
  </ng-container>

  <!-- In case of token creation -->
  <div class="center" *ngIf="data.tokenId">
    <!-- <p>{{ data.tokenId }}</p> -->
    <mat-form-field>
      <input
        matInput
        [placeholder]="'tokenValue' | i18nextCap"
        [type]="hidePassword ? 'password' : 'text'"
        required
        [value]="data.tokenId"
      />
      <mat-icon matSuffix (click)="hidePassword = !hidePassword">{{
        hidePassword ? 'visibility_off' : 'visibility'
      }}</mat-icon>
      <mat-hint *ngIf="hidePassword">{{ 'seeTokenId' | i18nextCap }}</mat-hint>
    </mat-form-field>
    <button mat-icon-button [matTooltip]="'copyToken' | i18nextCap">
      <div class="icon grey-icon" [cdkCopyToClipboard]="data.tokenId">
        <fa-icon [icon]="$any(faClipboard)" size="lg"></fa-icon>
      </div>
    </button>
  </div>
</mat-dialog-content>
<div mat-dialog-actions align="end">
  <button
    mat-button
    [color]="data?.closeButtonClass ? 'basic' : 'primary'"
    [ngClass]="data?.closeButtonClass"
    mat-dialog-close
    type="button"
    *ngIf="data.closeModalText"
  >
    {{ data.closeModalText | i18nextCap }}
  </button>
  <button
    mat-flat-button
    [color]="data?.actionButtonClass ? 'basic' : 'warn'"
    [ngClass]="data?.actionButtonClass"
    [mat-dialog-close]="true"
    cdkFocusInitial
    [attr.data-testid]="'modal-action-button-test'"
    type="button"
    *ngIf="data.actionModalText"
  >
    {{ data.actionModalText | i18nextCap }}
  </button>
</div>
