<mat-progress-bar
  *ngIf="loading$ | async"
  mode="indeterminate"
></mat-progress-bar>

<form class="download" [formGroup]="downloadForm" (ngSubmit)="onSubmit()">
  <a target="_blank" href="https://fullyops.com/">
    <img
      class="client-logo"
      src="assets/svg/fullyops-logo-white.svg"
      alt="FullyOps Logo"
    />
  </a>
  <br />
  <br />
  <p>
    <label *ngIf="tokenizedAsset$ | async; else invalidTokenBlock"
      >{{ 'translation:downloadValidTokenMessage' | i18nextCap
      }}{{ tenantName }}.
    </label>
    <ng-template #invalidTokenBlock>
      <label
        >{{ 'translation:downloadInvalidTokenMessage' | i18nextCap }}
      </label>
    </ng-template>
  </p>
  <div *ngIf="tokenizedAsset$ | async" class="download-button-wrapper">
    <button class="btn-flip">
      {{ 'translation:downloadSubmitBtnLabel' | i18nextCap }}
    </button>
  </div>
  <br />
  <p>
    <a target="_blank" href="https://fullyops.com">{{
      'translation:downloadKnowMore' | i18next
    }}</a>
  </p>
</form>
