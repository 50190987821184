import { Response } from '../Response';
import { Endpoint } from '../Endpoint';
import { HttpClient } from '@angular/common/http';
import { IListResponse } from '../IListResponse';
import { Injectable, Inject } from '@angular/core';
import { API_SERVICE_CONFIG } from '../ApiServiceConfig';

import { Observable, BehaviorSubject } from 'rxjs';

import { Company } from '../../models/v0/Company';
import { ISerialisedCompany } from '../../models/v0/serialisations/ISerialisedCompany';
import { IApiServiceConfig } from '../IApiServiceConfig';
import { UnsavedCompany } from '../../models/v0/UnsavedCompany';
import { map, tap } from 'rxjs/operators';

export type CompaniesFilter = {
  addressId?: string;
  assigneeIds?: string[];
  createdBy?: string;
  disjunction?: boolean;
  email?: string;
  externalId?: string;
  includeDeleted?: boolean;
  mobileNumber?: string;
  name?: string;
  nif?: string;
  notes?: string;
  order?: 'ASC' | 'DESC';
  orderBy?:
    | 'CREATED_AT'
    | 'UPDATED_AT'
    | 'UUID'
    | 'NAME'
    | 'EMAIL'
    | 'EXTERNAL_ID'
    | 'MOBILE_NUMBER'
    | 'NIF'
    | 'ZIP_CODE'
    | 'WEBSITE'
    | 'NOTES'
    | 'TYPE'
    | 'DISTANCE';
  page: number;
  size?: number;
  type?: string[];
  updatedBy?: string;
  website?: string;
  zipCode?: string;
};

type CompanyList = IListResponse<ISerialisedCompany>;

@Injectable()
export class CompaniesEndpoint extends Endpoint<Company, ISerialisedCompany> {
  private readonly url = this.config.url + 'companies/';

  constructor(
    @Inject(API_SERVICE_CONFIG) private config: IApiServiceConfig,
    private http: HttpClient
  ) {
    super(Company.fromSerialised);
  }

  getAll(
    filters: CompaniesFilter,
    source?: Observable<CompanyList>,
    store?: BehaviorSubject<Response<Company[]>>
  ): Observable<Response<Company[]>> {
    if (!filters.orderBy) filters.orderBy = 'NAME';
    let query = '';
    Object.keys(filters).forEach((key) => (query += `&${key}=${filters[key]}`));

    const defaultSource = this.http.get<CompanyList>(`${this.url}?${query}`);
    return this.list(source || defaultSource, store);
  }

  get(
    id: string,
    source?: Observable<ISerialisedCompany>,
    store?: BehaviorSubject<Response<Company[]>>
  ): Observable<Response<Company>> {
    const defaultSource = this.http.get<ISerialisedCompany>(
      `${this.url}${id}/`
    );
    return this.retrieve(source || defaultSource, store);
  }

  post(
    company: UnsavedCompany,
    source?: Observable<ISerialisedCompany>,
    store?: BehaviorSubject<Response<Company[]>>
  ): Observable<Response<Company>> {
    const defaultSource = this.http.post<ISerialisedCompany>(
      this.url,
      company.toSerialised()
    );
    return this.add(source || defaultSource, store);
  }

  patch(
    id: string,
    company: UnsavedCompany,
    source?: Observable<ISerialisedCompany>,
    store?: BehaviorSubject<Response<Company[]>>
  ) {
    const { ...body } = company.toSerialised();
    const defaultSource = this.http.patch<ISerialisedCompany>(
      `${this.url}${id}/`,
      body
    );

    return defaultSource.pipe(
      tap((instance) =>
        this.update(
          source || defaultSource,
          Company.fromSerialised(instance),
          store
        )
      ),
      map((serialiser) => new Response(Company.fromSerialised(serialiser)))
    );
  }

  delete(
    id: string,
    source?: Observable<{}>,
    store?: BehaviorSubject<Response<Company[]>>
  ): Observable<Response<{}>> {
    const defaultSource = this.http.delete<{}>(`${this.url}${id}/`);
    return this.remove(source || defaultSource, id, store);
  }
}
