import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActionButtons, ActionButton } from '../utils/crm-types';
import { MatLegacyTabGroup as MatTabGroup } from '@angular/material/legacy-tabs';
import { HideIfNotAllowedDirective } from '@fullyops/shared/directives/hide-if-not-allowed.directive';
import { ContainerSizesType } from '../container/container.component';
import { DialogService } from '../dialog/dialog.service';

@Component({
  selector: 'crm-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  providers: [HideIfNotAllowedDirective],
})
export class BreadcrumbComponent implements OnInit {
  @Input() header: string;
  @Input() tabs: MatTabGroup;
  @Input() specialActionButtons?: ActionButton[];
  @Input() actionButtons: ActionButtons;
  @Output() actionEvent = new EventEmitter();
  @Output() tabEvent = new EventEmitter();
  @Input() maxSize: ContainerSizesType;

  constructor(private dialogService: DialogService) {}

  ngOnInit() {}

  fireAction(action) {
    if (action === 'delete') {
      const dialogRef = this.dialogService.openDialogBeforeDelete();

      dialogRef.afterClosed().subscribe((saveOutput) => {
        if (saveOutput) {
          this.actionEvent.emit(action);
        }
      });
    } else if (action === 'back') {
      history.back();
    } else {
      this.actionEvent.emit(action);
    }
  }

  changeTab(index: number) {
    this.tabs.selectedIndex = index;
  }
}
