import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_SERVICE_CONFIG } from '../ApiServiceConfig';
import { IApiServiceConfig } from '../IApiServiceConfig';
import { BehaviorSubject, Observable } from 'rxjs';
import { IListResponse } from '../IListResponse';
import { ISerialisedReport } from '../../models/v0/serialisations/ISerialisedReport';
import { Report } from '../../models/v0/Report';
import { Endpoint } from '../Endpoint';
import { Response } from '../Response';
import { UnsavedReportRequest } from '../../models/v0/UnsavedReportRequest';

type ReportList = IListResponse<ISerialisedReport>;

@Injectable()
export class ReportEndpoint extends Endpoint<Report, ISerialisedReport> {
  private readonly url = this.config.url + 'reports';

  constructor(
    @Inject(API_SERVICE_CONFIG) private config: IApiServiceConfig,
    private http: HttpClient
  ) {
    super(Report.fromSerialised);
  }

  /*getAll(ticketId: string, requestType: string): Observable<string> {
    return this.http.get(`${this.url}/${requestType}/${ticketId}`, { responseType: 'text'});
  }*/

  getAll(
    ticketId: string,
    requestType: string,
    source?: Observable<ReportList>,
    store?: BehaviorSubject<Response<Report[]>>
  ): Observable<Response<Report[]>> {
    const defaultSource = this.http.get<ReportList>(
      `${this.url}/sign/${requestType}/${ticketId}`
    );

    return this.list(source || defaultSource, store);
  }

  createReport(
    ticketId: string,
    request: UnsavedReportRequest,
    source?: Observable<ISerialisedReport>,
    store?: BehaviorSubject<Response<Report[]>>
  ): Observable<Response<Report>> {
    const defaultSource = this.http.post<ISerialisedReport>(
      `${this.url}/sign/${request.type}/${ticketId}`,
      request.toSerialised()
    );

    return this.add(source || defaultSource, store);
  }

  createPropose(
    ticketId: string,
    lang: string,
    request: UnsavedReportRequest,
    source?: Observable<ISerialisedReport>,
    store?: BehaviorSubject<Response<Report[]>>
  ): Observable<Response<Report>> {
    const defaultSource = this.http.post<ISerialisedReport>(
      `${this.url}/preview/${request.type}/${ticketId}?language=${lang}`,
      request.toSerialised()
    );

    return this.add(source || defaultSource, store);
  }

  sendEmail(
    reportId: string,
    source?: Observable<ISerialisedReport>,
    store?: BehaviorSubject<Response<Report[]>>
  ): Observable<{}> {
    return this.http.post<{}>(`${this.url}/email/${reportId}`, {});
  }
}
