<mat-form-field [ngClass]="['d-block', color]">
  <mat-label>{{ 'forms:' + field.label | i18nextCap }}</mat-label>

  <mat-select
    [formControl]="formControl"
    (selectionChange)="onSelectChange($event)"
    [multiple]="multiple"
    (closed)="onClose($event)"
    [attr.data-testid]="this.field.dataTestId"
    (ngModelChange)="modelChange($event)"
    [matTooltip]="field.matTooltip | i18nextCap"
    matTooltipPosition="below"
    disableOptionCentering
  >
    <mat-select-trigger *ngIf="selected">
      <mat-icon class="icon" *ngIf="selected.icon">{{
        selected.icon
      }}</mat-icon>
      <span class="selected">
        <ng-container *ngIf="field.translate">
          {{ 'forms:' + selected.name | i18nextCap }}
        </ng-container>
        <ng-container *ngIf="!field.translate">
          {{ selected.name }}
        </ng-container>
      </span>
    </mat-select-trigger>

    <mat-option
      *ngIf="field.nullable"
      value=""
      #unassign
      (click)="toggleUnassigned()"
    >
      {{ 'forms:unassign' | i18nextCap }}
    </mat-option>
    <ng-container *ngIf="field.items$ | async as items">
      <ng-container *ngFor="let item of items">
        <mat-option
          *ngIf="
            field.select?.onlyActiveOption && item.active == false
              ? false
              : true
          "
          [value]="item.id"
          (click)="tosslePerOne()"
        >
          <mat-icon *ngIf="item.icon">{{ item.icon }}</mat-icon>
          <ng-container *ngIf="field.translate">
            {{
              'forms:' +
                (field.displayValue ? field.displayValue(item) : item.name)
                | i18nextCap
            }}
          </ng-container>
          <ng-container *ngIf="!field.translate">
            {{ field.displayValue ? field.displayValue(item) : item.name }}
          </ng-container>
        </mat-option>
      </ng-container>
    </ng-container>
  </mat-select>
  <mat-error>{{ 'validation:youMustSelectAnOption' | i18nextCap }}</mat-error>
</mat-form-field>
