import { ISerialisedTicketRequest } from './serialisations/ISerialisedTicketRequest';
import { Contact } from './Contact';
import { Company } from './Company';
import { Part } from './Part';
import { User } from './User';
import { UnsavedTicketRequest } from './UnsavedTicketRequest';
import { Status } from './Status';
import { TicketRequestPart } from './TicketRequestPart';

export class TicketRequest {
  constructor(
    readonly id: string,
    readonly identifier: number,
    readonly name: string,
    readonly priority: string,
    readonly status: Status,
    readonly assignees: User[],
    readonly company: Company,
    readonly contacts: Contact[],
    readonly extraInfo: string,
    readonly machine: string,
    readonly parts: TicketRequestPart[],
    readonly createdAt: Date,
    readonly closedAt: Date,
    readonly supportTicketId: string,
    readonly supplier: any,
    readonly supportTicketIdentifier: string,
    readonly supportTicketName: string,
    readonly unregisteredParts: string,
    readonly createdBy?: User
  ) {
    if (name) this.name = name.trim();
    if (priority) this.priority = priority.trim();
    if (extraInfo) this.extraInfo = extraInfo.trim();
    if (machine) this.machine = machine.trim();
    // if (supplier) this.supplier = supplier.trim();
  }

  static fromSerialised(serialised: ISerialisedTicketRequest) {
    const status = Status.fromSerialised(serialised.status);
    const company = Company.fromSerialised(serialised.company);

    const assignees = serialised?.assignees?.map(User.fromSerialised);
    const contacts = serialised?.contacts?.map(Contact.fromSerialised);
    const parts = serialised?.parts?.map(TicketRequestPart.fromSerialised);

    return new TicketRequest(
      serialised.id,
      serialised.identifier,
      serialised.name,
      serialised.priority,
      status,
      assignees,
      company,
      contacts,
      serialised.extraInfo,
      serialised.machine,
      parts,
      new Date(serialised.createdAt),
      new Date(serialised.closedAt),
      serialised.supportTicketId,
      serialised.supplier,
      serialised.supportTicketIdentifier,
      serialised.supportTicketName,
      serialised.unregisteredParts
    );
  }

  toSerialised(): ISerialisedTicketRequest {
    const statusObj = this.status.toSerialised();
    const companyObj = this.company.toSerialised();

    const assigneesObj = this.assignees.map((assignee) =>
      assignee.toSerialised()
    );
    const contactsObj = this.contacts.map((contact) => contact.toSerialised());
    const partsObj = this.parts.map((part) => part.toSerialised());

    return {
      id: this.id,
      identifier: this.identifier,
      name: this.name,
      priority: this.priority,
      status: statusObj,
      assignees: assigneesObj,
      company: companyObj,
      contacts: contactsObj,
      extraInfo: this.extraInfo,
      machine: this.machine,
      parts: partsObj,
      createdAt: this.createdAt.toISOString(),
      closedAt: this.closedAt ? this.closedAt.toISOString() : null,
      supportTicketId: this.supportTicketId,
      supplier: this.supplier,
      unregisteredParts: null,
      supportTicketIdentifier: this.supportTicketIdentifier,
      supportTicketName: this.supportTicketName,
      createdBy: this.createdBy,
    };
  }

  toDraft(): UnsavedTicketRequest {
    const ticketRequest = this.toSerialised();
    return UnsavedTicketRequest.fromSerialised({
      ...ticketRequest,
      statusId: ticketRequest.status.id,
      companyId: ticketRequest.company.id,
      contactIds: ticketRequest.contacts.map((contact) => contact.id),
      assigneeIds: ticketRequest.assignees.map((assignee) => assignee.id),
      parts: this.parts.map((part) => part.toDraft()),
    });
  }

  clone(partialTicketRequest: PartialTicketRequest): TicketRequest {
    const resolve = (key: keyof TicketRequest) =>
      partialTicketRequest.hasOwnProperty(key)
        ? partialTicketRequest[key]
        : this[key];

    return new TicketRequest(
      resolve('id'),
      resolve('identifier'),
      resolve('name'),
      resolve('priority'),
      resolve('status'),
      resolve('assignees'),
      resolve('company'),
      resolve('contacts'),
      resolve('extraInfo'),
      resolve('machine'),
      resolve('parts'),
      resolve('createdAt'),
      resolve('closedAt'),
      resolve('supportTicketId'),
      resolve('supplier'),
      resolve('supportTicketIdentifier'),
      resolve('supportTicketName'),
      resolve('unregisteredParts')
    );
  }
}

type PartialTicketRequest = Partial<
  Pick<
    TicketRequest,
    | 'id'
    | 'identifier'
    | 'name'
    | 'priority'
    | 'status'
    | 'assignees'
    | 'company'
    | 'contacts'
    | 'extraInfo'
    | 'supportTicketId'
    | 'machine'
    | 'parts'
    | 'createdAt'
    | 'closedAt'
    | 'supplier'
    | 'supportTicketIdentifier'
    | 'supportTicketName'
  >
>;
