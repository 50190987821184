<crm-breadcrumb
  [header]="header"
  [actionButtons]="actions"
  (actionEvent)="onActionEvent($event)"
></crm-breadcrumb>
<crm-container [maxSize]="'xl'">
  <ng-container *ngIf="ticketSaleFormService.form$ | async as ticketSaleForm">
    <form
      #ngForm="ngForm"
      class="main-form"
      [@inOut]="'in'"
      [formGroup]="ticketSaleForm"
      (ngSubmit)="onSubmit()"
    >
      <div class="row mx-0">
        <!-- Left -->
        <div class="left-col col-md-8">
          <mat-tab-group (selectedTabChange)="onTabClick($event)">
            <mat-tab>
              <ng-template mat-tab-label>{{
                'forms:sale' | i18nextCap
              }}</ng-template>
              <div class="remove-negative-margin">
                <crm-form-template
                  [formGroup]="ticketSaleForm"
                  [formFields]="infoTabFormFields"
                ></crm-form-template>
                <button
                  type="submit"
                  mat-flat-button
                  color="primary"
                  [disabled]="preview"
                >
                  {{ 'forms:save' | i18nextCap }}
                </button>
              </div>
            </mat-tab>

            <!-- Simulations -->
            <mat-tab *ngIf="entity">
              <ng-template mat-tab-label>{{
                'forms:simulations' | i18nextCap
              }}</ng-template>
              <div class="d-flex flex-column column-gap remove-negative-margin">
                <crm-simulations-list
                  [productsConfigsList$]="$any(productConfigs$)"
                  [simulations$]="ticketSimulations$"
                  [productsConfigs$]="ticketProductConfigs$"
                  (addProductConfigEvent)="onAddProductConfig($event)"
                  (openProductConfigEvent)="onOpenProductConfig($event)"
                  (removeProductConfigEvent)="onRemoveProductConfig($event)"
                  (createSimulationEvent)="onCreateSimulation()"
                  (openSimulationEvent)="onOpenSimulation($event)"
                  (removeSimulationEvent)="onRemoveSimulation($event)"
                ></crm-simulations-list>

                <crm-simulations-info
                  [simulations$]="ticketSimulations$"
                  [productsConfigs$]="ticketProductConfigs$"
                ></crm-simulations-info>
              </div>
            </mat-tab>
            <ng-container *hasPermission="{ permissions: reportPermissions }">
              <mat-tab *ngIf="entity">
                <ng-template mat-tab-label>{{
                  'forms:report' | i18nextCap
                }}</ng-template>
                <div class="tab-content">
                  <crm-report-list
                    [ticketId]="entity.id"
                    [reportType]="reportType"
                    [withProposal]="true"
                    [reports$]="reports$"
                    [ticket$]="$any(ticket$)"
                  ></crm-report-list>
                </div>
              </mat-tab>
            </ng-container>
          </mat-tab-group>
        </div>

        <!-- Right -->
        <div class="right-col col-md-4">
          <div class="d-flex flex-column column-gap">
            <crm-form-template
              [formGroup]="ticketSaleForm"
              [formFields]="trackingFields"
              (selectionChange)="patchIfTrackingFieldsChange()"
            ></crm-form-template>
            <div>
              <crm-history
                *ngIf="history$"
                [history$]="history$"
                (openActivityEvent)="onOpenActivity($event)"
                (openTicketEvent)="onOpenTicket($any($event))"
              ></crm-history>
            </div>
          </div>
        </div>
      </div>
    </form>
  </ng-container>
</crm-container>
