import { CookieService } from 'ngx-cookie-service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocaleService {
  constructor(public cookieService: CookieService) {}

  public getCurrentLanguage(): string {
    return (
      this.cookieService.get(LocaleService.LANGUAGE_COOKIE_KEY) ||
      LocaleService.DEFAULT_LANGUAGE
    );
  }

  public setCurrentLanguage(newLanguage: string): void {
    this.cookieService.set(LocaleService.LANGUAGE_COOKIE_KEY, newLanguage);
  }

  private static DEFAULT_LANGUAGE = 'pt'; // TODO: Should be set by config
  private static LANGUAGE_COOKIE_KEY = 'lang';
}
