import { ISerialisedTicketSale } from './serialisations/ISerialisedTicketSale';
import { Contact } from './Contact';
import { Company } from './Company';
import { User } from './User';
import { Status } from './Status';
import { UnsavedTicketSale } from './UnsavedTicketSale';
import { ProductConfig } from './ProductConfig';
import { ProductConfigSimulation } from './ProductConfigSimulation';
import { SaleTicketInfo } from './SaleTicketInfo';
import { LossMotive } from './LossMotive';
import { ClientInfoRequest } from '../../api/types/Client';

export class TicketSale {
  constructor(
    readonly id: string,
    readonly identifier: number,
    readonly name: string,
    readonly priority: string,
    readonly status: Status,
    readonly assignees: User[],
    readonly company: Company,
    readonly contacts: Contact[],
    readonly notes: string,
    readonly createdAt: Date,
    readonly saleDate: Date | null,
    readonly saleReturn: number,
    readonly won: boolean | null,
    readonly lossMotive: LossMotive | null,
    readonly productConfigurations: ProductConfig[],
    readonly simulations: ProductConfigSimulation[],
    readonly saleTicketInfo: SaleTicketInfo,
    readonly clientInfo: ClientInfoRequest,
    readonly createdBy: User
  ) {
    if (name) this.name = name.trim();
    if (priority) this.priority = priority.trim();
    if (notes) this.notes = notes.trim();
  }

  static fromSerialised(serialised: ISerialisedTicketSale) {
    const status = Status.fromSerialised(serialised.status);
    const company = Company.fromSerialised(serialised.company);
    const saleTicketInfo = SaleTicketInfo.fromSerialised(
      serialised.saleTicketInfo
    );

    const assignees = serialised.assignees.map(User.fromSerialised);
    const contacts = serialised.contacts.map(Contact.fromSerialised);

    const productConfigs = serialised.productConfigurations.map(
      ProductConfig.fromSerialised
    );
    const simulations = serialised.simulations.map(
      ProductConfigSimulation.fromSerialised
    );

    return new TicketSale(
      serialised.id,
      serialised.identifier,
      serialised.name,
      serialised.priority,
      status,
      assignees,
      company,
      contacts,
      serialised.notes,
      new Date(serialised.createdAt),
      serialised.saleDate ? new Date(serialised.saleDate) : null,
      serialised.saleReturn,
      serialised.won,
      serialised.lossMotive != null
        ? LossMotive.fromSerialised(serialised.lossMotive)
        : null,
      productConfigs,
      simulations,
      saleTicketInfo,
      serialised.clientInfo,
      User.fromSerialised(serialised.createdBy)
    );
  }

  toSerialised(): ISerialisedTicketSale {
    const statusObj = this.status.toSerialised();
    const companyObj = this.company.toSerialised();
    const saleTicketInfoObj = this.saleTicketInfo.toSerialised();

    const assigneesObj = this.assignees.map((assignee) =>
      assignee.toSerialised()
    );
    const contactsObj = this.contacts.map((contact) => contact.toSerialised());

    const productConfigObj = this.productConfigurations.map((config) =>
      config.toSerialised()
    );
    const simulationsObj = this.simulations.map((simulation) =>
      simulation.toSerialised()
    );

    return {
      id: this.id,
      identifier: this.identifier,
      name: this.name,
      priority: this.priority,
      status: statusObj,
      assignees: assigneesObj,
      company: companyObj,
      contacts: contactsObj,
      notes: this.notes,
      createdAt: this.createdAt.toISOString(),
      saleDate: this.saleDate ? this.saleDate.toISOString() : null,
      saleReturn: this.saleReturn,
      won: this.won,
      lossMotive: this.lossMotive?.toSerialised(),
      productConfigurations: productConfigObj,
      simulations: simulationsObj,
      saleTicketInfo: saleTicketInfoObj,
      clientInfo: this.clientInfo,
      createdBy: this.createdBy,
    };
  }

  toDraft(): UnsavedTicketSale {
    const ticketSale = this.toSerialised();
    return UnsavedTicketSale.fromSerialised({
      ...ticketSale,
      // If the loss motive is unset, make it appear as unset in the dropdown
      // (which maps the nil UUID to that option, matching the backend).
      lossMotiveId:
        ticketSale.lossMotive != null
          ? ticketSale.lossMotive.id
          : '00000000-0000-0000-0000-000000000000',
      statusId: ticketSale.status.id,
      companyId: ticketSale.company.id,
      contactIds: ticketSale.contacts.map((contact) => contact.id),
      assigneeIds: ticketSale.assignees.map((assignee) => assignee.id),
      productConfigurationIds: ticketSale.productConfigurations.map(
        (config) => config.id
      ),
      simulationsIds: ticketSale.simulations.map((simulation) => simulation.id),
      clientInfo: ticketSale.clientInfo || {
        address: '',
        city: '',
        zipCode: '',
        countryISOCode: '',
        distance: '',
      },
    });
  }

  clone(partialTicketSale: PartialTicketSale): TicketSale {
    const resolve = (key: keyof TicketSale) =>
      partialTicketSale.hasOwnProperty(key)
        ? partialTicketSale[key]
        : this[key];

    return new TicketSale(
      resolve('id'),
      resolve('identifier'),
      resolve('name'),
      resolve('priority'),
      resolve('status'),
      resolve('assignees'),
      resolve('company'),
      resolve('contacts'),
      resolve('notes'),
      resolve('createdAt'),
      resolve('saleDate'),
      resolve('saleReturn'),
      resolve('won'),
      resolve('lossMotive'),
      resolve('productConfigurations'),
      resolve('simulations'),
      resolve('saleTicketInfo'),
      resolve('clientInfo'),
      resolve('createdBy')
    );
  }
}

type PartialTicketSale = Partial<
  Pick<
    TicketSale,
    | 'id'
    | 'identifier'
    | 'name'
    | 'priority'
    | 'status'
    | 'assignees'
    | 'company'
    | 'contacts'
    | 'notes'
    | 'saleTicketInfo'
    | 'saleDate'
    | 'createdAt'
    | 'saleReturn'
    | 'won'
    | 'lossMotive'
    | 'productConfigurations'
    | 'simulations'
    | 'clientInfo'
    | 'createdBy'
  >
>;
