import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { ComponentInOutAnimation } from '@fullyops/legacy/ui/ui-shared/utils/component-base-animation';

@Component({
  selector: 'crm-product-form-details-subsection-item',
  templateUrl: './product-form-details-subsection-item.component.html',
  styleUrls: ['./product-form-details-subsection-item.component.scss'],
  animations: [ComponentInOutAnimation.AnimTrigger],
})
export class ProductFormDetailsSubsectionItemComponent {
  @Input() preview = false;
  @Input() subsectionItemForm: UntypedFormGroup;
  @Input() uploadService: (
    file: File,
    extension: string
  ) => BehaviorSubject<{ url: string }>;

  removeLine(index: number) {
    this.data.controls.splice(index, 1);
  }

  get data(): UntypedFormArray {
    return this.subsectionItemForm.get('data') as UntypedFormArray;
  }

  get urlControl(): UntypedFormControl {
    return this.subsectionItemForm.get('url') as UntypedFormControl;
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.data.controls,
      event.previousIndex,
      event.currentIndex
    );
    moveItemInArray(this.data.value, event.previousIndex, event.currentIndex);
  }

  createEntry() {
    this.data.push(new UntypedFormControl('', Validators.required));
  }
}
