import { ISerialisedTravel } from '../../models/v0/serialisations/ISerialisedTravel';
import { Endpoint } from '../Endpoint';
import { Inject, Injectable } from '@angular/core';
import { API_SERVICE_CONFIG } from '../ApiServiceConfig';
import { IApiServiceConfig } from '../IApiServiceConfig';
import { HttpClient } from '@angular/common/http';
import { Travel } from '../../models/v0/Travel';

@Injectable()
export class TravelAssigneeEndpoint extends Endpoint<
  Travel,
  ISerialisedTravel
> {
  constructor(
    @Inject(API_SERVICE_CONFIG) private config: IApiServiceConfig,
    private http: HttpClient
  ) {
    super(Travel.fromSerialised);
  }

  private readonly url = this.config.url + 'travels/assignees';

  assignUserEndpoint(body: { assigneeId: string; travelId: string }) {
    return this.http.post<ISerialisedTravel>(`${this.url}`, body);
  }

  unAssignUserEndpoint(assigneeId: string, travelId: string) {
    return this.http.delete<ISerialisedTravel>(
      `${this.url}/${travelId}/${assigneeId}`
    );
  }
}
