import { Component, Input } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { I18NextPipe } from 'angular-i18next';
import { BehaviorSubject } from 'rxjs';
import { ComponentInOutAnimation } from '@fullyops/legacy/ui/ui-shared/utils/component-base-animation';

@Component({
  selector: 'crm-product-form-details',
  templateUrl: './product-form-details.component.html',
  styleUrls: ['./product-form-details.component.scss'],
  animations: [ComponentInOutAnimation.AnimTrigger],
})
export class ProductFormDetailsComponent {
  constructor(private i18nextPipe: I18NextPipe) {}
  @Input() preview = false;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() createEntryLabel: string;
  @Input() sectionsForm: UntypedFormArray;
  @Input() uploadService: (
    file: File,
    extension: string
  ) => BehaviorSubject<{ url: string }>;

  selected = new UntypedFormControl(0);

  addSection(selectAfterAdding: boolean) {
    const newSection = new UntypedFormGroup({});

    newSection.addControl(
      'section',
      new UntypedFormControl(this.i18nextPipe.transform('forms:new'), [])
    );
    newSection.addControl('content', new UntypedFormArray([]));

    this.sectionsForm.controls.push(newSection);

    if (selectAfterAdding) {
      this.selected.setValue(this.sectionsForm.controls.length - 1);
    }
  }

  removeSection(index: number) {
    this.sectionsForm.controls.splice(index, 1);
  }

  removeSubsection(sectionIndex: number, subsectionIndex: number) {
    (
      this.sectionsForm.controls[sectionIndex].get(
        'content'
      ) as UntypedFormArray
    ).controls.splice(subsectionIndex, 1);
  }

  addSubsection(index: number) {
    const newSubsection = new UntypedFormGroup({});

    newSubsection.addControl(
      'title',
      new UntypedFormControl('', Validators.required)
    );
    newSubsection.addControl(
      'content',
      new UntypedFormArray([
        new UntypedFormGroup(
          {
            url: new UntypedFormControl(null, []),
            data: new UntypedFormArray([], Validators.required),
          },
          Validators.required
        ),
      ])
    );

    (this.sectionsForm.controls[index].get('content') as UntypedFormArray).push(
      newSubsection
    );
  }
}
