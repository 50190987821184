import { Injectable } from '@angular/core';
import * as moment from 'moment-timezone';
import { UiAuthService } from '@fullyops/legacy/ui/ui-crm/ui-auth.service';

/**
 * TODO: This service does more than formatting dates, should be renamed
 */
@Injectable({
  providedIn: 'root',
})
export class TimeZoneDateFormatterService {
  constructor(protected uiAuthService: UiAuthService) {
    moment.locale(this.uiAuthService.currentUser.locale); // TODO: This mutates the global language for moment. It will make testing harder in the future.
  }

  formatInTimezone(date: Date | string | number[]): moment.Moment {
    return moment(date).tz(this.uiAuthService.currentUser.timeZone);
  }

  currentDate() {
    return moment().tz(this.uiAuthService.currentUser.timeZone);
  }
}
