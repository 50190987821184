import { ISerialisedAccessToken } from '../../models/v0/serialisations/ISerialisedAccessToken';
import { AccessToken } from '../../models/AccessToken';
import { Injectable, Inject } from '@angular/core';
import { Endpoint } from '../Endpoint';
import { Response } from '../Response';
import { API_SERVICE_CONFIG } from '../ApiServiceConfig';
import { IApiServiceConfig } from '../IApiServiceConfig';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class MeAccessTokenEndpoint extends Endpoint<
  AccessToken,
  ISerialisedAccessToken
> {
  constructor(
    @Inject(API_SERVICE_CONFIG) private config: IApiServiceConfig,
    private http: HttpClient
  ) {
    super(AccessToken.fromSerialised);
  }

  private readonly url = this.config.url + '1.0/me/access-token/';

  getAll(
    source?: Observable<ISerialisedAccessToken>,
    store?: BehaviorSubject<Response<AccessToken[]>>
  ): Observable<Response<AccessToken[]>> {
    const defaultSource = this.http.get<ISerialisedAccessToken>(this.url);
    const useSource = (source || defaultSource).pipe(
      map((result) => ({ results: [result] }))
    );
    return this.list(useSource, store);
  }
}
