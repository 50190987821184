import { Response } from '../Response';
import { Endpoint } from '../Endpoint';
import { HttpClient } from '@angular/common/http';
import { IListResponse } from '../IListResponse';
import { Injectable, Inject } from '@angular/core';
import { API_SERVICE_CONFIG } from '../ApiServiceConfig';
import { IApiServiceConfig } from '../IApiServiceConfig';

import { Observable, BehaviorSubject } from 'rxjs';

import { ProductConfig } from '../../models/v0/ProductConfig';
import { ISerialisedProductConfig } from '../../models/v0/serialisations/ISerialisedProductConfig';

type ProductConfigList = IListResponse<ISerialisedProductConfig>;

@Injectable()
export class TicketsSaleProductsConfigEndpoint extends Endpoint<
  ProductConfig,
  ISerialisedProductConfig
> {
  private readonly url = this.config.url + 'tickets/sales';

  constructor(
    @Inject(API_SERVICE_CONFIG) private config: IApiServiceConfig,
    private http: HttpClient
  ) {
    super(ProductConfig.fromSerialised);
  }

  getAll(
    ticketId: string,
    filter?: {},
    source?: Observable<ProductConfigList>,
    store?: BehaviorSubject<Response<ProductConfig[]>>
  ): Observable<Response<ProductConfig[]>> {
    const defaultSource = this.http.get<ProductConfigList>(
      `${this.url}/${ticketId}/configurations`
    );

    return this.list(source || defaultSource, store);
  }

  post(
    ticketId: string,
    configurationId: string,
    source?: Observable<ISerialisedProductConfig>,
    store?: BehaviorSubject<Response<ProductConfig[]>>
  ): Observable<Response<ProductConfig>> {
    const defaultSource = this.http.post<ISerialisedProductConfig>(
      `${this.url}/${ticketId}/configurations/${configurationId}`,
      {}
    );

    return this.add(source || defaultSource, store);
  }

  delete(
    ticketId: string,
    configurationId: string,
    source?: Observable<{}>,
    store?: BehaviorSubject<Response<ProductConfig[]>>
  ): Observable<Response<{}>> {
    const defaultSource = this.http.delete<{}>(
      `${this.url}/${ticketId}/configurations/${configurationId}`
    );

    return this.remove(source || defaultSource, configurationId, store);
  }

  get() {}

  patch() {}
}
