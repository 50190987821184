import { TenantResponse } from '@fullyops/legacy/data/api/types/Tenant';
import { UiServiceV2 } from './ui.service-v2.service';
import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { API_SERVICE_CONFIG } from '@fullyops/legacy/data/api/ApiServiceConfig';
import { IApiServiceConfig } from '@fullyops/legacy/data/api/IApiServiceConfig';

@Injectable({
  providedIn: 'root',
})
export class TenantControllerV2 extends UiServiceV2 {
  constructor(
    @Inject(API_SERVICE_CONFIG) private config: IApiServiceConfig,
    private http: HttpClient
  ) {
    super();
  }

  private readonly url = this.config.url + 'tenants';

  getCurrentTenant() {
    return this.http.get<TenantResponse>(`${this.url}/current`);
  }
}
