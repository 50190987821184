import {
  WorkPeriodAssigneeResponse,
  WorkPeriodRequest,
} from './../../../data/api/types/Work';
import { I18NextPipe } from 'angular-i18next';
import {
  SupportTicketResponse,
  SupportTicketRequest,
} from './../../../data/api/types/SupportTicket';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { Component, OnInit, Input } from '@angular/core';
import { WorkPeriodControllerV2 } from '../ui-work-period-controller-v2.service';
import { WorkPeriodResponse } from '../../../data/api/types/Work';
import { HotToastService } from '@ngneat/hot-toast';
import { COLORS } from '../../ui-shared/utils/styles';
import {
  FormTemplateFields,
  TemplateFormFields,
} from '../../ui-shared/form-template/form-template.component';
import { map } from 'rxjs/operators';
import { WorkPeriodAssigneeControllerV2 } from '../ui-work-period-assignee.controller-v2.service';
import { faBusinessTime } from '@fortawesome/free-solid-svg-icons';
import { UiAuthService } from '../ui-auth.service';
import { RoleGroup } from '../../ui-shared/utils/crm-types';
import * as dayjs from 'dayjs';
import { UserResponse } from '../../../data/api/types/User';
import { TenantConfigurationController } from '../ui-tenant-configuration-v2-controller.service';
import { TimeZoneDateFormatterService } from '@fullyops/core/services/date-formatter.service';
import { Role, Tenant, User } from '@fullyops/legacy/data';

type UserId = string;

type UserTotalWorkedData = {
  firstName: string;
  lastName: string;
  totalWorkedTimeInMilliseconds: number;
};

type UsersWorkedHours = {
  [key: UserId]: UserTotalWorkedData;
};

@Component({
  selector: 'app-working-period',
  templateUrl: './working-period.component.html',
  styleUrls: ['./working-period.component.scss'],
})
export class WorkingPeriodComponent implements OnInit {
  constructor(
    protected workPeriodControllerV2: WorkPeriodControllerV2,
    private snackBarService: HotToastService,
    private i18nextPipe: I18NextPipe,
    private workPeriodAssigneeControllerV2: WorkPeriodAssigneeControllerV2,
    protected authService: UiAuthService,
    public timezoneDate: TimeZoneDateFormatterService,
    private tenantConfigurationController: TenantConfigurationController
  ) {}
  @Input() isDetailPage: boolean;
  @Input() usersAssignees$: BehaviorSubject<UserResponse[]>;
  @Input() ticket$: BehaviorSubject<SupportTicketResponse>;
  assignees$ = new BehaviorSubject<{ id: string; name: string }[]>([]);
  faBusinessTime = faBusinessTime;
  defaultWorkingPeriodDuration = 30;

  newDate = (props: { minutes: number }) => {
    const date = this.timezoneDate.currentDate();
    const next15Minutes = 15 * Math.ceil(date.minute() / 15);
    date.minutes(props.minutes ? next15Minutes + props.minutes : next15Minutes);
    date.seconds(0);
    return date.toDate();
  };

  newWorkPeriodForm = new UntypedFormGroup({
    assignees: new UntypedFormControl([], Validators.required),
    startingHour: new UntypedFormControl(
      this.newDate({ minutes: 0 }),
      Validators.required
    ),
    endingHour: new UntypedFormControl(
      this.newDate({ minutes: this.defaultWorkingPeriodDuration }),
      [Validators.required, this.validationEndDate.bind(this)]
    ),
  });
  formFields: TemplateFormFields<any>;

  isCreatingNewWorkPeriod = false;

  async ngOnInit() {
    this.initAssignees();
    await this.setDefaultWorkingPeriodDuration();
    this.initFormFields();
    this.setInicialUserSelected();
  }

  async setDefaultWorkingPeriodDuration() {
    await this.tenantConfigurationController
      .getConfigurationsByLabel({
        label: 'SUPPORT_TICKET_DEFAULT_WORKING_PERIOD_DURATION',
      })
      .toPromise()
      .then((e) => {
        this.defaultWorkingPeriodDuration = e.value
          ? +e.value
          : +e.defaultValue;
        this.newWorkPeriodForm.controls['endingHour'].setValue(
          this.newDate({ minutes: this.defaultWorkingPeriodDuration })
        );
      });
  }

  resetNewWorkPeriodForm() {
    this.newWorkPeriodForm.reset({
      assignees: [],
      startingHour: this.newDate({ minutes: 0 }),
      endingHour: this.newDate({ minutes: this.defaultWorkingPeriodDuration }),
    });

    this.setInicialUserSelected();
  }

  setInicialUserSelected() {
    const isUserTechnician = this.authService.hasRole(RoleGroup.TECHNICIAN);
    const userId = this.authService.currentUser$.value.id;

    if (
      isUserTechnician &&
      this.assignees$.value.some(({ id }) => id == userId)
    ) {
      this.newWorkPeriodForm.controls['assignees'].setValue([
        this.authService.currentUser$.value.id,
      ]);
    }
  }

  initAssignees() {
    this.usersAssignees$
      .pipe(
        map((users) =>
          users.map((user) => ({
            id: user.id,
            name: `${user.firstName} ${user.lastName}`,
          }))
        )
      )
      .subscribe((e) => {
        this.assignees$.next(e);
        this.setInicialUserSelected();
      });
  }

  isLessThanOneDay = (d: Date | null): boolean => {
    const startDate = d || this.timezoneDate.currentDate();
    const endDate = this.timezoneDate.formatInTimezone(
      this.newWorkPeriodForm.controls['startingHour'].value
    );
    const diffTime = startDate.valueOf() - endDate.valueOf();
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays <= 1 && diffDays >= 0;
  };

  validationEndDate(control: AbstractControl): ValidationErrors | null {
    if (!this.isLessThanOneDay) return;
    const endDate = this.timezoneDate.formatInTimezone(control.value).toDate();
    const isLessThanOneDayResult = this.isLessThanOneDay(endDate);
    if (isLessThanOneDayResult) return;
    return { message: 'itCannotBeLongerThanOneDay' };
  }

  initFormFields() {
    this.formFields = [
      [
        {
          name: 'startingHour',
          type: 'fulldate',
          label: 'startDate',
          size: 12,
        },
        {
          name: 'endingHour',
          type: 'fulldate',
          label: 'endDate',
          size: 12,
          fullDate: { matDatepickerFilter: this.isLessThanOneDay },
        },
        {
          name: 'assignees',
          type: 'multiple-select',
          label: 'assignee',
          size: 12,
          items$: this.assignees$,
          translate: false,
          dataTestId: 'work-period-assignees-select',
        },
      ],
    ];
  }

  loadWorkPeriod() {
    this.workPeriodControllerV2
      .getAllWorkPeriod({
        queryParams: {
          supportTicketId: this.ticket$.value.id,
          orderBy: 'CREATED_AT',
        },
      })
      .subscribe((res) => {
        const newTicket = { ...this.ticket$.value };
        newTicket.workPeriods = res.results;
        this.ticket$.next(newTicket);
      });
  }

  removeWorkPeriod(id: string) {
    this.workPeriodControllerV2.deleteById({ id: id }).subscribe(() => {
      const newTicket = { ...this.ticket$.value };
      newTicket.workPeriods = this.ticket$.value.workPeriods.filter(
        (workPeriod) => workPeriod.id !== id
      );
      this.ticket$.next(newTicket);
    });
  }

  openNewWorkPeriodEditor() {
    this.isCreatingNewWorkPeriod = true;
  }

  closeNewWorkPeriodEditor() {
    this.resetNewWorkPeriodForm();
    this.isCreatingNewWorkPeriod = false;
  }

  createWorkPeriod() {
    if (this.newWorkPeriodForm.invalid) {
      this.newWorkPeriodForm.markAllAsTouched();
      return this.snackBarService.error(
        this.i18nextPipe.transform('forms:SomeFieldIsInvalid'),
        {
          position: 'bottom-right',
          duration: 5000,
          dismissible: true,
          style: {
            color: 'white',
            backgroundColor: COLORS.red,
          },
          icon: '⚠️',
        }
      );
    }

    if (
      this.newWorkPeriodForm.controls['startingHour'].value >=
      this.newWorkPeriodForm.controls['endingHour'].value
    ) {
      return this.snackBarService.error(
        this.i18nextPipe.transform('forms:workStartDateGreaterThanWorkEndDate'),
        {
          position: 'bottom-right',
          duration: 5000,
          dismissible: true,
          style: {
            color: 'white',
            backgroundColor: COLORS.red,
          },
          icon: '⚠️',
        }
      );
    }

    const newWorkPeriod: WorkPeriodRequest = {
      endingHour: this.timezoneDate
        .formatInTimezone(this.newWorkPeriodForm.value.endingHour)
        .toISOString(),
      startingHour: this.timezoneDate
        .formatInTimezone(this.newWorkPeriodForm.value.startingHour)
        .toISOString(),
      supportTicketId: this.ticket$.value.id,
    };

    this.workPeriodControllerV2
      .createWorkPeriod({ newWorkPeriod })
      .subscribe((workPeriodAdded) => {
        let quantityOfAssigneeRequest = (
          this.newWorkPeriodForm.value.assignees as string[]
        ).length;

        (this.newWorkPeriodForm.value.assignees as string[]).map(
          (assigneeId) => {
            this.workPeriodAssigneeControllerV2
              .assignUserToWorkPeriod({
                assigneeId: assigneeId,
                workPeriodId: workPeriodAdded.id,
              })
              .subscribe(
                () => {},
                () => {},
                () => {
                  quantityOfAssigneeRequest--;
                  if (quantityOfAssigneeRequest == 0) {
                    this.isCreatingNewWorkPeriod = false;
                    this.loadWorkPeriod();
                    this.resetNewWorkPeriodForm();
                  }
                }
              );
          }
        );
      });
  }

  getUserFullName(assignee: WorkPeriodResponse['assignees'][0]['assignee']) {
    return `${assignee.firstName} ${assignee.lastName}`;
  }

  getDiffTimeFormatted(startDate: any, endDate: any) {
    startDate = this.timezoneDate.formatInTimezone(startDate);
    endDate = this.timezoneDate.formatInTimezone(endDate);
    const diffTime = startDate.valueOf() - endDate.valueOf();
    const padTo2Digits = (num) => num.toString().padStart(2, '0');

    let minutes = Math.round((diffTime / (1000 * 60)) % 60);
    let hours = Math.floor(diffTime / (1000 * 60 * 60));
    if (minutes == 60) {
      minutes = 0;
      hours += 1;
    }
    return `${padTo2Digits(hours)}h ${padTo2Digits(minutes)}min`;
  }

  formatDateString(dateString: WorkPeriodResponse['createdAt']) {
    const date = this.timezoneDate.formatInTimezone(dateString).toDate();
    const dayDate = date.getDate();
    const month = date.getMonth() + 1;
    const yyyy = date.getFullYear();
    const mm = month >= 10 ? month : `0${month}`;
    const dd = dayDate >= 10 ? dayDate : `0${dayDate}`;

    return `${dd}/${mm}/${yyyy}`;
  }

  formatTimeString(
    dateString:
      | WorkPeriodResponse['endingHour']
      | WorkPeriodResponse['startingHour']
  ) {
    const date = this.timezoneDate.formatInTimezone(dateString).toDate();
    const hour = date.getHours();
    const minutes = date.getMinutes();
    const hh = hour >= 10 ? hour : `0${hour}`;
    const min = minutes >= 10 ? minutes : `0${minutes}`;
    return `${hh}:${min}`;
  }

  convertMiliSecondsToTime(milliseconds) {
    const padTo2Digits = (num) => num.toString().padStart(2, '0');

    let minutes = Math.round((milliseconds / (1000 * 60)) % 60);
    let hours = Math.floor(milliseconds / (1000 * 60 * 60));
    if (minutes == 60) {
      minutes = 0;
      hours += 1;
    }

    return `${padTo2Digits(hours)} ${this.i18nextPipe.transform(
      'forms:hour'
    )} ${padTo2Digits(minutes)} ${this.i18nextPipe.transform('forms:minutes')}`;
  }

  getAssigneesTotalWorkedTime() {
    const getWorkedTime = (endingHour, startingHour) => {
      const startDate = this.timezoneDate
        .formatInTimezone(startingHour)
        .toDate();
      startDate.setSeconds(0);
      const endDate = this.timezoneDate.formatInTimezone(endingHour).toDate();
      endDate.setSeconds(0);
      return Math.abs(endDate.getTime() - startDate.getTime());
    };

    const workingPeriods = this.ticket$.value.workPeriods;
    const totalAssigneesWorkedTime: UsersWorkedHours =
      workingPeriods.reduce<UsersWorkedHours>(
        (acc: UsersWorkedHours, workPeriod) => {
          workPeriod.assignees.forEach((item) => {
            const assigneeId = item.assignee.id;
            if (!acc[assigneeId]) {
              const assigneeData = {
                firstName: item.assignee.firstName,
                lastName: item.assignee.lastName,
                totalWorkedTimeInMilliseconds: 0,
              };
              acc[assigneeId] = assigneeData;
            }
            const workedTime = getWorkedTime(
              workPeriod.endingHour,
              workPeriod.startingHour
            );

            acc[assigneeId].totalWorkedTimeInMilliseconds += workedTime;
          });

          return acc;
        },
        {}
      );

    const valuesInArray = Object.values(totalAssigneesWorkedTime);

    return valuesInArray;
  }

  getTotalWorkedTime(usersTotalWorkedData: UserTotalWorkedData[]) {
    return usersTotalWorkedData.reduce((acc, userTotalWorkedData) => {
      return acc + userTotalWorkedData.totalWorkedTimeInMilliseconds;
    }, 0);
  }

  mapAssigneeToUser(assignee: WorkPeriodAssigneeResponse) {
    return new User(
      assignee.assignee.id,
      assignee.assignee.email,
      assignee.assignee.firstName,
      assignee.assignee.lastName,
      assignee.assignee.locale,
      assignee.assignee.phone,
      assignee.assignee.photo,
      assignee.assignee.username,
      assignee.assignee.roles.map(
        (role) => new Role(role.id, role.name, [], role.deleted)
      ),
      assignee.assignee.tenants.map(
        (tenant) => new Tenant(tenant.id, tenant.name, tenant.email)
      ),
      assignee.assignee.active,
      assignee.assignee.profilePictureID,
      assignee.assignee.profilePictureURL,
      assignee.assignee.timeZone
    );
  }
}
