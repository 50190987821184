import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { SaleTicketInfo, UnsavedTicketSale } from '@fullyops/legacy/data';

class TicketSaleForm extends UntypedFormGroup {
  constructor(ticket: UnsavedTicketSale, dis: boolean) {
    super({});

    [
      'name',
      'priority',
      'assigneeIds',
      'statusId',
      'company',
      'contactIds',
      'notes',
      'saleDate',
      'won',
      'lossMotiveId',
    ].forEach((controlName) => {
      return this.addControl(
        controlName,
        new UntypedFormControl(
          { value: ticket[controlName], disabled: dis },
          []
        )
      );
    });

    Object.keys(ticket['saleTicketInfo']).forEach((controlName) => {
      return this.addControl(
        controlName,
        new UntypedFormControl(
          {
            value:
              ticket['saleTicketInfo'][
                controlName == 'companyId' ? 'company' : controlName
              ],
            disabled: dis,
          },
          []
        )
      );
    });

    /**
     *  Set validators
     */
    [
      'name',
      'priority',
      'statusId',
      'company',
      'contactIds',
      'expiresIn',
      'proposalNumber',
    ].forEach((controlName) =>
      (this as UntypedFormGroup)
        .get(controlName)
        .setValidators(Validators.required)
    );

    this.addControl(
      'companyDistance',
      new UntypedFormControl(
        { value: ticket.clientInfo.distance, disabled: false },
        []
      )
    );
    this.addControl(
      'companyAddress',
      new UntypedFormControl(
        { value: ticket.clientInfo.address, disabled: false },
        []
      )
    );
    this.addControl(
      'companyEmail',
      new UntypedFormControl({ value: '', disabled: true }, [])
    );
    this.addControl(
      'companyPhone',
      new UntypedFormControl({ value: '', disabled: true }, [])
    );
    this.addControl(
      'contactEmail',
      new UntypedFormControl({ value: '', disabled: true }, [])
    );
    this.addControl(
      'contactPhone',
      new UntypedFormControl({ value: '', disabled: true }, [])
    );
    this.addControl(
      'clientCity',
      new UntypedFormControl(
        { value: ticket.clientInfo.city, disabled: false },
        []
      )
    );
    this.addControl(
      'zipCode',
      new UntypedFormControl(
        { value: ticket.clientInfo.zipCode, disabled: false },
        []
      )
    );
    this.addControl(
      'countryISOCode',
      new UntypedFormControl(
        { value: ticket.clientInfo.countryISOCode, disabled: false },
        []
      )
    );
  }
}

@Injectable()
export class TicketSaleFormService {
  fb = new UntypedFormBuilder();

  private form: BehaviorSubject<UntypedFormGroup> =
    new BehaviorSubject<UntypedFormGroup>(new UntypedFormGroup({}));
  private initialTicket: UnsavedTicketSale;
  form$: Observable<UntypedFormGroup> = this.form.asObservable();

  constructor() {}

  initForm(draftTicketSale: UnsavedTicketSale, disabled: boolean) {
    this.initialTicket = draftTicketSale;

    this.form.next(new TicketSaleForm(draftTicketSale, disabled));
  }

  getDraft() {
    return this.initialTicket.clone({
      name: (this.form.getValue().get('name') as UntypedFormControl).value,
      priority: (this.form.getValue().get('priority') as UntypedFormControl)
        .value,
      assigneeIds: (
        this.form.getValue().get('assigneeIds') as UntypedFormControl
      ).value,
      statusId: (this.form.getValue().get('statusId') as UntypedFormControl)
        .value,
      companyId: (this.form.getValue().get('company') as UntypedFormControl)
        .value.id,
      contactIds: (this.form.getValue().get('contactIds') as UntypedFormControl)
        .value,
      notes: (this.form.getValue().get('notes') as UntypedFormControl).value,
      saleDate: (this.form.getValue().get('saleDate') as UntypedFormControl)
        .value,
      won: (this.form.getValue().get('won') as UntypedFormControl).value,
      lossMotiveId: (
        this.form.getValue().get('lossMotiveId') as UntypedFormControl
      ).value,
      saleTicketInfo: new SaleTicketInfo(
        (this.form.getValue().get('formation') as UntypedFormControl).value,
        (this.form.getValue().get('deliveryDate') as UntypedFormControl).value,
        (
          this.form.getValue().get('deliveryAddress') as UntypedFormControl
        ).value,
        (this.form.getValue().get('payment') as UntypedFormControl).value,
        (this.form.getValue().get('warranty') as UntypedFormControl).value,
        (this.form.getValue().get('installation') as UntypedFormControl).value,
        (
          this.form.getValue().get('technicalAssistance') as UntypedFormControl
        ).value,
        (this.form.getValue().get('expiresIn') as UntypedFormControl).value,
        (this.form.getValue().get('proposalNumber') as UntypedFormControl).value
      ),
      clientInfo: {
        address: this.form.getValue().get('companyAddress').value,
        city: this.form.getValue().get('clientCity').value,
        zipCode: this.form.getValue().get('zipCode').value,
        countryISOCode: this.form.getValue().get('countryISOCode').value,
        distance: this.form.getValue().get('companyDistance').value,
      },
    });
  }

  markAllAsTouched() {
    const currentTicketSale = this.form.getValue();
    currentTicketSale.markAllAsTouched();

    this.form.next(currentTicketSale);
  }
}
