import { Response } from '../Response';
import { Endpoint } from '../Endpoint';
import { HttpClient } from '@angular/common/http';
import { IListResponse } from '../IListResponse';
import { Injectable, Inject } from '@angular/core';
import { API_SERVICE_CONFIG } from '../ApiServiceConfig';
import { IApiServiceConfig } from '../IApiServiceConfig';

import { Observable, BehaviorSubject } from 'rxjs';

import { SubProduct } from '../../models/v0/SubProduct';
import { UnsavedSubProduct } from '../../models/v0/UnsavedSubProduct';
import { ISerialisedSubProduct } from '../../models/v0/serialisations/ISerialisedSubProduct';
import { tap, map } from 'rxjs/operators';

type SubProductList = IListResponse<ISerialisedSubProduct>;

@Injectable()
export class SubProductsEndpoint extends Endpoint<
  SubProduct,
  ISerialisedSubProduct
> {
  private readonly url = this.config.url + 'subproducts/';

  constructor(
    @Inject(API_SERVICE_CONFIG) private config: IApiServiceConfig,
    private http: HttpClient
  ) {
    super(SubProduct.fromSerialised);
  }

  getAll(
    source?: Observable<SubProductList>,
    store?: BehaviorSubject<Response<SubProduct[]>>
  ): Observable<Response<SubProduct[]>> {
    const defaultSource = this.http.get<SubProductList>(this.url);
    return this.list(source || defaultSource, store);
  }

  get(
    id: string,
    source?: Observable<ISerialisedSubProduct>,
    store?: BehaviorSubject<Response<SubProduct[]>>
  ): Observable<Response<SubProduct>> {
    const defaultSource = this.http.get<ISerialisedSubProduct>(
      `${this.url}${id}/`
    );
    return this.retrieve(source || defaultSource, store);
  }

  post(
    subProduct: UnsavedSubProduct,
    source?: Observable<ISerialisedSubProduct>,
    store?: BehaviorSubject<Response<SubProduct[]>>
  ): Observable<Response<SubProduct>> {
    const { ...body } = subProduct.toSerialised();
    const defaultSource = this.http.post<ISerialisedSubProduct>(this.url, body);
    return this.add(source || defaultSource, store);
  }

  patch(
    id: string,
    user: UnsavedSubProduct,
    source?: Observable<ISerialisedSubProduct>,
    store?: BehaviorSubject<Response<SubProduct[]>>
  ) {
    const defaultSource = this.http.patch<ISerialisedSubProduct>(
      `${this.url}${id}/`,
      user
    );

    return defaultSource.pipe(
      tap((instance) =>
        this.update(
          source || defaultSource,
          SubProduct.fromSerialised(instance),
          store
        )
      ),
      map((serialiser) => new Response(SubProduct.fromSerialised(serialiser)))
    );
  }

  delete(
    id: string,
    source?: Observable<{}>,
    store?: BehaviorSubject<Response<SubProduct[]>>
  ): Observable<Response<{}>> {
    const defaultSource = this.http.delete<{}>(`${this.url}${id}/`);
    return this.remove(source || defaultSource, id, store);
  }
}
