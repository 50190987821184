import { HttpClient } from '@angular/common/http';
import { IApiServiceConfig } from './../IApiServiceConfig';
import { API_SERVICE_CONFIG } from './../ApiServiceConfig';
import { Inject, Injectable } from '@angular/core';
import { Response } from '../Response';
import { ClientEquipment } from '../../models/v0/ClientEquipment';
import { IListResponse } from '../IListResponse';
import { Endpoint } from '../Endpoint';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { InstallationClientEquipment } from '../../models/v0/InstallationClientEquipment';
import {
  ISerialisedInstallationClientEquipment,
  ISerialisedUnsavedInstallationClientEquipment,
} from '../../models/v0/serialisations/ISerialisedInstallationClientEquipment';
import { UnsavedInstallationClientEquipment } from '../../models/v0/UnsavedInstalationClientEquipment';

type InstallationClientEquipmentList =
  IListResponse<ISerialisedUnsavedInstallationClientEquipment>;

@Injectable()
export class InstallationTicketClientEquipmentEndpoint extends Endpoint<
  InstallationClientEquipment,
  ISerialisedInstallationClientEquipment
> {
  constructor(
    @Inject(API_SERVICE_CONFIG) private config: IApiServiceConfig,
    private http: HttpClient
  ) {
    super(InstallationClientEquipment.fromSerialised);
  }

  private readonly url = this.config.url + 'tickets/installations/equipments/';

  getAll(
    filters: {},
    source?: Observable<InstallationClientEquipmentList>,
    store?: BehaviorSubject<Response<InstallationClientEquipment[]>>
  ): Observable<Response<InstallationClientEquipment[]>> {
    let query = '';
    Object.keys(filters).forEach((key) => (query += `&${key}=${filters[key]}`));

    const defaultSource = this.http.get<InstallationClientEquipmentList>(
      `${this.url}all?size=100&page=0${query}`
    );
    return this.list(source || defaultSource, store);
  }

  getTicketMachines(
    filters: {},
    source?: Observable<InstallationClientEquipmentList>,
    store?: BehaviorSubject<Response<InstallationClientEquipment[]>>
  ): Observable<Response<InstallationClientEquipment[]>> {
    let query = '';
    Object.keys(filters).forEach((key) => (query += `&${key}=${filters[key]}`));

    const defaultSource = this.http.get<InstallationClientEquipmentList>(
      `${this.url}?size=100&page=0${query}`
    );

    return this.list(source || defaultSource, store);
  }

  get(
    id: string,
    source?: Observable<ISerialisedUnsavedInstallationClientEquipment>,
    store?: BehaviorSubject<Response<InstallationClientEquipment[]>>
  ): Observable<Response<InstallationClientEquipment>> {
    const defaultSource =
      this.http.get<ISerialisedUnsavedInstallationClientEquipment>(
        `${this.url}${id}/`
      );
    return this.retrieve(source || defaultSource, store);
  }

  post(
    ticketId: string,
    machine: ClientEquipment,
    source?: Observable<ISerialisedUnsavedInstallationClientEquipment>,
    store?: BehaviorSubject<Response<InstallationClientEquipment[]>>
  ): Observable<Response<InstallationClientEquipment>> {
    const body = {
      equipmentId: machine.id,
      ticketId: ticketId,
      photos: [],
    };
    const defaultSource =
      this.http.post<ISerialisedUnsavedInstallationClientEquipment>(
        `${this.url}`,
        body
      );
    return this.add(source || defaultSource, store);
  }

  patch(
    ticketId: string,
    machine: UnsavedInstallationClientEquipment,
    source?: Observable<ISerialisedInstallationClientEquipment>,
    store?: BehaviorSubject<Response<InstallationClientEquipment[]>>
  ): Observable<Response<InstallationClientEquipment>> {
    const { ...body } = machine.toSerialised();
    const defaultSource =
      this.http.patch<ISerialisedInstallationClientEquipment>(
        `${this.url}${ticketId}/`,
        body
      );

    return defaultSource.pipe(
      tap((instance) =>
        this.update(
          source || defaultSource,
          InstallationClientEquipment.fromSerialised(instance),
          store
        )
      ),
      map(
        (serialiser) =>
          new Response(InstallationClientEquipment.fromSerialised(serialiser))
      )
    );
  }

  delete(
    ticketId: string,
    machineId: string,
    source?: Observable<{}>,
    store?: BehaviorSubject<Response<InstallationClientEquipment[]>>
  ): Observable<Response<{}>> {
    const defaultSource = this.http.delete<{}>(
      `${this.url}${ticketId}/${machineId}/`
    );
    return this.remove(source || defaultSource, ticketId + machineId, store);
  }
}
