import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { ComponentInOutAnimation } from '@fullyops/legacy/ui/ui-shared/utils/component-base-animation';
import { UserFormService } from './user-form.service';
import { TicketType, Response, Permission } from '@fullyops/legacy/data';
import { BehaviorSubject } from 'rxjs';
import { SnackbarService } from '../ui-snackbar.service';
import { UiAuthService } from '../ui-auth.service';
import { UserControllerV2 } from '../ui-user-controller-v2.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserInput, UserResponse } from '@fullyops/legacy/data/api/types/User';
import { TemplateFormFields } from '@fullyops/legacy/ui/ui-shared/form-template/form-template.component';
import { Router } from '@angular/router';
import { TenantControllerV2 } from '../ui-tenant-controller-v2.service';
import { ActionButtons } from '@fullyops/legacy/ui/ui-shared/utils/crm-types';

@Component({
  selector: 'crm-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss'],
  providers: [UserFormService],
  animations: [ComponentInOutAnimation.AnimTrigger],
})
export class UserFormComponent implements OnInit {
  constructor(
    public snackBar: SnackbarService,
    public authService: UiAuthService,
    private userControllerV2: UserControllerV2,
    protected router: Router,
    protected tenantControllerV2: TenantControllerV2
  ) {}

  @Input() isNew: Boolean;
  @Input() user$: BehaviorSubject<UserResponse>;
  @Input() roles$ = new BehaviorSubject<Permission[]>([]);
  @Input() history$ = new BehaviorSubject<Response<History[]>>(new Response());
  @Output() openActivityEvent = new EventEmitter<string>();
  @Output() openTicketEvent = new EventEmitter<{
    ticketId: string;
    ticketType: TicketType;
  }>();

  formFields: TemplateFormFields<any> = [];
  formGroup = new FormGroup({
    firstName: new FormControl<UserInput['firstName']>('', [
      Validators.required,
    ]),
    lastName: new FormControl<UserInput['lastName']>('', [Validators.required]),
    username: new FormControl<UserInput['username']>('', [Validators.required]),
    roles: new FormControl<UserInput['roles']>([], [Validators.required]),
    email: new FormControl<UserInput['email']>('', [
      Validators.email,
      Validators.required,
    ]),
    phone: new FormControl<UserInput['phone']>(''),
    active: new FormControl<UserInput['active']>(true),
    tenants: new FormControl<UserInput['tenants']>([]),
  });

  actionButtons: ActionButtons = {};

  ngOnInit() {
    this.initFields();
    this.initFormGroup();
    this.initActionButtons();
  }

  initActionButtons() {
    if (this.isNew) return;
    this.actionButtons.warn = { label: 'delete', color: 'warn' };
  }

  initFormGroup() {
    this.tenantControllerV2.getCurrentTenant().subscribe((res) => {
      const user = this.user$?.value;
      const controls = this.formGroup.controls;
      controls['tenants'].setValue([{ id: res.id }]);
      if (user) {
        controls['active'].setValue(user.active);
        controls['email'].setValue(user.email);
        controls['firstName'].setValue(user.firstName);
        controls['lastName'].setValue(user.lastName);
        controls['phone'].setValue(user.phone);
        controls['username'].setValue(user.username);
        controls['roles'].setValue(user.roles.map((r) => r.id));
      }
    });
  }

  initFields() {
    this.formFields = [
      [
        { name: 'firstName', label: 'firstName', type: 'text', size: 6 },
        { name: 'lastName', label: 'lastName', type: 'text', size: 6 },
        { name: 'username', label: 'username', type: 'text', size: 6 },
        {
          name: 'roles',
          label: 'roles',
          type: 'multiple-select',
          size: 6,
          items$: this.roles$,
          nullable: false,
          translate: true,
        },
        { name: 'email', label: 'email', type: 'text', size: 6 },
        { name: 'phone', label: 'phone', type: 'text', size: 6 },
      ],
    ];

    if (!this.isNew) {
      this.formFields[0].push({
        name: 'active',
        label: 'active',
        type: 'slide',
        size: 12,
      });
    }
  }

  onOpenActivity(activityId: string) {
    this.router.navigate([]).then((_) => {
      window.open(`/activities/${activityId}/edit`, '_blank');
    });
  }

  onOpenTicket({ ticketId, ticketType }) {
    let ticketTypeUrl = ticketType as string;

    this.router.navigate([]).then((_) => {
      window.open(
        `/tickets/${(ticketTypeUrl as string).toLowerCase()}/${ticketId}/edit`,
        '_blank'
      );
    });
  }

  onSubmit() {
    this.formGroup.markAllAsTouched();

    if (!this.formGroup.valid) {
      return this.snackBar.openErrorFormMissingFields();
    }

    if (this.isNew) {
      const newUser = this.formGroup.value;
      this.userControllerV2.createUser({ newUser }).subscribe((res) => {
        this.router.navigate([`/users/${res.id}/edit`]);
      });
    }
    if (!this.isNew) {
      const updateUser = this.formGroup.value;
      this.userControllerV2
        .updateUserById({ id: this.user$.value.id, user: updateUser })
        .subscribe((res) => this.user$.next(res));
    }
  }

  onActionEvent(action: string) {
    if (action == 'delete') {
      this.userControllerV2
        .deleteUserById({ id: this.user$.value.id })
        .subscribe(() => {
          this.router.navigate(['/users']);
        });
    }
  }
}
