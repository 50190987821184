import { UiAuthService } from '@fullyops/legacy/ui/ui-crm/ui-auth.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { UiPasswordService } from '@fullyops/legacy/ui';
import { BehaviorSubject } from 'rxjs';
import { AuthEndpoint } from '@fullyops/legacy/data/api/v0/AuthEndpoint';
import { PasswordResetControllerV2 } from '@fullyops/legacy/ui/ui-crm/ui-password-reset-controller-v2.service';
import { TenantResolverService } from '@fullyops/core/services/tenant/tenant-resolver.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordForm: UntypedFormGroup;
  loading$ = new BehaviorSubject(false);
  submitted = false;
  token: string;
  username: string;

  constructor(
    private passwordService: UiPasswordService,
    private authService: UiAuthService,
    private authEndpoint: AuthEndpoint,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    private passwordResetControllerV2: PasswordResetControllerV2,
    private tenantResolverService: TenantResolverService
  ) {}

  ngOnInit() {
    this.setTokenAndUserNameValues();
    this.validateToken();
    this.authService.logout();
    this.authEndpoint.logout();
    this.resetPasswordForm = this.formBuilder.group({
      newPassword: ['', Validators.required],
      confirmPassword: ['', Validators.required],
    });
  }

  setTokenAndUserNameValues() {
    this.token = this.route.snapshot.queryParams.token;
    this.username = this.route.snapshot.queryParams.user;
  }

  validateToken() {
    this.passwordResetControllerV2
      .checkPasswordResetToken({
        token: this.token,
        username: this.username,
        tenantName: this.getTenantName(),
      })
      .subscribe((res) => {
        if (!res.valid) this.router.navigate(['/token-expired']);
      });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.resetPasswordForm.invalid) {
      return;
    }

    this.loading$.next(true);
    this.passwordResetControllerV2
      .resetPassword({
        duplicatePassword: this.resetPasswordForm.get('confirmPassword').value,
        newPassword: this.resetPasswordForm.get('newPassword').value,
        token: this.token,
        username: this.username,
        tenantName: this.getTenantName(),
      })
      .subscribe(() => this.router.navigate(['/login']));
  }

  getTenantName() {
    return this.tenantResolverService.getActiveTenant();
  }
}
