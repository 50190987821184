<ng-container *ngIf="paginatedList$ | async as paginatedList">
  <ng-container *ngIf="paginatedList.count > 0">
    <div class="pageinator-details">
      {{
        'paginatorInfo'
          | i18nextCap
            : {
                startAt: paginatedList.currentPage * paginatedList.pageSize + 1,
                endAt:
                  paginatedList.currentPage * paginatedList.pageSize +
                  paginatedList.results.length,
                total: paginatedList.count
              }
      }}
    </div>
    <div class="paginator-container">
      <button
        mat-icon-button
        type="button"
        color="primary"
        aria-label="first page"
        [disabled]="!paginatedList.hasPrevious"
        (click)="handleFirstPage()"
      >
        <mat-icon>first_page</mat-icon>
      </button>
      <button
        mat-icon-button
        type="button"
        color="primary"
        aria-label="previous"
        [disabled]="!paginatedList.hasPrevious"
        (click)="handlePrevPage()"
      >
        <mat-icon>navigate_before</mat-icon>
      </button>
      <ng-container *ngIf="pages$ | async as pages">
        <button
          mat-flat-button
          type="button"
          class="page-button"
          *ngFor="let page of pages"
          [ngClass]="{ selected: page === paginatedList.currentPage + 1 }"
          (click)="handlePageChange(page - 1)"
        >
          {{ page }}
        </button>
      </ng-container>
      <button
        mat-icon-button
        type="button"
        color="primary"
        aria-label="next"
        [disabled]="!paginatedList.hasNext"
        (click)="handleNextPage()"
      >
        <mat-icon>navigate_next</mat-icon>
      </button>
      <button
        mat-icon-button
        type="button"
        color="primary"
        aria-label="last page"
        [disabled]="!paginatedList.hasNext"
        (click)="handleLastPage()"
      >
        <mat-icon>last_page</mat-icon>
      </button>
    </div>
  </ng-container>
</ng-container>
