<div class="align-center">
  <form class="login">
    <img
      class="client-logo"
      src="assets/svg/fullyops-logo-white.svg"
      alt="FullyOps Logo"
    />
    <br />
    <header>
      <h2>
        {{ 'translation:tokenExpiredOrInvalid' | i18nextCap }}
      </h2>
    </header>
  </form>
</div>
