<div class="simulations">
  <crm-list
    title="simulations"
    subtitle="listOfSimulations"
    createEntryLabel="createSimulation"
    emptyListLabel="noSimulationsAssociated"
    [actionButtons]="actionButtons"
    [list$]="simulations$"
    (actionEvent)="onSimulationsListEvent($event)"
    (createEntryEvent)="onCreateSimulation()"
  >
  </crm-list>

  <crm-list
    title="catalogProduct"
    subtitle="listOfProductsFromStock"
    emptyListLabel="noProductsAssociated"
    [actionButtons]="actionButtons"
    [list$]="productsConfigs$"
    (actionEvent)="onProductConfigsEvent($event)"
  >
  </crm-list>

  <mat-form-field *ngIf="productsConfigsList$ | async as productsConfigsList">
    <input
      type="text"
      matInput
      placeholder="{{ 'catalogProduct' | i18nextCap }}"
      [formControl]="productConfigControl"
      [matAutocomplete]="autoGroup"
    />
    <mat-autocomplete
      #autoGroup="matAutocomplete"
      (optionSelected)="addProductConfig($event)"
    >
      <mat-optgroup
        *ngFor="let group of productsConfigsList"
        [label]="group.product.name"
      >
        <mat-option
          *ngFor="let config of group.configurations"
          [value]="config.id"
          class="option"
        >
          <span>{{ config.name }}</span>
          <small>Features: {{ config.product.subproducts.length }}</small>
          <small>Price: {{ config.total }} €</small>
        </mat-option>
      </mat-optgroup>
    </mat-autocomplete>
  </mat-form-field>
</div>
