import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ComponentInOutAnimation } from '@fullyops/legacy/ui/ui-shared/utils/component-base-animation';
import { ProductConfig } from '@fullyops/legacy/data';

@Component({
  selector: 'crm-product-configuration-list-card',
  templateUrl: './product-configuration-list-card.component.html',
  styleUrls: ['./product-configuration-list-card.component.scss'],
  animations: [ComponentInOutAnimation.AnimTrigger],
})
export class ProductConfigurationListCardComponent {
  @Input() productConfig: ProductConfig;
  @Output() openConfigurationEvent = new EventEmitter<string>();

  panelOpenState = false;

  openConfiguration() {
    this.openConfigurationEvent.emit(this.productConfig.id);
  }
}
