import { ISerialisedTicketRequestPart } from './serialisations/ISerialisedTicketRequestPart';
import { Part } from './Part';
import { UnsavedTicketRequestPart } from './UnsavedTicketRequestPart';

export class TicketRequestPart {
  constructor(readonly part: Part, readonly quantity: number) {}

  static fromSerialised(serialised: ISerialisedTicketRequestPart) {
    const part = Part.fromSerialised(serialised.part);

    return new TicketRequestPart(part, serialised.quantity);
  }

  toSerialised(): ISerialisedTicketRequestPart {
    const partObj = this.part.toSerialised();

    return {
      part: partObj,
      quantity: this.quantity,
    };
  }

  toDraft(): UnsavedTicketRequestPart {
    const ticketRequestPart = this.toSerialised();
    return UnsavedTicketRequestPart.fromSerialised({
      part: {
        ...ticketRequestPart.part,
      },
      quantity: ticketRequestPart.quantity,
    });
  }

  clone(partialTicketRequestPart: PartialTicketRequestPart): TicketRequestPart {
    const resolve = (key: keyof TicketRequestPart) =>
      partialTicketRequestPart.hasOwnProperty(key)
        ? partialTicketRequestPart[key]
        : this[key];

    return new TicketRequestPart(resolve('part'), resolve('quantity'));
  }
}
type PartialTicketRequestPart = Partial<
  Pick<TicketRequestPart, 'part' | 'quantity'>
>;
