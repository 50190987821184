import { ISerialisedTravel } from './../../models/v0/serialisations/ISerialisedTravel';
import { Endpoint } from '../Endpoint';
import { Inject, Injectable } from '@angular/core';
import { API_SERVICE_CONFIG } from '../ApiServiceConfig';
import { IApiServiceConfig } from '../IApiServiceConfig';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Response } from '../Response';
import { Travel } from '../../models/v0/Travel';
import { addOrUpdateTravelProps } from '@fullyops/legacy/ui/ui-crm/ui-tickets-travel.service';

export type TravelTicketType = 'installationTicket' | 'supportTicket';

@Injectable()
export class TravelEndpoint extends Endpoint<Travel, ISerialisedTravel> {
  constructor(
    @Inject(API_SERVICE_CONFIG) private config: IApiServiceConfig,
    private http: HttpClient
  ) {
    super(Travel.fromSerialised);
  }

  private readonly url = this.config.url + 'travels';

  post(
    body,
    source?: Observable<ISerialisedTravel>,
    store?: BehaviorSubject<Response<Travel[]>>
  ) {
    const defaultSource = this.http.post<ISerialisedTravel>(
      `${this.url}`,
      body
    );
    return this.add(source || defaultSource, store);
  }

  delete(
    travelId: string,
    source?: Observable<ISerialisedTravel>,
    store?: BehaviorSubject<Response<Travel[]>>
  ) {
    const defaultSource = this.http.delete<null>(`${this.url}/${travelId}`);
    return this.remove(source || defaultSource, travelId, store);
  }

  patch(travelId: string, body: addOrUpdateTravelProps) {
    return this.http.patch(`${this.url}/${travelId}`, body);
  }

  getAllTravelsByTicketId(ticketId: string, ticketType: TravelTicketType) {
    return this.http.get<{
      page: number;
      results: Travel[];
      size: number;
      totalPages: number;
    }>(`${this.url}?${ticketType}Id=${ticketId}&orderBy=CREATED_AT`);
  }
}
