import { Response } from '../Response';
import { Endpoint } from '../Endpoint';
import { HttpClient } from '@angular/common/http';
import { IListResponse } from '../IListResponse';
import { Injectable, Inject } from '@angular/core';
import { API_SERVICE_CONFIG } from '../ApiServiceConfig';

import { Observable, BehaviorSubject } from 'rxjs';

import { ClientEquipment } from '../../models/v0/ClientEquipment';
import { ISerialisedClientEquipment } from '../../models/v0/serialisations/ISerialisedClientEquipment';
import { IApiServiceConfig } from '../IApiServiceConfig';
import { UnsavedClientEquipment } from '../../models/v0/UnsavedClientEquipment';
import { map, tap } from 'rxjs/operators';

type ClientEquipmentList = IListResponse<ISerialisedClientEquipment>;

@Injectable()
export class ClientEquipmentsEndpoint extends Endpoint<
  ClientEquipment,
  ISerialisedClientEquipment
> {
  private readonly url = this.config.url + 'clients/equipments/';

  constructor(
    @Inject(API_SERVICE_CONFIG) private config: IApiServiceConfig,
    private http: HttpClient
  ) {
    super(ClientEquipment.fromSerialised);
  }

  getAll(
    filters?: {},
    source?: Observable<ClientEquipmentList>,
    store?: BehaviorSubject<Response<ClientEquipment[]>>
  ): Observable<Response<ClientEquipment[]>> {
    let query = '';
    Object.keys(filters).forEach((key) => (query += `&${key}=${filters[key]}`));
    const defaultSource = this.http.get<ClientEquipmentList>(
      `${this.url}?page=0${query}`
    );

    return this.list(source || defaultSource, store);
  }

  get(
    id: string,
    source?: Observable<ISerialisedClientEquipment>,
    store?: BehaviorSubject<Response<ClientEquipment[]>>
  ): Observable<Response<ClientEquipment>> {
    const defaultSource = this.http.get<ISerialisedClientEquipment>(
      `${this.url}/${id}/`
    );
    return this.retrieve(source || defaultSource, store);
  }

  post(
    equipment: UnsavedClientEquipment,
    source?: Observable<ISerialisedClientEquipment>,
    store?: BehaviorSubject<Response<ClientEquipment[]>>
  ): Observable<Response<ClientEquipment>> {
    const defaultSource = this.http.post<ISerialisedClientEquipment>(
      `${this.url}`,
      equipment.toSerialised()
    );
    return this.add(source || defaultSource, store);
  }

  patch(
    id: string,
    equipment: UnsavedClientEquipment,
    source?: Observable<ISerialisedClientEquipment>,
    store?: BehaviorSubject<Response<ClientEquipment[]>>
  ) {
    const { ...body } = equipment.toSerialised();
    const defaultSource = this.http.patch<ISerialisedClientEquipment>(
      `${this.url}${id}/`,
      body
    );

    return defaultSource.pipe(
      tap((instance) =>
        this.update(
          source || defaultSource,
          ClientEquipment.fromSerialised(instance),
          store
        )
      ),
      map(
        (serialiser) => new Response(ClientEquipment.fromSerialised(serialiser))
      )
    );
  }

  delete(
    id: string,
    source?: Observable<{}>,
    store?: BehaviorSubject<Response<ClientEquipment[]>>
  ): Observable<Response<{}>> {
    const defaultSource = this.http.delete<{}>(`${this.url}/${id}/`);
    return this.remove(source || defaultSource, id, store);
  }
}
