import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Contact } from '../../models/v0/Contact';
import { ISerialisedContact } from '../../models/v0/serialisations/ISerialisedContact';
import { API_SERVICE_CONFIG } from '../ApiServiceConfig';
import { Endpoint } from '../Endpoint';
import { IApiServiceConfig } from '../IApiServiceConfig';
import { SupportTicketContactResponse } from '../types/Contact';

@Injectable()
export class TicketsSupportContactEndpoint extends Endpoint<
  Contact,
  ISerialisedContact
> {
  constructor(
    @Inject(API_SERVICE_CONFIG) private config: IApiServiceConfig,
    private http: HttpClient
  ) {
    super(Contact.fromSerialised);
  }
  private readonly url =
    this.config.url + 'tickets/multimachinesupports/contacts';

  assignContact(contactId: string, supportTicketId: string) {
    return this.http.post<SupportTicketContactResponse>(`${this.url}`, {
      contactId,
      supportTicketId,
    });
  }

  unAssignContact(contactId: string, supportTicketId: string) {
    return this.http.delete(`${this.url}/${supportTicketId}/${contactId}`);
  }
}
