import { ISerialisedUserPart } from './serialisations/ISerialisedUserPart';
import { UnsavedUserPart } from './UnsavedUserPart';
import { Part } from './Part';
import { User } from './User';

export class UserPart {
  constructor(
    readonly id: string,
    readonly part: Part,
    readonly quantity: number,
    readonly user: User
  ) {}

  static fromSerialised(serialised: ISerialisedUserPart) {
    // Once the endpoint results don't come with an id, we have
    // to create our own id using the 'user id' and the 'part id'.
    // It's sad but is true :)
    const userPartKey = serialised.user.id + serialised.part.id;

    const part = Part.fromSerialised(serialised.part);
    const user = User.fromSerialised(serialised.user);

    return new UserPart(userPartKey, part, serialised.quantity, user);
  }

  toSerialised(): ISerialisedUserPart {
    const partObj = this.part.toSerialised();
    const userObj = this.user.toSerialised();

    return {
      part: partObj,
      quantity: this.quantity,
      user: userObj,
    };
  }

  toDraft(): UnsavedUserPart {
    const ticketRequestPart = this.toSerialised();
    return UnsavedUserPart.fromSerialised({
      ...ticketRequestPart,
      partId: ticketRequestPart.part.id,
    });
  }

  clone(partialUserPart: PartialUserPart): UserPart {
    const resolve = (key: keyof UserPart) =>
      partialUserPart.hasOwnProperty(key) ? partialUserPart[key] : this[key];

    return new UserPart(
      resolve('id'),
      resolve('part'),
      resolve('quantity'),
      resolve('user')
    );
  }
}
type PartialUserPart = Partial<
  Pick<UserPart, 'id' | 'part' | 'quantity' | 'user'>
>;
