import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import {
  FieldType,
  ActionButtons,
  PermissionType,
} from '@fullyops/legacy/ui/ui-shared/utils/crm-types';
import { ComponentInOutAnimation } from '@fullyops/legacy/ui/ui-shared/utils/component-base-animation';
import { Response, ResponseState, LossMotive } from '@fullyops/legacy/data';
import { BehaviorSubject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'crm-loss-motives',
  templateUrl: './loss-motives.component.html',
  styleUrls: ['./loss-motives.component.scss'],
  animations: [ComponentInOutAnimation.AnimTrigger],
})
export class LossMotivesComponent implements OnInit {
  @Input() title: string;
  @Input() lossMotives$ = new BehaviorSubject<Response<LossMotive[]>>(
    new Response()
  );
  @Output() openEvent = new EventEmitter<string>();
  @Output() addNewEvent = new EventEmitter();

  // Response Possible States
  state = ResponseState;

  actions: ActionButtons = {};
  lossMotivesList$ = new BehaviorSubject<[]>([]);

  ngOnInit() {
    this.initLossMotivesList();

    this.initActionButtons();
  }

  initLossMotivesList() {
    this.lossMotives$
      .pipe(
        filter((response) => response.data !== null),
        map((response) => response.data as LossMotive[]),
        map((lossmotives) => lossmotives.map(this.parseLossMotive))
      )
      .subscribe(this.lossMotivesList$);
  }

  initActionButtons() {
    this.actions.primary = {
      label: 'addNew',
      color: 'primary',
      permissions: [PermissionType.CAN_EDIT_LOSS_MOTIVES],
    };
  }

  parseLossMotive(lossMotive: LossMotive) {
    return {
      id: lossMotive.id,
      columns: {
        name: { label: 'name', type: FieldType.Text, value: lossMotive.name },
        description: {
          label: 'description',
          type: FieldType.Text,
          value: lossMotive.description,
        },
      },
    };
  }

  onActionEvent(event: string) {
    if (event === 'addNew') {
      this.onAddNew();
    }
  }

  onOpen(id: string) {
    this.openEvent.emit(id);
  }

  onAddNew() {
    this.addNewEvent.emit();
  }
}
