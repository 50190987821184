import { BehaviorSubject } from 'rxjs';
import { Component, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import { TenantConfigurationResponse } from '@fullyops/legacy/data/api/types/Tenant';
import {
  AbstractControl,
  Form,
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { SnackbarService } from '../ui-snackbar.service';
import { TenantConfigurationController } from '../ui-tenant-configuration-v2-controller.service';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'crm-configurations-form',
  templateUrl: './configurations-form.component.html',
  styleUrls: ['./configurations-form.component.scss'],
})
export class ConfigurationsFormComponent implements OnInit {
  constructor(
    private snackbarService: SnackbarService,
    private tenantConfigurationController: TenantConfigurationController,
    public changeDetectorRef: ChangeDetectorRef
  ) {}

  @Input() tenantConfiguration$: BehaviorSubject<TenantConfigurationResponse[]>;

  formGroup: UntypedFormGroup;
  faCircleInfo = faCircleInfo;

  ngOnInit() {
    this.tenantConfiguration$.subscribe((res) => {
      this.initForm(res);
    });
  }

  initForm(tenantConfigurationResponse: TenantConfigurationResponse[]) {
    this.formGroup = new UntypedFormGroup({});

    tenantConfigurationResponse.forEach((configuration) => {
      let value;
      if (configuration.value === null && configuration.image === null) {
        value = configuration.defaultValue;
      } else {
        if (configuration.type === 'BOOLEAN') {
          if (configuration.value == 'true') value = true;
          if (configuration.value != 'true') value = false;
        }

        if (configuration.type === 'INTEGER') {
          value = +configuration.value;
        }

        if (
          configuration.type == 'EMAIL' ||
          configuration.type == 'TEXT' ||
          configuration.type == 'SELECT'
        ) {
          value = configuration.value;
        }

        if (configuration.type == 'IMAGE') {
          value = configuration.image;
        }
      }

      const control = new UntypedFormControl(
        value,
        this.loadValidators(configuration)
      );
      this.formGroup.addControl(configuration.label, control);
    });

    this.formGroup.valueChanges.pipe(debounceTime(800)).subscribe((e) => {
      this.submit();
    });
  }

  loadValidators(configuration: TenantConfigurationResponse) {
    const { required, email, max, min } = Validators;
    const validators: ValidatorFn[] = [];
    if (configuration.required && configuration.type != 'BOOLEAN') {
      validators.push(required);
    }
    if (configuration.type === 'EMAIL') validators.push(this.emailValidator());
    if (configuration.type === 'INTEGER') {
      validators.push(max(configuration.options.max || undefined));
      validators.push(min(configuration.options.min || 0));
    }
    return validators;
  }

  emailValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const emailRegex = new RegExp(/^[\w-\.]+@([\w-]+\.)+[a-zA-Z]{2,4}$/);
      const valid = emailRegex.test(control.value);
      return valid ? null : { invalidEmail: { value: control.value } };
    };
  }

  getOnlyChangedFields() {
    const changedFields: { [keyName: string]: { value?: any; image?: any } } =
      {};

    Object.keys(this.formGroup.controls).forEach((key) => {
      let currentControl = this.formGroup.controls[key];
      if (currentControl.dirty) {
        const configurationData = this.tenantConfiguration$.value.find(
          (configurationData) => configurationData.label === key
        );

        if (configurationData.type == 'IMAGE') {
          changedFields[key] = {
            image: currentControl.value,
          };
        } else {
          changedFields[key] = {
            value: currentControl.value,
          };
        }
        configurationData.type;
      }
    });
    return changedFields;
  }

  getFieldErrorMessage(controlName: string) {
    const errors = this.formGroup.controls[controlName].errors;
    if (!errors) return;

    if (errors.required) return 'error:fieldRequired';
    if (errors.email) return 'error:fieldEmailInvalid';
    if (errors.max) return 'error:fieldNumberMaxExceeded';
    if (errors.min) return 'error:fieldNumberMinExceeded';
    if (errors.size) return 'error:fieldFileSizeExceeded';
    if (errors.formatFile) return 'error:fieldFileFormatNotValid';

    return 'error:fieldInvalid';
  }

  uploadImage(event, formControlName) {
    const file = event.target.files[0];
    this.formGroup.controls[formControlName].setValue(file);
    this.formGroup.controls[formControlName].markAsDirty();
  }

  toFormData(formValue: {
    [p: string]: { value?: any; image?: any };
  }): FormData {
    const formData = new FormData();

    for (const key of Object.keys(formValue)) {
      const tenantConfigurationRaw = formValue[key];
      let tenantConfiguration = `configurationValues[${key}]`;
      let value = null;
      if (tenantConfigurationRaw.value != null) {
        tenantConfiguration += '.value';
        value = tenantConfigurationRaw.value;
      } else if (tenantConfigurationRaw.image != null) {
        tenantConfiguration += '.image';
        value = tenantConfigurationRaw.image;
      }
      formData.append(tenantConfiguration, value);
    }

    return formData;
  }

  updateValues(response: TenantConfigurationResponse[]) {
    response.forEach((configuration) => {
      let value;
      if (configuration.value === null && configuration.image === null) {
        value = configuration.defaultValue;
      } else {
        if (configuration.type === 'BOOLEAN') {
          if (configuration.value == 'true') value = true;
          if (configuration.value != 'true') value = false;
        }

        if (configuration.type === 'INTEGER') {
          value = +configuration.value;
        }

        if (
          configuration.type == 'EMAIL' ||
          configuration.type == 'TEXT' ||
          configuration.type == 'SELECT'
        ) {
          value = configuration.value;
        }

        if (configuration.type == 'IMAGE') {
          value = configuration.image;
        }
      }

      this.formGroup.controls[configuration.label].setValue(value, {
        emitEvent: false,
      });
    });
  }

  submit() {
    this.formGroup.markAllAsTouched();
    this.changeDetectorRef.detectChanges();

    if (!this.formGroup.valid) {
      return this.snackbarService.openErrorFormMissingFields();
    }

    const changedFields = this.getOnlyChangedFields();

    if (Object.keys(changedFields).length == 0) {
      return this.snackbarService.openErrorFormNothingChanged();
    }

    const oldValue = [...this.tenantConfiguration$.value];
    const newValue = [...this.tenantConfiguration$.value];

    this.tenantConfigurationController
      .updateTenantConfigurationInBatch(this.toFormData(changedFields))
      .subscribe(
        ({ results }) => {
          results.forEach((configurationUpdated) => {
            const indexToUpdate = newValue.findIndex(
              (oldConfig) => oldConfig.id == configurationUpdated.id
            );
            newValue[indexToUpdate] = configurationUpdated;
          });

          this.updateValues(newValue);
        },
        (res) => {
          if (!res.ok) {
            this.tenantConfiguration$.next(oldValue);
          }
        }
      );
  }
}
