import { Injectable } from '@angular/core';
import {
  Contact,
  UnsavedContact,
  ContactsEndpoint,
} from '@fullyops/legacy/data';
import { UiService } from './ui.service';

@Injectable({
  providedIn: 'root',
})
export class UiContactsService extends UiService<
  Contact,
  UnsavedContact,
  ContactsEndpoint
> {
  constructor(protected contactsEndpoint: ContactsEndpoint) {
    super(contactsEndpoint.createStore(), contactsEndpoint);
  }

  getAll(filters) {
    return this.contactsEndpoint.getAll(filters);
  }
}
