<div class="ticket" [attr.data-priority]="ticket.priority">
  <div class="header">
    <div class="name">
      <span [attr.data-testid]="'pipeline-ticket-identifier'"
        >[{{ ticket.identifier }}]
      </span>
      <span [attr.data-testid]="'pipeline-ticket-name'">{{ ticket.name }}</span>
      <mat-icon *ngIf="ticket.warranty" class="star">star</mat-icon>
    </div>
    <div class="company">
      <span
        class="primary-color"
        *ngIf="ticket.company"
        [attr.data-testid]="'pipeline-ticket-company'"
      >
        {{ ticket.company?.name }}
      </span>
      <span
        class="primary-color"
        *ngIf="ticket.supplier"
        [attr.data-testid]="'pipeline-ticket-company'"
      >
        {{ ticket.supplier?.name }}
      </span>
    </div>
  </div>
  <div class="ticket-row">
    <div class="priority">
      <img
        [attr.alt]="ticket.priority"
        [src]="
          'assets/svg/priorities/' +
          ticket.priority.toLocaleLowerCase() +
          '.svg'
        "
      /><span> {{ ticket.priority | i18nextCap }}</span>
    </div>

    <div *ngIf="ticket.interventionType">
      <span>
        {{ ticket.interventionType | i18nextCap }}
      </span>
    </div>
  </div>
  <div class="horizontal-line"></div>
  <div class="footer">
    <div class="assignees">
      <crm-assignees-icon-list
        [assignees]="ticket.assignees"
        [size]="25"
      ></crm-assignees-icon-list>
    </div>
    <div class="act-at">
      <span *ngIf="ticket.actAt || ticket.saleDate">{{
        ticket.actAt || ticket.saleDate
          | momentTimezoneToAngularTimezone: 'D MMM YYYY'
      }}</span>
    </div>
  </div>
</div>
