import { Permission } from './Permission';
import { ISerialisedRole } from './serialisations/ISerialisedRole';

export class Role {
  constructor(
    readonly id: string,
    readonly name: string,
    readonly permissions: Permission[],
    readonly deleted: boolean
  ) {
    if (name) this.name = name.trim();
  }

  static fromSerialised(serialised: ISerialisedRole) {
    const permissions = serialised?.permissions.map(Permission.fromSerialised);

    return new Role(
      serialised?.id,
      serialised?.name,
      permissions,
      serialised.deleted
    );
  }

  toSerialised(): ISerialisedRole {
    const permissionsObj = this.permissions.map((permissions) =>
      permissions.toSerialised()
    );

    return {
      id: this.id,
      name: this.name,
      permissions: permissionsObj,
      deleted: this.deleted,
    };
  }

  clone(partialRole: PartialRole): Role {
    const resolve = (key: keyof Role) =>
      partialRole.hasOwnProperty(key) ? partialRole[key] : this[key];

    return new Role(
      resolve('id'),
      resolve('name'),
      resolve('permissions'),
      resolve('deleted')
    );
  }
}
type PartialRole = Partial<
  Pick<Role, 'id' | 'name' | 'permissions' | 'deleted'>
>;
