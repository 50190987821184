import { ComponentCanDeactivate } from './component-can-deactivate';
import { NgForm } from '@angular/forms';

export abstract class FormCanDeactivate extends ComponentCanDeactivate {
  abstract get formGroupDirective(): NgForm;

  canDeactivate(): boolean {
    return this.formGroupDirective.submitted || !this.formGroupDirective.dirty;
  }
}
