import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import {
  FieldType,
  ActionButtons,
  PermissionType,
  RoleGroupColor,
} from '@fullyops/legacy/ui/ui-shared/utils/crm-types';
import { ComponentInOutAnimation } from '@fullyops/legacy/ui/ui-shared/utils/component-base-animation';
import { Label, Response, ResponseState, User } from '@fullyops/legacy/data';
import { map, filter } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { UserControllerV2 } from '../ui-user-controller-v2.service';
import { UserResponse } from '@fullyops/legacy/data/api/types/User';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import {
  faGear,
  faHelmetSafety,
  faTags,
  faUserGear,
  faUserTie,
  faWrench,
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'crm-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
  animations: [ComponentInOutAnimation.AnimTrigger],
})
export class UsersComponent implements OnInit {
  constructor(
    private userControllerV2: UserControllerV2,
    protected router: Router
  ) {}
  @Input() title: string;

  faTags = faTags;

  actions: ActionButtons = {};

  usersV2$ = new BehaviorSubject<UserResponse[]>([]);
  usersV2Filtered$ = new BehaviorSubject<UserResponse[]>([]);
  searchUser = new FormControl('');

  rolesIcons = {
    ADMIN: {
      icon: faUserGear,
      color: '#5D8FCA',
    },
    SALESMAN: { icon: faTags, color: '#00CAD6' },
    MANAGER: { icon: faUserTie, color: '#7E72B9' },
    TECHNICIAN: { icon: faHelmetSafety, color: '#2CE1B6' },
    INSTALLER: { icon: faWrench },
  };

  ngOnInit() {
    this.initActionButtons();

    this.loadUsers();

    this.filterUser();
  }

  filterUser() {
    this.searchUser.valueChanges.subscribe((e) => {
      const usersFiltered = this.usersV2$.value.filter((user) => {
        const fullName = user.firstName + user.lastName;
        return fullName.toLowerCase().includes(e.toLowerCase());
      });
      this.usersV2Filtered$.next(usersFiltered);
    });
  }

  loadUsers() {
    this.userControllerV2.getUsers({ queryParameters: {} }).subscribe((res) => {
      this.usersV2$.next(res.results);
      this.usersV2Filtered$.next(res.results);
    });
  }

  initActionButtons() {
    this.actions.primary = {
      label: 'addNew',
      color: 'primary',
      permissions: [PermissionType.CAN_EDIT_USERS],
    };
  }

  onActionEvent(event: string) {
    if (event === 'addNew') {
      this.router.navigate([`/users/new`]);
    }
  }

  openUser(userId: string) {
    this.router.navigate([`/users/${userId}/edit`]);
  }

  getRoleIcon(roleName: string) {
    return this.rolesIcons[roleName]?.icon || null;
  }

  getRoleColor(roleName: string) {
    return this.rolesIcons[roleName]?.color || '#5D8FCA';
  }

  getUserSortedRoles(user: UserResponse) {
    return user.roles.map((role) => role.name).sort();
  }
}
