<div class="subsection-item">
  <crm-upload-image
    [disabled]="preview"
    [uploadService]="uploadService"
    [imageControl]="urlControl"
  >
  </crm-upload-image>
  <div class="container">
    <span>{{ 'forms:description' | i18nextCap }}</span>
    <p *ngIf="!data.controls.length">
      {{ 'error:subsectionHasNoDetails' | i18next }}
    </p>
    <div cdkDropList class="list" (cdkDropListDropped)="drop($event)">
      <div
        class="list-item"
        formArraryName="data"
        *ngFor="let dataLine of data.controls; let i = index"
        cdkDrag
        [cdkDragStartDelay]="150"
      >
        <textarea matInput [formControl]="$any(dataLine)"></textarea>
        <button
          *ngIf="!preview"
          mat-icon-button
          color="warn"
          type="button"
          (click)="removeLine(i)"
        >
          <mat-icon>clear</mat-icon>
        </button>
      </div>
    </div>
    <button
      *ngIf="!preview"
      class="new-line"
      mat-button
      type="button"
      color="primary"
      (click)="createEntry()"
    >
      {{ 'forms:addLine' | i18nextCap }}
    </button>
  </div>
</div>
