import { UiServiceV2 } from './ui.service-v2.service';
import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { API_SERVICE_CONFIG } from '../../data/api/ApiServiceConfig';
import { IApiServiceConfig } from '../../data/api/IApiServiceConfig';
import { InterventionTypeResponse } from '../../data/api/types/Intervention';
import { SupportTicketScopeResponse } from '../../data/api/types/SupportTicket';
import { UiServiceWithCache } from './ui.service-with-cache.service';
import { BehaviorSubject } from 'rxjs';
import { SelectOptionType } from '../ui-shared/form-select/form-select.component';
import * as moment from 'moment';
import { FormControl } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class SupportTicketScopeController extends UiServiceWithCache {
  constructor(
    @Inject(API_SERVICE_CONFIG) private config: IApiServiceConfig,
    private http: HttpClient
  ) {
    super();
    this.updateSelectOptions();
  }

  private readonly url =
    this.config.url + 'tickets/multimachinesupports/scopes';

  protected cacheData = {
    scopes$: new BehaviorSubject<SupportTicketScopeResponse[]>([]),
    scopesSelectOptions$: new BehaviorSubject<SelectOptionType[]>([]),
  };

  protected getAll() {
    if (!this.isCacheValid()) {
      const response = this.http.get<SupportTicketScopeResponse[]>(
        `${this.url}`
      );
      response.subscribe((e) => {
        this.cacheData.scopes$.next(e);
        this.cacheTime = moment();
      });
    }
  }

  protected updateSelectOptions() {
    this.cacheData.scopes$.subscribe((e) => {
      this.cacheData.scopesSelectOptions$.next(
        e.map(({ label, active }) => ({ id: label, name: label, active }))
      );
    });
  }

  getScopesSelectOptions$() {
    this.getAll();
    return this.cacheData.scopesSelectOptions$;
  }

  getScopes$() {
    this.getAll();
    return this.cacheData.scopes$;
  }

  setDefaultValueOnForm({ formControl }: { formControl: FormControl }) {
    this.getAll();
    this.cacheData.scopes$.subscribe((value) => {
      if (!value) return;
      const defaultValue = value.find(({ isDefault }) => isDefault)?.label;
      if (defaultValue) formControl.setValue(defaultValue);
    });
  }
}
