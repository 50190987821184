import { UiServiceV2 } from './ui.service-v2.service';
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_SERVICE_CONFIG } from '@fullyops/legacy/data/api/ApiServiceConfig';
import { IApiServiceConfig } from '@fullyops/legacy/data/api/IApiServiceConfig';
import {
  ActivityPatchRequest,
  ActivityPostRequest,
  ActivityResponse,
} from '@fullyops/legacy/data/api/types/Activity';
import { PageResponseWrapper } from '@fullyops/legacy/data/api/types/HttpResponses';

export type QueryParams = {
  activityType?: string;
  assigneeIds?: string[];
  companyIds?: string[];
  contactIds?: string[];
  createdAt?: string;
  createdBy?: string;
  endsAt?: string;
  groupBy?: string;
  identifier?: string;
  includeDeleted?: boolean;
  name?: string;
  occurringIn?: string;
  order?: 'ASC' | 'DESC';
  orderBy?: string;
  page?: number;
  pageNumber?: number;
  pageSize?: number;
  size?: number;
  startsAt?: string;
  ticketId?: string;
  ticketName?: string;
  ticketType?: 'REQUEST' | 'SALE' | 'SUPPORT' | 'INSTALLATION';
  updatedBy?: string;
};

@Injectable({
  providedIn: 'root',
})
export class ActivityControllerV2 extends UiServiceV2 {
  constructor(
    private http: HttpClient,
    @Inject(API_SERVICE_CONFIG) private config: IApiServiceConfig
  ) {
    super();
  }

  private readonly endpointUrl = this.config.url + 'activities';

  getActivities(params: { queryParams: QueryParams }) {
    let query = '';
    if (Object.keys(params.queryParams).length > 0) {
      query = `?${this.createQueryParams(params.queryParams)}`;
    }
    return this.http.get<PageResponseWrapper<ActivityResponse>>(
      `${this.endpointUrl}${query}`
    );
  }

  createActivity(params: { newActivity: ActivityPostRequest }) {
    return this.http.post<ActivityResponse>(
      `${this.endpointUrl}`,
      params.newActivity
    );
  }

  getActivityById(params: { activityId: string }) {
    return this.http.get<ActivityResponse>(
      `${this.endpointUrl}/${params.activityId}`
    );
  }

  updateTicketActivityById(params: { activity: ActivityPatchRequest }) {
    return this.http.patch<ActivityResponse>(
      `${this.endpointUrl}/${params.activity.id}`,
      params.activity
    );
  }

  deleteActivity(params: { activityId: string }) {
    return this.http.delete(`${this.endpointUrl}/${params.activityId}`);
  }
}
