<div class="simulations">
  <h1 class="primary">{{ 'simulationsSummary' | i18nextCap }}</h1>
  <div class="timeline-container" [@inOut]="'in'">
    <ng-container>
      <ul>
        <ng-container *ngIf="simulations$ | async as simulations">
          <li class="simulation" *ngFor="let simulation of simulations">
            <div>
              <label
                >{{ simulation.name }} ({{ 'simulation' | i18nextCap }})</label
              >
              <label>{{ simulation.product.name }}</label>
            </div>
            <span>{{ simulation.total }}€</span>
          </li>
        </ng-container>
        <ng-container *ngIf="productsConfigs$ | async as productsConfigs">
          <li class="simulation" *ngFor="let config of productsConfigs">
            <div>
              <label>{{ config.name }}</label>
              <label>{{ config.product.name }}</label>
            </div>
            <span>{{ config.total }}€</span>
          </li>
        </ng-container>
        <mat-divider></mat-divider>
        <li class="price">
          <label>Total</label>
          <span class="final">{{ totalCost }} €</span>
        </li>
      </ul>
    </ng-container>
  </div>
</div>
