import { ISerialisedTicketSupport } from './serialisations/ISerialisedTicketSupport';
import { Contact } from './Contact';
import { Company } from './Company';
import { User } from './User';
import { UnsavedTicketSupport } from './UnsavedTicketSupport';
import { ClientInfo } from './ClientInfo';
import { TicketRequest } from './TicketRequest';
import { Status } from './Status';
import { Travel } from './Travel';
import { WorkPeriod } from './WorkPeriod';
import { InterventionUserParts } from './InterventionUserParts';
import { UnsavedInterventionUserParts } from './UnsavedInterventionUserParts';
import { TicketRequestPart } from './TicketRequestPart';

export class TicketSupport {
  constructor(
    readonly id: string,
    readonly identifier: number,
    readonly name: string,
    readonly priority: string,
    readonly status: Status,
    readonly assignees: User[],
    readonly company: Company,
    readonly contacts: Contact[],
    readonly clientInfo: ClientInfo,
    readonly anomalies: string,
    readonly anomalyType: string,
    readonly equipmentData: string,
    readonly internalObservation: string,
    readonly malfunctionType: string,
    readonly workDone: string,
    readonly requests: TicketRequest[],
    readonly photos: string[],
    readonly createdAt: Date,
    readonly actAt: Date | null,
    readonly executedAt: Date | null,
    readonly closedAt: Date | null,
    readonly warranty: boolean,
    readonly finished: boolean,
    readonly brand: string,
    readonly model: string,
    readonly serialNumber: string,
    readonly type: string,
    readonly userObservation: string,
    readonly parts: TicketRequestPart[],
    readonly travels: Travel[],
    readonly workPeriods: WorkPeriod[],
    readonly userParts: { [key: string]: InterventionUserParts },
    readonly unregisteredParts: string,
    readonly createdBy: User
  ) {
    if (name) this.name = name.trim();
    if (priority) this.priority = priority.trim();
    if (anomalies) this.anomalies = anomalies.trim();
    if (anomalyType) this.anomalyType = anomalyType.trim();
    if (equipmentData) this.equipmentData = equipmentData.trim();
    if (internalObservation)
      this.internalObservation = internalObservation.trim();
    if (malfunctionType) this.malfunctionType = malfunctionType.trim();
    if (workDone) this.workDone = workDone.trim();
    if (brand) this.brand = brand.trim();
    if (model) this.model = model.trim();
    if (serialNumber) this.serialNumber = serialNumber.trim();
    if (type) this.type = type.trim();
    if (userObservation) this.userObservation = userObservation.trim();
    if (unregisteredParts) this.unregisteredParts = unregisteredParts.trim();
  }

  static fromSerialised(serialised: ISerialisedTicketSupport) {
    const status = Status.fromSerialised(serialised.status);
    const company = Company.fromSerialised(serialised.company);
    const clientInfo = ClientInfo.fromSerialised(serialised.clientInfo);
    const requests = serialised.requests.map(TicketRequest.fromSerialised);

    const assignees = serialised.assignees.map(User.fromSerialised);
    const contacts = serialised.contacts.map(Contact.fromSerialised);
    const parts = serialised.parts.map(TicketRequestPart.fromSerialised);
    const travels = serialised.travels.map(Travel.fromSerialised);
    const workPeriods = serialised.workPeriods.map(WorkPeriod.fromSerialised);

    const userParts = {};
    Object.keys(serialised.userParts).forEach(
      (key) =>
        (userParts[key] = InterventionUserParts.fromSerialised(
          serialised.userParts[key]
        ))
    );

    return new TicketSupport(
      serialised.id,
      serialised.identifier,
      serialised.name,
      serialised.priority,
      status,
      assignees,
      company,
      contacts,
      clientInfo,
      serialised.anomalies,
      serialised.anomalyType,
      serialised.equipmentData,
      serialised.internalObservation,
      serialised.malfunctionType,
      serialised.workDone ? serialised.workDone : '',
      requests,
      serialised.photos ? serialised.photos : [],
      new Date(serialised.createdAt),
      serialised.actAt ? new Date(serialised.actAt) : null,
      serialised.executedAt ? new Date(serialised.executedAt) : null,
      serialised.closedAt ? new Date(serialised.closedAt) : null,
      serialised.warranty,
      serialised.finished,
      serialised.brand,
      serialised.model,
      serialised.serialNumber,
      serialised.type,
      serialised.userObservation,
      parts,
      travels,
      workPeriods,
      userParts,
      serialised.unregisteredParts,
      User.fromSerialised(serialised.createdBy)
    );
  }

  toSerialised(): ISerialisedTicketSupport {
    const statusObj = this.status.toSerialised();
    const companyObj = this.company.toSerialised();
    const clientInfoObj = this.clientInfo.toSerialised();
    const requestsObj = this.requests.map((request) => request.toSerialised());

    const assigneesObj = this.assignees.map((assignee) =>
      assignee.toSerialised()
    );
    const contactsObj = this.contacts.map((contact) => contact.toSerialised());
    const partsObj = this.parts.map((part) => part.toSerialised());
    const travelsObj = this.travels.map((travel) => travel.toSerialised());
    const workPeriodsObj = this.workPeriods.map((period) =>
      period.toSerialised()
    );

    const userPartsObj = {};
    Object.keys(this.userParts).forEach(
      (key) => (userPartsObj[key] = this.userParts[key].toSerialised())
    );

    return {
      id: this.id,
      identifier: this.identifier,
      name: this.name,
      priority: this.priority,
      status: statusObj,
      assignees: assigneesObj,
      company: companyObj,
      contacts: contactsObj,
      clientInfo: clientInfoObj,
      anomalies: this.anomalies,
      anomalyType: this.anomalyType,
      equipmentData: this.equipmentData,
      internalObservation: this.internalObservation,
      malfunctionType: this.malfunctionType,
      workDone: this.workDone,
      requests: requestsObj,
      photos: this.photos,
      createdAt: this.createdAt.toISOString(),
      actAt: this.actAt ? this.actAt.toISOString() : null,
      executedAt: this.executedAt ? this.executedAt.toISOString() : null,
      closedAt: this.closedAt ? this.closedAt.toISOString() : null,
      warranty: this.warranty,
      finished: this.finished,
      brand: this.brand,
      model: this.model,
      serialNumber: this.serialNumber,
      type: this.type,
      userObservation: this.userObservation,
      parts: partsObj,
      travels: travelsObj,
      workPeriods: workPeriodsObj,
      userParts: userPartsObj,
      unregisteredParts: this.unregisteredParts,
      createdBy: this.createdBy,
    };
  }

  toDraft(): UnsavedTicketSupport {
    const supportTicket = this.toSerialised();
    const userParts = {};
    Object.keys(this.userParts).forEach(
      (key) =>
        (userParts[key] = UnsavedInterventionUserParts.fromSerialised(
          this.userParts[key].toDraft()
        ))
    );

    return UnsavedTicketSupport.fromSerialised({
      ...supportTicket,
      statusId: supportTicket.status.id,
      companyId: supportTicket.company.id,
      contactIds: supportTicket.contacts.map((contact) => contact.id),
      assigneeIds: supportTicket.assignees.map((assignee) => assignee.id),
      parts: this.parts.map((part) => part.toDraft()),
      userParts,
    });
  }

  clone(partialTicketSupport: PartialTicketSupport): TicketSupport {
    const resolve = (key: keyof TicketSupport) =>
      partialTicketSupport.hasOwnProperty(key)
        ? partialTicketSupport[key]
        : this[key];

    return new TicketSupport(
      resolve('id'),
      resolve('identifier'),
      resolve('name'),
      resolve('priority'),
      resolve('status'),
      resolve('assignees'),
      resolve('company'),
      resolve('contacts'),
      resolve('clientInfo'),
      resolve('anomalies'),
      resolve('anomalyType'),
      resolve('equipmentData'),
      resolve('internalObservation'),
      resolve('malfunctionType'),
      resolve('workDone'),
      resolve('requests'),
      resolve('photos'),
      resolve('createdAt'),
      resolve('actAt'),
      resolve('executedAt'),
      resolve('closedAt'),
      resolve('warranty'),
      resolve('finished'),
      resolve('brand'),
      resolve('model'),
      resolve('serialNumber'),
      resolve('type'),
      resolve('userObservation'),
      resolve('parts'),
      resolve('travels'),
      resolve('workPeriods'),
      resolve('userParts'),
      resolve('unregisteredParts'),
      resolve('createdBy')
    );
  }
}

type PartialTicketSupport = Partial<
  Pick<
    TicketSupport,
    | 'id'
    | 'identifier'
    | 'name'
    | 'priority'
    | 'status'
    | 'assignees'
    | 'company'
    | 'contacts'
    | 'clientInfo'
    | 'anomalies'
    | 'parts'
    | 'anomalyType'
    | 'travels'
    | 'equipmentData'
    | 'internalObservation'
    | 'malfunctionType'
    | 'workDone'
    | 'requests'
    | 'photos'
    | 'createdAt'
    | 'actAt'
    | 'executedAt'
    | 'closedAt'
    | 'warranty'
    | 'finished'
    | 'brand'
    | 'model'
    | 'serialNumber'
    | 'type'
    | 'userObservation'
    | 'workPeriods'
    | 'userParts'
    | 'unregisteredParts'
    | 'createdBy'
  >
>;
