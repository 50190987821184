import { ReportType } from '../../api/types/Report';
import { ISerialisedReport } from './serialisations/ISerialisedReport';

export class Report {
  constructor(
    readonly id: string,
    readonly type: ReportType,
    readonly url: string,
    readonly internalUrl: string,
    readonly createdAt: Date,
    readonly updatedAt: Date
  ) {}

  static fromSerialised(serialised: ISerialisedReport) {
    return new Report(
      serialised.id,
      serialised.type,
      serialised.url,
      serialised.internalUrl,
      new Date(serialised.createdAt),
      new Date(serialised.updatedAt)
    );
  }

  toSerialised(): ISerialisedReport {
    return {
      id: this.id,
      type: this.type,
      url: this.url,
      internalUrl: this.internalUrl,
      createdAt: this.createdAt.toISOString(),
      updatedAt: this.updatedAt.toISOString(),
    };
  }
  clone(partialReport: PartialReport): Report {
    const resolve = (key: keyof Report) =>
      partialReport.hasOwnProperty(key) ? partialReport[key] : this[key];

    return new Report(
      resolve('id'),
      resolve('type'),
      resolve('url'),
      resolve('internalUrl'),
      resolve('createdAt'),
      resolve('updatedAt')
    );
  }
}
type PartialReport = Partial<
  Pick<
    Report,
    'id' | 'type' | 'url' | 'internalUrl' | 'createdAt' | 'updatedAt'
  >
>;
