<button
  mat-flat-button
  class="mat-flat-button-style"
  [ngClass]="getClass()"
  type="button"
  (click)="buttonClick()"
  [disabled]="this.isDisabled"
>
  {{ 'forms:' + text | i18nextCap }}
</button>
