import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import {
  UntypedFormGroup,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { UnsavedUser } from '@fullyops/legacy/data';

class UserForm extends FormGroup {
  constructor(user: UnsavedUser, dis: boolean) {
    super({});
    this.addControl(
      'firstName',
      new FormControl({ value: user.firstName, disabled: dis }, [
        Validators.required,
      ])
    );
    this.addControl(
      'lastName',
      new FormControl({ value: user.lastName, disabled: dis }, [
        Validators.required,
      ])
    );
    this.addControl(
      'username',
      new FormControl({ value: user.username, disabled: dis }, [])
    );
    this.addControl(
      'email',
      new FormControl({ value: user.email, disabled: dis }, [
        Validators.required,
        Validators.email,
      ])
    );
    this.addControl(
      'roles',
      new FormControl({ value: user.roles, disabled: dis }, [
        Validators.required,
      ])
    );
    this.addControl(
      'phone',
      new FormControl({ value: user.phone, disabled: dis }, [])
    );
    this.addControl(
      'active',
      new FormControl({ value: user.active, disabled: dis }, [
        Validators.required,
      ])
    );
  }
}

@Injectable()
export class UserFormService {
  private initialUser: UnsavedUser;
  private form: BehaviorSubject<UntypedFormGroup> =
    new BehaviorSubject<UntypedFormGroup>(new UntypedFormGroup({}));

  form$: Observable<UntypedFormGroup> = this.form.asObservable();

  constructor() {}

  initForm(draftUser: UnsavedUser, disabled: boolean) {
    this.initialUser = draftUser;
    this.form.next(new UserForm(draftUser, disabled));
  }

  getDraft() {
    const userDraft = (this.form.getValue() as UntypedFormGroup).value;
    return this.initialUser.clone({
      firstName: userDraft.firstName,
      lastName: userDraft.lastName,
      email: userDraft.email,
      phone: userDraft.phone,
      username: userDraft.username,
      password: userDraft.password,
      roles: userDraft.roles,
      active: userDraft.active,
    });
  }

  markAllAsTouched() {
    const currentUser = this.form.getValue();
    currentUser.markAllAsTouched();

    this.form.next(currentUser);
  }
}
