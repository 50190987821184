import { Response } from '../Response';
import { Endpoint } from '../Endpoint';
import { HttpClient } from '@angular/common/http';
import { IListResponse } from '../IListResponse';
import { Injectable, Inject } from '@angular/core';
import { API_SERVICE_CONFIG } from '../ApiServiceConfig';

import { Observable, BehaviorSubject } from 'rxjs';

import { UserPart } from '../../models/v0/UserPart';
import { ISerialisedUserPart } from '../../models/v0/serialisations/ISerialisedUserPart';
import { IApiServiceConfig } from '../IApiServiceConfig';
import { UnsavedUserPart } from '../../models/v0/UnsavedUserPart';
import { map, tap } from 'rxjs/operators';

type UserPartList = IListResponse<ISerialisedUserPart>;

@Injectable()
export class UserPartsEndpoint extends Endpoint<UserPart, ISerialisedUserPart> {
  private readonly url = this.config.url + 'users/parts/';

  constructor(
    @Inject(API_SERVICE_CONFIG) private config: IApiServiceConfig,
    private http: HttpClient
  ) {
    super(UserPart.fromSerialised);
  }

  getAll(
    filter: {},
    source?: Observable<UserPartList>,
    store?: BehaviorSubject<Response<UserPart[]>>
  ): Observable<Response<UserPart[]>> {
    const defaultSource = this.http.get<UserPartList>(`${this.url}`);

    return this.list(source || defaultSource, store);
  }

  get(
    userId: string,
    source?: Observable<UserPartList>,
    store?: BehaviorSubject<Response<UserPart[]>>
  ): Observable<Response<UserPart[]>> {
    const defaultSource = this.http.get<UserPartList>(`${this.url}${userId}/`);

    return this.list(source || defaultSource, store);
  }

  post(
    userId: string,
    userPart: UnsavedUserPart,
    source?: Observable<ISerialisedUserPart>,
    store?: BehaviorSubject<Response<UserPart[]>>
  ): Observable<Response<UserPart>> {
    const defaultSource = this.http.post<ISerialisedUserPart>(
      `${this.url}${userId}/`,
      userPart.toSerialised()
    );
    return this.add(source || defaultSource, store);
  }

  patch(
    userId: string,
    userPart: UnsavedUserPart,
    source?: Observable<ISerialisedUserPart>,
    store?: BehaviorSubject<Response<UserPart[]>>
  ): Observable<Response<UserPart>> {
    const { ...body } = userPart.toSerialised();
    const defaultSource = this.http.patch<ISerialisedUserPart>(
      `${this.url}${userId}/`,
      body
    );

    return defaultSource.pipe(
      tap((instance) =>
        this.update(
          source || defaultSource,
          UserPart.fromSerialised(instance),
          store
        )
      ),
      map((serialiser) => new Response(UserPart.fromSerialised(serialiser)))
    );
  }

  delete(
    userId: string,
    partId: string,
    source?: Observable<{}>,
    store?: BehaviorSubject<Response<UserPart[]>>
  ): Observable<Response<{}>> {
    const defaultSource = this.http.delete<{}>(
      `${this.url}${userId}/${partId}/`
    );
    return this.remove(source || defaultSource, userId + partId, store);
  }
}
