import { Response } from '../Response';
import { Endpoint } from '../Endpoint';
import { HttpClient } from '@angular/common/http';
import { IListResponse } from '../IListResponse';
import { Injectable, Inject } from '@angular/core';
import { API_SERVICE_CONFIG } from '../ApiServiceConfig';

import { Observable, BehaviorSubject } from 'rxjs';

import { IApiServiceConfig } from '../IApiServiceConfig';
import { map, tap } from 'rxjs/operators';
import {
  InterventionClientEquipment,
  UnsavedInterventionClientEquipment,
} from '@fullyops/legacy/data';
import { ISerialisedInterventionClientEquipment } from '../../models/v0/serialisations/ISerialisedInterventionClientEquipment';

type InterventionClientEquipmentList =
  IListResponse<ISerialisedInterventionClientEquipment>;

@Injectable()
export class InterventionsClientEquipmentEndpoint extends Endpoint<
  InterventionClientEquipment,
  ISerialisedInterventionClientEquipment
> {
  private readonly url = this.config.url + 'interventions/equipments';

  constructor(
    @Inject(API_SERVICE_CONFIG) private config: IApiServiceConfig,
    private http: HttpClient
  ) {
    super(InterventionClientEquipment.fromSerialised);
  }

  getAll(
    filters: {},
    source?: Observable<InterventionClientEquipmentList>,
    store?: BehaviorSubject<Response<InterventionClientEquipment[]>>
  ): Observable<Response<InterventionClientEquipment[]>> {
    let query = '';
    Object.keys(filters).forEach((key) => (query += `&${key}=${filters[key]}`));

    const defaultSource = this.http.get<InterventionClientEquipmentList>(
      `${this.url}?size=100&page=0${query}`
    );
    return this.list(source || defaultSource, store);
  }

  get(
    id: string,
    source?: Observable<ISerialisedInterventionClientEquipment>,
    store?: BehaviorSubject<Response<InterventionClientEquipment[]>>
  ): Observable<Response<InterventionClientEquipment>> {
    const defaultSource = this.http.get<ISerialisedInterventionClientEquipment>(
      `${this.url}${id}/`
    );
    return this.retrieve(source || defaultSource, store);
  }

  post(
    interventionClientEquipment: UnsavedInterventionClientEquipment,
    source?: Observable<ISerialisedInterventionClientEquipment>,
    store?: BehaviorSubject<Response<InterventionClientEquipment[]>>
  ): Observable<Response<InterventionClientEquipment>> {
    const body = {
      equipmentId: interventionClientEquipment.clientEquipmentId,
      interventionId: interventionClientEquipment.interventionId,
    };
    const defaultSource =
      this.http.post<ISerialisedInterventionClientEquipment>(this.url, body);
    return this.add(source || defaultSource, store);
  }

  patch(
    id: string,
    part: UnsavedInterventionClientEquipment,
    source?: Observable<ISerialisedInterventionClientEquipment>,
    store?: BehaviorSubject<Response<InterventionClientEquipment[]>>
  ): Observable<Response<InterventionClientEquipment>> {
    const { ...body } = part.toSerialised();
    const defaultSource =
      this.http.patch<ISerialisedInterventionClientEquipment>(
        `${this.url}${id}/`,
        body
      );

    return defaultSource.pipe(
      tap((instance) =>
        this.update(
          source || defaultSource,
          InterventionClientEquipment.fromSerialised(instance),
          store
        )
      ),
      map(
        (serialiser) =>
          new Response(InterventionClientEquipment.fromSerialised(serialiser))
      )
    );
  }

  delete(
    interventionId: string,
    clientEquipmentId: string,
    source?: Observable<{}>,
    store?: BehaviorSubject<Response<InterventionClientEquipment[]>>
  ): Observable<Response<{}>> {
    const defaultSource = this.http.delete<{}>(
      `${this.url}/${interventionId}/${clientEquipmentId}/`
    );
    return this.remove(
      source || defaultSource,
      interventionId + clientEquipmentId,
      store
    );
  }
}
