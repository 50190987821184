<div class="travels">
  <mat-accordion>
    <app-travel-card-v2
      *ngIf="!isDetailPage"
      [ticket$]="this.ticket$"
      [usersAssignees$]="$any(usersAssignees$)"
      [isNew]="true"
      (createTravelEmitter)="createTravel($event)"
      [openedIndex]="openedIndex"
      (setOpenedIndex)="setOpenedIndex($event)"
      [index]="-1"
    ></app-travel-card-v2>

    <app-travel-card-v2
      *ngFor="let travel of this.ticket$.value.travels; let i = index"
      [isDetailPage]="isDetailPage"
      [ticket$]="this.ticket$"
      [travel]="travel"
      [usersAssignees$]="$any(usersAssignees$)"
      (removeTravelEmitter)="removeTravel($event)"
      [openedIndex]="openedIndex"
      (setOpenedIndex)="setOpenedIndex($event)"
      (editTravelEmitter)="editTravel($event)"
      [index]="i"
    ></app-travel-card-v2>
  </mat-accordion>
</div>
