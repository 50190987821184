import { CompanyResponse } from '@fullyops/legacy/data/api/types/Company';
import { ActivatedRoute, Router } from '@angular/router';
import { TemplateFormFields } from '@fullyops/legacy/ui/ui-shared/form-template/form-template.component';
import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import {
  TicketSale,
  UnsavedTicketSale,
  Company,
  User,
  Contact,
  Status,
  Response,
  TicketType,
  History,
  ProductConfig,
  ProductConfigListItem,
  ProductConfigSimulation,
  UnsavedReportRequest,
  SaleTicketInfo,
  LossMotive,
} from '@fullyops/legacy/data';
import { BehaviorSubject } from 'rxjs';
import { TicketSaleFormService } from './ticket-sale-form.service';
import {
  Priority,
  PriorityColor,
  PermissionType,
} from '@fullyops/legacy/ui/ui-shared/utils/crm-types';
import { UntypedFormControl } from '@angular/forms';
import { ComponentInOutAnimation } from '@fullyops/legacy/ui/ui-shared/utils/component-base-animation';
import { map, pairwise } from 'rxjs/operators';
import { FormComponent } from '@fullyops/legacy/ui/ui-shared/utils/form.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DialogSaveComponent } from '@fullyops/legacy/ui/ui-shared/dialog-save/dialog-save.component';
import { UiCompaniesService } from '../ui-companies.service';
import { UiContactsService } from '../ui-contacts.service';
import { UiAuthService } from '@fullyops/legacy/ui';
import { SnackbarService } from '../ui-snackbar.service';
import { Report, ReportType } from '@fullyops/legacy/data/api/types/Report';
import { ReportControllerControllerV2 } from '../ui-report-controller-v2.service';
import { CountryForm } from '@fullyops/legacy/data/models/v0/CountryForm';
import { Country } from '@fullyops/legacy/data/models/v0/Country';
import { AssigneeService } from '@fullyops/core/services/assignees.service';

enum Tabs {
  Sale = 0,
  Simulation = 1,
  Report = 2,
}
@Component({
  selector: 'crm-ticket-sale-form',
  templateUrl: './ticket-sale-form.component.html',
  styleUrls: ['./ticket-sale-form.component.scss'],
  providers: [TicketSaleFormService],
  animations: [ComponentInOutAnimation.AnimTrigger],
})
export class TicketSaleFormComponent
  extends FormComponent<TicketSale, UnsavedTicketSale, TicketSaleFormService>
  implements OnInit
{
  @Input() ticket$: BehaviorSubject<Status[]>;
  @Input() statuses$: BehaviorSubject<Status[]>;
  @Input() lossMotives$: BehaviorSubject<LossMotive[]>;
  @Input() users$: BehaviorSubject<User[]>;
  @Input() contacts$: BehaviorSubject<Contact[]>;
  @Input() companies$: BehaviorSubject<Company[]>;
  @Input() history$: BehaviorSubject<Response<History[]>>;
  @Input() productConfigs$: BehaviorSubject<Response<ProductConfigListItem[]>>;
  @Input() ticketProductConfigs$: BehaviorSubject<ProductConfig[]>;
  @Input() ticketSimulations$: BehaviorSubject<ProductConfigSimulation[]>;
  @Input() generatingReport$: BehaviorSubject<boolean>;
  @Output() openActivityEvent = new EventEmitter<string>();
  @Output() openTicketEvent = new EventEmitter<{
    ticketId: string;
    ticketType: TicketType;
  }>();
  @Output() addProductConfigEvent = new EventEmitter<string>();
  @Output() openProductConfigEvent = new EventEmitter<string>();
  @Output() removeProductConfigEvent = new EventEmitter<string>();
  @Output() createSimulationEvent = new EventEmitter<UnsavedTicketSale>();
  @Output() openSimulationEvent = new EventEmitter<{
    updatedTicket: UnsavedTicketSale;
    simulationId: string;
  }>();
  @Output() removeSimulationEvent = new EventEmitter<string>();
  @Output() generateReportEvent = new EventEmitter<{
    ticketId: string;
    request: UnsavedReportRequest;
  }>();
  @Output() generateProposalEvent = new EventEmitter<{
    ticketId: string;
    request: UnsavedReportRequest;
  }>();
  @Output() sendReportEmailEvent = new EventEmitter<string>();

  trackingFields = [];
  companyContacts$: BehaviorSubject<Contact[]> = new BehaviorSubject<Contact[]>(
    []
  );
  assignees$ = new BehaviorSubject<[]>([]);
  header: string;
  reportPermissions = [PermissionType.CAN_EDIT_REPORTS];

  infoTabFormFields: TemplateFormFields<any> = [];
  infoTabFormFieldsLastRows: TemplateFormFields<any> = [];
  infoTabFormFieldsCompanyRow: TemplateFormFields<any> = [];
  reports$: BehaviorSubject<Report[]> = new BehaviorSubject<Report[]>([]);
  reportType: ReportType = 'SALE_REPORT';
  countries$ = new BehaviorSubject<CountryForm[]>([]);

  constructor(
    public ticketSaleFormService: TicketSaleFormService,
    public authService: UiAuthService,
    public dialog: MatDialog,
    protected companiesService: UiCompaniesService,
    protected contactsService: UiContactsService,
    protected router: Router,
    public snackBar: SnackbarService,
    protected reportControllerControllerV2: ReportControllerControllerV2,
    protected route: ActivatedRoute,
    private assigneeService: AssigneeService
  ) {
    super(snackBar, authService);

    this.formService = ticketSaleFormService;
    this.formEditPermissions = [PermissionType.CAN_EDIT_SALE_TICKETS];
  }

  ngOnInit() {
    this.initFormFields();
    const defaultAssigneeId: string = this.authService.currentUser?.id;
    this.draft$ = new BehaviorSubject<UnsavedTicketSale>(
      new UnsavedTicketSale(
        '',
        'LOW',
        '',
        [defaultAssigneeId],
        '',
        [],
        '',
        new Date(),
        new Date(),
        0,
        false,
        null,
        [],
        [],
        new SaleTicketInfo('', '', '', '', '', '', '', '', ''),
        {
          address: '',
          city: '',
          countryISOCode: '',
          distance: '',
          zipCode: '',
        }
      )
    );

    this.header = this.entity
      ? `[V-${this.entity.identifier}] ${this.entity.name}`
      : 'newTicketSale';

    this.initForm();

    if (!this.entity) {
      this.statuses$
        .pipe(
          map((statuses) => statuses.sort((a, b) => a.position - b.position))
        )
        .subscribe((statuses) => {
          if (statuses.length) {
            (this.form.get('statusId') as UntypedFormControl).patchValue(
              statuses[0].id
            );
          }
        });

      (this.form.get('statusId') as UntypedFormControl).disable();
    }

    this.updateClientDataWhenChangeCompany();

    this.ticketSaleFormService.form$.subscribe((form) => {
      if (this.entity) {
        (form.get('companyEmail') as UntypedFormControl).patchValue(
          this.entity.company.email
        );
        (form.get('companyPhone') as UntypedFormControl).patchValue(
          this.entity.company.mobileNumber
        );
        (form.get('company') as UntypedFormControl).patchValue(
          this.entity.company
        );
      }
    });

    this.loadReports();
    this.getCountries();
    this.loadAssigneesList();
  }

  loadAssigneesList() {
    this.users$
      .pipe(
        map((users) => {
          return this.assigneeService.getDropdownAssigneeList({
            formValue: this.entity?.assignees || [],
            listOfUsers: users,
          });
        }),
        map((users) =>
          users.map((user: User) => ({
            id: user.id,
            name: `${user.firstName} ${user.lastName}`,
          }))
        )
      )
      .subscribe(this.assignees$);
  }

  loadReports() {
    if (!this.entity?.id) return;
    return this.reportControllerControllerV2
      .getTicketReports({
        reportType: this.reportType,
        ticketId: this.entity.id,
      })
      .subscribe((res) => this.reports$.next(res.results));
  }

  initFormFields() {
    const priorities$ = new BehaviorSubject(
      Object.keys(Priority).map((key) => ({
        id: key,
        name: Priority[key],
        color: PriorityColor[key],
      }))
    );

    this.infoTabFormFields = [
      [
        { name: 'name', type: 'text', label: 'title', size: 12 },
        {
          name: 'company',
          type: 'autocomplete',
          label: 'company',
          size: 12,
          items$: this.companies$,
          nullable: false,
          translate: false,
        },

        {
          name: 'companyAddress',
          type: 'text',
          label: 'companyAddress',
          size: 9,
        },
        { name: 'companyDistance', type: 'text', label: 'distance', size: 3 },
        { name: 'clientCity', label: 'clientCity', type: 'text', size: 4 },
        {
          name: 'countryISOCode',
          type: 'select',
          label: 'country',
          items$: this.countries$,
          size: 4,
        },
        { name: 'zipCode', label: 'zipCode', type: 'text', size: 4 },

        { name: 'companyEmail', type: 'text', label: 'companyEmail', size: 8 },
        { name: 'companyPhone', type: 'text', label: 'companyPhone', size: 4 },
        {
          name: 'contactIds',
          type: 'multiple-select',
          label: 'contacts',
          size: 12,
          items$: this.companyContacts$,
          nullable: false,
          translate: false,
          displayValue: (contact: Contact) => {
            return `${contact.name}  ${
              contact.jobPosition ? `(${contact.jobPosition})` : ''
            }`;
          },
        },
        {
          name: 'proposalNumber',
          type: 'text',
          label: 'proposalNumber',
          size: 12,
        },
        { name: 'expiresIn', type: 'text', label: 'expiresIn', size: 12 },
        { name: 'formation', type: 'text', label: 'formation', size: 12 },
        { name: 'deliveryDate', type: 'text', label: 'deliveryDate', size: 12 },
        {
          name: 'deliveryAddress',
          type: 'text',
          label: 'deliveryAddress',
          size: 12,
        },
        { name: 'warranty', type: 'text', label: 'warranty', size: 12 },
        { name: 'installation', type: 'text', label: 'installation', size: 12 },
        {
          name: 'technicalAssistance',
          type: 'text',
          label: 'technicalAssistance',
          size: 12,
        },
        { name: 'payment', type: 'text', label: 'payment', size: 12 },
        { name: 'notes', type: 'textarea', label: 'notes', size: 12 },
      ],
    ];

    this.trackingFields = [
      [
        {
          name: 'statusId',
          type: 'select',
          label: 'status',
          size: 6,
          items$: this.statuses$,
          translate: true,
        },
        {
          name: 'priority',
          type: 'select',
          label: 'priority',
          size: 6,
          items$: priorities$,
          translate: true,
        },
        {
          name: 'won',
          type: 'checkbox',
          label: 'won',
          size: 6,
          translate: true,
        },
        {
          name: 'lossMotiveId',
          type: 'select',
          label: 'lossMotive',
          size: 6,
          items$: this.lossMotives$,
          translate: false,
        },
      ],
      [
        { type: 'separator', label: 'saleDate' },
        { name: 'saleDate', type: 'fulldate', label: 'chooseDate', size: 12 },
        {
          name: 'assigneeIds',
          type: 'multiple-select',
          label: 'assignee',
          size: 12,
          items$: this.assignees$,
          translate: false,
        },
      ],
    ];

    if (this.entity) this.addRedirectCompanyButtonOnField();
  }

  addRedirectCompanyButtonOnField() {
    const companyIndex = this.infoTabFormFields[0].findIndex(
      (fieldObj) => fieldObj.name == 'company'
    );

    this.infoTabFormFields[0][companyIndex] = {
      ...this.infoTabFormFields[0][companyIndex],
      button: {
        iconName: 'account_balance',
        onClick: () => this.redirectToCompanyPage(),
      },
    };
  }

  updateClientDataWhenChangeCompany() {
    const companyControl = this.form.controls['company'];

    companyControl.valueChanges.subscribe((value) => {
      if (!value) return this.companyContacts$.next([]);
      if (typeof value === 'string') return;

      (this.form.get('companyEmail') as UntypedFormControl).patchValue(
        value.email
      );
      (this.form.get('companyPhone') as UntypedFormControl).patchValue(
        value.mobileNumber
      );
      (this.form.get('companyAddress') as UntypedFormControl).patchValue(
        value.address
      );

      (this.form.get('companyDistance') as UntypedFormControl).patchValue(
        value.distance
      );

      (this.form.get('clientCity') as UntypedFormControl).patchValue(
        value.city
      );
      (this.form.get('zipCode') as UntypedFormControl).patchValue(
        value.zipCode
      );
      (this.form.get('countryISOCode') as UntypedFormControl).patchValue(
        value.countryISOCode
      );

      this.contactsService
        .getAll({ companyId: value.id })
        .subscribe((response) => this.companyContacts$.next(response.data));
    });
  }

  onAddProductConfig(productConfigId: string) {
    this.addProductConfigEvent.emit(productConfigId);
  }

  onOpenProductConfig(productConfigId: string) {
    this.openProductConfigEvent.emit(productConfigId);
  }

  onRemoveProductConfig(productConfigId: string) {
    this.removeProductConfigEvent.emit(productConfigId);
  }

  onCreateSimulation() {
    this.createSimulationEvent.emit(this.formService.getDraft());
  }

  onOpenSimulation(simulationId: string) {
    this.openSimulationEvent.emit({
      updatedTicket: this.formService.getDraft(),
      simulationId,
    });
  }

  onRemoveSimulation(simulationId: string) {
    // TODO: remove when the simulations endpoint get updated
    this.ticketSimulations$.next(
      this.ticketSimulations$
        .getValue()
        .filter((simulation) => simulation.id !== simulationId)
    );

    this.removeSimulationEvent.emit(simulationId);
  }

  onOpenActivity(id: string) {
    this.openActivityEvent.emit(id);
  }

  onOpenTicket(ticket: { ticketId: string; ticketType: TicketType }) {
    this.openTicketEvent.emit(ticket);
  }

  onGenerateReport(file: FormData) {
    this.generateReportEvent.emit({
      ticketId: this.entity.id,
      request: new UnsavedReportRequest(
        '19e91a2c-cd50-4e23-9e12-a07758165c89',
        'SALE_REPORT',
        file
      ),
    });
  }

  onGenerateProposal(file: FormData) {
    this.generateProposalEvent.emit({
      ticketId: this.entity.id,
      request: new UnsavedReportRequest(
        '19e91a2c-cd50-4e23-9e12-a07758165c89',
        'SALE_REPORT',
        null
      ),
    });
  }

  onSendReportEmail(reportd: string) {
    this.sendReportEmailEvent.emit(reportd);
  }

  onTabClick(event) {
    if (event.index === Tabs.Report && this.form.dirty) {
      const dialogRef = this.dialog.open(DialogSaveComponent, {
        data: {
          title: 'dirtyFormTitle',
          message: 'dirtyFormMessage',
        },
      });

      dialogRef.afterClosed().subscribe((saveOutput) => {
        if (saveOutput) {
          this.ngForm.onSubmit(null);
        }
      });
    }
  }

  navigateToCompanyPage() {
    const company = this.form.controls['company'].value as CompanyResponse;
    if (!company) return;
    return this.authService
      .hasPermission([PermissionType.CAN_EDIT_COMPANIES])
      .subscribe((canEditCompanies) => {
        return this.router.navigate([
          `companies/${company.id}${canEditCompanies ? '/edit' : ''}`,
        ]);
      });
  }

  setCompanyIfExist() {
    if (this.entity) {
      this.form.controls['company'].setValue(this.entity.company);
    }
  }

  redirectToCompanyPage() {
    const company = this.form.controls['company'].value as CompanyResponse;

    return this.authService
      .hasPermission([PermissionType.CAN_EDIT_COMPANIES])
      .subscribe((canEditCompanies) => {
        return this.router.navigate([
          `companies/${company.id}${canEditCompanies ? '/edit' : ''}`,
        ]);
      });
  }

  patchIfTrackingFieldsChange() {
    const isNew = this.entity?.identifier == undefined;
    if (!isNew) this.onSubmit();
  }

  getCountries() {
    this.route.data.subscribe((data) => {
      this.countries$.next(
        data?.countries
          .filter((c: Country) => c.active)
          .map((c: Country) => {
            return { countryISOCode: c.isoCode, name: c.name, id: c.isoCode };
          })
      );
    });
  }
}
