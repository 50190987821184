<mat-form-field class="d-block f-right">
  <mat-label>{{ 'forms:partsList' | i18nextCap }}</mat-label>
  <input matInput [matAutocomplete]="auto" [formControl]="partSearchCtrl" />
  <mat-autocomplete
    #auto="matAutocomplete"
    [disableRipple]="true"
    (optionSelected)="onAddPart($event)"
  >
    <ng-container *ngIf="filteredParts$ | async as filteredParts">
      <mat-option *ngFor="let part of filteredParts" [value]="part.ref">
        <span>{{ part.name }}</span>
        <small> [ref: {{ part.ref }}]</small>
      </mat-option>
      <mat-option *ngIf="!filteredParts.length" disabled>
        <span>{{ 'forms:emptyList' | i18nextCap }}</span>
      </mat-option>
    </ng-container>
  </mat-autocomplete>
  <button
    *ngIf="canAddParts && showAddButton && partSearchCtrl.value"
    type="button"
    matSuffix
    mat-flat-button
    (click)="onCreatePart()"
  >
    {{ 'forms:add' | i18nextCap }}
    <mat-icon
      matTooltip="{{ 'forms:add' | i18nextCap }} &quot;{{
        partSearchCtrl.value
      }}&quot;"
      >add</mat-icon
    >
  </button>
</mat-form-field>
