import { UiServiceV2 } from './ui.service-v2.service';
import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { API_SERVICE_CONFIG } from '@fullyops/legacy/data';
import { IApiServiceConfig } from '@fullyops/legacy/data/api/IApiServiceConfig';
import { PageResponseWrapper } from '@fullyops/legacy/data/api/types/HttpResponses';
import {
  RequestTicketDetailResponse,
  RequestTicketPatchRequest,
  RequestTicketPostRequest,
  RequestTicketResponse,
} from '@fullyops/legacy/data/api/types/RequestTicke';

type GetAllParams = {
  companyIds?: Array<string>;
  createdBy?: string;
  endsAt?: string;
  finalizedAfter?: string;
  identifier?: number;
  includeDeleted?: boolean;
  name?: string;
  order?: 'ASC' | 'DESC';
  orderBy?:
    | 'UUID'
    | 'COMPANY'
    | 'STATUS'
    | 'STARTS_AT'
    | 'ENDS_AT'
    | 'NAME'
    | 'IDENTIFIER';
  page?: number;
  priority?: 'LOW' | 'MEDIUM' | 'HIGH' | 'URGENT';
  size?: number;
  statusIds?: Array<string>;
  updatedBy?: string;
  userIds?: Array<string>;
};

/**
 * TODO: This isn't a controller, at least from a front-end app perspective. We're also extending a UIServiceV2, which is a misnomer.
 */
@Injectable({
  providedIn: 'root',
})
export class RequestTicketControllerV2 extends UiServiceV2 {
  constructor(
    @Inject(API_SERVICE_CONFIG) private config: IApiServiceConfig,
    private http: HttpClient
  ) {
    super();
  }

  private readonly url = this.config.url + 'tickets/requests';

  getAll(params: GetAllParams) {
    return this.http.get<PageResponseWrapper<RequestTicketResponse>>(
      `${this.url}`
    );
  }

  getById(params: { id: string }) {
    return this.http.get<RequestTicketDetailResponse>(
      `${this.url}/${params.id}`
    );
  }

  updateById(params: { newData: Partial<RequestTicketPatchRequest> }) {
    return this.http.patch<RequestTicketDetailResponse>(
      `${this.url}/${params.newData.id}`,
      params.newData
    );
  }

  create(params: { newData: RequestTicketPostRequest }) {
    return this.http.post<RequestTicketDetailResponse>(
      `${this.url}`,
      params.newData
    );
  }

  deleteById(params: { id: string }) {
    return this.http.delete(`${this.url}/${params.id}`);
  }

  sendTicketEmail(params: { id: string; emailsRecipes: string[] }) {
    return this.http.post<RequestTicketResponse>(
      `${this.url}/${params.id}/email`,
      { recipients: params.emailsRecipes }
    );
  }
}
