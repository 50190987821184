import { UiServiceV2 } from './ui.service-v2.service';
import { PageResponseWrapper } from '@fullyops/legacy/data/api/types/HttpResponses';
import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { API_SERVICE_CONFIG } from '@fullyops/legacy/data/api/ApiServiceConfig';
import { IApiServiceConfig } from '@fullyops/legacy/data/api/IApiServiceConfig';
import {
  TravelRequest,
  TravelResponse,
} from '@fullyops/legacy/data/api/types/Travel';

type QueryParameters = {
  createdBy?: string;
  includeDeleted?: boolean;
  installationTicketId?: string;
  order?: 'ASC' | 'DESC';
  orderBy?:
    | 'CREATED_AT'
    | 'UPDATED_AT'
    | 'UUID'
    | 'STARTING_HOUR'
    | 'ENDING_HOUR'
    | 'STAY'
    | 'DISTANCE';
  page?: number;
  size?: number;
  supportTicketId?: string;
  updatedBy?: string;
};

@Injectable({
  providedIn: 'root',
})
export class TravelControllerV2 extends UiServiceV2 {
  constructor(
    @Inject(API_SERVICE_CONFIG) private config: IApiServiceConfig,
    private http: HttpClient
  ) {
    super();
  }

  private readonly url = this.config.url + 'travels';

  getAll(params: { queryParameters?: QueryParameters }) {
    let query = '';
    if (params.queryParameters) {
      const queries = this.createQueryParams(params.queryParameters);
      query = `?${queries}`;
    }

    return this.http.get<PageResponseWrapper<TravelResponse>>(
      `${this.url}${query}`
    );
  }

  create(props: { travel: TravelRequest }) {
    return this.http.post<TravelResponse>(this.url, props.travel);
  }

  delete(params: { travelId: string }) {
    return this.http.delete(`${this.url}/${params.travelId}`);
  }

  updateById(props: { travel: TravelRequest; travelId: string }) {
    return this.http.patch<TravelResponse>(
      `${this.url}/${props.travelId}`,
      props.travel
    );
  }
}
