import {
  InstallationTicketEndpoint,
  UnsavedTicketInstallation,
  TicketInstallation,
} from '@fullyops/legacy/data';
import { Injectable } from '@angular/core';
import { UiService } from './ui.service';
import { InstallationTicketInput } from '@fullyops/legacy/data/api/types/InstallationTicket';

@Injectable({
  providedIn: 'root',
})
export class UiInstallationTicketService extends UiService<
  TicketInstallation,
  UnsavedTicketInstallation,
  InstallationTicketEndpoint
> {
  constructor(protected endpoint: InstallationTicketEndpoint) {
    super(endpoint.createStore(), endpoint);
  }

  updateRequestTicketById(id: string, body: InstallationTicketInput) {
    return this.endpoint.updateRequestTicketById(id, body, this.store$);
  }
}
