import { ISerialisedUnsavedActivity } from './serialisations/ISerialisedActivity';

export class UnsavedActivity {
  constructor(
    readonly name: string,
    readonly ticketId: string | null,
    readonly ticketType: string | null,
    readonly activityType: string,
    readonly assigneeIds: string[],
    readonly companyId: string,
    readonly contactIds: string[],
    readonly done: boolean,
    readonly startsAt: Date | null,
    readonly endsAt: Date | null,
    readonly internalObservation: string
  ) {
    if (name) this.name = name.trim();
    if (ticketType) this.ticketType = ticketType.trim();
    if (activityType) this.activityType = activityType.trim();
    if (internalObservation)
      this.internalObservation = internalObservation.trim();
  }

  static fromSerialised(serialised: ISerialisedUnsavedActivity) {
    return new UnsavedActivity(
      serialised.name,
      serialised.ticketId,
      serialised.ticketType,
      serialised.activityType,
      serialised.assigneeIds,
      serialised.companyId,
      serialised.contactIds,
      serialised.done,
      serialised.startsAt ? new Date(serialised.startsAt) : null,
      serialised.endsAt ? new Date(serialised.endsAt) : null,
      serialised.internalObservation
    );
  }

  toSerialised(): ISerialisedUnsavedActivity {
    return {
      name: this.name,
      ticketId: this.ticketId,
      ticketType: this.ticketType,
      activityType: this.activityType,
      assigneeIds: this.assigneeIds,
      companyId: this.companyId,
      contactIds: this.contactIds,
      done: this.done,
      startsAt: this.startsAt ? this.startsAt.toISOString() : null,
      endsAt: this.endsAt ? this.endsAt.toISOString() : null,
      internalObservation: this.internalObservation,
    };
  }

  clone(partialUnsavedActivity: PartialUnsavedActivity): UnsavedActivity {
    const resolve = (key: keyof UnsavedActivity) =>
      partialUnsavedActivity.hasOwnProperty(key)
        ? partialUnsavedActivity[key]
        : this[key];

    return new UnsavedActivity(
      resolve('name'),
      resolve('ticketId'),
      resolve('ticketType'),
      resolve('activityType'),
      resolve('assigneeIds'),
      resolve('companyId'),
      resolve('contactIds'),
      resolve('done'),
      resolve('startsAt'),
      resolve('endsAt'),
      resolve('internalObservation')
    );
  }
}

type PartialUnsavedActivity = Partial<
  Pick<
    UnsavedActivity,
    | 'name'
    | 'ticketId'
    | 'ticketType'
    | 'activityType'
    | 'assigneeIds'
    | 'companyId'
    | 'contactIds'
    | 'done'
    | 'startsAt'
    | 'endsAt'
    | 'internalObservation'
  >
>;
