import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { UnsavedClientEquipment } from '@fullyops/legacy/data';

class MachineForm {
  brand = new UntypedFormControl();
  model = new UntypedFormControl();
  serialNumber = new UntypedFormControl();
  type = new UntypedFormControl();
  equipmentData = new UntypedFormControl();
  warranty = new UntypedFormControl();
  hasWarranty = new UntypedFormControl();

  constructor(machine: UnsavedClientEquipment, dis: boolean) {
    const fb = new UntypedFormBuilder();
    this.brand = fb.control(
      { value: machine.brand, disabled: dis },
      Validators.required
    );
    this.model = fb.control(
      { value: machine.model, disabled: dis },
      Validators.required
    );
    this.serialNumber = fb.control(
      { value: machine.serialNumber, disabled: dis } //, Validators.required
    );
    this.type = fb.control({ value: machine.type, disabled: dis });
    this.equipmentData = fb.control({
      value: machine.equipmentData,
      disabled: dis,
    });
    this.warranty = fb.control({ value: machine.warranty, disabled: dis });
    this.hasWarranty = fb.control(
      { value: machine.hasWarranty, disabled: dis },
      Validators.required
    );
  }
}

@Injectable()
export class MachineFormService {
  fb = new UntypedFormBuilder();

  private form: BehaviorSubject<UntypedFormGroup> =
    new BehaviorSubject<UntypedFormGroup>(new UntypedFormGroup({}));
  form$: Observable<UntypedFormGroup> = this.form.asObservable();

  constructor() {}

  initForm(draftMachine: UnsavedClientEquipment, disabled: boolean) {
    this.form.next(this.fb.group(new MachineForm(draftMachine, disabled)));
  }

  getDraft() {
    return new UnsavedClientEquipment(
      (this.form.getValue().get('brand') as UntypedFormControl).value,
      null,
      null,
      null,
      (this.form.getValue().get('equipmentData') as UntypedFormControl).value,
      (this.form.getValue().get('hasWarranty') as UntypedFormControl).value,
      null,
      (this.form.getValue().get('model') as UntypedFormControl).value,
      (this.form.getValue().get('serialNumber') as UntypedFormControl).value,
      (this.form.getValue().get('type') as UntypedFormControl).value,
      null,
      null,
      (this.form.getValue().get('warranty') as UntypedFormControl).value
    );
  }

  markAllAsTouched() {
    const currentMachine = this.form.getValue();
    currentMachine.markAllAsTouched();

    this.form.next(currentMachine);
  }
}
