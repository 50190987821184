import { Injectable } from '@angular/core';
import {
  Response,
  History,
  HistoriesEndpoint,
  TicketType,
} from '@fullyops/legacy/data';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UiHistoryService {
  readonly histories$ = this.histories.createStore();

  constructor(protected histories: HistoriesEndpoint) {}

  loadAll() {
    this.loadHistories();
  }

  loadHistories() {
    this.histories
      .getAll({}, undefined, this.histories$)
      .subscribe({ error: console.error });
  }

  getHistory(id: string): Observable<Response<History>> {
    return this.histories.get(id, undefined, this.histories$);
  }

  getHistoryByAssigneeId(id: string): Observable<Response<History[]>> {
    return this.histories.getAll({ assignee: id }, undefined, this.histories$);
  }

  getHistoryByCompanyId(id: string): Observable<Response<History[]>> {
    return this.histories.getAll({ company: id }, undefined, this.histories$);
  }

  getHistoryByContactId(id: string): Observable<Response<History[]>> {
    return this.histories.getAll({ contact: id }, undefined, this.histories$);
  }

  getHistoryByTicketId(
    type: TicketType,
    id: string
  ): Observable<Response<History[]>> {
    return this.histories.getAll(
      { ticketType: type, ticketId: id },
      undefined,
      this.histories$
    );
  }
}
