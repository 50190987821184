import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import {
  Response,
  ResponseState,
  Activity,
  ActivityLinkType,
  ActivityType,
  Contact,
  User,
  Company,
} from '@fullyops/legacy/data';
import { BehaviorSubject } from 'rxjs';
import { TicketIdentifierPipe } from '@fullyops/legacy/ui/ui-shared/utils/tickets-identifier.pipe';
import {
  ActionButtons,
  PermissionType,
  PaginationResult,
} from '@fullyops/legacy/ui/ui-shared/utils/crm-types';
import { ComponentInOutAnimation } from '@fullyops/legacy/ui/ui-shared/utils/component-base-animation';
import { TimeZoneDateFormatterService } from '@fullyops/core/services/date-formatter.service';
import { FormControl } from '@angular/forms';
import { ActivityResponse } from '@fullyops/legacy/data/api/types/Activity';

@Component({
  selector: 'crm-activities',
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.scss'],
  animations: [ComponentInOutAnimation.AnimTrigger],
  providers: [TicketIdentifierPipe],
})
export class ActivitiesComponent implements OnInit {
  constructor(public timezoneDate: TimeZoneDateFormatterService) {}

  @Input() title: string;
  @Input() filters$ = new BehaviorSubject<{ [key: string]: string }>({});
  @Input() listActivitiesPagination$ = new BehaviorSubject<
    PaginationResult<ActivityResponse>
  >(null);
  @Input() companies$: BehaviorSubject<Company[]>;
  @Input() users$: BehaviorSubject<User[]>;
  @Input() contacts$: BehaviorSubject<Contact[]>;

  @Output() openEvent = new EventEmitter<string>();
  @Output() addNewEvent = new EventEmitter();
  @Output() filtersChangeEvent = new EventEmitter<{}>();
  @Output() pageChangeEvent = new EventEmitter<number>();

  // Response Possible Statuses
  state = ResponseState;
  actions: ActionButtons = {};
  activitiesList$ = new BehaviorSubject<any[]>([]);

  // Seacrh Fielter
  filteredActivities$ = new BehaviorSubject<[]>([]);

  ActivityType = ActivityType;
  ActivityLinkType = ActivityLinkType;

  displayedColumns = ['name', 'company', 'link', 'assignees'];

  ngOnInit() {
    this.initActionButtons();
  }

  initActionButtons() {
    this.actions.primary = {
      label: 'addNew',
      color: 'primary',
      permissions: [PermissionType.CAN_CREATE_ACTIVITIES],
    };
  }

  onActivitiesFilterChange(items) {
    this.filteredActivities$.next(items);
  }

  onActionEvent(event: string) {
    if (event === 'addNew') {
      this.onAddNew();
    }
  }

  onOpen(id: string) {
    this.openEvent.emit(id);
  }

  onAddNew() {
    this.addNewEvent.emit();
  }

  onFiltersChange(filters) {
    this.filtersChangeEvent.emit(filters);
  }

  onPageChange(pageIndex: number) {
    this.pageChangeEvent.emit(pageIndex);
  }
}
