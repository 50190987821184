import { NgModule } from '@angular/core';

import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';
import { MatGridListModule } from '@angular/material/grid-list';
import {
  MatLegacyButtonModule,
  MatLegacyButtonModule as MatButtonModule,
} from '@angular/material/legacy-button';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';

import {
  MatLegacyListModule,
  MatLegacyListModule as MatListModule,
} from '@angular/material/legacy-list';
import {
  MatLegacyAutocompleteModule,
  MatLegacyAutocompleteModule as MatAutocompleteModule,
} from '@angular/material/legacy-autocomplete';
import {
  MatLegacyInputModule,
  MatLegacyInputModule as MatInputModule,
} from '@angular/material/legacy-input';
import {
  MatLegacyMenuModule,
  MatLegacyMenuModule as MatMenuModule,
} from '@angular/material/legacy-menu';
import { CdkColumnDef, CdkTableModule } from '@angular/cdk/table';
import { A11yModule } from '@angular/cdk/a11y';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CdkTreeModule } from '@angular/cdk/tree';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatSortModule } from '@angular/material/sort';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { PortalModule } from '@angular/cdk/portal';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatLegacyChipsModule } from '@angular/material/legacy-chips';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatLegacySlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacySnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacySelectModule } from '@angular/material/legacy-select';
import { MatLegacyTableModule } from '@angular/material/legacy-table';
import { MatLegacyTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyCardModule } from '@angular/material/legacy-card';
import { MatLegacyProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';

@NgModule({
  imports: [
    MatSliderModule,
    MatGridListModule,
    MatLegacyButtonModule,
    MatToolbarModule,
    MatSidenavModule,
    MatIconModule,
    MatLegacyListModule,
    MatLegacyAutocompleteModule,
    MatLegacyInputModule,
    MatLegacyMenuModule,
  ],
  exports: [
    A11yModule,
    CdkStepperModule,
    CdkTableModule,
    CdkTreeModule,
    DragDropModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatLegacyCardModule,
    MatCheckboxModule,
    MatLegacyChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatLegacyDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatLegacyProgressBarModule,
    MatLegacyProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatLegacySelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatLegacySlideToggleModule,
    MatLegacySnackBarModule,
    MatSortModule,
    MatLegacyTableModule,
    MatLegacyTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    PortalModule,
    ScrollingModule,
    MatMomentDateModule,
  ],
  providers: [CdkColumnDef],
})
export class MaterialModule {}
