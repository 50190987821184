import { Injectable } from '@angular/core';
import {
  UserPart,
  UserPartsEndpoint,
  UnsavedUserPart,
  Response,
} from '@fullyops/legacy/data';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UiUserPartsService {
  readonly store$ = this.service.createStore();

  constructor(protected service: UserPartsEndpoint) {}

  loadAll() {
    this.service
      .getAll({}, undefined, this.store$)
      .subscribe({ error: console.error });
  }

  get(id: string): Observable<Response<UserPart[]>> {
    return this.service.get(id, undefined, this.store$);
  }

  create(userId: string, unsavedModel: UnsavedUserPart) {
    return this.service.post(userId, unsavedModel, undefined, this.store$);
  }

  update(id: string, unsavedModel: UnsavedUserPart) {
    return this.service.patch(id, unsavedModel, undefined, this.store$);
  }

  remove(userId: string, partId: string): Observable<Response<{}>> {
    return this.service.delete(userId, partId, undefined, this.store$);
  }
}
