import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { SubProductVariant } from '@fullyops/legacy/data';
import { ComponentInOutAnimation } from '@fullyops/legacy/ui/ui-shared/utils/component-base-animation';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'crm-sub-product-variants',
  templateUrl: './sub-product-variants.component.html',
  styleUrls: ['./sub-product-variants.component.scss'],
  animations: [ComponentInOutAnimation.AnimTrigger],
})
export class SubProductVariantsComponent implements OnInit {
  @Input() preview = false;
  @Input() formGroup?: UntypedFormGroup;
  @Input() variants: SubProductVariant[];
  @Output() selectVariantEvent = new EventEmitter();
  @Output() addVariantEvent = new EventEmitter();
  @Output() editVariantEvent = new EventEmitter<string>();

  selectedVariant = 'id';

  ngOnInit() {
    this.formGroup
      ?.get('variantId')
      .valueChanges.subscribe((value) => (this.selectedVariant = value));
  }

  selectVariant(variantId: string) {
    if (this.formGroup) {
      this.formGroup.get('variantId').patchValue(variantId);
    } else {
      this.editVariant(variantId);
    }

    this.selectVariantEvent.emit();
  }

  addVariant() {
    this.addVariantEvent.emit();
  }

  editVariant(variantId: string) {
    this.editVariantEvent.emit(variantId);
  }
}
