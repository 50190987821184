import { Response } from '../Response';
import { Endpoint } from '../Endpoint';
import { HttpClient } from '@angular/common/http';
import { IListResponse } from '../IListResponse';
import { Injectable, Inject } from '@angular/core';
import { API_SERVICE_CONFIG } from '../ApiServiceConfig';

import { Observable, BehaviorSubject } from 'rxjs';

import { IApiServiceConfig } from '../IApiServiceConfig';
import { WorkPeriodAssignees } from '../../models/v0/WorkPeriodAssignees';
import { ISerialisedWorkPeriodAssignees } from '../../models/v0/serialisations/ISerialisedWorkPeriodAssignees';

type WorkPeriodAssigneeList = IListResponse<ISerialisedWorkPeriodAssignees>;

@Injectable()
export class WorkPeriodAssigneeEndpoint extends Endpoint<
  WorkPeriodAssignees,
  ISerialisedWorkPeriodAssignees
> {
  private readonly url = this.config.url + 'workperiods/assignees';

  constructor(
    @Inject(API_SERVICE_CONFIG) private config: IApiServiceConfig,
    private http: HttpClient
  ) {
    super(WorkPeriodAssignees.fromSerialised);
  }

  getAll(
    filters: {},
    source?: Observable<WorkPeriodAssigneeList>,
    store?: BehaviorSubject<Response<WorkPeriodAssignees[]>>
  ): Observable<Response<WorkPeriodAssignees[]>> {
    let query = '';
    Object.keys(filters).forEach((key) => (query += `&${key}=${filters[key]}`));

    const defaultSource = this.http.get<WorkPeriodAssigneeList>(
      `${this.url}?size=100&page=0${query}`
    );
    return this.list(source || defaultSource, store);
  }

  get(
    id: string,
    source?: Observable<ISerialisedWorkPeriodAssignees>,
    store?: BehaviorSubject<Response<WorkPeriodAssignees[]>>
  ): Observable<Response<WorkPeriodAssignees>> {
    const defaultSource = this.http.get<ISerialisedWorkPeriodAssignees>(
      `${this.url}${id}/`
    );
    return this.retrieve(source || defaultSource, store);
  }

  post(
    workPeriodId: string,
    assigneeId: string,
    source?: Observable<ISerialisedWorkPeriodAssignees>,
    store?: BehaviorSubject<Response<WorkPeriodAssignees[]>>
  ): Observable<Response<WorkPeriodAssignees>> {
    const body = {
      assigneeId: assigneeId,
      workPeriodId: workPeriodId,
    };

    const defaultSource = this.http.post<ISerialisedWorkPeriodAssignees>(
      this.url,
      body
    );
    return this.add(source || defaultSource, store);
  }

  delete(
    workPeriodId: string,
    assigneeId: string,
    source?: Observable<{}>,
    store?: BehaviorSubject<Response<WorkPeriodAssignees[]>>
  ): Observable<Response<{}>> {
    const defaultSource = this.http.delete<{}>(
      `${this.url}/${workPeriodId}/${assigneeId}/`
    );
    return this.remove(source || defaultSource, null);
  }
}
