<div class="content-wrapper">
  <!-- <crm-breadcrumb
    [header]="'workOrderCategories'"
    [actionButtons]="actions"
    (actionEvent)="onActionEvent($event)"
  ></crm-breadcrumb> -->
  <div class="add-row">
    <button
      mat-flat-button
      color="primary"
      type="button"
      (click)="openCreateCategoryModal()"
    >
      {{ 'add category' | i18nextCap }}
    </button>
  </div>

  <mat-divider></mat-divider>

  <div class="table-wrapper-container">
    <div class="table-wrapper">
      <mat-table id="matTable" [dataSource]="workOrderCategories$">
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef>
            {{ 'name' | i18nextCap }}
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <div class="cell-row">
              <span class="title">{{ element.name }}</span>
              <div>
                <span *ngIf="element.label" class="subtitle">
                  {{ element.label }}</span
                >
                <ngx-colors
                  *ngIf="element.color != null"
                  ngx-colors-trigger
                  [hideTextInput]="false"
                  [hideColorPicker]="false"
                  [colorPickerControls]="'no-alpha'"
                  [ngModel]="element.color"
                >
                </ngx-colors>
              </div>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef>
            {{ 'actions' | i18nextCap }}
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <div class="cell-row category-actions">
              <button
                class="edit-category"
                mat-icon-button
                (click)="openEditCategoryModal(element)"
                type="button"
              >
                <div class="icon">
                  <fa-icon [icon]="faEdit"></fa-icon>
                </div>
                {{ 'edit' | i18nextCap }}
              </button>
              <button
                class="delete-category"
                mat-icon-button
                (click)="deleteCategory(element.id)"
                type="button"
              >
                <div class="icon red-icon">
                  <fa-icon [icon]="faTrashCan"></fa-icon>
                </div>
              </button>
            </div>
          </mat-cell>
        </ng-container>

        <ng-content></ng-content>

        <mat-header-row
          *matHeaderRowDef="defaultTableHeaders; sticky: true"
        ></mat-header-row>

        <mat-row *matRowDef="let row; columns: defaultTableHeaders"> </mat-row>
      </mat-table>
      <div *ngIf="noWorkOrderCategories$ | async" class="no-results">
        {{ 'noResults' | i18nextCap }}
      </div>
    </div>
  </div>
</div>
