import {
  RequestTicketDetailResponse,
  RequestTicketPatchRequest,
  RequestTicketResponse,
} from '@fullyops/legacy/data/api/types/RequestTicke';
import { UiServiceV2 } from './ui.service-v2.service';
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_SERVICE_CONFIG } from '@fullyops/legacy/data/api/ApiServiceConfig';
import { IApiServiceConfig } from '@fullyops/legacy/data/api/IApiServiceConfig';
import { ResponseWrapper } from '@fullyops/legacy/data/api/v0/InstallationTicketStatusEndpoint';
import { TicketStatusResponse } from '@fullyops/legacy/data/api/types/Ticket';
import { PageResponseWrapper } from '@fullyops/legacy/data/api/types/HttpResponses';
import { map } from 'rxjs/operators';

export type QueryParams = {
  companyIds?: string[];
  createdBy?: string;
  endsAt?: string;
  finalizedAfter?: string;
  identifier?: string;
  includeDeleted?: boolean;
  name?: string;
  order?: 'ASC' | 'DESC';
  orderBy?:
    | 'UUID'
    | 'COMPANY'
    | 'STATUS'
    | 'STARTS_AT'
    | 'ENDS_AT'
    | 'NAME'
    | 'IDENTIFIER';
  page?: number;
  priority?: 'LOW' | 'MEDIUM' | 'HIGH' | 'URGENT';
  size?: number;
  startsAt?: string;
  statusIds?: string[];
  ticketName?: string;
  updatedBy?: string;
  userIds?: string[];
};

@Injectable({
  providedIn: 'root',
})
export class RequestTicketControllerV2 extends UiServiceV2 {
  constructor(
    private http: HttpClient,
    @Inject(API_SERVICE_CONFIG) private config: IApiServiceConfig
  ) {
    super();
  }

  private readonly endpointUrl = this.config.url + 'tickets/requests';

  updateById(params: {
    newData: Partial<RequestTicketPatchRequest>;
    ticketId: string;
  }) {
    return this.http.patch<RequestTicketResponse>(
      `${this.endpointUrl}/${params.ticketId}`,
      params.newData
    );
  }

  getAll(params: { queryParams?: QueryParams }) {
    let query = '';
    if (params.queryParams) {
      query = `?${this.createQueryParams(params.queryParams)}`;
    }

    return this.http
      .get<PageResponseWrapper<RequestTicketResponse>>(
        `${this.endpointUrl}${query}`
      )
      .pipe(
        map((response) => ({
          ...response,
          results: response.results.map((t) => ({
            ...t,
            identifier: `R-${t.identifier}`,
          })),
        }))
      );
  }

  getRequestTicketStatuses() {
    return this.http.get<ResponseWrapper<TicketStatusResponse>>(
      `${this.endpointUrl}/status`
    );
  }

  getById(params: { id: string }) {
    return this.http.get<RequestTicketDetailResponse>(
      `${this.endpointUrl}/${params.id}`
    );
  }
}
