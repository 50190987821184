<div class="dropzone">
  <input type="file" (change)="uploadTemplate($event)" accept=".doc, .docx" />
  <div class="dropzone-visual-content">
    <div class="icon" [ngClass]="isDragOver ? 'hide-icon' : 'blue-icon'">
      <fa-icon [icon]="faCloudArrowUp" size="xl"></fa-icon>
    </div>
    <p class="normal-text" *ngIf="!isDragOver">
      {{ 'dragAndDropTemplate' | i18nextCap }}
    </p>
    <p class="normal-text" *ngIf="isDragOver">
      {{ 'releaseToUploadFile' | i18nextCap }}
    </p>
  </div>
</div>
