import { Response } from '../Response';
import { Endpoint } from '../Endpoint';
import { HttpClient } from '@angular/common/http';
import { IListResponse } from '../IListResponse';
import { Injectable, Inject } from '@angular/core';
import { API_SERVICE_CONFIG } from '../ApiServiceConfig';

import { Observable, BehaviorSubject } from 'rxjs';

import { ClientEquipment } from '../../models/v0/ClientEquipment';
import { ISerialisedClientEquipment } from '../../models/v0/serialisations/ISerialisedClientEquipment';
import { IApiServiceConfig } from '../IApiServiceConfig';
import { UnsavedClientEquipment } from '../../models/v0/UnsavedClientEquipment';
import { map, tap } from 'rxjs/operators';

type MachineList = IListResponse<ISerialisedClientEquipment>;

@Injectable()
export class TicketsSupportMultimachineEquipmentEndpoint extends Endpoint<
  ClientEquipment,
  ISerialisedClientEquipment
> {
  private readonly url =
    this.config.url + 'tickets/multimachinesupports/equipments/';

  constructor(
    @Inject(API_SERVICE_CONFIG) private config: IApiServiceConfig,
    private http: HttpClient
  ) {
    super(ClientEquipment.fromSerialised);
  }

  getAll(
    filters: {},
    source?: Observable<MachineList>,
    store?: BehaviorSubject<Response<ClientEquipment[]>>
  ): Observable<Response<ClientEquipment[]>> {
    let query = '';
    Object.keys(filters).forEach((key) => (query += `&${key}=${filters[key]}`));

    const defaultSource = this.http.get<MachineList>(
      `${this.url}all?size=100&page=0${query}`
    );
    return this.list(source || defaultSource, store);
  }

  getTicketMachines(
    ticketId: string,
    source?: Observable<MachineList>,
    store?: BehaviorSubject<Response<ClientEquipment[]>>
  ): Observable<Response<ClientEquipment[]>> {
    const defaultSource = this.http.get<MachineList>(`${this.url}${ticketId}/`);

    return this.list(source || defaultSource, store);
  }

  get(
    id: string,
    source?: Observable<ISerialisedClientEquipment>,
    store?: BehaviorSubject<Response<ClientEquipment[]>>
  ): Observable<Response<ClientEquipment>> {
    const defaultSource = this.http.get<ISerialisedClientEquipment>(
      `${this.url}${id}/`
    );
    return this.retrieve(source || defaultSource, store);
  }

  post(
    ticketId: string,
    machine: ClientEquipment,
    source?: Observable<ISerialisedClientEquipment>,
    store?: BehaviorSubject<Response<ClientEquipment[]>>
  ): Observable<Response<ClientEquipment>> {
    const body = {
      equipmentId: machine.id,
      ticketId: ticketId,
    };
    const defaultSource = this.http.post<ISerialisedClientEquipment>(
      `${this.url}`,
      body
    );
    return this.add(source || defaultSource, store);
  }

  patch(
    ticketId: string,
    machine: UnsavedClientEquipment,
    source?: Observable<ISerialisedClientEquipment>,
    store?: BehaviorSubject<Response<ClientEquipment[]>>
  ): Observable<Response<ClientEquipment>> {
    const { ...body } = machine.toSerialised();
    const defaultSource = this.http.patch<ISerialisedClientEquipment>(
      `${this.url}${ticketId}/`,
      body
    );

    return defaultSource.pipe(
      tap((instance) =>
        this.update(
          source || defaultSource,
          ClientEquipment.fromSerialised(instance),
          store
        )
      ),
      map(
        (serialiser) => new Response(ClientEquipment.fromSerialised(serialiser))
      )
    );
  }

  delete(
    ticketId: string,
    machineId: string,
    source?: Observable<{}>,
    store?: BehaviorSubject<Response<ClientEquipment[]>>
  ): Observable<Response<{}>> {
    const defaultSource = this.http.delete<{}>(
      `${this.url}${ticketId}/${machineId}/`
    );
    return this.remove(source || defaultSource, ticketId + machineId, store);
  }
}
