import { Injectable } from '@angular/core';
import { ApiWorkOrderScopesAdapterService } from '@fullyops/data/work-order-scopes/api-adapter.service';
import { WorkOrderScope } from '@fullyops/data/work-order-scopes/work-order-scope';
import { Observable, catchError, throwError } from 'rxjs';
import { createStore } from '@ngneat/elf';
import {
  selectAllEntities,
  setEntities,
  setEntitiesMap,
  updateAllEntities,
  updateEntities,
  withEntities,
} from '@ngneat/elf-entities';

@Injectable({ providedIn: 'root' })
export class WorkOrderScopesService {
  private store = createStore(
    { name: 'work-order-scopes' },
    withEntities<WorkOrderScope, 'label'>({ idKey: 'label' })
  );

  scopes$: Observable<WorkOrderScope[]> = this.store
    .asObservable()
    .pipe(selectAllEntities());

  constructor(private api: ApiWorkOrderScopesAdapterService) {}

  getAll(): Observable<WorkOrderScope[]> {
    this.api.get().subscribe((res) => this.store.update(setEntities(res)));
    return this.scopes$;
  }

  toggleActive(label: string) {
    const orig = this.store.getValue().entities[label];
    const op = (s: WorkOrderScope) => ({ ...s, active: !s.active });
    this.store.update(updateEntities(label, op));
    this.api
      .update(op(orig))
      .pipe(
        catchError((err) => {
          this.store.update(updateEntities(label, orig));
          return throwError(() => err);
        })
      )
      .subscribe((res) => this.store.update(updateEntities(res.label, res)));
  }

  setDefault(label: string) {
    const orig = this.store.getValue();
    this.store.update(
      updateAllEntities((s) => {
        if (s.label === label) {
          return { ...s, isDefault: true };
        }
        return { ...s, isDefault: false };
      })
    );
    this.api
      .update({ ...orig.entities[label], isDefault: true })
      .pipe(
        catchError((err) => {
          this.store.update(setEntitiesMap(orig.entities));
          return throwError(() => err);
        })
      )
      .subscribe((res) => this.store.update(updateEntities(res.label, res)));
  }
}
