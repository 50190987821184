import { Injectable } from '@angular/core';
import { User, UnsavedUser, UsersEndpoint } from '@fullyops/legacy/data';
import { UiService } from './ui.service';

@Injectable({
  providedIn: 'root',
})
export class UiUsersService extends UiService<
  User,
  UnsavedUser,
  UsersEndpoint
> {
  constructor(protected usersEndpoint: UsersEndpoint) {
    super(usersEndpoint.createStore(), usersEndpoint);
  }

  getMe() {
    return this.usersEndpoint.getMe();
  }

  resetPassword(params: { language: string }) {
    return this.usersEndpoint.resetPassword({ language: params.language });
  }

  updateImage(image: File, id) {
    return this.usersEndpoint.updatePicture(image, id);
  }
}
