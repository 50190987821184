<mat-toolbar class="breadcrumb">
  <mat-toolbar-row
    [ngClass]="{
      'max-w-screen-sm': maxSize == 'sm',
      'max-w-screen-md': maxSize == 'md',
      'max-w-screen-lg': maxSize == 'lg',
      'max-w-screen-xl': maxSize == 'xl',
      'max-w-screen-2xl': maxSize == '2xl'
    }"
  >
    <div class="breadcrumb-title-container">
      <span class="glyphicon glyphicon-plus">{{ header | i18nextCap }}</span>
      <span style="flex: 1 1 auto"></span>
    </div>
    <ng-container *ngIf="tabs">
      <div class="tab-container">
        <button
          *ngFor="let tab of tabs._tabs; index as i"
          mat-button
          [class.mat-flat-button]="tabs.selectedIndex === i"
          color="primary"
          type="button"
          (click)="changeTab(i)"
        >
          {{ tab.textLabel | i18nextCap }}
        </button>
      </div>
    </ng-container>
    <ng-container *ngFor="let button of specialActionButtons; index as i">
      <div class="special-action-buttons-container">
        <button
          mat-button
          [class]="button.color"
          type="button"
          (click)="fireAction(button.label)"
        >
          {{ button.label | i18nextCap }}
        </button>
      </div>
    </ng-container>
    <!-- Delete button -->
    <ng-container *ngIf="actionButtons.warn">
      <button
        mat-button
        color="warn"
        type="button"
        [hideIfNotAllowed]="actionButtons.warn.permissions"
        (click)="fireAction(actionButtons.warn.label)"
        [attr.data-testid]="actionButtons.warn.dataTestId"
      >
        {{ 'forms:' + actionButtons.warn.label | i18nextCap }}
      </button>
    </ng-container>
    <ng-container *ngIf="actionButtons.secondary">
      <button
        mat-button
        color="primary"
        type="button"
        [hideIfNotAllowed]="actionButtons.secondary.permissions"
        (click)="fireAction(actionButtons.secondary.label)"
        [attr.data-testid]="actionButtons.secondary.dataTestId"
      >
        {{ 'forms:' + actionButtons.secondary.label | i18nextCap }}
      </button>
    </ng-container>
    <ng-container *ngIf="actionButtons.primary">
      <button
        type="button"
        mat-flat-button
        color="primary"
        [hideIfNotAllowed]="actionButtons.primary.permissions"
        (click)="fireAction(actionButtons.primary.label)"
        [attr.data-testid]="actionButtons.primary.dataTestId"
      >
        {{ 'forms:' + actionButtons.primary.label | i18nextCap }}
      </button>
    </ng-container>
  </mat-toolbar-row>
</mat-toolbar>
