import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FieldType, ListItem, ListItemColumn } from '../utils/crm-types';
import { Sort } from '@angular/material/sort';
import { filter, tap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { ActivityType } from '@fullyops/legacy/data';

@Component({
  selector: 'crm-list-container',
  templateUrl: './list-container.component.html',
  styleUrls: ['./list-container.component.scss'],
})
export class ListContainerComponent implements OnInit {
  @Input() list$: BehaviorSubject<ListItem[]> = new BehaviorSubject([]);
  @Output() openEvent = new EventEmitter<string>();
  @Output() loadMore = new EventEmitter<string>();

  columns: ListItemColumn[] = [];
  columnType = FieldType;
  ActivityType = ActivityType;

  filteredItems$ = new BehaviorSubject<ListItem[]>([]);

  constructor() {}

  ngOnInit() {
    this.list$.subscribe((e) => {});
    this.list$
      .pipe(
        filter((list) => list.length > 0),
        tap((_) => (this.columns = []))
      )
      .subscribe((list) => {
        this.parseListColumns(list);
        this.filteredItems$.next(list);
      });

    this.list$
      .pipe(
        filter((list) => list.length == 0),
        tap((_) => (this.columns = []))
      )
      .subscribe((_) => this.filteredItems$.next([]));
  }

  parseListColumns(list: ListItem[]) {
    Object.entries((list[0] as ListItem).columns).forEach(([k, value]) => {
      // Max of 6 columns
      if (k === 'rowType' || this.columns.length > 5) {
        return;
      }

      this.columns.push({ ...value, key: k });
    });
  }

  get desktopColumnSize() {
    return Math.floor(12 / this.columns.length);
  }

  get tabletColumnSize() {
    return Math.floor(8 / this.columns.length);
  }

  get mobileColumnSize() {
    return Math.floor(3 / this.columns.length);
  }

  // For now, it will be a simple alphabetical ordering. To be improved in the filters task.
  sortData(sort: Sort) {
    const data = this.filteredItems$.getValue().slice();
    if (!sort.active || sort.direction === '') {
      this.filteredItems$.next(data);
      return;
    }

    this.filteredItems$.next(
      data.sort((a: ListItem, b: ListItem) => {
        const isAsc = sort.direction === 'asc';

        for (const itr in this.columns) {
          if (this.columns[itr].key === sort.active) {
            const valueA = a.columns[this.columns[itr].key].value;
            const valueB = b.columns[this.columns[itr].key].value;
            return compare(valueA, valueB, isAsc);
          }
        }

        return 0;
      })
    );
    return;
  }

  onOpen(id: string) {
    this.openEvent.emit(id);
  }

  onLoadMore() {
    this.loadMore.emit();
  }
}

// For now, it will be a simple alphabetical ordering. To be improved in the filters task.
function compare(a: number | string, b: number | string, isAsc: boolean) {
  // This lowercases the string and removes special characters like 'é' and 'á'to 'e' and 'a'
  if (typeof a == 'string') {
    a = a
      .toLocaleLowerCase()
      .normalize('NFD')
      .replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '');
  }

  if (typeof b == 'string') {
    b = b
      .toLocaleLowerCase()
      .normalize('NFD')
      .replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '');
  }

  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
