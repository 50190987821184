import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { ComponentInOutAnimation } from '../utils/component-base-animation';

@Component({
  selector: 'crm-empty-warn',
  templateUrl: './empty-warn.component.html',
  styleUrls: ['./empty-warn.component.scss'],
  animations: [ComponentInOutAnimation.AnimTrigger],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmptyWarnComponent implements OnInit {
  @Input() message: string;
  @Input() fullscreen = false;
  @Input() actionLabel: string;
  @Input() isDisabled: boolean;
  @Output() actionEvent = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  onAction() {
    this.actionEvent.emit();
  }
}
