import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import {
  Activity,
  ActivityType,
  ActivityLinkType,
  TicketType,
  TicketSupport,
  TicketRequest,
} from '@fullyops/legacy/data';
import { ComponentInOutAnimation } from '@fullyops/legacy/ui/ui-shared/utils/component-base-animation';
import { PermissionType } from '@fullyops/legacy/ui/ui-shared/utils/crm-types';

interface TicketItem {
  readonly id: string;
  readonly type: string;
  readonly name: string;
  readonly icon: string;
  readonly completed: boolean;
  readonly date: string;
}

@Component({
  selector: 'crm-history-ticket',
  templateUrl: './history-ticket.component.html',
  styleUrls: ['./history-ticket.component.scss'],
  animations: [ComponentInOutAnimation.AnimTrigger],
})
export class HistoryTicketComponent implements OnInit {
  @Input() ticket: TicketItem;
  @Output() openTicketEvent = new EventEmitter<{
    ticketId: string;
    ticketType: ActivityLinkType;
  }>();

  TicketType = TicketType;
  ticketPermissions = [];

  ngOnInit() {
    switch (this.ticket.type) {
      case TicketType.REQUEST:
        this.ticketPermissions.push(
          PermissionType.CAN_ACCESS_REQUEST_TICKETS,
          PermissionType.CAN_ACCESS_OWN_REQUEST_TICKETS
        );
        break;
      case TicketType.SUPPORT:
        this.ticketPermissions.push(PermissionType.CAN_ACCESS_SUPPORT_TICKETS);
        break;
      default:
        break;
    }
  }

  openTicket() {
    this.openTicketEvent.emit({
      ticketId: this.ticket.id,
      ticketType: this.ticket.type as ActivityLinkType,
    });
  }
}
