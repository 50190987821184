import { Response } from '../Response';
import { Endpoint } from '../Endpoint';
import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { API_SERVICE_CONFIG } from '../ApiServiceConfig';

import { Observable } from 'rxjs';

import { Role } from '../../models/v0/Role';
import { ISerialisedRole } from '../../models/v0/serialisations/ISerialisedRole';
import { IApiServiceConfig } from '../IApiServiceConfig';

@Injectable()
export class UsersRolesEndpoint extends Endpoint<Role, ISerialisedRole> {
  private readonly url = this.config.url + 'users/roles/';

  constructor(
    @Inject(API_SERVICE_CONFIG) private config: IApiServiceConfig,
    private http: HttpClient
  ) {
    super(Role.fromSerialised);
  }

  post(
    userId: string,
    roleId: string,
    source?: Observable<ISerialisedRole>
  ): Observable<Response<Role>> {
    const defaultSource = this.http.post<ISerialisedRole>(
      `${this.url}${userId}/${roleId}/`,
      {}
    );
    return this.add(source || defaultSource, null);
  }

  delete(
    userId: string,
    roleId: string,
    source?: Observable<{}>
  ): Observable<Response<{}>> {
    const defaultSource = this.http.delete<{}>(
      `${this.url}${userId}/${roleId}/`
    );
    return this.remove(source || defaultSource, null);
  }
}
