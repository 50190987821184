import { ISerialisedSubProductVariant } from './serialisations/ISerialisedSubProductVariant';
import { UnsavedSubProductVariant } from './UnsavedSubProductVariant';

export class SubProductVariant {
  constructor(
    readonly id: string,
    readonly name: string,
    readonly price: number,
    readonly stock: number,
    readonly description: string,
    readonly imageUri: string,
    readonly subProductId: string,
    readonly accessories: string[]
  ) {
    if (name) this.name = name.trim();
    if (description) this.description = description.trim();
  }

  static fromSerialised(serialised: ISerialisedSubProductVariant) {
    return new SubProductVariant(
      serialised.id,
      serialised.name,
      serialised.price,
      serialised.stock,
      serialised.description,
      serialised.imageUri,
      serialised.subProductId,
      serialised.accessories
    );
  }

  toDraft(): UnsavedSubProductVariant {
    return UnsavedSubProductVariant.fromSerialised({
      ...this,
    });
  }

  toSerialised(): ISerialisedSubProductVariant {
    return {
      id: this.id,
      name: this.name,
      price: this.price,
      stock: this.stock,
      description: this.description,
      imageUri: this.imageUri,
      subProductId: this.subProductId,
      accessories: this.accessories,
    };
  }
}
