import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class UiServiceWithCache {
  protected cacheExpiryMinutes = 5;
  protected cacheTime: moment.Moment;

  isCacheValid(): boolean {
    if (!this.cacheTime) {
      return false;
    }

    const expiryTime = this.cacheTime
      .clone()
      .add(this.cacheExpiryMinutes, 'minutes');
    return moment().isBefore(expiryTime);
  }

  createQueryParams = (params) => {
    return Object.keys(params)
      .map((k) => `${k}=${encodeURI(params[k])}`)
      .join('&');
  };
}
