<mat-progress-bar
  *ngIf="loading$ | async"
  mode="indeterminate"
></mat-progress-bar>

<form class="login" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
  <img
    class="client-logo"
    src="assets/svg/fullyops-logo-white.svg"
    alt="FullyOps Logo"
  />
  <br />
  <mat-form-field appearance="outline">
    <mat-label>{{ 'validation:username' | i18nextCap }}</mat-label>
    <input matInput type="text" name="username" formControlName="username" />
    <mat-error>{{ 'validation:youMustEnterAValue' | i18nextCap }}</mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Password</mat-label>
    <input
      matInput
      name="password"
      formControlName="password"
      [type]="passwordHidden ? 'password' : 'text'"
    />
    <button
      mat-icon-button
      matSuffix
      type="button"
      (click)="passwordHidden = !passwordHidden"
      [attr.aria-label]="'Hide password'"
      [attr.aria-pressed]="passwordHidden"
    >
      <mat-icon>{{
        passwordHidden ? 'visibility_off' : 'visibility'
      }}</mat-icon>
    </button>
    <mat-error>{{ 'validation:youMustEnterAValue' | i18nextCap }}</mat-error>
  </mat-form-field>

  <button
    class="submit btn-flip"
    [attr.data-front]="'signIn' | i18nextCap"
    [attr.data-back]="'logIn' | i18nextCap"
  ></button>
  <p>
    <a routerLink="/forgot-password">{{
      'translation:forgotPassword' | i18nextCap
    }}</a>
  </p>
</form>
