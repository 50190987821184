import { Response } from '../Response';
import { Endpoint } from '../Endpoint';
import { HttpClient } from '@angular/common/http';
import { IListResponse } from '../IListResponse';
import { Injectable, Inject } from '@angular/core';
import { API_SERVICE_CONFIG } from '../ApiServiceConfig';

import { Observable, BehaviorSubject } from 'rxjs';
import { IApiServiceConfig } from '../IApiServiceConfig';

import { map, tap } from 'rxjs/operators';
import { ISerialisedInterventionUserParts } from '../../models/v0/serialisations/ISerialisedInterventionUserParts';
import { InterventionUserParts } from '../../models/v0/InterventionUserParts';
import { UnsavedInterventionUserParts } from '../../models/v0/UnsavedInterventionUserParts';

type PartList = IListResponse<ISerialisedInterventionUserParts>;

@Injectable()
export class InterventionUserPartsEndpoint extends Endpoint<
  InterventionUserParts,
  ISerialisedInterventionUserParts
> {
  private readonly url = this.config.url + 'interventions/userparts';

  constructor(
    @Inject(API_SERVICE_CONFIG) private config: IApiServiceConfig,
    private http: HttpClient
  ) {
    super(InterventionUserParts.fromSerialised);
  }

  getAll(
    filters: {},
    source?: Observable<PartList>,
    store?: BehaviorSubject<Response<InterventionUserParts[]>>
  ): Observable<Response<InterventionUserParts[]>> {
    let query = '';
    Object.keys(filters).forEach((key) => (query += `&${key}=${filters[key]}`));

    const defaultSource = this.http.get<PartList>(
      `${this.url}?size=100&page=0${query}`
    );
    return this.list(source || defaultSource, store);
  }

  get(
    id: string,
    source?: Observable<ISerialisedInterventionUserParts>,
    store?: BehaviorSubject<Response<InterventionUserParts[]>>
  ): Observable<Response<InterventionUserParts>> {
    const defaultSource = this.http.get<ISerialisedInterventionUserParts>(
      `${this.url}${id}/`
    );
    return this.retrieve(source || defaultSource, store);
  }

  post(
    part: UnsavedInterventionUserParts,
    source?: Observable<ISerialisedInterventionUserParts>,
    store?: BehaviorSubject<Response<InterventionUserParts[]>>
  ): Observable<Response<InterventionUserParts>> {
    const body = {
      interventionId: part.interventionId,
      partId: part.part.id,
      quantity: part.quantity,
      userId: part.user.id,
    };
    const defaultSource = this.http.post<ISerialisedInterventionUserParts>(
      this.url,
      body
    );
    return this.add(source || defaultSource, store);
  }

  patch(
    interventionId: string,
    partId: string,
    userId: string,
    quantity: number,
    source?: Observable<ISerialisedInterventionUserParts>,
    store?: BehaviorSubject<Response<InterventionUserParts[]>>
  ): Observable<Response<InterventionUserParts>> {
    const body = {
      quantity,
    };
    const defaultSource = this.http.patch<ISerialisedInterventionUserParts>(
      `${this.url}/${interventionId}/${partId}/${userId}/`,
      body
    );

    return defaultSource.pipe(
      tap((instance) =>
        this.update(
          source || defaultSource,
          InterventionUserParts.fromSerialised(instance),
          store
        )
      ),
      map(
        (serialiser) =>
          new Response(InterventionUserParts.fromSerialised(serialiser))
      )
    );
  }

  delete(
    interventionId: string,
    partId: string,
    userId: string,
    source?: Observable<{}>,
    store?: BehaviorSubject<Response<InterventionUserParts[]>>
  ): Observable<Response<{}>> {
    const defaultSource = this.http.delete<{}>(
      `${this.url}/${interventionId}/${partId}/${userId}/`
    );
    return this.remove(
      source || defaultSource,
      interventionId + partId + userId,
      store
    );
  }
}
