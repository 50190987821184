import { Response } from '../Response';
import { Endpoint } from '../Endpoint';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IListResponse } from '../IListResponse';
import { Injectable, Inject } from '@angular/core';
import { API_SERVICE_CONFIG } from '../ApiServiceConfig';

import { Observable, BehaviorSubject } from 'rxjs';

import { User } from '../../models/v0/User';
import { ISerialisedUser } from '../../models/v0/serialisations/ISerialisedUser';
import { IApiServiceConfig } from '../IApiServiceConfig';
import { UnsavedUser } from '../../models/v0/UnsavedUser';
import { tap, map } from 'rxjs/operators';

type UserList = IListResponse<ISerialisedUser>;

@Injectable()
export class UsersEndpoint extends Endpoint<User, ISerialisedUser> {
  private readonly url = this.config.url + 'users/';

  constructor(
    @Inject(API_SERVICE_CONFIG) private config: IApiServiceConfig,
    private http: HttpClient
  ) {
    super(User.fromSerialised);
  }

  getAll(
    filters: {},
    source?: Observable<UserList>,
    store?: BehaviorSubject<Response<User[]>>
  ): Observable<Response<User[]>> {
    let query = '';
    Object.keys(filters).forEach((key) => (query += `&${key}=${filters[key]}`));

    const defaultSource = this.http.get<UserList>(`${this.url}?page=0${query}`);
    return this.list(source || defaultSource, store);
  }

  get(
    id: string,
    source?: Observable<ISerialisedUser>,
    store?: BehaviorSubject<Response<User[]>>
  ): Observable<Response<User>> {
    const defaultSource = this.http.get<ISerialisedUser>(`${this.url}${id}/`);
    return this.retrieve(source || defaultSource, store);
  }

  getMe(
    source?: Observable<ISerialisedUser>,
    store?: BehaviorSubject<Response<User[]>>
  ): Observable<Response<User>> {
    const defaultSource = this.http.get<ISerialisedUser>(`${this.url}me`);
    return this.retrieve(source || defaultSource, store);
  }

  post(
    user: UnsavedUser,
    source?: Observable<ISerialisedUser>,
    store?: BehaviorSubject<Response<User[]>>
  ): Observable<Response<User>> {
    const defaultSource = this.http.post<ISerialisedUser>(
      this.url,
      user.toSerialised()
    );
    return this.add(source || defaultSource, store);
  }

  patch(
    id: string,
    user: UnsavedUser,
    source?: Observable<ISerialisedUser>,
    store?: BehaviorSubject<Response<User[]>>
  ) {
    const { password, ...body } = user;
    const defaultSource = this.http.patch<ISerialisedUser>(
      `${this.url}${id}/`,
      body
    );

    return defaultSource.pipe(
      tap((instance) =>
        this.update(
          source || defaultSource,
          User.fromSerialised(instance),
          store
        )
      ),
      map((serialiser) => new Response(User.fromSerialised(serialiser)))
    );
  }

  delete(
    id: string,
    source?: Observable<{}>,
    store?: BehaviorSubject<Response<User[]>>
  ): Observable<Response<{}>> {
    const defaultSource = this.http.delete<{}>(`${this.url}${id}/`);
    return this.remove(source || defaultSource, id, store);
  }

  resetPassword(params: { language: string }) {
    return this.http.post<ISerialisedUser>(`${this.url}me/reset-password`, {
      language: params.language,
    });
  }

  updatePicture(image: File, id: string) {
    const formData: FormData = new FormData();
    formData.append('file', image, image.name);
    return this.http.post<{ file: FormData }>(
      `${this.url}${id}/profile-picture`,
      formData
    );
  }
}
