import { Response } from '../Response';
import { Endpoint } from '../Endpoint';
import { HttpClient } from '@angular/common/http';
import { IListResponse } from '../IListResponse';
import { Injectable, Inject } from '@angular/core';
import { API_SERVICE_CONFIG } from '../ApiServiceConfig';
import { IApiServiceConfig } from '../IApiServiceConfig';

import { Observable, BehaviorSubject } from 'rxjs';

import { ProductConfigSimulation } from '../../models/v0/ProductConfigSimulation';
import { UnsavedProductConfigSimulation } from '../../models/v0/UnsavedProductConfigSimulation';
import {
  ISerialisedProductConfigSimulation,
  ISerialisedProductConfigSimulationListItem,
} from '../../models/v0/serialisations/ISerialisedProductConfigSimulation';
import { ProductConfigSimulationListItem } from '../../models/v0/ProductConfigSimulationListItem';
import { map } from 'rxjs/operators';
import { Label } from '../../models/v0/Label';

type ProductConfigSimulationList =
  IListResponse<ISerialisedProductConfigSimulation>;

@Injectable()
export class ProductsConfigSimulationsEndpoint extends Endpoint<
  ProductConfigSimulation,
  ISerialisedProductConfigSimulation
> {
  private readonly url = this.config.url + 'product/simulations/';

  constructor(
    @Inject(API_SERVICE_CONFIG) private config: IApiServiceConfig,
    private http: HttpClient
  ) {
    super(ProductConfigSimulation.fromSerialised);
  }

  getAll(filter?: {}): Observable<Response<ProductConfigSimulationListItem[]>> {
    const defaultSource = this.http.get<
      IListResponse<ISerialisedProductConfigSimulationListItem>
    >(this.url);

    return defaultSource.pipe(
      map((serialised) =>
        serialised.results.map((response) =>
          ProductConfigSimulationListItem.fromSerialised(response)
        )
      ),
      map(
        (newTs) => new Response<ProductConfigSimulationListItem[]>(newTs, null)
      )
    );
  }

  get(
    id: string,
    source?: Observable<ISerialisedProductConfigSimulation>,
    store?: BehaviorSubject<Response<ProductConfigSimulation[]>>
  ): Observable<Response<ProductConfigSimulation>> {
    const defaultSource = this.http.get<ISerialisedProductConfigSimulation>(
      `${this.url}${id}/`
    );
    return this.retrieve(source || defaultSource, store);
  }

  post(
    ProductConfigSimulation: UnsavedProductConfigSimulation,
    source?: Observable<ISerialisedProductConfigSimulation>,
    store?: BehaviorSubject<Response<ProductConfigSimulation[]>>
  ): Observable<Response<ProductConfigSimulation>> {
    const serialization = ProductConfigSimulation.toSerialised();
    const body = {
      ...serialization,
      product: {
        ...serialization.product,
        labels: serialization.product.labels.map((label) => label.id),
      },
    };
    const defaultSource = this.http.post<ISerialisedProductConfigSimulation>(
      this.url,
      body
    );
    return this.add(source || defaultSource, store);
  }

  patch() {}

  delete(
    id: string,
    source?: Observable<{}>,
    store?: BehaviorSubject<Response<ProductConfigSimulation[]>>
  ): Observable<Response<{}>> {
    const defaultSource = this.http.delete<{}>(`${this.url}${id}/`);
    return this.remove(source || defaultSource, id, store);
  }
}
