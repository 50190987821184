import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { UnsavedClientEquipment } from '@fullyops/legacy/data';

class ClientEquipmentForm extends UntypedFormGroup {
  constructor(clientEquipment: UnsavedClientEquipment, dis: boolean) {
    super({});

    Object.keys(clientEquipment).forEach((key) =>
      this.addControl(
        key,
        new UntypedFormControl(
          { value: clientEquipment[key], disabled: dis },
          []
        )
      )
    );

    (this as UntypedFormGroup).get('brand').setValidators(Validators.required);
    (this as UntypedFormGroup).get('model').setValidators(Validators.required);
    (this as UntypedFormGroup)
      .get('serialNumber')
      .setValidators(Validators.required);
  }
}

@Injectable()
export class ClientEquipmentFormService {
  private initialClientEquipment: UnsavedClientEquipment;

  private form: BehaviorSubject<UntypedFormGroup> =
    new BehaviorSubject<UntypedFormGroup>(new UntypedFormGroup({}));
  form$: Observable<UntypedFormGroup> = this.form.asObservable();

  constructor() {}

  initForm(draftClientEquipment: UnsavedClientEquipment, disabled: boolean) {
    this.initialClientEquipment = draftClientEquipment;

    this.form.next(new ClientEquipmentForm(draftClientEquipment, disabled));
  }

  getDraft() {
    return this.initialClientEquipment.clone({
      brand: (this.form.getValue().get('brand') as UntypedFormControl).value,
      model: (this.form.getValue().get('model') as UntypedFormControl).value,
      serialNumber: (
        this.form.getValue().get('serialNumber') as UntypedFormControl
      ).value,
      warranty: (this.form.getValue().get('warranty') as UntypedFormControl)
        .value,
    });
  }

  markAllAsTouched() {
    const currentClientEquipment = this.form.getValue();
    currentClientEquipment.markAllAsTouched();

    this.form.next(currentClientEquipment);
  }
}
