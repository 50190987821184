import { ErrorHandingInterceptor } from './interceptors/ErrorHandingInterceptor';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from './api/api.service';
import { CompaniesEndpoint } from './api/v0/CompaniesEndpoint';
import { ContactsEndpoint } from './api/v0/ContactsEndpoint';
import { MeEndpoint } from './api/v0/MeEndpoint';
import { MeAccessTokenEndpoint } from './api/v0/MeAccessTokenEndpoint';
import { AccessTokenInterceptor } from './interceptors/AccessTokenInterceptor';
import { LoadingHTTPRequestInterceptor } from './interceptors/LoadingHTTPRequestInterceptor';
import {
  API_SERVICE_CONFIG,
  createApiServiceConfig,
  defaultApiServiceConfig,
} from './api/ApiServiceConfig';
import { ProductsEndpoint } from './api/v0/ProductsEndpoint';
import { LabelsEndpoint } from './api/v0/LabelsEndpoint';
import { TicketsRequestEndpoint } from './api/v0/TicketsRequestEndpoint';
import { UsersEndpoint } from './api/v0/UsersEndpoint';
import { TicketsSupportEndpoint } from './api/v0/TicketsSupportEndpoint';
import { TicketsSupportMultimachineEndpoint } from './api/v0/TicketsSupportMultimachineEndpoint';
import { TicketsSupportMultimachineEquipmentEndpoint } from './api/v0/TicketsSupportMultimachineEquipmentEndpoint';
import { TicketsSupportAnomalyEndpoint } from './api/v0/TicketsSupportAnomalyEndpoint';
import { TicketsRequestStatusesEndpoint } from './api/v0/TicketsRequestStatusesEndpoint';
import { TicketsSupportStatusesEndpoint } from './api/v0/TicketsSupportStatusesEndpoint';
import { PartsEndpoint } from './api/v0/PartsEndpoint';
import { UploadEndpoint } from './api/v0/UploadEndpoint';
import { ReportEndpoint } from './api/v0/ReportEndpoint';
import { ActivitiesEndpoint } from './api/v0/ActivitiesEndpoint';
import { PermissionsEndpoint } from './api/v0/PermissionsEndpoint';
import { HistoriesEndpoint } from './api/v0/HistoriesEndpoint';
import { ProductsConfigEndpoint } from './api/v0/ProductsConfigEndpoint';
import { SubProductsEndpoint } from './api/v0/SubProductsEndpoint';
import { SubProductVariantsEndpoint } from './api/v0/SubProductVariantsEndpoint';
import { TicketsSaleEndpoint } from './api/v0/TicketsSaleEndpoint';
import { UserPartsEndpoint } from './api/v0/UserPartsEndpoint';
import { PasswordEndpoint } from './api/v0/PasswordEndpoint';
import { TicketsSaleStatusesEndpoint } from './api/v0/TicketsSaleStatusesEndpoint';
import { ProductsConfigSimulationsEndpoint } from './api/v0/ProductsConfigSimulationsEndpoint';
import { TicketsSaleProductsConfigEndpoint } from './api/v0/TicketsSaleProductsConfigEndpoint';
import { TicketsSaleProductsConfigSimulationEndpoint } from './api/v0/TicketsSaleProductsConfigSimulationEndpoint';
import { ClientEquipmentsEndpoint } from './api/v0/ClientEquipmentsEndpoint';
import { AnomaliesEndpoint } from './api/v0/AnomaliesEndpoint';
import { InterventionsEndpoint } from './api/v0/InterventionsEndpoint';
import { InterventionPartsEndpoint } from './api/v0/InterventionPartsEndpoint';
import { InterventionUserPartsEndpoint } from './api/v0/InterventionUserPartsEndpoint';
import { InterventionsAnomalyEndpoint } from './api/v0/InterventionsAnomalyEndpoint';
import { InterventionsClientEquipmentEndpoint } from './api/v0/InterventionsClientEquipmentEndpoint';
import { UsersPermissionsEndpoint } from './api/v0/UsersPermissionsEndpoint';
import { RolesEndpoint } from './api/v0/RolesEndpoint';
import { UsersRolesEndpoint } from './api/v0/UsersRolesEndpoint';
import { LossMotivesEndpoint } from './api/v0/LossMotivesEndpoint';
import { WorkPeriodEndpoint } from './api/v0/WorkPeriodEndpoint';
import { WorkPeriodAssigneeEndpoint } from './api/v0/WorkPeriodAssigneeEndpoint';
import { TicketsSupportMultimachineAssigneeEndpoint } from './api/v0/TicketsSupportMultimachineAssigneeEndpoint';
import { InstallationTicketStatusEndpoint } from './api/v0/InstallationTicketStatusEndpoint';
import { InstallationTicketAssigneeEndpoint } from './api/v0/InstallationTicketAssigneeEndpoint';
import { InstallationTicketClientEquipmentEndpoint } from './api/v0/InstallationTicketClientEquipmentEndpoint';
import { InstallationTicketContactEndpoint } from './api/v0/InstallationTicketContactEndpoint';
import { InstallationTicketEndpoint } from './api/v0/InstallationTicketEndpoint';
import { TravelEndpoint } from './api/v0/TravelEndpoint';
import { TravelAssigneeEndpoint } from './api/v0/TravelAssigneeEndpoint';
import { TicketsSupportContactEndpoint } from './api/v0/TicketsSupportContactEndpoint';
import { CompanyAssigneeEndpoint } from './api/v0/CompanyAssigneeEndpoint';
import { environment } from '../../../environments/environment';
import { CustomApplicationReferrerInterceptor } from '@fullyops/legacy/data/interceptors/CustomApplicationReferrerInterceptor';

const apiServiceConfig = () => {
  return createApiServiceConfig(environment.apiUrl);
};

@NgModule({
  imports: [HttpClientModule],
  providers: [
    // { provide: HTTP_INTERCEPTORS, useClass: CsrfInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: RefreshTokenInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AccessTokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandingInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingHTTPRequestInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomApplicationReferrerInterceptor,
      multi: true,
    },
    { provide: API_SERVICE_CONFIG, useFactory: apiServiceConfig },
    CookieService,
    ApiService,
    ActivitiesEndpoint,
    LabelsEndpoint,
    PartsEndpoint,
    ProductsEndpoint,
    ProductsConfigEndpoint,
    ProductsConfigSimulationsEndpoint,
    SubProductsEndpoint,
    SubProductVariantsEndpoint,
    ContactsEndpoint,
    CompaniesEndpoint,
    TicketsRequestEndpoint,
    TicketsSupportEndpoint,
    TicketsSupportMultimachineEndpoint,
    TicketsSupportMultimachineEquipmentEndpoint,
    TicketsSupportAnomalyEndpoint,
    TicketsSaleEndpoint,
    TicketsRequestStatusesEndpoint,
    TicketsSupportStatusesEndpoint,
    TicketsSaleStatusesEndpoint,
    TicketsSaleProductsConfigEndpoint,
    TicketsSaleProductsConfigSimulationEndpoint,
    ClientEquipmentsEndpoint,
    LossMotivesEndpoint,
    UsersEndpoint,
    MeEndpoint,
    UploadEndpoint,
    ReportEndpoint,
    MeAccessTokenEndpoint,
    PermissionsEndpoint,
    UsersRolesEndpoint,
    UsersPermissionsEndpoint,
    RolesEndpoint,
    HistoriesEndpoint,
    UserPartsEndpoint,
    PasswordEndpoint,
    AnomaliesEndpoint,
    InterventionsEndpoint,
    InterventionsAnomalyEndpoint,
    InterventionsClientEquipmentEndpoint,
    InterventionPartsEndpoint,
    InterventionUserPartsEndpoint,
    WorkPeriodEndpoint,
    WorkPeriodAssigneeEndpoint,
    TicketsSupportMultimachineAssigneeEndpoint,
    InstallationTicketStatusEndpoint,
    InstallationTicketAssigneeEndpoint,
    InstallationTicketClientEquipmentEndpoint,
    InstallationTicketContactEndpoint,
    InstallationTicketEndpoint,
    TravelEndpoint,
    TravelAssigneeEndpoint,
    TicketsSupportContactEndpoint,
    CompanyAssigneeEndpoint,
  ],
})
export class DataModule {}
