import { Injectable } from '@angular/core';
import { TicketsSupportContactEndpoint } from '@fullyops/legacy/data';

@Injectable({
  providedIn: 'root',
})
export class UiTicketsSupportMultimachineContactService {
  constructor(protected endpoint: TicketsSupportContactEndpoint) {}

  assignContact(contactId: string, supportTicketId: string) {
    return this.endpoint.assignContact(contactId, supportTicketId);
  }

  unAssignContact(contactId: string, supportTicketId: string) {
    return this.endpoint.unAssignContact(contactId, supportTicketId);
  }
}
