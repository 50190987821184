<section *ngIf="subProducts$ | async as subProducts" [formGroup]="formGroup">
  <ng-container *ngIf="subProducts.length">
    <h1>{{ 'subproducts' | i18nextCap }}</h1>
    <mat-accordion>
      <ng-container *ngFor="let subProduct of subProducts; let i = index">
        <mat-expansion-panel [expanded]="step === i" (opened)="setStep(i)">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div [formGroup]="$any(formGroup.controls[i])">
                {{ subProduct.name }}
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <crm-sub-product-variants
            [variants]="subProduct.variants"
            [formGroup]="$any(formGroup.controls[i])"
            (selectVariantEvent)="onVariantSelected(i)"
          ></crm-sub-product-variants>
          <mat-action-row>
            <button mat-button type="button" color="warn" (click)="prevStep()">
              {{ 'previous' | i18nextCap }}
            </button>
            <button
              mat-button
              type="button"
              color="primary"
              (click)="nextStep()"
            >
              {{ 'next' | i18nextCap }}
            </button>
          </mat-action-row>
        </mat-expansion-panel>
      </ng-container>
    </mat-accordion>
  </ng-container>
</section>
