import { Injectable } from '@angular/core';
import { UiService } from './ui.service';
import {
  TicketSupportMultimachine,
  UnsavedTicketSupportMultimachine,
  TicketsSupportMultimachineEndpoint,
} from '@fullyops/legacy/data';

@Injectable({
  providedIn: 'root',
})
export class UiTicketsSupportMultimachineService extends UiService<
  TicketSupportMultimachine,
  UnsavedTicketSupportMultimachine,
  TicketsSupportMultimachineEndpoint
> {
  constructor(
    protected ticketsSupportMultimachineEndpoint: TicketsSupportMultimachineEndpoint
  ) {
    super(
      ticketsSupportMultimachineEndpoint.createStore(),
      ticketsSupportMultimachineEndpoint
    );
  }
}
