<crm-breadcrumb
  [header]="title"
  [tabs]="tabs"
  [actionButtons]="actions"
  (actionEvent)="onActionEvent($event)"
></crm-breadcrumb>
<crm-container [maxSize]="'xl'">
  <div class="board">
    <ng-container *ngIf="companies$ | async as companies">
      <crm-pipeline-filters
        [customFilterFields]="customFilterFields"
        [filters$]="filters$"
        [companies]="companies"
        (filtersChangeEvent)="onFiltersChange($event)"
        [statuses$]="statuses$"
        [page]="title"
        [usersOptions$]="usersOptions$"
      ></crm-pipeline-filters>
    </ng-container>
    <mat-tab-group #tabs dynamicHeight class="header-less-tabs teste">
      <mat-tab label="pipeline">
        <crm-pipeline
          [pipeline$]="pipeline$"
          (openEvent)="onOpen($event)"
          (dropTicket)="onDropTicket($event)"
          (loadMoreTickets)="onloadMoreTickets($event)"
        ></crm-pipeline>
      </mat-tab>
      <mat-tab label="list">
        <crm-tickets
          [listTicketsPage$]="listTicketsPage$"
          (openEvent)="onOpen($event)"
          (pageChangeEvent)="handlePageSelection($event)"
        ></crm-tickets>
      </mat-tab>
    </mat-tab-group>
  </div>
</crm-container>
