import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ComponentInOutAnimation } from '@fullyops/legacy/ui/ui-shared/utils/component-base-animation';
import {
  FieldType,
  ActionButtons,
  PermissionType,
} from '@fullyops/legacy/ui/ui-shared/utils/crm-types';
import { Response, ResponseState, Product } from '@fullyops/legacy/data';
import { map, filter } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'crm-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
  animations: [ComponentInOutAnimation.AnimTrigger],
})
export class ProductsComponent implements OnInit {
  @Input() title: string;
  @Input() products$ = new BehaviorSubject<Response<Product[]>>(new Response());
  @Output() openEvent = new EventEmitter<string>();
  @Output() addNewEvent = new EventEmitter();

  // Response Possible States
  state = ResponseState;

  actions: ActionButtons = {};
  productsList$ = new BehaviorSubject<[]>([]);

  // Seacrh Fielter
  filteredItems$ = new BehaviorSubject<[]>([]);

  ngOnInit() {
    this.initProductsList();

    this.initActionButtons();
  }

  initProductsList() {
    this.products$
      .pipe(
        filter((response) => response.data !== null),
        map((response) => response.data as Product[]),
        map((products) => products.map(this.parseProduct))
      )
      .subscribe((products: []) => {
        this.productsList$.next(products);
        this.filteredItems$.next(products);
      });
  }

  initActionButtons() {
    this.actions.primary = {
      label: 'addNew',
      color: 'primary',
      permissions: [PermissionType.CAN_EDIT_PRODUCTS],
    };
  }

  parseProduct(product: Product) {
    return {
      id: product.id,
      columns: {
        name: { label: 'name', type: FieldType.Text, value: product.name },
        subproducts: {
          label: 'subproducts',
          type: FieldType.Text,
          value: `${product.subproducts.length}`,
        },
        labels: {
          label: 'labels',
          type: FieldType.Array,
          value: product.labels,
        },
      },
    };
  }

  /**
   * Event Handlers
   */

  onFiltersChange(items) {
    this.filteredItems$.next(items);
  }

  onActionEvent(event: string) {
    if (event === 'addNew') {
      this.onAddNew();
    }
  }

  onOpen(id: string) {
    this.openEvent.emit(id);
  }

  onAddNew() {
    this.addNewEvent.emit();
  }
}
