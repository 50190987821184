import { AutenticanteErrorMessages } from './AutenticanteErrorMessages';
import { CompanyRequestErrorMessages } from './CompanyRequestErrorMessagens';
import { TicketRequestErrorMessages } from './TicketRequestErrorMessages';
import { TicketSupportErrorMessages } from './TicketSupportErrorMessages';
export type EndpointErrorMessagesType = {
  400: { default: string; [key: string]: string };
  500: { default: string; [key: string]: string };
  [key: string]: { default: string; [key: string]: string };
};

type EndpointErrorMessagesTypeCollection = {
  [key: string]: EndpointErrorMessagesType;
};

type GenericErrorMessagesType = {
  '401': string;
  '4xx': string;
  '5xx': string;
  xxx: string;
};

export const EndpointErrorMessages = {
  '/tickets/requests': TicketRequestErrorMessages,
  '/tickets/multimachinesupports': TicketSupportErrorMessages,
  '/authenticate': AutenticanteErrorMessages,
  '/companies': CompanyRequestErrorMessages,
};

export const GenericErrorMessages: GenericErrorMessagesType = {
  '401': 'error:genericError401Default',
  '4xx': 'error:genericError400Default',
  '5xx': 'error:genericError500Default',
  xxx: 'Unknown Error',
};
