import { ISerialisedUnsavedInterventionClientEquipment } from './serialisations/ISerialisedInterventionClientEquipment';
import { User } from './User';

export class UnsavedInterventionClientEquipment {
  constructor(
    readonly id: string,
    readonly clientEquipmentId: string,
    readonly createdAt: string,
    readonly createdBy: User,
    readonly interventionId: string,
    readonly updatedAt: string,
    readonly updatedBy: User
  ) {}

  static fromSerialised(
    serialised: ISerialisedUnsavedInterventionClientEquipment
  ) {
    const createdBy = User.fromSerialised(serialised.createdBy);
    const updatedBy = User.fromSerialised(serialised.updatedBy);

    return new UnsavedInterventionClientEquipment(
      serialised.id,
      serialised.clientEquipmentId,
      serialised.createdAt,
      createdBy,
      serialised.interventionId,
      serialised.updatedAt,
      updatedBy
    );
  }

  toSerialised(): ISerialisedUnsavedInterventionClientEquipment {
    const createdByObj = this.createdBy.toSerialised();
    const updatedByObj = this.updatedBy.toSerialised();

    return {
      id: this.id,
      clientEquipmentId: this.clientEquipmentId,
      createdAt: this.createdAt,
      createdBy: createdByObj,
      interventionId: this.interventionId,
      updatedAt: this.updatedAt,
      updatedBy: updatedByObj,
    };
  }

  clone(
    partialUnsavedInterventionClientEquipment: PartialUnsavedInterventionClientEquipment
  ): UnsavedInterventionClientEquipment {
    const resolve = (key: keyof UnsavedInterventionClientEquipment) =>
      partialUnsavedInterventionClientEquipment.hasOwnProperty(key)
        ? partialUnsavedInterventionClientEquipment[key]
        : this[key];

    return new UnsavedInterventionClientEquipment(
      resolve('id'),
      resolve('clientEquipmentId'),
      resolve('createdAt'),
      resolve('createdBy'),
      resolve('interventionId'),
      resolve('updatedAt'),
      resolve('updatedBy')
    );
  }
}
type PartialUnsavedInterventionClientEquipment = Partial<
  // tslint:disable-next-line: max-line-length
  Pick<
    UnsavedInterventionClientEquipment,
    | 'id'
    | 'clientEquipmentId'
    | 'createdAt'
    | 'createdBy'
    | 'interventionId'
    | 'updatedAt'
    | 'updatedBy'
  >
>;
