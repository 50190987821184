import { Response } from '../Response';
import { Endpoint } from '../Endpoint';
import { HttpClient } from '@angular/common/http';
import { IListResponse } from '../IListResponse';
import { Injectable, Inject } from '@angular/core';
import { API_SERVICE_CONFIG } from '../ApiServiceConfig';

import { Observable, BehaviorSubject } from 'rxjs';

import { Intervention } from '../../models/v0/Intervention';
import { ISerialisedIntervention } from '../../models/v0/serialisations/ISerialisedIntervention';
import { IApiServiceConfig } from '../IApiServiceConfig';
import { UnsavedIntervention } from '../../models/v0/UnsavedIntervention';
import { map, tap } from 'rxjs/operators';

type InterventionList = IListResponse<ISerialisedIntervention>;

@Injectable()
export class InterventionsEndpoint extends Endpoint<
  Intervention,
  ISerialisedIntervention
> {
  private readonly url = this.config.url + 'interventions/';

  constructor(
    @Inject(API_SERVICE_CONFIG) private config: IApiServiceConfig,
    private http: HttpClient
  ) {
    super(Intervention.fromSerialised);
  }

  getAll(
    filters: {},
    source?: Observable<InterventionList>,
    store?: BehaviorSubject<Response<Intervention[]>>
  ): Observable<Response<Intervention[]>> {
    let query = '';
    Object.keys(filters).forEach((key) => (query += `&${key}=${filters[key]}`));

    const defaultSource = this.http.get<InterventionList>(
      `${this.url}all?size=100&page=0${query}`
    );
    return this.list(source || defaultSource, store);
  }

  get(
    id: string,
    source?: Observable<ISerialisedIntervention>,
    store?: BehaviorSubject<Response<Intervention[]>>
  ): Observable<Response<Intervention>> {
    const defaultSource = this.http.get<ISerialisedIntervention>(
      `${this.url}${id}/`
    );
    return this.retrieve(source || defaultSource, store);
  }

  post(
    intervention: UnsavedIntervention,
    source?: Observable<ISerialisedIntervention>,
    store?: BehaviorSubject<Response<Intervention[]>>
  ): Observable<Response<Intervention>> {
    const body = {
      //closedAt: "2022-06-07T14:53:06.285Z",
      description: intervention.description,
      internalObservation: intervention.internalObservation,
      supportTicketId: intervention.supportTicketId,
      unregisteredParts: intervention.unregisteredParts,
      userObservation: intervention.userObservation,
      closedAt: intervention.closedAt,
    };
    const defaultSource = this.http.post<ISerialisedIntervention>(
      this.url,
      intervention
    );
    return this.add(source || defaultSource, store);
  }

  patch(
    id: string,
    part: UnsavedIntervention,
    source?: Observable<ISerialisedIntervention>,
    store?: BehaviorSubject<Response<Intervention[]>>
  ): Observable<Response<Intervention>> {
    const { ...body } = part.toSerialised();
    const defaultSource = this.http.patch<ISerialisedIntervention>(
      `${this.url}${id}/`,
      body
    );

    return defaultSource.pipe(
      tap((instance) =>
        this.update(
          source || defaultSource,
          Intervention.fromSerialised(instance),
          store
        )
      ),
      map((serialiser) => new Response(Intervention.fromSerialised(serialiser)))
    );
  }

  delete(
    id: string,
    source?: Observable<{}>,
    store?: BehaviorSubject<Response<Intervention[]>>
  ): Observable<Response<{}>> {
    const defaultSource = this.http.delete<{}>(`${this.url}${id}/`);
    return this.remove(source || defaultSource, id, store);
  }
}
