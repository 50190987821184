import { Injectable } from '@angular/core';
import {
  Response,
  SubProductVariantsEndpoint,
  UnsavedSubProductVariant,
  SubProductVariant,
} from '@fullyops/legacy/data';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UiSubProductVariantsService {
  readonly subProductVariants$ = this.subProductVariants.createStore();

  constructor(protected subProductVariants: SubProductVariantsEndpoint) {}

  loadAll() {
    this.loadSubProductVariants();
  }

  loadSubProductVariants() {
    this.subProductVariants
      .getAll(undefined, this.subProductVariants$)
      .subscribe({ error: console.error });
  }

  getSubProductVariant(id: string): Observable<Response<SubProductVariant>> {
    return this.subProductVariants.get(id, undefined, this.subProductVariants$);
  }

  createSubProductVariant(subProductVariant: UnsavedSubProductVariant) {
    return this.subProductVariants.post(
      subProductVariant,
      undefined,
      this.subProductVariants$
    );
  }

  updateSubProductVariant(
    productId: string,
    unsavedSubProductVariants: UnsavedSubProductVariant
  ) {
    return this.subProductVariants.patch(
      productId,
      unsavedSubProductVariants,
      undefined,
      this.subProductVariants$
    );
  }

  removeSubProductVariant(id: string): Observable<Response<{}>> {
    return this.subProductVariants.delete(
      id,
      undefined,
      this.subProductVariants$
    );
  }
}
