import { EndpointErrorMessagesType } from './ErrorHandlingMessages';

export const AutenticanteErrorMessages: EndpointErrorMessagesType = {
  400: { default: 'error:loginWrongCredentials' },
  401: {
    default: 'genericError401Default',
    sessionExpired: 'error:sessionExpired',
  },
  404: { default: 'error:loginWrongCredentials' },
  500: { default: 'error:loginWrongCredentials' },
};
