import { APP_INITIALIZER, LOCALE_ID } from '@angular/core';
import {
  I18NEXT_SERVICE,
  ITranslationService,
  I18NextModule,
  defaultInterpolationFormat,
  I18NextLoadResult,
} from 'angular-i18next';
import i18nextModule from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import XHR from 'i18next-http-backend';

const i18nextOptions = {
  whitelist: ['en', 'pt'],
  fallbackLng: 'pt',
  debug: false, // set debug?
  returnEmptyString: false,
  ns: ['translation', 'validation', 'forms', 'error'],
  interpolation: {
    format: I18NextModule.interpolationFormat(defaultInterpolationFormat),
  },
  keySeparator: '.',

  backend: {
    loadPath: 'assets/locales/{{lng}}/{{ns}}.json',
  },
  // lang detection plugin options
  detection: {
    // order and from where user language should be detected
    order: ['cookie'],

    // keys or params to lookup language from
    lookupCookie: 'lang',

    // cache user language on
    caches: ['cookie'],

    // optional expire and domain for set cookie
    cookieMinutes: 10080, // 7 days
    cookieDomain: 'lang',
  },
};

export function appInit(i18next: ITranslationService) {
  return () => {
    const promise: Promise<I18NextLoadResult> = i18next
      .use(XHR)
      .use(LanguageDetector)
      .init(i18nextOptions)
      .then((result) => {
        // Override wrong default pluralization rules for Portuguese
        // (the defaults treat 0 as singular when it should be plural)
        i18nextModule.services.pluralResolver.addRule('pt', {
          numbers: [1, 2],
          plurals: (n: number) => (n === 1 ? 0 : 1),
        });
        return result;
      });
    return promise;
  };
}

export function localeIdFactory(i18next: ITranslationService) {
  return i18next.language;
}

export const I18N_PROVIDERS = [
  {
    provide: APP_INITIALIZER,
    useFactory: appInit,
    deps: [I18NEXT_SERVICE],
    multi: true,
  },
  {
    provide: LOCALE_ID,
    deps: [I18NEXT_SERVICE],
    useFactory: localeIdFactory,
  },
];
