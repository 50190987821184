import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { I18NextModule } from 'angular-i18next';
import { LoadingComponent } from './ui-shared/loading/loading.component';
import { ErrorComponent } from './ui-shared/error/error.component';
import { WindowService } from './ui-shared/utils/window.service';
import { ButtonComponent } from './ui-shared/button/button.component';

import { HttpClientModule } from '@angular/common/http';

import { CompanyFormService } from './ui-crm/company-form/company-form.service';
import { BreadcrumbComponent } from './ui-shared/breadcrumb/breadcrumb.component';
import { ListContainerComponent } from './ui-shared/list-container/list-container.component';
import { IconComponent } from './ui-shared/icon/icon.component';
import { MultipleSelectComponent } from './ui-shared/multiple-select/multiple-select.component';
import { EmptyWarnComponent } from './ui-shared/empty-warn/empty-warn.component';
import { FormTemplateComponent } from './ui-shared/form-template/form-template.component';
import { FormSelectComponent } from './ui-shared/form-select/form-select.component';
import { FormDateComponent } from './ui-shared/form-date/form-date.component';
import { SignaturePadComponent } from './ui-shared/signature-pad/signature-pad.component';
import { ReportRequestModalComponent } from './ui-shared/report-request-modal/report-request-modal.component';
import { TicketIdentifierPipe } from './ui-shared/utils/tickets-identifier.pipe';
import { DialogComponent } from './ui-shared/dialog/dialog.component';
import { FormAnomaliesAutocompleteComponent } from './ui-shared/form-anomalies-autocomplete/form-anomalies-autocomplete.component';
import { FormPartsAutocompleteComponent } from './ui-shared/form-parts-autocomplete/form-parts-autocomplete.component';
import { ListComponent } from './ui-shared/list/list.component';
import { UploadImageComponent } from './ui-shared/upload-image/upload-image.component';
import { DialogSaveComponent } from './ui-shared/dialog-save/dialog-save.component';
import { CarouselComponent } from './ui-shared/carousel/carousel.component';
import { CalendarComponent } from './ui-shared/calendar/calendar.component';
import { FullCalendarModule } from '@fullcalendar/angular'; // must go before plugins
import { FormAutocompleteComponent } from './ui-shared/form-autocomplete/form-autocomplete.component';
import { ChipsAutocompleteComponent } from './ui-shared/form-chips-autocomplete/form-chips-autocomplete.component';
import { MultipleSelectV2Component } from './ui-shared/multiple-select-v2/multiple-select-v2.component';
import { ButtonV2Component } from './ui-shared/button-v2/button-v2.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ContainerComponent } from './ui-shared/container/container.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { DialogDocumentViewComponent } from './ui-shared/dialog-document-view/dialog.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { DateAdapter } from '@angular/material/core';
import { FedDateAdapter } from './ui-shared/form-date/date-adapter.component';
import { InputImages } from './ui-shared/drop-input/input-images.component';
import { DefaultDialogComponent } from './ui-shared/default-dialog/default-dialog.component';
import { SearchFilterComponent } from './ui-shared/search-filter/search-filter.component';
import { AppPageDirective } from './app-page.directive';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AssigneesIconListComponent } from './ui-shared/assignees-icon-list/assignees-icon-list.component';
import { UserProfileImageComponent } from './ui-shared/image/user-profile-image.component';
import { AvatarModule } from 'ngx-avatars';
import { TableComponent } from './ui-shared/table/table.component';
import { PaginatorComponent } from './ui-shared/paginator/paginator.component';
import { RouterModule } from '@angular/router';
import { AutocompleteListComponent } from './ui-shared/form-autocomplete-list/form-autocomplete-list.component';
import { SharedModule } from '@fullyops/shared/shared.module';
import { MatLegacyChipsModule } from '@angular/material/legacy-chips';
import { FeaturesModule } from '@fullyops/features/features.module';

@NgModule({
  imports: [
    CommonModule,
    I18NextModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    FullCalendarModule,
    FontAwesomeModule,
    ClipboardModule,
    NgxExtendedPdfViewerModule,
    MatMomentDateModule,
    InfiniteScrollModule,
    AvatarModule,
    RouterModule,
    SharedModule,
    MatLegacyChipsModule,
  ],
  declarations: [
    DialogSaveComponent,
    CarouselComponent,
    UploadImageComponent,
    FormAnomaliesAutocompleteComponent,
    FormPartsAutocompleteComponent,
    BreadcrumbComponent,
    DialogComponent,
    DialogDocumentViewComponent,
    DefaultDialogComponent,
    LoadingComponent,
    ErrorComponent,
    IconComponent,
    MultipleSelectComponent,
    ButtonComponent,
    ListContainerComponent,
    EmptyWarnComponent,
    ReportRequestModalComponent,
    SignaturePadComponent,
    FormTemplateComponent,
    FormSelectComponent,
    FormDateComponent,
    TicketIdentifierPipe,
    AppPageDirective,
    ListComponent,
    CalendarComponent,
    FormAutocompleteComponent,
    ChipsAutocompleteComponent,
    AutocompleteListComponent,
    MultipleSelectV2Component,
    ButtonV2Component,
    ContainerComponent,
    InputImages,
    SearchFilterComponent,
    AssigneesIconListComponent,
    UserProfileImageComponent,
    TableComponent,
    PaginatorComponent,
  ],
  exports: [
    DialogSaveComponent,
    CarouselComponent,
    UploadImageComponent,
    FormAnomaliesAutocompleteComponent,
    FormPartsAutocompleteComponent,
    I18NextModule,
    BreadcrumbComponent,
    DialogComponent,
    DialogDocumentViewComponent,
    DefaultDialogComponent,
    LoadingComponent,
    ErrorComponent,
    IconComponent,
    MultipleSelectComponent,
    ButtonComponent,
    ListContainerComponent,
    EmptyWarnComponent,
    ReportRequestModalComponent,
    SignaturePadComponent,
    FormTemplateComponent,
    FormSelectComponent,
    FormDateComponent,
    TicketIdentifierPipe,
    AppPageDirective,
    ListComponent,
    CalendarComponent,
    FormAutocompleteComponent,
    ChipsAutocompleteComponent,
    MultipleSelectV2Component,
    ButtonV2Component,
    ContainerComponent,
    InputImages,
    SearchFilterComponent,
    AssigneesIconListComponent,
    UserProfileImageComponent,
    TableComponent,
    PaginatorComponent,
    RouterModule,
  ],
  providers: [
    WindowService,
    CompanyFormService,
    { provide: DateAdapter, useClass: FedDateAdapter },
  ],
})
export class UiModule {}
