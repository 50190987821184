import { Injectable, Inject } from '@angular/core';
import { API_SERVICE_CONFIG } from './ApiServiceConfig';
import { IApiServiceConfig } from './IApiServiceConfig';
import { CompaniesEndpoint } from './v0/CompaniesEndpoint';

@Injectable({ providedIn: 'root' })
export class ApiService {
  constructor(
    @Inject(API_SERVICE_CONFIG) private apiServiceConfig: IApiServiceConfig,
    public companiesEndpoint: CompaniesEndpoint
  ) {}

  get config() {
    return this.apiServiceConfig;
  }
}
