import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_SERVICE_CONFIG } from '../ApiServiceConfig';
import { IApiServiceConfig } from '../IApiServiceConfig';
import { Observable } from 'rxjs';
import { UnsavedUpload } from '../../models/v0/UnsavedUpload';

@Injectable()
export class UploadEndpoint {
  private readonly url = this.config.url + 'upload/image';

  constructor(
    @Inject(API_SERVICE_CONFIG) private config: IApiServiceConfig,
    private http: HttpClient
  ) {}

  post(upload: UnsavedUpload): Observable<{ url: string }> {
    return this.http.post<{ url: string }>(this.url, upload.toSerialised());
  }
}
