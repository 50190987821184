import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_SERVICE_CONFIG } from '../ApiServiceConfig';
import { IApiServiceConfig } from '../IApiServiceConfig';
import { Auth } from '../../models/v0/Auth';
import { Observable, ReplaySubject, of, BehaviorSubject } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class AuthEndpoint {
  private readonly url = this.config.url + 'authenticate/';
  $authorazied: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    @Inject(API_SERVICE_CONFIG) private config: IApiServiceConfig,
    private http: HttpClient,
    private cookieService: CookieService
  ) {
    if (this.cookieService.getAll()['jwt']) {
      this.$authorazied.next(true);
    }
  }

  login(nickname: string, pass: string, tenantName: string): Observable<Auth> {
    const request = this.http.post(
      this.url,
      {
        username: nickname,
        password: pass,
      },
      { headers: { tenantName: tenantName } }
    );
    const site = window.location.href.split('/')[2];

    return request.pipe(
      tap((response: Auth) => {
        if (response.jwt !== null) {
          this.cookieService.set(
            'jwt',
            response.jwt,
            null,
            '/',
            null,
            null,
            null
          );
          this.cookieService.set(
            'tenant',
            response.user.tenants[0].name,
            null,
            '/',
            null,
            null,
            null
          );
          this.setAuthorize(true);
        }
      }),
      catchError((error) => of(error))
    );
  }

  refreshToken(): Observable<Auth> {
    return this.http.post(this.url + `refresh-token`, {}) as Observable<Auth>;
  }

  logout(): Observable<{}> {
    this.$authorazied.next(false);
    this.cookieService.delete('jwt', '/');
    this.cookieService.delete('tenant');
    return of({});
  }

  setAuthorize(value: boolean) {
    this.$authorazied.next(value);
  }
}
