<div class="mockup-page">
  <div class="mockup-sidebar">
    <img
      class="mockup-logo"
      src="assets/svg/mockup-logo.svg"
      alt="FullyOps Logo"
    />
  </div>
  <div class="mockup-page-content">
    <nav class="mockup-navbar">
      <!-- Left Options -->
      <div class="mockup-navbar-options">
        <div
          *ngFor="let section of navSections"
          [hideIfNotAllowed]="$any(section).permissions"
        >
          <ng-container *ngIf="section.subsections">
            <a mat-button [matMenuTriggerFor]="s1">
              {{ section.name | i18nextCap }}
              <mat-icon>expand_more</mat-icon>
            </a>
            <mat-menu #s1="matMenu">
              <a
                mat-menu-item
                *ngFor="let subsection of section.subsections"
                [hideIfNotAllowed]="$any(subsection).permissions"
                [routerLink]="$any(subsection).path"
              >
                <span>{{ subsection.name | i18nextCap }}</span>
              </a>
            </mat-menu>
          </ng-container>

          <a
            *ngIf="!section.subsections"
            mat-button
            [routerLink]="$any(section).path"
            >{{ section.name | i18nextCap }}</a
          >
        </div>
      </div>

      <!-- Right Options - User Info -->
      <div class="mockup-navbar-options">
        <div class="mockup-navbar-language">
          <img
            class="mockup-navbar-language-flag"
            src="assets/svg/Flag_of_the_United_States.svg"
            alt="language-flag"
          />
          <div class="font14">English</div>
        </div>
        <div class="mockup-navbar-user">
          <div class="font14">Edu Martin</div>
          <div class="font12">Admin</div>
        </div>
        <div class="mockup-navbar-avatar">
          <img src="assets/svg/mockup-avatar.svg" alt="User-avatar" />
        </div>
      </div>
    </nav>
    <main class="mockup-main">
      <!-- Header -->
      <div class="page-header">
        <p class="page-header-title">Assistência</p>
        <mat-icon>chevron_right</mat-icon>
        <div class="page-header-subtitle">Assistências</div>
        <mat-icon>chevron_right</mat-icon>
        <div class="font14">
          [S-358] Trocar botão de emergência painel priminer
        </div>
      </div>

      <!-- Wizard -->
      <div class="mockup-wizard">
        <div *ngFor="let item of wizardItems; index as i">
          <div class="mockup-wizard-item">
            <mat-icon *ngIf="i != 0">chevron_right</mat-icon>
            <div
              class="mockup-wizard-icon-box"
              [ngClass]="item.selected && 'selected'"
            >
              <mat-icon class="mockup-wizard-mat-icon">{{
                item.icon
              }}</mat-icon>
            </div>
            <div class="mockup-wizard-item-details">
              <div
                class="mockup-wizard-item-details-title"
                [ngClass]="item.selected && 'selected'"
              >
                {{ item.title }}
              </div>
              <div class="mockup-wizard-item-details-subtitle">
                {{ item.subtitle }}
              </div>
            </div>
          </div>
        </div>
        <!-- <mat-icon>chevron_right</mat-icon>
        <div>Step</div>
        <mat-icon>chevron_right</mat-icon>
        <div>Step</div> -->
      </div>

      <!-- Data Content -->
      <div class="mockup-page-data">
        <div class="mockup-page-data-left">
          <div class="whiteContainerP30">
            <div class="main-form">
              <div class="main-form-header">
                <div class="main-form-header-left">
                  <div class="main-form-header-title">
                    {{ ticketData.title }}
                  </div>
                  <div class="main-form-header-subtitle">
                    {{ ticketData.subtitle }}
                  </div>
                </div>
                <div class="main-form-header-right">
                  <div>Criado a:</div>
                  <div>20 de Setembro 2022</div>
                  <div>às <span class="main-form-header-hour">21:20</span></div>
                </div>
              </div>
            </div>
          </div>
          <div class="whiteContainerP30">Left</div>
        </div>
        <div class="mockup-page-data-right">
          <div class="whiteContainerP20">Right</div>
          <div class="whiteContainerP20">Right</div>
        </div>
      </div>
    </main>
  </div>
</div>
