import { ISerialisedReportContent } from './serialisations/ISerialisedReportSection';

export class ReportContent {
  constructor(readonly data: string[], readonly url: string) {}

  static fromSerialised(serialised: ISerialisedReportContent) {
    return new ReportContent(serialised.data, serialised.url);
  }

  toSerialised(): ISerialisedReportContent {
    return {
      data: this.data,
      url: this.url,
    };
  }

  clone(partialReportContent: PartialReportContent): ReportContent {
    const resolve = (key: keyof ReportContent) =>
      partialReportContent.hasOwnProperty(key)
        ? partialReportContent[key]
        : this[key];

    return new ReportContent(resolve('data'), resolve('url'));
  }
}
type PartialReportContent = Partial<Pick<ReportContent, 'data' | 'url'>>;
