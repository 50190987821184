import { Injectable } from '@angular/core';
import {
  Response,
  ProductConfig,
  ProductsConfigSimulationsEndpoint,
  ProductConfigSimulationListItem,
  ProductConfigSimulation,
  UnsavedProductConfigSimulation,
} from '@fullyops/legacy/data';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UiProductsConfigurationSimulationService {
  readonly simulationsStore$ = this.simulationEndpoint.createStore();
  readonly productsConfigs$ = new BehaviorSubject<
    Response<ProductConfigSimulationListItem[]>
  >(new Response<ProductConfigSimulationListItem[]>());

  constructor(
    protected simulationEndpoint: ProductsConfigSimulationsEndpoint
  ) {}

  loadAll() {
    this.loadProductsConfig();
  }

  loadProductsConfig() {
    this.simulationEndpoint
      .getAll()
      .subscribe((response) => this.productsConfigs$.next(response));
  }

  get(id: string): Observable<Response<ProductConfigSimulation>> {
    return this.simulationEndpoint.get(id, undefined, this.simulationsStore$);
  }

  create(productsConfig: UnsavedProductConfigSimulation) {
    return this.simulationEndpoint.post(
      productsConfig,
      undefined,
      this.simulationsStore$
    );
  }

  update(
    id: string,
    unsavedModel: UnsavedProductConfigSimulation
  ): Observable<Response<ProductConfig>> {
    return;
  }

  remove(id: string): Observable<Response<{}>> {
    return this.simulationEndpoint.delete(
      id,
      undefined,
      this.simulationsStore$
    );
  }
}
