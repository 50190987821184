import { ISerialisedClientInfo } from './serialisations/ISerialisedClientInfo';

export class ClientInfo {
  constructor(
    readonly address: string,
    readonly city: string,
    readonly countryISOCode: string,
    readonly distance: string,
    readonly zipCode: string
  ) {
    if (address) this.address = address.trim();
  }

  static fromSerialised(serialised: ISerialisedClientInfo) {
    return new ClientInfo(
      serialised.address,
      serialised.city,
      serialised.countryISOCode,
      serialised.distance,
      serialised.zipCode
    );
  }

  toSerialised(): ISerialisedClientInfo {
    return {
      address: this.address,
      city: this.city,
      countryISOCode: this.countryISOCode,
      distance: this.distance,
      zipCode: this.zipCode,
    };
  }
  clone(partialClientInfo: PartialClientInfo): ClientInfo {
    const resolve = (key: keyof ClientInfo) =>
      partialClientInfo.hasOwnProperty(key)
        ? partialClientInfo[key]
        : this[key];

    return new ClientInfo(
      resolve('address'),
      resolve('city'),
      resolve('countryISOCode'),
      resolve('distance'),
      resolve('zipCode')
    );
  }
}
type PartialClientInfo = Partial<
  Pick<
    ClientInfo,
    'address' | 'city' | 'countryISOCode' | 'distance' | 'zipCode'
  >
>;
