import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ElementRef,
} from '@angular/core';
import {
  FormControl,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { TemplateFormFields } from '@fullyops/legacy/ui/ui-shared/form-template/form-template.component';
import { ClientEquipmentResponse } from '@fullyops/legacy/data/api/types/ClientEquipment';
import { faGears, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { PermissionType } from '@fullyops/legacy/ui/ui-shared/utils/crm-types';
import { UiAuthService } from '../ui-auth.service';
import { SnackbarService } from '../ui-snackbar.service';
import { BehaviorSubject } from 'rxjs';
import { DialogService } from '@fullyops/legacy/ui/ui-shared/dialog/dialog.service';
import { TimeZoneDateFormatterService } from '@fullyops/core/services/date-formatter.service';
import { SignedImageResponse } from '@fullyops/legacy/data/api/types/Image';

export type ClientEquipmentForm = {
  brand: string;
  createdAt?: string;
  equipmentData: string;
  model: string;
  serialNumber: string;
  hasWarranty: boolean;
  type: string;
  warranty: string;
  name: string;
  externalId: string;
  images: Array<SignedImageResponse>;
};

@Component({
  selector: 'crm-company-client-equipment-accordion',
  templateUrl: './company-client-equipment-accordion.component.html',
  styleUrls: ['./company-client-equipment-accordion.component.scss'],
})
export class CompanyClientEquipmentAccordion implements OnInit {
  constructor(
    public authService: UiAuthService,
    public snackBar: SnackbarService,
    private dialogService: DialogService,
    public timeZoneDate: TimeZoneDateFormatterService,
    public elementRef: ElementRef
  ) {}
  formGroup = new UntypedFormGroup({});
  formFields: TemplateFormFields<any> = [];
  @Input() indexExpanded: number;
  @Input() currentIndex: number;
  @Input() isNew: boolean;
  @Input() equipment: ClientEquipmentResponse;
  @Output() editEquipmentEmitter = new EventEmitter<any>();
  @Output() deleteEquipmentEmitter = new EventEmitter<any>();
  @Output() createEquipmentEmitter = new EventEmitter<UntypedFormGroup>();
  @Output() setIndexExpanded = new EventEmitter();

  faGears = faGears;
  faPlusCircle = faPlusCircle;

  canEditAnCreateAsync$ = new BehaviorSubject<boolean>(false);
  canEditAnCreate = [PermissionType.CAN_EDIT_CLIENT_EQUIPMENTS];
  canDeleteClientEquipment = [PermissionType.CAN_DELETE_CLIENT_EQUIPMENTS];

  ngOnInit(): void {
    this.initFormGroup();
    this.initFormFields();
    this.checkIfCanEditAndCreate();
  }

  initFormGroup() {
    this.formGroup.reset();

    if (this.isNew) {
      this.formGroup = new UntypedFormGroup({
        brand: new UntypedFormControl(''),
        equipmentData: new UntypedFormControl('', Validators.required),
        model: new UntypedFormControl(''),
        serialNumber: new UntypedFormControl(''),
        hasWarranty: new UntypedFormControl(false),
        type: new UntypedFormControl(''),
        warranty: new UntypedFormControl(null),
        name: new UntypedFormControl('', Validators.required),
        externalId: new UntypedFormControl(''),
        images: new FormControl<Array<SignedImageResponse>>([]),
      });
      this.formGroup.controls['warranty'].disable();
    } else {
      this.formGroup = new UntypedFormGroup({
        brand: new UntypedFormControl(this.equipment.brand),
        createdAt: new UntypedFormControl(this.equipment.createdAt),
        equipmentData: new UntypedFormControl(
          this.equipment.equipmentData,
          Validators.required
        ),
        model: new UntypedFormControl(this.equipment.model),
        serialNumber: new UntypedFormControl(this.equipment.serialNumber),
        hasWarranty: new UntypedFormControl(
          this.equipment.hasWarranty || false
        ),
        type: new UntypedFormControl(this.equipment.type),
        warranty: new UntypedFormControl(this.equipment.warranty),
        name: new UntypedFormControl(this.equipment.name, Validators.required),
        externalId: new UntypedFormControl(this.equipment.externalId),
        images: new FormControl<Array<SignedImageResponse>>(
          this.equipment.images
        ),
      });
      this.formGroup.controls['createdAt'].disable();
      if (!this.equipment.hasWarranty) {
        this.formGroup.controls['warranty'].disable();
      }
    }

    this.enableDisableWarrantyInputHandle();
  }

  checkIfCanEditAndCreate() {
    this.authService
      .hasPermission(this.canEditAnCreate)
      .subscribe((response) => {
        if (!response) {
          this.formGroup.disable();
        } else {
          this.canEditAnCreateAsync$.next(true);
        }
      });
  }

  enableDisableWarrantyInputHandle() {
    this.formGroup.controls['hasWarranty'].valueChanges.subscribe(
      (hasWarranty) => {
        if (hasWarranty) return this.formGroup.controls['warranty'].enable();
        this.formGroup.controls['warranty'].patchValue(null);
        return this.formGroup.controls['warranty'].disable();
      }
    );
  }

  initFormFields() {
    if (this.isNew) {
      this.formFields = [
        [
          { name: 'name', type: 'text', label: 'name', size: 12 },
          { name: 'externalId', type: 'text', label: 'externalId', size: 12 },
          { label: 'brand', name: 'brand', type: 'text', size: 6 },
          { label: 'model', name: 'model', type: 'text', size: 6 },
          { label: 'type', name: 'type', type: 'text', size: 6 },
          {
            label: 'serialNumber',
            name: 'serialNumber',
            type: 'text',
            size: 6,
          },
          {
            label: 'equipmentData',
            name: 'equipmentData',
            type: 'textarea',
            size: 12,
          },
          {
            label: 'hasWarranty',
            name: 'hasWarranty',
            type: 'checkbox',
            size: 6,
          },
          {
            label: 'warranty',
            name: 'warranty',
            type: 'date',
            size: 6,
          },
          {
            label: 'images',
            name: 'images',
            type: 'images',
            size: 12,
            inputImages: {
              multipleImage: true,
              uploadImageOnAdd: true,
              isSignedImageResponseType: true,
              maxSizeInKB: 5000,
            },
          },
        ],
      ];
      return;
    }
    this.formFields = [
      [
        { name: 'name', type: 'text', label: 'name', size: 6 },
        { label: 'createdAt', name: 'createdAt', type: 'date', size: 6 },
        { name: 'externalId', type: 'text', label: 'externalId', size: 12 },
        { label: 'brand', name: 'brand', type: 'text', size: 6 },
        { label: 'model', name: 'model', type: 'text', size: 6 },
        { label: 'type', name: 'type', type: 'text', size: 6 },
        {
          label: 'serialNumber',
          name: 'serialNumber',
          type: 'text',
          size: 6,
        },
        {
          label: 'equipmentData',
          name: 'equipmentData',
          type: 'textarea',
          size: 12,
        },
        {
          label: 'hasWarranty',
          name: 'hasWarranty',
          type: 'checkbox',
          size: 6,
        },
        {
          label: 'warranty',
          name: 'warranty',
          type: 'date',
          size: 6,
        },
        {
          label: 'images',
          name: 'images',
          type: 'images',
          size: 12,
          inputImages: {
            multipleImage: true,
            uploadImageOnAdd: true,
            isSignedImageResponseType: true,
            maxSizeInKB: 5000,
          },
        },
      ],
    ];
  }

  isFormValid() {
    if (!this.formGroup.valid) {
      this.formGroup.markAllAsTouched();
      this.snackBar.openErrorFormMissingFields();
      return false;
    }
    return true;
  }

  onEdit() {
    if (this.isFormValid()) {
      return this.editEquipmentEmitter.emit(this.formGroup.value);
    }
  }

  onDelete() {
    const dialogRef = this.dialogService.openDialogBeforeDelete();

    dialogRef.afterClosed().subscribe((saveOutput) => {
      if (saveOutput) {
        return this.deleteEquipmentEmitter.emit();
      }
    });
  }

  onCreate() {
    if (this.isFormValid()) {
      this.createEquipmentEmitter.emit(this.formGroup);
    }
  }

  resetClientEquipmentForm() {
    this.initFormGroup();
  }
}
