import {
  CompanyResponse,
  CountryResponse,
} from '@fullyops/legacy/data/api/types/Company';
import { ContactResponse } from '@fullyops/legacy/data/api/types/Contact';
import { ContactControllerV2 } from './../ui-contact-controller-v2.service';
import { TemplateFormFields } from '@fullyops/legacy/ui/ui-shared/form-template/form-template.component';
import {
  Component,
  Input,
  OnInit,
  EventEmitter,
  Output,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import {
  UnsavedTicketRequest,
  Status,
  Part,
  UnsavedPart,
  Response,
  History,
  TicketSupportMultimachine,
} from '@fullyops/legacy/data';
import { BehaviorSubject } from 'rxjs';
import {
  ActionButton,
  ActionButtons,
  PermissionType,
  Priority,
  PriorityColor,
} from '@fullyops/legacy/ui/ui-shared/utils/crm-types';
import {
  UntypedFormControl,
  UntypedFormGroup,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';
import { ComponentInOutAnimation } from '@fullyops/legacy/ui/ui-shared/utils/component-base-animation';
import { debounceTime, pairwise } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { UiAuthService } from '../ui-auth.service';
import { SnackbarService } from '../ui-snackbar.service';
import { TenantConfigurationController } from '../ui-tenant-configuration-v2-controller.service';
import {
  PartUncataloguedControllerV2,
  QueryParameters as partUncataloguedQueryParameters,
} from '../ui-part-uncatalogued-controller-v2.service';
import { UncataloguedPartResponse } from '@fullyops/legacy/data/api/types/Intervention';
import {
  faHelmetSafety,
  faScrewdriverWrench,
  faTrashCan,
} from '@fortawesome/free-solid-svg-icons';
import { MatLegacyAutocompleteTrigger as MatAutocompleteTrigger } from '@angular/material/legacy-autocomplete';
import { RequestTicketControllerV2 } from '../request-ticket-controller-v2.service';
import {
  RequestTicketDetailResponse,
  RequestTicketPatchRequest,
  RequestTicketPostRequest,
  RequestTicketUncataloguedPartResponse,
} from '@fullyops/legacy/data/api/types/RequestTicke';
import { PartControllerV2 } from '../ui-part-controller-v2.service';
import {
  PartResponse,
  RequestTicketPartResponse,
} from '@fullyops/legacy/data/api/types/Part';
import { UserResponse } from '@fullyops/legacy/data/api/types/User';
import {
  CompanyControllerV2,
  QueryParams as CompanyQueryParams,
} from '../ui-company-controller-v2.service';
import { UserControllerV2 } from '../ui-user-controller-v2.service';
import { MatLegacyTabChangeEvent as MatTabChangeEvent } from '@angular/material/legacy-tabs';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DialogComponent } from '@fullyops/legacy/ui/ui-shared/dialog/dialog.component';
import {
  SupportTicketController,
  QueryParams as SupportTicketQueryParams,
} from '../ui-support-ticket-controller.service';
import { SignedImageResponse } from '@fullyops/legacy/data/api/types/Image';
import { ValidationsRequestForm } from './validation.service';
import {
  SelectEmailDialogComponent,
  SelectEmailDialogData,
  SelectEmailResponseDialogData,
} from '../select-email-dialog/select-email-dialog.component';
import { SupportTicketResponse } from '@fullyops/legacy/data/api/types/SupportTicket';
import { Country } from '@fullyops/legacy/data/models/v0/Country';
import { TimeZoneDateFormatterService } from '@fullyops/core/services/date-formatter.service';

export type RequestFormType = FormGroup<{
  name: FormControl<string>;
  supportTicket: FormControl<SupportTicketResponse>;
  images: FormControl<SignedImageResponse[]>;
  description: FormControl<string>;
  parts: FormControl<RequestTicketPartResponse[]>;
  uncataloguedParts: FormControl<RequestTicketUncataloguedPartResponse[]>;
  supplier: FormControl<CompanyResponse>;
  supplierAddress: FormControl<string>;
  supplierPostCode: FormControl<string>;
  supplierCity: FormControl<string>;
  supplierCountry: FormControl<string>;
  supplierContactsIds: FormControl<string[]>;
  statusId: FormControl;
  priority: FormControl<Priority>;
  assigneeIds: FormControl<string[]>;
  supplierDistance: FormControl<number>;
}>;

@Component({
  selector: 'crm-ticket-request-form',
  templateUrl: './ticket-request-form.component.html',
  styleUrls: ['./ticket-request-form.component.scss'],
  animations: [ComponentInOutAnimation.AnimTrigger],
  providers: [ValidationsRequestForm],
})
export class TicketRequestFormComponent implements OnInit {
  constructor(
    protected route: ActivatedRoute,
    public authService: UiAuthService,
    protected router: Router,
    protected contactControllerV2: ContactControllerV2,
    private tenantConfigurationController: TenantConfigurationController,
    protected partUncataloguedControllerV2: PartUncataloguedControllerV2,
    private snackBarService: SnackbarService,
    private changeDetectorRef: ChangeDetectorRef,
    protected requestTicketControllerV2: RequestTicketControllerV2,
    protected partControllerV2: PartControllerV2,
    protected companyControllerV2: CompanyControllerV2,
    protected userControllerV2: UserControllerV2,
    public dialog: MatDialog,
    protected supportTicketController: SupportTicketController,
    private validationsRequestForm: ValidationsRequestForm,
    public timezoneDate: TimeZoneDateFormatterService
  ) {}

  @Input() statuses$: BehaviorSubject<Status[]>;
  @Input() history$: BehaviorSubject<Response<History[]>>;

  @Output() createPartEvent = new EventEmitter<UnsavedPart>();
  @Output() sendEmailEvent = new EventEmitter<UnsavedTicketRequest>();
  @Output() openActivityEvent = new EventEmitter<string>();
  @Output() openTicketEvent = new EventEmitter<{
    ticketId: string;
    ticketType: string;
  }>();

  id = this.route.snapshot.paramMap.get('id');

  specialActions: ActionButton[] = [{ label: 'sendEmail', color: 'primary' }];
  actionButtons: ActionButtons = {};
  companyContacts$ = new BehaviorSubject<ContactResponse[]>([]);
  assignees$ = new BehaviorSubject<UserResponse[]>([]);
  ticketsSupportSelect$: BehaviorSubject<TicketSupportMultimachine[]> =
    new BehaviorSubject<TicketSupportMultimachine[]>([]);
  priorities$ = new BehaviorSubject(
    Object.keys(Priority).map((key) => ({
      id: key,
      name: Priority[key],
      color: PriorityColor[key],
    }))
  );

  header: string;
  supplierParts$ = new BehaviorSubject<Part[]>([]);

  ticketNameFormGroup = new UntypedFormGroup({
    ticketName: new UntypedFormControl(''),
  });

  supportTickets$ = new BehaviorSubject<Array<SupportTicketResponse>>([]);

  //Parts Context
  showUncataloguedPart = false;
  uncataloguedParts$ = new BehaviorSubject<UncataloguedPartResponse[]>([]);
  newUncataloguedPartControl = new FormControl<
    string | UncataloguedPartResponse
  >('');
  newPartControl = new FormControl<string | PartResponse>('');
  filteredUncataloguedParts$ = new BehaviorSubject<UncataloguedPartResponse[]>(
    []
  );
  generalParts$ = new BehaviorSubject<Array<PartResponse>>([]);
  filteredGeneralParts$ = new BehaviorSubject<Array<PartResponse>>([]);

  //Icons
  faScrewdriverWrench = faScrewdriverWrench;
  faTrashCan = faTrashCan;

  //Others
  @ViewChild('trigger')
  matAutocompleteTrigger: MatAutocompleteTrigger;

  //Suppliers
  suppliers$ = new BehaviorSubject<any[]>([]); // Should be typed
  supplierContacts$ = new BehaviorSubject<any[]>([]); // Should be typed

  countries$ = new BehaviorSubject<Array<CountryResponse>>([]);

  requestTicket$ = new BehaviorSubject<RequestTicketDetailResponse>(null);

  selectedTab = [0];

  isSupportTicketButtonDisabled$ = new BehaviorSubject<boolean>(true);

  //Form
  requestTicketFormGroup = new FormGroup({
    name: new FormControl<string>('', [Validators.required]),
    supportTicket: new FormControl<SupportTicketResponse>(null),
    images: new FormControl<SignedImageResponse[]>([]),
    description: new FormControl<string>('', []),
    parts: new FormControl<RequestTicketPartResponse[]>([]),
    uncataloguedParts: new FormControl<RequestTicketUncataloguedPartResponse[]>(
      []
    ),
    supplier: new FormControl<CompanyResponse>(null),
    supplierAddress: new FormControl<string>(''),
    supplierPostCode: new FormControl<string>(''),
    supplierCity: new FormControl<string>(''),
    supplierCountry: new FormControl<string>(''),
    supplierContactsIds: new FormControl<string[]>([]),
    statusId: new FormControl('', [Validators.required]),
    priority: new FormControl<Priority>(null, [Validators.required]),
    assigneeIds: new FormControl<string[]>(null, []),
    supplierDistance: new FormControl<number>(null, []),
  });

  // formFields
  infoTabFormFields: TemplateFormFields<any> = [
    [
      { name: 'name', label: 'name', type: 'text', size: 12 },
      {
        name: 'supportTicket',
        label: 'supportTicket',
        type: 'autocomplete',
        size: 12,
        items$: this.supportTickets$,
        displayValue: (
          supportTicketResponse: SupportTicketResponse | string
        ) => {
          if (typeof supportTicketResponse == 'string') {
            return supportTicketResponse;
          }
          if (!supportTicketResponse) return null;
          return `${supportTicketResponse.identifier} - ${supportTicketResponse.name}`;
        },
        button: {
          onClick: () => this.navigateToSupportPage(),
          fontAwesomeIconName: faHelmetSafety,
          disabled$: this.isSupportTicketButtonDisabled$,
          testId: 'support-ticket-company-button',
        },
        placeholder: 'TypeToSearchForTicket',
        dataTestId: 'support-ticket-input',
      },
      { name: 'description', label: 'description', type: 'textarea', size: 12 },
      {
        name: 'images',
        label: 'images',
        type: 'images',
        size: 12,
        inputImages: {
          height: 150,
          imagesPerRow: { sm: 1, md: 2, lg: 3 },
          accept: 'image/*',
          multipleImage: true,
          uploadImageOnAdd: true,
          isSignedImageResponseType: true,
          maxSizeInKB: 5000,
          compressImage: false,
        },
      },
    ],
  ];

  supplierTabFormFields: TemplateFormFields<any> = [
    [
      {
        label: 'supplier',
        name: 'supplier',
        type: 'autocomplete',
        size: 12,
        items$: this.suppliers$,
        button: {
          onClick: () => this.navigateToCompanyPage(),
          iconName: 'account_balance',
        },
      },

      {
        label: 'supplierAddress',
        name: 'supplierAddress',
        type: 'text',
        size: 9,
      },
      { name: 'supplierDistance', type: 'text', label: 'distance', size: 3 },
      { name: 'supplierCity', label: 'supplierCity', type: 'text', size: 4 },
      {
        name: 'supplierCountry',
        type: 'select',
        label: 'country',
        items$: this.countries$,
        size: 4,
      },
      {
        name: 'supplierPostCode',
        label: 'supplierPostCode',
        type: 'text',
        size: 4,
      },
      {
        label: 'supplierContacts',
        name: 'supplierContactsIds',
        type: 'multiple-select',
        size: 12,
        items$: this.supplierContacts$,
      },
    ],
  ];

  trackingFields: TemplateFormFields<any> = [];

  ngOnInit() {
    this.getCountries();

    this.initFormFieldsAndValues();

    this.setUncataloguedPartConfiguration();

    this.loadParts({ name: null });
    this.debounceParts();

    this.loadUncataloguedParts({ name: null });
    this.debounceUncataloguedParts();

    this.loadCompanies({ name: null });
    this.debounceCompanies();
    this.onCompanyChange();

    this.loadAssigneesOptions();

    this.debounceSupportTicket();
    this.setActionsButtons();

    if (this.id) this.loadRequestTicket();

    this.requestTicket$.subscribe(() => {
      this.initFormFieldsAndValues();
    });

    this.statuses$.subscribe((statuses) => {
      const statusId = statuses.find((s) => s.name === 'In Queue')?.id;

      if (statusId)
        this.requestTicketFormGroup.controls.statusId.setValue(statusId);
    });

    this.setSupportTicketButtonValid();
  }

  setSupportTicketButtonValid() {
    if (!this.requestTicketFormGroup.controls['supportTicket'].value?.id) {
      this.isSupportTicketButtonDisabled$.next(true);
      this.changeDetectorRef.detectChanges();
    }
    this.requestTicketFormGroup.controls[
      'supportTicket'
    ].valueChanges.subscribe((e) => {
      if (!e?.id) {
        this.isSupportTicketButtonDisabled$.next(true);
      } else {
        this.isSupportTicketButtonDisabled$.next(false);
      }
      this.changeDetectorRef.detectChanges();
    });
  }

  setHeader() {
    if (this.id) {
      const identifier = this.requestTicket$.value?.identifier;
      const name = this.requestTicket$.value?.name;
      this.header = `[R-${identifier}] ${name}`;
    } else {
      this.header = 'newTicketRequest';
    }
  }

  setSupplierConfiguration() {
    this.tenantConfigurationController
      .getConfigurationsByLabel({ label: 'REQUEST_TICKET_SUPPLIER_MANDATORY' })
      .subscribe((res) => {
        if (res.value == 'true') {
          this.requestTicketFormGroup.controls.supplier.setValidators([
            Validators.required,
          ]);
          this.requestTicketFormGroup.controls.supplier.updateValueAndValidity();
        }

        if (!res.value && res.defaultValue == 'true') {
          this.requestTicketFormGroup.controls.supplier.setValidators([
            Validators.required,
          ]);
          this.requestTicketFormGroup.controls.supplier.updateValueAndValidity();
        }
        this.changeDetectorRef.detectChanges();
      });
  }

  setActionsButtons() {
    if (this.id) {
      this.actionButtons = {
        warn: {
          label: 'delete',
          color: 'warn',
          dataTestId: 'delete-ticket-test',
        },
      };
    }
  }

  onActionEvent(event: string) {
    switch (event) {
      case 'delete':
        this.requestTicketControllerV2
          .deleteById({ id: this.id })
          .subscribe(() => this.router.navigate([`tickets/request`]));
        break;
      case 'sendEmail':
        this.onSendEmail();
        break;
      default:
        break;
    }
  }

  debounceSupportTicket() {
    const regex = /^S-\d+ - /;

    this.requestTicketFormGroup.controls.supportTicket.valueChanges
      .pipe(debounceTime(300))
      .subscribe((value) => {
        if (typeof value == 'string') {
          const isStringATitleOfSelectedTicket = regex.test(value);
          if (isStringATitleOfSelectedTicket) {
            const onlyText = (value as string).split(' - ')[1];
            this.requestTicketFormGroup.controls.supportTicket.setValue(
              // @ts-ignore
              onlyText
            );
          } else {
            return this.loadSupportTickets({ combinedTextSearch: value });
          }
        }
      });
  }

  loadSupportTickets(params: SupportTicketQueryParams) {
    this.supportTicketController
      .getAllSupportTickets({ queryParams: params })
      .subscribe((e) => {
        this.supportTickets$.next(e.results);
        this.changeDetectorRef.detectChanges();
      });
  }

  getAndSetSupport(id: string) {
    this.supportTicketController.getSupportTicketById(id).subscribe((e) => {
      this.supportTickets$.next([e]);
      this.requestTicketFormGroup.controls.supportTicket.setValue(e);
    });
  }

  loadAssigneesOptions() {
    this.userControllerV2
      .getUsers({ queryParameters: { roles: ['TECHNICIAN', 'ADMIN'] } })
      .subscribe((e) => this.assignees$.next(e.results));
  }

  loadContacts() {
    const companyId = this.requestTicketFormGroup.controls.supplier.value?.id;
    if (!companyId) {
      this.supplierContacts$.next([]);
      this.requestTicketFormGroup.controls.supplierContactsIds.setValue(null);
      return;
    }
    this.contactControllerV2
      .getAll({ queryParams: { companyId } })
      .subscribe((e) => this.supplierContacts$.next(e.results));
  }

  onCompanyChange() {
    this.requestTicketFormGroup.controls.supplier.valueChanges
      .pipe(pairwise())
      .subscribe(
        ([prevCompany, company]: [CompanyResponse, CompanyResponse]) => {
          const controls = this.requestTicketFormGroup.controls;
          controls.supplierAddress.setValue(company ? company?.address : null);
          controls.supplierPostCode.setValue(company ? company?.zipCode : null);
          controls.supplierCity.setValue(company ? company?.city : null);
          controls.supplierCountry.setValue(
            company ? company.countryISOCode : null
          );
          controls.supplierContactsIds.setValue(null);
          controls.supplierDistance.setValue(
            company ? company?.distance : null
          );
          this.loadContacts();
        }
      );
  }

  debounceCompanies() {
    this.requestTicketFormGroup.controls.supplier.valueChanges
      .pipe(debounceTime(500))
      .subscribe((name) => {
        if (typeof name == 'string') {
          this.loadCompanies({ name });
        }
      });
  }

  loadCompanies(params: { name: string }) {
    const { name = null } = params;
    const queryParameters: CompanyQueryParams = {
      order: 'ASC',
      size: 20,
      type: ['SUPPLIER'],
    };
    if (name) queryParameters['name'] = name;

    this.companyControllerV2
      .getAll({ queryParams: queryParameters })
      .subscribe((e) => {
        this.suppliers$.next(e.results);
        this.changeDetectorRef.detectChanges();
      });
  }

  loadParts(params: { name: string }) {
    const { name = null } = params;
    const queryParameters: partUncataloguedQueryParameters = {
      order: 'ASC',
      size: 20,
    };
    if (name) queryParameters['name'] = name;

    this.partControllerV2.getAll({ queryParameters }).subscribe((res) => {
      this.filteredGeneralParts$.next(
        this.filterNotAddedGeneralParts({ parts: res.results })
      );
      this.generalParts$.next(res.results);
    });
  }

  loadUncataloguedParts(params: { name: string }) {
    const { name = null } = params;
    const queryParameters: partUncataloguedQueryParameters = {
      order: 'ASC',
      size: 20,
    };
    if (name) queryParameters['name'] = name;

    this.partUncataloguedControllerV2
      .getAll({ queryParameters })
      .subscribe(({ results: uncataloguedParts }) => {
        this.filteredUncataloguedParts$.next(
          this.filterNotAddedUncatalogedParts({ uncataloguedParts })
        );
      });
  }

  debounceParts() {
    this.newPartControl.valueChanges
      .pipe(debounceTime(500))
      .subscribe((name) => {
        if (typeof name == 'string') {
          this.loadParts({ name });
        }
      });
  }

  debounceUncataloguedParts() {
    this.newUncataloguedPartControl.valueChanges
      .pipe(debounceTime(500))
      .subscribe((name) => {
        if (typeof name == 'string') {
          this.loadUncataloguedParts({ name });
        }
      });
  }

  //
  //
  //
  //
  //

  loadRequestTicket() {
    this.requestTicketControllerV2
      .getById({ id: this.id })
      .subscribe((e) => this.requestTicket$.next(e));
  }

  initFormFieldsAndValues() {
    this.trackingFields = [
      [
        {
          name: 'statusId',
          type: 'select',
          label: 'status',
          size: 6,
          items$: this.statuses$,
          translate: true,
        },
        {
          name: 'priority',
          type: 'select',
          label: 'priority',
          size: 6,
          items$: this.priorities$,
          translate: true,
        },
      ],
      [
        {
          name: 'assigneeIds',
          type: 'multiple-select',
          label: 'assignee',
          size: 12,
          items$: this.assignees$,
          translate: false,
          displayValue: (u: UserResponse) => u.firstName + ' ' + u.lastName,
        },
      ],
    ];

    if (!this.id) {
      this.route.queryParams.subscribe((e) => {
        if (e.supportTicketId) this.getAndSetSupport(e.supportTicketId);
        this.loadContacts();
      });
      this.setSupplierConfiguration();
      this.setHeader();
      return;
    }

    const requestTicket = this.requestTicket$.value;
    const supplierContactsIds = requestTicket?.contacts
      ? requestTicket?.contacts.map((c) => c.id)
      : null;

    this.requestTicketFormGroup.reset({
      name: requestTicket?.name,
      description: requestTicket?.description,
      parts: requestTicket?.parts,
      uncataloguedParts: requestTicket?.uncataloguedParts,
      assigneeIds: requestTicket?.assignees.map((u) => u.id),
      statusId: requestTicket?.status.id,
      priority: requestTicket?.priority,
      supplier: requestTicket?.supplier,
      supplierAddress: requestTicket?.clientInfo?.address,
      supplierCity: requestTicket?.clientInfo?.city,
      supplierCountry: requestTicket?.clientInfo?.countryISOCode,
      supplierPostCode: requestTicket?.clientInfo?.zipCode,
      supplierContactsIds,
      images: requestTicket?.images,
    });

    if (requestTicket?.supportTicketId)
      this.getAndSetSupport(requestTicket?.supportTicketId);
    this.loadContacts();
    this.setSupplierConfiguration();
    this.setHeader();
  }

  onOpenTicketClick(supportTicketId: string) {
    this.onOpenTicket({
      ticketId: supportTicketId,
      ticketType: 'support',
    });
  }

  onOpenActivity(id: string) {
    this.openActivityEvent.emit(id);
  }

  onOpenTicket(ticket: { ticketId: string; ticketType: string }) {
    this.openTicketEvent.emit(ticket);
  }

  onSendEmail() {
    this.validationsRequestForm.validate({
      form: this.requestTicketFormGroup,
      selectedTab: this.selectedTab,
    });

    if (this.requestTicketFormGroup.valid) {
      const emails = [];
      this.tenantConfigurationController
        .getConfigurationsByLabel({
          label: 'DEFAULT_REQUEST_TICKET_EMAIL_RECIPIENT',
        })
        .subscribe(
          (res) => {
            if (res.value) emails.push(res.value);
          },
          () => {},
          () => {
            const dialog = this.dialog.open<
              SelectEmailDialogComponent,
              SelectEmailDialogData,
              SelectEmailResponseDialogData
            >(SelectEmailDialogComponent, {
              data: {
                ccList: emails,
                showBccList: false,
                showCcList: true,
                title: 'requestSendEmailDialogTitle',
              },
            });

            dialog.afterClosed().subscribe((e) => {
              if (!e) return;
              if (e.ccList?.length == 0) return;
              this.requestTicketControllerV2
                .sendTicketEmail({
                  id: this.id,
                  emailsRecipes: e.ccList,
                })
                .subscribe(() => {});
            });
          }
        );
    }
  }

  navigateToCompanyPage() {
    const supplier = this.requestTicketFormGroup.controls.supplier.value;
    if (!supplier?.id) return;
    return this.authService
      .hasPermission([PermissionType.CAN_EDIT_COMPANIES])
      .subscribe((canEditCompanies) => {
        return this.router.navigate([
          `companies/${supplier.id}${canEditCompanies ? '/edit' : ''}`,
        ]);
      });
  }

  navigateToSupportPage() {
    const support = this.requestTicketFormGroup.controls.supportTicket.value;
    if (!support?.id) return;
    return this.authService
      .hasPermission([PermissionType.CAN_EDIT_SUPPORT_TICKETS])
      .subscribe((canEdit) => {
        return this.router.navigate([
          `tickets/support/${support.id}${canEdit ? '/edit' : ''}`,
        ]);
      });
  }

  onSubmit() {
    //  Overwrite FormComponent onSubmit component
    // if (this.ngForm.valid) {
    //   this.saveEvent.emit(this.formService.getDraft());
    // } else {
    //   this.formService.markAllAsTouched();
    //   const parts = this.ngForm.form.controls['parts'].value as any[];
    //   if (parts.length == 0) {
    //     return this.snackBar.openError({ message: 'forms:noPartsSelected' });
    //   }
    //   return this.snackBar.openErrorFormMissingFields();
    // }
  }

  // New component code organized bellow

  setUncataloguedPartConfiguration() {
    this.tenantConfigurationController
      .getConfigurationsByLabel({
        label: 'REQUEST_TICKET_ENABLE_UNCATALOGUED_PARTS',
      })
      .subscribe((res) => {
        if (res.value == 'true') {
          this.showUncataloguedPart = true;
          this.requestPartsUncatalogued();
        }

        if (!res.value && res.defaultValue == 'true') {
          this.showUncataloguedPart = true;
          this.requestPartsUncatalogued();
        }
      });
  }

  requestPartsUncatalogued() {
    const queryParameters: partUncataloguedQueryParameters = {
      order: 'ASC',
      size: 20,
    };

    this.partUncataloguedControllerV2
      .getAll({ queryParameters })
      .subscribe((res) => this.uncataloguedParts$.next(res.results));
  }

  updatePartQuantity(
    quantity,
    index,
    controlName: 'parts' | 'uncataloguedParts'
  ) {
    if (controlName == 'uncataloguedParts') {
      const newUncataloguedPartsValue = [
        ...this.requestTicketFormGroup.controls[controlName].value,
      ];
      newUncataloguedPartsValue[index].quantity = quantity;
      this.requestTicketFormGroup.controls[controlName].setValue(
        newUncataloguedPartsValue
      );
    }

    if (controlName == 'parts') {
      const newValue = [
        ...this.requestTicketFormGroup.controls[controlName].value,
      ];
      newValue[index].quantity = quantity;
      this.requestTicketFormGroup.controls[controlName].setValue(newValue);
    }

    this.requestTicketFormGroup.markAsTouched();
    this.requestTicketFormGroup.markAsDirty();
  }

  removePart(
    index: number,
    controlName: 'parts' | 'uncataloguedParts',
    addInputControl
  ) {
    const control = this.requestTicketFormGroup.get(controlName);

    const newValue = [...control.value];
    newValue.splice(index, 1);
    //@ts-ignore
    control.patchValue(newValue);
    this.requestTicketFormGroup.markAsDirty();
    addInputControl.setValue('');
  }

  addUncataloguedPart() {
    const { newUncataloguedPartControl, uncataloguedParts$ } = this;
    const newUncataloguedPart = newUncataloguedPartControl.value;
    newUncataloguedPartControl.markAsTouched();

    if (!newUncataloguedPartControl.value) return;

    if (!newUncataloguedPartControl.valid) {
      return this.snackBarService.openErrorFormMissingFields();
    }

    if (typeof newUncataloguedPart == 'string') {
      const uncataloguedPartControl =
        this.requestTicketFormGroup.controls.uncataloguedParts;

      const isPartAlreadyAdded = uncataloguedPartControl.value?.some(
        (addedUncataloguedPart) => {
          return (
            addedUncataloguedPart.uncataloguedPart.name.toLowerCase() ==
            newUncataloguedPart.toLowerCase()
          );
        }
      );

      if (isPartAlreadyAdded) {
        return newUncataloguedPartControl.setErrors({
          message: 'validation:itemAlreadyAdded',
        });
      }

      const findAlreadyExist = uncataloguedParts$.value.find((part) => {
        return part.name.toLowerCase() == newUncataloguedPart.toLowerCase();
      });

      if (findAlreadyExist) {
        newUncataloguedPartControl.setValue(findAlreadyExist);
        this.addUncataloguedPart();
        return;
      }

      // return this.createPartUncatalogued.emit(newUncataloguedPart);
      return;
    }
    return this.addUncataloguedPartOnForm(newUncataloguedPart);
  }

  addUncataloguedPartOnForm(uncataloguedPart: UncataloguedPartResponse) {
    const uncataloguedPartControl =
      this.requestTicketFormGroup.get('uncataloguedParts');

    const valueToAddOnForm: RequestTicketUncataloguedPartResponse = {
      requestTicketId: this.id,
      quantity: 1,
      uncataloguedPart: uncataloguedPart,
    };

    let newUncataloguedPartsList: RequestTicketUncataloguedPartResponse[] = [];
    if (uncataloguedPartControl?.value) {
      newUncataloguedPartsList = [...uncataloguedPartControl?.value];
    }

    newUncataloguedPartsList.push(valueToAddOnForm);
    uncataloguedPartControl.patchValue(newUncataloguedPartsList);

    this.newUncataloguedPartControl.reset('');
    this.requestTicketFormGroup.markAsDirty();
    this.matAutocompleteTrigger.closePanel();
    this.changeDetectorRef.detectChanges();
  }

  filterNotAddedUncatalogedParts({
    uncataloguedParts,
  }: {
    uncataloguedParts: UncataloguedPartResponse[];
  }) {
    const uncataloguedPartControlValue =
      this.requestTicketFormGroup.controls.uncataloguedParts.value;
    const uncataloguedPartsNotAdded = uncataloguedParts.filter(
      (uncataloguedPart) => {
        const alreadyAdded = uncataloguedPartControlValue?.some(
          (interventionUncataloguedPart) => {
            return (
              interventionUncataloguedPart.uncataloguedPart.id ==
              uncataloguedPart.id
            );
          }
        );
        return !alreadyAdded;
      }
    );

    return uncataloguedPartsNotAdded;
  }

  displayUncataloguedPart(part): string {
    if (!part) return '';
    return part.name;
  }

  filterNotAddedGeneralParts({ parts }: { parts: PartResponse[] }) {
    const partControlValue = this.requestTicketFormGroup.controls.parts.value;
    const partsNotAdded = parts.filter((part) => {
      const alreadyAdded = partControlValue?.some((interventionPart) => {
        return interventionPart.part.id == part.id;
      });
      return !alreadyAdded;
    });

    return partsNotAdded;
  }

  addPart() {
    const { newPartControl, generalParts$ } = this;

    const newPart = newPartControl.value;

    if (!newPart) return;

    if (typeof newPart == 'string') {
      const partControl = this.requestTicketFormGroup.controls.parts;
      const isPartAlreadyAdded = partControl.value.some((part) => {
        return part.part.name.toLowerCase() == newPart.toLowerCase();
      });

      if (isPartAlreadyAdded) {
        return newPartControl.setErrors({
          message: 'validation:itemAlreadyAdded',
        });
      }

      const findAlreadyExist = generalParts$.value.find((part) => {
        return part.name.toLowerCase() == newPart.toLowerCase();
      });

      if (findAlreadyExist) {
        newPartControl.setValue(findAlreadyExist);
        this.addPart();
        return;
      }
    } else {
      this.addPartOnForm(newPart);
    }
  }

  addPartOnForm(part: PartResponse) {
    const partControl = this.requestTicketFormGroup.controls['parts'];

    let newPartsList: RequestTicketPartResponse[] = [];
    if (partControl?.value) {
      newPartsList = [...partControl?.value];
    }

    newPartsList.push({
      part,
      quantity: 1,
    });

    partControl.patchValue(newPartsList);

    this.newPartControl.reset('');
    this.requestTicketFormGroup.markAsDirty();
    this.matAutocompleteTrigger.closePanel();
    this.changeDetectorRef.detectChanges();
  }

  editRequestTicket() {
    const newData: Partial<RequestTicketPatchRequest> = {
      assigneeIds: this.requestTicketFormGroup.value.assigneeIds,
      contactIds: this.requestTicketFormGroup.value.supplierContactsIds,
      description: this.requestTicketFormGroup.value.description,
      id: this.id,
      name: this.requestTicketFormGroup.value.name,
      parts: this.requestTicketFormGroup.value.parts.map((p) => {
        return {
          partId: p.part.id,
          quantity: p.quantity,
          requestTicketId: this.id,
        };
      }),
      priority: this.requestTicketFormGroup.value.priority,
      statusId: this.requestTicketFormGroup.value.statusId,
      supplierId: this.requestTicketFormGroup.value.supplier?.id || null,
      supportTicketId:
        this.requestTicketFormGroup.value.supportTicket?.id || null,
      uncataloguedParts:
        this.requestTicketFormGroup.value.uncataloguedParts.map((p) => {
          return {
            quantity: p.quantity,
            requestTicketId: this.id,
            uncataloguedPartId: p.uncataloguedPart.id,
          };
        }),
      imageIds: this.requestTicketFormGroup.value.images.map((i) => i.id),
      clientInfo: {
        address: this.requestTicketFormGroup.value.supplierAddress,
        city: this.requestTicketFormGroup.value.supplierCity,
        countryISOCode: this.requestTicketFormGroup.value.supplierCountry,
        distance: `${this.requestTicketFormGroup.value.supplierDistance}`,
        zipCode: this.requestTicketFormGroup.value.supplierPostCode,
      },
    };

    this.validationsRequestForm.validate({
      form: this.requestTicketFormGroup,
      selectedTab: this.selectedTab,
    });

    if (this.requestTicketFormGroup.valid) {
      this.requestTicketControllerV2.updateById({ newData }).subscribe((e) => {
        this.requestTicket$.next(e);
      });
    }
  }

  openDialogChangesNotSaved() {
    return this.dialog
      .open(DialogComponent, {
        data: {
          title: 'unsavedChangesTitle',
          message: 'unsavedChangesText',
          closeModalText: 'no',
          actionModalText: 'save',
          actionButtonClass: 'button-green',
          closeButtonClass: 'button-red',
        },
      })
      .afterClosed();
  }

  onChangingTab(e: MatTabChangeEvent) {
    const isReturningAfterDialog = () => {
      return (
        this.selectedTab[this.selectedTab.length - 1] ==
        this.selectedTab[this.selectedTab.length - 2]
      );
    };

    if (isReturningAfterDialog()) return;

    if (this.requestTicketFormGroup.dirty && this.id) {
      const dialog = this.openDialogChangesNotSaved();
      dialog.subscribe((saveOutput) => {
        if (!saveOutput) return this.initFormFieldsAndValues();
        if (saveOutput) {
          this.editRequestTicket();
        }
      });
    }
  }

  onTrackingFieldsUpdate() {
    // this.re
  }

  createRequest() {
    const form = this.requestTicketFormGroup.value;
    const newData: RequestTicketPostRequest = {
      assigneeIds: form.assigneeIds,
      closedAt: null,
      contactIds: form.supplierContactsIds,
      createdAt: this.timezoneDate.currentDate().toISOString(),
      description: form.description,
      imageIds: form.images.map((i) => i.id),
      name: form.name,
      parts: form.parts.map((e) => {
        return {
          partId: e.part.id,
          quantity: e.quantity,
          requestTicketId: this.id,
        };
      }),
      priority: form.priority,
      statusId: form.statusId,
      supplierId: form.supplier?.id,
      supportTicketId: form.supportTicket?.id,
      uncataloguedParts: form.uncataloguedParts.map((e) => {
        return {
          quantity: e.quantity,
          uncataloguedPartId: e.uncataloguedPart.id,
          requestTicketId: this.id,
        };
      }),
      clientInfo: {
        address: form.supplierAddress,
        city: form.supplierCity,
        countryISOCode: form.supplierCountry,
        distance: `${form.supplierDistance}`,
        zipCode: form.supplierPostCode,
      },
    };

    this.validationsRequestForm.validate({
      form: this.requestTicketFormGroup,
      selectedTab: this.selectedTab,
    });

    if (this.requestTicketFormGroup.valid) {
      this.requestTicketControllerV2.create({ newData }).subscribe((res) => {
        this.router.navigate([`tickets/request/${res.id}/edit`]);
      });
    }
  }

  onChange(e) {
    if (this.id) {
      this.editRequestTicket();
    }
  }

  getCountries() {
    this.route.data.subscribe((data) => {
      if (!data?.countries) return;
      this.countries$.next(
        data?.countries
          .filter((c: Country) => c.active)
          .map((c: Country) => {
            return { countryISOCode: c.isoCode, name: c.name, id: c.isoCode };
          })
      );
    });
  }
}
