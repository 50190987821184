import { ISerialisedProduct } from './serialisations/ISerialisedProduct';
import { Label } from './Label';
import { SubProduct } from './SubProduct';
import { UnsavedProduct } from './UnsavedProduct';
import { ReportSection } from './ReportSection';

export class Product {
  constructor(
    readonly id: string,
    readonly name: string,
    readonly stock: number,
    readonly price: number,
    readonly ref: string,
    readonly description: string,
    readonly labels: Label[],
    readonly subproducts: SubProduct[],
    readonly technicalCharacteristics: { [key: string]: [][] } | null,
    readonly additionalInfo: ReportSection[],
    readonly catalogueUrl: string | null,
    readonly imageUrls: string[] | null
  ) {
    if (name) this.name = name.trim();
    if (ref) this.ref = ref.trim();
    if (description) this.description = description.trim();
  }

  static fromSerialised(serialised: ISerialisedProduct) {
    const labels = serialised.labels.map(Label.fromSerialised);
    const subproducts = serialised.subproducts.map(SubProduct.fromSerialised);
    const additionalInfo = serialised.additionalInfo.map(
      ReportSection.fromSerialised
    );
    return new Product(
      serialised.id,
      serialised.name,
      serialised.stock,
      serialised.price,
      serialised.ref,
      serialised.description,
      labels,
      subproducts,
      serialised.technicalCharacteristics,
      additionalInfo,
      serialised.catalogueUrl,
      serialised.imageUrls
    );
  }

  toSerialised(): ISerialisedProduct {
    const labelsObj = this.labels.map((label) => label.toSerialised());
    const subproductsObj = this.subproducts.map((subproduct) =>
      subproduct.toSerialised()
    );
    const additionalInfoObj = this.additionalInfo.map((info) =>
      info.toSerialised()
    );

    return {
      id: this.id,
      name: this.name,
      stock: this.stock,
      price: this.price,
      ref: this.ref,
      description: this.description,
      labels: labelsObj,
      subproducts: subproductsObj,
      technicalCharacteristics: this.technicalCharacteristics,
      additionalInfo: additionalInfoObj,
      catalogueUrl: this.catalogueUrl,
      imageUrls: this.imageUrls,
    };
  }

  toDraft(): UnsavedProduct {
    const product = this.toSerialised();
    return UnsavedProduct.fromSerialised({
      ...product,
    });
  }

  clone(partialProduct: PartialProduct): Product {
    const resolve = (key: keyof Product) =>
      partialProduct.hasOwnProperty(key) ? partialProduct[key] : this[key];

    return new Product(
      resolve('id'),
      resolve('name'),
      resolve('stock'),
      resolve('price'),
      resolve('ref'),
      resolve('description'),
      resolve('labels'),
      resolve('subproducts'),
      resolve('technicalCharacteristics'),
      resolve('additionalInfo'),
      resolve('catalogueUrl'),
      resolve('imageUrls')
    );
  }
}

type PartialProduct = Partial<
  Pick<
    Product,
    | 'id'
    | 'name'
    | 'stock'
    | 'price'
    | 'ref'
    | 'description'
    | 'labels'
    | 'subproducts'
    | 'technicalCharacteristics'
    | 'additionalInfo'
    | 'catalogueUrl'
    | 'imageUrls'
  >
>;
