<div
  class="drop-input"
  [ngClass]="{
    'drop-input-margin-bottom':
      (formControlElement.errors && !formControlElement.touched) ||
      !formControlElement.errors
  }"
>
  <h4>{{ label | i18nextCap }}</h4>

  <div class="field-and-info-icon">
    <div style="width: 100%">
      <div
        class="dropzone"
        [ngStyle]="{ 'height.px': height }"
        [ngClass]="{
          'dropzone-error':
            formControlElement.errors && formControlElement.touched
        }"
      >
        <input
          type="file"
          (change)="uploadTemplate($event)"
          [accept]="accept"
        />

        <!-- If there is a image file, it will render that div -->
        <div class="imagePreview" *ngIf="imageURL && imageURL !== ''">
          <img [attr.src]="imageURL" />
        </div>

        <!-- If there is a no image, it will render that div -->
        <div class="dropzone-visual-content" *ngIf="!imageURL">
          <div class="icon" [ngClass]="isDragOver ? 'hide-icon' : 'blue-icon'">
            <fa-icon [icon]="faCloudArrowUp" size="xl"></fa-icon>
          </div>
          <p class="normal-text" *ngIf="!isDragOver">
            {{ 'dragAndDropFile' | i18nextCap }}
          </p>
          <p class="normal-text" *ngIf="isDragOver">
            {{ 'releaseToUploadFile' | i18nextCap }}
          </p>
        </div>
      </div>
      <mat-error
        *ngIf="formControlElement.errors && formControlElement.touched"
        class="mat-error-custom"
      >
        {{ errorMessage }}
      </mat-error>
    </div>

    <div
      class="icon blue-icon"
      [matTooltip]="
        description +
        (this.configuration.options?.maxFileSize
          ? '. ' +
            ('maxFileSize'
              | i18nextCap
                : { size: formatBytes(this.configuration.options.maxFileSize) })
          : '') +
        (getAcceptFile() ? ' ' + getAcceptFile() : '')
      "
    >
      <fa-icon [icon]="faCircleInfo" size="xl"></fa-icon>
    </div>
  </div>
</div>
