import { ISerialisedUnsavedProduct } from './serialisations/ISerialisedProduct';
import { Label } from './Label';
import { UnsavedSubProduct } from './UnsavedSubProduct';
import { ReportSection } from './ReportSection';

export class UnsavedProduct {
  constructor(
    readonly name: string,
    readonly stock: number,
    readonly price: number,
    readonly ref: string,
    readonly description: string,
    readonly labels: Label[],
    readonly subproducts: UnsavedSubProduct[],
    readonly technicalCharacteristics: { [key: string]: [][] },
    readonly additionalInfo: ReportSection[],
    readonly catalogueUrl: string | null,
    readonly imageUrls: string[] | null
  ) {
    if (name) this.name = name.trim();
    if (ref) this.ref = ref.trim();
    if (description) this.description = description.trim();
  }

  static fromSerialised(serialised: ISerialisedUnsavedProduct) {
    const labels = serialised.labels.map(Label.fromSerialised);
    const subproducts = serialised.subproducts.map(
      UnsavedSubProduct.fromSerialised
    );
    const additionalInfo = serialised.additionalInfo.map(
      ReportSection.fromSerialised
    );

    return new UnsavedProduct(
      serialised.name,
      serialised.stock,
      serialised.price,
      serialised.ref,
      serialised.description,
      labels,
      subproducts,
      serialised.technicalCharacteristics,
      additionalInfo,
      serialised.catalogueUrl,
      serialised.imageUrls
    );
  }

  toSerialised(): ISerialisedUnsavedProduct {
    const labelsObj = this.labels.map((label) => label.toSerialised());
    const subproductsObj = this.subproducts.map((subproduct) =>
      subproduct.toSerialised()
    );
    const additionalInfoObj = this.additionalInfo.map((info) =>
      info.toSerialised()
    );

    return {
      name: this.name,
      stock: this.stock,
      price: this.price,
      ref: this.ref,
      description: this.description,
      labels: labelsObj,
      subproducts: subproductsObj,
      technicalCharacteristics: this.technicalCharacteristics,
      additionalInfo: additionalInfoObj,
      catalogueUrl: this.catalogueUrl,
      imageUrls: this.imageUrls,
    };
  }

  clone(partialUnsavedProduct: PartialUnsavedProduct): UnsavedProduct {
    const resolve = (key: keyof UnsavedProduct) =>
      partialUnsavedProduct.hasOwnProperty(key)
        ? partialUnsavedProduct[key]
        : this[key];

    return new UnsavedProduct(
      resolve('name'),
      resolve('stock'),
      resolve('price'),
      resolve('ref'),
      resolve('description'),
      resolve('labels'),
      resolve('subproducts'),
      resolve('technicalCharacteristics'),
      resolve('additionalInfo'),
      resolve('catalogueUrl'),
      resolve('imageUrls')
    );
  }
}

type PartialUnsavedProduct = Partial<
  Pick<
    UnsavedProduct,
    | 'name'
    | 'stock'
    | 'price'
    | 'ref'
    | 'description'
    | 'labels'
    | 'subproducts'
    | 'technicalCharacteristics'
    | 'additionalInfo'
    | 'catalogueUrl'
    | 'imageUrls'
  >
>;
