import { ISerialisedUnsavedAnomaly } from './serialisations/ISerialisedAnomaly';
import { User } from './User';

export class UnsavedAnomaly {
  constructor(
    readonly createdAt: string,
    readonly createdBy: User | null,
    readonly description: string,
    readonly id: string,
    readonly photos: string[],
    readonly type: string,
    readonly updatedAt: string,
    readonly updatedBy: User | null
  ) {
    if (description) this.description = description.trim();
    if (type) this.type = type.trim();
  }

  static fromSerialised(serialised: ISerialisedUnsavedAnomaly) {
    const createdBy = User.fromSerialised(serialised.createdBy);
    const updatedBy = User.fromSerialised(serialised.updatedBy);

    return new UnsavedAnomaly(
      serialised.createdAt,
      createdBy,
      serialised.description,
      serialised.id,
      serialised.photos,
      serialised.type,
      serialised.updatedAt,
      updatedBy
    );
  }

  toSerialised(): ISerialisedUnsavedAnomaly {
    const createdByObj = this.createdBy ? this.createdBy.toSerialised() : null;
    const updatedByObj = this.updatedBy ? this.updatedBy.toSerialised() : null;

    return {
      createdAt: this.createdAt,
      createdBy: createdByObj,
      description: this.description,
      id: this.id,
      photos: this.photos,
      type: this.type,
      updatedAt: this.updatedAt,
      updatedBy: updatedByObj,
    };
  }

  clone(partialUnsavedAnomaly: PartialUnsavedAnomaly): UnsavedAnomaly {
    const resolve = (key: keyof UnsavedAnomaly) =>
      partialUnsavedAnomaly.hasOwnProperty(key)
        ? partialUnsavedAnomaly[key]
        : this[key];

    return new UnsavedAnomaly(
      resolve('createdAt'),
      resolve('createdBy'),
      resolve('description'),
      resolve('id'),
      resolve('photos'),
      resolve('type'),
      resolve('updatedAt'),
      resolve('updatedBy')
    );
  }
}
type PartialUnsavedAnomaly = Partial<
  Pick<
    UnsavedAnomaly,
    | 'createdAt'
    | 'createdBy'
    | 'description'
    | 'id'
    | 'photos'
    | 'type'
    | 'updatedAt'
    | 'updatedBy'
  >
>;
