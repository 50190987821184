import { Component, Input, OnInit } from '@angular/core';
import { Priority } from '@fullyops/legacy/ui/ui-shared/utils/crm-types';
import { User, Company } from '@fullyops/legacy/data';
import { ChangeDetectionStrategy } from '@angular/core';
import { SupportTicketInterventionType } from '@fullyops/legacy/data/api/types/SupportTicket';

@Component({
  selector: 'crm-pipeline-ticket',
  templateUrl: './pipeline-ticket.component.html',
  styleUrls: ['./pipeline-ticket.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PipelineTicketComponent implements OnInit {
  @Input() ticket: {
    identifier: string;
    name: string;
    priority: Priority;
    assignee: User;
    assignees: User[];
    company?: Company;
    supplier: Company;
    warranty: boolean;
    actAt?: Date;
    saleDate?: Date;
    interventionType?: SupportTicketInterventionType;
  };

  ngOnInit(): void {}
}
